import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {
    FormWrapper,
    InputBoxWithState,
    InputButtonWithState, SelectBoxWithState
} from "../../../../../../components/boda-boda/Forms";
import {countryCodeAPI, getUserStaffList} from "../../../../../../config/boda-boda/apiUrls.config";
import {useDispatch} from "react-redux";
import {createMerchantAdministrationTrKey} from "../../../../../../store/boda-boda/transaction_request/actionTypes";
import {phoneToStandardFormat} from "../../../../../../helpers/boda-boda/common-helpers/phone.helper";

class MerchantAdministration extends Component {
    render() {
        return (
            <Row>
                <Col sm={12}>
                    <FormWrapper
                        setGroupName={`${getUserStaffList.key}_create`}
                        onDestroyUnsetFormObject={true}
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Name"
                                    placeHolderText={"Name"}
                                    inputName={"name"}
                                    formGroupName={`${getUserStaffList.key}_create`}
                                />
                            </div>
                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Identification"
                                    placeHolderText={"Identification"}
                                    inputName={"identification"}
                                    formGroupName={`${getUserStaffList.key}_create`}
                                />
                            </div>
                            <div className="col-md-6">

                                <div className={"row"}>
                                    <div className={"col-md-3"}>
                                        <SelectBoxWithState
                                            isRequired={true}
                                            labelText="Country Code"
                                            inputStatePath={`${getUserStaffList.key}_create.countryCode.id`}
                                            formGroupName={`${getUserStaffList.key}_create`}
                                            apiUrl={`${countryCodeAPI.url}/all`}
                                            apiStateKey={countryCodeAPI.key}
                                            onRebuildResponseFn={(response) => {
                                                const data = [];

                                                response.forEach((value) => {
                                                    data.push({
                                                        id: `${value.id}/${value.code}/${value.prefix}`,
                                                        value: value.code
                                                    });
                                                });

                                                return {
                                                    "data": {
                                                        "data": data
                                                    }
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className={"col-md-9"}>
                                        <InputBoxWithState
                                            labelText="Phone"
                                            inputName={"phone"}
                                            placeHolderText={"Phone"}
                                            formGroupName={`${getUserStaffList.key}_create`}
                                            isRequired={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Email"
                                    placeHolderText={"Email"}
                                    inputName={"email"}
                                    formGroupName={`${getUserStaffList.key}_create`}
                                />
                            </div>

                            <div className="col-md-12">
                                <hr />
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputBoxWithState
                                            isRequired={true}
                                            labelText="Username"
                                            placeHolderText={"Username"}
                                            inputName={"credential"}
                                            formGroupName={`${getUserStaffList.key}_create`}
                                            maxLength={50}
                                        />
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <InputBoxWithState
                                            isRequired={true}
                                            labelText="Enter the Password"
                                            inputType={"password"}
                                            formGroupName={`${getUserStaffList.key}_create`}
                                            inputName={"password"}
                                            placeHolderText={"Password"}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputBoxWithState
                                            isRequired={true}
                                            labelText="Re Enter the Password"
                                            inputType={"password"}
                                            formGroupName={`${getUserStaffList.key}_create`}
                                            inputName={"confirm_password"}
                                            placeHolderText={"Re-Password"}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className={"d-flex justify-content-end"}>
                                    <CreateBtn
                                        onNextFn={this.props.onNextFn}
                                        merchantId={this.props.merchantId}
                                    />
                                </div>
                            </div>
                        </div>

                    </FormWrapper>
                </Col>
            </Row>
        )
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Next"}
            formGroupName={`${getUserStaffList.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantAdministrationTrKey,
                    payload: {
                        ...dataObject.formObject,
                        onNextFn: props.onNextFn,
                        merchant: props.merchantId,
                        user: "not_found",
                        staff: "not_found"
                    }
                })
            }}
        />
    )
}

export default MerchantAdministration;