import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./customer_includes/FilterUI";
import { RemoveMessage, RemoveBtn } from "./customer_includes/DeleteUI";
import { getCustomerList, getUserStaffList } from "../../../config/boda-boda/apiUrls.config";


class Customer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { history } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Customer</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Customer</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Customer"
              filterFromComponent={FilterUI}
              apiURL={`${getCustomerList.url}?page=1&limit=20`}
              dataTableStateKey={getCustomerList.key}
              defaultFilterFormObject={{ id: "", name: "", phone: "", status: "", identification: "" }}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: false,
                onClickBtnFn: () => history.push("/customer/create")
              }}
              isDropdownActionBtn={true}
              tableHeaderList={[
                { displayName: "Customer Id", key: "id" },
                { displayName: "Name", key: "name" },
                { displayName: "Identification", key: "identification", default: "-" },
                { displayName: "Phone Number", key: "phone", default: "-" },
                { displayName: "Status", key: "status" },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/customer/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/customer/edit/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  }
                }
              ]}
            />
          </Row>


        </div>
      </React.Fragment>
    )
  }
}

export default Customer;
