import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {
    FormWrapper,
    InputBoxWithState
} from "../../../../../../components/boda-boda/Forms";
import SelectBoxWithState from "../../../../../../components/boda-boda/Forms/SelectBoxWithState";
import {
    countryCodeAPI,
    getAllFacilityAPI,
    getCityListAPI,
    merchantBranchAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import InputButtonWithState from "../../../../../../components/boda-boda/Forms/InputButtonWithState";
import {createMerchantBranchTrKey} from "../../../../../../store/boda-boda/transaction_request/actionTypes";
import {useDispatch} from "react-redux";
import {phoneToStandardFormat} from "../../../../../../helpers/boda-boda/common-helpers/phone.helper";
import {currency} from "../../../../../../config/boda-boda/db_config/db_status.config";

class MerchantDefaultBranch extends Component {
    render() {
        return (
            <Row>
                <Col sm={12}>
                    <FormWrapper
                        setGroupName={`${merchantBranchAPI.key}_create`}
                        onDestroyUnsetFormObject={true}
                    >

                        <div className="row">
                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Branch Name"
                                    placeHolderText={"Branch Name"}
                                    inputName={"name"}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                />
                            </div>

                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText={`Pick Up Cost(${currency})`}
                                    placeHolderText={`Pick Up Cost(${currency})`}
                                    inputName={"pickupPrice"}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                />
                            </div>


                            <div className="col-md-6">
                                <SelectBoxWithState
                                    isRequired={true}
                                    labelText={"Delivery Center"}
                                    inputName={"facility"}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                    placeHolderText={"Delivery Center"}
                                    apiUrl={getAllFacilityAPI.url}
                                    apiStateKey={getAllFacilityAPI.key}
                                    keyName={"id"}
                                    valueName={"name"}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Address"
                                    placeHolderText={"Enter the Shipping Address"}
                                    inputName={"address"}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                    maxLength={250}
                                />
                            </div>


                            <div className={"col-md-6"}>
                                <SelectBoxWithState
                                    isRequired={true}
                                    labelText="City"
                                    inputName={"cityId"}
                                    placeHolderText={"Select a City"}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                    apiUrl={`${getCityListAPI.url}/all`}
                                    apiStateKey={getCityListAPI.key}
                                    keyName={"id"}
                                    valueName={"name"}
                                />
                            </div>

                        </div>

                        <hr />

                        <div className={"row"}>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Contact Person"
                                    placeHolderText={"Contact Person"}
                                    inputName={"fullName"}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                />
                            </div>

                            <div className="col-md-6">

                                <div className={"row"}>
                                    <div className={"col-md-3"}>
                                        <SelectBoxWithState
                                            isRequired={true}
                                            labelText="Country Code"
                                            inputStatePath={`${merchantBranchAPI.key}_create.countryCode.id`}
                                            formGroupName={`${merchantBranchAPI.key}_create`}
                                            apiUrl={`${countryCodeAPI.url}/all`}
                                            apiStateKey={countryCodeAPI.key}
                                            onRebuildResponseFn={(response) => {
                                                const data = [];

                                                response.forEach((value) => {
                                                    data.push({
                                                        id: `${value.id}/${value.code}/${value.prefix}`,
                                                        value: value.code
                                                    });
                                                });

                                                return {
                                                    "data": {
                                                        "data": data
                                                    }
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className={"col-md-9"}>
                                        <InputBoxWithState
                                            labelText="Phone"
                                            inputName={"phone"}
                                            placeHolderText={"Phone"}
                                            formGroupName={`${merchantBranchAPI.key}_create`}
                                            isRequired={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Email"
                                    placeHolderText={"Email"}
                                    inputName={'email'}
                                    formGroupName={`${merchantBranchAPI.key}_create`}
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="btn_div" className="float-right">
                                    <CreateBtn
                                        merchantId={this.props.merchantId}
                                        historyFn={this.props.history}
                                    />
                                </div>
                            </div>
                        </div>

                    </FormWrapper>
                </Col>
            </Row>
        )
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Finish"}
            formGroupName={`${merchantBranchAPI.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantBranchTrKey,
                    payload: {
                        ...dataObject.formObject,
                        merchant: props.merchantId,
                        historyFn: props.historyFn,
                        branch: "not_found"
                    }
                })
            }}
        />
    )
}

export default MerchantDefaultBranch;