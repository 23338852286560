import React from "react"
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap"
import { DatePickerBoxWithState, FormWrapper, InputButton, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getUserDriverList } from "../../../../config/boda-boda/apiUrls.config";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/actions";
import { get } from "lodash";

export const DriverSettlement = (props) => {
    const formState = useSelector(state => state.FormData);
    const dispatch = useDispatch();
    const history = useHistory();

    const validateFn = () => {
        const errors = [];

        if (isEmptyValue(get(formState, "driver_settlement.deliveredDateFrom", ""))) {
            errors.push({
                "property": "deliveredDateFrom",
                "error": "The Delivered Date From field is required"
            })
        }
        
        if (isEmptyValue(get(formState, "driver_settlement.deliveredDateTo", ""))) {
            errors.push({
                "property": "deliveredDateTo",
                "error": "The Delivered Date To field is required"
            })
        }

        if (errors.length === 0) {
            if (new Date(get(formState, "driver_settlement.deliveredDateFrom", "")) > new Date(get(formState, "driver_settlement.deliveredDateTo", ""))) {
                dispatch(setFormErrorFn("driver_settlement", [{
                    "property": "deliveredDateTo",
                    "error": "The Delivered Date To field should not be less than the Delivered Date From"
                }]));

                return false;
            } else {
                dispatch(setFormErrorFn("driver_settlement", []));
                return true;
            }
        } else {
            dispatch(setFormErrorFn("driver_settlement", errors));
            return false;
        }
    }

    const onGenerateFn = () => {
        if (validateFn() === true) {
            history.push("/driver-settlement/view");
        }
    }



    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Driver Settlement</h4>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Driver Settlement</li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <div className={"col-md-12"}>

                        <div className={"card"} style={{ marginBottom: "200px" }}>
                            <div className={"card-body"}>

                                <FormWrapper
                                    setGroupName={"driver_settlement"}
                                    onDestroyUnsetFormObject={false}
                                    setFormObject={{
                                        deliveredDateFrom: null,
                                        deliveredDateTo: null,
                                        driver: "",
                                    }}
                                >
                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                isRequired={true}
                                                labelText="Delivered Date From"
                                                inputName={"deliveredDateFrom"}
                                                formGroupName={"driver_settlement"}
                                                placeholderText={"Delivered Date From"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                isRequired={true}
                                                labelText="Delivered Date To"
                                                inputName={"deliveredDateTo"}
                                                formGroupName={"driver_settlement"}
                                                placeholderText={"Delivered Date To"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"Driver"}
                                                inputName={"driver"}
                                                placeHolderText={"Driver"}
                                                formGroupName={"driver_settlement"}
                                                apiUrl={`${getUserDriverList.url}/all`}
                                                apiStateKey={getUserDriverList.key}
                                                onRebuildResponseFn={(response) => {
                                                    const data = [{
                                                        id: "",
                                                        value: "All"
                                                    }];
                                                    // const data = [];
                                                    response.forEach((value) => {
                                                        data.push({
                                                            id: `${value.userId}/${value.name}/${value.licenseNo}`,
                                                            value: `${value.name} - ${value.licenseNo}`
                                                        });
                                                    });

                                                    return {
                                                        "data": {
                                                            "data": data
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>

                                            <InputButton
                                                btnText={"Generate"}
                                                startIcon={"fas fa-spinner"}
                                                onClickBtnFn={onGenerateFn}
                                            />

                                        </div>
                                    </div>
                                </FormWrapper>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}