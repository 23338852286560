import React from "react";
import {
  FormWrapper,
  InputBoxWithState,
  SubmitButton,
} from "../../../../../components/boda-boda/Forms";
import { headOfficeSettlementAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { getDataByFormObject } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";

const MerchantSettlementConfirmUI = (props) => {
  return (
    <FormWrapper
      setGroupName={`${headOfficeSettlementAPI.key}_edit`}
      onDestroyUnsetFormObject={true}
    >
      <InputBoxWithState
        labelText={"Remark"}
        placeHolderText={"remark"}
        formGroupName={`${headOfficeSettlementAPI.key}_edit`}
        inputName={"remark"}
        isRequired={true}
      />
    </FormWrapper>
  );
};


const MerchantSettlementConfirmBtn = (props) => {
  return (
    <SubmitButton
      btnText={"Confirm"}
      formGroupName={`${headOfficeSettlementAPI.key}_edit`}
      isValidate={false}
      flashMessages={{
        "success": {
          status: true,
          message: "Merchant Settlement successfully confirmed",
          type: "success"
        }
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "put",
        onUpload: false
      }}
      onGetAPIEndPointFn={(formObject) => {
        return {
          url: `${headOfficeSettlementAPI.url}/${props.row.id}`,
          key: `${headOfficeSettlementAPI.key}_edit`
        }
      }}
      onChangeRequestBodyFn={(formObject) => {
        return { ...getDataByFormObject(formObject), settled: true };
      }}
      onResponseCallBackFn={(error, result) => {
        if (!error) {
          props.setModelStatusFn(false);
        }
      }}
    />
  );
}

export { MerchantSettlementConfirmUI, MerchantSettlementConfirmBtn };
