import React, {Component} from "react";
import {
    FormWrapper,
    InputBoxWithState, InputButtonWithState,
    SelectBoxWithState
} from "../../../../../../components/boda-boda/Forms";
import {notifications} from "../../../../../../config/boda-boda/db_config/db_status.config";
import {
    countryCodeAPI,
    getMerchantAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import {useDispatch} from "react-redux";
import {createMerchantTrKey} from "../../../../../../store/boda-boda/transaction_request/actionTypes";

class MerchantProfile extends Component {
    render() {
        return (
            <FormWrapper
                setGroupName={`${getMerchantAPI.key}_create`}
                onDestroyUnsetFormObject={true}
            >
                <div className="container-fluid">
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Merchant Name"
                                                placeHolderText={"Merchant Name"}
                                                inputName={"name"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Merchant Code"
                                                placeHolderText={"Merchant Code"}
                                                inputName={"code"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Business Reg. No."
                                                placeHolderText={"Registration No"}
                                                inputName={"registrationNo"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Website"
                                                placeHolderText={"Website"}
                                                inputName={"website"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <div className={"card-title mb-4"}>Contact Person</div>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <InputBoxWithState
                                                labelText={"Full Name"}
                                                placeHolderText={"Full Name"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                                inputName={"fullName"}
                                                isRequired={true}
                                            />
                                        </div>
                                        <div className="col-md-6">

                                            <div className={"row"}>
                                                <div className={"col-md-3"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Country Code"
                                                        inputStatePath={`${getMerchantAPI.key}_create.countryCode.id`}
                                                        formGroupName={`${getMerchantAPI.key}_create`}
                                                        apiUrl={`${countryCodeAPI.url}/all`}
                                                        apiStateKey={countryCodeAPI.key}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.code}/${value.prefix}`,
                                                                    value: value.code
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className={"col-md-9"}>
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"Phone"}
                                                        formGroupName={`${getMerchantAPI.key}_create`}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <InputBoxWithState
                                                labelText={"Email"}
                                                placeHolderText={"Email"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                                inputName={"email"}
                                                isRequired={true}
                                            />
                                        </div>
                                        <div className={"col-md-6"}>
                                            <SelectBoxWithState
                                                labelText={"Notifications"}
                                                placeHolderText={"Notifications"}
                                                formGroupName={`${getMerchantAPI.key}_create`}
                                                inputName={"notification"}
                                                dataList={notifications}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"d-flex justify-content-end"}>
                                <CreateBtn
                                    onNextFn={this.props.onNextFn}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        )
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Next"}
            formGroupName={`${getMerchantAPI.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantTrKey,
                    payload: {
                        ...dataObject.formObject,
                        onNextFn: props.onNextFn,
                        merchant: "not_found",
                        merchantContact: "not_found",
                    }
                })
            }}
        />
    )
}

export default MerchantProfile;