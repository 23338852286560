import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { getMerchantAPI, getMerchantBankAllAPI, getMerchantContactAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { get } from "lodash";
import { DeleteUI } from "./DeleteUI";

class ViewUI extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    let { match, history, formState } = this.props;

    return (
      <div className="container-fluid">
        <FormWrapper
          apiUrl={`${getMerchantAPI.url}/${match.params.id}`}
          setGroupName={`${getMerchantAPI.key}_view`}
          onDestroyUnsetFormObject={true}
          onRebuildResponseFn={(response) => {
            console.log(response.deletedBy);
            return {
              id: response.id,
              name: response.name,
              code: response.code,
              registrationNo: response.registrationNo,
              categoryId: response.categoryId,
              status: response.status,
              type: response.type,
              rating: response.rating,
              orderCount: response.orderCount,
              contacts: response.contacts,
              website: response.website,
              deletedBy: response.deletedBy,
              trackByChannelOrderNoPrefix: response.trackByChannelOrderNoPrefix,
              isCustomerNotificationEnable: response.isCustomerNotificationEnable,
              "_onLoad": false,
            }
          }}
        >
          <Row className="align-items-center">
            <Col sm={3}>
              <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                <h4 className="font-size-18">View Merchant Details</h4>
              </div>
            </Col>

            <Col sm={9}>
              <div className={"d-flex justify-content-end"}>
                <BackBtnComponent
                  elementWrapperStyle={"mr-2"}
                  path={"/merchant"}
                />
                {
                  (get(formState, `${getMerchantAPI.key}_view.status`, "") === "ACTIVE") ? (
                    <React.Fragment>
                      <InputButton
                        elementWrapperStyle={"mr-2"}
                        btnText={"Contracts"}
                        startIcon={"fas fa-file-signature"}
                        onClickBtnFn={() => history.push(`/merchant/${match.params.id}/contract`)}
                      />
                      <InputButton
                        elementWrapperStyle={"mr-2"}
                        btnText={"Branches"}
                        startIcon={"fas fa-store-alt"}
                        onClickBtnFn={() => history.push(`/merchant/${match.params.id}/branch`)}
                      />
                      <InputButton
                        elementWrapperStyle={"mr-2"}
                        btnText={"Merchant Admin Users"}
                        startIcon={"fas fa-users-cog"}
                        onClickBtnFn={() => history.push(`/merchant/${match.params.id}/admin-users`)}
                      />
                      <InputButton
                        elementWrapperStyle={"mr-2"}
                        btnText={"Manage Templates"}
                        startIcon={"fas fa-file-word"}
                        onClickBtnFn={() => history.push(`/merchant/template/${match.params.id}`)}
                      />
                      <InputButton
                        elementWrapperStyle={"mr-2"}
                        btnText={"Edit"}
                        startIcon={"mdi mdi-square-edit-outline"}
                        onClickBtnFn={() => history.push(`/merchant/edit/${match.params.id}`)}
                      />
                    </React.Fragment>
                  ) : get(formState, `${getMerchantAPI.key}_view.status`, "") === "INACTIVE" ?
                    (
                      <React.Fragment>
                        <InputButton
                          elementWrapperStyle={"mr-2"}
                          btnText={"Edit"}
                          startIcon={"mdi mdi-square-edit-outline"}
                          onClickBtnFn={() => history.push(`/merchant/edit/${match.params.id}`)}
                        />
                      </React.Fragment>
                    ) :
                    null
                }

                {
                  (get(formState, `${getMerchantAPI.key}_view.deletedBy`, "") === null && get(formState, `${getMerchantAPI.key}_view.status`, "") !== "INACTIVE") ? (
                    <DeleteUI
                      historyFn={history}
                      merchant={get(formState, `${getMerchantAPI.key}_view.id`, "")}
                    />
                  ) : null
                }

              </div>
            </Col>
          </Row>

          <div className="row">
            <div className={"col-md-12"}>
              <div className="card">
                <div className="card-body">

                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <dl className={"row"}>

                        <dd className={"col-sm-4"}>Merchant Code</dd>
                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.code`, "")}</dt>

                        <dd className={"col-sm-4"}>Business Reg.No.</dd>
                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.registrationNo`, "")}</dt>

                        <dd className={"col-sm-4"}>Website</dd>
                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.website`, "") === null ? "-" : get(formState, `${getMerchantAPI.key}_view.website`, "")}</dt>

                      </dl>
                    </div>

                    <div className={"col-md-6"}>
                      <dl className={"row"}>

                        <dd className={"col-sm-4"}>Merchant Name</dd>
                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.name`, "")}</dt>

                        <dd className={"col-sm-4"}>Status</dd>
                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.status`, "")}</dt>

                        <dd className={"col-sm-4"}>Send Customer Notifications</dd>
                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.isCustomerNotificationEnable`, "") ? "ENABLED" : "DISABLED"}</dt>

                        {get(formState, `${getMerchantAPI.key}_view.trackByChannelOrderNoPrefix`, "") !== null ? (
                          <React.Fragment>
                            <dd className={"col-sm-4"}>Tracking Number Prefix</dd>
                            <dt className={"col-sm-8"}>{get(formState, `${getMerchantAPI.key}_view.trackByChannelOrderNoPrefix`, "")}</dt>
                          </React.Fragment>
                        ) : null}
                      </dl>
                    </div>
                  </div>

                  <h4 className="card-title mb-3">Bank Detail</h4>
                  <FormWrapper
                    setGroupName={`${getMerchantBankAllAPI.key}`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getMerchantBankAllAPI.url}?merchantId=${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                      return {
                        merchantBank: get(response, "[0]", {}),
                        _onLoad: false,
                      };
                    }}
                  >
                    <div className={"row"}>
                      <div className={"col-md-6"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>Bank</dd>
                          <dt className={"col-sm-8"}>
                            {get(formState, `${getMerchantBankAllAPI.key}.merchantBank.bank`, "-")}
                          </dt>

                          <dd className={"col-sm-4"}>Account Name</dd>
                          <dt className={"col-sm-8"}>
                            {get(formState, `${getMerchantBankAllAPI.key}.merchantBank.accountName`, "-")}
                          </dt>

                          <dd className={"col-sm-4"}>Account Number</dd>
                          <dt className={"col-sm-8"}>
                            {get(formState, `${getMerchantBankAllAPI.key}.merchantBank.accountNumber`, "-")}
                          </dt>

                          <dd className={"col-sm-4"}>Branch</dd>
                          <dt className={"col-sm-8"}>
                            {get(formState, `${getMerchantBankAllAPI.key}.merchantBank.branch`, "-")}
                          </dt>
                        </dl>
                      </div>
                    </div>
                  </FormWrapper>


                  <CrudComponent
                    title={"Contact Details"}
                    isSetAction={false}
                    dataTableStateKey={getMerchantContactAPI.key}
                    apiURL={`${getMerchantContactAPI.url}?merchantId=${match.params.id}&page=1&limit=20`}
                    isSetCreateAction={false}
                    isFilter={false}
                    tableHeaderList={[
                      { displayName: "Contact Person", key: "fullName" },
                      { displayName: "Phone No.", key: "phone" },
                      { displayName: "Email", key: "email" },
                      { displayName: "Contact Type", key: "departmentType" },
                      {
                        displayName: "Receive Notification", key: "notification", onPrintFn: (rawData) => {
                          return rawData.notification === true ? "Yes" : "No"
                        }
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

        </FormWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData
  };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
  ViewUIWithState
};
