export const createUserTrKey = "@TRANSACTION/create_user";
export const updateUserTrKey = "@TRANSACTION/update_user";
export const createDriverTrKey = "@TRANSACTION/create_driver";
export const updateDriverTrKey = "@TRANSACTION/update_driver";
export const updateMerchantAdminUserTrKey = "@TRANSACTION/updateMerchantAdminUser";
export const updateManifestIdTrKey = "@TRANSACTION/update_manifest_id";
export const updateManifestIdAddPackageTrKey = "@TRANSACTION/updateManifestIdAddPackageTrKey";
export const deliveryCenterAddZones = "@TRANSACTION/deliveryCenterAddZones";
export const createZoneTrKey = "@TRANSACTION/create_zone";
export const zoneAddCitiesTrKey = "@TRANSACTION/zoneAddCitiesTrKey";
export const createRunSheetLineTrKey = "@TRANSACTION/createRunSheetLineTrKey";
export const addCollectionsRunSheetLineTrKey = "@TRANSACTION/addCollectionsRunSheetLineTrKey";
export const editRunSheetLineTrKey = "@TRANSACTION/editRunSheetLineTrKey";
export const createRunSheetLastTrKey = "@TRANSACTION/createRunSheetLastTrKey";
export const editRunSheetLastTrKey = "@TRANSACTION/editRunSheetLastTrKey";
export const addPickupsRunSheetLastTrKey = "@TRANSACTION/addPickupsRunSheetLastTrKey";
export const createMerchantTrKey = "@TRANSACTION/create_merchant";
export const editMerchantTrKey = "@TRANSACTION/edit_merchant";
export const createMerchantAdministrationTrKey = "@TRANSACTION/create_merchant_administration";
export const createMerchantBranchTrKey = "@TRANSACTION/create_merchant_branch";
export const createBranchTrKey = "@TRANSACTION/create_branch";
export const createMerchantAdminUserTrKey = "@TRANSACTION/createMerchantAdminUser";
export const removePickupsRunSheetLastTrKey = "@TRANSACTION/removePickupsRunSheetLastTrKey";
export const addDeliveriesRunSheetLastTrKey = "@TRANSACTION/addDeliveriesRunSheetLastTrKey";
export const removeDeliveriesRunSheetLastTrKey = "@TRANSACTION/removeDeliveriesRunSheetLastTrKey";
export const addReturnsRunSheetLastTrKey = "@TRANSACTION/addReturnsRunSheetLastTrKey";
export const removeReturnsRunSheetLastTrKey = "@TRANSACTION/removeReturnsRunSheetLastTrKey";
export const routeLastAddCitiesTrKey = "@TRANSACTION/routeLastAddCitiesTrKey";
export const routeLastRemoveCitiesTrKey = "@TRANSACTION/routeLastRemoveCitiesTrKey";
export const linehaulAddLocationsTrKey = "@TRANSACTION/linehaulAddLocationsTrKey";
export const linehaulRemoveLocationsTrKey = "@TRANSACTION/linehaulRemoveLocationsTrKey";
export const updateLinehaulTrKey = "@TRANSACTION/updateLinehaulTrKey";
export const updateLastMileTrKey = "@TRANSACTION/updateLastMileTrKey";
export const createLinehaulTrKey = "@TRANSACTION/createLinehaulTrKey";
export const createLastMileTrKey = "@TRANSACTION/createLastMileTrKey";
export const updateMerchantBranchTrKey = "TRANSACTION/updateMerchantBranchTrKey";
export const updateUserProfileTrKey = "TRANSACTION/updateUserProfileTrKey";
export const changePasswordTrKey = "TRANSACTION/changePasswordTrKey";
export const bulkTagPrintTrKey = "TRANSACTION/bulkTagPrintTrKey";
export const userPasswordResetTrKey = "TRANSACTION/userPasswordResetTrKey";
export const switchFacilityTrKey = "TRANSACTION/switchFacilityTrKey";
export const changeDriverPasswordTrKey = "TRANSACTION/changeDriverPasswordTrKey";
export const createMerchantContactTrKey = "TRANSACTION/createMerchantContactTrKey";
export const createMerchantBranchContactTrKey = "TRANSACTION/createMerchantBranchContactTrKey";
