import React, { Component } from "react";

import { DatePickerBoxWithState, InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getRoleListAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { transactionTypes, userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";


class FilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Transaction Type"}
                            inputName={"type"}
                            placeHolderText={"Transaction Type"}
                            dataList={transactionTypes}
                            formGroupName={this.props.searchFromKey}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                </div>
                <div className="row">

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText="Date From"
                            inputName={"dateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Date From"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText="Date To"
                            inputName={"dateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Date To"}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default FilterUI;
