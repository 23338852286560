import React, { Component, Fragment } from "react";
import { Col, Label, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputButton, FormWrapper, SubmitButton } from "../../../../components/boda-boda/Forms";
import { getUserDriverList } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { fileGetAPIUrl } from "../../../../config/boda-boda/core.config";
import { UIModal } from "../../pick-request/includes/AcceptRejectUI";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class ViewUI extends Component {
    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserDriverList.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserDriverList.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: get(response, "id", ""),
                            name: get(response, "name", ""),
                            email: get(response, "user.staffs[0].email", ""),
                            phone: get(response, "user.staffs[0].phone", ""),
                            image: get(response, "user.staffs[0].image", null),
                            identification: get(response, "identification", ""),
                            branch: get(response, "merchantBranch.name", ""),
                            category: get(response, "category", ""),
                            status: get(response, "user.staffs[0].status", ""),
                            deletedAt: get(response, "deletedAt", null),
                            licenseNo: get(response, "licenseNo", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={2}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Driver</h4>
                            </div>
                        </Col>

                        <Col sm={10}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/users/driver"}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/users/driver/edit/${match.params.id}`)}
                                />
                                {
                                    (get(formState, `${getUserDriverList.key}_view.category`, "") === "LAST") ? (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Manage Tills"}
                                            startIcon={"mdi mdi-account-box-multiple"}
                                            onClickBtnFn={() => history.push(`/users/driver/view/${match.params.id}/till-management`)}
                                        />
                                    ) : (null)
                                }

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Cash/Card Deliveries"}
                                    startIcon={"mdi mdi-cash-usd-outline"}
                                    onClickBtnFn={() => history.push(`/cash-card-delivery/${match.params.id}`)}
                                />
                                {
                                    (get(formState, `${getUserDriverList.key}_view.category`, "") === "LINE") ? (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"View Runsheet"}
                                            startIcon={"mdi mdi-google-spreadsheet"}
                                            onClickBtnFn={() => history.push(`/runsheets-line-driver/${match.params.id}`)}
                                        />
                                    ) : (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"View Runsheet"}
                                            startIcon={"mdi mdi-google-spreadsheet"}
                                            onClickBtnFn={() => history.push(`/runsheets-last-driver/${match.params.id}`)}
                                        />
                                    )
                                }
                                {
                                    (get(formState, `${getUserDriverList.key}_view.category`, "") === "LINE") ? (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Create Runsheet"}
                                            startIcon={"mdi mdi-file-document-edit-outline"}
                                            onClickBtnFn={() => history.push(`/runsheets-line-driver/create/${match.params.id}`)}
                                        />
                                    ) : (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Create Runsheet"}
                                            startIcon={"mdi mdi-file-document-edit-outline"}
                                            onClickBtnFn={() => history.push(`/runsheets-last-driver/create/${match.params.id}`)}
                                        />
                                    )
                                }
                                {
                                    get(formState, `${getUserDriverList.key}_view.deletedAt`) !== null ? (
                                        <InputButton
                                            elementWrapperStyle={""}
                                            btnText={"Restore"}
                                            startIcon={"fa fa-undo"}
                                            btnColor={"success"}
                                        />

                                    ) : (
                                        <SubmitButton
                                            btnText={"Delete"}
                                            elementStyle={"btn btn-danger  waves-effect waves-light"}
                                            dataTableKey={getUserDriverList.key}
                                            startIcon={"mdi mdi-trash-can-outline"}
                                            isValidate={false}
                                            isSetModel={true}
                                            flashMessages={{
                                                "success": {
                                                    status: true,
                                                    message: "Driver has successfully deleted",
                                                    type: "success"
                                                }
                                            }}
                                            callApiObject={{
                                                isSetHeaders: true,
                                                multipart: false,
                                                method: "delete",
                                                onUpload: false
                                            }}
                                            apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: apiResponseStoringType.dateTableLinkedForm,
                                                mergeToSuccessResponse: null,
                                                mergeToErrorResponse: null,
                                            }}
                                            onGetAPIEndPointFn={(formObject) => {
                                                return {
                                                    url: `${getUserDriverList.url}/${match.params.id}`,
                                                    key: getUserDriverList.key
                                                }
                                            }}
                                            onChangeRequestBodyFn={(formObject) => {
                                                return getDataByFormObject(formObject);
                                            }}
                                            onResponseCallBackFn={(error, result) => {
                                                if (!error) {
                                                    history.push("/users/driver");
                                                }
                                            }}
                                        />
                                    )

                                }

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>

                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Driver ID</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.id`, "-")}</dt>

                                                <dd className={"col-sm-4"}>Driver Type</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.category`, "-")}</dt>
                                            </dl>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                {/* <dd className={"col-sm-4"}>User's Location</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.branch`, "-")}</dt> */}

                                                <dd className={"col-sm-4"}>Status</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.status`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Personal Details</h4>

                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-4"}>Name</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.name`, "")}</dt>

                                                        <dd className={"col-sm-4"}>Identification</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.identification`, "")}</dt>

                                                        <dd className={"col-sm-4"}>Driver's License No</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.licenseNo`, "")}</dt>
                                                    </dl>
                                                </div>

                                                {
                                                    (get(formState, `${getUserDriverList.key}_view.image`, null) !== null) ? (
                                                        <div className={"col-md-6"}>
                                                            <div>
                                                                <Label>Profile Picture</Label>
                                                            </div>

                                                            <div>
                                                                <img
                                                                    className={"img-thumbnail user-profile-picture"}
                                                                    src={`${fileGetAPIUrl}${get(formState, `${getUserDriverList.key}_view.image`, "")}`}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-4"}>E-mail</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.email`, "")}</dt>
                                                        {get(formState, `${getUserDriverList.key}_view.deletedAt`) !== null ? (
                                                            <Fragment>
                                                                <dd className={"col-sm-4"}>Deleted At</dd>
                                                                <dt className={"col-sm-8"}>{dateObjectToString(
                                                                    get(formState, `${getUserDriverList.key}_view.deletedAt`, ""),
                                                                    "YY-mm-dd HH:MM:II"
                                                                )
                                                                }</dt>
                                                            </Fragment>

                                                        ) : null

                                                        }
                                                    </dl>
                                                </div>
                                                <div className={"col-md-6"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-4"}>Phone</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.phone`, "")}</dt>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
    ViewUIWithState
}
