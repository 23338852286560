import React, {Component, Fragment} from "react";
import { useSelector } from "react-redux";
import {InputButton, SubmitButton} from "../../../../components/boda-boda/Forms";
import {ModalUI} from "../../../../components/boda-boda/Modal/ModalUI";
import {apiResponseStoringType} from "../../../../config/boda-boda/apiResponseKey";
import {getOrdersListAPI} from "../../../../config/boda-boda/apiUrls.config";
import {getDataByFormObject} from "../../../../helpers/boda-boda/common-helpers/common.helpers";

class TerminateUI extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fas fa-window-close"}
                    elementStyle={"btn btn-danger  waves-effect waves-light"}
                    btnText={"Terminate"}
                    onClickBtnFn={() => onClickFn()}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Please Confirm"}
                    modalBodyComponent={<p>Are your sure you want to terminate this Consignment ?</p>}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <TerminateBtn
                            consignment={this.props.consignment}
                            historyFn={this.props.historyFn}
                        />
                    }
                />
            </Fragment>
        );
    }
}

const TerminateBtn = (props) => {
    const authStatus = useSelector(state => state.AuthState);
    return(
        <SubmitButton
            btnText={"Terminate"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Consignment has successfully terminated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${props.consignment}`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    status: "TERMINATED",
                    terminatedAt: new Date(),
                    terminatedBy: authStatus?.authUser?.id,
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push('/consignments');
                }
            }}
        />
    )
}

export {
    TerminateUI,
    TerminateBtn
}