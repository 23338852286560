import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { headOfficeSettlementAPI, hubSettlementAPI, hubSettlementReportAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {
  downloadCSV,
  fromObjectToQueryString,
  isEmptyValue,
  roundValue,
} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";

const TableLastRow = (props) => {
  return (
    <Fragment>
      <tr>
        <td colSpan={"4"}></td>
        <td>Total</td>
        <td>{roundValue(get(props, "tableBodyLastProps.total", ""))}</td>
      </tr>
    </Fragment>
  );
};

class HubSettlementReportView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loaderStatus: false,
    };
  }

  getQueryString = () => {
    let { formState } = this.props;

    let dateTo = new Date(
      get(formState, "hub_settlement_report.dateTo", new Date())
    );
    dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
    dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

    const queryObj = {
      settlementDateFrom: get(formState, "hub_settlement_report.dateFrom", new Date()).toISOString(),
      settlementDateTo: dateTo.toISOString(),
      settleFromFacilityId: get(formState, "hub_settlement_report.branch")?.split("/")[0],
      settleToFacilityId: get(formState, "hub_settlement_report.settleToFacilityId")
    };

    return `&${fromObjectToQueryString(queryObj)}`;
  };

  getCsv = (id) => {
    callApi(`${hubSettlementAPI.url}/${id}/orders`)
      .method("get")
      .headers(true)
      .send()
      .then((result) => {
        downloadCSV(result.data.data.toString(), "Orders.csv")
      })
  }

  render() {
    let { formState, coreState } = this.props;

    return (
      <React.Fragment>
        {get(formState, "hub_settlement_report.dateFrom", "") === "" ||
          get(formState, "hub_settlement_report.dateTo", "") === "" ? (
          <Redirect to={"/reports/hub-settlement-report"} />
        ) : (
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Hub Settlement Transaction Report</h4>
                </div>

                <div className={"row"}>
                  <div className={"col-sm-12"}>
                    From<strong className={"mx-2"}>{dateObjectToString(get(formState, "hub_settlement_report.dateFrom"), "dd/mm/YY")}</strong>
                    to <strong className={"mx-2"}>{dateObjectToString(get(formState, "hub_settlement_report.dateTo"), "dd/mm/YY")}</strong>
                  </div>

                  <div className={"col-sm-12"}>
                    Branch:<strong className={"mx-2"}>{get(formState, "hub_settlement_report.branch", "") === "" ? "All" : get(formState, "hub_settlement_report.branch", "")?.split("/")[1]}</strong>
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div className={"d-flex justify-content-end"}>
                  <BackBtnComponent path={"/reports/hub-settlement-report"} />
                </div>
              </Col>
            </Row>

            <div className={"row mt-3"}>
              <CrudComponent
                title={"Hub Settlement Transaction Report"}
                apiURL={`${hubSettlementReportAPI.url}?page=1&limit=20${this.getQueryString()}`}
                dataTableStateKey={hubSettlementReportAPI.key}
                isFilter={false}
                isSetCreateAction={false}
                isDropdownActionBtn={false}
                isSetAction={false}
                tableHeaderList={[
                  {
                    displayName: "Settlement Date",
                    key: "settlementDate",
                    onPrintFn: (rawData) =>
                      dateObjectToString(
                        rawData.settlementDate,
                        "YY-mm-dd"
                      ),
                  },
                  {
                    displayName: "Banked Date",
                    key: "settledAt",
                    onPrintFn: (rawData) =>
                      !isEmptyValue(get(rawData, "settledAt")) ? dateObjectToString(
                        rawData.settledAt,
                        "YY-mm-dd"
                      ) : "-",
                  },
                  { displayName: "Delivery Center Code", key: "settleFromFacility.code" },
                  { displayName: "Delivery Center Name", key: "settleFromFacility.name" },
                  {
                    displayName: "Transaction Number", key: "reference",
                    onPrintFn: (rawData) => <button className="hub_settlement_list_button" onClick={() => this.getCsv(rawData.id)}>{rawData.reference}</button>
                  },
                  {
                    displayName: "Amount",
                    key: "total",
                    onPrintFn: (rawData) => {
                      return rawData.total === null ? 0 : rawData.total;
                    },
                  },
                ]}
                tableBodyLastComponent={TableLastRow}
                tableBodyLastProps={get(coreState, `dataTableResponses.${hubSettlementReportAPI.key}.totalsRow`, {})}
                isForceShowLoader={this.state.loaderStatus}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.FormData,
    coreState: state.CoreState,
  };
};

export default connect(mapStateToProps, null)(HubSettlementReportView);
