import {
    setToggleSideMenuKey, setPageLoaderKey, setRouteKey,
    setFlashMessageKey, removeFlashMessageKey, setSideMenuStatusKey,
    addFlashMessageKey, setPickRequestCountKey, getPickRequestCountKey
} from "./actionTypes";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";


export const addFlashMessage = (messageObject) => {
    return {
        type: addFlashMessageKey,
        payload: messageObject
    }
}

export const setFlashMessage=(messageObject)=>{

    const key=dateObjectToString(new Date(),"YYmmddHHMMII");

    // setTimeout(()=>{
    //     removeFlashMessage(key);
    // }, 5000);

    return {
        type:setFlashMessageKey,
        playload:{
            ...messageObject,
            key:key
        }
    }
}

export const removeFlashMessage=(key=null)=>{
    return {
        type:removeFlashMessageKey,
        key:key
    }
}

export const toggleSideMenu=()=>{
   return {
       type:setToggleSideMenuKey
   }
}

export const setSideToggleStatus=(status)=>{
    return {
        type:setSideMenuStatusKey,
        playload:status
    }
}

export const setPageLoader=(status)=>{
    return {
        type:setPageLoaderKey,
        playload:status
    }
}

export const setCurrentRouteFn=(key)=>{
    return {
        type:setRouteKey,
        playload:key
    }
}

export const setPickRequestCountFn = (value) => {
    return {
        type: setPickRequestCountKey,
        payload: value
    }
}

export const getPickRequestCountFn = (facilityId) => {
    return {
        type: getPickRequestCountKey,
        facilityId: facilityId
    }
}
