import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";

import InputBoxWithState from "../../../components/boda-boda/Forms/InputBoxWithState";
import { InputButton } from "../../../components/boda-boda/Forms";
import FormWrapper from "../../../components/boda-boda/Forms/FormWrapper";
import { onLoginFn } from "../../../store/actions";
import Loader from "../../../components/Loader";
import { SnackbarList } from "../../../components/boda-boda/Snackbar/Snackbar";
import logo from "../../../assets/images/boda-logo1.png";


class LoginPage extends Component {
  listener = (event) => {
    let { formData, onLoginFn } = this.props;

    if (event.code === "Enter" || event.code === "NumpadEnter") {
      onLoginFn(get(formData, 'loginFrom', {}), 'loginFrom');
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.listener);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.listener);
  }

  render() {
    let { formData, onLoginFn, uiState } = this.props;

    return (
      <React.Fragment>
        {
          (uiState.setPageLoader === true) ? (
            <Loader />
          ) : null
        }

        <SnackbarList />

        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">

                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Welcome Back !
                        </h5>
                        <p className="text-white-50">
                          Sign in to continue to Boda-Boda.
                        </p>
                        {/* <Link to="/" className="logo logo-admin"> */}
                        <div className={"custom-logo-wrapper"}>
                          <img src={logo} alt="logo" />
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">

                        <div className="form-horizontal mt-4">

                          <FormWrapper
                            setGroupName={`loginFrom`}
                          >
                            <InputBoxWithState
                              labelText="Credential"
                              formGroupName={`loginFrom`}
                              inputName={"credential"}
                              placeHolderText={"Credential"}
                              maxLength={50}
                              //cacheLevel={formCacheLevel.updateOnIndividual}
                            />

                            <InputBoxWithState
                              labelText="Password"
                              inputType={"password"}
                              formGroupName={`loginFrom`}
                              inputName={"password"}
                              placeHolderText={"Password"}
                              //cacheLevel={formCacheLevel.updateOnIndividual}
                            />
                          </FormWrapper>


                          <Row className="form-group">
                            <Col sm={6}>
                            </Col>
                            <Col sm={6} className="text-right">
                              <InputButton
                                btnText={"Log In"}
                                elementStyle="btn btn-primary w-md waves-effect waves-light"
                                onClickBtnFn={() => onLoginFn(get(formData, 'loginFrom', {}), 'loginFrom')}
                              />

                            </Col>
                          </Row>
                          <Row className="form-group mt-2 mb-0">
                            <div className="col-12 mt-4">
                              <Link to="/forgot-password">
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => {
  return {
    formData: state.FormData,
    uiState: state.UIState
  };
};

export default connect(mapStatetoProps, {
  onLoginFn
})(LoginPage);
