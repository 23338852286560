import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    SubmitButton
} from "../../../../../components/boda-boda/Forms";
import {
    getAllFacilityAPI,
    merchantBranchAPI,
    getCityListAPI,
    merchantBranchContactAPI, getFacilityAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { CreateUI } from "./common-includes/common-includes";
import { CreateMerchantBranchContactBtn } from "./common-includes/CreateContactBtn";
import { RemoveBtn, RemoveMessage } from "./common-includes/DeleteContactUI";
import {currency} from "../../../../../config/boda-boda/db_config/db_status.config";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setPageLoader } from "../../../../../store/boda-boda/ui/actions";
import { changeInputFn } from "../../../../../store/actions";
import {getDataByFormObject} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


class EditUI extends Component {
    constructor(props) {
        super(props);
    }

    facilityChange = async (eventData) => {
        let { setPageLoader, changeInputFn } = this.props;
        setPageLoader(true);

        const response = await callApi(`${getFacilityAPI.url}/${eventData.value}`)
            .headers(true)
            .method('get')
            .send()

        if (response._statue === true) {
            const pickupCost = get(response, 'data.data.pickupCost', undefined);
            changeInputFn(`${merchantBranchAPI.key}_create`, 'pickupPrice', '', pickupCost);
            setPageLoader(false);
        }
    }

    render() {
        let { history, match } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Update Merchant Branch Details</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                id={match.params.id}
                                historyFn={history}
                                merchantId={match.params.merchantId}
                            />
                            <BackBtnComponent
                                path={`/merchant/${match.params.merchantId}/branch`}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${merchantBranchAPI.key}_create`}
                            apiUrl={`${merchantBranchAPI.url}/${match.params.id}`}
                            onDestroyUnsetFormObject={true}
                            onRebuildResponseFn={(response) => {
                                return {
                                    name: response.name,
                                    facility: {
                                        id: response.facility.id
                                    },
                                    location: {
                                        name: response.location.name,
                                        address: response.location.address,
                                        city: {
                                            id: response.location.city.id
                                        },
                                    },
                                    status: response.status,
                                    pickupPrice: response.pickupPrice,
                                    "_onLoad": false,
                                }
                            }}
                        >
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className={"row"}>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Branch Name"
                                                        placeHolderText={"Branch Name"}
                                                        inputName={"name"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Status"
                                                        placeHolderText={"Status"}
                                                        inputStatePath={`${merchantBranchAPI.key}_create.status`}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        dataList={[
                                                            {
                                                                id: "ACTIVE",
                                                                value: "Active"
                                                            },
                                                            {
                                                                id: "INACTIVE",
                                                                value: "Inactive"
                                                            },
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Address"
                                                        placeHolderText={"Address"}
                                                        inputStatePath={`${merchantBranchAPI.key}_create.location.address`}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                    />
                                                </div>


                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="City"
                                                        placeHolderText={"City"}
                                                        inputStatePath={`${merchantBranchAPI.key}_create.location.city.id`}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        apiUrl={`${getCityListAPI.url}/all`}
                                                        apiStateKey={getCityListAPI.key}
                                                        keyName={"id"}
                                                        valueName={"name"}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Delivery Center"
                                                        placeHolderText={"Delivery Center"}
                                                        inputStatePath={`${merchantBranchAPI.key}_create.facility.id`}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        apiUrl={getAllFacilityAPI.url}
                                                        apiStateKey={getAllFacilityAPI.key}
                                                        keyName={"id"}
                                                        valueName={"name"}
                                                        onChangeFn={(eventData) => this.facilityChange(eventData)}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText={`PickUp Price (${currency})`}
                                                        placeHolderText={"PickUp Price"}
                                                        inputName={"pickupPrice"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"row"}>
                                                <CrudComponent
                                                    title={"Contact Details"}
                                                    isFilter={false}
                                                    dataTableStateKey={merchantBranchContactAPI.key}
                                                    apiURL={`${merchantBranchContactAPI.url}?merchantBranchId=${match.params.id}&limit=20&page=1`}
                                                    createActionProps={{
                                                        actionType: actionBtnTypes.other,
                                                        actionBtn: {
                                                            color: "primary",
                                                            icon: "mdi mdi-account-box-multiple",
                                                            btnText: "Add New",
                                                            tooltip: "Add New",
                                                        },
                                                        isSetModel: true,
                                                        modalProps: {
                                                            modalTitle: "Add Contact",
                                                            modalBodyComponent: CreateUI,
                                                            modalFooterComponent: CreateMerchantBranchContactBtn,
                                                            isSetClose: true,
                                                            isSetFooterClose: true,
                                                            parentToModelProps: {
                                                                merchantBranchId: match.params.id,
                                                            }
                                                        }
                                                    }}
                                                    tableHeaderList={[
                                                        { displayName: "Contact Person", key: "fullName" },
                                                        { displayName: "Phone No.", key: "phone" },
                                                        { displayName: "Email", key: "email" },
                                                        {
                                                            displayName: "Receive Notification", key: "notification", onPrintFn: (rawData) => {
                                                                return rawData.notification === true ? "Yes" : "No"
                                                            }
                                                        },
                                                    ]}
                                                    actionList={[
                                                        {
                                                            actionType: actionBtnTypes.delete,
                                                            isSetModel: true,
                                                            modalProps: {
                                                                modalTitle: "Please Confirm",
                                                                modalType: "",
                                                                modalBodyComponent: RemoveMessage,
                                                                modalFooterComponent: RemoveBtn,
                                                                isSetClose: true,
                                                                isSetFooterClose: true,
                                                            }
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        )
    }
}

const CreateBtn = (props) => {

    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${merchantBranchAPI.key}_create`}
            dataTableKey={merchantBranchAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    "facility.id": "Delivery Center",
                    "location.address": "Address",
                    "location.city": "City",
                    "status": "Status",
                    "pickupPrice": "Pick Up Price"
                },
                rules: {
                    name: "required",
                    "facility.id": "required",
                    "location.address": "required",
                    "location.city": "required",
                    "status": "required",
                    "pickupPrice": "required"
                },
                message: {}
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Merchant Branch Details has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={() => {
                return {
                    url: `${merchantBranchAPI.url}/${props.id}`,
                    key: merchantBranchAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                delete data.location.name
                return {
                    ...data,
                    pickupPrice: parseFloat(data.pickupPrice),
                    location: {
                        name: data.name,
                        ...data.location
                    }
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push(`/merchant/${props.merchantId}/branch`);
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const EditUIWithState = connect(mapStateToProps, { changeInputFn, setPageLoader })(EditUI)

export {
    EditUIWithState
}