import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { getTillList, getUserDriverList } from "../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { FormWrapper } from "../../../components/boda-boda/Forms";
import { connect } from "react-redux";
import { formatToDateHelper, isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../components/boda-boda/CommonElements/BaseElements";


class Till extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { match, history, formState } = this.props;

    return (
      <React.Fragment>
        <FormWrapper
          setGroupName={`${getUserDriverList.key}_till`}
          onDestroyUnsetFormObject={true}
          apiUrl={`${getUserDriverList.url}/${match.params.id}`}
          onRebuildResponseFn={(response) => {
            console.log(response)
            return {
              id: response.id,
              name: response.name,
              tills: response.tills[0],
              "_onLoad": false,
            }
          }}
        >
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={3}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Till Management</h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Till Management</li>
                  </ol>
                </div>
              </Col>
              <Col sm={9}>
                <div className={"d-flex justify-content-end"}>
                  <BackBtnComponent
                    elementWrapperStyle={"mr-2"}
                    path={`/users/driver/view/${match.params.id}`}
                  />
                </div>
              </Col>
            </Row>

            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className="card mb-1">
                  <div className="card-body">
                    <div className={"row"}>
                      <div className={"col-md-6"}>

                        <dl className={"row mb-0"}>
                          <dd className={"col-sm-4"}>Driver ID</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_till.id`, "-")}</dt>
                        </dl>

                        <dl className={"row mb-0"}>
                          <dd className={"col-sm-4"}>Driver Name</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_till.name`, "-")}</dt>
                        </dl>
                      </div>
                      <div className={"col-md-6"}>
                        <dl className={"row mb-0"}>
                          <dd className={"col-sm-4"}>Total Pending Settlement</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_till.tills.balanceDue`, 0)}</dt>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Row>
              <CrudComponent
                title="Till Management"
                filterFromComponent={FilterUI}
                apiURL={`${getTillList.url}?page=1&limit=20&driverId=${match.params.id}`}
                dataTableStateKey={getTillList.key}
                defaultFilterFormObject={{ type: "", dateTo: "", dateFrom: "" }}
                onRebuildFilterObjFn={(filterData) => {
                  const data = filterData;

                  if (!isEmptyValue(filterData.dateTo)) {
                    data["dateTo"] = formatToDateHelper(filterData.dateTo);
                  }
                  if (!isEmptyValue(filterData.dateFrom)) {
                    data["dateFrom"] = filterData.dateFrom.toISOString();
                  }

                  return data;
                }}
                createActionProps={{
                  actionType: actionBtnTypes.create,
                  isSetModel: false,
                  onClickBtnFn: () => history.push(`/users/driver/view/${match.params.id}/till-management-create`)
                }}
                tableHeaderList={[
                  { displayName: "Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt, "dd-mm-YY") },
                  { displayName: "Time", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt, "HH:MM:II") },
                  {
                    displayName: "Type", key: "type", onPrintFn: (rawData) => {
                      return (rawData.type === "BALANCE_UPDATE") ? "DRIVER CASH FLOAT" : (rawData.type === "DELIVERY") ? "CASH COLLECTED" : "SETTLEMENT";
                    }
                  },
                  { displayName: "Transaction By", key: "creator.staffs[0].name" },
                  { displayName: "Amount", key: "amount" },
                  { displayName: "Remarks", key: "note", default: "-" },
                ]}
                isSetAction={false}
              />
            </Row>


          </div>
        </FormWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData,
  };
};

export default connect(mapStateToProps, null)(Till);
