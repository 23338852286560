/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-02 16:22:03
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-02 17:25:19
 */
import React, { Component, Fragment,useState } from "react";
import { Col, Label, Row } from "reactstrap";
import { get, some } from "lodash";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../components/boda-boda/CRUD/CrudComponent";
import {
    
    FormWrapper,
    InputBoxWithState, SubmitButton,SelectBoxWithState,InputButton,InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import FilterUI from "../../collections/collection-includes/includes/PackageFilterUI";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import {
    
    getAllFacilityAPI,
    getPackageListAPI,
    getManifestListAPI,
    getAllManifestListAPI,
    getMerchantBranchAllAPI,
} from "../../../../config/boda-boda/apiUrls.config";
import { mergedDataTableResult, requestAPICallFn } from "../../../../store/boda-boda/core/actions";
import { updateManifestId } from "../../../../store/boda-boda/transaction_request/actions";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { updateManifestIdTrKey ,updateManifestIdAddPackageTrKey} from "../../../../store/boda-boda/transaction_request/actionTypes";
import { currency, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";


const Message = (props) => {
    return(
        <p> This package is already Sorted. Do you want to continue?</p>
    );
};

const UpdateBtn = (props) => {

    let { history, location, match , row } = props;
    const dispatch = useDispatch();
    const formState = useSelector(state => state.FormData);

    const modalCloseFn = () => {
        props.setModelStatusFn(false);
      };
    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Yes"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={"editCollection"}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: updateManifestIdAddPackageTrKey,
                    payload :{
                        "manifest": {"id": Number(get(formState, "editCollection.id", "")),},
                        "status": "SORTED",
                        "id": props.row.id,
                        //"driver": null,
                        "locationPick" :  {"id": get(formState, "editCollection.locationPick.id", "")},
                        "locationDrop" :  {"id":get(formState, "editCollection.locationDrop.id", "")},
                        "locationCurrent" :{"id": get(formState, "editCollection.locationCurrent.id", "")}
    
                    }
                })
            }}
        />
    )
}

class CreateCollectiono extends Component {
    constructor(props) {
        super(props);
        this.state = {
           // modal: false,
            isScan: false,
            isOpen: false
        };
    }


    addPackage = (row) => {
        console.log("iiiiiii", row);
        
                let body = {
                    "manifest": {"id": Number(this.props.match.params.id),},
                    "status": "SORTED",
                    //"driver": null,
                    "locationPick" :  {"id": get(this.props.formState, "editCollection.locationPick.id", "")},
                    "locationDrop" :  {"id":get(this.props.formState, "editCollection.locationDrop.id", "")},
                    "locationCurrent" :{"id": get(this.props.formState, "editCollection.locationCurrent.id", "")}

                }

        const flashMsjObj = {
            status: true,
            message: "Package has successfully added",
            type: "success"
        };

        this.props.updateManifestId(row.id, body, flashMsjObj);
    }

    deletePackage = (row) => {
        const body = {
            "manifest": null
        }

        const flashMsjObj = {
            status: true,
            message: "Package has successfully removed",
            type: "success"
        };

        this.props.updateManifestId(row.id, body, flashMsjObj);
    }

    render() {
        let { match, formState, coreState, history, location, row } = this.props;
        
        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={"editCollection"}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${match.params.id}/compressed`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code:response.code,
                            status: response.status,
                            locationId: get(response, "locationCurrent.id", ""),
                            locationDropId: response.locationDropId,
                            locationName: get(response, "locationCurrent.name", ""),
                            locationPick: get(response, "locationPick", ""),
                            locationDrop: get(response, "locationDrop", ""),
                            facility: get(response, "facility", ""),
                            locationCurrent: get(response, "locationCurrent", ""),
                            packages: get(response, "packages", []),
                            notes: response.notes,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">
                                    {
                                        (location.pathname.split('/')[3] === "create") ? "Create Collection" : "Edit Collection"
                                    }
                                </h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <CreateBtn
                                        history={history}
                                        location={location}
                                        id={match.params.id}
                                    />
                                    <CancelUIModal
                                    />

                                    
                                </Fragment>
                            </div>

                        </Col>

                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                <div className="row">
                                        
                                        <div className="col-md-6">
                                            <Label>Collection Code: {get(formState, "editCollection.code", "")}</Label>
                                        </div>
                                        <div className="col-md-6">
                                            <Label>Status: {
                                                    get(formState, "editCollection.status", "") === "DRAFT" ? "Open" 
                                                    :  get(formState, "editCollection.status", "") === "PUBLISH" ? "Closed" 
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "") === "IN_TRANSIT_LINE" ? "Intransit to regional hub"
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "") === "COMPLETED" ? "Delivered to regional hub"
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "")}
                                            </Label>
                                        </div>
                                        <div className="col-md-6">
                                            <Label>Pick Up Location: {get(formState, "editCollection.locationPick.name", "-")}</Label>
                                        </div>
                                       
                                        { 
                                        
                                            !(get(formState, "editCollection.status", "") === "DRAFT" && get(formState, "editCollection.packages", []).length === 0)  ?(
                                            <div className="col-md-6">
                                                <Label>Destination Delivery Center: {get(formState, "editCollection.locationDrop.name", "-")}</Label>
                                            </div>
                                            ):null
                                        }
{/* 
                                        { 
                                        
                                            get(formState, "editCollection.status", "") != "DRAFT" ?( */}
                                            
                                                <div className="col-md-6">
                                                    <Label>Current Location: {get(formState, "editCollection.locationCurrent.name", "-")}</Label>
                                                </div>
                                            {/* ):null
                                         } */}

                                        {/* { 
                                        
                                        !(get(formState, "editCollection.status", "") === "DRAFT" && (get(formState, "editCollection.packages", []).length === 0) )?( */}

                                                <div className="col-md-6">
                                                    <Label>Drop Location: {get(formState, "editCollection.locationDrop.name", "")}</Label>
                                                </div>
                                            {/* ):null
                                        } */}

                                        
                                        
                                        

                                    </div>
                                    <br/>
                                    {
                                    
                                        get(formState, "editCollection.status", "") === "DRAFT" && (get(formState, "editCollection.packages", []).length === 0) ?(
                                        <div className="row">
                                           <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Drop Location"}
                                                    placeHolderText={"Drop Location"}
                                                    inputName={"locationDrop"}
                                                    inputStatePath={"editCollection.locationDrop.id"}
                                                    formGroupName={"editCollection"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={getAllFacilityAPI.key}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(data) => {
                                                        const temp = [];

                                                        data.forEach((value) => {
                                                            temp.push({
                                                                id: value.location.id,
                                                                name: value.location.name
                                                            })
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": temp
                                                            }
                                                        }
                                                    }}
                                                    />
                                             </div>
                                        </div>
                                        
                                        ):null

                                    }

                                    <div className="row">
                                    </div>
                                </div>
                         </div>

                        </div>
                    </div>
                    <div className={"row"}>
                            <div className="col-md-12 padding-none">
                                <CrudComponent
                                    title="Packages"
                                    apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=null&statuses=["SORTED" , "RECEIVED"]&locationFinalFacilityId=${get(formState, "editCollection.locationDropId", "")}`}
                                    dataTableStateKey={getPackageListAPI.key}
                                    defaultFilterFormObject={{
                                        articleNo: "",
                                        name: "",
                                        merchantId: "",
                                        deliveryType: "",
                                        paymentMethod: "",
                                        category: "",
                                        pickupLocation: ""
                                    }}
                                    filterFromComponent={FilterUI}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={true}
                                    tableHeaderList={[
                                        { displayName: "Article No", key: "articleNo" },
                                        { displayName: "Channel Order No", key: "order.channelOrderNo" },
                                        { displayName: "Customer Name", key: "order.name" },
                                        { displayName: "Customer Address & City", key: "order.shippingLocation.address", onPrintFn: (rawData) => `${rawData?.order?.shippingLocation.address},${rawData?.order?.shippingLocation.city.name}` },
                                        { displayName: "Contact Number", key: "order.phone" },
                                        { displayName: "Payment Method", key: "order.paymentMethod" },
                                        { displayName: "Delivery Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                        { displayName: `COD Amount (${currency})`, key: "order.amount" },
                                    ]}
                                    actionList={[
                                        {
                                            actionType: actionBtnTypes.view,
                                            isSetModel: {

                                            },
                                            actionBtn: {
                                                onClickBtnFn: (rawData) => history.push(`/packages/view/${rawData.id}`),
                                            }
                                        },

                                        {
                                            actionType: actionBtnTypes.create,
                                            isSetModel: true,
                                            actionBtn: {
                                                btnText:"Select",
                                                onClickBtnFn: (data) => this.addPackage(data)
                                                
                                            },
                                            modalProps: {
                                                modalTitle: "Please Confirm",
                                                modalType:  true,
                                                modalBodyComponent: Message,
                                                modalFooterComponent: UpdateBtn,
                                                isSetClose: true,
                                                isSetFooterClose: true,
                                                // parentToModelProps: {
                                                //     type: "datatable",
                                                //     status: "success",
                                                //     //flashMessage: "Runsheet has successfully cancelled"
                                                // },
                                                closeBtnName: "Cancel"
                                            },
                                            checkBeforeActionRenderFn: (rawData) => {
                                                if (rawData.rowData.status === "SORTED") {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }

                                        },
                                        {
                                            actionType: actionBtnTypes.create,
                                            isSetModel: false,
                                            actionBtn: {
                                                btnText:"Select",
                                                onClickBtnFn: (data) => this.addPackage(data)
                                                
                                            },
                                            checkBeforeActionRenderFn: (rawData) => {
                                                if (rawData.rowData.status !== "SORTED") {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }

                                        }
                                    ]}
                                />
                            </div>
                        
                    
                    </div>

                    <div className={"row"}>
                        
                        <CrudComponent
                            title="Selected Packages"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}&statuses=["SORTED" ,statuses="RECEIVED"]&locationFinalFacilityId=${get(formState, "editCollection.locationDropId", "")}`}
                            dataTableStateKey={`${getPackageListAPI.key}_manifest`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={true}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Channel Order No", key: "order.channelOrderNo" },
                                { displayName: "Customer Name", key: "order.name" },
                                { displayName: "Customer Address & City", key: "order.shippingLocation.address", onPrintFn: (rawData) => `${rawData?.order?.shippingLocation.address},${rawData?.order?.shippingLocation.city.name}` },
                                { displayName: "Contact Number", key: "order.phone" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Delivery Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                { displayName: `COD Amount (${currency})`, key: "order.amount" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: false,
                                    actionBtn: {
                                        
                                        onClickBtnFn: (data) => {
                                            this.deletePackage(data)}
                                    }
                                },
                            ]}
                        />
                    </div>
                    <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <div className={"row"}>
                                    <div className="col-md-6">
                                        <label>Notes</label>
                                        {
                                            get(formState, "editCollection.notes", []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, "editCollection.notes", []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                    <div>None</div>
                                                )
                                        }
                                    </div>

                                    <div className="col-md-6"> 
                                        <InputBoxWithState
                                            labelText={"New Note"}
                                            inputName={"note"}
                                            inputType={"textarea"}
                                            rows={3}
                                            formGroupName={"editCollection"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </FormWrapper>

            </div>
        );
    }
}

const CancelUIModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <BackBtnComponent
                path={"/collection"}
                btnName={"Cancel"}
                icon={"far fa-times-circle"}
                btnColor={"danger"}
                isModal={true}
            />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState,
        authState: state.AuthState
    };
};

const CreateCollection = withRouter(CreateCollectiono);
const UpdateBtn1 = withRouter(UpdateBtn);


const CreateBtn = (props) => {
    const authState = useSelector(state => state.AuthState);

    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={"editCollection"}
            dataTableKey={getManifestListAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    "facility.id": "Destination Delivery CenterDestination Delivery Center",
                    //"note": "Special Notes"
                },
                rules: {
                    "facility.id": "required",
                },
                message: {}

            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: props.location.pathname.split('/')[3] === "edit" ? "Collection has successfully updated" : "Collection has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getManifestListAPI.url}/${props.id}`,
                    key: getManifestListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                
                let data = getDataByFormObject(formObject);
                console.log("check this", data);
                
                let res = {
                    //...data,
                    locationDrop: {
                        id: data.locationDrop.id
                    },
                    // locationPick: {
                    //     id: data.locationPick.id
                    // },
                    // locationCurrent: {
                    //     id: data.locationDrop.id
                    // },
                    facility: {
                        id: data.locationPick.id
                    },
                    notes: [{
                        note: data.note
                            }]
                }
                res["notes"] = [...data.notes];
                if (!isEmptyValue(get(data, 'note', ""))) {
                    res["notes"] = [
                        ...data.notes,
                        {
                            "name": get(authState, "authUser.role.name", ""),
                            "at": new Date(),
                            "note": data.note,
                            "createdBy": get(authState, "authUser.id", "")
                        }
                    ]
                }
                console.log("999",data);

                return res;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.location.pathname.split('/')[3] === "edit") {
                        props.history.goBack();
                    } else {
                        props.history.push(`/collection`);
                    }
                }
            }}
        />
    )
}

export default connect(mapStateToProps, {
    mergedDataTableResult,
    requestAPICallFn,
    updateManifestId
})(CreateCollection,UpdateBtn1);
