import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import formSaga from "./boda-boda/form/saga";
import coreSaga from "./boda-boda/core/saga";
import authUserSaga from "./boda-boda/auth/saga";
import uiSaga from "./boda-boda/ui/saga";
import transactionSaga from "./boda-boda/transaction_request/saga";


export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        formSaga(),
        coreSaga(),
        authUserSaga(),
        uiSaga(),
        transactionSaga(),
    ])
}
