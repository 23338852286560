import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { FormWrapper, InputButton, SubmitButton } from "../../../../components/boda-boda/Forms";
import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getCityListAPI, getZoneAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";


class ViewUI extends Component {
    render() {
        let { history, match, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getZoneAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getZoneAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            code: response.code,
                            status: response.status,
                            facility: get(response, "facility.name", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Zone Details</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    isGoBack={true}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/zones/edit/${match.params.id}`)}
                                />

                                {/* <InputButton
                                    btnText={"Delete"}
                                    btnColor={"danger"}
                                    startIcon={"mdi mdi-trash-can-outline"}
                                /> */}
                                <SubmitButton
                                    btnText={"Delete"}
                                    elementStyle={"btn btn-danger  waves-effect waves-light"}
                                    dataTableKey={getZoneAPI.key}
                                    isValidate={false}
                                    callApiObject={{
                                        isSetHeaders: true,
                                        multipart: false,
                                        method: "delete",
                                        onUpload: false
                                    }}
                                    apiDataStoringObject={{
                                        setLoader: true,
                                        storingType: apiResponseStoringType.setResponse,
                                        mergeToSuccessResponse: null,
                                        mergeToErrorResponse: null,
                                    }}
                                    onGetAPIEndPointFn={() => {
                                        return {
                                            url: `${getZoneAPI.url}/${match.params.id}`,
                                            key: getZoneAPI.key
                                        }
                                    }}
                                    onResponseCallBackFn={() => {
                                        history.push(`/zones`);
                                    }}
                                    flashMessages={{
                                        "success": {
                                            status: true,
                                            message: "Zone successfully deleted",
                                            type: "success"
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <div className="card mb-1">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Zone Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getZoneAPI.key}_view.code`, "")}</dt>

                                                <dd className={"col-sm-4"}>Delivery Center</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getZoneAPI.key}_view.facility`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Zone Name</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getZoneAPI.key}_view.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Status</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getZoneAPI.key}_view.status`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <CrudComponent
                            title="Cities"
                            apiURL={`${getCityListAPI.url}?page=1&limit=20&zoneId=${match.params.id}`}
                            dataTableStateKey={`${getCityListAPI.key}`}
                            isFilter={false}
                            isSetAction={false}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "City", key: "name" },
                                { displayName: "District", key: "district.name" },
                                { displayName: "Province", key: "district.province.name" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ZoneView = connect(mapStateToProps, null)(ViewUI);

export {
    ZoneView
};
