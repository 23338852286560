import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {actionBtnTypes, CrudComponent} from "../../../../components/boda-boda/CRUD/CrudComponent";
import {getContractAPI} from "../../../../config/boda-boda/apiUrls.config";
import {dateObjectToString} from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";

class Contract extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, location} = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Contract</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={`/merchant/view/${match.params.id}`}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <CrudComponent
                            title={"Merchant Contract"}
                            apiURL={`${getContractAPI.url}?merchantId=${match.params.id}&page=1&limit=20`}
                            dataTableStateKey={getContractAPI.key}
                            isFilter={false}
                            createActionProps={{
                                actionType:actionBtnTypes.create,
                                isSetModel:false,
                                onClickBtnFn: () => history.push(`/merchant/${match.params.id}/contract/create`)
                            }}
                            tableHeaderList={[
                                { displayName: "Contract Name", key: "name"},
                                { displayName: "Contract Type", key: "pricingModelType", onPrintFn:(rawData) => rawData.pricingModelType === "TYPE_ONE" ? "Type 1" : "Type 2"},
                                { displayName: "Effective date-From", key: "start", onPrintFn: (rawData) => dateObjectToString(rawData.start, "YY-mm-dd")},
                                { displayName: "Effective date-To", key: "end", onPrintFn: (rawData) => rawData.end ? dateObjectToString(rawData.end, "YY-mm-dd") : '-'},
                                { displayName: "Status", key: "status"},
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant/${match.params.id}/contract/view/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default Contract;