import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { FormWrapper, SubmitButton } from "../../../../components/boda-boda/Forms";
import InputBoxWithState from "../../../../components/boda-boda/Forms/InputBoxWithState";
import FileUpload from "../../../../components/boda-boda/FileUpload/FileUpload";
import { merchantTemplateAPI } from "../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class MerchantTemplate extends Component {

    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={merchantTemplateAPI.key}
                    apiUrl={`${merchantTemplateAPI.url}?page=1&limit=20&merchantId=${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        if (response.length > 0) {
                            return {
                                id: get(response, "[0].id", ""),
                                logo: get(response, "[0].logo", ""),
                                headerBanner: get(response, "[0].headerBanner", ""),
                                footerContent: get(response, "[0].footerContent", ""),
                                data: true,
                                "_onLoad": false
                            }
                        } else {
                            return {
                                data: false,
                                "_onLoad": false
                            }
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Merchant Notification Template</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <SaveBtn
                                    data={get(formState, `${merchantTemplateAPI.key}.data`, false)}
                                    id={get(formState, `${merchantTemplateAPI.key}.id`, "")}
                                    merchant={match.params.id}
                                    history={history}
                                />

                                <BackBtnComponent
                                    path={`/merchant/view/${match.params.id}`}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>

                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <FileUpload
                                                        isRequired={true}
                                                        labelText={"Logo"}
                                                        formGroupName={merchantTemplateAPI.key}
                                                        formKey={"logo"}
                                                        helperText={"Supported formats (png, jpg, jpeg)"}
                                                    />
                                                </div>

                                                <div className={"col-md-6"}>
                                                    <FileUpload
                                                        isRequired={true}
                                                        labelText={"Header"}
                                                        formGroupName={merchantTemplateAPI.key}
                                                        formKey={"headerBanner"}
                                                        helperText={"Supported formats (png, jpg, jpeg)"}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className={"col-md-6"}>
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText={"Footer Content"}
                                                formGroupName={merchantTemplateAPI.key}
                                                inputName={"footerContent"}
                                                inputType={"textarea"}
                                                rows={7}
                                                maxLength={1000}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

const SaveBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${merchantTemplateAPI.key}`}
            dataTableKey={merchantTemplateAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    logo: "Logo",
                    headerBanner: "Header",
                    footerContent: "Footer Content",
                },
                rules: {
                    logo: "required",
                    headerBanner: "required",
                    footerContent: "required",
                },
                message: {}
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: props.data === false ? "Template has successfully created" : "Template has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: props.data === false ? "post" : "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                if (props.data === false) {
                    return {
                        url: `${merchantTemplateAPI.url}`,
                        key: merchantTemplateAPI.key
                    }
                } else {
                    return {
                        url: `${merchantTemplateAPI.url}/${props.id}`,
                        key: merchantTemplateAPI.key
                    }
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                return {
                    ...data,
                    merchant: {
                        id: Number(props.merchant)
                    },
                    status: "ACTIVE",
                    data: undefined,
                    id: undefined,
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.history.push(`/merchant/view/${props.merchant}`);
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
    };
};

const CreateMerchantTemplate = connect(mapStateToProps, null)(MerchantTemplate);

export {
    CreateMerchantTemplate
}
