import React from "react"
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap"
import { hubSettlementAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import MarkAsSettledBtn from "./MarkAsSettledBtn";
import { isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";

export const HubSettlementView = (props) => {
    const formState = useSelector(state => state.FormData);
    const coreState = useSelector(state => state.CoreState);
    const generatedHubSettlement = get(coreState, `apiResponses.${hubSettlementAPI.key}.result`, [])

    return (
        <React.Fragment>
            {
                (get(formState, `${hubSettlementAPI.key}.settlementDate`, "") === "") ? (
                    <Redirect to={"/hub-settlement"} />
                ) : (
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Hub Settlement</h4>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-sm-12"}>
                                        As of <strong className={"mx-2"}>{dateObjectToString(get(formState, `${hubSettlementAPI.key}.settlementDate`), "dd/mm/YY")}</strong>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className={"d-flex justify-content-end"}>
                                    <div className="mt-2 mr-2"><strong className={"mx-2"}>{`Total: ${generatedHubSettlement.total}`}</strong></div>
                                    <div className="mr-2">
                                        <MarkAsSettledBtn settled={generatedHubSettlement?.settled} />
                                    </div>
                                    <BackBtnComponent
                                        path={"/hub-settlement"}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className={"row mt-3"}>
                            <CrudComponent
                                title={"Hub Settlement"}
                                tableBodyList={[generatedHubSettlement]}
                                dataTableStateKey={hubSettlementAPI.key}
                                isFilter={false}
                                isPagingShow={false}
                                isSetCreateAction={false}
                                isDropdownActionBtn={false}
                                isSetAction={false}
                                tableHeaderList={[
                                    { displayName: "Reference Number", key: "reference" },
                                    { displayName: "Report Run Date", key: "settlementDate", onPrintFn: (rawData) => dateObjectToString(rawData.settlementDate, "YY-mm-dd") },
                                    { displayName: "Banked Date", key: "settledAt", onPrintFn: (rawData) => !isEmptyValue(rawData?.settledAt) ? dateObjectToString(rawData.settledAt, "YY-mm-dd") : "-"},
                                    { displayName: "Delivery Center Code ", key: "settleFromFacility.code" },
                                    { displayName: "Delivery Center Name", key: "settleFromFacility.name" },
                                    { displayName: "COD Status", key: "settled", onPrintFn: (rawData => rawData.received === true ? 'Received' : rawData.settled === true ? 'Settled' : 'Pending') },
                                    { displayName: "Amount", key: "total" },
                                ]}
                            />
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}