import { put, call } from 'redux-saga/effects';
import validateMethod from "../../../helpers/boda-boda/validation";
import { get } from "lodash";
import {setFormErrorFn} from "../form/actions";
import {createAuthUserAPI, getRoleListAPI, getUserStaffList} from "../../../config/boda-boda/apiUrls.config";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {defaultCountryCode} from "../../../config/boda-boda/core.config";
import parsePhoneNumber from "libphonenumber-js";
import { isEmptyValue } from '../../../helpers/boda-boda/common-helpers/common.helpers';

const emptyFn = (...para) => undefined;

function* createMerchantAdminUserFn(data) {

    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            identification: "Identification",
            email: "Email",
            credential: "Credential",
            password: "Password",
            confirm_password: "Confirm Password",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            identification: "required|min:8|max:20",
            email: "email|max:50",
            credential: "required|email|max:100",
            password: "required|passwordPolicy",
            confirm_password: "required|same:password",
            "countryCode.id": "required"
        },
        {
            "confirm_password.same": "Passwords do not match"
        },
    ));

    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${getUserStaffList.key}_create`, validationResponse.errors));

    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_create`, []));

        const role = yield call( () => callApi(`${getRoleListAPI.url}/all?isDefault=true&type=MERCHANT`)
            .headers(true)
            .method("get")
            .send())

        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            credential: get(data, "payload.credential", ""),
            password: get(data, "payload.password", ""),
            merchant: {
                id: Number(get(data, "payload.merchant", "")),
            },
            role: {
                id: get(role, "data.data[0].id", ""),
            },
            canSwitch: false
        };

        const userAPIRequest = yield call(() => callApi(createAuthUserAPI.url).headers(true).method("post").body(userAPIRequestBody).send());

        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                user: {
                    id: get(userAPIRequest, "data.data.id", ""),
                },
                merchant: {
                    id: Number(get(data, "payload.merchant", "")),
                },
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                category: "MERCHANT",
                status: "ACTIVE",
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            const staffAPIRequest = yield call(() => callApi(getUserStaffList.url).headers(true).method("post").body(staffAPIRequestBody).send());

            if (staffAPIRequest._statue === false) {
                yield call(() => callApi(`${createAuthUserAPI.url}/${get(userAPIRequest, "data.data.id", "")}`).headers(true).method("delete").send());

                if (get(staffAPIRequest, "data.code", null) === 400){
                    yield put(setFormErrorFn(
                        `${getUserStaffList.key}_create`,
                        get(staffAPIRequest, "data.errors", [])
                    ))
                }
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Merchant admin user has successfully created",
                    type: "success",
                }));
                get(data, "payload.historyFn.push", emptyFn)(`/merchant/${get(data, "payload.merchant", "")}/admin-users/`);
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(
                    `${getUserStaffList.key}_create`,
                    get(userAPIRequest, "data.errors", [])
                ))
                yield put(
                    setFlashMessage({
                        status: true,
                        message: get(userAPIRequest, "data.errors[0].error", "Something went wrong!"),
                        type: "danger",
                    }));
            }
        }
    }
    yield put(setPageLoader(false));
}

function* updateMerchantAdminUserTrFn(data)  {

    let countryCode = get(data, 'payload.countryCode.id', "");
    
    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            identification: "Identification",
            email: "Email",
            status: "Status",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            identification: "required|min:8|max:20",
            email: "required|email|max:50",
            status: "required",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
        },
        {},
        )
    );
    
    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, validationResponse.errors));

    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, []));

        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            status: get(data, "payload.status", ""),
        };
        
        const userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
            .headers(true)
            .method("put")
            .body(userAPIRequestBody)
            .send()
        );
        
        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                status: get(data, "payload.status", ""),
                identification: get(data, "payload.identification", ""),
                // merchantBranch: {
                //     id: parseInt(get(data, "payload.merchantBranch", "")),
                // },
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                countryCode: {
                    id: parseInt(get(countryCode, "[0]"))
                },
                countryCodePrefix: get(countryCode, "[2]")
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            const staffAPIRequest = yield call(() => callApi(`${getUserStaffList.url}/${get(data, "payload.id")}`)
                .headers(true)
                .method("put")
                .body(staffAPIRequestBody)
                .send()
            );

            if (staffAPIRequest._statue === false) {
                if (get(staffAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, get(staffAPIRequest, "data.errors", [])));
                }

                yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
                    .headers(true)
                    .method("put")
                    .body({
                        email: get(data, "payload.authEmail", "")
                    })
                    .send()
                );
            } else {
                get(data, "payload.historyFn.push", emptyFn)(`/merchant/${get(data, "payload.merchantId", "")}/admin-users`);
                
                yield put(setPageLoader(false));
                yield put(setFlashMessage({
                    status: true,
                    message: "Admin user has successfully updated",
                    type: "success",
                }));
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, get(userAPIRequest, "data.errors", [])));
            } else if (get(userAPIRequest, "data.statusCode", null) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger"
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger"
                }));
            }
        }
    }

    yield put(setPageLoader(false));
}
  
export {
    createMerchantAdminUserFn,
    updateMerchantAdminUserTrFn
}