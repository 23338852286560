import React from "react"
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap"
import { hubSettlementAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { useSelector } from "react-redux";
import { convertToIST, fromObjectToQueryString } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { get } from "lodash";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";

export const HubSettlementBalanceView = (props) => {
    const formState = useSelector(state => state.FormData);

    const getQueryString = () => {

        const settlementDateFrom = get(formState, `${hubSettlementAPI.key}_balance.settlementDate`)
        let settlementDateTo = new Date(get(formState, `${hubSettlementAPI.key}_balance.settlementDate`));
        settlementDateTo = new Date(settlementDateTo.setDate(settlementDateTo.getDate() + 1));

        const queryObj = {
            // settlementDate: get(formState, `${hubSettlementAPI.key}_balance.settlementDate`, new Date()).toISOString(),
            settlementDateFrom: convertToIST(settlementDateFrom),
            settlementDateTo: convertToIST(settlementDateTo),
            settleFromFacilityId: get(formState, `${hubSettlementAPI.key}_balance.settleFromFacilityId`),
            settleToFacilityId: get(formState, `${hubSettlementAPI.key}_balance.settleToFacilityId`),
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    return (
        <React.Fragment>
            {
                (get(formState, `${hubSettlementAPI.key}_balance.settlementDate`, "") === "") ? (
                    <Redirect to={"/hub-settlement-balance"} />
                ) : (
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Hub Settlement Balance</h4>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-sm-12"}>
                                        As of <strong className={"mx-2"}>{dateObjectToString(get(formState, `${hubSettlementAPI.key}_balance.settlementDate`), "dd/mm/YY")}</strong>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className={"d-flex justify-content-end"}>
                                    <BackBtnComponent
                                        path={"/hub-settlement-balance"}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className={"row mt-3"}>
                            <CrudComponent
                                title={"Hub Settlement Balance"}
                                apiURL={`${hubSettlementAPI.url}?page=1&limit=20${getQueryString()}`}
                                dataTableStateKey={hubSettlementAPI.key}
                                isFilter={false}
                                isSetCreateAction={false}
                                isDropdownActionBtn={false}
                                isSetAction={false}
                                tableHeaderList={[
                                    { displayName: "Delivery Center Code", key: "settleFromFacility.code" },
                                    { displayName: "Name", key: "settleFromFacility.name" },
                                    { displayName: "Amount", key: "total", },
                                    { displayName: "COD Status", key: "settled", onPrintFn: (rawData => rawData.received === true ? 'Received' : rawData.settled === true ? 'Settled' : 'Pending') },
                                ]}
                            // tableBodyLastComponent={TableLastRow}
                            // tableBodyLastProps={this.state.data}
                            // isForceShowLoader={this.state.loaderStatus}
                            />
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}