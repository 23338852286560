import React from "react";
import {SubmitButton} from "../../../../../../components/boda-boda/Forms";
import {getLastMileAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import { withRouter } from "react-router-dom";
import {apiResponseStoringType} from "../../../../../../config/boda-boda/apiResponseKey";
import {getDataByFormObject} from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { setFlashMessage } from "../../../../../../store/actions";
const CreateLastMileBtn = (props) => {

    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <SubmitButton
                btnText={"Create"}
                startIcon={"mdi mdi-content-save-all-outline"}
                formGroupName={`${getLastMileAPI.key}`}
                dataTableKey={getLastMileAPI.key}
                isValidate={true}
                validationObject={{
                    fields: {
                        name: "Route Name",
                        status: "Route Status"
                    },
                    rules: {
                        name: "required",
                        status: "required"
                    },
                    messages: {}
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: getLastMileAPI.url,
                        key: getLastMileAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);

                    return {
                        ...data
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.setModelStatusFn(false);
                        props.history.push(`/routes/last-mile/create/${result.data.id}`)
                    }
                }}
            />
        </React.Fragment>
    )
}

export default withRouter(CreateLastMileBtn)