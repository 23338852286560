import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import parsePhoneNumber from "libphonenumber-js";
import { setFlashMessage, setPageLoader } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getUserDriverList, createAuthUserAPI, getUserStaffList, getRoleAPI } from "../../../config/boda-boda/apiUrls.config";
import validateMethod from '../../../helpers/boda-boda/validation';
import { setFormErrorFn } from '../form/actions';
import { defaultCountryCode } from "../../../config/boda-boda/core.config";
import { isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";

const emptyFn = (...para) => undefined;

function* createDriverFn(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            licenseNo: "License Number",
            identification: "Identification",
            email: "Email",
            type: "Driver Type",
            credential: "Username",
            password: "Password",
            confirm_password: "Confirm Password",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
            identification: "required|min:8|max:20",
            email: "required|email|max:50",
            type: "required",
            licenseNo: "required",
            credential: "required|max:50|email",
            password: "required|passwordPolicy",
            confirm_password: "required|same:password"
        },
        {
            "credential.email": "Username must be an email",
            "confirm_password.same": "Passwords do not match"
        },
    )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getUserDriverList.key}_create`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserDriverList.key}_create`, []));

        // const userRoleRequest = yield call(() => callApi(`${getRoleAPI.url}/all?status=ACTIVE&type=DRIVER`).headers(true).method("get").send());

        let id = parseInt(get(data, "payload.role", ""));
        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            credential: get(data, "payload.credential", ""),
            password: get(data, "payload.password", ""),
            canSwitch: get(data, "payload.canSwitch", false),
            role: {
                id: id,
            }
        };

        
        try {
            const userAPIRequest = yield call(() =>
              callApi(createAuthUserAPI.url)
                .headers(true)
                .method("post")
                .body(userAPIRequestBody)
                .send()
                .then((response) => {
                  return response;
                })
                .catch((err) => {
                  console.log("error", err);
                })
                );
        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                user: {
                    id: get(userAPIRequest, "data.data.id", ""),
                },
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                category: "DRIVER",
                status: "ACTIVE",
                countryCode: {
                    id: parseInt(get(countryCode, "[0]"))
                },
                countryCodePrefix: get(countryCode, "[2]")
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            if (!isEmptyValue(get(data, "payload.image", ""))) {
                staffAPIRequestBody["image"] = get(data, "payload.image", "");
            }

            const staffAPIRequest = yield call(() => callApi(getUserStaffList.url).headers(true).method("post").body(staffAPIRequestBody).send());
            if (staffAPIRequest._statue === false) {
                if (get(staffAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(
                        `${getUserDriverList.key}_create`,
                        get(staffAPIRequest, "data.errors", [])
                    ));
                }
                    yield put(
                        setFlashMessage({
                        status: true,
                        message: get(staffAPIRequest,
                            "data.errors[0].error",
                            "Something went wrong!"),
                        type: "danger",
                        })
                        );

                    yield call(() => callApi(`${createAuthUserAPI.url}/${get(userAPIRequest, "data.data.id", "")}`).headers(true).method("delete").send());
            } else {
                const driverAPIRequestBody = {
                    name: get(data, "payload.name", ""),
                    licenseNo: get(data, "payload.licenseNo", ""),
                    identification: get(data, "payload.identification", ""),
                    user: {
                        id: get(userAPIRequest, "data.data.id", ""),
                    },
                    category: get(data, "payload.type", ""),
                    status: "ACTIVE",
                }

                const driverAPIRequest = yield call(() => callApi(getUserDriverList.url).headers(true).method("post").body(driverAPIRequestBody).send());

                if (driverAPIRequest._statue === false) {
                    if (get(driverAPIRequest, "data.code", null) === 400) {
                        yield put(setFormErrorFn(
                            `${getUserDriverList.key}_create`,
                            get(driverAPIRequest, "data.errors", [])
                        ));
                    }

                    yield call(() => callApi(`${createAuthUserAPI.url}/${get(userAPIRequest, "data.data.id", "")}`).headers(true).method("delete").send());
                    yield call(() => callApi(`${getUserStaffList.url}/${get(staffAPIRequest, "data.data.id", "")}`).headers(true).method("delete").send());
                } else {
                        yield put(setFlashMessage({
                            status: true,
                            message: "Driver has successfully created",
                            type: "success",
                        }));

                        get(data, "payload.historyFn.push", emptyFn)("/users/driver");
                }
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(
                    `${getUserDriverList.key}_create`,
                    get(userAPIRequest, "data.errors", [])
                ));
                
           
                yield put(
                    setFlashMessage({
                    status: true,
                    message: get(
                        userAPIRequest,
                        "data.errors[0].error",
                        "Something went wrong!"
                    ),
                    type: "danger",
                    })
                );
	         } else if (get(userAPIRequest, "data.statusCode", null) === 403) {
                yield put(
                    setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong...!!",
                            type: "danger",
                        }));
                }
            }
        } catch (err) {
          console.log(err, "error");
        }
      }
         
            
    yield put(setPageLoader(false));
}

function* updateDriverFn(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            licenseNo: "License Number",
            identification: "Identification",
            email: "Email",
            category: "Driver Type",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            identification: "required|min:8|max:20",
            email: "required|email|max:50",
            category: "required",
            licenseNo: "required",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
        },
        {},
    )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getUserDriverList.key}_edit`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserDriverList.key}_edit`, []));

        let roleId = parseInt(get(data, "payload.role.id", ""));
        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            role: {
                id: roleId,
            },
            status: get(data, "payload.status", ""),
        };

        const userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.staffObj.userId")}`)
            .headers(true)
            .method("put")
            .body(userAPIRequestBody)
            .send()
        );

        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                status: get(data, "payload.status", ""),
                countryCode: {
                    id: parseInt(get(countryCode, "[0]"))
                },
                countryCodePrefix: get(countryCode, "[2]")
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            if (!isEmptyValue(get(data, "payload.image", ""))) {
                staffAPIRequestBody["image"] = get(data, "payload.image", "");
            }

            const staffAPIRequest = yield call(() => callApi(`${getUserStaffList.url}/${get(data, "payload.staffId", "")}`).headers(true).method("put").body(staffAPIRequestBody).send());

            if (staffAPIRequest._statue === true) {
                const driverAPIRequestBody = {
                    name: get(data, "payload.name", ""),
                    licenseNo: get(data, "payload.licenseNo", ""),
                    identification: get(data, "payload.identification", ""),
                    category: get(data, "payload.category", ""),
                    status: get(data, "payload.status", ""),
                }

                const driverAPIRequest = yield call(() => callApi(`${getUserDriverList.url}/${get(data, "payload.id", "")}`).headers(true).method("put").body(driverAPIRequestBody).send());

                if (driverAPIRequest._statue === true) {
                    yield put(setPageLoader(false));
                    yield put(setFlashMessage({
                        status: true,
                        message: "Driver details have updated successfully",
                        type: "success",
                    }));

                    get(data, "payload.historyFn.push", emptyFn)("/users/driver");
                } else {
                    if (get(driverAPIRequest, "data.code", null) === 400) {
                        yield put(setFormErrorFn(`${getUserDriverList.key}_edit`, get(driverAPIRequest, "data.errors", [])));
                    }
                    

                    const oldStaffAPIRequestBody = {
                        name: get(data, "payload.staffObj.name", ""),
                        identification: get(data, "payload.staffObj.identification", ""),
                        phone: get(data, "payload.staffObj.phone", ""),
                        email: get(data, "payload.staffObj.email", ""),
                        status: get(data, "payload.staffObj.status", ""),
                        countryCode: {
                            id: get(data, "payload.staffObj.countryCodeId", "")
                        },
                        countryCodePrefix: get(data, "payload.staffObj.countryCodePrefix", "")
                    };

                    yield call(() => callApi(`${getUserStaffList.url}/${get(data, "payload.staffId", "")}`).headers(true).method("put").body(oldStaffAPIRequestBody).send());

                    const oldUserAPIRequestBody = {
                        email: get(data, "payload.staffObj.email", ""),
                    };

                    yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.staffObj.userId")}`)
                        .headers(true)
                        .method("put")
                        .body(oldUserAPIRequestBody)
                        .send()
                    );
                }
            } else {
                if (get(staffAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${getUserDriverList.key}_edit`, get(staffAPIRequest, "data.errors", [])));
                }
                    yield put(
                        setFlashMessage({
                        status: true,
                        message: get(
                            staffAPIRequest,
                            "data.errors[0].error",
                            "Something went wrong!"
                        ),
                        type: "danger",
                        })
                        );
                const oldUserAPIRequestBody = {
                    email: get(data, "payload.staffObj.email", ""),
                };

                yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.staffObj.userId")}`)
                    .headers(true)
                    .method("put")
                    .body(oldUserAPIRequestBody)
                    .send()
                );
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(`${getUserDriverList.key}_edit`, get(userAPIRequest, "data.errors", [])));
                yield put(setFlashMessage({
                    status: true,
                    message: get(
                        userAPIRequest,
                        "data.errors[0].error",
                        "Something went wrong!"
                        ),
                    type: "danger",
                }));
            } else if (get(userAPIRequest, "data.statusCode", null) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger",
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: get(userAPIRequest, "data.errors", "Something went wrong!"),
                    type: "danger",
                }));
            }
        }
    }

    yield put(setPageLoader(false));
}

export {
    createDriverFn,
    updateDriverFn
}
