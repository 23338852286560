import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import { setPageLoader, setFlashMessage } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getZoneAPI, getCityListAPI } from "../../../config/boda-boda/apiUrls.config";
import validateMethod from '../../../helpers/boda-boda/validation';
import { setFormErrorFn } from '../form/actions';
import { resetDataTable } from "../core/actions";

const emptyFn = (...para) => undefined;

function* createZoneFn(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            code: "Code"
        },
        {
            name: "required|min:4",
            code: "required|max:6|min:6"
        },
        {
            "code.min": "The Code must be equal to 6 characters",
            "name.min": "The Name must be longer than or equal to 4 characters"
        },
    )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getZoneAPI.key}_create`, validationResponse.errors));

        if (get(data, 'payload.cities', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have select at least one city",
                type: "danger",
            }));
        }
    } else {
        yield put(setFormErrorFn(`${getZoneAPI.key}_create`, []));

        if (get(data, 'payload.cities', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have select at least one city",
                type: "danger",
            }));
        } else {
            yield put(setPageLoader(true));
            let temp = [];

            get(data, 'payload.cities', []).forEach((value) => {
                temp.push({ "id": value });
            });

            const apiRequestBody = {
                name: get(data, 'payload.name', ""),
                code: get(data, 'payload.code', ""),
                cities: temp,
                status: "ACTIVE",
            }

            const response = yield call(() => callApi(getZoneAPI.url).headers(true).method("post").body(apiRequestBody).send());

            yield put(setPageLoader(false));

            if (response._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Zone has successfully created",
                    type: "success",
                }));

                get(data, "payload.history.push", emptyFn)("/zones");
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: get(response, "data.errors[0].error", []),
                    type: "danger",
                }));
            }
        }
    }
}

function* addCities(data) {

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getCityListAPI.url}/${data.id}`)
        .headers(true)
        .method("put")
        .body(data.payload)
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getCityListAPI.key}_available`));
        yield put(resetDataTable(`${getCityListAPI.key}_selected`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

export {
    createZoneFn,
    addCities
}
