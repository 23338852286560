import React, {Component, Fragment} from "react";
import {InputButton, SubmitButton} from "../../../../../components/boda-boda/Forms";
import {getMerchantAPI} from "../../../../../config/boda-boda/apiUrls.config";
import {apiResponseStoringType} from "../../../../../config/boda-boda/apiResponseKey";
import {ModalUI} from "../../../../../components/boda-boda/Modal/ModalUI";

class DeleteUI extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fas fa-window-close"}
                    elementStyle={"btn btn-danger  waves-effect waves-light"}
                    btnText={"Delete"}
                    onClickBtnFn={() => onClickFn()}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Please Confirm"}
                    modalBodyComponent={<p>Are you sure you want to delete this merchant?</p>}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <DeleteBtn
                            historyFn={this.props.historyFn}
                            merchant={this.props.merchant}
                        />
                    }
                />
            </Fragment>
        );
    }

}

const DeleteBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Delete"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Merchant has successfully deleted",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getMerchantAPI.url}/${props.merchant}`,
                    key: getMerchantAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                const requestBody = {
                    status: "INACTIVE"
                }
                return requestBody;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push('/merchant');
                }
            }}
        />
    )
}

const RemoveMessage = () => {
    return (
        <p>Are you sure you want to delete this merchant?</p>
    );
}

const RemoveBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Delete"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            dataTableKey={getMerchantAPI.key}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getMerchantAPI.url}/${props.row.id}`,
                    key: getMerchantAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                const requestBody = {
                    status: "INACTIVE"
                }
                return requestBody;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    )
}

export {
    RemoveMessage,
    RemoveBtn,
    DeleteUI,
    DeleteBtn
}