import React, {Component} from "react";
import {FormWrapper, InputButtonWithState} from "../../../../../../components/boda-boda/Forms";
import {getCityListAPI, getLastMileAPI, getRouteLastCityAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import {Col, Label, Row} from "reactstrap";
import {BackBtnComponent} from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import {connect} from "react-redux";
import { get, map, set, reject } from "lodash";
import {actionBtnTypes, CrudComponent} from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { FilterUI } from "./FilterUI"
import { routeLastAddCities, routeLastRemoveCities, createLastMile } from "../../../../../../store/boda-boda/transaction_request/actions";

class CreateLastMile extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    addCity  = (row) => {
        const body = {
            "routeLast": {
                "id": Number(this.props.match.params.id)
            },
            "city": {
                "id": row.id
            }
        };

        const flashMsjObj = {
            status: true,
            message: "City has successfully added",
            type: "success"
        };

        const cities = get(this.props.formState, `${getLastMileAPI.key}.cities`, "");

        set(this.props.formState, `${getLastMileAPI.key}.cities`,  [...cities, { cityId : row.id}]);

        this.props.routeLastAddCities(row.id, body, flashMsjObj);
    }

    removeCity = (row) => {
        const flashMsjObj = {
            status: true,
            message: "City has successfully removed",
            type: "success"
        };

        let cities = get(this.props.formState, `${getLastMileAPI.key}.cities`, "");

        cities = reject(cities, function (el) { return el.cityId === row.cityId});

        set(this.props.formState, `${getLastMileAPI.key}.cities`, [...cities]);

        this.props.routeLastRemoveCities(row.id, null, flashMsjObj);
    }

    render() {
        let { match, formState, createLastMile, history } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={getLastMileAPI.key}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getLastMileAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            status: response.status,
                            cities: response.cities,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Create Pick Up/Last Route</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <InputButtonWithState
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Save"}
                                    startIcon={"mdi mdi-content-save-all-outline"}
                                    formGroupName={getLastMileAPI.key}
                                    onClickBtnFn={(dataObject) => {
                                        createLastMile({
                                            ...dataObject.formObject,
                                            history: history
                                        })
                                    }}
                                />
                                <BackBtnComponent
                                    elementWrapperStyle={""}
                                    path={"/routes/last-mile"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Label>Route ID: {get(formState, `${getLastMileAPI.key}.id`, "")}</Label>
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <Label>Route Name: {get(formState, `${getLastMileAPI.key}.name`, "")}</Label>
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <Label>Route Status: {get(formState, `${getLastMileAPI.key}.status`, "")}</Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="col-md-12 padding-none">
                            <CrudComponent
                                title={"Cities"}
                                apiURL={`${getCityListAPI.url}?page=1&limit=20`}
                                dataTableStateKey={`${getCityListAPI.key}_lastMile`}
                                isSetCreateAction={false}
                                filterFromComponent={FilterUI}
                                defaultFilterFormObject={{
                                    name: "",
                                    provinceId: "",
                                    districtId: ""
                                }}
                                tableHeaderList={[
                                    { displayName: "No", key: "id" },
                                    { displayName: "City", key: "name" },
                                    { displayName: "District", key: "district.name" },
                                    { displayName: "Province", key: "district.province.name" },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.create,
                                        isSetModel: false,
                                        actionBtn: {
                                            tooltip: "Add",
                                            onClickBtnFn: (rawData) => this.addCity(rawData)
                                        },
                                        checkBeforeActionRenderFn: (rawData) => {
                                            return !map(get(formState, `${getLastMileAPI.key}.cities`, ""), "cityId").includes(rawData.rowData.id);
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="col-md-12 padding-none">
                            <CrudComponent
                                title={"Selected Cities"}
                                apiURL={`${getRouteLastCityAPI.url}?routeLastId=${match.params.id}&page=1&limit=20`}
                                dataTableStateKey={`${getRouteLastCityAPI.key}_lastMile`}
                                isSetCreateAction={false}
                                isFilter={false}
                                tableHeaderList={[
                                    { displayName: "No", key: "id" },
                                    { displayName: "City", key: "city.name" },
                                    { displayName: "District", key: "city.district.name" },
                                    { displayName: "Province", key: "city.district.province.name" },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.delete,
                                        isSetModel: false,
                                        actionBtn: {
                                            tooltip: "Add",
                                            onClickBtnFn: (rawData) => this.removeCity(rawData)
                                        },
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};


const CreateLastMileWithState = connect(mapStateToProps, { routeLastAddCities, routeLastRemoveCities, createLastMile })(CreateLastMile);

export {
    CreateLastMileWithState
}