import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { merchantSettlementReportAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {
  fromObjectToQueryString, isEmptyValue,
} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";


class MerchantSettlementReportView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loaderStatus: true,
    };
  }


  getQueryString = () => {
    let { formState } = this.props;

    let dateTo = new Date(
      get(formState, "merchant_settlement_report.dateTo", new Date())
    );
    dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
    dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

    const queryObj = {
      settlementDateFrom: get(formState, "merchant_settlement_report.dateFrom", new Date()).toISOString(),
      settlementDateTo: dateTo.toISOString(),
      settleToMerchantId: get(formState, "merchant_settlement_report.merchantId")?.split("/")[0],
    };

    return `&${fromObjectToQueryString(queryObj)}`;
  };

  render() {
    let { formState } = this.props;

    return (
      <React.Fragment>
        {get(formState, "merchant_settlement_report.dateFrom", "") === "" ||
          get(formState, "merchant_settlement_report.dateTo", "") === "" ? (
          <Redirect to={"/reports/merchant-settlement-report"} />
        ) : (
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">
                    Merchant Settlement Transaction Report
                  </h4>
                </div>

                <div className={"row"}>
                  <div className={"col-sm-12"}>
                    From<strong className={"mx-2"}>{dateObjectToString(get(formState, "merchant_settlement_report.dateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "merchant_settlement_report.dateTo"), "dd/mm/YY")}</strong>
                  </div>

                  <div className={"col-sm-12"}>
                    Merchant:<strong className={"mx-2"}>{get(formState, "merchant_settlement_report.merchantId", "") === "" ? "All" : get(formState, "merchant_settlement_report.merchantId", "")?.split("/")[1]}</strong>
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div className={"d-flex justify-content-end"}>
                  <BackBtnComponent
                    path={"/reports/merchant-settlement-report"}
                  />
                </div>
              </Col>
            </Row>

            <div className={"row mt-3"}>
              <CrudComponent
                title={"Merchant Settlement Transaction Report"}
                apiURL={`${merchantSettlementReportAPI.url}?page=1&limit=20${this.getQueryString()}`}
                dataTableStateKey={merchantSettlementReportAPI.key}
                isFilter={false}
                isSetCreateAction={false}
                isDropdownActionBtn={false}
                isSetAction={false}
                tableHeaderList={[
                  {
                    displayName: "Settlement Date",
                    key: "settlementDate",
                    onPrintFn: (rawData) =>
                      dateObjectToString(
                        rawData.settlementDate,
                        "YY-mm-dd"
                      ),
                  },
                  {
                    displayName: "Banked Date",
                    key: "settledAt",
                    onPrintFn: (rawData) =>
                    !isEmptyValue(get(rawData, "settledAt")) ? dateObjectToString(
                        rawData.settledAt,
                        "YY-mm-dd"
                      ) : "-",
                  },
                  { displayName: "Merchant Code", key: "settleToMerchant.code" },
                  { displayName: "Merchant Name", key: "settleToMerchant.name" },
                  { displayName: "Transaction Number", key: "reference" },
                  {
                    displayName: "Amount",
                    key: "total",
                    onPrintFn: (rawData) => {
                      return rawData.total === null ? 0 : rawData.total;
                    },
                  },
                  { displayName: "Remark", key: "remark" },
                ]}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.FormData,
  };
};

export default connect(mapStateToProps, null)(MerchantSettlementReportView);
