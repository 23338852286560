import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { get } from "lodash";

import { FormWrapper, RadioButtonWithState, SubmitButton } from "../../../components/boda-boda/Forms";
import { forgotPasswordOTPEmailAPI, forgotPasswordOTPSMSAPI } from "../../../config/boda-boda/apiUrls.config";
import { SnackbarList } from "../../../components/boda-boda/Snackbar/Snackbar";
import Loader from "../../../components/Loader";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";
import logo from "../../../assets/images/boda-logo3.png";


class ForgetPasswordOTPPage extends Component {
  render() {
    let { formData, uiState, history } = this.props;

    return (
        <React.Fragment>

          {
            (get(formData, "forgotPasswordFrom", null) === null) ? (
                <Redirect to={"/forgot-password"} />
            ) : (
                <React.Fragment>
                  {
                    (uiState.setPageLoader === true) ? (
                        <Loader />
                    ) : null
                  }
                  <SnackbarList />
                  <div className="account-pages my-5 pt-5">
                    <div className="container">
                      <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                          <div className="position-relative">
                            <Card className="overflow-hidden">
                              <div className="bg-primary">
                                <div className="text-primary text-center p-4">
                                  <h5 className="text-white font-size-20">
                                    Forgot Password
                                  </h5>
                                  <p className="text-white-50">
                                    Select How do you want to receive the OTP.
                                  </p>
                                  <Link to="/" className="logo logo-admin">
                                    <img src={logo} height="50" alt="logo" />
                                  </Link>
                                </div>
                              </div>

                              <CardBody className="p-4">
                                <div className="p-3">

                                  <div className="form-horizontal mt-4">

                                    <FormWrapper
                                        setGroupName={`forgotPasswordOTPFrom`}
                                        setFormObject={{ otpType: "email" }}
                                        onDestroyUnsetFormObject={false}
                                    >
                                      <RadioButtonWithState
                                          formGroupName={`forgotPasswordOTPFrom`}
                                          inputName={"otpType"}
                                          isInline={false}
                                          dataList={[{ id: "email", value: "Send OTP to email address" }, { id: "sms", value: "Send OTP via SMS to phone number" }]}
                                      />

                                    </FormWrapper>
                                    <div className={"row"}>
                                      <Col sm={6}></Col>
                                      <Col sm={6} className="text-right">
                                        <SubmitButton
                                            btnText={"Send OTP"}
                                            elementStyle="btn btn-primary w-md waves-effect waves-light"
                                            formGroupName={`forgotPasswordOTPFrom`}
                                            dataTableKey={forgotPasswordOTPEmailAPI.key}
                                            isValidate={false}
                                            elementWrapperStyle={"mt-3"}
                                            flashMessages={{
                                              "success": {
                                                status: true,
                                                message: "OTP has successfully send",
                                                type: "success"
                                              },
                                              "error": {
                                                status: true,
                                                message: "No user found with that credential",
                                                type: "danger"
                                              }
                                            }}
                                            validationObject={{
                                              fields: {
                                                credential: "Username",
                                              },
                                              rules: {
                                              },
                                              message: {}
                                            }}
                                            callApiObject={{
                                              isSetHeaders: true,
                                              multipart: false,
                                              method: "post",
                                              onUpload: false
                                            }}
                                            apiDataStoringObject={{
                                              setLoader: true,
                                              storingType: apiResponseStoringType.apiRequestOnly,
                                              mergeToSuccessResponse: null,
                                              mergeToErrorResponse: null,
                                            }}
                                            onGetAPIEndPointFn={(formObject) => {
                                              if (formObject.otpType === "email") {
                                                return {
                                                  url: `${forgotPasswordOTPEmailAPI.url}`,
                                                  key: forgotPasswordOTPEmailAPI.key
                                                }
                                              } else {
                                                return {
                                                  url: `${forgotPasswordOTPSMSAPI.url}`,
                                                  key: forgotPasswordOTPSMSAPI.key
                                                }
                                              }
                                            }}
                                            onChangeRequestBodyFn={(formObject) => {
                                              return {
                                                "credential": get(formData, "forgotPasswordFrom.credential", "")
                                              }
                                            }}
                                            onResponseCallBackFn={(error, result) => {
                                              if (!error) {
                                                history.push(`/forgot-password-otp-verify`);
                                              }
                                            }}
                                        />
                                      </Col>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                              <p>
                                Go back to{" "}
                                <Link
                                    to="/login"
                                    className="font-weight-medium text-primary"
                                >
                                  Login
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
            )
          }
        </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    formData: state.FormData,
    uiState: state.UIState
  };
};

export default withRouter(
    connect(mapStatetoProps, null)(ForgetPasswordOTPPage)
);
