import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TabComponent, SubTab } from "../../../components/boda-boda/Tab/Tab";

import { InputButton, FormWrapper } from "../../../components/boda-boda/Forms";
import { deliveryChargeManagementAllAPI, getAllFacilityAPI, getFacilityAPI } from "../../../config/boda-boda/apiUrls.config";
import { initFromObjectFn, initFormFn, removeFromGroupFn, changeInputFn } from "../../../store/boda-boda/form/actions";


class DeliveryCharge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    }
  }

  componentDidMount() {
    this.initFormGroupForActiveTabFn();
  }

  initFormGroupForActiveTabFn = () => {
    let { initFormFn, formState } = this.props;

    if (get(formState, "deliveryChargeActiveTab", null) === null) {
      const formObj = initFromObjectFn("deliveryChargeActiveTab")
        .setGroupName("deliveryChargeActiveTab")
        .setFormObject({
          activeTab: 0
        })
        .generate();

      initFormFn({
        "deliveryChargeActiveTab": formObj
      })
    }
  }

  render() {
    let { history, formState, authState, changeInputFn } = this.props;

    return (
      <div className="container-fluid">
        <div className={"row"}>
          <div className={"col-md-12"}>

            <div className="card mt-4">
              <div className="card-body">

                <TabComponent
                  activeTab={get(formState, "deliveryChargeActiveTab.activeTab", 0)}
                  onClickFn={(tab) => changeInputFn("deliveryChargeActiveTab", "activeTab", "", tab)}
                >
                  <SubTab tabId={0} tabName={"Costing"}>
                    <FormWrapper>
                      <Row className="align-items-center">
                        <Col sm={6}>
                          <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View Delivery Costing</h4>
                          </div>
                        </Col>

                        <Col sm={6}>
                          <div className={"d-flex justify-content-end"}>

                            <InputButton
                              elementWrapperStyle={"mr-2"}
                              btnText={"Edit"}
                              startIcon={"mdi mdi-square-edit-outline"}
                              onClickBtnFn={() => history.push(`/update-delivery-charges-costing`)}
                            />

                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <FormWrapper
                            setGroupName={`${deliveryChargeManagementAllAPI.key}_view`}
                            onDestroyUnsetFormObject={true}
                            apiUrl={`${deliveryChargeManagementAllAPI.url}?status=ACTIVE`}
                            onRebuildResponseFn={(response) => {
                              return {
                                id: get(response[0], "id", ""),
                                xlPackageHandlingBaseCost: get(response[0], "xlPackageHandlingBaseCost", ""),
                                xlPackageHandlingBase: get(response[0], "xlPackageHandlingBase", ""),
                                expressBaseMultiplierCost: get(response[0], "expressBaseMultiplierCost", ""),
                                expressBaseMultiplier: get(response[0], "expressBaseMultiplier", ""),
                                cashChargingTypeBaseType: get(response[0], "cashChargingTypeBaseType", ""),
                                cashDeliveryBase: get(response[0], "cashDeliveryBase", ""),
                                status: get(response[0], "status", ""),
                                deletedAt: get(response[0], "deletedAt", null),
                                cardChargingTypeBaseType: get(response[0], "cardChargingTypeBaseType", ""),
                                cardDeliveryBase: get(response[0], "cardDeliveryBase", ""),
                                cashChargingTypeBaseCostType: get(response[0], "cashChargingTypeBaseCostType", ""),
                                cashDeliveryBaseCost: get(response[0], "cashDeliveryBaseCost", ""),
                                cardChargingTypeBaseCostType: get(response[0], "cardChargingTypeBaseCostType", ""),
                                cardDeliveryBaseCost: get(response[0], "cardDeliveryBaseCost", ""),
                                createdAt: get(response[0], "createdAt", null),
                                updatedAt: get(response[0], "updatedAt", null),
                                "_onLoad": false,
                              }
                            }}
                          >

                            <div className="card">
                              <div className="card-body">



                                <div className="row">
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>Express Delivery Multiplier</dd>
                                      <dt className={"col-sm-8"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.expressBaseMultiplierCost`, "-")}</dt>
                                    </dl>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>Per Package Cash on Delivery</dd>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Cash on Delivery Type</dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cashChargingTypeBaseCostType`)}</dt>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Percentage (%) / Flat Fee </dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cashDeliveryBaseCost`, "-")}</dt>
                                    </dl>
                                  </div>
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>Per Package Card on Delivery</dd>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Card on Delivery Type</dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cardChargingTypeBaseCostType`)}</dt>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Percentage (%) / Flat Fee </dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cardDeliveryBaseCost`, "-")}</dt>
                                    </dl>
                                  </div>

                                </div>
                                <div className="row">
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>XL Package Handling Cost</dd>
                                      <dt className={"col-sm-8"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.xlPackageHandlingBaseCost`, "-")}</dt>
                                    </dl>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </FormWrapper>



                        </Col>
                      </Row>

                    </FormWrapper>
                  </SubTab>

                  <SubTab tabId={1} tabName={"Pricing"}>
                    <FormWrapper>
                      <Row className="align-items-center">
                        <Col sm={6}>
                          <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View Delivery Pricing</h4>
                          </div>
                        </Col>

                        <Col sm={6}>
                          <div className={"d-flex justify-content-end"}>

                            <InputButton
                              elementWrapperStyle={"mr-2"}
                              btnText={"Edit"}
                              startIcon={"mdi mdi-square-edit-outline"}
                              onClickBtnFn={() => history.push(`/update-delivery-charges-pricing`)}
                            />

                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <FormWrapper
                            setGroupName={`${deliveryChargeManagementAllAPI.key}_view`}
                            onDestroyUnsetFormObject={true}
                            apiUrl={`${deliveryChargeManagementAllAPI.url}?status=ACTIVE`}
                            onRebuildResponseFn={(response) => {
                              return {
                                id: get(response[0], "id", ""),
                                xlPackageHandlingBaseCost: get(response[0], "xlPackageHandlingBaseCost", ""),
                                xlPackageHandlingBase: get(response[0], "xlPackageHandlingBase", ""),
                                expressBaseMultiplierCost: get(response[0], "expressBaseMultiplierCost", ""),
                                expressBaseMultiplier: get(response[0], "expressBaseMultiplier", ""),
                                cashChargingTypeBaseType: get(response[0], "cashChargingTypeBaseType", ""),
                                cashDeliveryBase: get(response[0], "cashDeliveryBase", ""),
                                status: get(response[0], "status", ""),
                                deletedAt: get(response[0], "deletedAt", null),
                                cardChargingTypeBaseType: get(response[0], "cardChargingTypeBaseType", ""),
                                cardDeliveryBase: get(response[0], "cardDeliveryBase", ""),
                                cashChargingTypeBaseCostType: get(response[0], "cashChargingTypeBaseCostType", ""),
                                cashDeliveryBaseCost: get(response[0], "cashDeliveryBaseCost", ""),
                                cardChargingTypeBaseCostType: get(response[0], "cardChargingTypeBaseCostType", ""),
                                cardDeliveryBaseCost: get(response[0], "cardDeliveryBaseCost", ""),
                                createdAt: get(response[0], "createdAt", null),
                                updatedAt: get(response[0], "updatedAt", null),
                                "_onLoad": false,
                              }
                            }}
                          >

                            <div className="card">
                              <div className="card-body">


                                <div className="row">
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>Express Delivery Multiplier</dd>
                                      <dt className={"col-sm-8"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.expressBaseMultiplier`, "-")}</dt>
                                    </dl>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>Per Package Cash on Delivery</dd>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Cash on Delivery Type</dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cashChargingTypeBaseType`)}</dt>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Percentage (%) / Flat Fee </dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cashDeliveryBase`, "-")}</dt>
                                    </dl>
                                  </div>
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>Per Package Card on Delivery</dd>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Card on Delivery Type</dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cardChargingTypeBaseType`)}</dt>
                                    </dl>
                                  </div>
                                  <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-8"}>Percentage (%) / Flat Fee </dd>
                                      <dt className={"col-sm-4"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.cardDeliveryBase`, "-")}</dt>
                                    </dl>
                                  </div>

                                </div>
                                <div className="row">
                                  <div className={"col-md-12"}>
                                    <dl className={"row"}>
                                      <dd className={"col-sm-4"}>XL Package Handling Price</dd>
                                      <dt className={"col-sm-8"}>{get(formState, `${deliveryChargeManagementAllAPI.key}_view.xlPackageHandlingBase`, "-")}</dt>
                                    </dl>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </FormWrapper>

                        </Col>
                      </Row>

                    </FormWrapper>
                  </SubTab>
                </TabComponent>

                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Lodgment Location wise Rate</h4>

                        <FormWrapper
                          setGroupName={`${getFacilityAPI.key}_view`}
                          onDestroyUnsetFormObject={true}
                          apiUrl={`${getFacilityAPI.url}/${get(authState, "authUser.facility.id", "")}`}
                          onRebuildResponseFn={(response) => {
                            return {
                              facility: response,
                              "_onLoad": false,
                            }
                          }}
                        >
                          <div className={"row"}>
                            <div className={"col-md-12"}>

                              <ul>
                                { 
                                  // console.log("====================", get(formState, `${getFacilityAPI.key}_view.facility`, []))
                                  // get(formState, `${getFacilityAPI.key}_view.facility`, []).map((values, index) => {
                                  // return (
                                  <React.Fragment>
                                    <Link to={"/delivery-charges-costing/" + get(authState, "authUser.facility.id", "") + "/volumetric-weight/" + get(authState, "authUser.facility.name", "")} ><li>Volumetric Weights - {get(authState, "authUser.facility.name", "")}</li></Link>
                                    <Link to={"/delivery-charges-costing/" + get(authState, "authUser.facility.id", "") + "/actual-weight/" + get(authState, "authUser.facility.name", "")} ><li>Actual Weights - {get(authState, "authUser.facility.name", "")}</li></Link>
                                  </React.Fragment>
                                  // )
                                  // })
                                }
                              </ul>
                            </div>

                          </div>
                        </FormWrapper>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData,
    authState: state.AuthState
  };
};

const ViewUIWithState = connect(mapStateToProps, {
  initFormFn,
  removeFromGroupFn,
  changeInputFn
})(DeliveryCharge);

export {
  ViewUIWithState
}
