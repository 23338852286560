import React, { Fragment, useState } from "react";

import { SubmitButton, InputButton } from "../../../../../components/boda-boda/Forms";
import { runSheetLineAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";


const Message = (props) => {
    return(
        <p>{props.displayMessage}</p>
    );
};

const APICallBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={runSheetLineAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: props.flashMessage,
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: props.type === "datatable" ? apiResponseStoringType.dateTableLinkedForm : apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${runSheetLineAPI.url}/${props.row.id}`,
                    key: runSheetLineAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    "status": props.status
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.type === "datatable") {
                        props.setModelStatusFn(false);
                    } else {
                        props.history.push("/runsheets-line");
                    }
                }
            }}
        />
    );
};

const UIModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                elementWrapperStyle={"ml-2"}
                btnText={props.btnText}
                startIcon={props.startIcon}
                btnColor={props.btnColor}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<Message displayMessage={props.displayMessage} />}
                modalFooterComponent={<APICallBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={"form"}
                    row={{ "id": props.id }}
                    history={props.history}
                    status={props.status}
                    flashMessage={props.flashMessage}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    Message,
    APICallBtn,
    UIModal
}
