import React, {Component} from "react";
import { get } from "lodash";
import {Col, Row} from "reactstrap";
import {connect} from "react-redux";

import {FormWrapper, InputButton} from "../../../../../components/boda-boda/Forms";
import {BackBtnComponent} from "../../../../../components/boda-boda/CommonElements/BaseElements";
import {
    merchantBranchAPI,
    merchantBranchContactAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import {DeleteUI} from "./DeleteUI";
import {currency} from "../../../../../config/boda-boda/db_config/db_status.config";
import {CrudComponent} from "../../../../../components/boda-boda/CRUD/CrudComponent";

class ViewUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, formState } = this.props;
        return (
            <div className="container-fluid">
                <FormWrapper
                    apiUrl={`${merchantBranchAPI.url}/${match.params.id}`}
                    setGroupName={`${merchantBranchAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        console.log("777777",response)
                        return {
                            id: get(response, "id",""),
                            name: get(response, "name", ""),
                            pickLocationId: get(response, "locationId", ""),
                            pickAddress: get(response, "location.address", ""),
                            status: get(response,"status", ""),
                            city: get(response, "location.city.name", ""),
                            province: get(response, "location.city.district.province.name", ""),
                            district: get(response, "location.city.district.name", ""),
                            pickupPrice: get(response, "pickupPrice", ""),
                            contactPerson: get(response, "contacts[0].fullName", ""),
                            phone: get(response, "contacts[0].phone", ""),
                            email: get(response, "contacts[0].email", ""),
                            postalCode: get(response, "location.postalCode", ""),
                            plusCode: get(response, "location.plusCode", ""),
                            facility: get(response, "facility.name", ""),
                            deletedBy:get(response, "deletedBy",""),
                            chargingMethod: get(response,"merchant.contracts[0].chargingMethod",""),
                            //province: get(response, "location.city.district.province", "")
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Branch</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={`/merchant/${match.params.merchantId}/branch`}
                                />

                                {
                                    (get(formState, `${merchantBranchAPI.key}_view.deletedBy`, "") === null) ? (
                                        <React.Fragment>
                                            {
                                             (get(formState, `${merchantBranchAPI.key}_view.chargingMethod`, "") === "VOLUMETRIC") ? 
                                            <InputButton
                                                elementWrapperStyle={"mr-2"}
                                                btnText={"Volumetric Weights"}
                                                startIcon={"fas fa-table"}
                                                onClickBtnFn={() => history.push(`/merchant/${match.params.merchantId}/merchant-branch/view/${match.params.id}/volumetric-weight/${get(formState, `${merchantBranchAPI.key}_view.name`, "")}`)}
                                            />
                                            : 
                                            <InputButton
                                                elementWrapperStyle={"mr-2"}
                                                btnText={"Actual Weights"}
                                                startIcon={"fas fa-table"}
                                                onClickBtnFn={() => history.push(`/merchant/${match.params.merchantId}/merchant-branch/view/${match.params.id}/actual-weight/${get(formState, `${merchantBranchAPI.key}_view.name`, "")}`)}
                                            />
                                             }
                                            
                                            <InputButton
                                                elementWrapperStyle={"mr-2"}
                                                btnText={"Edit"}
                                                startIcon={"mdi mdi-square-edit-outline"}
                                                onClickBtnFn={() => history.push(`/merchant/${match.params.merchantId}/merchant-branch/edit/${match.params.id}`)}
                                            />

                                            <DeleteUI
                                                historyFn={history}
                                                merchantBranch={match.params.id}
                                                merchant={match.params.merchantId}
                                            />
                                        </React.Fragment>
                                    ) : null
                                }

                            </div>
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dt className={"col-md-6"}>Branch Name</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.name`, "")}</dd>
                                                
                                                <dt className={"col-md-6"}>Pick Location Id</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.pickLocationId`, "")}</dd>

                                                <dt className={"col-md-6"}>Status</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.status`, "")}</dd>

                                                <dt className={"col-md-6"}>Delivery Center</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.facility`, "")}</dd>

                                                <dt className={"col-md-6"}>Pick Up Cost</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.pickupPrice`, "")} {currency}</dd>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dt className={"col-md-6"}>Address</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.pickAddress`, "")}</dd>

                                                <dt className={"col-md-6"}>Province</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.province`, "")}</dd>

                                                <dt className={"col-md-6"}>District</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.district`, "")}</dd>

                                                <dt className={"col-md-6"}>City</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.city`, "")}</dd>

                                            </dl>
                                        </div>
                                    </div>

                                    <CrudComponent
                                        title={"Contact Details"}
                                        isSetAction={false}
                                        isSetCreateAction={false}
                                        isFilter={false}
                                        dataTableStateKey={merchantBranchContactAPI.key}
                                        apiURL={`${merchantBranchContactAPI.url}?merchantBranchId=${match.params.id}&limit=20&page=1`}
                                        tableHeaderList={[
                                            { displayName: "Contact Person", key: "fullName"},
                                            { displayName: "Phone No.", key: "phone"},
                                            { displayName: "Email", key: "email"},
                                            {
                                                displayName: "Receive Notification", key: "notification", onPrintFn: (rawData) => {
                                                    return rawData.notification === true ? "Yes" : "No"
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export { ViewUIWithState };