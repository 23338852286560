import React, { Fragment, memo } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import { formCacheLevel } from "../../../config/boda-boda/template.config";


const emptyFn = (...para) => undefined;

const RadioButton = ({
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    isRequired = false,
    inputName = "",
    inputValue = "",
    inputError = "",
    helperText = "",
    keyName = 'id',
    valueName = 'value',
    dataList = [],
    isInline = true,
    isDisabled = false,
    onChangeFn = emptyFn,
}) => {
    return (
        <Fragment>
            <FormGroup className={`${elementWrapperStyle}`}>
                <Label className={`${inputError ? "selectBoxLabelStyle" : ""}`}>{labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}</Label>
                <FormGroup style={{ marginBottom: "0" }}>
                    {
                        (dataList || []).map((row, index) => {
                            return (
                                <FormGroup key={index} check inline={isInline}>
                                    <Label check>
                                        <Input
                                            type={"radio"}
                                            className={`${elementStyle}`}
                                            name={`${inputName}_${index}`}
                                            disabled={isDisabled}
                                            checked={inputValue === row[keyName]}
                                            onChange={(event) => {
                                                onChangeFn({
                                                    value: row[keyName],
                                                    name: inputName,
                                                    eventInfo: event
                                                })
                                            }}
                                        />
                                        {row[valueName]}
                                    </Label>
                                </FormGroup>
                            )
                        })
                    }
                </FormGroup>
                <small className={`${inputError ? "helperTextErrorStyle" : ""}`}>
                    {inputError ? inputError : helperText}
                </small>
            </FormGroup>
        </Fragment>
    )
}

const areEqual=(prevProps, nextProps) => {
    if (nextProps.cacheLevel===formCacheLevel.none || nextProps.setCache===false) {
        return false;
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange){
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.responseUpdateStatus===nextProps.responseUpdateStatus) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
            (prevProps.inputValue===nextProps.inputValue) && (prevProps.updateStatus===nextProps.updateStatus) &&
            (prevProps.dataList.length===nextProps.dataList.length)
        )
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.inputValue===nextProps.inputValue) &&
            (prevProps.responseUpdateStatus===nextProps.responseUpdateStatus) && (prevProps.dataList.length===nextProps.dataList.length)
        )
    }
}

const RadioButtonMemo = memo(RadioButton, areEqual);

export {
    RadioButton,
    RadioButtonMemo
}
