import React, { Component } from "react";

import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { facilityContactAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { CreateContact, CreateContactBtn } from "../update-includes/CreateContactDetails";
import { RemoveBtn, RemoveMessage } from "../update-includes/ContactDeleteUI";
import { InputButton } from "../../../../../components/boda-boda/Forms";


class AddContact extends Component {
    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <CrudComponent
                        title={"Contact Details"}
                        apiURL={`${facilityContactAPI.url}?page=1&limit=20&facilityId=${this.props.deliveryCenterId}`}
                        dataTableStateKey={facilityContactAPI.key}
                        isSetAction={true}
                        isFilter={false}
                        isSetCreateAction={true}
                        createActionProps={{
                            actionType: actionBtnTypes.create,
                            isSetModel: true,
                            actionBtn: {
                                btnText: "Add New"
                            },
                            modalProps: {
                                modalTitle: "Add Contact",
                                modalBodyComponent: CreateContact,
                                modalFooterComponent: CreateContactBtn,
                                isSetClose: true,
                                isSetFooterClose: true,
                                parentToModelProps: {
                                    facilityId: this.props.deliveryCenterId
                                }
                            }
                        }}
                        tableHeaderList={[
                            { displayName: "Contact Person", key: "name" },
                            { displayName: "Phone", key: "phone" },
                            { displayName: "Email", key: "email" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.delete,
                                isSetModel: true,
                                modalProps: {
                                    modalTitle: "Please Confirm",
                                    modalType: "",
                                    modalBodyComponent: RemoveMessage,
                                    modalFooterComponent: RemoveBtn,
                                    isSetClose: true,
                                    isSetFooterClose: true,
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-md-12"}>
                        <div className={"d-flex justify-content-end"}>
                            <InputButton
                                btnText={"Next"}
                                onClickBtnFn={() => this.props.onNextFn()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddContact;
