import React from "react";
import {FormWrapper} from "../../../../../../components/boda-boda/Forms";
import {getLinehaulAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import InputBoxWithState from "../../../../../../components/boda-boda/Forms/InputBoxWithState";
import {routeStatusList} from "../../../../../../config/boda-boda/db_config/db_status.config";
import SelectBoxWithState from "../../../../../../components/boda-boda/Forms/SelectBoxWithState";

const CreateUI = (props) => {
    return (
        <FormWrapper
            setGroupName={getLinehaulAPI.key}
        >
            <InputBoxWithState
                labelText="Route Name"
                inputName={"name"}
                isRequired={true}
                formGroupName={getLinehaulAPI.key}
                maxLength={20}
                placeHolderText={"Route Name"}
            />

            <SelectBoxWithState
                labelText={"Route Status"}
                isRequired={true}
                placeHolderText={"Route Status"}
                dataList={routeStatusList}
                inputName={"status"}
                formGroupName={getLinehaulAPI.key}
            />
        </FormWrapper>
    )
}

export {
    CreateUI
}

