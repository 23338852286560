import React, { Component} from "react";
import { connect} from "react-redux";
import { get } from "lodash";
import { Col, Row } from "reactstrap";

import {
    FormWrapper,
    InputBoxWithState
} from "../../../../components/boda-boda/Forms";
import { bulkPrintAPI} from "../../../../config/boda-boda/apiUrls.config";
import { BulkTagPrint } from "./BulkTagPrint";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";


class CollectionPrint extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    setErrors = (errors) => {
        let { setFormErrorFn } = this.props;
        setFormErrorFn(`${bulkPrintAPI.key}_create`, errors);
    }

    render() {
        let { formState, authState, setFormErrorFn } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${bulkPrintAPI.key}_create`}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Collection Bulk Tag Print</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BulkTagPrint
                                    facility={get(authState, 'authUser.facility.id', '')}
                                    quantity={get(formState, `${bulkPrintAPI.key}_create.count`, '')}
                                    setErrors={setFormErrorFn}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Enter the Quantity"
                                                placeHolderText={"Enter the Quantity"}
                                                inputName={"count"}
                                                formGroupName={`${bulkPrintAPI.key}_create`}
                                                inputType={"number"}
                                                isRequired={false}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState,
        formState: state.FormData
    };
};

export default connect(mapStateToProps, { setFormErrorFn })(CollectionPrint);
