import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getRoleListAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import {
    deliveryTypes,
    paymentMethods
} from "../../../../config/boda-boda/db_config/db_status.config.js";


class DeliveryFilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Driver Id"
                            inputName={"id"}
                            placeHolderText={"Driver Id"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Name"
                            inputName={"name"}
                            placeHolderText={"Name"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Delivery Type"}
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryTypes}
                            placeHolderText={"Delivery Type"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Payment Method"}
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default DeliveryFilterUI;
