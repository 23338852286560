import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getRoleListAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { driverTypes, userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";


class FilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Driver Id"
                            inputName={"id"}
                            placeHolderText={"Driver Id"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Name"
                            inputName={"name"}
                            placeHolderText={"Name"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Identification"
                            inputName={"identification"}
                            placeHolderText={"Identification"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Phone No"
                            inputName={"phone"}
                            placeHolderText={"Phone No"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={20}
                        />
                    </div>

                    {/* <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Type"}
                            inputName={"type"}
                            placeHolderText={"Type"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getRoleListAPI.url}/all?type=DRIVER`}
                            apiStateKey={getRoleListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div> */}
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Type"}
                            inputName={"category"}
                            placeHolderText={"Type"}
                            formGroupName={this.props.searchFromKey}
                            dataList={driverTypes}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            placeHolderText={"Status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={userStatusList}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default FilterUI;
