import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import {
    InputBoxWithState,
    FormWrapper,
    SubmitButton,
    SelectBoxWithState
} from "../../../../components/boda-boda/Forms";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getCityListAPI, getZoneAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { FilterUI } from "./create-includes/FilterUI";
import { addCities } from "../../../../store/boda-boda/transaction_request/actions";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class EditUI extends Component {

    addCity = (row) => {
        const body = {
            "zone": {
                "id": Number(this.props.match.params.id)
            }
        }

        const flashMsjObj = {
            status: true,
            message: "City has successfully added",
            type: "success"
        };

        this.props.addCities(row.id, body, flashMsjObj);
    }

    deleteCity = (row) => {
        const body = {
            "zone": null
        }

        const flashMsjObj = {
            status: true,
            message: "City has successfully removed",
            type: "success"
        };

        this.props.addCities(row.id, body, flashMsjObj);
    }

    render() {
        let { history, match, formState, coreState } = this.props;
        console.log("hhhhhhh", coreState)

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getZoneAPI.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getZoneAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            code: response.code,
                            status: response.status,
                            deliveryCenter: get(response, "facility.name", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Edit Zone</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <EditBtn
                                    id={match.params.id}
                                    history={history}
                                />

                                <BackBtnComponent
                                    path={"/zones"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <div className="card mb-1">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Zone Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getZoneAPI.key}_edit.code`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Delivery Center</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getZoneAPI.key}_edit.deliveryCenter`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Zone Name"
                                                placeHolderText={"Zone Name"}
                                                inputName={"name"}
                                                formGroupName={`${getZoneAPI.key}_edit`}
                                                isRequired={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Zone Code"
                                                placeHolderText={"Zone Code"}
                                                inputName={"code"}
                                                formGroupName={`${getZoneAPI.key}_edit`}
                                                isRequired={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="Status"
                                                placeHolderText={"Status"}
                                                inputName={"status"}
                                                formGroupName={`${getZoneAPI.key}_edit`}
                                                isRequired={true}
                                                dataList={[
                                                    {
                                                        id: "ACTIVE",
                                                        value: "ACTIVE"
                                                    },
                                                    {
                                                        id: "INACTIVE",
                                                        value: "INACTIVE"
                                                    }
                                                ]}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <CrudComponent
                            title="Cities"
                            apiURL={`${getCityListAPI.url}?page=1&limit=20&zoneId=null`}
                            dataTableStateKey={`${getCityListAPI.key}_available`}
                            isFilter={true}
                            defaultFilterFormObject={{
                                provinceId: "",
                                districtId: "",
                                name: "",
                            }}
                            filterFromComponent={FilterUI}
                            isSetAction={true}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "City", key: "name" },
                                { displayName: "District", key: "district.name" },
                                { displayName: "Province", key: "district.province.name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.create,
                                    isSetModel: false,
                                    actionBtn: {
                                        tooltip: "Add",
                                        onClickBtnFn: (rawData) => this.addCity(rawData),
                                    }
                                },
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Cities"
                            apiURL={`${getCityListAPI.url}?page=1&limit=20&zoneId=${match.params.id}`}
                            dataTableStateKey={`${getCityListAPI.key}_selected`}
                            isFilter={false}
                            isSetAction={true}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "City", key: "name" },
                                { displayName: "District", key: "district.name" },
                                { displayName: "Province", key: "district.province.name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => {
                                            this.deleteCity(rawData)
                                        },
                                    },
                                    checkBeforeActionRenderFn: () => {
                                        console.log("iiiiiiiiii", coreState.dataTableResponses.cityListAPIKey_selected.results.length)
                                        return coreState.dataTableResponses.cityListAPIKey_selected.results.length > 1
                                    },
                                },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

const EditBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getZoneAPI.key}_edit`}
            dataTableKey={getZoneAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    status: "Status",
                    code: "Code"
                },
                rules: {
                    name: "required|min:4",
                    status: "required",
                    code: "required|min:6",
                },
                message: {
                    "code.min": "The Code must be longer than or equal to 6 characters",
                    "name.min": "The Name must be longer than or equal to 4 characters",
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Zone has successfully updated",
                    type: "success"
                },
                "error": {
                    status: true,
                    message: "Zone code and name must be unique ",
                    type: "danger"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getZoneAPI.url}/${props.id}`,
                    key: getZoneAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                const data = getDataByFormObject(formObject);
                console.log("=============>", data);

                return {
                    ...data,
                    deliveryCenter: undefined
                }
            }}
            onResponseCallBackFn={(error, result) => {
                console.log("3333333333333", error)
                if (!error) {
                    props.history.push("/zones");
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const ZoneEdit = connect(mapStateToProps, { addCities })(EditUI);

export {
    ZoneEdit
};
