import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    SubmitButton
} from "../../../../components/boda-boda/Forms";
import {getTillList, getUserDriverList} from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { transactionTypes } from "../../../../config/boda-boda/db_config/db_status.config";
import { formObjectValidationHelper, getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import {get} from "lodash";
import {connect} from "react-redux";


class CreateTill extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getTillList.key}_create`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserDriverList.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        console.log(response)
                        return {
                            tillsId: response.tills[0].id,
                            "_onLoad": false,
                        }
                    }}
                >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Add Transaction</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                                driverId={match.params.id}
                                tillsId={get(formState, `${getTillList.key}_create.tillsId`, "-")}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={""}
                                path={`/users/driver/view/${match.params.id}/till-management`}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className="card">
                                    <div className="card-body">

                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Transaction Type"}
                                                    inputName={"type"}
                                                    formGroupName={`${getTillList.key}_create`}
                                                    dataList={transactionTypes}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    placeHolderText={"Transaction Type"}
                                                    isRequired={true}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Amount"
                                                    placeHolderText={"Amount"}
                                                    inputName={"amount"}
                                                    formGroupName={`${getTillList.key}_create`}
                                                    isRequired={true}
                                                    maxLength={100}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Remark"
                                                    placeHolderText={"Remark"}
                                                    inputName={"note"}
                                                    formGroupName={`${getTillList.key}_create`}
                                                    maxLength={20}
                                                    rows={3}
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
                </FormWrapper>
            </div>
        );
    }
}

const CreateBtn = (props) => {
    return (
        <Fragment>
            <SubmitButton
                btnText={"Save"}
                elementWrapperStyle={"d-flex justify-content-end mr-2"}
                startIcon={"mdi mdi-content-save-all-outline"}
                isValidate={true}
                formGroupName={`${getTillList.key}_create`}
                validationObject={{
                    fields: {
                        "type": "Transaction Type",
                        "amount": "Amount",
                        "note": "Note"
                    },
                    rules: {
                        "type": "required",
                        "amount": "required"
                    },
                    message: {
                    }
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Transaction has successfully created",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.apiRequestOnly,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${getTillList.url}`,
                        key: getTillList.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);

                    return {
                        amount: formObjectValidationHelper(data, "amount", (data) => { return Number(data) }),
                        driver: { id: Number(props.driverId) },
                        till: { id: Number(props.tillsId) },
                        note: data.note,
                        type: data.type
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.historyFn.push(`/users/driver/view/${props.driverId}/till-management`);
                    }
                }}
            />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
    };
};

export default connect(mapStateToProps, null)(CreateTill);
