import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import parsePhoneNumber from "libphonenumber-js";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    SubmitButton, InputButtonWithState
} from "../../../../../components/boda-boda/Forms";
import {
    countryCodeAPI,
    getUserStaffList
} from "../../../../../config/boda-boda/apiUrls.config";
import { userStatusList } from "../../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import {connect, useDispatch } from "react-redux";
import { updateMerchantAdminUserTrKey } from "../../../../../store/boda-boda/transaction_request/actionTypes";

class EditUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, formState, history} = this.props;
        
        return (
            <div className="container-fluid">
                    <FormWrapper
                        setGroupName={`${getUserStaffList.key}_edit`}
                        apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                        onDestroyUnsetFormObject={true}
                        onRebuildResponseFn={(response) => {
                            return {
                                merchantId: response.merchantId,
                                id: response.id,
                                userId: response.userId,
                                name: response.name,
                                identification: response.identification,
                                phone: response.phone,
                                email: response.email,
                                status: response.status,
                                countryCodePrefix: get(response, "countryCodePrefix", ""),
                                countryCode: {
                                    id: `${get(response, "countryCode.id", "")}/${get(response, "countryCode.code", "")}/${get(response, "countryCode.prefix", "")}`
                                },
                                "_onLoad": false,
                            }
                        }}
                    >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Update Merchant Admin User</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <InputButtonWithState
                                    startIcon={"mdi mdi-content-save-all-outline"}
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Reset Password"}
                                    onClickBtnFn={() => history.push(`/merchant/${match.params.merchantId}/admin-users/change-password/${match.params.id}`)}
                                />

                                <EditAdminUserBtn
                                    staffId={match.params.id}
                                    merchantId={match.params.merchantId}
                                    historyFn={history}
                                    countryCode={get(formState, `${getUserStaffList.key}_edit.countryCode.id`, "").toString().split("/")}
                                />

                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={`/merchant/${match.params.merchantId}/admin-users`}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="User Id"
                                                placeHolderText={"User Id"}
                                                inputName={"id"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                                isDisabled={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Name"
                                                placeHolderText={"Name"}
                                                inputName={"name"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                isRequired={true}
                                                maxLength={100}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Identification"
                                                placeHolderText={"Identification"}
                                                inputName={"identification"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                isRequired={true}
                                                maxLength={20}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="Status"
                                                inputName={"status"}
                                                placeHolderText={"Status"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                dataList={userStatusList}
                                                isRequired={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <div className={"row"}>
                                                <div className={"col-md-3"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Country Code"
                                                        inputStatePath={`${getUserStaffList.key}_edit.countryCode.id`}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        apiUrl={`${countryCodeAPI.url}/all`}
                                                        apiStateKey={countryCodeAPI.key}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.code}/${value.prefix}`,
                                                                    value: value.code
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className={"col-md-9"}>
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"+XXXXXXXXXXX"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Email"
                                                inputName={"email"}
                                                placeHolderText={"Email"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={50}
                                                isRequired={true}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                    
                        </Col>
                    </Row>
                </FormWrapper>
         </div>

                );
            }
        }
        const EditAdminUserBtn = (props) => {
            const dispatch = useDispatch();
            return (

                  <InputButtonWithState
                        btnText={"Update"}
                        elementWrapperStyle={"d-flex justify-content-end mr-2"}
                        startIcon={"mdi mdi-content-save-all-outline"}
                        formGroupName={`${getUserStaffList.key}_edit`}
    
                        onClickBtnFn={(dataObject) => {
                            dispatch({
                                type: updateMerchantAdminUserTrKey,
                                payload: {
                                    ...dataObject.formObject,
                                    historyFn: props.historyFn
                                }
                            })
                        }}
                                          
                    />
            )}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

const EditUIWithState = connect(mapStateToProps,null)(EditUI);


export { EditUIWithState};



