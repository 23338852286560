import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
} from "../../../../components/boda-boda/Forms";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    convertToCsvValue,
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { merchantAPI, sageMerchantReportAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setFlashMessage } from '../../../../store/boda-boda/ui/actions'


class SAGEMerchantInvoiceReport extends Component {
    onGenerateFn = () => {
        let { formState } = this.props;

        if (this.validateFn() === true) {
            let dateFrom = new Date(get(formState, "sage_merchant_report.dateFrom"));

            let dateTo = new Date(get(formState, "sage_merchant_report.dateFrom"));
            dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 1));
            dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

            const queryObj = {
                dateTo: dateTo.toISOString(),
                dateFrom: dateFrom.toISOString(),
                merchantId: get(formState, "sage_merchant_report.merchantId", ""),
            }

            this.getAPIData(fromObjectToQueryString(queryObj));

        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "sage_merchant_report.dateFrom", ""))) {
            errors.push({
                "property": "dateFrom",
                "error": "This field is required"
            })
        }

        if (errors.length === 0) {
            setFormErrorFn("sage_merchant_report", []);
            return true;
        } else {
            setFormErrorFn("sage_merchant_report", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${sageMerchantReportAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);
                        
                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }
                                        
                        result.data.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data"));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    generateCSVData = (data = []) => {
        let { formState } = this.props;

        let dateTo = new Date(get(formState, "sage_merchant_report.dateFrom"));
        dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 1));
        dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

        const headerHeadings = ["RECTYPE", "CNTBTCH", "CNTITEM", "IDCUST", "IDINVC", "TEXTTRX", "ORDRNBR", "CUSTPO", "INVCDESC", "DATEINVC", "CODECURN", "RATETYPE", "EXCHRATEHC", "TERMCODE", "DATEDUE", "CODETAXGRP", "DATERATE", "AMTDUE"];
        const manifestHeadings = ["RECTYPE", "CNTBTCH", "CNTITEM", "CNTLINE", "TEXTDESC", "IDACCTREV", "AMTEXTN"];
        const footeHeadings = ["RECTYPE", "CNTBTCH", "CNTITEM", "CNTPAYM", "DATEDUE", "AMTDUE"];

        const dataList = [];

        let count = 1;

        data.forEach((value) => {
            if ((get(value, "manifests", []).length > 0) || (get(value, "orders", []).length > 0) || (get(value, "packages", []).length > 0)) {
                const firstTemp = [];

                let settlementDays = parseInt(get(value, "contracts[0].settlementPeriod", "0"));
                let dueDate = new Date(dateTo);
                dueDate.setDate(dueDate.getDate() + settlementDays);
                firstTemp.push("1");
                firstTemp.push("1");
                firstTemp.push(count);
                firstTemp.push(`"${convertToCsvValue(get(value, "code", "-"))}"`);
                firstTemp.push(`"${convertToCsvValue(`${get(value, "code", "-")}-${dateObjectToString(get(formState, "sage_merchant_report.dateFrom"), "YY-mm")}`)}"`);
                firstTemp.push("1");
                firstTemp.push("");
                firstTemp.push("");
                firstTemp.push("");
                firstTemp.push(`${dateObjectToString(dateTo, "YYYYmmdd")}`);
                firstTemp.push("SLR");
                firstTemp.push("AV");
                firstTemp.push("1");
                firstTemp.push(`${get(value, "contracts[0].settlementPeriod", "0")}`);
                firstTemp.push(`${dateObjectToString(dueDate, "YYYYmmdd")}`);
                firstTemp.push("VAT");
                firstTemp.push(`${dateObjectToString(dateTo, "YYYYmmdd")}`);
                var amtDue = 0;
                if (get(value, "manifests", []).length > 0) {
                    get(value, "manifests", []).map((secondValue) => {
                        amtDue += parseFloat(get(secondValue, "pickupPrice", 0));
                    });
                }
                if (get(value, "orders", []).length > 0) {
                    get(value, "orders", []).map((secondValue) => {
                        if (get(secondValue, "paymentMethod", "") === "CASH_ON_DELIVERY") {
                            amtDue += parseFloat(get(secondValue, "cashDeliveryPrice", 0));
                        } else if (get(secondValue, "paymentMethod", "") === "CARD_ON_DELIVERY") {
                            amtDue += parseFloat(get(secondValue, "cardDeliveryPrice", 0));
                        }
                    })
                }
                if (get(value, "packages", []).length > 0) {
                    get(value, "packages", []).map((secondValue) => {
                        if (get(secondValue, "priceType", "") === "VL_RATE") {
                            amtDue += parseFloat(get(secondValue, "totalPrice", 0));
                        } else if (get(secondValue, "priceType", "") === "FLAT_RATE") {
                            amtDue += parseFloat(get(secondValue, "totalFlatPrice", 0));
                        }
                    });
                }
                firstTemp.push(amtDue);

                dataList.push(firstTemp);
                var cntLine = 20;
                if (get(value, "manifests", []).length > 0) {
                    get(value, "manifests", []).map((secondValue) => {
                        const secondTemp = [];
                        secondTemp.push("2");
                        secondTemp.push("1");
                        secondTemp.push(count);
                        secondTemp.push(cntLine);
                        cntLine = cntLine + 20;
                        secondTemp.push(get(secondValue, "code", "-"));
                        secondTemp.push(process.env.REACT_APP_MERCHANT_MANIFESTS_GL_CODE);
                        secondTemp.push(get(secondValue, "pickupPrice", 0));
                        dataList.push(secondTemp);
                    })
                }

                if (get(value, "orders", []).length > 0) {
                    get(value, "orders", []).map((secondValue) => {
                        const secondTemp = [];
                        secondTemp.push("2");
                        secondTemp.push("1");
                        secondTemp.push(count);
                        secondTemp.push(cntLine);
                        cntLine = cntLine + 20;
                        secondTemp.push(get(secondValue, "consignmentNo", "-"));
                        secondTemp.push(process.env.REACT_APP_MERCHANT_ORDERS_GL_CODE);

                        if (get(secondValue, "paymentMethod", "") === "CASH_ON_DELIVERY") {
                            secondTemp.push(get(secondValue, "cashDeliveryPrice", "0"));
                        } else if (get(secondValue, "paymentMethod", "") === "CARD_ON_DELIVERY") {
                            secondTemp.push(get(secondValue, "cardDeliveryPrice", "0"));
                        } else {
                            secondTemp.push(0);
                        }
                        dataList.push(secondTemp);
                    })
                }

                if (get(value, "packages", []).length > 0) {
                    get(value, "packages", []).map((secondValue) => {
                        const secondTemp = [];
                        secondTemp.push("2");
                        secondTemp.push("1");
                        secondTemp.push(count);
                        secondTemp.push(cntLine);
                        cntLine = cntLine + 20;
                        secondTemp.push(get(secondValue, "articleNo", "-"));
                        secondTemp.push(process.env.REACT_APP_MERCHANT_PACKAGES_GL_CODE);

                        if (get(secondValue, "priceType", "") === "VL_RATE") {
                            secondTemp.push(get(secondValue, "totalPrice", "0"));
                        } else if (get(secondValue, "priceType", "") === "FLAT_RATE") {
                            secondTemp.push(get(secondValue, "totalFlatPrice", "0"));
                        } else {
                            secondTemp.push(0);
                        }
                        dataList.push(secondTemp);
                    })
                }

                const thridTemp = [];
                thridTemp.push("3");
                thridTemp.push("1");
                thridTemp.push(count);
                thridTemp.push("1");
                thridTemp.push(`${dateObjectToString(dueDate, "YYYYmmdd")}`);
                thridTemp.push(amtDue);
                dataList.push(thridTemp);
                count += 1;
            }

        });

        const finalDataSet = [headerHeadings, manifestHeadings, footeHeadings];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        finalDataSet.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
        ]);
        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Merchant_Invoice_${dateObjectToString(dateTo, "YYYYmmdd")}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">SAGE - Merchant Invoice Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">SAGE - Merchant Invoice Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "100%" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"sage_merchant_report"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            dateFrom: null,
                                            dateTo: null,
                                            merchantId: "",
                                            paymentMethod: "",
                                            type: "screen"
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Month/Year"
                                                    inputName={"dateFrom"}
                                                    formGroupName={"sage_merchant_report"}
                                                    monthYearPicker={true}
                                                    dateFormat={"MM/yyyy"}
                                                    maxDate={new Date()}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Merchant"}
                                                    apiUrl={`${merchantAPI.url}/all`}
                                                    apiStateKey={merchantAPI.key}
                                                    inputName={"merchantId"}
                                                    placeHolderText={"Merchant"}
                                                    formGroupName={"sage_merchant_report"}
                                                    elementStyle=""
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            if (get(value, "status", "") !== "INCOMPLETE") {
                                                                data.push({
                                                                    id: `${value.id}`,
                                                                    value: value.name
                                                                });
                                                            }
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    setFlashMessage,
})(SAGEMerchantInvoiceReport);
