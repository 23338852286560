import React, { Component } from "react";
import { Col, Row } from "reactstrap";

import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import {
    CheckBoxGroupWithState,
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    SubmitButton
} from "../../../../components/boda-boda/Forms";
import { getRoleAPI } from "../../../../config/boda-boda/apiUrls.config";
import { permissions } from "../../../../config/boda-boda/permissions.config";
import { roleTypes, userRoleStatus } from "../../../../config/boda-boda/db_config/db_status.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class CreateUI extends Component {
    render() {
        let { history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create User Role</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                history={history}
                            />

                            <BackBtnComponent
                                path={"/users/roles"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getRoleAPI.key}_create`}
                        >
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="User Role Name"
                                                placeHolderText={"User Role Name"}
                                                inputName={"name"}
                                                formGroupName={`${getRoleAPI.key}_create`}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="User Role Type"
                                                inputName={"type"}
                                                formGroupName={`${getRoleAPI.key}_create`}
                                                placeHolderText={"User Role Type"}
                                                dataList={roleTypes}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Status"
                                                inputName={"status"}
                                                formGroupName={`${getRoleAPI.key}_create`}
                                                placeHolderText={"Status"}
                                                dataList={userRoleStatus}
                                            />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <h4 className="card-title">Permissions</h4>
                                        </div>

                                        <div className={"col-md-12"}>
                                        {permissions.map((row) => {
                                            return (
                                                <CheckBoxGroupWithState
                                                    labelText={row.header}
                                                    formGroupName={`${getRoleAPI.key}_create`}
                                                    inputName={"permissions"}
                                                    dataList={row.data}
                                                    isCustomCheckBox={true}
                                                />
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        )
    }
}

const CreateBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getRoleAPI.key}_create`}
            dataTableKey={getRoleAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "User Role Name",
                    type: "User Role Type",
                    status: "Status",
                    permissions: "Permissions",
                },
                rules: {
                    name: "required",
                    type: "required",
                    status: "required",
                    permissions: "isArray:1",
                },
                message: {
                    "permissions.isArray": "You should have select at least one permission"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "User role has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getRoleAPI.url}`,
                    key: getRoleAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.history.push(`/users/roles`);
                }
            }}
        />
    )
}

export {
    CreateUI
}
