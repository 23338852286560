import {call, put} from "redux-saga/effects";
import validateMethod from "../../../helpers/boda-boda/validation";
import { get } from "lodash";
import {setFormErrorFn} from "../form/actions";
import {merchantBranchAPI, merchantBranchContactAPI, merchantBranchDedicatedAPI} from "../../../config/boda-boda/apiUrls.config";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {defaultCountryCode} from "../../../config/boda-boda/core.config";
import parsePhoneNumber from "libphonenumber-js";

const emptyFn = (...para) => undefined;

function* createBranchFn(data) {

    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            pickupPrice: "Pickup Price",
            facility: "Delivery Center",
            address: "Address",
            cityId: "City",
            fullName: "Contact Person",
            phone: "Phone No.",
            email: "Email",
            "countryCode.id": "Country Code",
        },
        {
            name: "required",
            pickupPrice: "required",
            facility: "required",
            address: "required",
            cityId: "required",
            fullName: "required",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            email: "required|email",
            "countryCode.id": "required",
        },
        {}
    ));

    if (validationResponse._status === false){
        yield put(setFormErrorFn(`${merchantBranchAPI.key}_create`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${merchantBranchAPI.key}_create`, []));

        let merchantBranchAPIRequestBody = {
            name: get(data, "payload.name", ""),
            merchant: {
                id: Number(get(data, "payload.merchant", ""))
            },
            facility: {
                id: get(data, "payload.facility", "")
            },
            location: {
                name: get(data, "payload.name", ""),
                address: get(data, "payload.address", ""),
                default: false,
                status: "ACTIVE",
                city: {
                    id: get(data, "payload.cityId", "")
                }
            },
            pickupPrice: Number(get(data, "payload.pickupPrice", ""))
        };

        const merchantBranchAPIRequest = yield call(() => callApi(merchantBranchDedicatedAPI.url)
            .headers(true)
            .method("post")
            .body(merchantBranchAPIRequestBody)
            .send());

        if (merchantBranchAPIRequest._statue === true) {

            const merchantBranchContactAPIRequestBody = {
                name: get(data, "payload.fullName", ""),
                fullName: get(data, "payload.fullName", ""),
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                merchantBranch: {
                    id: Number(get(merchantBranchAPIRequest, "data.data.id", ""))
                },
                notification: true
            }

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    merchantBranchContactAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            const merchantBranchContactAPIRequest = yield call(() => callApi(`${merchantBranchContactAPI.url}`)
                .headers(true)
                .method("post")
                .body(merchantBranchContactAPIRequestBody)
                .send());

            if (merchantBranchContactAPIRequest._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Merchant Branch has successfully created",
                    type: "success",
                }));

                get(data, "payload.historyFn.push", emptyFn)(`/merchant/${get(data, "payload.merchant", "")}/branch`);
            }
        } else {
            if (get(merchantBranchAPIRequest, "data.statusCode", 0) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger"
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger"
                }));
            }
        }
    }

    yield put(setPageLoader(false));
}

export {
    createBranchFn
}