export const initDataTableKey="@CoreContext/INIT_DATA_TABLE_DATA";
export const initResponseKey="@CoreContext/INIT_RESPONSE_DATA";
export const setDataTableKey="@CoreContext/SET_DATA_TABLE_DATA";
export const updateDataTableObjectKey="@CoreContext/UPDATE_DATA_TABLE_CONTENT";
export const shortDataTableDataKey="@CoreContext/SHORT_DATATABLE_DATA";
export const setReloadDataTableKey="@CoreContext/RELOAD_DATA_TABLE";
export const setApiResponseKey="@CoreContext/SET_RESPONSE_DATA";
export const updateDataTableRowKey="@CoreContext/UPDATE_DATA_TABLE_ROW";
export const updateDataTableFieldValueKey="@CoreContext/ON_CHANGE_DATA_TABLE_FIELD_VALUE";
export const setApiResponseErrorKey="@CoreContext/SET_RESPONSE_ERRORS";
export const requestAPIDataKey="@CoreContext/REQUEST_API_DATA";
export const setInitialState="@CoreContext/SET_INITIAL_STATE";
export const removeDatatable="@CoreContext/REMOVE_DATATABLE";
export const setFetchingInit="@CoreContext/SET_FETCHING_INIT";
