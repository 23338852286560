import React, { Component, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputButton, SubmitButton } from "../../../../components/boda-boda/Forms";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getOrdersListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { Button } from "reactstrap";
import _, { get } from "lodash";
import { resetDataTable, setBulkValues, setFlashMessage } from "../../../../store/actions";

const ReadyToReturnUI = (props) => {

    const [isShow, setIsShow] = useState(false)
    const formState = useSelector((state) => state.FormData);
    const dispatch = useDispatch()

    const onClickFn = () => {
        setIsShow(true)
    };

    const getSelectedOrders = () => {
        try {
        let selecteditemList = [];
        const chkList = get(formState, `${getOrdersListAPI.key}`, null);

        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'tableSelect')) {
                return item.replace('tableSelect', '');
            }
        });
        selecteditemList = selecteditemList.filter(function (element) {
            return element !== undefined;
        });
        selecteditemList = selecteditemList.map((item) => {
            return Number(item)
        });

        return selecteditemList
    } catch (error){
        dispatch(setFlashMessage({
            status: true,
            message: 'An error has occurred',
            type: 'danger',
        }))
    }
    };

    return (
        <Fragment>
            <Button
                color="danger"
                className={`btn btn-danger waves-effect waves-light ${props.isViewScreen ? 'mr-2' : 'mr-1'}`}
                onClick={() => onClickFn()}
            >
                <i className="fas fa-window-close mr-2"></i>
                Ready for Return
            </Button>

            <ModalUI
                isOpen={isShow}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<p>{props.isViewScreen ? `Are your sure you want to mark this consignment as Ready for Return?` : `Are your sure you want to mark ${getSelectedOrders()?.length} consignments as Ready for Return?`}</p>}
                showHideToggleFn={() => setIsShow(false)}
                modalFooterComponent={
                    <ReadyToReturnBtn
                        consignment={props.isViewScreen ? props.consignment : getSelectedOrders()}
                        historyFn={props.historyFn}
                        isViewScreen={props.isViewScreen}
                        setIsShow={setIsShow}
                    />
                }
            />

        </Fragment>
    );
}

const ReadyToReturnBtn = (props) => {
    const formState = useSelector((state) => state.FormData);
    const dispatch = useDispatch()

    const clearSelectedOrders = () => {
        let chkList = get(formState, `${getOrdersListAPI.key}`, null);
        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        let selecteditemList = [];
        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'tableSelect')) {
                let pair = {};
                let value = item;
                pair[value] = false;
                return pair;
            }
        });
        if (get(formState, `${getOrdersListAPI.key}.selectall`, null) !== null) {
            let selectAll = {};
            selectAll['selectall'] = false;
            selecteditemList.push(selectAll);
        }

        for (let i = 0; i < selecteditemList?.length; i += 1) {
            dispatch(setBulkValues(`${getOrdersListAPI.key}`, selecteditemList[i]))
        }
    }

    return (
        <SubmitButton
            btnText={"Ready for Return"}
            elementStyle={"btn btn-danger waves-effect waves-light"}
            isValidate={false}
            isBtnDisabled={!props.isViewScreen && props.consignment?.length === 0}
            // flashMessages={{
            //     "success": {
            //         status: true,
            //         message: "Consignment status successfully changed",
            //         type: "success"
            //     }
            // }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/ready-for-return`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    orders: props.isViewScreen ? [props.consignment] : props.consignment,
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    dispatch(setFlashMessage({
                        status: true,
                        message: `${result?.data?.counter} consignment(s) updated`,
                        type: 'success',
                    }))
                    if (props.isViewScreen){
                        props.historyFn.push('/consignments');
                    } else {
                        dispatch(resetDataTable(getOrdersListAPI.key))
                        clearSelectedOrders()
                        props.setIsShow(false)
                    }
                }
            }}
        />
    )
}

export {
    ReadyToReturnUI,
    ReadyToReturnBtn
}