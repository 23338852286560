import React, { Fragment, useState } from "react";
import { groupBy, get } from "lodash";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  SubmitButton,
  InputButton,
  SelectBoxWithState,
  FormWrapper,
} from "../../../../../components/boda-boda/Forms";
import { facilityRateDedicatedAPI, orderCSVUploadAPI, getDistrictListAPI } from "../../../../../config/boda-boda/apiUrls.config";

import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { setPageLoader, setFlashMessage } from "../../../../../store/boda-boda/ui/actions";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { fileGetAPIUrl } from "../../../../../config/boda-boda/core.config";
import { downloadCSV } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


const buildResponse = (data) => {
  let city = groupBy(data, "cityId");
  let temp2 = [];

  Object.keys(city).forEach((values, index) => {
    temp2.push(
      {
        city: city[values][0].city.name,
        cityId: city[values][0].city.id,
        range1: {
          cost: city[values][0] ? city[values][0].cost : "-",
          price: city[values][0] ? city[values][0].price : "-",
        },
        range2: {
          cost: city[values][1] ? city[values][1].cost : "-",
          price: city[values][1] ? city[values][1].price : "-",
        },
        range3: {
          cost: city[values][2] ? city[values][2].cost : "-",
          price: city[values][2] ? city[values][2].price : "-",
        },

        range4: {
          cost: city[values][3] ? city[values][3].cost : "-",
          price: city[values][3] ? city[values][3].price : "-",
        },

        range5: {
          cost: city[values][4] ? city[values][4].cost : "-",
          price: city[values][4] ? city[values][4].price : "-",
        },

        range6: {
          cost: city[values][5] ? city[values][5].cost : "-",
          price: city[values][5] ? city[values][5].price : "-",
        },

        range7: {
          cost: city[values][6] ? city[values][6].cost : "-",
          price: city[values][6] ? city[values][6].price : "-",
        },

        range8: {
          cost: city[values][7] ? city[values][7].cost : "-",
          price: city[values][7] ? city[values][7].price : "-",
        },

        range9: {
          cost: city[values][8] ? city[values][8].cost : "-",
          price: city[values][8] ? city[values][8].price : "-",
        },

        range10: {
          cost: city[values][9] ? city[values][9].cost : "-",
          price: city[values][9] ? city[values][9].price : "-",
        },

        range11: {
          cost: city[values][10] ? city[values][10].cost : "-",
          price: city[values][10] ? city[values][10].price : "-",
        },
        range12: {
          cost: city[values][11] ? city[values][11].cost : "-",
          price: city[values][11] ? city[values][11].price : "-",
        },
        range13: {
          cost: city[values][12] ? city[values][12].cost : "-",
          price: city[values][12] ? city[values][12].price : "-",
        },
        range14: {
          cost: city[values][13] ? city[values][13].cost : "-",
          price: city[values][13] ? city[values][13].price : "-",
        },
        range15: {
          cost: city[values][14] ? city[values][14].cost : "-",
          price: city[values][14] ? city[values][14].price : "-",
        },
        range16: {
          cost: city[values][15] ? city[values][15].cost : "-",
          price: city[values][15] ? city[values][15].price : "-",
        },
        range17: {
          cost: city[values][16] ? city[values][16].cost : "-",
          price: city[values][16] ? city[values][16].price : "-",
        },
        range18: {
          cost: city[values][17] ? city[values][17].cost : "-",
          price: city[values][17] ? city[values][17].price : "-",
        },
        range19: {
          cost: city[values][18] ? city[values][18].cost : "-",
          price: city[values][18] ? city[values][18].price : "-",
        },
        range20: {
          cost: city[values][19] ? city[values][19].cost : "-",
          price: city[values][19] ? city[values][19].price : "-",
        },
        range21: {
          cost: city[values][20] ? city[values][20].cost : "-",
          price: city[values][20] ? city[values][20].price : "-",
        },
        range22: {
          cost: city[values][21] ? city[values][21].cost : "-",
          price: city[values][21] ? city[values][21].price : "-",
        },
      }
    )
  })

  return temp2;
}

const validateFn = (formState, setFormErrorFn) => {
  const errors = [];

  if (get(formState, "volumetric_weight_rates.districtIdDownload", "") === "") {
    errors.push({
      "property": "districtIdDownload",
      "error": "The District field is required"
    })
    setFormErrorFn("volumetric_weight_rates", errors);
    return false;
  } else {
    setFormErrorFn("volumetric_weight_rates", []);
    return true;
  }
}


const generateCSVData = (facilityId, formState, setFormErrorFn, setPageLoader) => {

  setPageLoader(true);

  if (validateFn(formState, setFormErrorFn) === false) {
    setPageLoader(false);
  } else {
    let values = null;
    callApi(`${facilityRateDedicatedAPI.url}/all?facilityId=${facilityId}&status=ACTIVE&districtId=${get(formState, "volumetric_weight_rates.districtIdDownload", "")}`)
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        values = buildResponse(get(result, "data.data", []));

        const headers = ["cityId",
          "city",
          "0_0.5_price",
          "0_0.5_cost",
          "0.5_1_price",
          "0.5_1_cost",
          "1_2_price",
          "1_2_cost",
          "2_3_price",
          "2_3_cost",
          "3_4_price",
          "3_4_cost",
          "4_5_price",
          "4_5_cost",
          "5_6_price",
          "5_6_cost",
          "6_7_price",
          "6_7_cost",
          "7_8_price",
          "7_8_cost",
          "8_9_price",
          "8_9_cost",
          "9_10_price",
          "9_10_cost",
          "10_11_price",
          "10_11_cost",
          "11_12_price",
          "11_12_cost",
          "12_13_price",
          "12_13_cost",
          "13_14_price",
          "13_14_cost",
          "14_15_price",
          "14_15_cost",
          "15_16_price",
          "15_16_cost",
          "16_17_price",
          "16_17_cost",
          "17_18_price",
          "17_18_cost",
          "18_19_price",
          "18_19_cost",
          "19_20_price",
          "19_20_cost",
          "20_plus_price",
          "20_plus_cost",];

        const dataList = [];

        values.forEach((value) => {
          const temp = [];
          temp.push(get(value, "cityId", ""));
          temp.push(get(value, "city", ""));
          temp.push(get(value, "range1.price", ""));
          temp.push(get(value, "range1.cost", ""));
          temp.push(get(value, "range2.price", ""));
          temp.push(get(value, "range2.cost", ""));
          temp.push(get(value, "range3.price", ""));
          temp.push(get(value, "range3.cost", ""));
          temp.push(get(value, "range4.price", ""));
          temp.push(get(value, "range4.cost", ""));
          temp.push(get(value, "range5.price", ""));
          temp.push(get(value, "range5.cost", ""));
          temp.push(get(value, "range6.price", ""));
          temp.push(get(value, "range6.cost", ""));
          temp.push(get(value, "range7.price", ""));
          temp.push(get(value, "range7.cost", ""));
          temp.push(get(value, "range8.price", ""));
          temp.push(get(value, "range8.cost", ""));
          temp.push(get(value, "range9.price", ""));
          temp.push(get(value, "range9.cost", ""));
          temp.push(get(value, "range10.price", ""));
          temp.push(get(value, "range10.cost", ""));
          temp.push(get(value, "range11.price", ""));
          temp.push(get(value, "range11.cost", ""));
          temp.push(get(value, "range12.price", ""));
          temp.push(get(value, "range12.cost", ""));
          temp.push(get(value, "range13.price", ""));
          temp.push(get(value, "range13.cost", ""));
          temp.push(get(value, "range14.price", ""));
          temp.push(get(value, "range14.cost", ""));
          temp.push(get(value, "range15.price", ""));
          temp.push(get(value, "range15.cost", ""));
          temp.push(get(value, "range16.price", ""));
          temp.push(get(value, "range16.cost", ""));
          temp.push(get(value, "range17.price", ""));
          temp.push(get(value, "range17.cost", ""));
          temp.push(get(value, "range18.price", ""));
          temp.push(get(value, "range18.cost", ""));
          temp.push(get(value, "range19.price", ""));
          temp.push(get(value, "range19.cost", ""));
          temp.push(get(value, "range20.price", ""));
          temp.push(get(value, "range20.cost", ""));
          temp.push(get(value, "range21.price", "-"));
          temp.push(get(value, "range21.cost", "-"));
          temp.push(get(value, "range22.price", "-"));
          temp.push(get(value, "range22.cost", "-"));


          dataList.push(temp);
        });

        const finalDataSet = [headers];

        dataList.forEach((value) => {
          finalDataSet.push(value);
        });

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        downloadCSV(csvDataContent, `Volumetric Weight Rates-${Date.now()}.csv`);
        setPageLoader(false);
      })
      .catch((error) => {
        setPageLoader(false);
        console.log(error);
      })
  }
}

const DownloadCSVBtn = (props) => {
  let { facilityId, formState, setFormErrorFn, setPageLoader } = props;
  return (
    <InputButton
      btnText={"Download"}
      elementStyle={"btn btn-primary waves-effect waves-light"}
      onClickBtnFn={() => generateCSVData(facilityId, formState, setFormErrorFn, setPageLoader)}
    />
  );
};

const DownloadModalBody = () => {
  return (
    <div className="col-md-6">
      <SelectBoxWithState
        labelText={"District"}
        apiUrl={`${getDistrictListAPI.url}`}
        apiStateKey={`${getDistrictListAPI.key}_search`}
        inputName={"districtIdDownload"}
        placeHolderText={"District"}
        formGroupName={"volumetric_weight_rates"}
        onRebuildResponseFn={(response) => {
          const data = [];

          response.forEach((value) => {
            data.push({
              id: `${value.id}`,
              value: `${value.name}`,
            });
          });

          return {
            data: {
              data: data,
            },
          };
        }}
      />

    </div>
  )
}

const DownloadModal = (props) => {
  let { facilityId, formState, setFormErrorFn, setPageLoader } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>

      <InputButton
        elementWrapperStyle={"mr-2"}
        btnText={"Download"}
        startIcon={"mdi mdi-content-save-all-outline"}
        onClickBtnFn={() => setOpen(true)}
      />
      <ModalUI
        isOpen={isOpen}
        modalTitle={"Download CSV"}
        modalBodyComponent={<DownloadModalBody />}
        modalFooterComponent={
          <DownloadCSVBtn setPageLoader={setPageLoader} formState={formState} setFormErrorFn={setFormErrorFn} facilityId={facilityId} setModelStatusFn={(value) => setOpen(value)} />
        }
        showHideToggleFn={() => {
          setOpen(false);
        }}
      />

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    formState: state.FormData,
    coreState: state.CoreState
  };
};

const DownloadModalUI = connect(mapStateToProps, {
  setPageLoader,
  setFlashMessage,
  setFormErrorFn,
})(DownloadModal);

export { DownloadModalUI };
