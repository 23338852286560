import React, { Component, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { get } from "lodash";

import { TabComponent, SubTab } from "../../../../../../components/boda-boda/Tab/Tab";
import {
    getManifestListAPI,
    getOrdersListAPI,
    getPackageListAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import FilterUI from "../../../../pick-request/includes/FilterUI";
import { dateObjectToString } from "../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { actionBtnTypes } from "../../../../../../components/boda-boda/CRUD/configs/crud.config";
import { CrudComponent } from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { addPickupsToRunSheetLast, removePickupsToRunSheetLast, addDeliveriesToRunSheetLast, removeDeliveriesToRunSheetLast } from "../../../../../../store/boda-boda/transaction_request/actions";
import { setFlashMessage } from "../../../../../../store/boda-boda/ui/actions";
import { ModalUI } from "../../../../../../components/boda-boda/Modal/ModalUI";
import { InputButton, SubmitButton } from "../../../../../../components/boda-boda/Forms";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../../../config/boda-boda/apiResponseKey";
import PickRequestViewModalUI from "../../../../pick-request/common-includes/PickRequestViewModalUI";
import PackageViewModalUI from "../../../../packages/common_include/PackageViewModalUI";
import ConsignmentViewModalUI from "../../../../consignments/common-includes/ConsignmentViewModalUI";
import { HIDE_RIGHT_SIDEBAR } from "../../../../../../store/layout/actionTypes";


class PickUps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            showModal: false,
            manifest: null,
            deliveryManifest: "fetching",
            package: null,
            showDeliveryModal: false
        }
    }

    componentDidMount() {
        this.checkDeliveryManifest();
    }

    checkDeliveryManifest = async () => {
        let { runSheetId } = this.props;

        const response = await callApi(`${getManifestListAPI.url}/compressed?page=1&limit=20&type=DELIVERY&runSheetLastId=${runSheetId}`)
            .headers(true)
            .method("get")
            .send();

        if (response._statue === true) {
            if (get(response, "data.data", []).length === 0) {
                this.setState({
                    ...this.state,
                    deliveryManifest: "not_found"
                })
            } else {
                this.setState({
                    ...this.state,
                    deliveryManifest: get(response, "data.data", [])[0]
                })
            }
        }
    }

    addPickup = (row) => {
        let { runSheetId, driver, addPickupsToRunSheetLast, setFlashMessage } = this.props;

        if (driver === null) {
            setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger"
            })
        } else {
            this.setState({
                ...this.state,
                manifest: row
            }, () => {
                addPickupsToRunSheetLast({
                    "runSheetLast": Number(runSheetId),
                    "driver": Number(driver.id),
                    "manifest": row,
                    "force": false,
                    "showModalFn": this.showModalFn,
                    "closeModalFn": this.closeModalFn
                });
            })
        }
    }

    deletePickup = (row) => {
        let { runSheetId, removePickupsToRunSheetLast } = this.props;

        removePickupsToRunSheetLast({
            "runSheetLast": Number(runSheetId),
            "manifest": row,
        });
    }

    showModalFn = (state) => {
        this.setState({
            ...this.state,
            [state]: true,
        })
    }

    closeModalFn = () => {
        this.setState({
            ...this.state,
            manifest: null,
            showModal: false,
            package: null,
            showDeliveryModal: false
        })
    }

    refreshDeliveryTabFn = () => {
        this.setState({
            ...this.state,
            deliveryManifest: "fetching"
        }, () => {
            this.checkDeliveryManifest();
        })
    }

    render() {
        let { authState, runSheetId, driver, addPickupsToRunSheetLast,
            addDeliveriesToRunSheetLast, coreState, status
        } = this.props;

        return (
            <div className={"container-fluid"}>
                <TabComponent
                    activeTab={this.state.activeTab}
                    onClickFn={(tabId) => this.setState({ activeTab: tabId })}
                >
                    <SubTab tabId={0} tabName={"Pickups"}>
                        <div className="card mb-1">
                            <div className="card-body">

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-2"}>No of Pick-Requests</dd>
                                            <dt className={"col-sm-10"}>{get(coreState, "dataTableResponses.manifestListAPIKey_runSheetLast_selected.total", 0)}</dt>
                                        </dl>
                                    </div>

                                    <CrudComponent
                                        title={"Pick-Requests"}
                                        apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=MERCHANT&runSheetLastId=null&status=ACCEPTED${get(authState, "authUser.facility.id", null) === null ? "" : `&facilityId=${get(authState, "authUser.facility.id")}`}`}
                                        dataTableStateKey={`${getManifestListAPI.key}_runSheetLast`}
                                        isFilter={true}
                                        filterFromComponent={FilterUI}
                                        isSetCreateAction={false}
                                        tableHeaderList={[
                                            { displayName: "Manifest Code", key: "code" },
                                            { displayName: "Merchant Name", key: "merchant.name" },
                                            { displayName: "Pick Up Date", key: "pickAt", onPrintFn: (rawData) => dateObjectToString(rawData.pickAt, "dd-mm-YY") },
                                            { displayName: "Pick Up Location", key: "locationPick.name" },
                                            {
                                                displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                                    if (rawData.status === "PUBLISH") {
                                                        return "PENDING";
                                                    } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                                        return "PICKED UP";
                                                    } else if (rawData.status === "COMPLETED") {
                                                        return "PICKUP COMPLETED";
                                                    } else {
                                                        return rawData.status;
                                                    }
                                                }
                                            },
                                            { displayName: "No of Packages", key: "publishedPackages", onPrintFn: (rawData) => rawData?.publishedPackages?.length },
                                        ]}
                                        actionList={[
                                            {
                                                actionType: actionBtnTypes.view,
                                                isSetModel: true,
                                                modalProps: {
                                                    modalTitle: "View Pick Up",
                                                    modalType: "modal-lg",
                                                    modalBodyComponent: PickRequestViewModalUI,
                                                    isSetClose: true,
                                                    isSetFooterClose: true,
                                                }
                                            },
                                            {
                                                actionType: actionBtnTypes.create,
                                                isSetModel: false,
                                                actionBtn: {
                                                    tooltip: "Add",
                                                    onClickBtnFn: (row) => this.addPickup(row)
                                                },
                                                checkBeforeActionRenderFn: (rawData) => {
                                                    return rawData.status !== "STARTED";
                                                }
                                            }
                                        ]}
                                    />
                                </div>

                                <div className={"row"}>
                                    <CrudComponent
                                        title={"Selected Pick-Requests"}
                                        apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=MERCHANT&runSheetLastId=${this.props.runSheetId}`}
                                        dataTableStateKey={`${getManifestListAPI.key}_runSheetLast_selected`}
                                        isSetCreateAction={false}
                                        isFilter={false}
                                        tableHeaderList={[
                                            { displayName: "Manifest Code", key: "code" },
                                            { displayName: "Merchant Name", key: "merchant.name" },
                                            { displayName: "Pick Up Date", key: "pickAt", onPrintFn: (rawData) => dateObjectToString(rawData.pickAt, "dd-mm-YY") },
                                            { displayName: "Pick Up Location", key: "locationPick.name" },
                                            {
                                                displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                                    if (rawData.status === "PUBLISH") {
                                                        return "PENDING";
                                                    } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                                        return "PICKED UP";
                                                    } else if (rawData.status === "COMPLETED") {
                                                        return "PICKUP COMPLETED";
                                                    } else {
                                                        return rawData.status;
                                                    }
                                                }
                                            },
                                            { displayName: "No of Packages", key: "publishedPackages", onPrintFn: (rawData) => rawData?.publishedPackages?.length },
                                        ]}
                                        actionList={[
                                            {
                                                actionType: actionBtnTypes.view,
                                                isSetModel: true,
                                                modalProps: {
                                                    modalTitle: "View Pick Up",
                                                    modalType: "modal-lg",
                                                    modalBodyComponent: PickRequestViewModalUI,
                                                    isSetClose: true,
                                                    isSetFooterClose: true,
                                                }
                                            },
                                            {
                                                actionType: actionBtnTypes.delete,
                                                isSetModel: false,
                                                actionBtn: {
                                                    onClickBtnFn: (row) => this.deletePickup(row)
                                                },
                                                checkBeforeActionRenderFn: (rawData) => {
                                                    return rawData.rowData.status === "ACCEPTED";
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </SubTab>
                </TabComponent>

                <ModalUI
                    isOpen={this.state.showModal}
                    modalTitle={"Please Confirm"}
                    modalBodyComponent={<p>Pick Up location does not belong to the runsheet route. Are you sure want to add this pickup?</p>}
                    modalFooterComponent={<InputButton
                        btnText={"Yes"}
                        onClickBtnFn={() => addPickupsToRunSheetLast({
                            "runSheetLast": Number(runSheetId),
                            "driver": Number(driver.id),
                            "manifest": this.state.manifest,
                            "force": true,
                            "showModalFn": this.showModalFn,
                            "closeModalFn": this.closeModalFn
                        })}
                    />}
                    showHideToggleFn={this.closeModalFn}
                    closeBtnName={"No"}
                />

                <ModalUI
                    isOpen={this.state.showDeliveryModal}
                    modalTitle={"Please Confirm"}
                    modalBodyComponent={<p>Delivery location does not belong to the runsheet route. Are you sure want to add this delivery?</p>}
                    modalFooterComponent={<InputButton
                        btnText={"Yes"}
                        onClickBtnFn={() => {
                            addDeliveriesToRunSheetLast({
                            "runSheetLast": Number(runSheetId),
                            "manifest": this.state.deliveryManifest,
                            "driver": driver,
                            "package": this.state.package,
                            "force": true,
                            "showModalFn": this.showModalFn,
                            "closeModalFn": this.closeModalFn,
                            "refreshDeliveryTabFn": this.refreshDeliveryTabFn
                        });
                    }}
                    />}
                    showHideToggleFn={this.closeModalFn}
                    closeBtnName={"No"}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState,
        coreState: state.CoreState
    };
};

export default connect(mapStateToProps, {
    addPickupsToRunSheetLast,
    setFlashMessage,
    removePickupsToRunSheetLast,
    addDeliveriesToRunSheetLast,
    removeDeliveriesToRunSheetLast
})(PickUps);
