import React from "react";
import {SubmitButton} from "../../../../../../components/boda-boda/Forms";
import {apiResponseStoringType} from "../../../../../../config/boda-boda/apiResponseKey";
import {getMerchantContactAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import {getDataByFormObject} from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";

const RemoveMessage = () => {
    return (
        <p>Are you sure you want to delete this contact detail?</p>
    );
}

const RemoveBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Delete"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            dataTableKey={getMerchantContactAPI.key}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getMerchantContactAPI.url}/${props.row.id}`,
                    key: getMerchantContactAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    )
}

export {
    RemoveBtn,
    RemoveMessage
}