import React, { Component, Fragment } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent"
import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { getPackageListAPI, getManifestListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { isEmptyValue, printModalHelperFn } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { currency } from "../../../../config/boda-boda/db_config/db_status.config";


class DeliveryPrint extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    btnText="Print Preview"
                    onClickBtnFn={() => onClickFn()}
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fa fa-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Delivery"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<LabelPrint row={this.props.delivery} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLabelBtn type={"create"} />
                    }
                />
            </Fragment>
        );

    }
}

const LabelPrint = (props) => {
    const formState = useSelector(state => state.FormData);
    return (

        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_print`}
                    apiURL={`${getManifestListAPI.url}/${get(props, `row.id`, "")}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code: response.code,
                            status: response.status,
                            facility: response.facility,
                            locationPick: response.locationPick,
                            locationDrop: response.locationDrop,
                            note: get(response, "notes[0].note", ""),
                            locationCurrent: response.locationCurrent,
                            runSheetLastId: response.runSheetLastId,
                            runSheetLast: response.runSheetLast,
                            packages: response.packages,
                            runSheetLine: response.runSheetLine,
                            runSheetLineId: response.runSheetLineId,
                            merchant: response.merchant,
                            publishedPackages: response.publishedPackages,
                            orderCount: get(response, "merchant.orderCount", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Delivery Code</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.code`, "")}</dt>

                                {!isEmptyValue(get(props, `row.runSheetLastId`, "")) ? (
                                    <React.Fragment>
                                        <dd className={"col-sm-5"}>Runsheet Code</dd>
                                        <dt className={"col-sm-7"}>{get(props, `row.runSheetLast.code`, "")}</dt>
                                    </React.Fragment>
                                ) : null}

                                {!isEmptyValue(get(props, `row.runSheetLineId`, "")) ? (
                                    <React.Fragment>
                                        <dd className={"col-sm-5"}>Line Runsheet Code</dd>
                                        <dt className={"col-sm-7"}>{get(props, `row.runSheetLine.code`, "")}</dt>
                                    </React.Fragment>
                                ) : null}

                                <dd className={"col-sm-5"}>Status</dd>
                                <dt className={"col-sm-7"}>
                                    {get(props, `row.status`, "") === "DRAFT" ? "OPEN"
                                        : get(props, `row.status`, "") === "PUBLISH" ? "CLOSED"
                                            : get(props, `row.status`, "") === "COMPLETED" ? "Delivered to regional hub"
                                                : get(props, `row.status`, "") === "IN_TRANSIT_LINE" ? "Intransit to regional hub"
                                                    : get(props, `row.status`, "")
                                    }
                                </dt>

                                <dd className={"col-sm-5"}>Destination Delivery Center</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationDrop.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Special Notes</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.note`, "")}</dt>

                                {!isEmptyValue(get(props, `row.runSheetLineId`, "")) ? (
                                    <React.Fragment>
                                        <dd className={"col-sm-5"}>Line Runsheet Date</dd>
                                        <dt className={"col-sm-7"}>{!isEmptyValue(get(props, `row.runSheetLine.runSheetAt`, "")) ? dateObjectToString(get(props, `row.runSheetLine.runSheetAt`, ""), "dd-mm-YY") : "-"}</dt>
                                    </React.Fragment>
                                ) : null}

                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Current Location</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationCurrent.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Pick Location</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationPick.name`, "-")}</dt>

                                <dd className={"col-sm-5"}>Packages Count</dd>
                                <dt className={"col-sm-7"}>{
                                    get(props, `row.status`, "") === "DRAFT" ? get(props, `row.packages.length`, "-")
                                        : get(props, `row.publishedPackages.length`, "-")
                                }
                                </dt>

                                {!isEmptyValue(get(props, `row.runSheetLastId`, "")) ? (
                                    <React.Fragment>
                                        <dd className={"col-sm-5"}>Driver Name</dd>
                                        <dt className={"col-sm-7"}>{get(props, `row.runSheetLast.driver.name`, "")}</dt>

                                        <dd className={"col-sm-5"}>Runsheet Date</dd>
                                        <dt className={"col-sm-7"}>{!isEmptyValue(get(props, `row.runSheetLast.runSheetAt`, "")) ? dateObjectToString(get(props, `row.runSheetLast.runSheetAt`, ""), "dd-mm-YY") : "-"}</dt>
                                    </React.Fragment>
                                ) : null}

                            </dl>
                        </div>
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Packages"
                            apiURL={get(props, `row.status`, "") === "DRAFT" ?
                                `${getPackageListAPI.url}/all?manifestId=${get(props, `row.id`, "")}`
                                : `${getManifestListAPI.url}/${get(props, `row.id`, "")}/published-packages?page=1&limit=20`
                            }
                            dataTableStateKey={getPackageListAPI.key}
                            isFilter={false}
                            isSetAction={false}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                {
                                    displayName: "Channel Order No", key: "order.channelOrderNo",
                                    onPrintFn: (rawData) => rawData?.order?.channelOrderNo ? rawData?.order?.channelOrderNo : "-"
                                },
                                { displayName: "Customer Name", key: "order.name" },
                                {
                                    displayName: "Customer Address/ City", key: "order.shippingLocation.address",
                                    onPrintFn: (rawData) => `${rawData?.order?.shippingLocation?.address}/ ${rawData?.order?.shippingLocation?.city?.name}`
                                },
                                { displayName: "Customer Phone", key: "order.phone" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                {
                                    displayName: `COD Amount (${currency})`, key: "order.amount",
                                    onPrintFn: (rawData) => rawData?.order?.paymentMethod === "PREPAID" ? "-" : rawData?.order?.amount
                                },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        </div>
    );
}

const PrintLabelBtn = (props) => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => { printModalHelperFn("print_preview") }
            }
        />
    )
}

export {
    DeliveryPrint,
    LabelPrint,
    PrintLabelBtn
};