import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { roleTypes, userRoleStatus } from "../../../../config/boda-boda/db_config/db_status.config";

class FilterUI extends Component {
    render() {
        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Role ID"
                            inputName={"id"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Role ID"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Role Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Role Name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Role Type"
                            inputName={"type"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Role Type"}
                            dataList={roleTypes}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={userRoleStatus}
                            placeHolderText={"Status"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export {
    FilterUI
}
