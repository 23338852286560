import React, { Component } from "react";
import {Modal} from "reactstrap";
import {modalTypes} from "./config/model.config";

const emptyFun = (...para) => undefined;

class ModalUI extends Component {
  static defaultProps = {
    isOpen: false,
    showHideToggleFn: emptyFun,
    modalTitle: "",
    modalType: "",
    modalBodyComponent: null,
    modalFooterComponent: null,
    isSetClose: true,
    isSetFooterClose: true,
    closeBtnName: "Close"
  };

  constructor(props) {
    super(props);
  }

  render() {
    let {
      isOpen,
      showHideToggleFn,
      modalTitle,
      modalType,
      modalBodyComponent,
      modalFooterComponent,
      isSetClose,
      isSetFooterClose,
      closeBtnName
    } = this.props;

    return (
      <Modal className={modalType} isOpen={isOpen} toggle={() => showHideToggleFn()}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modalTitle}
          </h5>

          {
            isSetClose === true ? (
            <button
              type="button"
              onClick={() => showHideToggleFn()}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          ) : (null)
          
          }
        </div>
        <div className="modal-body">{modalBodyComponent}</div>
        <div className="modal-footer">
          {modalFooterComponent} 
          {
              (isSetFooterClose===true)?(
                <button
                type="button"
                onClick={() => showHideToggleFn()}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                  {closeBtnName}
              </button>
              ):(null)
          }
        </div>
      </Modal>
    );
  }
}

export { ModalUI,modalTypes };
