import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    SubmitButton,
} from "../../../../components/boda-boda/Forms";
import {
    getOrdersListAPI,
    getPackageListAPI
} from "../../../../config/boda-boda/apiUrls.config";
import {
    mapPackageStatusesNames,
    orderStatusList
} from "../../../../config/boda-boda/db_config/db_status.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject,
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import ExpansionPanel from "../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { modalTypes } from "../../../../components/boda-boda/Modal/ModalUI";
import { LabelPrint, PrintLabelBtn } from "../../packages/includes/ShipingLabel";
import { ShippingLabelUI } from "../../packages/includes/ShippingLabelUI";


class EditUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            receiverPanel: false,
            locationPanel: false,
        }
    }

    render() {
        let { history, match, formState, location } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getOrdersListAPI.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            email: response.email,
                            consignmentNo: response.consignmentNo,
                            idVerificationRequired: response.idVerificationRequired,
                            idVerificationType: response.idVerificationType,
                            idVerificationReference: response.idVerificationReference,
                            phone: response.phone,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            status: response.status,
                            deliveryType: response.deliveryType,
                            paymentMethod: response.paymentMethod,
                            signatureRequired: response.signatureRequired,
                            paid: response.paid,
                            shippingLocation: response.shippingLocation,
                            amount: response.amount,
                            merchant: response.merchant.name,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Edit Consignment</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <EditBtn
                                    historyFn={history}
                                    orderId={match.params.id}
                                />

                                <BackBtnComponent
                                    isGoBack={true}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card mb-1">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Consignment Details</h4>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-4"}>Consignment No</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getOrdersListAPI.key}_edit.consignmentNo`, "")}</dd>

                                                <dt className={"col-sm-4"}>Merchant</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getOrdersListAPI.key}_edit.merchant`, "")}</dd>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-4"}>Delivery Type</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getOrdersListAPI.key}_edit.deliveryType`, "")}</dd>

                                                <dt className={"col-sm-4"}>Payment Method</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getOrdersListAPI.key}_edit.paymentMethod`, "")}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Signature Required"
                                                inputName={"signatureRequired"}
                                                placeHolderText={"Select a Option"}
                                                formGroupName={`${getOrdersListAPI.key}_edit`}
                                                dataList={[
                                                    {
                                                        id: true,
                                                        value: "Yes"
                                                    },
                                                    {
                                                        id: false,
                                                        value: "No"
                                                    }
                                                ]}
                                            />
                                        </div>

                                        {/* <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Delivery Type"
                                                inputName={"deliveryType"}
                                                placeHolderText={"Select a Delivery Type"}
                                                formGroupName={`${getOrdersListAPI.key}_edit`}
                                                dataList={deliveryTypes}
                                            />
                                        </div> */}

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isDisabled={true}
                                                isRequired={true}
                                                labelText="Status"
                                                inputName={"status"}
                                                placeHolderText={"Select a Status"}
                                                formGroupName={`${getOrdersListAPI.key}_edit`}
                                                dataList={orderStatusList}
                                            />
                                        </div>

                                        {/* <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Payment Method"
                                                inputName={"paymentMethod"}
                                                placeHolderText={"Select a Payment Method"}
                                                formGroupName={`${getOrdersListAPI.key}_edit`}
                                                dataList={paymentMethods}
                                            />
                                        </div> */}

                                        {
                                            (get(formState, `${getOrdersListAPI.key}_edit.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        // isRequired={true}
                                                        isDisabled={true}
                                                        labelText="Amount"
                                                        placeHolderText={"Enter Order Amount"}
                                                        inputName={"amount"}
                                                        inputType={"number"}
                                                        formGroupName={`${getOrdersListAPI.key}_edit`}
                                                        maxLength={10}
                                                    />
                                                </div>
                                            ) : null
                                        }

                                    </div>

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Special Note"
                                                inputName={"notesPublic"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={`${getOrdersListAPI.key}_edit`}
                                            />
                                        </div>

                                        {/* <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Private Note"
                                                inputName={"notesPrivate"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={`${getOrdersListAPI.key}_edit`}
                                            />
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Customer Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.phone`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>ID Verification Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.idVerificationRequired`, false) === false ? "No" : "Yes"}</dt>
                                    {
                                        (get(formState, `${getOrdersListAPI.key}_edit.idVerificationRequired`, false) === true) ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.idVerificationType`, "")}</dt>

                                                <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.idVerificationReference`, "")}</dt>
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.postalCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.lat`, "")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.lon`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_edit.shippingLocation.city.name`, "")}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <div className={"row"}>
                        <CrudComponent
                            title="Packages"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                            dataTableStateKey={getPackageListAPI.key}
                            isFilter={false}
                            isDropdownActionBtn={true}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Package No", key: "id" },
                                { displayName: "Package Type", key: "type.name" },
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Delivery Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                { displayName: "Package Category", key: "category.name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.status === "RECEIVED" || rawData.rowData.status === "SORTED" || rawData.rowData.status === "NOT_DELIVERED") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "fa fa-barcode",
                                        color: "primary",
                                        tooltip: "Shipping Label",
                                        btnText: "Shipping_Label"
                                    },
                                    modalProps: {
                                        modalTitle: "Shipping Label",
                                        modalType: modalTypes.large,
                                        modalBodyComponent: (rawData) => {
                                            return <ShippingLabelUI packages={[rawData.row]}/>
                                          },
                                        modalFooterComponent: PrintLabelBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                }
                            ]}
                        />
                    </div>
                </FormWrapper>

            </div>
        )
    }
}

const EditBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getOrdersListAPI.key}_edit`}
            dataTableKey={getOrdersListAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    status: "Status",
                    // deliveryType: "Delivery Type",
                    // paymentMethod: "Payment Method",
                    signatureRequired: "Signature Required",
                    // amount: "Amount",
                },
                rules: {
                    status: "required",
                    // deliveryType: "required",
                    // paymentMethod: "required",
                    signatureRequired: "required",
                    // amount: "requiredIfMultiple:paymentMethod,CASH_ON_DELIVERY,CARD_ON_DELIVERY|max:10",
                },
                message: {
                    // "amount.requiredIfMultiple": "The Amount field is required when payment method is either Cash on Delivery or Card on Delivery"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Consignment has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${props.orderId}`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                return {
                    ...data,
                    signatureRequired: formObjectValidationHelper(data, "signatureRequired", (data) => { return data === true }),
                    paid: data.paymentMethod === "PREPAID",
                    amount: formObjectValidationHelper(data, "amount", (data) => { return Number(data) }),
                    shippingLocation: undefined,
                    merchant: undefined,
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push("/consignments");
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
    };
};

const ConsignmentEdit = connect(mapStateToProps, null)(EditUI);

export { ConsignmentEdit }
