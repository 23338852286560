import React, { memo, Fragment } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import { toBoolean } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { formCacheLevel } from "../../../config/boda-boda/template.config";


const emptyFn = (...para) => undefined;

const CheckBox = ({
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    inputName = "",
    inputValue = false,
    isDisabled = false,
    helperText = "",
    onChangeFn = emptyFn,
}) => {
    return (
        <div className={`defaultCheckBoxWrapper ${elementWrapperStyle}`}>
            <FormGroup check>
                <Label check>
                    <Input
                        type={"checkbox"}
                        className={`${elementStyle}`}
                        name={inputName}
                        checked={toBoolean(inputValue)}
                        disabled={isDisabled}
                        onChange={(event) => {
                            onChangeFn({
                                value: !toBoolean(inputValue),
                                name: inputName,
                                eventInfo: event
                            })
                        }}
                    />
                    {labelText}
                </Label>
            </FormGroup>
            <small>
                {helperText}
            </small>
        </div>
    )
}

const CheckBoxGroup = ({
    elementWrapperStyle = "",
    elementStyle = "",
    colClassName = "col-md-4",
    labelText = "",
    inputName = "",
    inputValue = [],
    inputError = "",
    isRequired = false,
    isDisabled = false,
    isCustomCheckBox = false,
    keyName = 'id',
    valueName = 'value',
    dataList = [],
    isInline = false,
    helperText = "",
    onChangeFn = emptyFn,
}) => {
    return (
        <FormGroup className={`${elementWrapperStyle}`}>
            <Label className={`${inputError ? "selectBoxLabelStyle" : ""}`}>{labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}</Label>
            {
                (isCustomCheckBox) ? (
                    <CustomCheckBox
                        elementStyle={elementStyle}
                        colClassName={colClassName}
                        dataList={dataList}
                        inputName={inputName}
                        inputValue={inputValue}
                        isDisabled={isDisabled}
                        isInline={isInline}
                        keyName={keyName}
                        valueName={valueName}
                        onChangeFn={onChangeFn}
                    />
                ) : (
                    <FormGroup style={{ marginBottom: "0" }}>
                        {
                            (dataList || []).map((row, index) => {
                                return (
                                    <FormGroup check key={index} inline={isInline}>
                                        <Label check>
                                            <Input
                                                type={"checkbox"}
                                                className={`${elementStyle}`}
                                                name={inputName}
                                                checked={inputValue.includes(row[keyName])}
                                                disabled={isDisabled}
                                                onChange={(event) => {
                                                    let temp = [...inputValue];
                                                    if (temp.includes(row[keyName])) {
                                                        temp = temp.filter((element) => element != row[keyName]);
                                                    } else {
                                                        temp.push(row[keyName]);
                                                    }

                                                    onChangeFn({
                                                        value: temp,
                                                        name: inputName,
                                                        eventInfo: event
                                                    })
                                                }}
                                            />
                                            {row[valueName]}
                                        </Label>
                                    </FormGroup>
                                )
                            })
                        }
                    </FormGroup>
                )
            }
            <small className={`${inputError ? "helperTextErrorStyle" : ""}`}>
                {inputError ? inputError : helperText}
            </small>
        </FormGroup>
    )
}

const CustomCheckBox = ({
    elementStyle = "",
    colClassName = "col-md-4",
    inputName = "",
    inputValue = [],
    isDisabled = false,
    keyName = 'id',
    valueName = 'value',
    dataList = [],
    isInline = false,
    onChangeFn = emptyFn,
}) => {
    return (
        <FormGroup style={{ marginBottom: "0" }}>
            <div className={"row"}>
                {
                    (dataList || []).map((row, index) => {
                        return (
                            <div className={colClassName} key={index}>
                                <FormGroup check inline={isInline}>
                                    <Label check>
                                        <Input
                                            type={"checkbox"}
                                            className={`${elementStyle}`}
                                            name={inputName}
                                            checked={inputValue.includes(row[keyName])}
                                            disabled={isDisabled}
                                            onChange={(event) => {
                                                let temp = [...inputValue];
                                                if (temp.includes(row[keyName])) {
                                                    temp = temp.filter((element) => element != row[keyName]);
                                                } else {
                                                    temp.push(row[keyName]);
                                                }

                                                onChangeFn({
                                                    value: temp,
                                                    name: inputName,
                                                    eventInfo: event
                                                })
                                            }}
                                        />
                                        {row[valueName]}
                                    </Label>
                                </FormGroup>
                            </div>
                        )
                    })
                }
            </div>
        </FormGroup>
    )
}

const areEqual=(prevProps, nextProps) =>{
    if (nextProps.cacheLevel===formCacheLevel.none) {
        return false;
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.labelText===nextProps.labelText) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) &&
            (prevProps.inputValue===nextProps.inputValue) && (prevProps.updateStatus===nextProps.updateStatus)
        )
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.labelText===nextProps.labelText) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.inputValue===nextProps.inputValue)
        )
    }
}

const areEqualCheckBoxGroup =(prevProps, nextProps) =>{
    if (nextProps.cacheLevel===formCacheLevel.none) {
        return false;
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.labelText===nextProps.labelText) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.dataList.length===nextProps.dataList.length) &&
            (prevProps.inputValue===nextProps.inputValue) && (prevProps.updateStatus===nextProps.updateStatus)
        )
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) && (prevProps.dataList.length===nextProps.dataList.length) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.labelText===nextProps.labelText) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.inputValue===nextProps.inputValue)
        )
    }
}

const CheckBoxMemo = memo(CheckBox, areEqual);
const CheckBoxGroupMemo = memo(CheckBoxGroup, areEqualCheckBoxGroup);

export {
    CheckBox,
    CheckBoxMemo,
    CheckBoxGroup,
    CheckBoxGroupMemo
}
