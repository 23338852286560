import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get, filter, find, uniqBy } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
    RadioButtonWithState,
    InputBoxWithState
} from "../../../../components/boda-boda/Forms";
import { currency, weightMetric, packageStatusReportStatuses, codStatuses, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    roundValue,
    convertToCsvValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn, changeInputFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { merchantAPI, getCityListAPI, getZoneAPI, getUserDriverList, getAllFacilityAPI, getMerchantBranchAllAPI, packageStatusReportDedicatedAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setApiResponse } from "../../../../store/actions";
import { setFlashMessage } from '../../../../store/boda-boda/ui/actions'


class PackageStatusReport extends Component {

    onGenerateFn = () => {
        let { formState, history } = this.props;
        if (this.validateFn() === true) {
            let createdDateTo = new Date(get(formState, "package_status_search.createdDateTo"));
            createdDateTo = new Date(createdDateTo.setDate(createdDateTo.getDate() + 1));
            createdDateTo = new Date(createdDateTo.setSeconds(createdDateTo.getSeconds() - 1));

            let deliveredDateTo = new Date(get(formState, "package_status_search.deliveredDateTo"));
            deliveredDateTo = new Date(deliveredDateTo.setDate(deliveredDateTo.getDate() + 1));
            deliveredDateTo = new Date(deliveredDateTo.setSeconds(deliveredDateTo.getSeconds() - 1));

            let returnedDateTo = new Date(get(formState, "package_status_search.returnedDateTo"));
            returnedDateTo = new Date(returnedDateTo.setDate(returnedDateTo.getDate() + 1));
            returnedDateTo = new Date(returnedDateTo.setSeconds(returnedDateTo.getSeconds() - 1));

            let failedDeliveryDateTo = new Date(get(formState, "package_status_search.failedDeliveryDateTo"));
            failedDeliveryDateTo = new Date(failedDeliveryDateTo.setDate(failedDeliveryDateTo.getDate() + 1));
            failedDeliveryDateTo = new Date(failedDeliveryDateTo.setSeconds(failedDeliveryDateTo.getSeconds() - 1));

            const queryObj = {
                createdDateFrom: get(formState, "package_status_search.createdDateFrom").toISOString(),
                deliveredDateFrom: get(formState, "package_status_search.deliveredDateFrom") ? get(formState, "package_status_search.deliveredDateFrom").toISOString() : undefined,
                createdDateTo: createdDateTo.toISOString(),
                deliveredDateTo: isEmptyValue(get(formState, "package_status_search.deliveredDateTo")) ? undefined : deliveredDateTo.toISOString(),
                deliveryType: get(formState, "package_status_search.deliveryType"),
                status: get(formState, "package_status_search.status"),
                merchantId: get(formState, "package_status_search.merchantId").split("/")[0],
                merchantBranchId: get(formState, "package_status_search.merchantBranchId").split("/")[0],
                zoneId: get(formState, "package_status_search.zoneId").split("/")[0],
                cityId: get(formState, "package_status_search.cityId"),
                customerName: get(formState, "package_status_search.customerName", ""),
                channelOrderNo: get(formState, "package_status_search.channelOrderNo", ""),
                deliveredBy: get(formState, "package_status_search.deliveredBy", "")?.split("/")[0],
                paymentMethod: get(formState, "package_status_search.paymentMethod"),
                status: get(formState, "package_status_search.status"),
                pickedUp: get(formState, "package_status_search.pickedUp"),
                locationCurrentId: get(formState, "package_status_search.locationCurrentId")?.split("/")[0],
                locationFinalFacilityId: get(formState, "package_status_search.locationFinalFacilityId")?.split("/")[0],
                lodgementFacilityId: get(formState, "package_status_search.lodgementFacilityId")?.split("/")[0],
                returnedDateFrom: get(formState, "package_status_search.returnedDateFrom") ? get(formState, "package_status_search.returnedDateFrom").toISOString() : undefined,
                returnedDateTo: isEmptyValue(get(formState, "package_status_search.returnedDateTo")) ? undefined : returnedDateTo.toISOString(),
                failedDeliveryDateFrom: get(formState, "package_status_search.failedDeliveryDateFrom") ? get(formState, "package_status_search.failedDeliveryDateFrom").toISOString() : undefined,
                failedDeliveryDateTo: isEmptyValue(get(formState, "package_status_search.failedDeliveryDateTo")) ? undefined : failedDeliveryDateTo.toISOString(),
                codSettlementStatus: get(formState, "package_status_search.codSettlementStatus"),
            }


            if (get(formState, "package_status_search.type", "screen") === "screen") {
                history.push("/reports/package-status-report/view");
            } else {
                this.getAPIData(fromObjectToQueryString(queryObj));
            }
        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "package_status_search.createdDateFrom", ""))) {
            errors.push({
                "property": "createdDateFrom",
                "error": "The Created Date From field is required"
            })
        }

        if (isEmptyValue(get(formState, "package_status_search.createdDateTo", ""))) {
            errors.push({
                "property": "createdDateTo",
                "error": "The Created Date To field is required"
            })
        }

        if (!isEmptyValue(get(formState, "package_status_search.deliveredDateFrom", "")) || !isEmptyValue(get(formState, "package_status_search.deliveredDateTo", ""))) {
            if (isEmptyValue(get(formState, "package_status_search.deliveredDateFrom", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "deliveredDateFrom",
                    "error": "Delivery date from is required if delivery date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "package_status_search.deliveredDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "deliveredDateTo",
                    "error": "Delivery date to is required if delivery date to is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "package_status_search.deliveredDateFrom", "")) > new Date(get(formState, "package_status_search.deliveredDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "deliveredDateTo",
                    "error": "The delivery date to To field should not be less than the delivery date to From"
                }]);

                return false;
            }
        }

        if (!isEmptyValue(get(formState, "package_status_search.returnedDateFrom", "")) || !isEmptyValue(get(formState, "package_status_search.returnedDateTo", ""))) {
            if (isEmptyValue(get(formState, "package_status_search.returnedDateFrom", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "returnedDateTo",
                    "error": "Returned date from is required if returned date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "package_status_search.returnedDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "returnedDateTo",
                    "error": "Returned date to is required if returned date from is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "package_status_search.returnedDateFrom", "")) > new Date(get(formState, "package_status_search.returnedDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "returnedDateTo",
                    "error": "The returned date to To field should not be less than the returned date to From"
                }]);

                return false;
            }
        }

        if (!isEmptyValue(get(formState, "package_status_search.failedDeliveryDateFrom", "")) || !isEmptyValue(get(formState, "package_status_search.failedDeliveryDateTo", ""))) {
            if (isEmptyValue(get(formState, "package_status_search.failedDeliveryDateFrom", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "failedDeliveryDateFrom",
                    "error": "Failed delivery date from is required if failed delivery date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "package_status_search.failedDeliveryDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "failedDeliveryDateTo",
                    "error": "Failed delivery date to is required if failed delivery date from is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "package_status_search.failedDeliveryDateFrom", "")) > new Date(get(formState, "package_status_search.failedDeliveryDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "failedDeliveryDateTo",
                    "error": "The failed delivery date to field should not be less than the failed delivery date from"
                }]);

                return false;
            }
        }

        if (errors.length === 0) {
            if (new Date(get(formState, "package_status_search.createdDateFrom", "")) > new Date(get(formState, "package_status_search.createdDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "createdDateTo",
                    "error": "The Created Date To field should not be less than the Created Date From"
                }]);

                return false;
            } else {
                setFormErrorFn("package_status_search", []);
                return true;
            }
        } else {
            setFormErrorFn("package_status_search", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${packageStatusReportDedicatedAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);

                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }

                        result.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data", []), get(result, "data.meta.totalsRow", {}));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    displayAttempts = (value) => {
        let finalString = '';
        let counter = 1;
        for (let index = 0; index < value?.packageHistories?.length; index++) {
            if (value?.packageHistories[index]?.status === "DELIVERED" || value?.packageHistories[index]?.status === "NOT_DELIVERED") {
                finalString += `${counter}) ${dateObjectToString(value?.packageHistories[index]?.updatedAt, "YY-mm-dd HH:MM:II")} - ${value?.packageHistories[index]?.driver?.name} - ${value?.packageHistories[index]?.status === "DELIVERED" ? "Delivered" : "Failed Delivery"}  `
                counter++;
            }
        }
        return finalString;
    }

    getAttemptedCount = (value) => {
        let counter = 0;
        for (let index = 0; index < value?.packageHistories?.length; index++) {
            if (value?.packageHistories[index]?.status === "DELIVERED" || value?.packageHistories[index]?.status === "NOT_DELIVERED") {
                counter++;
            }
        }
        return `${counter}`;
    };

    generateCSVData = (data = [], totalData) => {
        let { formState } = this.props;
        const reportName = ["Package Status Report "];
        const dateRange = [`"Created From ${dateObjectToString(get(formState, "package_status_search.createdDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.createdDateTo"), "dd/mm/YY")}"`];
        const deliveredDateRange = [`"Delivered From ${get(formState, "package_status_search.deliveredDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.deliveredDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.deliveredDateTo"), "dd/mm/YY")}` : "All"}"`];
        const returnedDateRange = [`"Returned From ${get(formState, "package_status_search.returnedDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.returnedDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.returnedDateTo"), "dd/mm/YY")}` : "All"}"`];
        const failedDeliveryDateRange = [`"Failed Delivery From ${get(formState, "package_status_search.failedDeliveryDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.failedDeliveryDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.failedDeliveryDateTo"), "dd/mm/YY")}` : "All"}"`];
        const merchant = [`"Merchant: ${convertToCsvValue(get(formState, "package_status_search.merchantId", "") === "" ? "All" : get(formState, "package_status_search.merchantId", "").split("/")[1])}"`];
        const merchantBranchId = [`"Merchant Branch: ${convertToCsvValue(get(formState, "package_status_search.merchantBranchId", "") === "" ? "All" : get(formState, "package_status_search.merchantBranchId", "").split("/")[1])}"`];
        const zone = [`"Zone: ${convertToCsvValue(get(formState, "package_status_search.zoneId", "") === "" ? "All" : get(formState, "package_status_search.zoneId", "").split("/")[1])}"`];
        const city = [`"City: ${convertToCsvValue(get(formState, "package_status_search.cityId", "") === "" ? "All" : get(formState, "package_status_search.selectedCity", ""))}"`];
        const packageStatus = [`"Package Status: ${convertToCsvValue(get(formState, "package_status_search.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "package_status_search.status", "")])}"`];
        const customerName = [`"Customer Name: ${convertToCsvValue(get(formState, "package_status_search.customerName", "") === "" ? "All" : get(formState, "package_status_search.customerName", ""))}"`];
        const orderNo = [`"Order Number: ${convertToCsvValue(get(formState, "package_status_search.channelOrderNo", "") === "" ? "All" : get(formState, "package_status_search.channelOrderNo", ""))}"`];
        const lastMileDriver = [`"Last Mile Driver: ${convertToCsvValue(get(formState, "package_status_search.deliveredBy", "") === "" ? "All" : get(formState, "package_status_search.deliveredBy", "").split("/")[1])}"`];
        const locationFinalFacilityId = [`"Destination Delivery Center: ${convertToCsvValue(get(formState, "package_status_search.locationFinalFacilityId", "") === "" ? "All" : get(formState, "package_status_search.locationFinalFacilityId", "").split("/")[1])}"`];
        const paymentType = [`"Payment Type: ${convertToCsvValue(get(formState, "package_status_search.paymentMethod", "") === "" ? "All" : get(formState, "package_status_search.paymentMethod", ""))}"`];
        const pickedUp = [`"Picked Up: ${convertToCsvValue(get(formState, "package_status_search.pickedUp", "") === "" ? "All" : get(formState, "package_status_search.pickedUp", "") === "true" ? "Yes" : "No")}"`];
        const locationCurrentId = [`"Current Location: ${convertToCsvValue(get(formState, "package_status_search.locationCurrentId", "") === "" ? "All" : get(formState, "package_status_search.locationCurrentId", "").split("/")[1])}"`];
        const lodgementFacilityId = [`"Lodgment Delivery Center: ${convertToCsvValue(get(formState, "package_status_search.lodgementFacilityId", "") === "" ? "All" : get(formState, "package_status_search.lodgementFacilityId", "").split("/")[1])}"`];
        const codSettlementStatus = [`"COD Status: ${convertToCsvValue(get(formState, "package_status_search.codSettlementStatus", "") === "" ? "All" : get(formState, "package_status_search.codSettlementStatus", ""))}"`];
        const headers = ["Created Date", "Article ID", "Tracking Number", "Consignment ID", "Order Number", "Manifest Code", "Pickup Runsheet Code", "Delivered Runsheet Code", "Failed Delivered Runsheet Code", "Customer Name", "Address", "City", "Customer Contact Number", "Zone", "Merchant Code", "Merchant Branch", "Delivery Type", "XL Package",
            "Payment Type", "Lodgement Location", "Last Mile Driver", "Destination Delivery Center", "Picked Up ", "PickUp Date", "Status", "Closure Date/Time", "Status Reason", "COD Status", "Driver Settled Date", "Hub Settled Date", "Hub Settlement Reference", "Merchant Settled Date", "Merchant Settlement Reference", "Merchant Settlement Remark", "Attempted Deliveries", "Total Attempts ", "Last Scan Date/Time", "Current Location", `Actual Weight (${weightMetric})`, `Volumetric Weight (${weightMetric})`,
            `Order Amount (${currency})`, `Cash on Delivery Fee (${currency})`, `Card on Delivery Fee (${currency})`, `Express Fee (${currency})`, `XL Handling Fee (${currency})`, `Delivery Fee (${currency})`, `Delivery Flat Fee (${currency})`, `Total Delivery Fee (${currency})`, `Total Delivery Flat Fee (${currency})`,
        ];
        const dataList = [];
        data.forEach((value) => {
            const temp = [];
            temp.push(value?.createdAt ? dateObjectToString(get(value, "createdAt", "")) : "-");
            temp.push(`"${convertToCsvValue(get(value, "articleNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.trackingNumber", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.consignmentNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.channelOrderNo", "-"))}"`);
            temp.push(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest?.code)}"` : "-");
            temp.push(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest?.runSheetLast?.code)}"` : "-");
            temp.push(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "DELIVERED").length !== 0 ? `"${convertToCsvValue(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "DELIVERED")[0].manifest?.runSheetLast?.code)}"` : "-");
            temp.push(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "NOT_DELIVERED").length !== 0 ? `"${convertToCsvValue(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "NOT_DELIVERED")[0].manifest?.runSheetLast?.code)}"` : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.address", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.city.name", "-"))}"`);
            temp.push(get(value, "order.phone", "-"));
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.city.zone.name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchant.code", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchantBranch.name", "-"))}"`);
            temp.push(get(value, "deliveryType", "-"));
            temp.push(get(value, "xlHandling", false) === false ? "No" : "Yes");
            temp.push(get(value, "order.paymentMethod", "-") === "CASH_ON_DELIVERY" ? "CASH ON DELIVERY" : get(value, "order.paymentMethod", "-") === "CARD_ON_DELIVERY" ? "CARD ON DELIVERY" : get(value, "order.paymentMethod", "-") === "PREPAID" ? "PREPAID" : "-");
            temp.push(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories?.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0]?.manifest?.locationDrop?.name)}"` : "-");
            temp.push(`"${convertToCsvValue(get(value, "courier.staffs[0].name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "locationFinalFacility.name", "-"))}"`);
            temp.push(get(value, "tookOverAt", false) ? "Yes" : "No");
            temp.push(value?.tookOverAt ? dateObjectToString(get(value, "tookOverAt", "")) : "-");
            temp.push(
                get(value, "status", "-") === "PACKED" ?
                    (value.onHold === true) ? "Packed" + "(On Hold)" : "Packed"
                    : get(value, "status", "-") === "IN_TRANSIT_FIRST" ?
                        (value.onHold === true) ? "Package Picked up" + "(On Hold)" : "Package Picked up"

                        : get(value, "status", "-") === "RECEIVED" ?
                            (value.onHold === true) ? "Received at processing Center" + "(On Hold)" : "Received at processing Center"

                            : get(value, "status", "-") === "SORTED" ?
                                (value.onHold === true) ? "Ready for Transit" + "(On Hold)" : "Ready for Transit"

                                : get(value, "status", "-") === "IN_TRANSIT_LINE" ?
                                    (value.onHold === true) ? "Intransit to regional hub" + "(On Hold)" : "Intransit to regional hub"

                                    : get(value, "status", "-") === "IN_TRANSIT_LAST" ?
                                        (value.onHold === true) ? "Out for Delivery" + "(On Hold)" : "Out for Delivery"

                                        : get(value, "status", "-") === "DELIVERED" ?
                                            (value.onHold === true) ? "Delivered" + "(On Hold)" : "Delivered"

                                            : get(value, "status", "-") === "NOT_DELIVERED" ?
                                                (value.onHold === true) ? "Failed Delivery" + "(On Hold)" : "Failed Delivery"
                                                : get(value, "status", "-") === "READY_FOR_RETURN" ?
                                                    (value.onHold === true) ? "Ready for Return" + "(On Hold)" : "Ready for Return"
                                                    : get(value, "status", "-") === "IN_TRANSIT_RETURN" ?
                                                        (value.onHold === true) ? "Return Intransit" + "(On Hold)" : "Return Intransit"
                                                        : get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                                                            (value.onHold === true) ? "Returned to Merchant" + "(On Hold)" : "Returned to Merchant"
                                                            : get(value, "status", "-") === "NOT_RETURNED" ?
                                                                (value.onHold === true) ? "Failed Return" + "(On Hold)" : "Failed Return"
                                                                : "-");
            temp.push(
                get(value, "status", "-") === "DELIVERED" ?
                    (get(value, "deliveredAt", "-") !== null) ? dateObjectToString(get(value, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II") : "-"
                        : get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                            (get(value, "returnedAt", "-") !== null) ? dateObjectToString(get(value, "returnedAt", new Date()), "YY-mm-dd HH:MM:II") : "-"
                                : "-");

            temp.push(
                get(value, "status", "-") === "DELIVERED" ?
                    `"${convertToCsvValue(value.order.remarkDelivery)}"`
                    : get(value, "status", "-") === "NOT_DELIVERED" || get(value, "status", "-") === "READY_FOR_RETURN" || get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                        `"${convertToCsvValue(value.notDeliveredReason)}"`
                        : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.paymentMethod") === "CASH_ON_DELIVERY" ? get(value, "order.codSettlementStatus", "-") : "-")}"`);
            temp.push(value?.order?.driverSettledAt ? dateObjectToString(get(value, "order.driverSettledAt", "-")) : "-");
            temp.push(value?.order?.hubSettlement?.settledAt ? dateObjectToString(get(value, "order.hubSettlement.settledAt", "-")) : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.hubSettlement.reference", "-"))}"`);
            temp.push(value?.order?.headOfficeSettlement?.settledAt ? dateObjectToString(get(value, "order.headOfficeSettlement.settledAt", "-")) : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.headOfficeSettlement.reference", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.headOfficeSettlement.remark", "-"))}"`);
            temp.push(`"${convertToCsvValue(this.displayAttempts(value))}"`);
            temp.push(`"${convertToCsvValue(this.getAttemptedCount(value))}"`);
            temp.push(get(value, `packageHistories[${value?.packageHistories?.length - 1}].updatedAt`, "-") === "-"
                ? "-"
                : dateObjectToString(get(value, `packageHistories[${value?.packageHistories?.length - 1}].updatedAt`, new Date()),
                    "YY-mm-dd HH:MM:II"))
            temp.push(get(value, "locationCurrent.name", "-"));
            temp.push(get(value, "weight", "-"));
            temp.push(get(value, "volumetric", "-"));
            temp.push(isEmptyValue(get(value, "order.amount", "")) ? "-" : get(value, "order.amount", ""));
            temp.push(get(value, "order.paymentMethod", "") === "CASH_ON_DELIVERY" ? get(value, "order.cashDeliveryPrice", "-") : "0.00");
            temp.push(get(value, "order.paymentMethod", "") === "CARD_ON_DELIVERY" ? get(value, "order.cardDeliveryPrice", "-") : "0.00");
            temp.push(get(value, "expressFee", "-"));
            temp.push(get(value, "xlPackageHandlingFee", "-"));
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? get(value, "merchantPrice", "0") : "0");
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? "0" : get(value, "merchantFlatPrice", "0"));
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? get(value, "totalPrice", "0") : "0");
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? "0" : get(value, "totalFlatPrice", "0"));
            dataList.push(temp);
        });

        const finalDataSet = [reportName, [""], dateRange, deliveredDateRange, returnedDateRange, failedDeliveryDateRange, merchant, merchantBranchId, zone, city, locationFinalFacilityId, lodgementFacilityId, paymentType, pickedUp, locationCurrentId, packageStatus, customerName, orderNo, lastMileDriver, codSettlementStatus, [""], [""], headers];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        finalDataSet.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total",
            roundValue(get(totalData, "expressFee", "")),
            roundValue(get(totalData, "xlPackageHandlingFee", "")),
            roundValue(get(totalData, "merchantPrice", "")),
            roundValue(get(totalData, "merchantFlatPrice", "")),
            roundValue(get(totalData, "totalPrice", "")),
            roundValue(get(totalData, "totalFlatPrice", ""))
        ]);

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Package Status Report-${Date.now()}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        let { changeInputFn, formState, setApiResponse } = this.props;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Package Status Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Package Status Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "120px" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"package_status_search"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            createdDateFrom: null,
                                            createdDateTo: null,
                                            deliveredDateFrom: null,
                                            deliveredDateTo: null,
                                            failedDeliveryDateFrom: null,
                                            failedDeliveryDateTo: null,
                                            codSettlementStatus: "",
                                            merchantId: "",
                                            merchantBranchId: "",
                                            deliveryType: "",
                                            status: "",
                                            zoneId: "",
                                            cityId: "",
                                            type: "screen",
                                            cityList: [],
                                            customerName: "",
                                            channelOrderNo: "",
                                            deliveredBy: "",
                                            paymentMethod: "",
                                            pickedUp: "",
                                            locationCurrentId: "",
                                            locationFinalFacilityId: "",
                                            returnedDateFrom: null,
                                            returnedDateTo: null,
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Created Date From"
                                                    inputName={"createdDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Created Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Created Date To"
                                                    inputName={"createdDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Created Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Delivered Date From"
                                                    inputName={"deliveredDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Delivered Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Delivered Date To"
                                                    inputName={"deliveredDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Delivered Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Returned Date From"
                                                    inputName={"returnedDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Returned Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Returned Date To"
                                                    inputName={"returnedDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Returned Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Failed Delivery Date From"
                                                    inputName={"failedDeliveryDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Failed Delivery Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Failed Delivery Date To"
                                                    inputName={"failedDeliveryDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Failed Delivery Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText="Zone"
                                                    inputName={"zoneId"}
                                                    placeHolderText={"Zone"}
                                                    formGroupName={"package_status_search"}
                                                    apiUrl={`${getZoneAPI.url}/all`}
                                                    apiStateKey={getZoneAPI.key}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                    onChangeFn={(eventData) => {
                                                        if (eventData.value !== "") {
                                                            let zoneName = eventData.value.split("/")
                                                            const cities = filter(
                                                                get(formState, `package_status_search.cityList`, []),
                                                                (e) => e.zoneId === parseInt(zoneName[0])
                                                            );

                                                            setApiResponse(getCityListAPI.key, [{ id: "", name: "All" }, ...cities])
                                                        } else {
                                                            setApiResponse(getCityListAPI.key, get(formState, `package_status_search.cityList`, []))
                                                        }
                                                        changeInputFn("package_status_search", "cityId", "", "")
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText="City"
                                                    inputName={"cityId"}
                                                    placeHolderText={"City"}
                                                    formGroupName={"package_status_search"}
                                                    apiUrl={`${getCityListAPI.url}/all`}
                                                    apiStateKey={getCityListAPI.key}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            name: "All"
                                                        }, ...response];

                                                        changeInputFn("package_status_search", "cityList", "", data)

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}

                                                    onChangeFn={(eventData) => {
                                                        const selectedCity = find(
                                                            get(formState, `package_status_search.cityList`, []),
                                                            (e) => e.id === eventData.value
                                                        );
                                                        changeInputFn("package_status_search", "selectedCity", "", selectedCity.name)
                                                    }}

                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Merchant"}
                                                    apiUrl={`${merchantAPI.url}/all`}
                                                    apiStateKey={merchantAPI.key}
                                                    inputName={"merchantId"}
                                                    placeHolderText={"Merchant"}
                                                    formGroupName={"package_status_search"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                {get(formState, `package_status_search.merchantId`) ?
                                                    <SelectBoxWithState
                                                        labelText={"Merchant Branch"}
                                                        placeHolderText={"Merchant Branch"}
                                                        inputName={"merchantBranchId"}
                                                        formGroupName={"package_status_search"}
                                                        apiUrl={`${getMerchantBranchAllAPI.url}?merchantId=${get(formState, `package_status_search.merchantId`)?.split("/")[0]}`}
                                                        apiStateKey={`${getMerchantBranchAllAPI.key}`}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [{
                                                                id: "",
                                                                value: "All"
                                                            }];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.name}`,
                                                                    value: value.name
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <SelectBoxWithState
                                                        labelText={"Merchant Branch"}
                                                        placeHolderText={"Merchant Branch"}
                                                        inputName={"merchantBranchId"}
                                                        formGroupName={"package_status_search"}
                                                        apiUrl={`${getMerchantBranchAllAPI.url}`}
                                                        apiStateKey={`${getMerchantBranchAllAPI.key}`}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [{
                                                                id: "",
                                                                value: "All"
                                                            }];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.name}`,
                                                                    value: value.name
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Lodgment Delivery Center"}
                                                    inputName={"lodgementFacilityId"}
                                                    placeHolderText={"Lodgment Delivery Center"}
                                                    formGroupName={"package_status_search"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={`${getAllFacilityAPI.key}LodgementFacility`}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(data) => {
                                                        const temp = [];

                                                        data.forEach((value) => {
                                                            temp.push({
                                                                id: `${get(value, "id", "")}/${get(value, "location.name", "")}`,
                                                                name: get(value, "location.name", ""),
                                                            })

                                                            return temp
                                                        });
                                                        let newTemp = uniqBy(temp, 'id', "");
                                                        return {
                                                            "data": {
                                                                "data": newTemp
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Destination Delivery Center"}
                                                    inputName={"locationFinalFacilityId"}
                                                    placeHolderText={"Destination Delivery Center"}
                                                    formGroupName={"package_status_search"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={`${getAllFacilityAPI.key}LocationFinalFacility`}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(data) => {
                                                        const temp = [];

                                                        data.forEach((value) => {
                                                            temp.push({
                                                                id: `${get(value, "locationId", "")}/${get(value, "location.name", "")}`,
                                                                name: get(value, "location.name", ""),
                                                            })

                                                            return temp
                                                        });
                                                        let newTemp = uniqBy(temp, 'id', " ");

                                                        return {
                                                            "data": {
                                                                "data": newTemp
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Order Number"
                                                    inputName={"channelOrderNo"}
                                                    formGroupName={"package_status_search"}
                                                    placeHolderText={"Order Number"}
                                                    maxLength={20}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Customer Name"
                                                    inputName={"customerName"}
                                                    formGroupName={"package_status_search"}
                                                    placeHolderText={"Customer Name"}
                                                    maxLength={100}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Payment Type"}
                                                    inputName={"paymentMethod"}
                                                    placeHolderText={"Payment Type"}
                                                    formGroupName={"package_status_search"}
                                                    isDisabled={get(formState, "package_status_search.codSettlementStatus")}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        {
                                                            id: "PREPAID",
                                                            value: "PREPAID",
                                                        },
                                                        {
                                                            id: "CASH_ON_DELIVERY",
                                                            value: "CASH ON DELIVERY",
                                                        },
                                                        {
                                                            id: "CARD_ON_DELIVERY",
                                                            value: "CARD ON DELIVERY",
                                                        },
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Current Location"}
                                                    placeHolderText={"Current Location"}
                                                    inputName={"locationCurrentId"}
                                                    formGroupName={"package_status_search"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={`${getAllFacilityAPI.key}LocationCurrent`}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(data) => {
                                                        const temp = [];

                                                        data.forEach((value) => {
                                                            temp.push({
                                                                id: `${get(value, "location.id", "")}/${get(value, "location.name", "")}`,
                                                                name: get(value, "location.name", ""),
                                                            })

                                                            return temp
                                                        });
                                                        let newTemp = uniqBy(temp, 'id', "");

                                                        return {
                                                            "data": {
                                                                "data": newTemp
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Status"}
                                                    inputName={"status"}
                                                    placeHolderText={"Status"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...packageStatusReportStatuses
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Last Mile Driver"}
                                                    inputName={"deliveredBy"}
                                                    placeHolderText={"Last Mile Driver"}
                                                    formGroupName={"package_status_search"}
                                                    apiUrl={`${getUserDriverList.url}/all?category=LAST`}
                                                    apiStateKey={getUserDriverList.key}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.userId}/${value.name}/${value.licenseNo}`,
                                                                value: `${value.name} - ${value.licenseNo}`
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Picked Up"}
                                                    inputName={"pickedUp"}
                                                    placeHolderText={"Picked Up"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        {
                                                            id: "true",
                                                            value: "Yes"
                                                        },
                                                        {
                                                            id: "false",
                                                            value: "No"
                                                        }
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"COD Status"}
                                                    inputName={"codSettlementStatus"}
                                                    placeHolderText={"COD Status"}
                                                    formGroupName={"package_status_search"}
                                                    isDisabled={get(formState, "package_status_search.paymentMethod") && get(formState, "package_status_search.paymentMethod") !== 'CASH_ON_DELIVERY'}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...codStatuses
                                                    ]}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <RadioButtonWithState
                                                    isRequired={true}
                                                    labelText={"Report Type"}
                                                    inputName={"type"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "screen",
                                                            value: "View on Screen"
                                                        },
                                                        {
                                                            id: "csv",
                                                            value: "Export"
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    changeInputFn,
    setApiResponse,
    setFlashMessage,
})(PackageStatusReport);
