import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import {
    getCustomerList
} from "../../../../config/boda-boda/apiUrls.config";
import { createUserTrKey } from "../../../../store/boda-boda/transaction_request/actionTypes";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";


class CreateUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create New Customer</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={""}
                                path={"/customer"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getCustomerList.key}_create`}
                            onDestroyUnsetFormObject={true}
                        >

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Personal Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Name"
                                                        placeHolderText={"Name"}
                                                        //inputStatePath={`${getCustomerList.key}_create.test.name`}
                                                        inputName={"name"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={100}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Identification"
                                                        placeHolderText={"Identification"}
                                                        inputName={"identification"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Address"
                                                        placeHolderText={"Address"}
                                                        inputName={"address"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="Province"
                                                        inputName={"province"}
                                                        placeHolderText={"Province"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        dataList={[{ id: 1, value: "Southern" }, { id: 2, value: "Western" }]}
                                                        isRequired={true}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="District"
                                                        inputName={"district"}
                                                        placeHolderText={"District"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        dataList={[{ id: 1, value: "Matara" }, { id: 2, value: "Colombo" }]}
                                                        isRequired={true}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="City"
                                                        inputName={"city"}
                                                        placeHolderText={"City"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        dataList={[{ id: 1, value: "Matara" }, { id: 2, value: "Colombo" }]}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"Enter Phone"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        inputType={"number"}
                                                        isRequired={true}
                                                        maxLength={10}
                                                    />
                                                </div>



                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Email"
                                                        inputName={"email"}
                                                        placeHolderText={"Email"}
                                                        formGroupName={`${getCustomerList.key}_create`}
                                                        maxLength={50}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        );
    }
}

const CreateBtn = (props) => {
    const authState = useSelector(state => state.AuthState);
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getCustomerList.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createUserTrKey,
                    payload: {
                        ...dataObject.formObject,
                        merchant: get(authState, "authUser.merchant.id", ""),
                        historyFn: props.historyFn
                    }
                })
            }}
        />
    )
}

export { CreateUI };
