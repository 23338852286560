import React, { Component, Fragment, useEffect, useState } from "react";
import { get } from "lodash";
import JsBarcode from "jsbarcode";
import { useDispatch, useSelector } from "react-redux";

import { InputButton } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { bulkPrintAPI, getManifestListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { CustomLoader } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { changeInputFn } from "../../../../store/boda-boda/form/actions";
import { setFlashMessage, setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { printerServiceAPIUrl } from "../../../../config/boda-boda/core.config";


class BulkTagPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false
        }
    }

    onClickFn = () => {
        let { quantity, setErrors } = this.props;

        if (quantity <= 0) {
            setErrors(`${bulkPrintAPI.key}_create`, [{
                "property":"count",
                "error":"The Count must be at least 1"
            }]);
        } else {
            setErrors(`${bulkPrintAPI.key}_create`, []);
            this.setState({ isShow: true });
        }
    };

    render() {
        return (
            <Fragment>
                <InputButton
                    btnText="Print Preview"
                    onClickBtnFn={() => this.onClickFn()}
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fas fa-print"}
                />

                <BackBtnComponent
                    path={`/collection`}
                    btnName={"Cancel"}
                    icon={"far fa-times-circle"}
                    btnColor={"danger"}
                    isModal={true}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Collection Bulk Tag"}
                    modalBodyComponent={
                        <BulkTag
                            facility={this.props.facility}
                            quantity={this.props.quantity}
                        />
                    }
                    modalType={modalTypes.large}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintBulkTagBtn
                            setModelStatusFn={(status) => this.setState({ isShow: status })}
                        />
                    }
                />
            </Fragment>
        )
    }
}

const BulkTag = (props) => {
    const [tags, setTags] = useState("fetching");
    const dispatch = useDispatch();

    useEffect(() => {

        async function loadTags(){

            const requestBody = {
                count: Number(get(props, `quantity`, 1)),
                facility: {
                    id: Number(get(props, `facility`, ""))
                }
            }

            const response = await callApi(`${getManifestListAPI.url}/collection-code-bulk`)
                .headers(true)
                .body(requestBody)
                .method("post")
                .send()

            if (response._statue === true){
                setTags(get(response, "data.data", []));
                dispatch(changeInputFn(`${bulkPrintAPI.key}_create`, "bulks", "", get(response, "data.data", [])));
            } else {
                if (get(response, "data.statusCode", 0) === 403) {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger"
                    }));
                } else {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "Something went wrong!!..",
                        type: "danger"
                    }));
                }
            }
        }

        loadTags().then(r =>
            JsBarcode(".tags").init()
        );


    }, [])

    return (
        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                {
                    (tags === "fetching") ? (
                        <div className={"col-md-12"}>
                            <div className={"card mb-1"}>
                                <div className={"card-body"}>
                                    <center>
                                        <CustomLoader />
                                    </center>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={"row"}>
                            {
                                tags.map((bodyData, index) => {
                                    return (
                                        <div className={"col-md-6 text-center mb-4"} key={index}>
                                            <svg
                                                className="tags"
                                                jsbarcode-value={get(bodyData, "code", "")}
                                                jsbarcode-width="1"
                                                jsbarcode-height="50"
                                                jsbarcode-margin="5"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const PrintBulkTagBtn = (props) => {
    const formState = useSelector(state => state.FormData);
    const dispatch = useDispatch();

    const sendPrintRequest = () => {
        dispatch(setPageLoader(true));

        const barcodeLabels = [];

        get(formState, `${bulkPrintAPI.key}_create.bulks`, []).forEach((value) => {
            barcodeLabels.push({
                "collectionNo": value.code,
                "orderNo": null,
                "articledID": null,
                "consignmentNo": null,
                "packageNo": null,
                "weightCategory": null,
                "signatureOnDelivery": null,
                "paymentType": null,
                "delivertoName": null,
                "delivertoPhone": null,
                "senderName": null,
                "senderPhone": null,
                "specialNotes": null
            });
        });

        const dataObj = {
            "barcodeLabels": barcodeLabels,
            "labelType": 2
        }

        callApi(printerServiceAPIUrl)
            .method("post")
            .headers(true)
            .body(dataObj)
            .send()
            .then((result) => {
                if (result._statue === true) {
                    dispatch(setPageLoader(false));
                    dispatch(setFlashMessage({
                        status: true,
                        message: "Request has sent to the printer",
                        type: "success",
                    }));

                    props.setModelStatusFn(false);
                } else {
                    dispatch(setPageLoader(false));
                    dispatch(setFlashMessage({
                        status: true,
                        message: "An error has occurred",
                        type: "danger",
                    }));
                }
            });
    }

    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => sendPrintRequest()}
        />
    )
}

export {
    BulkTagPrint,
    BulkTag
}
