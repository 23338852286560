/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-06-25 13:10:44
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-07-14 11:09:44
 */
import { uploadComponentConfig, validImageTypes, validFileTypes } from "../../../config/boda-boda/core.config";


const getValidFiles = (fileArray, count) => {
    let temp = [];

    if (Array.isArray(fileArray)) {
        fileArray.forEach((value, index) => {
            if (index < count) {
                temp.push(value);
            }
        });
    } else {
        temp = [fileArray]
    }

    return temp;
};

const getFileType = (stringValue) => {
    const temp = stringValue.split('.');
    return temp[temp.length - 1];
};

const getRelevantImage = (stringValue) => {
    const type = getFileType(stringValue);
    const images = ["png", "jpeg", "jpg"];

    if (images.includes(type.toLowerCase())) {
        return null;
    } else {
        return uploadComponentConfig[type];
    }
};

const checkValidFileType = (file, type, validTypes) => {
    const temp = [];

    if (type === "image") {
        validTypes.forEach((value) => {
            temp.push(validImageTypes[value]);
        });

        return temp.includes(file);
    } else {
        validTypes.forEach((value) => {
            temp.push(validFileTypes[value]);
        });

        return temp.includes(file);
    }
};


export {
    getValidFiles,
    getFileType,
    getRelevantImage,
    checkValidFileType
}
