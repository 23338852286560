import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { get } from "lodash";

import { CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import { FilterUI } from "./includes/FilterUI";
import { actionBtnTypes } from "../../../components/boda-boda/CRUD/configs/crud.config";
import { getRoleAPI } from "../../../config/boda-boda/apiUrls.config";
import { RemoveMessage, RemoveBtn } from "./includes/DeleteUI";


class UserRole extends Component {
    render() {
        let { history } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">User Roles</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">User Roles</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title={"User Roles Details"}
                            apiURL={`${getRoleAPI.url}?page=1&limit=20`}
                            dataTableStateKey={getRoleAPI.key}
                            filterFromComponent={FilterUI}
                            isDropdownActionBtn={true}
                            defaultFilterFormObject={{ id: "", name: "", type: "", status: "" }}
                            createActionProps={{
                                actionType: actionBtnTypes.create,
                                isSetModel: false,
                                onClickBtnFn: () => history.push("/users/roles/create")
                            }}
                            tableHeaderList={[
                                { displayName: "Role ID", key: "id" },
                                { displayName: "Role Name", key: "name" },
                                { displayName: "Role Type", key: "type" },
                                { displayName: "Status", key: "status" }
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/users/roles/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.isDefault !== true) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: RemoveMessage,
                                        modalFooterComponent: RemoveBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.isDefault !== true && get(rawData, "rowData.users", []).length === 0 && get(rawData, "rowData.status", "") === "ACTIVE") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                    // checkBeforeActionRenderFn: (rawData) => {
                                    //     return get(rawData, "rowData.users", []).length === 0;
                                    // }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default UserRole
