import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

import { SelectBoxMemo } from "./SelectBox";
import { getValueByFilter } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { changeInputFn } from "../../../store/boda-boda/form/actions";
import { formCacheLevel } from "../../../config/boda-boda/template.config";
import { generateApiCallObjFn, requestAPICallFn, initResponse } from "../../../store/boda-boda/core/actions";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";


const emptyFun = (...para) => undefined;

class SelectBoxWithState extends Component {

    static defaultProps = {
        elementWrapperStyle: "",
        elementStyle: "",
        labelText: "",
        isRequired: false,
        placeHolderText: "",
        inputName: "",
        inputValue: "",
        isDisabled: false,
        helperText: "",
        dataList: [],
        keyName: "id",
        valueName: "value",
        inputStatePath: "",
        formGroupName: "",
        apiUrl: "",
        apiStateKey: "",
        setCache: false,
        cacheLevel: formCacheLevel.updateOnFormGroupChange,
        onRebuildResponseFn: false,
        onChangeFn: emptyFun,
        onClickFn: emptyFun
    }

    constructor(props) {
      super(props);
    }

    componentDidMount() {
        if (this.props.apiUrl) {
            this.getAPIData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {apiUrl,apiStateKey,coreState,setCache}=this.props
        if (apiUrl) {
            if (apiUrl !== prevProps.apiUrl || apiStateKey !== prevProps.apiStateKey) {
                this.getAPIData();            
            }
        }
    }

    getAPIData = () => {
        let {apiUrl, apiStateKey, coreState, setCache, onRebuildResponseFn } = this.props;

        if((get(coreState, `apiResponses.${apiStateKey}.result`, []).length === 0) || (setCache===false) ){
            this.props.initResponse(apiStateKey);
            const callApiObj = generateApiCallObjFn(apiUrl)
                .method("get")
                .setInitStoring(apiResponseStoringType.setResponse, {
                    "responseKey": apiStateKey
                })
                .setOnRebuildResponseFn(onRebuildResponseFn !== false ? (response) => onRebuildResponseFn(response) : false)
                .generate();

            this.props.requestAPICallFn(callApiObj);
        }
    }

    render() {
        let {
            elementWrapperStyle, elementStyle, labelText,
            placeHolderText, inputName,
            inputValue, isDisabled, helperText,
            inputStatePath, formGroupName, cacheLevel,
            onClickFn, isRequired, dataList,
            apiUrl, apiStateKey, setCache,
            changeInputFn, formState, valueName,
            keyName, coreState, onChangeFn
        } = this.props;

        return (
            <SelectBoxMemo
                elementWrapperStyle={elementWrapperStyle}
                elementStyle={elementStyle}
                labelText={labelText}
                isRequired={isRequired}
                placeHolderText={placeHolderText}
                inputName={inputName}
                dataList={apiUrl ? get(coreState, `apiResponses.${apiStateKey}.result`, []) : dataList}
                inputValue={get(formState,inputStatePath?inputStatePath:`${formGroupName}.${inputName}`,inputValue)}
                inputError={getValueByFilter(get(formState,`${formGroupName}._errors`,[]),["property",((inputStatePath)?inputStatePath.substr(formGroupName.length + 1):inputName)],"error","","")}
                isDisabled={isDisabled}
                helperText={helperText}
                keyName={keyName}
                valueName={valueName}
                updateStatus={get(formState,`${formGroupName}._updateStatus`,undefined)}
                cacheLevel={cacheLevel}
                setCache={setCache}
                onChangeFn={(eventData) => {
                    changeInputFn(formGroupName,inputName,inputStatePath,eventData.value);
                    onChangeFn(eventData);
                }}
                onClickFn={(event)=>onClickFn(event)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    requestAPICallFn,
    initResponse
})(SelectBoxWithState);
