import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { InputBoxWithState, SelectBoxWithState, DatePickerBoxWithState } from "../../../../../components/boda-boda/Forms";
import { runSheetLineStatuses } from "../../../../../config/boda-boda/db_config/db_status.config";
import { getLinehaulAPI, getUserDriverList } from "../../../../../config/boda-boda/apiUrls.config";


class FilterUI extends Component {
    render() {
        let { authState } = this.props;

        return (
            <div className="col-md-12">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Runsheet Code"
                            inputName={"code"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Runsheet Code"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Status"}
                            dataList={runSheetLineStatuses}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"From Date"}
                            inputName={"runSheetAtFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"To Date"}
                            inputName={"runSheetAtTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"To Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            apiUrl={`${getLinehaulAPI.url}/all?status=ACTIVE&facilityId=${get(authState, "authUser.facility.id", "")}`}
                            apiStateKey={getLinehaulAPI.key}
                            labelText="Route"
                            inputName={"routeLineId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Route"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            apiUrl={`${getUserDriverList.url}/all?status=ACTIVE&category=LINE`}
                            apiStateKey={`${getUserDriverList.key}_line`}
                            labelText="Driver"
                            inputName={"driverId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Driver"}
                            valueName={"name"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, null)(FilterUI);
