import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { getLastMileAPI } from "../../../../config/boda-boda/apiUrls.config";
import { CreateUI } from "./includes/last-mile-includes/common-icludes";
import CreateLastMileBtn from "./includes/last-mile-includes/CreateLastMileBtn";


class LastMile extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Pick Up/Last Route</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Pick Up/Last Route</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <CrudComponent
                            title="All Last Mile Routes"
                            apiURL={`${getLastMileAPI.url}?page=1&limit=20&facilityId=${get(authState, "authUser.facility.id", "")}`}
                            dataTableStateKey={getLastMileAPI.key}
                            filterFromComponent={FilterUI}
                            defaultFilterFormObject={{
                                name: "",
                                status: "",
                                cityIds: ""
                            }}
                            isDropdownActionBtn={true}
                            createActionProps={{
                                actionType: actionBtnTypes.create,
                                isSetModel: true,
                                modalProps: {
                                    modalTitle: "Create Pick Up/Last Route",
                                    modalType: "",
                                    modalBodyComponent: CreateUI,
                                    modalFooterComponent: CreateLastMileBtn,
                                    isSetClose: true,
                                    isSetFooterClose: true,
                                    parentToModelProps: {
                                        formKey: "_create",
                                        uiType: "create",
                                    },
                                }
                            }}
                            tableHeaderList={[
                                { displayName: "Route ID", key: "id", isSort: false },
                                { displayName: "Route Name", key: "name", isSort: false },
                                { displayName: "Status", key: "status", isSort: false },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/routes/last-mile/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/routes/last-mile/edit/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, null)(LastMile);
