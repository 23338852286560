import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
  InputButton,
  FormWrapper,
  DatePickerBoxWithState,
  SelectBoxWithState,
  RadioButtonWithState,
} from "../../../../components/boda-boda/Forms";
import {
  isEmptyValue,
  fromObjectToQueryString,
  downloadCSV,
  convertToCsvValue,
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {
  merchantAPI,
  merchantSettlementReportAPI,
} from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";

class MerchantSettlementReport extends Component {
  onGenerateFn = () => {
    let { formState, history } = this.props;

    if (this.validateFn() === true) {
      let dateTo = new Date(get(formState, "merchant_settlement_report.dateTo"));
      dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
      dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

      const queryObj = {
        settlementDateFrom: get(formState, "merchant_settlement_report.dateFrom").toISOString(),
        settlementDateTo: dateTo.toISOString(),
        settleToMerchantId: get(formState, "merchant_settlement_report.merchantId")?.split("/")[0],
      };

      if (get(formState, "merchant_settlement_report.type", "screen") === "screen") {
        history.push("/reports/merchant-settlement-report/view");
      } else {
        this.getAPIData(fromObjectToQueryString(queryObj));
      }
    }
  };

  validateFn = () => {
    let { formState, setFormErrorFn } = this.props;
    const errors = [];

    if (
      isEmptyValue(get(formState, "merchant_settlement_report.dateFrom", ""))
    ) {
      errors.push({
        property: "dateFrom",
        error: "The Settlement Transaction Date From field is required",
      });
    }

    if (isEmptyValue(get(formState, "merchant_settlement_report.dateTo", ""))) {
      errors.push({
        property: "dateTo",
        error: "The Settlement Transaction Date To field is required",
      });
    }

    if (errors.length === 0) {
      if (
        new Date(get(formState, "merchant_settlement_report.dateFrom", "")) >
        new Date(get(formState, "merchant_settlement_report.dateTo", ""))
      ) {
        setFormErrorFn("merchant_settlement_report", [
          {
            property: "dateTo",
            error:
              "The Settlement Transaction Date To field should not be less than the Settlement Transaction Date From",
          },
        ]);

        return false;
      } else {
        setFormErrorFn("merchant_settlement_report", []);
        return true;
      }
    } else {
      setFormErrorFn("merchant_settlement_report", errors);
      return false;
    }
  };

  getAPIData = (queryString) => {
    let { setPageLoader } = this.props;

    setPageLoader(true);

    callApi(`${merchantSettlementReportAPI.url}?${queryString}`)
      .method("get")
      .headers(true)
      .send()
      .then((result) => {
        this.generateCSVData(
          get(result, "data.data", []),
          get(result, "data.meta.totalsRow", {})
        );
        setPageLoader(false);
      })
      .catch((error) => {
        setPageLoader(false);
      });
  };

  generateCSVData = (data = [], totalData) => {
    let { formState } = this.props;

    const reportName = ["Merchant Transaction Report"];
    const dateRange = [
      `From ${dateObjectToString(
        get(formState, "merchant_settlement_report.dateFrom"),
        "dd/mm/YY"
      )} to ${dateObjectToString(
        get(formState, "merchant_settlement_report.dateTo"),
        "dd/mm/YY"
      )}`,
    ];
    const merchant = [
      `"Merchant: ${convertToCsvValue(
        get(formState, "merchant_settlement_report.merchantId", "") === ""
          ? "All"
          : get(formState, "merchant_settlement_report.merchantId", "")?.split(
            "/"
          )[1]
      )}"`,
    ];
    const headers = [
      "Settlement Date",
      "Banked Date",
      "Merchant Code",
      "Merchant Name",
      "Transaction Number",
      "Amount",
      "Remark"
    ];

    const dataList = [];

    data.forEach((value) => {
      const temp = [];

      temp.push(
        dateObjectToString(
          get(value, "settlementDate", new Date()),
          "YY-mm-dd"
        )
      );
      temp.push(
        !isEmptyValue(get(value, "settledAt")) ? dateObjectToString(
          get(value, "settledAt", new Date()),
          "YY-mm-dd"
        ) : "-"
      );
      temp.push(`"${convertToCsvValue(get(value, "settleToMerchant.code", "-"))}"`);
      temp.push(`"${convertToCsvValue(get(value, "settleToMerchant.name", "-"))}"`);
      temp.push(`"${convertToCsvValue(get(value, "reference", "-"))}"`);
      temp.push(
        get(value, "total", "") === null ? 0 : get(value, "total", "")
      );
      temp.push(`"${convertToCsvValue(get(value, "remark", "-"))}"`);
      dataList.push(temp);
    });

    const finalDataSet = [
      reportName,
      [""],
      dateRange,
      merchant,
      [""],
      [""],
      headers,
    ];

    dataList.forEach((value) => {
      finalDataSet.push(value);
    });

    const csvDataContent = finalDataSet.map((e) => e.join(",")).join("\n");
    const fileName = `Merchant Transaction Report-${Date.now()}.csv`;
    downloadCSV(csvDataContent, fileName);
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">
                  Merchant Settlement Transaction Report
                </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Merchant Settlement Transaction Report
                  </li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"card"} style={{ marginBottom: "120px" }}>
                <div className={"card-body"}>
                  <FormWrapper
                    setGroupName={"merchant_settlement_report"}
                    onDestroyUnsetFormObject={false}
                    setFormObject={{
                      dateFrom: null,
                      dateTo: null,
                      merchantId: "",
                      type: "screen",
                    }}
                  >
                    <div className={"row"}>
                      <div className="col-md-6">
                        <DatePickerBoxWithState
                          isRequired={true}
                          labelText="Settlement Transaction Date From"
                          inputName={"dateFrom"}
                          formGroupName={"merchant_settlement_report"}
                          placeholderText={"Settlement Transaction Date From"}
                        />
                      </div>

                      <div className="col-md-6">
                        <DatePickerBoxWithState
                          isRequired={true}
                          labelText="Settlement Transaction Date To"
                          inputName={"dateTo"}
                          formGroupName={"merchant_settlement_report"}
                          placeholderText={"Settlement Transaction Date To"}
                        />
                      </div>

                      <div className="col-md-6">
                        <SelectBoxWithState
                          labelText={"Merchant"}
                          apiUrl={`${merchantAPI.url}/all`}
                          apiStateKey={merchantAPI.key}
                          inputName={"merchantId"}
                          placeHolderText={"Merchant"}
                          formGroupName={"merchant_settlement_report"}
                          onRebuildResponseFn={(response) => {
                            const data = [
                              {
                                id: "",
                                value: "All",
                              },
                            ];

                            response.forEach((value) => {
                              data.push({
                                id: `${value.id}/${value.name}`,
                                value: value.name,
                              });
                            });

                            return {
                              data: {
                                data: data,
                              },
                            };
                          }}
                        />
                      </div>

                      <div className="col-md-6"></div>

                      <div className="col-md-6">
                        <RadioButtonWithState
                          isRequired={true}
                          labelText={"Report Type"}
                          inputName={"type"}
                          formGroupName={"merchant_settlement_report"}
                          dataList={[
                            {
                              id: "screen",
                              value: "View on Screen",
                            },
                            {
                              id: "csv",
                              value: "Export",
                            },
                          ]}
                        />
                      </div>
                    </div>

                    <div className={"row"}>
                      <div className={"col-md-12"}>
                        <InputButton
                          btnText={"Generate"}
                          startIcon={"fas fa-spinner"}
                          onClickBtnFn={this.onGenerateFn}
                        />
                      </div>
                    </div>
                  </FormWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.FormData,
    authState: state.AuthState,
  };
};

export default connect(mapStateToProps, {
  setFormErrorFn,
  setPageLoader,
})(MerchantSettlementReport);
