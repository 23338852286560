import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";

import { getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";
import { actionBtnTypes } from "../../../components/boda-boda/CRUD/configs/crud.config";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { Message, APICallBtn } from "./includes/AcceptRejectUI";
import { formatToDateHelper, isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { withRouter } from "react-router-dom";


class PickRequests extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Pick Requests</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Pick Requests</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title={"Pick-Requests"}
                            apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=MERCHANT&facilityId=${get(authState, "authUser.facility.id", "")}&statuses=["PUBLISH","ACCEPTED","COMPLETED","IN_TRANSIT_FIRST","REJECTED"]`}
                            dataTableStateKey={getManifestListAPI.key}
                            isFilter={true}
                            filterFromComponent={FilterUI}
                            isSetCreateAction={false}
                            isDropdownActionBtn={true}
                            defaultFilterFormObject={{
                                code: "",
                                merchantId: "",
                                locationPickId: "",
                                status: "",
                                pickupDateTo: null,
                                pickupDateFrom: null
                            }}
                            clearErrors={() => {
                                const { setFormErrorFn } = this.props;
                                setFormErrorFn("manifestListAPIKey_search", [])
                            }} 
                            onRebuildFilterObjFn={(filterData) => {
                                const data = filterData;
                                let { setFormErrorFn  } = this.props;
                                const errors = [];

                                if (new Date(filterData.pickupDateFrom) > new Date(filterData.pickupDateTo)) {
                                    errors.push({
                                        "property":"pickupDateTo",
                                        "error":"The To Date field should not be less than the From Date field "
                                    })
                                    }

                                    if(errors.length > 0 ){
                                        setFormErrorFn("manifestListAPIKey_search", errors);
                                        return;
                                    }else{
                                        setFormErrorFn("manifestListAPIKey_search", []);
                                    }

                                if (!isEmptyValue(filterData.pickupDateTo)) {
                                    data["pickupDateTo"] = formatToDateHelper(filterData.pickupDateTo);
                                }

                                if (!isEmptyValue(filterData.pickupDateFrom)) {
                                    data["pickupDateFrom"] = filterData.pickupDateFrom.toISOString();
                                }

                                return data;
                            }}
                            tableHeaderList={[
                                { displayName: "Pick Up Request Code", key: "code" },
                                { displayName: "Merchant Name", key: "merchant.name" },
                                { displayName: "Pick Up Date", key: "pickAt", onPrintFn: (rawData) => dateObjectToString(rawData.pickAt, "YY-mm-dd") },
                                { displayName: "Pick Up Location", key: "locationPick.name" },
                                {
                                    displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                        if (rawData.status === "PUBLISH") {
                                            return "PENDING";
                                        } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                            return "PICKED UP";
                                        } else if (rawData.status === "COMPLETED") {
                                            return "PICKUP COMPLETED";
                                        } else {
                                            return rawData.status;
                                        }
                                    }
                                }
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/pick-requests/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "far fa-check-circle",
                                        color: "success",
                                        btnText: "Accept",
                                        tooltip: "Accept"
                                    },
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: Message,
                                        modalFooterComponent: APICallBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable",
                                            displayMessage: "Are you sure you want to accept this pick request?",
                                            status: "ACCEPTED",
                                            flashMessage: "Pick request has successfully accepted"
                                        },
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "PUBLISH";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "far fa-times-circle",
                                        color: "danger",
                                        btnText: "Reject",
                                        tooltip: "Reject"
                                    },
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: Message,
                                        modalFooterComponent: APICallBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable",
                                            displayMessage: "Are you sure you want to reject this pick request?",
                                            status: "REJECTED",
                                            flashMessage: "Pick request has successfully rejected"
                                        },
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "PUBLISH";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "far fa-times-circle",
                                        color: "success",
                                        btnText: "Revert",
                                        tooltip: "Revert"
                                    },
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: Message,
                                        modalFooterComponent: APICallBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable",
                                            displayMessage: "Are you sure you want to revert this pick request?",
                                            status: "PUBLISH",
                                            flashMessage: "Pick request has successfully reverted"
                                        },
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.status === "ACCEPTED"){
                                            if (rawData.rowData.runSheetLastId === null && rawData.rowData.runSheetLineId === null){
                                                return true;
                                            }else{
                                                return false;
                                            }
                                        }else{
                                            return false;
                                        }
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "far fa-times-circle",
                                        color: "success",
                                        btnText: "Complete",
                                        tooltip: "Complete"
                                    },
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: Message,
                                        modalFooterComponent: APICallBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable",
                                            displayMessage: "Are you sure you want to complete this pick request?",
                                            status: "COMPLETED",
                                            flashMessage: "Pick request has successfully completed"
                                        },
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {

                                        if (rawData.rowData.status === "PICKED_UP") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
        setFormErrorFn,
})( withRouter(PickRequests));

