import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import {FilterUI} from "./includes/FilterUI";
import {getManifestListAPI} from "../../../config/boda-boda/apiUrls.config";
import { modalTypes } from "../../../components/boda-boda/Modal/config/model.config";
import {LabelPrint, PrintLabelBtn } from "./includes/PrintUI";
import { MerchantPrint, PrintManifestBtn} from "./includes/PrintUiPickRequest";

class InboundsN extends Component {
  render() {
    let { history, authState } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Manage Inbounds</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Manage Inbounds</li>
                </ol>
              </div>
            </Col>
          </Row>

           <Row>
            <CrudComponent
              title="Manifest Details"
              filterFromComponent={FilterUI}
              isFilter={true}
              defaultFilterFormObject={{
                type: "",
                code: "",
                status: "",
              }}
              apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&types=["MERCHANT","COLLECTION"]&statuses=["IN_TRANSIT_FIRST", "ACCEPTED" ,"IN_TRANSIT_LINE","COMPLETED"]&locationDropId=${get(authState, "authUser.facility.locationId")}`}
              dataTableStateKey={getManifestListAPI.key}
              isSetCreateAction={false}
              isDropdownActionBtn={true}
              tableHeaderList={[
                { displayName: "Manifest Code", key: "code" },
                { displayName: "Manifest Type", key: "type", onPrintFn: (rowData) => { 
                  if (rowData.type === "MERCHANT") 
                    return "Pick Request";
                  else
                    return "Collection"
                } },
                { displayName: "Destination Delivey Center", key: "locationDrop.name" },
                { displayName: "Current Location", key: "locationCurrent.name", "default": "-" },
                { displayName: "Pick Location", key: "locationPick.name" },
                { displayName: "Drop Location", key: "locationDrop.name" },
                { displayName: "Status", key: "status" , onPrintFn:(rawData) => {
                  if (rawData.status === "IN_TRANSIT_LINE") {
                      return "Intransit to regional hub"  ;
                  }else if (rawData.status === "COMPLETED") {
                    return " Delivered to regional hub"  ;
                  
                  }else{
                      return rawData.status;
                  }
              }},
            ]}
              actionList={[
                  {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => {
                      if(rawData.type === "MERCHANT" )
                        history.push(`/pick-requests/view/${rawData.id}`)
                      else
                        history.push(`/collection/view/${rawData.id}`)
                    
                    },
                  },
                },
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: true,
                  actionBtn: {
                      //icon: "fa fa-printer",
                      color: "primary",
                      tooltip: "Print",
                      btnText: "Print",
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.type === "MERCHANT" ;
                  },
                  modalProps: {
                    
                      modalTitle: "Print Pick Request",
                      modalType: modalTypes.large,
                      modalBodyComponent: MerchantPrint,
                      modalFooterComponent: PrintManifestBtn,
                      isSetClose: true,
                      isSetFooterClose: true,
                  },
                  
              },
              {
                actionType: actionBtnTypes.other,
                isSetModel: true,
                actionBtn: {
                    //icon: "fa fa-printer",
                    color: "primary",
                    tooltip: "Print",
                    btnText: "Print",
                },
                checkBeforeActionRenderFn: (rawData) => {
                  return rawData.rowData.type === "COLLECTION" ;
                },
                modalProps: {
                  
                    modalTitle: "Print Collection",
                    modalType: modalTypes.large,
                    modalBodyComponent: LabelPrint,
                    modalFooterComponent: PrintLabelBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                },
                
            }
              ]}
            />

           </Row>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    authState: state.AuthState
  };
};

const Inbounds = connect(mapStateToProps, {  })(InboundsN);

export {
  Inbounds,
};
