import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

import { TabComponent, SubTab } from "../../../../../../components/boda-boda/Tab/Tab";
import { getManifestListAPI, getOrdersListAPI, getPackageListAPI } from "../../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { actionBtnTypes } from "../../../../../../components/boda-boda/CRUD/configs/crud.config";
import { CrudComponent } from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import PickRequestViewModalUI from "../../../../pick-request/common-includes/PickRequestViewModalUI";
import ConsignmentViewModalUI from "../../../../consignments/common-includes/ConsignmentViewModalUI";
import { currency } from "../../../../../../config/boda-boda/db_config/db_status.config";
import { ModalUI } from "../../../../../../components/boda-boda/Modal/ModalUI";


class PickupAndDeliveries extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            deliveryManifest: "fetching",
            returnManifest: "fetching",
            isShowAlert: false
        }
    }

    componentDidMount() {
        this.checkDeliveryManifest();
        this.checkReturnManifest();
    }

    checkDeliveryManifest = async () => {
        let { runSheetId } = this.props;

        const response = await callApi(`${getManifestListAPI.url}/compressed?page=1&limit=20&type=DELIVERY&runSheetLastId=${runSheetId}`)
            .headers(true)
            .method("get")
            .send();

        if (response._statue === true) {
            if (get(response, "data.data", []).length === 0) {
                this.setState({
                    ...this.state,
                    deliveryManifest: "not_found"
                })
            } else {
                this.setState({
                    ...this.state,
                    deliveryManifest: get(response, "data.data", [])[0]
                })
            }
        }
    }

    checkReturnManifest = async () => {
        let { runSheetId } = this.props;

        const response = await callApi(`${getManifestListAPI.url}/compressed?page=1&limit=20&type=RETURN&runSheetLastId=${runSheetId}`)
            .headers(true)
            .method("get")
            .send();

        if (response._statue === true) {
            if (get(response, "data.data", []).length === 0) {
                this.setState({
                    ...this.state,
                    returnManifest: "not_found"
                })
            } else {
                this.setState({
                    ...this.state,
                    returnManifest: get(response, "data.data", [])[0]
                })
            }
        }
    }

    render() {
        let { coreState } = this.props;

        return (
            <div className={"container-fluid"}>
                <TabComponent
                    activeTab={this.state.activeTab}
                    onClickFn={(tabId) => this.setState({ activeTab: tabId })}
                >
                    <SubTab tabId={0} tabName={"Pickups"}>
                        <div className="card mb-1">
                            <div className="card-body">

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-2"}>No of Pick-Requests</dd>
                                            <dt className={"col-sm-10"}>{get(coreState, "dataTableResponses.manifestListAPIKey_runSheetLast_selected.total", 0)}</dt>
                                        </dl>
                                    </div>

                                    <CrudComponent
                                        title={"Pickups"}
                                        apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=MERCHANT&${this.props.status === "EXPIRED" ? "lastExpiredRunSheetLastId" : "runSheetLastId"}=${this.props.runSheetId}`}
                                        dataTableStateKey={`${getManifestListAPI.key}_runSheetLast_selected`}
                                        isSetCreateAction={false}
                                        isFilter={false}
                                        tableHeaderList={[
                                            { displayName: "Manifest Code", key: "code" },
                                            { displayName: "Merchant Name", key: "merchant.name" },
                                            { displayName: "Pick Up Date", key: "pickAt", onPrintFn: (rawData) => dateObjectToString(rawData.pickAt, "dd-mm-YY") },
                                            { displayName: "Pick Up Location", key: "locationPick.name" },
                                            {
                                                displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                                    if (rawData.status === "PUBLISH") {
                                                        return "PENDING";
                                                    } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                                        return "PICKED UP";
                                                    } else if (rawData.status === "COMPLETED") {
                                                        return "PICKUP COMPLETED";
                                                    } else {
                                                        return rawData.status;
                                                    }
                                                }
                                            },
                                            { displayName: "No of Packages", key: "publishedPackages", onPrintFn: (rawData) => rawData?.publishedPackages?.length },
                                        ]}
                                        actionList={[
                                            {
                                                actionType: actionBtnTypes.view,
                                                isSetModel: true,
                                                modalProps: {
                                                    modalTitle: "View Pick Up",
                                                    modalType: "modal-lg",
                                                    modalBodyComponent: PickRequestViewModalUI,
                                                    isSetClose: true,
                                                    isSetFooterClose: true,
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </SubTab>

                    <SubTab tabId={1} tabName={"Deliveries"}>
                        {
                            (this.state.deliveryManifest === "fetching") ? (
                                <div className={"card mb-1"}>
                                    <div className={"card-body"}>
                                        <center>
                                            <CustomLoader />
                                        </center>
                                    </div>
                                </div>
                            ) : (
                                (this.state.deliveryManifest === "not_found") ? (
                                    <div className={"card mb-1"}>
                                        <div className={"card-body"}>
                                            <h4 className="card-title">Deliveries</h4>

                                            <div className="fullWidthDiv defaultMarginTopBottom">
                                                No results found
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card mb-1">
                                        <div className="card-body">
                                            <div className={"row"}>
                                                <div className={"col-md-12"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-2"}>No of Deliveries</dd>
                                                        <dt className={"col-sm-10"}>{get(coreState, "dataTableResponses.ordersListAPIKey_runSheetLast_selected.total", 0)}</dt>
                                                    </dl>
                                                </div>

                                                <CrudComponent
                                                    title="Deliveries"
                                                    apiURL={`${getOrdersListAPI.url}?page=1&limit=20&${this.props.status === "EXPIRED" ? "lastExpiredManifestId" : "manifestId"}=${this.state.deliveryManifest.id}`}
                                                    dataTableStateKey={`${getOrdersListAPI.key}_runSheetLast_selected`}
                                                    isSetCreateAction={false}
                                                    isFilter={false}
                                                    tableHeaderList={[
                                                        { displayName: "Consignment No", key: "consignmentNo" },
                                                        { displayName: "Channel Order No", key: "channelOrderNo" },
                                                        { displayName: "Customer Name", key: "name" },
                                                        // { displayName: "Customer Address / City", key: "shippingLocation.address", onPrintFn: (rawData) =>{ return `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`}},
                                                        { displayName: "Customer Address / City", key: "shippingLocation.address", onPrintFn: (rawData) =>{ 
                                                            if (rawData?.shippingLocation?.isCityConflicted) {
                                                                return <p>{`${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`} <i class="fa fa-exclamation-triangle" aria-hidden="true" onClick={() => this.setState({isShowAlert: true})} style={{color:"#FFC107"}}></i></p>;
                                                            } else {
                                                                return `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`
                                                            }
                                                        }},
                                                        { displayName: "Contact Number", key: "phone" },
                                                        { displayName: "Payment Type", key: "paymentMethod" },
                                                        {
                                                            displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                                                if (rawData.status === "PACKED") {
                                                                    if (rawData?.packages[0]?.status === "NOT_DELIVERED") {
                                                                        return "FAILED_DELIVERY";
                                                                    } else {
                                                                        return "PENDING_DELIVERY";
                                                                    }
                                                                } else {
                                                                    return rawData.status
                                                                }
                                                            }
                                                        },
                                                        { displayName: `COD Amount (${currency})`, key: "amount" },
                                                    ]}
                                                    actionList={[
                                                        {
                                                            actionType: actionBtnTypes.view,
                                                            isSetModel: true,
                                                            modalProps: {
                                                                modalTitle: "View Consignment",
                                                                modalType: "modal-lg",
                                                                modalBodyComponent: ConsignmentViewModalUI,
                                                                isSetClose: true,
                                                                isSetFooterClose: true,
                                                            }
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </SubTab>

                    <SubTab tabId={2} tabName={"Returns"}>
                        {
                            (this.state.returnManifest === "fetching") ? (
                                <div className={"card mb-1"}>
                                    <div className={"card-body"}>
                                        <center>
                                            <CustomLoader />
                                        </center>
                                    </div>
                                </div>
                            ) : (
                                (this.state.returnManifest === "not_found") ? (
                                    <div className={"card mb-1"}>
                                        <div className={"card-body"}>
                                            <h4 className="card-title">Returns</h4>

                                            <div className="fullWidthDiv defaultMarginTopBottom">
                                                No results found
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card mb-1">
                                        <div className="card-body">
                                            <div className={"row"}>
                                                <div className={"col-md-12"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-2"}>No of Returns</dd>
                                                        <dt className={"col-sm-10"}>{get(coreState, `dataTableResponses.${getOrdersListAPI.key}_runSheetLast_returns_selected.total`, 0)}</dt>
                                                    </dl>
                                                </div>

                                                <CrudComponent
                                                    title="Returns"
                                                    apiURL={`${getOrdersListAPI.url}?page=1&limit=20&${this.props.status === "EXPIRED" ? "lastExpiredManifestId" : "manifestId"}=${this.state.returnManifest.id}`}
                                                    dataTableStateKey={`${getOrdersListAPI.key}_runSheetLast_returns_selected`}
                                                    isSetCreateAction={false}
                                                    isFilter={false}
                                                    tableHeaderList={[
                                                        { displayName: "Consignment No", key: "consignmentNo" },
                                                        { displayName: "Channel Order No", key: "channelOrderNo" },
                                                        { displayName: "Customer Name", key: "name" },
                                                        // { displayName: "Customer Address / City", key: "shippingLocation.address", onPrintFn: (rawData) =>{ return `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`}},
                                                        { displayName: "Customer Address / City", key: "shippingLocation.address", onPrintFn: (rawData) =>{ 
                                                            if (rawData?.shippingLocation?.isCityConflicted) {
                                                                return <p>{`${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`} <i class="fa fa-exclamation-triangle" aria-hidden="true" onClick={() => this.setState({isShowAlert: true})} style={{color:"#FFC107"}}></i></p>;
                                                            } else {
                                                                return `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`
                                                            }
                                                        }},
                                                        { displayName: "Contact Number", key: "phone" },
                                                        { displayName: "Payment Type", key: "paymentMethod" },
                                                        {
                                                            displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                                                if (rawData.status === "READY_FOR_RETURN") {
                                                                    if (rawData?.packages[0]?.status === "NOT_RETURNED") {
                                                                        return "FAILED_RETURN";
                                                                    } else {
                                                                        return "PENDING_RETURN";
                                                                    }
                                                                } else {
                                                                    return rawData.status
                                                                }
                                                            }
                                                        },
                                                        { displayName: `COD Amount (${currency})`, key: "amount" },
                                                    ]}
                                                    actionList={[
                                                        {
                                                            actionType: actionBtnTypes.view,
                                                            isSetModel: true,
                                                            modalProps: {
                                                                modalTitle: "View Consignment",
                                                                modalType: "modal-lg",
                                                                modalBodyComponent: ConsignmentViewModalUI,
                                                                isSetClose: true,
                                                                isSetFooterClose: true,
                                                            }
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </SubTab>
                </TabComponent>
                <ModalUI
                    isOpen={this.state.isShowAlert}
                    modalTitle={"Attention"}
                    // modalType={modalTypes.large}
                    showHideToggleFn={() => this.setState({ isShowAlert: false })}
                    modalBodyComponent={
                        <p>There are multiple cities with the same destination postal code.</p>
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        coreState: state.CoreState
    };
};

export default connect(mapStateToProps, null)(PickupAndDeliveries);
