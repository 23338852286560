import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import {getMerchantAPI} from "../../../../config/boda-boda/apiUrls.config";
import {RemoveBtn, RemoveMessage} from "./includes/DeleteUI";

class AllMerchant extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { history } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Merchants</h4>
                            <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="#">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Merchants</li>
                            </ol>
                        </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title={"Merchant Details"}
                            apiURL={`${getMerchantAPI.url}?page=1&limit=20`}
                            dataTableStateKey={getMerchantAPI.key}
                            isFilter={true}
                            filterFromComponent={FilterUI}
                            defaultFilterFormObject={{
                                code: "",
                                name: "",
                                status: ""
                            }}
                            createActionProps={{
                                actionType:actionBtnTypes.create,
                                isSetModel:false,
                                onClickBtnFn: () => history.push("/merchant/create")
                              }}
                            tableHeaderList={[
                                { displayName: "Merchant Code", key: "code" },
                                { displayName: "Merchant Name", key: "name" },
                                { displayName: "Status", key: "status" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant/view/${rawData.id}`),
                                    },
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "ACTIVE" || rawData.rowData.status === "INACTIVE";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: false,
                                    actionBtn: {
                                        icon: "fas fa-undo",
                                        color: "primary",
                                        btnText: "Complete",
                                        tooltip: "Complete",
                                        onClickBtnFn: (rawData) => history.push(`/merchant/resume/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "INCOMPLETE" && rawData.rowData.deletedBy === null;
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: RemoveMessage,
                                        modalFooterComponent: RemoveBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.deletedBy === null && rawData.rowData.status !== "INACTIVE";
                                    }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default AllMerchant;
