import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";

import {
    InputBoxWithState,
    FormWrapper,
    SubmitButton,
    SelectBoxWithState
} from "../../../../components/boda-boda/Forms";
import {
    deliveryChargeManagementAllAPI,
    deliveryChargeManagementAPI,
} from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class PricingEditUI extends Component {
    render() {
        let { history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Update Delivery Pricing</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <EditPackageBtn
                                historyFn={history}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={"/delivery-charges-costing"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                            apiUrl={`${deliveryChargeManagementAllAPI.url}?status=ACTIVE`}
                            onDestroyUnsetFormObject={true}
                            onRebuildResponseFn={(response) => {
                                return {
                                    id: response[0].id,
                                    xlPackageHandlingBaseCost: response[0].xlPackageHandlingBaseCost,
                                    xlPackageHandlingBase: response[0].xlPackageHandlingBase,
                                    expressBaseMultiplierCost: response[0].expressBaseMultiplierCost,
                                    expressBaseMultiplier: response[0].expressBaseMultiplier,
                                    cashChargingTypeBaseType: response[0].cashChargingTypeBaseType,
                                    cashDeliveryBase: response[0].cashDeliveryBase,
                                    cardChargingTypeBaseType: response[0].cardChargingTypeBaseType,
                                    cardDeliveryBase: response[0].cardDeliveryBase,
                                    cashChargingTypeBaseCostType: response[0].cashChargingTypeBaseCostType,
                                    cashDeliveryBaseCost: response[0].cashDeliveryBaseCost,
                                    cardChargingTypeBaseCostType: response[0].cardChargingTypeBaseCostType,
                                    cardDeliveryBaseCost: response[0].cardDeliveryBaseCost,
                                    "_onLoad": false,
                                }
                            }}
                        >


                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Express Delivery Multiplier"
                                                placeHolderText={"Express Delivery Multiplier"}
                                                inputName={"expressBaseMultiplier"}
                                                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Per Package Cash on Delivery</label>

                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Cash on Delivery Type"
                                                inputName={"cashChargingTypeBaseType"}
                                                placeHolderText={"Select a Type"}
                                                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                                                dataList={[
                                                    {
                                                        id: "FEE",
                                                        value: "FEE"
                                                    },
                                                    {
                                                        id: "PERCENTAGE",
                                                        value: "PERCENTAGE"
                                                    }

                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label> &nbsp;</label>
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Percentage (%) / Flat Fee"
                                                placeHolderText={"Percentage (%) / Flat Fee"}
                                                inputName={"cashDeliveryBase"}
                                                inputType={"number"}
                                                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Per Package Card on Delivery</label>
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Card on Delivery Type"
                                                inputName={"cardChargingTypeBaseType"}
                                                placeHolderText={"Select a Type"}
                                                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                                                dataList={[
                                                    {
                                                        id: "FEE",
                                                        value: "FEE"
                                                    },
                                                    {
                                                        id: "PERCENTAGE",
                                                        value: "PERCENTAGE"
                                                    }

                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label> &nbsp;</label>
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Percentage (%) / Flat Fee"
                                                placeHolderText={"Percentage (%) / Flat Fee"}
                                                inputName={"cardDeliveryBase"}
                                                inputType={"number"}
                                                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="XL Package Handling Cost"
                                                placeHolderText={"XL Package Handling Cost"}
                                                inputName={"xlPackageHandlingBase"}
                                                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                                                maxLength={100}
                                                inputType={"number"}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </FormWrapper>

                    </Col>
                </Row>

            </div>
        );
    }
}

const EditPackageBtn = (props) => {
    return (
        <Fragment>
            <SubmitButton
                btnText={"Update"}
                elementWrapperStyle={"d-flex justify-content-end mr-2"}
                startIcon={"mdi mdi-content-save-all-outline"}
                isValidate={true}
                dataTableKey={deliveryChargeManagementAllAPI.key}
                formGroupName={`${deliveryChargeManagementAllAPI.key}_edit`}
                validationObject={{
                    fields: {
                        expressBaseMultiplier: "Express Delivery Multiplier",
                        cashChargingTypeBaseType: "Cash on Delivery Type",
                        cashDeliveryBase: "Cash on Delivery Percentage / Flat Fee",
                        cardChargingTypeBaseType: "Card on Delivery Type",
                        cardDeliveryBase: "Card on Delivery Percentage / Flat Fee",
                        xlPackageHandlingBase: "XL Package Handling Cost",
                    },
                    rules: {
                        expressBaseMultiplier: "required|minAmount:1",
                        cashChargingTypeBaseCostType: "required",
                        cashDeliveryBaseCost: "required|minAmount:1",
                        cardChargingTypeBaseCostType: "required",
                        cardDeliveryBaseCost: "required|minAmount:1",
                        xlPackageHandlingBaseCost: "required|minAmount:1",
                    },
                    message: {
                        "expressBaseMultiplier.minAmount": "The Express Delivery Multiplier must be at least 1",
                        "cashDeliveryBaseCost.minAmount": "The Cash Delivery Percentage (%) / Flat Fee  must be at least 1",
                        "cardDeliveryBaseCost.minAmount": "The Card Delivery Percentage (%) / Flat Fee  must be at least 1",
                        "xlPackageHandlingBaseCost.minAmount": "The XL Package Handling Cost must be at least 1",
                    }
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Delivery price has successfully updated",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.apiRequestOnly,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${deliveryChargeManagementAPI.url}`,
                        key: deliveryChargeManagementAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);

                    return {
                        ...data,
                        xlPackageHandlingBase: formObjectValidationHelper(data, "xlPackageHandlingBase", (data) => { return Number(data) }),
                        expressBaseMultiplier: formObjectValidationHelper(data, "expressBaseMultiplier", (data) => { return Number(data) }),
                        cashDeliveryBase: formObjectValidationHelper(data, "cashDeliveryBase", (data) => { return Number(data) }),
                        cardDeliveryBase: formObjectValidationHelper(data, "cardDeliveryBase", (data) => { return Number(data) }),

                        xlPackageHandlingBaseCost: formObjectValidationHelper(data, "xlPackageHandlingBaseCost", (data) => { return Number(data) }),
                        expressBaseMultiplierCost: formObjectValidationHelper(data, "expressBaseMultiplierCost", (data) => { return Number(data) }),
                        cashDeliveryBaseCost: formObjectValidationHelper(data, "cashDeliveryBaseCost", (data) => { return Number(data) }),
                        cardDeliveryBaseCost: formObjectValidationHelper(data, "cardDeliveryBaseCost", (data) => { return Number(data) }),
                        id: undefined,
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.historyFn.push("/delivery-charges-costing");
                    }
                }}
            />
        </Fragment>
    )
}

export { PricingEditUI };
