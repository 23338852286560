import React from "react";
import { InputBoxWithState, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import {routeStatusList} from "../../../../../config/boda-boda/db_config/db_status.config";
import {getCityAPI} from "../../../../../config/boda-boda/apiUrls.config";

const FilterUI = (props) => {
        return (
            <div className="col-md-12">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Route Name"
                            inputName={"name"}
                            formGroupName={props.searchFromKey}
                            placeHolderText={"Route Name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Route Status"
                            inputName={"status"}
                            formGroupName={props.searchFromKey}
                            dataList={routeStatusList}
                            placeHolderText={"Route Status"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Waypoint"
                            inputName={"cityIds"}
                            formGroupName={props.searchFromKey}
                            apiUrl={getCityAPI.url}
                            apiStateKey={getCityAPI.key}
                            placeHolderText={"Waypoint"}
                            onRebuildResponseFn={(response) => {
                                const data = [];

                                response.forEach((value) => {
                                    data.push({
                                        id: `[${value.id}]`,
                                        value: value.name
                                    });
                                });

                                return {
                                    "data": {
                                        "data": data
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

export default FilterUI;