import React, { Component, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { get } from "lodash";

import {
  TabComponent,
  SubTab,
} from "../../../../../../components/boda-boda/Tab/Tab";
import {
  getManifestListAPI,
  getOrdersListAPI,
  getPackageListAPI,
} from "../../../../../../config/boda-boda/apiUrls.config";
import FilterUI from "../../../../pick-request/includes/FilterUI";
import { dateObjectToString } from "../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { actionBtnTypes } from "../../../../../../components/boda-boda/CRUD/configs/crud.config";
import { CrudComponent } from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import {
  addPickupsToRunSheetLast,
  removePickupsToRunSheetLast,
  addDeliveriesToRunSheetLast,
  removeDeliveriesToRunSheetLast,
  addReturnsToRunSheetLast,
  removeReturnsToRunSheetLast,
} from "../../../../../../store/boda-boda/transaction_request/actions";
import { setFlashMessage } from "../../../../../../store/boda-boda/ui/actions";
import { ModalUI } from "../../../../../../components/boda-boda/Modal/ModalUI";
import {
  InputButton,
  SubmitButton,
} from "../../../../../../components/boda-boda/Forms";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../../../config/boda-boda/apiResponseKey";
import PickRequestViewModalUI from "../../../../pick-request/common-includes/PickRequestViewModalUI";
import PackageViewModalUI from "../../../../packages/common_include/PackageViewModalUI";
import ConsignmentViewModalUI from "../../../../consignments/common-includes/ConsignmentViewModalUI";
import DeliveriesFilterUI from "./DeliveriesFilterUI";
import { HIDE_RIGHT_SIDEBAR } from "../../../../../../store/layout/actionTypes";
import { currency } from "../../../../../../config/boda-boda/db_config/db_status.config";

class PickupAndDeliveries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      showModal: false,
      manifest: null,
      deliveryManifest: "fetching",
      returnManifest: "fetching",
      package: null,
      showDeliveryModal: false,
      showReturnModal: false,
      isShowAlert: false,
    };
  }

  componentDidMount() {
    this.checkDeliveryManifest();
    this.checkReturnManifest();
  }

  checkDeliveryManifest = async () => {
    let { runSheetId } = this.props;

    const response = await callApi(
      `${getManifestListAPI.url}/compressed?page=1&limit=20&type=DELIVERY&runSheetLastId=${runSheetId}`
    )
      .headers(true)
      .method("get")
      .send();

    if (response._statue === true) {
      if (get(response, "data.data", []).length === 0) {
        this.setState({
          ...this.state,
          deliveryManifest: "not_found",
        });
      } else {
        this.setState({
          ...this.state,
          deliveryManifest: get(response, "data.data", [])[0],
        });
      }
    }
  };

  checkReturnManifest = async () => {
    let { runSheetId } = this.props;

    const response = await callApi(
      `${getManifestListAPI.url}/compressed?page=1&limit=20&type=RETURN&runSheetLastId=${runSheetId}`
    )
      .headers(true)
      .method("get")
      .send();

    if (response._statue === true) {
      if (get(response, "data.data", []).length === 0) {
        this.setState({
          ...this.state,
          returnManifest: "not_found",
        });
      } else {
        this.setState({
          ...this.state,
          returnManifest: get(response, "data.data", [])[0],
        });
      }
    }
  };

  addPickup = (row) => {
    let { runSheetId, driver, addPickupsToRunSheetLast, setFlashMessage } =
      this.props;

    if (driver === null) {
      setFlashMessage({
        status: true,
        message: "You should have attach a driver",
        type: "danger",
      });
    } else {
      this.setState(
        {
          ...this.state,
          manifest: row,
        },
        () => {
          addPickupsToRunSheetLast({
            runSheetLast: Number(runSheetId),
            driver: Number(driver.id),
            manifest: row,
            force: false,
            showModalFn: this.showModalFn,
            closeModalFn: this.closeModalFn,
          });
        }
      );
    }
  };

  deletePickup = (row) => {
    let { runSheetId, removePickupsToRunSheetLast } = this.props;

    removePickupsToRunSheetLast({
      runSheetLast: Number(runSheetId),
      manifest: row,
    });
  };

  showModalFn = (state) => {
    this.setState({
      ...this.state,
      [state]: true,
    });
  };

  closeModalFn = () => {
    this.setState({
      ...this.state,
      manifest: null,
      showModal: false,
      package: null,
      showDeliveryModal: false,
      showReturnModal: false
    });
  };

  addDelivery = (row) => {
    let { runSheetId, addDeliveriesToRunSheetLast, driver } = this.props;

    this.setState(
      {
        ...this.state,
        package: row,
      },
      () => {
        addDeliveriesToRunSheetLast({
          runSheetLast: Number(runSheetId),
          manifest: this.state.deliveryManifest,
          package: row,
          force: false,
          driver: driver,
          showModalFn: this.showModalFn,
          closeModalFn: this.closeModalFn,
          refreshDeliveryTabFn: this.refreshDeliveryTabFn,
        });
      }
    );
  };

  deleteDelivery = (row) => {
    let { runSheetId, removeDeliveriesToRunSheetLast } = this.props;

    removeDeliveriesToRunSheetLast({
      runSheetLast: Number(runSheetId),
      manifest: this.state.deliveryManifest,
      order: row,
      refreshDeliveryTabFn: this.refreshDeliveryTabFn,
    });
  };

  refreshDeliveryTabFn = () => {
    this.setState(
      {
        ...this.state,
        deliveryManifest: "fetching",
      },
      () => {
        this.checkDeliveryManifest();
      }
    );
  };

  addReturn = (row) => {
    let { runSheetId, addReturnsToRunSheetLast, driver } = this.props;

    this.setState(
      {
        ...this.state,
        package: row,
      },
      () => {
        addReturnsToRunSheetLast({
          runSheetLast: Number(runSheetId),
          manifest: this.state.returnManifest,
          package: row,
          force: false,
          driver: driver,
          showModalFn: this.showModalFn,
          closeModalFn: this.closeModalFn,
          refreshReturnTabFn: this.refreshReturnTabFn,
        });
      }
    );
  };

  deleteReturn = (row) => {
    let { runSheetId, removeReturnsToRunSheetLast } = this.props;

    removeReturnsToRunSheetLast({
      runSheetLast: Number(runSheetId),
      manifest: this.state.returnManifest,
      order: row,
      refreshReturnTabFn: this.refreshReturnTabFn,
    });
  };

  refreshReturnTabFn = () => {
    this.setState(
      {
        ...this.state,
        returnManifest: "fetching",
      },
      () => {
        this.checkReturnManifest();
      }
    );
  };

  render() {
    let {
      authState,
      runSheetId,
      driver,
      addPickupsToRunSheetLast,
      addDeliveriesToRunSheetLast,
      addReturnsToRunSheetLast,
      coreState,
      status,
    } = this.props;

    return (
      <div className={"container-fluid"}>
        <TabComponent
          activeTab={this.state.activeTab}
          onClickFn={(tabId) => this.setState({ activeTab: tabId })}
        >
          <SubTab tabId={0} tabName={"Pickups"}>
            <div className="card mb-1">
              <div className="card-body">
                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <dl className={"row"}>
                      <dd className={"col-sm-2"}>No of Pick-Requests</dd>
                      <dt className={"col-sm-10"}>
                        {get(
                          coreState,
                          "dataTableResponses.manifestListAPIKey_runSheetLast_selected.total",
                          0
                        )}
                      </dt>
                    </dl>
                  </div>

                  <CrudComponent
                    title={"Pick-Requests"}
                    apiURL={`${getManifestListAPI.url
                      }/compressed?page=1&limit=20&type=MERCHANT&runSheetLastId=null&status=ACCEPTED${get(authState, "authUser.facility.id", null) === null
                        ? ""
                        : `&facilityId=${get(
                          authState,
                          "authUser.facility.id"
                        )}`
                      }`}
                    dataTableStateKey={`${getManifestListAPI.key}_runSheetLast`}
                    isFilter={true}
                    filterFromComponent={FilterUI}
                    defaultFilterFormObject={{
                      code: "",
                      merchantId: "",
                      pickupDateFrom: "",
                      pickupDateTo: "",
                      locationPickId: "",
                      status: "",
                    }}
                    isSetCreateAction={false}
                    tableHeaderList={[
                      { displayName: "Manifest Code", key: "code" },
                      { displayName: "Merchant Name", key: "merchant.name" },
                      {
                        displayName: "Pick Up Date",
                        key: "pickAt",
                        onPrintFn: (rawData) =>
                          dateObjectToString(rawData.pickAt, "dd-mm-YY"),
                      },
                      {
                        displayName: "Pick Up Location",
                        key: "locationPick.name",
                      },
                      {
                        displayName: "Status",
                        key: "status",
                        onPrintFn: (rawData) => {
                          if (rawData.status === "PUBLISH") {
                            return "PENDING";
                          } else if (rawData.status === "IN_TRANSIT_FIRST") {
                            return "PICKED UP";
                          } else if (rawData.status === "COMPLETED") {
                            return "PICKUP COMPLETED";
                          } else {
                            return rawData.status;
                          }
                        },
                      },
                      {
                        displayName: "No of Packages",
                        key: "publishedPackages",
                        onPrintFn: (rawData) =>
                          rawData?.publishedPackages?.length,
                      },
                    ]}
                    actionList={[
                      {
                        actionType: actionBtnTypes.view,
                        isSetModel: true,
                        modalProps: {
                          modalTitle: "View Pick Up",
                          modalType: "modal-lg",
                          modalBodyComponent: PickRequestViewModalUI,
                          isSetClose: true,
                          isSetFooterClose: true,
                        },
                      },
                      {
                        actionType: actionBtnTypes.create,
                        isSetModel: false,
                        actionBtn: {
                          tooltip: "Add",
                          onClickBtnFn: (row) => this.addPickup(row),
                        },
                        checkBeforeActionRenderFn: (rawData) => {
                          return status !== "STARTED";
                        },
                      },
                    ]}
                  />
                </div>

                <div className={"row"}>
                  <CrudComponent
                    title={"Selected Pick-Requests"}
                    apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=MERCHANT&runSheetLastId=${this.props.runSheetId}`}
                    dataTableStateKey={`${getManifestListAPI.key}_runSheetLast_selected`}
                    isSetCreateAction={false}
                    isFilter={false}
                    tableHeaderList={[
                      { displayName: "Manifest Code", key: "code" },
                      { displayName: "Merchant Name", key: "merchant.name" },
                      {
                        displayName: "Pick Up Date",
                        key: "pickAt",
                        onPrintFn: (rawData) =>
                          dateObjectToString(rawData.pickAt, "dd-mm-YY"),
                      },
                      {
                        displayName: "Pick Up Location",
                        key: "locationPick.name",
                      },
                      {
                        displayName: "Status",
                        key: "status",
                        onPrintFn: (rawData) => {
                          if (rawData.status === "PUBLISH") {
                            return "PENDING";
                          } else if (rawData.status === "IN_TRANSIT_FIRST") {
                            return "PICKED UP";
                          } else if (rawData.status === "COMPLETED") {
                            return "PICKUP COMPLETED";
                          } else {
                            return rawData.status;
                          }
                        },
                      },
                      {
                        displayName: "No of Packages",
                        key: "publishedPackages",
                        onPrintFn: (rawData) =>
                          rawData?.publishedPackages?.length,
                      },
                    ]}
                    actionList={[
                      {
                        actionType: actionBtnTypes.view,
                        isSetModel: true,
                        modalProps: {
                          modalTitle: "View Pick Up",
                          modalType: "modal-lg",
                          modalBodyComponent: PickRequestViewModalUI,
                          isSetClose: true,
                          isSetFooterClose: true,
                        },
                      },
                      {
                        actionType: actionBtnTypes.delete,
                        isSetModel: false,
                        actionBtn: {
                          onClickBtnFn: (row) => this.deletePickup(row),
                        },
                        checkBeforeActionRenderFn: (rawData) => {
                          return status !== "STARTED";
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </SubTab>

          <SubTab tabId={1} tabName={"Deliveries"}>
            {this.state.deliveryManifest === "fetching" ? (
              <div className={"card mb-1"}>
                <div className={"card-body"}>
                  <center>
                    <CustomLoader />
                  </center>
                </div>
              </div>
            ) : this.state.deliveryManifest === "not_found" ? (
              <div className={"card mb-1"}>
                <div className={"card-body text-center"}>
                  <EnableDeliveryBtn
                    driverId={Number(get(driver, "id", ""))}
                    runSheetId={Number(runSheetId)}
                    setManifestDataFn={(data) =>
                      this.setState({
                        ...this.state,
                        deliveryManifest: data,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="card mb-1">
                  <div className="card-body">
                    <div className={"row"}>
                      <div className={"col-md-12"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-2"}>No of Deliveries</dd>
                          <dt className={"col-sm-10"}>
                            {get(
                              coreState,
                              "dataTableResponses.ordersListAPIKey_runSheetLast_selected.total",
                              0
                            )}
                          </dt>
                        </dl>
                      </div>

                      <CrudComponent
                        title="Deliveries"
                        apiURL={`${getPackageListAPI.url
                          }?page=1&limit=20&locationCurrentId=${get(
                            authState,
                            "authUser.facility.locationId"
                          )}&locationFinalFacilityId=${get(
                            authState,
                            "authUser.facility.locationId"
                          )}&statuses=["RECEIVED", "SORTED", "NOT_DELIVERED"]&orderStatus=PACKED`}
                        dataTableStateKey={`${getPackageListAPI.key}_runSheetLast`}
                        defaultFilterFormObject={{
                          articleNo: "",
                          name: "",
                          deliveryType: "",
                          paymentMethod: "",
                          categoryId: "",
                          channelOrderNo: "",
                          merchantId: "",
                        }}
                        filterFromComponent={DeliveriesFilterUI}
                        isSetCreateAction={false}
                        isDropdownActionBtn={true}
                        tableHeaderList={[
                          { displayName: "Article No", key: "articleNo" },
                          {
                            displayName: "Chanel Order No",
                            key: "order.channelOrderNo",
                          },
                          {
                            displayName: "Customer Name",
                            key: "order.name",
                            default: "-",
                          },
                          {
                            displayName: "Customer Address & City",
                            key: "order.shippingLocation.address",
                            onPrintFn: (rawData) => {
                              // `${rawData?.order?.shippingLocation?.address}, ${rawData?.order?.shippingLocation?.city?.name}`
                              if (
                                rawData?.order?.shippingLocation
                                  ?.isCityConflicted
                              ) {
                                return (
                                  <p>
                                    {`${rawData?.order?.shippingLocation?.address}, ${rawData?.order?.shippingLocation?.city?.name}`}{" "}
                                    <i
                                      class="fa fa-exclamation-triangle"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.setState({ isShowAlert: true })
                                      }
                                      style={{ color: "#FFC107" }}
                                    ></i>
                                  </p>
                                );
                              } else {
                                return `${rawData?.order?.shippingLocation?.address}, ${rawData?.order?.shippingLocation?.city?.name}`;
                              }
                            },
                          },
                          {
                            displayName: "Payment Method",
                            key: "order.paymentMethod",
                          },
                          {
                            displayName: "Package Category",
                            key: "category.name",
                          },
                          {
                            displayName: "Status",
                            key: "status",
                            onPrintFn: (rawData) => {
                              if (rawData.status === "IN_TRANSIT_FIRST") {
                                return "PACKAGE PICKED UP";
                              } else if (rawData.status === "RECEIVED") {
                                if (
                                  rawData.manifest &&
                                  get(rawData, "manifest.type", "") ===
                                  "DELIVERY"
                                ) {
                                  return `RECEIVED AT PROCESSING CENTER (Assigned)`;
                                } else {
                                  return "RECEIVED AT PROCESSING CENTER";
                                }
                              } else if (rawData.status === "SORTED") {
                                if (
                                  rawData.manifest &&
                                  get(rawData, "manifest.type", "") ===
                                  "DELIVERY"
                                ) {
                                  return `READY FOR TRANSIT (Assigned)`;
                                } else {
                                  return "READY FOR TRANSIT";
                                }
                              } else if (rawData.status === "IN_TRANSIT_LINE") {
                                return "INTRANSIT TO REGIONAL HUB";
                              } else if (rawData.status === "IN_TRANSIT_LAST") {
                                return "OUT FOR DELIVERY";
                              } else if (rawData.status === "NOT_DELIVERED") {
                                if (
                                  rawData.locationCurrentId !== null &&
                                  get(rawData, "order.status", "") !==
                                  "TERMINATED"
                                ) {
                                  if (
                                    rawData.manifest &&
                                    get(rawData, "manifest.type", "") ===
                                    "DELIVERY"
                                  ) {
                                    return `FAILED DELIVERY (Assigned)`;
                                  } else {
                                    return "FAILED DELIVERY";
                                  }
                                } else {
                                  return "FAILED DELIVERY";
                                }
                              } else {
                                return rawData.status;
                              }
                              // if (rawData.status === "RECEIVED" || rawData.status === "SORTED") {
                              //     if (rawData.manifest && get(rawData, "manifest.type", "") === "DELIVERY") {
                              //         return `${rawData.status} (Assigned)`;
                              //     }
                              // } else if (rawData.status === "NOT_DELIVERED" && rawData.locationCurrentId !== null && get(rawData, "order.status", "") !== "TERMINATED") {
                              //     if (rawData.manifest && get(rawData, "manifest.type", "") === "DELIVERY") {
                              //         return `${rawData.status} (Assigned)`;
                              //     }
                              // }
                            },
                          },
                          {
                            displayName: `COD amount(${currency})`,
                            key: "order.amount",
                          },
                        ]}
                        actionList={[
                          {
                            actionType: actionBtnTypes.view,
                            isSetModel: true,
                            modalProps: {
                              modalTitle: "View Delivery",
                              modalType: "modal-lg",
                              modalBodyComponent: PackageViewModalUI,
                              isSetClose: true,
                              isSetFooterClose: true,
                            },
                          },
                          {
                            actionType: actionBtnTypes.create,
                            isSetModel: false,
                            actionBtn: {
                              tooltip: "Add",
                              btnText: "Add",
                              onClickBtnFn: (row) => this.addDelivery(row),
                            },
                            checkBeforeActionRenderFn: (rawData) => {
                              if (
                                rawData.rowData.status === "RECEIVED" ||
                                rawData.rowData.status === "SORTED"
                              ) {
                                if (
                                  rawData.rowData.manifest &&
                                  get(rawData, "rowData.manifest.type", "") ===
                                  "DELIVERY"
                                ) {
                                  return false;
                                } else {
                                  return true;
                                }
                              } else if (
                                rawData.rowData.status === "NOT_DELIVERED" &&
                                rawData.rowData.locationCurrentId !== null &&
                                get(rawData, "rowData.order.status", "") !==
                                "TERMINATED"
                              ) {
                                if (
                                  rawData.rowData.manifest &&
                                  get(rawData, "rowData.manifest.type", "") ===
                                  "DELIVERY"
                                ) {
                                  return false;
                                } else {
                                  return true;
                                }
                              } else {
                                return false;
                              }
                            },
                          },
                        ]}
                      />
                    </div>

                    <div className={"row"}>
                      <CrudComponent
                        title="Selected Deliveries"
                        apiURL={`${getOrdersListAPI.url}?page=1&limit=20&manifestId=${this.state.deliveryManifest.id}`}
                        dataTableStateKey={`${getOrdersListAPI.key}_runSheetLast_selected`}
                        isSetCreateAction={false}
                        isFilter={false}
                        isDropdownActionBtn={true}
                        tableHeaderList={[
                          {
                            displayName: "Consignment No",
                            key: "consignmentNo",
                          },
                          {
                            displayName: "Chanel Order No",
                            key: "channelOrderNo",
                          },
                          { displayName: "Customer Name", key: "name" },
                          {
                            displayName: "Customer Address & City",
                            key: "shippingLocation.address",
                            onPrintFn: (rawData) => {
                              if (rawData?.shippingLocation?.isCityConflicted) {
                                return (
                                  <p>
                                    {`${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`}{" "}
                                    <i
                                      class="fa fa-exclamation-triangle"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.setState({ isShowAlert: true })
                                      }
                                      style={{ color: "#FFC107" }}
                                    ></i>
                                  </p>
                                );
                              } else {
                                return `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`;
                              }
                            },
                            // `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`,
                          },
                          { displayName: "Phone", key: "phone" },
                          {
                            displayName: "Payment Method",
                            key: "paymentMethod",
                          },
                          {
                            displayName: "Status",
                            key: "status",
                            onPrintFn: (rawData) => {
                              if (rawData.status === "PACKED") {
                                if (rawData?.packages[0]?.status === "NOT_DELIVERED") {
                                  return "FAILED_DELIVERY";
                                } else {
                                  return "PENDING_DELIVERY";
                                }
                              } else {
                                return rawData.status;
                              }
                            },
                          },
                          {
                            displayName: `COD amount(${currency})`,
                            key: "amount",
                          },
                        ]}
                        actionList={[
                          {
                            actionType: actionBtnTypes.view,
                            isSetModel: true,
                            modalProps: {
                              modalTitle: "View Consignment",
                              modalType: "modal-lg",
                              modalBodyComponent: ConsignmentViewModalUI,
                              isSetClose: true,
                              isSetFooterClose: true,
                            },
                          },
                          {
                            actionType: actionBtnTypes.delete,
                            isSetModel: false,
                            actionBtn: {
                              onClickBtnFn: (row) => this.deleteDelivery(row),
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </SubTab>

          <SubTab tabId={2} tabName={"Returns"}>
            {this.state.returnManifest === "fetching" ? (
              <div className={"card mb-1"}>
                <div className={"card-body"}>
                  <center>
                    <CustomLoader />
                  </center>
                </div>
              </div>
            ) : this.state.returnManifest === "not_found" ? (
              <div className={"card mb-1"}>
                <div className={"card-body text-center"}>
                  <EnableReturnBtn
                    driverId={Number(get(driver, "id", ""))}
                    runSheetId={Number(runSheetId)}
                    setManifestDataFn={(data) =>
                      this.setState({
                        ...this.state,
                        returnManifest: data,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="card mb-1">
                  <div className="card-body">
                    <div className={"row"}>
                      <div className={"col-md-12"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-2"}>No of Returns</dd>
                          <dt className={"col-sm-10"}>
                            {get(
                              coreState,
                              `dataTableResponses.${getOrdersListAPI.key}_returns_selected.total`,
                              0
                            )}
                          </dt>
                        </dl>
                      </div>

                      <CrudComponent
                        title={"Packages"}
                        apiURL={`${getPackageListAPI.url
                          }?page=1&limit=20&locationCurrentId=${get(
                            authState,
                            "authUser.facility.locationId"
                          )}&locationReturnFacilityId=${get(
                            authState,
                            "authUser.facility.locationId"
                          )}&statuses=["READY_FOR_RETURN","NOT_RETURNED"]&orderStatus=READY_FOR_RETURN`}
                        // apiURL={`${getPackageListAPI.url
                        //   }?page=1&limit=20&statuses=["READY_FOR_RETURN","NOT_RETURNED"]`}
                        dataTableStateKey={`${getPackageListAPI.key}_returns`}
                        filterFromComponent={DeliveriesFilterUI}
                        defaultFilterFormObject={{
                          articleNo: "",
                          name: "",
                          deliveryType: "",
                          paymentMethod: "",
                          categoryId: "",
                          channelOrderNo: "",
                          merchantId: "",
                        }}
                        isFilter={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                          { displayName: "Article No", key: "articleNo" },
                          { displayName: "Merchant Name", key: "merchant.name" },
                          { displayName: "Merchant Branch", key: "merchantBranch.name" },
                          { displayName: "Payment Method", key: "order.paymentMethod" },
                          { displayName: "Package Category", key: "deliveryType" },
                          {
                            displayName: "Created Date",
                            key: "createdAt",
                            onPrintFn: (rawData) =>
                              dateObjectToString(rawData.createdAt, "dd-mm-YY"),
                          },
                          {
                            displayName: "Status",
                            key: "status",
                            onPrintFn: (rawData) => {
                              if (
                                rawData.manifest &&
                                get(rawData, "manifest.type", "") ===
                                "RETURN"
                              ) {
                                return `${rawData?.status} (Assigned)`;
                              } else {
                                return `${rawData?.status}`;
                              }
                            },
                          },
                        ]}
                        actionList={[
                          {
                            actionType: actionBtnTypes.view,
                            isSetModel: true,
                            modalProps: {
                              modalTitle: "View Pick Up",
                              modalType: "modal-lg",
                              modalBodyComponent: PackageViewModalUI,
                              isSetClose: true,
                              isSetFooterClose: true,
                            },
                          },
                          {
                            actionType: actionBtnTypes.create,
                            isSetModel: false,
                            actionBtn: {
                              tooltip: "Add",
                              onClickBtnFn: (row) => this.addReturn(row),
                            },
                            checkBeforeActionRenderFn: (rawData) => {
                              if (
                                rawData.rowData.manifest &&
                                get(rawData, "rowData.manifest.type", "") ===
                                "RETURN"
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            },
                          },
                        ]}
                      />
                    </div>

                    <div className={"row"}>
                      <CrudComponent
                        title={"Selected Returns"}
                        apiURL={`${getOrdersListAPI.url}?page=1&limit=20&manifestId=${this.state.returnManifest.id}`}
                        dataTableStateKey={`${getOrdersListAPI.key}_returns_selected`}
                        isSetCreateAction={false}
                        isFilter={false}
                        tableHeaderList={[
                          {
                            displayName: "Consignment No",
                            key: "consignmentNo",
                          },
                          {
                            displayName: "Chanel Order No",
                            key: "channelOrderNo",
                          },
                          { displayName: "Customer Name", key: "name" },
                          {
                            displayName: "Customer Address & City",
                            key: "shippingLocation.address",
                            onPrintFn: (rawData) => {
                              if (rawData?.shippingLocation?.isCityConflicted) {
                                return (
                                  <p>
                                    {`${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`}{" "}
                                    <i
                                      class="fa fa-exclamation-triangle"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.setState({ isShowAlert: true })
                                      }
                                      style={{ color: "#FFC107" }}
                                    ></i>
                                  </p>
                                );
                              } else {
                                return `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`;
                              }
                            },
                            // `${rawData?.shippingLocation?.address}, ${rawData?.shippingLocation?.city?.name}`,
                          },
                          { displayName: "Phone", key: "phone" },
                          {
                            displayName: "Payment Method",
                            key: "paymentMethod",
                          },
                          {
                            displayName: "Status",
                            key: "status",
                            onPrintFn: (rawData) => {
                              if (rawData.status === "PACKED") {
                                return "PENDING_DELIVERY";
                              } else {
                                return rawData.status;
                              }
                            },
                          },
                          {
                            displayName: `COD amount(${currency})`,
                            key: "amount",
                          },
                        ]}
                        actionList={[
                          {
                            actionType: actionBtnTypes.view,
                            isSetModel: true,
                            modalProps: {
                              modalTitle: "View Pick Up",
                              modalType: "modal-lg",
                              modalBodyComponent: ConsignmentViewModalUI,
                              isSetClose: true,
                              isSetFooterClose: true,
                            },
                          },
                          {
                            actionType: actionBtnTypes.delete,
                            isSetModel: false,
                            actionBtn: {
                              onClickBtnFn: (row) => this.deleteReturn(row),
                            },
                            // checkBeforeActionRenderFn: (rawData) => {
                            //   return status !== "STARTED";
                            // },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </SubTab>
        </TabComponent>

        <ModalUI
          isOpen={this.state.showModal}
          modalTitle={"Please Confirm"}
          modalBodyComponent={
            <p>
              Pick Up location does not belong to the runsheet route. Are you
              sure want to add this pickup?
            </p>
          }
          modalFooterComponent={
            <InputButton
              btnText={"Yes"}
              onClickBtnFn={() =>
                addPickupsToRunSheetLast({
                  runSheetLast: Number(runSheetId),
                  driver: Number(driver.id),
                  manifest: this.state.manifest,
                  force: true,
                  showModalFn: this.showModalFn,
                  closeModalFn: this.closeModalFn,
                })
              }
            />
          }
          showHideToggleFn={this.closeModalFn}
          closeBtnName={"No"}
        />

        <ModalUI
          isOpen={this.state.showDeliveryModal}
          modalTitle={"Please Confirm"}
          modalBodyComponent={
            <p>
              Delivery location does not belong to the runsheet route. Are you
              sure want to add this delivery?
            </p>
          }
          modalFooterComponent={
            <InputButton
              btnText={"Yes"}
              onClickBtnFn={() => {
                addDeliveriesToRunSheetLast({
                  runSheetLast: Number(runSheetId),
                  manifest: this.state.deliveryManifest,
                  driver: driver,
                  package: this.state.package,
                  force: true,
                  showModalFn: this.showModalFn,
                  closeModalFn: this.closeModalFn,
                  refreshDeliveryTabFn: this.refreshDeliveryTabFn,
                });
              }}
            />
          }
          showHideToggleFn={this.closeModalFn}
          closeBtnName={"No"}
        />
        <ModalUI
          isOpen={this.state.showReturnModal}
          modalTitle={"Please Confirm"}
          modalBodyComponent={
            <p>
              Return location does not belong to the runsheet route. Are you
              sure want to add this return?
            </p>
          }
          modalFooterComponent={
            <InputButton
              btnText={"Yes"}
              onClickBtnFn={() => {
                addReturnsToRunSheetLast({
                  runSheetLast: Number(runSheetId),
                  manifest: this.state.returnManifest,
                  driver: driver,
                  package: this.state.package,
                  force: true,
                  showModalFn: this.showModalFn,
                  closeModalFn: this.closeModalFn,
                  refreshReturnTabFn: this.refreshReturnTabFn,
                });
              }}
            />
          }
          showHideToggleFn={this.closeModalFn}
          closeBtnName={"No"}
        />
        <ModalUI
          isOpen={this.state.isShowAlert}
          modalTitle={"Attention"}
          // modalType={modalTypes.large}
          showHideToggleFn={() => this.setState({ isShowAlert: false })}
          modalBodyComponent={
            <p>
              There are multiple cities with the same destination postal code.
            </p>
          }
        />
      </div>
    );
  }
}

const EnableDeliveryBtn = (props) => {
  const authState = useSelector((state) => state.AuthState);

  return (
    <SubmitButton
      btnText={"Enable Delivery"}
      //startIcon={"mdi mdi-content-save-all-outline"}
      isValidate={false}
      flashMessages={{
        success: {
          status: true,
          message: "Delivery Manifest has successfully created",
          type: "success",
        },
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "post",
        onUpload: false,
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.apiRequestOnly,
        mergeToSuccessResponse: null,
        mergeToErrorResponse: null,
      }}
      onGetAPIEndPointFn={(formObject) => {
        return {
          url: `${getManifestListAPI.url}`,
          key: getManifestListAPI.key,
        };
      }}
      onChangeRequestBodyFn={(formObject) => {
        return {
          type: "DELIVERY",
          driver: {
            id: props.driverId,
          },
          status: "DRAFT",
          facility: {
            id: get(authState, "authUser.facility.id"),
          },
          locationPick: {
            id: get(authState, "authUser.facility.locationId"),
          },
          locationDrop: {
            id: get(authState, "authUser.facility.locationId"),
          },
          runSheetLast: {
            id: props.runSheetId,
          },
          notes: [],
        };
      }}
      onResponseCallBackFn={(error, result) => {
        if (!error) {
          props.setManifestDataFn(result.data);
        }
      }}
    />
  );
};

const EnableReturnBtn = (props) => {
  const authState = useSelector((state) => state.AuthState);

  return (
    <SubmitButton
      btnText={"Enable Return"}
      //startIcon={"mdi mdi-content-save-all-outline"}
      isValidate={false}
      flashMessages={{
        success: {
          status: true,
          message: "Return Manifest has successfully created",
          type: "success",
        },
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "post",
        onUpload: false,
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.apiRequestOnly,
        mergeToSuccessResponse: null,
        mergeToErrorResponse: null,
      }}
      onGetAPIEndPointFn={(formObject) => {
        return {
          url: `${getManifestListAPI.url}`,
          key: getManifestListAPI.key,
        };
      }}
      onChangeRequestBodyFn={(formObject) => {
        return {
          type: "RETURN",
          driver: {
            id: props.driverId,
          },
          status: "DRAFT",
          facility: {
            id: get(authState, "authUser.facility.id"),
          },
          locationPick: {
            id: get(authState, "authUser.facility.locationId"),
          },
          locationDrop: {
            id: get(authState, "authUser.facility.locationId"),
          },
          runSheetLast: {
            id: props.runSheetId,
          },
          notes: [],
        };
      }}
      onResponseCallBackFn={(error, result) => {
        if (!error) {
          props.setManifestDataFn(result.data);
        }
      }}
    />
  );
};


const mapStateToProps = (state) => {
  return {
    authState: state.AuthState,
    coreState: state.CoreState,
  };
};

export default connect(mapStateToProps, {
  addPickupsToRunSheetLast,
  setFlashMessage,
  removePickupsToRunSheetLast,
  addDeliveriesToRunSheetLast,
  removeDeliveriesToRunSheetLast,
  addReturnsToRunSheetLast,
  removeReturnsToRunSheetLast,
})(PickupAndDeliveries);
