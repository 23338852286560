import {phoneNumberPrefix} from "../../../config/boda-boda/core.config";

/**
 * @description convert phone number to standard format
 * @param phone
 */


const phoneToStandardFormat = (phone) => {
    if (phone === null){
        return null;
    } else if (phone.substring(0, 3) === phoneNumberPrefix){
        return phone
    }else if (phone.length !== 0){
        return `${phoneNumberPrefix}${phone}`
    }else {
        return phone
    }
}

export {
    phoneToStandardFormat
}