import React from "react";
import { InputBoxWithState, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import { merchantStatusList } from "../../../../../config/boda-boda/db_config/db_status.config";

const FilterUI = (props) => {
    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText={"Merchant Code"}
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Merchant Code"}
                    />
                </div>

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText={"Merchant Name"}
                        inputName={"name"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Merchant Name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Merchant Status"}
                        inputName={"status"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Merchant Status"}
                        dataList={merchantStatusList}
                    />
                </div>

            </div>
        </div>
    )
}

export default FilterUI;