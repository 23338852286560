import React, { Fragment, useState } from "react";

import {
  SubmitButton,
  InputButton,
  SelectBoxWithState,
  FormWrapper,
} from "../../../../../components/boda-boda/Forms";

import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { getDistrictListAPI, merchantBranchRateDedicatedAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { downloadCSV } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setPageLoader, setFlashMessage } from "../../../../../store/boda-boda/ui/actions";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";
import { groupBy, get, find } from "lodash";
import { connect } from "react-redux";


const validateFn = (formState, setFormErrorFn) => {
  
  const errors = [];
  if (get(formState, "branch_volumetric_weight_rates.districtIdDownload", "") === "") {
    errors.push({
      "property": "districtIdDownload",
      "error": "The District field is required"
    })
    setFormErrorFn("branch_volumetric_weight_rates", errors);
    return false;
  } else {
    setFormErrorFn("branch_volumetric_weight_rates", []);
    return true;
  }
}

const findRange = (data, range) => {
  let result = find(data, function (obj) {
    if (obj.volumeMin == range) {
      return true;
    }
  });
  return result;
};

const onClickDownloadFn = (merchantBranchid, merchantBranchName, setPageLoader, setFormErrorFn, formState) => {
  setPageLoader(true);

  if (validateFn(formState, setFormErrorFn)) {

    callApi(
      `${merchantBranchRateDedicatedAPI.url}/all?&merchantBranchId=${merchantBranchid}&status=ACTIVE&districtId=${get(formState, "branch_volumetric_weight_rates.districtIdDownload", "")}`
    )
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        generateCSVData(
          get(result, "data.data", []),
          `${get(formState, "branch_volumetric_weight_rates.districtIdDownload", "")} - Volumetric Rates ${Date.now()} .csv`
        );
        setPageLoader(false);
      });
  } else {
    setPageLoader(false);
  }
};

const generateCSVData = (data = [], fileName) => {
  const headers = [
    "CityId",
    "City",
    "Price(0-0.5)",
    "Price(0.5-1)",
    "Price(1-2)",
    "Price(2-3)",
    "Price(3-4)",
    "Price(4-5)",
    "Price(5-6)",
    "Price(6-7)",
    "Price(7-8)",
    "Price(8-9)",
    "Price(9-10)",
    "Price(10-11)",
    "Price(11-12)",
    "Price(12-13)",
    "Price(13-14)",
    "Price(14-15)",
    "Price(15-16)",
    "Price(16-17)",
    "Price(17-18)",
    "Price(18-19)",
    "Price(19-20)",
    "Price(20+)",
  ];

  const dataList = [];
  let city = groupBy(data, "cityId");

  Object.keys(city).forEach((values, index) => {
    let temp2 = [];

    temp2.push(city[values][0].city.id);
    temp2.push(city[values][0].city.name);
    temp2.push(
      findRange(city[values], 0)
        ? findRange(city[values], 0).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 0.5)
        ? findRange(city[values], 0.5).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 1)
        ? findRange(city[values], 1).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 2)
        ? findRange(city[values], 2).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 3)
        ? findRange(city[values], 3).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 4)
        ? findRange(city[values], 4).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 5)
        ? findRange(city[values], 5).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 6)
        ? findRange(city[values], 6).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 7)
        ? findRange(city[values], 7).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 8)
        ? findRange(city[values], 8).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 9)
        ? findRange(city[values], 9).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 10)
        ? findRange(city[values], 10).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 11)
        ? findRange(city[values], 11).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 12)
        ? findRange(city[values], 12).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 13)
        ? findRange(city[values], 13).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 14)
        ? findRange(city[values], 14).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 15)
        ? findRange(city[values], 15).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 16)
        ? findRange(city[values], 16).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 17)
        ? findRange(city[values], 17).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 18)
        ? findRange(city[values], 18).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 19)
        ? findRange(city[values], 19).merchantPrice
        : "-"
    );
    temp2.push(
      findRange(city[values], 20)
        ? findRange(city[values], 20).merchantPrice
        : "-"
    );

    dataList.push(temp2);
  });

  const finalDataSet = [headers];

  dataList.forEach((value) => {
    finalDataSet.push(value);
  });

  const csvDataContent = finalDataSet.map((e) => e.join(",")).join("\n");
  downloadCSV(csvDataContent, fileName);
};
const DownloadCSVBtn = (props) => {
  const { formState, merchantBranchid, merchantBranchName, setPageLoader, setFormErrorFn } = props;
  return (

    <InputButton
      btnText={"Download"}
      elementStyle={"btn btn-primary waves-effect waves-light"}
      onClickBtnFn={() => onClickDownloadFn(merchantBranchid, merchantBranchName, setPageLoader, setFormErrorFn, formState)}
    />

  );
};
const DownloadModalBody = () => {
  return (
    <div className="col-md-7">
      <SelectBoxWithState
        labelText={"District"}
        apiUrl={`${getDistrictListAPI.url}`}
        apiStateKey={getDistrictListAPI.key}
        inputName={"districtIdDownload"}
        placeHolderText={"District"}
        formGroupName={"branch_volumetric_weight_rates"}
        onRebuildResponseFn={(response) => {
          const data = [];

          response.forEach((value) => {
            data.push({
              id: `${value.id}`,
              value: `${value.name}`,
            });
          });

          return {
            data: {
              data: data,
            },
          };
        }}
      />
    </div>
  );
};


const DownloadModal = (props) => {
  const [isOpen, setOpen] = useState(false);
  const { merchantBranchid, merchantBranchName, setPageLoader, setFormErrorFn, formState } = props;

  return (
    <Fragment>
      <InputButton
        elementWrapperStyle={"mr-2"}
        btnText={"Download"}
        startIcon={"mdi mdi-content-save-all-outline"}
        onClickBtnFn={() => setOpen(true)}
      />

      <ModalUI
        isOpen={isOpen}
        modalTitle={"Download CSV"}
        modalBodyComponent={<DownloadModalBody />}
        modalFooterComponent={
          <DownloadCSVBtn formState={formState} merchantBranchName={merchantBranchName} merchantBranchid={merchantBranchid} setPageLoader={setPageLoader} setFormErrorFn={setFormErrorFn} setModelStatusFn={(value) => setOpen(value)} />
        }
        showHideToggleFn={() => {
          setOpen(false);
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    formState: state.FormData,
    coreState: state.CoreState
  };
};

const DownloadModalUI = connect(mapStateToProps, {
  setPageLoader,
  setFlashMessage,
  setFormErrorFn,
})(DownloadModal);

export { DownloadModalUI };



