import React, { Component } from "react";
import { connect } from "react-redux";
import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import { getLinehaulAPI, getRouteLineLocationAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { get } from "lodash";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";

class RouteViewModelUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { formState, row } = this.props;
        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getLinehaulAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getLinehaulAPI.url}/${row.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            status: response.status,
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Route Details</h4>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Route ID</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getLinehaulAPI.key}_view.id`, "-")}</dt>

                                <dd className={"col-sm-4"}>Type</dd>
                                <dt className={"col-sm-8"}>Linehaul</dt>
                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Route Name</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getLinehaulAPI.key}_view.name`, "-")}</dt>

                                {/* <dd className={"col-sm-4"}>Route Status</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getLinehaulAPI.key}_view.status`, "-")}</dt> */}
                            </dl>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Delivery Center Details</h4>
                        </div>
                        <CrudComponent
                            title={"Delivery Centers"}
                            apiURL={`${getRouteLineLocationAPI.url}?routeLineId=${row.id}&page=1&limit=20`}
                            dataTableStateKey={getRouteLineLocationAPI.key}
                            isSetCreateAction={false}
                            isFilter={false}
                            isSetAction={false}
                            tableHeaderList={[
                                { displayName: "No", key: "id" },
                                { displayName: "Delivery Center Code", key: "location.id" },
                                { displayName: "Name", key: "location.name" },
                                { displayName: "Status", key: "location.status" }
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(RouteViewModelUI)