import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import parsePhoneNumber from "libphonenumber-js";

import { setPageLoader, setFlashMessage } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getUserStaffList, createAuthUserAPI, changePasswordAPI } from "../../../config/boda-boda/apiUrls.config";
import validateMethod from '../../../helpers/boda-boda/validation';
import { setFormErrorFn } from '../form/actions';
import { isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { updateAuthUserInfo } from "../auth/actions";
import { defaultCountryCode } from "../../../config/boda-boda/core.config";

const emptyFn = (...para) => undefined;

function* updateProfileDetails(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            identification: "Identification",
            email: "Email",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            identification: "required|min:8|max:20",
            email: "email|max:50",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
        },
        {},
    ));

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, []));

        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
        };

        const userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
            .headers(true)
            .method("put")
            .body(userAPIRequestBody)
            .send()
        );

        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                countryCode: {
                    id: parseInt(get(countryCode, "[0]"))
                },
                countryCodePrefix: get(countryCode, "[2]")
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            if (!isEmptyValue(get(data, "payload.image", ""))) {
                staffAPIRequestBody["image"] = get(data, "payload.image", "");
            }

            const staffAPIRequest = yield call(() => callApi(`${getUserStaffList.url}/${get(data, "payload.staffId")}`)
                .headers(true)
                .method("put")
                .body(staffAPIRequestBody)
                .send()
            );

            if (staffAPIRequest._statue === false) {
                if (get(staffAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, get(staffAPIRequest, "data.errors", [])));
                }

                yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
                    .headers(true)
                    .method("put")
                    .body({ email: get(data, "payload.oldEmail", "") })
                    .send()
                );
            } else {
                yield put(updateAuthUserInfo());
                get(data, "payload.history.push", emptyFn)("/user-profile/view");

                yield put(setPageLoader(false));
                yield put(setFlashMessage({
                    status: true,
                    message: "Profile details have updated successfully",
                    type: "success",
                }));
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, get(userAPIRequest, "data.errors", [])));
            }else {
                if (get(userAPIRequest, "data.statusCode", 0) === 403){
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Something went wrong!!",
                        type: "danger",
                    }));
                }
            }
    
        }
    }

    yield put(setPageLoader(false));
}

function* changePassword(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            old: "Old Password",
            new: "New Password",
            confirm_password: "Confirm Password",
        },
        {
            old: "required",
            new: "required|passwordPolicy",
            confirm_password: "required|same:new",
        },
        {
            "confirm_password.same": "Passwords do not match"
        }
    ));

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${changePasswordAPI.key}`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${changePasswordAPI.key}`, []));

        const apiRequestBody = {
            old: get(data, "payload.old", ""),
            new: get(data, "payload.new", ""),
        };

        const userAPIRequest = yield call(() => callApi(`${changePasswordAPI.url}`)
            .headers(true)
            .method("put")
            .body(apiRequestBody)
            .send()
        );

        if (userAPIRequest._statue === true) {
            get(data, "payload.history.push", emptyFn)("/user-profile/view");

            yield put(setPageLoader(false));
            yield put(setFlashMessage({
                status: true,
                message: "Password has changed successfully",
                type: "success",
            }));
        } else {
            if (get(userAPIRequest, "data.statusCode", null) === 400) {
                yield put(setFormErrorFn(`${changePasswordAPI.key}`, [
                    {
                        "property": "old",
                        "error": "The Old Password you have entered is incorrect"
                    }
                ]));
            }
        }
    }

    yield put(setPageLoader(false));
}

function* switchFacility(data) {
    yield put(setPageLoader(true));

    const apiRequestBody = {
        facility: {
            id: get(data, "payload.facility")
        }
    };

    const userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
        .headers(true)
        .method("put")
        .body(apiRequestBody)
        .send()
    );

    if (userAPIRequest._statue === true) {
        yield put(updateAuthUserInfo());
        get(data, "payload.history.push", emptyFn)("/");

        yield put(setPageLoader(false));
        yield put(setFlashMessage({
            status: true,
            message: "Facility has switched successfully",
            type: "success",
        }));
    }


    yield put(setPageLoader(false));
}

export {
    updateProfileDetails,
    changePassword,
    switchFacility
}
