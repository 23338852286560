import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { runSheetLineAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { Message, APICallBtn } from "./includes/CancelRunSheetLine";
import { formatToDateHelper, isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";
import { withRouter } from "react-router-dom";

class RunSheetLine extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Runsheet Line</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Runsheet Line</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title="Runsheets"
                            apiURL={`${runSheetLineAPI.url}?page=1&limit=20&facilityId=${get(authState, "authUser.facility.id", "")}`}
                            dataTableStateKey={runSheetLineAPI.key}
                            filterFromComponent={FilterUI}
                            defaultFilterFormObject={{
                                runSheetAtTo: null,
                                runSheetAtFrom: null,
                                code: "",
                                routeLineId: "",
                                driverId: "",
                                status: ""
                            }}
                            clearErrors={() => {
                                const { setFormErrorFn } = this.props;
                                setFormErrorFn("runSheetLineAPIKey_search", [])
                            }}
                            onRebuildFilterObjFn={(filterData) => {
                                const data = filterData;

                                let { setFormErrorFn  } = this.props;
                                const errors = [];

                                if (new Date(filterData.runSheetAtFrom) > new Date(filterData.runSheetAtTo)) {
                                    errors.push({
                                        "property":"runSheetAtTo",
                                        "error":"The To Date field should not be less than the From Date field "
                                    })
                                    }

                                    if(errors.length > 0 ){
                                        setFormErrorFn("runSheetLineAPIKey_search", errors);
                                        return;
                                    }else{
                                        setFormErrorFn("runSheetLineAPIKey_search", []);
                                    }

                                if (!isEmptyValue(filterData.runSheetAtTo)) {
                                    data["runSheetAtTo"] = formatToDateHelper(filterData.runSheetAtTo);
                                }

                                if (!isEmptyValue(filterData.runSheetAtFrom)) {
                                    data["runSheetAtFrom"] = filterData.runSheetAtFrom.toISOString();
                                }

                                return data;
                            }}
                            createActionProps={{
                                actionType: actionBtnTypes.create,
                                isSetModel: false,
                                onClickBtnFn: () => history.push("/runsheets-line/create")
                            }}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Runsheet Code", key: "code" },
                                { displayName: "Runsheet Name", key: "name" },
                                { displayName: "Date", key: "runSheetAt", onPrintFn: (rawData) => dateObjectToString(rawData.runSheetAt, "dd-mm-YY") },
                                { displayName: "Assigned By", key: "assigned", onPrintFn: (rawData) => get(rawData, "creator.staffs[0].name", "") },
                                { displayName: "Route", key: "routeLine.name" },
                                { displayName: "Driver", key: "driver.name" },
                                { displayName: "Status", key: "status" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/runsheets-line/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/runsheets-line/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status !== "EXPIRED" && rawData.rowData.status !== "COMPLETED" && rawData.rowData.status !== "STARTED" && rawData.rowData.status !== "CANCELLED";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: true,
                                    actionBtn: {
                                        btnText: "Cancel Runsheet",
                                    },
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: Message,
                                        modalFooterComponent: APICallBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable",
                                            displayMessage: "Are you sure you want to cancel this runsheet?",
                                            status: "CANCELLED",
                                            flashMessage: "Runsheet has successfully cancelled"
                                        },
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "ACTIVE";
                                    }
                                },
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
        setFormErrorFn,
})( withRouter(RunSheetLine));


