import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { FormWrapper, InputButton, SubmitButton } from "../../../../../../components/boda-boda/Forms";
import InputBoxWithState from "../../../../../../components/boda-boda/Forms/InputBoxWithState";
import DatePickerBoxWithState from "../../../../../../components/boda-boda/Forms/DatePickerBoxWithState";
import {
    deliveryChargeManagementAPI,
    getContractAPI,
    getContractWizardAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import {
    chargingType,
    contractExpiry,
    currency,
    contractTypes,
    deliveryMethods,
    chargingMethods
} from "../../../../../../config/boda-boda/db_config/db_status.config";
import { get } from "lodash";
import SelectBoxWithState from "../../../../../../components/boda-boda/Forms/SelectBoxWithState";
import { apiResponseStoringType } from "../../../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject
} from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { yearsDiff } from "../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";

class MerchantContract extends Component {
    constructor(props) {
        super(props);

        this.state = {
            merchantContract: "fetching",
            deliveryCharge: "fetching",
            cashCharging: null,
            cardCharging: null,
            isDisabled: false
        }
    }

    cashDeliveryTypeChange = (eventData) => {
        let { changeInputFn } = this.props;
        let FEE, PERCENTAGE = undefined;

        if (get(this.state, "merchantContract", "") === "not_found") {
            FEE = (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "FEE") ? get(this.state, "deliveryCharge.cashDeliveryBase", undefined) : 0;
            PERCENTAGE = (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "PERCENTAGE") ? get(this.state, "deliveryCharge.cashDeliveryBase", undefined) : 0;
        } else {
            FEE = (get(this.state, "merchantContract.cashChargingType", "") === "FEE") ? get(this.state, "merchantContract.cashDelivery", "") : 0;
            PERCENTAGE = (get(this.state, "merchantContract.cashChargingType", "") === "PERCENTAGE") ? get(this.state, "merchantContract.cashDelivery", "") : 0;
        }

        if (eventData.value === "FEE") {
            changeInputFn(`${getContractWizardAPI.key}_create`, "cashDelivery", "", FEE);
            this.setState({
                ...this.state,
                cashCharging: currency
            })
        } else if (eventData.value === "PERCENTAGE") {
            changeInputFn(`${getContractWizardAPI.key}_create`, "cashDelivery", "", PERCENTAGE);
            this.setState({
                ...this.state,
                cashCharging: "%"
            })
        }
    }

    cardDeliveryTypeChange = (eventData) => {
        let { changeInputFn } = this.props;

        let FEE, PERCENTAGE = undefined;

        if (get(this.state, "merchantContract", "") === "not_found") {
            FEE = (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "FEE") ? get(this.state, "deliveryCharge.cardDeliveryBase", undefined) : 0;
            PERCENTAGE = (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "PERCENTAGE") ? get(this.state, "deliveryCharge.cardDeliveryBase", undefined) : 0;
        } else {
            FEE = (get(this.state, "merchantContract.cardChargingType", "") === "FEE") ? get(this.state, "merchantContract.cardDelivery", "") : 0;
            PERCENTAGE = (get(this.state, "merchantContract.cardChargingType", "") === "PERCENTAGE") ? get(this.state, "merchantContract.cardDelivery", "") : 0;
        }


        if (eventData.value === "FEE") {
            changeInputFn(`${getContractWizardAPI.key}_create`, "cardDelivery", "", FEE);
            this.setState({
                ...this.state,
                cardCharging: currency
            })
        } else if (eventData.value === "PERCENTAGE") {
            changeInputFn(`${getContractWizardAPI.key}_create`, "cardDelivery", "", PERCENTAGE);
            this.setState({
                ...this.state,
                cardCharging: "%"
            })
        }
    }

    componentDidMount() {
        this.checkContract();
    }

    checkContract = async () => {
        let { merchantId } = this.props;

        const contractResponse = await callApi(`${getContractAPI.url}?merchantId=${merchantId}&limit=1&page=1`)
            .headers(true)
            .method("get")
            .send();

        if (contractResponse._statue === true) {
            if (get(contractResponse, "data.data", []).length === 0) {
                const deliveryChargeResponse = await callApi(`${deliveryChargeManagementAPI.url}/all?status=ACTIVE`)
                    .headers(true)
                    .method("get")
                    .send();

                if (deliveryChargeResponse._statue === true) {
                    this.setState({
                        ...this.state,
                        deliveryCharge: get(deliveryChargeResponse, "data.data", [])[0],
                        merchantContract: "not_found"
                    })

                    if (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "FEE") {
                        this.setState({
                            ...this.state,
                            cashCharging: currency
                        })
                    } else if (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "PERCENTAGE") {
                        this.setState({
                            ...this.state,
                            cashCharging: "%"
                        })
                    }

                    if (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "FEE") {
                        this.setState({
                            ...this.state,
                            cardCharging: currency
                        })
                    } else if (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "PERCENTAGE") {
                        this.setState({
                            ...this.state,
                            cardCharging: "%"
                        })
                    }
                }
            } else {
                let merchantContract = get(contractResponse, "data.data", [])[0];
                if (merchantContract.cashOnDelivery) {
                    merchantContract.cashOnDelivery = "ENABLED";
                } else {
                    merchantContract.cashOnDelivery = "DISABLED";
                }

                if (merchantContract.cardOnDelivery) {
                    merchantContract.cardOnDelivery = "ENABLED";
                } else {
                    merchantContract.cardOnDelivery = "DISABLED";
                }

                if (merchantContract.continuation) {
                    merchantContract.continuation = "CONTINUE";
                } else {
                    merchantContract.continuation = "DISCONTINUE";
                }

                merchantContract.start = new Date(merchantContract.start);

                const start = new Date(merchantContract.start);
                const end = new Date(merchantContract.end);
                if(merchantContract.end){
                    merchantContract.effectivePeriod = yearsDiff(start, end);
                }

                this.setState({
                    ...this.state,
                    isDisabled: true,
                    deliveryCharge: "",
                    merchantContract: merchantContract
                })


                if (merchantContract.cashChargingType === "FEE") {
                    this.setState({
                        ...this.state,
                        cashCharging: currency
                    })
                } else if (merchantContract.cashChargingType === "PERCENTAGE") {
                    this.setState({
                        ...this.state,
                        cashCharging: "%"
                    })
                }

                if (merchantContract.cardChargingType === "FEE") {
                    this.setState({
                        ...this.state,
                        cardCharging: currency
                    })
                } else if (merchantContract.cardChargingType === "PERCENTAGE") {
                    this.setState({
                        ...this.state,
                        cardCharging: "%"
                    })
                }
            }
        }
    }

    render() {
        let minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);

        return (
            <React.Fragment>
                {
                    (this.state.merchantContract === "fetching" || this.state.deliveryCharge === "fetching") ? (
                        <center>
                            <CustomLoader />
                        </center>
                    ) : (
                        <Row>
                            <Col sm={12}>
                                <FormWrapper
                                    setGroupName={`${getContractWizardAPI.key}_create`}
                                    setFormObject={{
                                        name: get(this.state, "merchantContract.name", ""),
                                        start: get(this.state, "merchantContract.start", ""),
                                        end: get(this.state, "merchantContract.effectivePeriod", ""),
                                        cashOnDelivery: get(this.state, "merchantContract.cashOnDelivery", ""),
                                        cardOnDelivery: get(this.state, "merchantContract.cardOnDelivery", ""),
                                        continuation: get(this.state, "merchantContract.continuation", ""),
                                        discount: get(this.state, "merchantContract.discount", ""),
                                        pricingModelType: get(this.state, "merchantContract.pricingModelType", "TYPE_ONE"),
                                        flatRate: get(this.state, "merchantContract.flatRate", ""),
                                        maxPackageCount: get(this.state, "merchantContract.maxPackageCount", ""),
                                        settlementPeriod: get(this.state, "merchantContract.settlementPeriod", ""),
                                        expressMultiplier: (get(this.state, "merchantContract", "") === "not_found") ? get(this.state, "deliveryCharge.expressBaseMultiplier", undefined) : get(this.state, "merchantContract.expressMultiplier", ""),
                                        xlPackageHandling: (get(this.state, "merchantContract", "") === "not_found") ? get(this.state, "deliveryCharge.xlPackageHandlingBase", undefined) : get(this.state, "merchantContract.xlPackageHandling", ""),
                                        cashChargingType: (get(this.state, "merchantContract", "") === "not_found") ? get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") : get(this.state, "merchantContract.cashChargingType", ""),
                                        cashDelivery: (get(this.state, "merchantContract", "") === "not_found") ? get(this.state, "deliveryCharge.cashDeliveryBase", undefined) : get(this.state, "merchantContract.cashDelivery", ""),
                                        cardChargingType: (get(this.state, "merchantContract", "") === "not_found") ? get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") : get(this.state, "merchantContract.cardChargingType", ""),
                                        cardDelivery: (get(this.state, "merchantContract", "") === "not_found") ? get(this.state, "deliveryCharge.cardDeliveryBase", undefined) : get(this.state, "merchantContract.cardDelivery", ""),
                                        chargingMethod: get(this.state, "merchantContract.chargingMethod", ""),
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Contract Type"
                                                inputName={"pricingModelType"}
                                                placeHolderText={"Contract Type"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                dataList={contractTypes}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Contract Name"
                                                placeHolderText={"Contract Name"}
                                                inputName={"name"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                isRequired={true}
                                                minDate={minDate}
                                                placeholderText={"Contract Effective From"}
                                                labelText={"Contract Effective From"}
                                                inputName={"start"}
                                                inputValue={get(this.state, "merchantContract.start", null)}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={false}
                                                labelText={"Contract Effective Period (Years)"}
                                                placeHolderText={"Contract Effective Period"}
                                                inputName={"end"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                inputType={"number"}
                                            />
                                        </div>
                                        {
                                            (get(this.props.formState, `${getContractWizardAPI.key}_create.pricingModelType`, '') === "TYPE_TWO") ? (
                                                <Fragment>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={"Flat Rate"}
                                                            placeHolderText={"Flat Rate"}
                                                            inputName={"flatRate"}
                                                            formGroupName={`${getContractWizardAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={"Maximum Number of Packages"}
                                                            placeHolderText={"Maximum Number of Packages"}
                                                            inputName={"maxPackageCount"}
                                                            formGroupName={`${getContractWizardAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ) : (null)
                                        }
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText={"Express Delivery Charge Multiplier"}
                                                placeHolderText={"Express Delivery Charge Multiplier"}
                                                inputName={"expressMultiplier"}
                                                inputValue={get(this.state, "merchantContract.expressMultiplier", "")}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText={"XL Package Handling Fee"}
                                                placeHolderText={"XL Package Handling Fee"}
                                                inputName={"xlPackageHandling"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText={"Discount (%)"}
                                                placeHolderText={"Discount"}
                                                inputName={"discount"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Upon Contract Expiry"
                                                placeHolderText={"Upon Contract Expiry"}
                                                inputName={"continuation"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                dataList={contractExpiry}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText={"Settlement Period (Days)"}
                                                placeHolderText={"Settlement Period"}
                                                inputName={"settlementPeriod"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Cash on Delivery"
                                                inputName={"cashOnDelivery"}
                                                placeHolderText={"Cash on Delivery"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                dataList={deliveryMethods}
                                            />
                                        </div>

                                        {/* <div className="col-md-6"></div> */}

                                        {
                                            (get(this.props.formState, `${getContractWizardAPI.key}_create.cashOnDelivery`, '') === "ENABLED") ? (
                                                <Fragment>
                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Cash Charging Type"
                                                            inputName={"cashChargingType"}
                                                            placeHolderText={"Cash Charging Type"}
                                                            formGroupName={`${getContractWizardAPI.key}_create`}
                                                            dataList={chargingType}
                                                            onChangeFn={(eventData) => this.cashDeliveryTypeChange(eventData)}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={`Cash Charging Value (${this.state.cashCharging})`}
                                                            placeHolderText={"Cash Charging Value"}
                                                            inputName={"cashDelivery"}
                                                            formGroupName={`${getContractWizardAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ) : null
                                        }

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Card on Delivery"
                                                placeHolderText={"Card on Delivery"}
                                                inputName={"cardOnDelivery"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                inputValue={get(this.state, "merchantContract.cardOnDelivery", "")}
                                                dataList={deliveryMethods}
                                            />
                                        </div>

                                        {/* <div className="col-md-6"></div> */}

                                        {
                                            (get(this.props.formState, `${getContractWizardAPI.key}_create.cardOnDelivery`, '') === "ENABLED") ? (
                                                <Fragment>
                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Card Charging Type"
                                                            inputName={"cardChargingType"}
                                                            placeHolderText={"Card Charging Type"}
                                                            formGroupName={`${getContractWizardAPI.key}_create`}
                                                            dataList={chargingType}
                                                            onChangeFn={(eventData) => this.cardDeliveryTypeChange(eventData)}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={`Card Charging Value  (${this.state.cardCharging})`}
                                                            placeHolderText={"Card Charging Value"}
                                                            inputName={"cardDelivery"}
                                                            formGroupName={`${getContractWizardAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ) : null
                                        }

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="Charging Method"
                                                placeHolderText={"Charging Method"}
                                                inputName={"chargingMethod"}
                                                formGroupName={`${getContractWizardAPI.key}_create`}
                                                // inputValue={get(this.state, "merchantContract.chargingMethod", "")}
                                                dataList={chargingMethods}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={"d-flex justify-content-end"}>
                                                <InputButton
                                                    elementWrapperStyle={"mr-2"}
                                                    btnText={"Back"}
                                                    onClickBtnFn={this.props.onBackFn}
                                                />
                                                <CreateBtn
                                                    merchantContract={this.state.merchantContract}
                                                    onNextFn={this.props.onNextFn}
                                                    merchant={this.props.merchantId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FormWrapper>
                            </Col>
                        </Row>
                    )
                }
            </React.Fragment>
        )
    }
}

const CreateBtn = (props) => {

    return (
        <SubmitButton
            btnText={"Next"}
            formGroupName={`${getContractWizardAPI.key}_create`}
            dataTableKey={getContractWizardAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    pricingModelType: "Contract Type",
                    maxPackageCount: "Max Package Count",
                    flatRate: "Flat Rate",
                    discount: "Discount",
                    expressMultiplier: "Express Delivery Charge Multiplier",
                    xlPackageHandling: "XL Package Handling Fee",
                    cashOnDelivery: "Cash on Delivery",
                    cardOnDelivery: "Card on Delivery",
                    start: "Contract Effective From",
                    continuation: "Upon Contract Expiry",
                    end: "Contract Effective Period",
                    settlementPeriod: "Settlement Period",
                    chargingMethod: "Charging Method"
                },
                rules: {
                    name: "required",
                    pricingModelType: "required",
                    maxPackageCount: "requiredIf:pricingModelType,TYPE_TWO",
                    flatRate: "requiredIf:pricingModelType,TYPE_TWO",
                    discount: "required",
                    expressMultiplier: "required",
                    xlPackageHandling: "required",
                    cashOnDelivery: "required",
                    cardOnDelivery: "required",
                    start: "required",
                    end: "minAmount:1",
                    continuation: "required",
                    settlementPeriod: "required",
                    chargingMethod: "required"
                },
                message: {
                    "end.minAmount": "The Contract Effective Period must be at least 1 year"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Merchant contract has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: getContractWizardAPI.url,
                    key: getContractWizardAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let contractEndDate = undefined;

                if (data.end && data.start !== undefined && data.end !== undefined) {
                    const contractEffectivePeriod = formObjectValidationHelper(data, "end", (data) => { return Number(data) });
                    const contractStartDate = new Date(data.start);
                    contractEndDate = new Date(data.start);
                    contractEndDate.setFullYear(contractStartDate.getFullYear() + contractEffectivePeriod);
                    contractEndDate.setSeconds(contractEndDate.getSeconds() - 1);
                }

                if (data.end && Number(data.end) < 1) {
                    contractEndDate = data.end
                }

                let temp = {
                    name: data.name,
                    maxPackageCount: formObjectValidationHelper(data, "maxPackageCount", (data) => { return Number(data) }),
                    flatRate: formObjectValidationHelper(data, "flatRate", (data) => { return Number(data) }),
                    pricingModelType: data.pricingModelType,
                    merchant: {
                        id: Number(props.merchant)
                    },
                    discount: formObjectValidationHelper(data, "discount", (data) => { return Number(data) }),
                    expressMultiplier: formObjectValidationHelper(data, "expressMultiplier", (data) => { return Number(data) }),
                    xlPackageHandling: formObjectValidationHelper(data, "xlPackageHandling", (data) => { return Number(data) }),
                    settlementPeriod: data.settlementPeriod,
                    start: data.start,
                    end: contractEndDate,
                    chargingMethod: data.chargingMethod
                }

                if (data.cashOnDelivery === "ENABLED") {
                    temp.cashOnDelivery = true;
                    temp.cashChargingType = data.cashChargingType;
                    temp.cashDelivery = formObjectValidationHelper(data, "cashDelivery", (data) => { return Number(data) });
                } else if (data.cashOnDelivery === "DISABLED") {
                    temp.cashOnDelivery = false;
                    temp.cashChargingType = 'FEE';
                    temp.cashDelivery = 0;
                }

                if (data.cardOnDelivery === "ENABLED") {
                    temp.cardOnDelivery = true;
                    temp.cardChargingType = data.cardChargingType;
                    temp.cardDelivery = formObjectValidationHelper(data, "cardDelivery", (data) => { return Number(data) });
                } else if (data.cardOnDelivery === "DISABLED") {
                    temp.cardOnDelivery = false;
                    temp.cardChargingType = 'FEE';
                    temp.cardDelivery = 0
                }

                if (data.continuation === "CONTINUE") {
                    temp.continuation = true;
                } else if (data.continuation === "DISCONTINUE") {
                    temp.continuation = false;
                } else {
                    temp.continuation = '';
                }

                return temp;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.onNextFn();
                }
            }}
        />
    )
}

export default MerchantContract;