import React, { Component } from "react";


const emptyFun = (...para) => undefined;

class Footer extends Component {

    static defaultProps = {
        totalRecordsCount: "",
        currentPage: 1,
        pageCount: 1,
        perPage: 20,
        pageSize: 20,
        onNextFn: emptyFun
    }

    render() {
        let { totalRecordsCount, currentPage, pageCount, pageSize, onNextFn } = this.props;

        return (
            <div className={"row mt-1"}>
                <div className="col-md-6">
                    <p>Showing {currentPage>=2?((currentPage-1) * pageSize)+1:currentPage} to {(currentPage*pageSize) > totalRecordsCount ? totalRecordsCount : currentPage*pageSize} of {totalRecordsCount} entries</p>
                </div>

                <div className={"col-md-6 tableBtnWrapper"}>
                    <button
                        type="button"
                        className={`btn waves-light waves-effect btn-primary tablePagingBtn`}
                        disabled={currentPage===1?true:false}
                        onClick={() => onNextFn("next", 1)}
                    >
                        <i className="fas fa-angle-double-left"></i>
                    </button>
                    <button
                        type="button"
                        className={`btn waves-light waves-effect btn-primary tablePagingBtn`}
                        disabled={(currentPage-1)===0?true:false}
                        onClick={() => onNextFn("next", currentPage-1)}
                    >
                        <i className="fas fa-angle-left"></i>
                    </button>
                    <button
                        type="button"
                        className={`btn waves-light waves-effect btn-primary tablePagingBtn`}
                        disabled={((currentPage+1)>pageCount)?true:false}
                        onClick={() => onNextFn("next", currentPage + 1)}
                    >
                        <i className="fas fa-angle-right"></i>
                    </button>
                    <button
                        type="button"
                        className={`btn waves-light waves-effect btn-primary tablePagingBtn`}
                        disabled={pageCount===currentPage?true:false}
                        onClick={() => onNextFn("next", pageCount)}
                    >
                        <i className="fas fa-angle-double-right"></i>
                    </button>
                </div>
            </div>
        )
    }
}

export default Footer;
