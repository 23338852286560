import React, { Component } from "react";
import { useDispatch } from "react-redux";
import { InputButton, SubmitButton } from "../../../../components/boda-boda/Forms";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { createAuthUserAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";

class DeactivateUser extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <React.Fragment>
                <InputButton
                    elementWrapperStyle={""}
                    btnText={"Deactivate"}
                    btnColor={"danger"}
                    onClickBtnFn={() => onClickFn()}
                />
                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Please Confirm"}
                    modalBodyComponent={<p>Are you sure you want to deactivate this user?</p>}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <DeactivateBtn
                            historyFn={this.props.historyFn}
                            staff={this.props.staff}
                            user={this.props.user}
                        />
                    }
                />
            </React.Fragment>
        )
    }
}

const DeactivateBtn = (props) => {
    const dispatch = useDispatch();

    const callUserAPI = () => {
        const userAPIResponse = callApi(`${createAuthUserAPI.url}/${props.user}`)
            .headers(true)
            .method("put")
            .body({ status: "INACTIVE" })
            .send();
    }

    return (
        <SubmitButton
            btnText={"Deactivate"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            flashMessages={{
                "success": {
                    status: true,
                    message: "User has successfully deactivated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getUserStaffList.url}/${props.staff}`,
                    key: getUserStaffList.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                const requestBody = {
                    status: "INACTIVE"
                }
                return requestBody;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    try {
                        callUserAPI();
                        props.historyFn.push('/users/staff');
                    } catch (err) {
                        console.log("ERROR", err)
                    }
                }

            }}
        />
    )
}

export {
    DeactivateUser,
    DeactivateBtn
}