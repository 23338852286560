import React, {Fragment} from "react";
import {InputButtonWithState, SubmitButton} from "../../../../../../components/boda-boda/Forms";
import {getMerchantContactAPI, merchantBranchContactAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import {apiResponseStoringType} from "../../../../../../config/boda-boda/apiResponseKey";
import {getDataByFormObject} from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import {useDispatch} from "react-redux";
import {
    createMerchantBranchContactTrKey
} from "../../../../../../store/boda-boda/transaction_request/actionTypes";

const CreateMerchantBranchContactBtn = (props) => {
    const dispatch = useDispatch();

    return(
        <InputButtonWithState
            elementWrapperStyle={"d-flex justify-content-end"}
            btnText={"Create"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getMerchantContactAPI.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantBranchContactTrKey,
                    payload: {
                        ...dataObject.formObject,
                        merchantBranchId: Number(props.merchantBranchId),
                        setModelStatusFn: props.setModelStatusFn
                    }
                })
            }}
        />
    )
}

const CreateContactBtn = (props) => {
    return (
        <Fragment>
            <SubmitButton
                elementWrapperStyle={"d-flex justify-content-end"}
                btnText={"Create"}
                startIcon={"mdi mdi-content-save-all-outline"}
                formGroupName={`${getMerchantContactAPI.key}_create`}
                dataTableKey={merchantBranchContactAPI.key}
                isValidate={true}
                validationObject={{
                    fields: {
                        fullName: "Full Name",
                        phone: "Phone",
                        email: "Email",
                        notification: "Notifications"
                    },
                    rules: {
                        fullName: "required",
                        phone: "required",
                        email: "required|email",
                        notification:  "required"
                    },
                    message: {}
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Contact has successfully created",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: merchantBranchContactAPI.url,
                        key: merchantBranchContactAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    const data = getDataByFormObject(formObject);

                    if (data.notification === "ENABLED"){
                        data.notification = true;
                    }else if (data.notification === "DISABLED"){
                        data.notification = false;
                    }

                    return {
                        ...data,
                        name: data.fullName,
                        merchantBranch: {
                            id: Number(props.merchantBranchId)
                        }
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.setModelStatusFn(false);
                    }
                }}
            />
        </Fragment>
    )
}

export {
    CreateContactBtn,
    CreateMerchantBranchContactBtn
};