import React from "react";

import {
    InputBoxWithState,
    FormWrapper,
    SubmitButton
} from "../../../../../components/boda-boda/Forms";
import {
    facilityContactAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


const CreateContact = (props) => {
    return (
        <FormWrapper
            setGroupName={`${facilityContactAPI.key}_create`}
            onDestroyUnsetFormObject={true}
        >
            <InputBoxWithState
                labelText="Name"
                inputName={"name"}
                placeHolderText={"Name"}
                formGroupName={`${facilityContactAPI.key}_create`}
                isRequired={true}
            />

            <InputBoxWithState
                labelText="Full Name"
                inputName={"fullName"}
                placeHolderText={"Full Name"}
                formGroupName={`${facilityContactAPI.key}_create`}
                isRequired={true}
            />

            <InputBoxWithState
                labelText="Email"
                inputName={"email"}
                placeHolderText={"Email"}
                formGroupName={`${facilityContactAPI.key}_create`}
                isRequired={true}

            />

            <InputBoxWithState
                labelText="Phone"
                inputName={"phone"}
                placeHolderText={"Phone"}
                formGroupName={`${facilityContactAPI.key}_create`}
                isRequired={true}
            />
        </FormWrapper>
    )
}

const CreateContactBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"d-flex justify-content-end"}
            btnText={"Create"}
            formGroupName={`${facilityContactAPI.key}_create`}
            dataTableKey={facilityContactAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    fullName: "Full Name",
                    phone: "Phone",
                    email: "Email",
                },
                rules: {
                    name: "required",
                    fullName: "required",
                    phone: "required|max:10", 
                    email: "required|email",
                },
                message: {}
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Contact has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: facilityContactAPI.url,
                    key: facilityContactAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                const data = getDataByFormObject(formObject);

                return {
                    ...data,
                    facility: {
                        id: Number(props.facilityId)
                    }
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    )
}

export {
    CreateContact,
    CreateContactBtn
};
