import { 
    initFormGroupKey, removeFormGroupKey,setErrorsKey,setInputValueChangeKey,
    setComplexInputValueChangeKey,mergeFormObjectKey,setInitialState, setBulkValuesKey, setSelectAllValuesKey, setTotalAmountKey
} from "./actionTypes";

const initFormFn = (object) => {
    return {
        type: initFormGroupKey,
        payload: object
    }
};

const initFromObjectFn=(formGroupKey)=>{    
    var attributes = {
        _uibackProsess:false,
        _formGroupLinkKey:formGroupKey,
        _uiFormGroup:formGroupKey,
        _uiFormDescription:formGroupKey,
        _updateStatus:false,
        _onLoad:false,
        _errors:[]
    };
    return {
        isBackProsess:function(status=false) {
            attributes = {
                ...attributes,
                _uibackProsess: status
            };
            return this;
        },
        setDescription:function(description="") {
            attributes = {
                ...attributes,
                _uiFormDescription: description
            };
            return this;
        },
        setGroupName:function(group="") {
            attributes = {
                ...attributes,
                _uiFormGroup: group
            };
            return this;
        },
        setLinkWithOthers:function(formGroupLinkKey="") {
            attributes = {
                ...attributes,
                _formGroupLinkKey: formGroupLinkKey
            };
            return this;
        },
        setFormObject:function(formObject) {
            attributes = {
                ...attributes,
                ...formObject
            };
            return this;
        },
        setOnLoad:function(isload=false) {
            attributes = {
                ...attributes,
                _onLoad: isload
            };
            return this;
        },
        generate:function() {
            return attributes;
        }
    }
};

const removeFromGroupFn=(formGroupKey)=>{
    return{
        type:removeFormGroupKey,
        playload:formGroupKey
    };
} 

const setFormErrorFn=(formGroupKey,errors)=>{
    return{
        type:setErrorsKey,
        formGroupKey:formGroupKey,
        playload:errors
    };
} 


const changeInputFn=(formGroupKey,inputKey,inputStatePath,value)=>{
    if(!inputStatePath){       
        return{
            type:setInputValueChangeKey,
            formGroupKey:formGroupKey,
            inputKey:inputKey,
            value:value
        };
    }else{       
        return{
            type:setComplexInputValueChangeKey,
            formGroupKey:formGroupKey,
            inputStatePath:inputStatePath,
            value:value
        }; 
    }
}


const mergeFormObject=(formGroupKey,data)=>{
    return{
        type:mergeFormObjectKey,
        formGroupKey:formGroupKey,
        playload:data
    }; 
}

const setInitialStateFn = () => {
    return {
        type: setInitialState
    };
}

const setBulkValues = (formGroupKey, data) => {
    return {
        type: setBulkValuesKey,
        formGroupKey:formGroupKey,
        payload: data,
    };
}

const setSelectAllValue = (formGroupKey, data, inputKey) => {
    console.log(formGroupKey, data, inputKey);
    return {
      type: setSelectAllValuesKey,
      formGroupKey: formGroupKey,
      inputKey: inputKey,
          payload: data,
    };
  };
  
  const setTotalAmount = (formGroupKey, data, inputKey) => {
    return {
        type: setTotalAmountKey,
        formGroupKey: formGroupKey,
        inputKey: inputKey,
        payload: data,
    };
  };


export {
    initFormFn,
    initFromObjectFn,
    removeFromGroupFn,
    setFormErrorFn,
    changeInputFn,
    mergeFormObject,
    setInitialStateFn,
    setBulkValues,
    setSelectAllValue,
    setTotalAmount,
}
