import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputBoxMemo, inputBoxInputTypes } from "./InputBox";
import { getValueByFilter } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { changeInputFn } from "../../../store/boda-boda/form/actions";
import { formCacheLevel } from "../../../config/boda-boda/template.config";


const emptyFun = (...para) => undefined;

class InputBoxWithState extends Component {

    static defaultProps = {
        elementWrapperStyle: "",
        elementStyle: "",
        labelText: "",
        isRequired: false,
        placeHolderText: "",
        inputType: inputBoxInputTypes.text,
        inputName: "",
        inputValue: "",
        isDisabled: false,
        rows: 1,
        maxLength: 225,
        helperText: "",
        inputStatePath: "",
        formGroupName: "",
        cacheLevel: formCacheLevel.updateOnFormGroupChange,
        onChangeFn: emptyFun,
        onClickFn: emptyFun
    }

    constructor(props) {
      super(props);
    }

    render() {
        let {
            elementWrapperStyle, elementStyle, labelText,
            placeHolderText, inputType, inputName,
            inputValue, isDisabled, rows,
            maxLength, helperText, inputStatePath,
            formGroupName, cacheLevel, onClickFn,
            changeInputFn, formState, isRequired
        } = this.props;

        return (
            <InputBoxMemo
                elementWrapperStyle={elementWrapperStyle}
                elementStyle={elementStyle}
                labelText={labelText}
                isRequired={isRequired}
                placeHolderText={placeHolderText}
                inputType={inputType}
                inputName={inputName}
                inputValue={get(formState,inputStatePath?inputStatePath:`${formGroupName}.${inputName}`,inputValue)}
                inputError={getValueByFilter(get(formState,`${formGroupName}._errors`,[]),["property",((inputStatePath)?inputStatePath.substr(formGroupName.length + 1):inputName)],"error","","")}
                isDisabled={isDisabled}
                rows={rows}
                maxLength={maxLength}
                helperText={helperText}
                updateStatus={get(formState,`${formGroupName}._updateStatus`,undefined)}
                cacheLevel={cacheLevel}
                onChangeFn={(eventData)=>changeInputFn(formGroupName,inputName,inputStatePath,eventData.value)}
                onClickFn={(event)=>onClickFn(event)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, { changeInputFn })(InputBoxWithState);
