import React from "react"
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap"
import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import { getOrdersListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { useSelector } from "react-redux";
import { convertToIST, fromObjectToQueryString } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { get } from "lodash";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import MarkAsSettledBtn from "./MarkAsSettledBtn";

export const DriverSettlementView = (props) => {
    const formState = useSelector(state => state.FormData);
    const authState = useSelector((state) => state.AuthState);
    const deliveredFacilityId = authState?.authUser?.facility?.id;

    React.useEffect(
        () => () => {
            sessionStorage.removeItem('selectedOrders')
        },
        [],
    );

    const getQueryString = () => {
        let deliveredDateTo = new Date(get(formState, "driver_settlement.deliveredDateTo", new Date()));
        deliveredDateTo = new Date(deliveredDateTo.setDate(deliveredDateTo.getDate() + 1));
        deliveredDateTo = new Date(deliveredDateTo.setSeconds(deliveredDateTo.getSeconds() - 1));

        const queryObj = {
            deliveredDateFrom: convertToIST(get(formState, "driver_settlement.deliveredDateFrom", new Date())),
            deliveredDateTo: convertToIST(deliveredDateTo),
            deliveredBy: get(formState, "driver_settlement.driver").split("/")[0],
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    return (
        <React.Fragment>
            {
                (get(formState, "driver_settlement.deliveredDateFrom", "") === "" || get(formState, "driver_settlement.deliveredDateTo", "") === "") ? (
                    <Redirect to={"/driver-settlement"} />
                ) : (
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Driver Settlement</h4>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-sm-12"}>
                                        From:<strong className={"mx-2"}>{dateObjectToString(get(formState, "driver_settlement.deliveredDateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "driver_settlement.deliveredDateTo"), "dd/mm/YY")}</strong>
                                    </div>

                                    <div className={"col-sm-12"}>
                                        Driver:<strong className={"mx-2"}>{get(formState, "driver_settlement.driver", "") === "" ? "All" : get(formState, "driver_settlement.driver", "")?.split("/")[1]}</strong>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className={"d-flex justify-content-end"}>
                                    <div className="mt-2 mr-2"><strong className={"mx-2"}>{`Total: ${get(formState, `${getOrdersListAPI.key}.totalAmount`, '0')}`}</strong></div>
                                    <div className="mr-2">
                                        <MarkAsSettledBtn />
                                    </div>
                                    <BackBtnComponent
                                        path={"/driver-settlement"}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className={"row mt-3"}>
                            <FormWrapper
                                setGroupName={getOrdersListAPI.key}
                                onDestroyUnsetFormObject={true}
                            >
                                <CrudComponent
                                    title={"Driver Settlement"}
                                    apiURL={`${getOrdersListAPI.url}?page=1&limit=20&codSettlementStatus=PENDING&status=DELIVERED&paymentMethod=CASH_ON_DELIVERY&deliveredFacilityId=${deliveredFacilityId}${getQueryString()}`}
                                    dataTableStateKey={getOrdersListAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={false}
                                    isSetAction={false}
                                    isMarkAsSettle={true}
                                    tableHeaderList={[
                                        { displayName: "Delivered Date & Time", key: "deliveredAt", onPrintFn: (rawData) => rawData.deliveredAt ? dateObjectToString(rawData.deliveredAt, "YY-mm-dd HH:MM:II") : '-' },//delivery time
                                        { displayName: "Consignment ID", key: "consignmentNo" },
                                        { displayName: "Channel Order ID", key: "channelOrderNo" },
                                        { displayName: "Merchant Code", key: "merchant.code" },
                                        { displayName: "Number of Articles", key: "_count.packages", default: "-" },
                                        { displayName: "City", key: "shippingLocation.city.name", default: "-" },
                                        {
                                            displayName: "Amount", key: "amount", onPrintFn: (rawData) => {
                                                return rawData.amount === null ? 0 : rawData.amount;
                                            }
                                        },
                                        { displayName: "COD Status", key: "codSettlementStatus" },
                                        {
                                            displayName: "Cash on Delivery Fee", key: "cashDeliveryPrice", onPrintFn: (rawData) => {
                                                if (rawData.paymentMethod === "CASH_ON_DELIVERY") {
                                                    return rawData.cashDeliveryPrice;
                                                } else {
                                                    return 0;
                                                }
                                            }
                                        },
                                    ]}
                                />
                            </FormWrapper>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}