import {call, put} from "redux-saga/effects";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {
    getCityListAPI,
    getFacilityAPI, getLastMileAPI, getLinehaulAPI,
    getRouteLastCityAPI,
    getRouteLineLocationAPI
} from "../../../config/boda-boda/apiUrls.config";
import {resetDataTable} from "../core/actions";
import validateMethod from "../../../helpers/boda-boda/validation";
import { get } from "lodash";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {setFormErrorFn} from "../form/actions";

const emptyFn = (...para) => undefined;

function*  routeLastAddCities(data){

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getRouteLastCityAPI.url}`)
        .headers(true)
        .method("post")
        .body(data.payload)
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getCityListAPI.key}_lastMile`));
        yield put(resetDataTable(`${getRouteLastCityAPI.key}_lastMile`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

function* routeLastRemoveCities(data){
    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getRouteLastCityAPI.url}/${data.id}`)
        .headers(true)
        .method("delete")
        .send()
    );

    if (response._statue === true){
        yield put(resetDataTable(`${getCityListAPI.key}_lastMile`));
        yield put(resetDataTable(`${getRouteLastCityAPI.key}_lastMile`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

function* linehaulAddLocations(data){

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getRouteLineLocationAPI.url}`)
        .headers(true)
        .method("post")
        .body(data.payload)
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getFacilityAPI.key}_linehaul`));
        yield put(resetDataTable(`${getRouteLineLocationAPI.key}_linehaul`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

function* linehaulRemoveLocations(data){

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getRouteLineLocationAPI.url}/${data.id}`)
        .headers(true)
        .method("delete")
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getFacilityAPI.key}_linehaul`));
        yield put(resetDataTable(`${getRouteLineLocationAPI.key}_linehaul`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

function* updateLinehaul(data){
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            status: "Status",
        },
        {
            name: "required",
            status: "required",
        },
        {}
    ));

    if (validationResponse._statue === false) {

        yield put(setFormErrorFn(`${getLinehaulAPI.key}_update`, validationResponse.errors));

    }else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getLinehaulAPI.key}_update`, []));

        const linehaulAPIRequestBody = {
            name: get(data, 'payload.name', ""),
            status: get(data, 'payload.status', "")
        }

        const linehaulAPIRequest = yield call(() => callApi(`${getLinehaulAPI.url}/${get(data, "payload.linehaulId", "")}`).headers(true).method("put").body(linehaulAPIRequestBody).send());

        yield put(setPageLoader(false));

        if (linehaulAPIRequest._statue === true) {
            yield put(setFlashMessage({
                status: true,
                message: "Route has successfully updated",
                type: "success",
            }));

            get(data, "payload.history.push", emptyFn)("/routes/linehaul");
        } else {
            if (get(linehaulAPIRequest, "data.statusCode", 0) === 403){
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger",
                })); 
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger",
                }));
            }
        }
    }
}

function* updateLastMile(data){
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            status: "Status",
        },
        {
            name: "required",
            status: "required",
        },
        {}
    ));

    if (validationResponse._statue === false) {

        yield put(setFormErrorFn(`${getLastMileAPI.key}_update`, validationResponse.errors));

    }else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getLastMileAPI.key}_update`, []));

        const lastMileAPIRequestBody = {
            name: get(data, 'payload.name', ""),
            status: get(data, 'payload.status', "")
        }

        const lastMileAPIRequest = yield call(() => callApi(`${getLastMileAPI.url}/${get(data, "payload.lastMileId", "")}`).headers(true).method("put").body(lastMileAPIRequestBody).send());

        yield put(setPageLoader(false));

        if (lastMileAPIRequest._statue === true) {
            yield put(setFlashMessage({
                status: true,
                message: "Route has successfully updated",
                type: "success",
            }));

            get(data, "payload.history.push", emptyFn)("/routes/last-mile");
        } else {
            if (get(lastMileAPIRequest, "data.statusCode", 0) === 403){
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger",
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger",
                }));
            }
        }
    }
}

function* createLinehaul(data) {
    if (get(data, 'payload.locations', []).length === 0){
        yield put(setFlashMessage({
            status: true,
            message: "You should have select at least one Delivery Center",
            type: "danger",
        }));
    } else {
        yield put(setFlashMessage({
            status: true,
            message: "Route has successfully created",
            type: "success",
        }));

        get(data, "payload.history.push", emptyFn)("/routes/linehaul");
    }
}

function* createLastMile(data) {
    if (get(data, 'payload.cities', []).length === 0){
        yield put(setFlashMessage({
            status: true,
            message: "You should have select at least one City",
            type: "danger",
        }));
    } else {
        yield put(setFlashMessage({
            status: true,
            message: "Route has successfully created",
            type: "success",
        }));

        get(data, "payload.history.push", emptyFn)("/routes/last-mile");
    }
}

export {
    routeLastAddCities,
    routeLastRemoveCities,
    linehaulAddLocations,
    linehaulRemoveLocations,
    updateLinehaul,
    updateLastMile,
    createLinehaul,
    createLastMile
}