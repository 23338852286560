import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { Badge } from 'reactstrap';
import { useSelector } from "react-redux";
import { get } from "lodash";


const SidebarContent = props => {
  const uiState = useSelector(state => state.UIState);

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">

        <li>
          <Link to="/" className="waves-effect">
            <i className="ti-home"></i>
            {/* <span className="badge badge-pill badge-primary float-right">
              2
            </span> */}
            <span>Dashboard</span>
          </Link>
        </li>

        <li>
          <Link to="/consignments" className="waves-effect">
            <i className="ti-clipboard"></i>
            <span>Consignments</span>
          </Link>
        </li>

        <li>
          <Link to="/packages" className="waves-effect">
            <i className="ti-package"></i>
            <span>Packages</span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-thumb-up"></i>
            <span>Collections</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/collection">Manage Collections</Link>
            </li>
            <li>
              <Link to="/collections-print">Bulk Tag Print</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/pick-requests" className="waves-effect">
            <i className="ti-thumb-up"></i>
            <span>Pick Up Request</span>
            <span className={"ml-2"}>
              <Badge color="danger" pill>{get(uiState, "pickRequestCount", 0)}</Badge>
            </span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-package"></i>
            <span>Runsheets</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/runsheets-line">Runsheets Line</Link>
            </li>
            <li>
              <Link to="/runsheets-last">Runsheet Pickup/Last</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/inbounds" className="waves-effect">
            <i className="ti-user"></i>
            <span>Inbounds</span>
          </Link>
        </li>

        <li>
          <Link to="/outbounds" className="waves-effect">
            <i className="ti-user"></i>
            <span>Outbounds</span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-package"></i>
            <span>Cash on Delivery Management</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/driver-settlement">Driver Settlement</Link>
            </li>
            <li>
              <Link to="/hub-settlement">Hub Settlement</Link>
            </li>
            <li>
              <Link to="/hub-settlement-balance">BODA Head Office - Hub Settlement Balance</Link>
            </li>
            <li>
              <Link to="/settlements-pending-confirmation">Settlements Pending Confirmation</Link>
            </li>
            <li>
              <Link to="/merchant-settlement">Merchant Settlement</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-truck"></i>
            <span>Routes</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/routes/linehaul">Linehaul Route</Link>
            </li>
            <li>
              <Link to="/routes/last-mile">Pickup/Last Route</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/delivery-centers" className="waves-effect">
            <i className="ti-home"></i>
            <span>Delivery Centers</span>
          </Link>

        </li>

        <li>
          <Link to="/zones" className="waves-effect">
            <i className="ti-layout-grid4-alt"></i>
            <span>Zones</span>
          </Link>
        </li>


        <li>
          <Link to="/delivery-charges-costing" className="waves-effect">
            <i className="ti-package"></i>
            <span>Delivery Charges Management</span>
          </Link>
          {/* <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/delivery-charges-costing">Costing</Link>
            </li>
            <li>
              <Link to="/delivery-pricing">Pricing</Link>
            </li>
          </ul> */}
        </li>
        <li>
          <Link to="/merchant" className="waves-effect">
            <i className="ti-shopping-cart"></i>
            <span>Merchants</span>
          </Link>
        </li>

        {/* <li>
          <Link to="/customer" className="waves-effect">
            <i className="ti-user"></i>
            <span>Customers</span>
          </Link>
        </li> */}

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-package"></i>
            <span>Users</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/users/staff" className="waves-effect">
                <i className="ti-user"></i>
                <span>Staff</span>
              </Link>
            </li>
            <li>
              <Link to="/users/driver" className="waves-effect">
                <i className="ti-user"></i>
                <span>Driver</span>
              </Link>
            </li>
            <li>
              <Link to="/users/roles" className="waves-effect">
                <i className="ti-user"></i>
                <span>Roles</span>
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ti-package"></i>
            <span>Reports</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/reports/lodgement-reconciliation-report">Lodgement Reconciliation Report</Link>
            </li>
            <li>
              <Link to="/reports/cash-or-card-on-delivery-report">Cash/Card on Delivery Report</Link>
            </li>
            <li>
              <Link to="/reports/merchant-settlement-report">Merchant Settlement Transaction Report</Link>
            </li>
            <li>
              <Link to="/reports/hub-settlement-report">Hub Settlement Transaction Report</Link>
            </li>
            <li>
              <Link to="/reports/package-wise-costing-report">Package Wise Costing Report</Link>
            </li>
            <li>
              <Link to="/reports/package-status-report">Package Status Report</Link>
            </li>
            <li>
              <Link to="/reports/sage-merchant-invoice-report">SAGE - Merchant Invoice Report</Link>
            </li>
            <li>
              <Link to="/reports/sage-driver-payment-accrual-report">SAGE - Driver Payment Accrual Report</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/pdf/User Manual-Delivery Portal v0.3.pdf" className="waves-effect" target="_blank">
            <i className="ti-home"></i>
            <span>Help</span>
          </Link>
        </li>

      </ul>
    </div>
  );
};


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    if (this.props.type !== "condensed" || this.props.isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");

      const pathArray = this.props.location.pathname.split('/');
      let locationPath = `/${pathArray[1]}`;
      let matchFirstItem = false;

      for (var i = 0; i < items.length; ++i) {
        if (locationPath === items[i].pathname) {
          matchingMenuItem = items[i];
          matchFirstItem = true;
          break;
        }
      }

      if (!matchFirstItem) {
        locationPath = `${locationPath}/${pathArray[2]}`;

        for (var i = 0; i < items.length; ++i) {
          if (locationPath === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }

      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = item => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <SidebarContent />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
