import React, { Component } from "react";
import { Collapse, Button } from "reactstrap";
import { get } from "lodash";

import { VariableToComponent } from "../../CommonElements/BaseElements";
import FormWrapper from "../../Forms/FormWrapper";
import InputButtonWithState from "../../Forms/InputButtonWithState";
import { PrintLabelBtn } from "../../../../pages/boda-boda/packages/includes/ShipingLabel";
import { BulkPrintLabelBtn } from "../../../../pages/boda-boda/packages/includes/BulkPrintBtn";

const emptyFn = (...para) => undefined;

class Header extends Component {

  static defaultProps = {
    title: "",
    isSetCreateAction: true,
    dataTableStateKey: "",
    isFilter: false,
    defaultFilterFormObject: {},
    filterFromComponent: null,
    createActionProps: {},
    onRebuildFilterObjFn: false,
    isOpenFilterCollapse: false,
    onCreateFn: emptyFn,
    onFilterFn: emptyFn,
    onResetFn: emptyFn,
    searchButtonOnTop: false,
    bulkPrintAll: false,
    readyForReturn: false,
    headerBtn: <React.Fragment></React.Fragment>
  }

  constructor(props) {
    super(props);
    this.state = {
      isFilterShow: false
    }
  }

  render() {

    let {
      isSetCreateAction, onCreateFn, isFilter,
      filterFromComponent, dataTableStateKey,
      defaultFilterFormObject, onFilterFn, onResetFn,
      createActionProps, onRebuildFilterObjFn, isOpenFilterCollapse,
      isBulkPrint, searchButtonOnTop, bulkPrintAll, readyForReturn, headerBtn
    } = this.props;

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-md-6">
            <h4 className="card-title">{this.props.title}</h4>
          </div>
          <div className="col-md-6 text-right">

            {
              ((this.state.isFilterShow === true || isOpenFilterCollapse) && searchButtonOnTop) ? (
                <React.Fragment>
                  <div className="btn-group">
                    <InputButtonWithState
                      btnName="filter"
                      btnText={isOpenFilterCollapse ? "View" : "Search"}
                      formGroupName={`${dataTableStateKey}_search`}
                      onClickBtnFn={(eventData) => {
                        let filterData = eventData.formObject;

                        if (onRebuildFilterObjFn !== false) {
                          filterData = onRebuildFilterObjFn(eventData.formObject);
                        }

                        onFilterFn(filterData);
                      }}
                      startIcon={"fas fa-search"}
                    />
                  </ div>{" "}
                  <div className="btn-group">
                    {isOpenFilterCollapse ? null :
                      <InputButtonWithState
                        btnName="reset"
                        btnText={"Reset"}
                        formGroupName={`${dataTableStateKey}_search`}
                        mergeToForm={defaultFilterFormObject}
                        onClickBtnFn={() => onResetFn()}
                        startIcon={"fas fa-sync-alt"}
                      />}
                  </div>{" "}
                </React.Fragment>
              ) : (null)
            }

            {
              (isSetCreateAction === true) ? (
                <Button
                  color="primary"
                  className="btn btn-primary waves-effect waves-light mr-1"
                  onClick={() => {
                    if (get(createActionProps, "isSetModel", false) !== false) {
                      onCreateFn();
                    } else {
                      get(createActionProps, "onClickBtnFn", emptyFn)();
                    }
                  }}
                >
                  <i className="mdi mdi-plus-circle-outline mr-2"></i>
                  {get(createActionProps, "actionBtn.btnText", "Create New")}
                </Button>
              ) : null}

            {isBulkPrint == true ? (
              <React.Fragment>
                <BulkPrintLabelBtn
                  type='bulk'
                  className='btn btn-primary waves-effect waves-light mr-3'
                />
                {" "}
              </React.Fragment>
            ) : null}

            {bulkPrintAll || readyForReturn ? headerBtn : null}

            {
              (isFilter === true) ? (
                <Button
                  color="primary"
                  onClick={() => this.setState({
                    ...this.state,
                    isFilterShow: !this.state.isFilterShow
                  })}
                  className="btn btn-primary waves-effect waves-light"
                >
                  <i className="mdi mdi-filter-variant mr-2"></i>
                  Filter
                </Button>
              ) : (null)
            }
          </div>
        </div>
        {
          (this.state.isFilterShow === true || isOpenFilterCollapse) ? (
            <div className="row mb-3">
              <div className="col-12">
                <Collapse isOpen={this.state.isFilterShow || isOpenFilterCollapse}>

                  <FormWrapper
                    setGroupName={`${dataTableStateKey}_search`}
                    formGroupLinkWith={dataTableStateKey}
                    setFormObject={defaultFilterFormObject ? defaultFilterFormObject : {}}
                  >
                    <VariableToComponent
                      component={filterFromComponent}
                      props={{
                        "dataTableStateKey": dataTableStateKey,
                        "searchFromKey": `${dataTableStateKey}_search`,
                        "defaultFilterFormObject": defaultFilterFormObject
                      }}
                    />
                  </FormWrapper>

                  {!searchButtonOnTop ?
                    <div className="col-md-12 text-left buttonWrapper">
                      <InputButtonWithState
                        elementWrapperStyle={"mr-2"}
                        btnName="filter"
                        btnText={isOpenFilterCollapse ? "View" : "Search"}
                        formGroupName={`${dataTableStateKey}_search`}
                        onClickBtnFn={(eventData) => {
                          let filterData = eventData.formObject;

                          if (onRebuildFilterObjFn !== false) {
                            filterData = onRebuildFilterObjFn(eventData.formObject);
                          }

                          onFilterFn(filterData);
                        }}
                        startIcon={"fas fa-search"}
                      />
                      {isOpenFilterCollapse ? null :
                        <InputButtonWithState
                          btnName="reset"
                          btnText={"Reset"}
                          formGroupName={`${dataTableStateKey}_search`}
                          mergeToForm={defaultFilterFormObject}
                          onClickBtnFn={() => onResetFn()}
                          startIcon={"fas fa-sync-alt"}
                        />}
                    </div>
                    : null}

                </Collapse>
              </div>
            </div>
          ) : (null)
        }
      </React.Fragment>
    )
  }

}

export default Header;
