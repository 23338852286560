import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import { RemoveMessage, RemoveBtn } from "./includes/DeleteUI";
import { FilterUI } from "./includes/FilterUI";
import { getZoneAPI,getPackageListAPI } from "../../../config/boda-boda/apiUrls.config";


class Zones extends Component {
  render() {
    let { history, formState } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Zones</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Zones</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Zone Details"
              filterFromComponent={FilterUI}
              apiURL={`${getZoneAPI.url}?page=1&limit=20`}
              dataTableStateKey={getZoneAPI.key}
              defaultFilterFormObject={{ code: "", name: "", facilityId: "", cityId:"" }}
              isDropdownActionBtn={true}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: false,
                onClickBtnFn: () => history.push("/zones/create")
              }}
              tableHeaderList={[
                { displayName: "Zone Code", key: "code" },
                { displayName: "Zone Name", key: "name" },
                { displayName: "Delivery Center", key: "facility.name", default: "-" },
                { displayName: "Status", key: "status" },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/zones/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/zones/edit/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    console.log("******************", rawData);
                    return !get(formState, `${getZoneAPI.key}_create.cities`, []).includes(rawData.rowData.id)
                }
                }
              ]}
            />
          </Row>

        </div>
      </React.Fragment>
    )
  }
}

export {
  Zones
};
