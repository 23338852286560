import React, { Component } from "react";
import {get, uniqBy , sortBy} from 'lodash';
import { useSelector } from "react-redux";

import { InputBoxWithState, RadioButton,SelectBoxWithState, DatePickerBoxWithState } from "../../../../../components/boda-boda/Forms";
import {getMerchantBranchAllAPI, getAllFacilityAPI, getAllManifestListAPI} from "../../../../../config/boda-boda/apiUrls.config";
import { collectionStatus } from "../../../../../config/boda-boda/db_config/db_status.config";


const FilterUI = (props) => {
    
    
    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Collection Code"
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        maxLength={20}
                        placeHolderText={"Collection Code"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        isRequired={true}
                        labelText="Status"
                        inputName={"status"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Status"}
                        dataList={collectionStatus}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Pick Up Location"}
                        placeHolderText={"Pick Up Location"}
                        inputName={"locationPickId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={`${getAllFacilityAPI.url}`}
                        apiStateKey={`${getAllFacilityAPI.key}_pickLocation`}
                        keyName={"id"}
                        valueName={"name"}
                        onRebuildResponseFn={(data) => {
                            const temp = [];

                            data.forEach((value) => {
                                temp.push({
                                    id: get(value, "location.id", ""),
                                    name: get(value, "location.name", ""),
                                })

                                return temp
                            });
                            let newTemp = uniqBy(temp, 'id', " ");

                            return {
                                "data": {
                                    "data": newTemp
                                }
                            }
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Drop Location"}
                        placeHolderText={"Drop Location"}
                        inputName={"locationDropId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={`${getAllFacilityAPI.url}`}
                        apiStateKey={`${getAllFacilityAPI.key}_dropLocation`}
                        keyName={"id"}
                        valueName={"name"}
                        onRebuildResponseFn={(data) => {
                            const temp = [];

                            data.forEach((value) => {
                                temp.push({
                                    id: get(value, "location.id", ""),
                                    name: get(value, "location.name", ""),
                                })

                                return temp
                            });
                            let newTemp = uniqBy(temp, 'id', " ");

                            return {
                                "data": {
                                    "data": newTemp
                                }
                            }
                        }}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Current Location"}
                        placeHolderText={"Current Location"}
                        inputName={"locationCurrentId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={`${getAllFacilityAPI.url}`}
                        apiStateKey={`${getAllFacilityAPI.key}_currentLocation`}
                        keyName={"id"}
                        valueName={"name"}
                        onRebuildResponseFn={(data) => {
                            const temp = [];

                            data.forEach((value) => {
                                console.log("Value", value)
                                temp.push({
                                    id: get(value, "location.id", ""),
                                    name: get(value, "location.name", ""),
                                })

                                return temp
                            });
                            let newTemp = uniqBy(temp, 'id', " ");

                            return {
                                "data": {
                                    "data": newTemp
                                }
                            }
                        }}
                    />
                </div>
                
                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"From Date"}
                        inputName={"dateFrom"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"From Date"}
                    />
                </div>

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"To Date"}
                        inputName={"dateTo"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"To Date"}
                    />
                </div>
                {/* <SelectBoxWithState
                    labelText={"Destination Delivery Center"}
                    placeHolderText={"Destination Delivery Center"}
                    inputName={"locationDropId"}
                    formGroupName={props.searchFromKey}
                    apiUrl={`${getAllManifestListAPI.url}`}
                    apiStateKey={`${getAllManifestListAPI.key}_dropLocation`}
                    keyName={"id"}
                    valueName={"name"}
                    onRebuildResponseFn={(data) => {
                        const temp = [];
                        
                        data.forEach((value) => {
                                temp.push({
                                    id: get(value, "locationDrop.id", ""),
                                    name: get(value, "locationDrop.name", ""),
                            })
                            
                            return temp
                        });
                        let newTemp = uniqBy(temp , 'id', '');
                        return {
                            "data": {
                                "data": newTemp
                            }
                        }
                    }}
                    /> */}
                
            </div>
        </div>
    )
}
export default  FilterUI;
