import React, { Component, Fragment, useEffect, useState } from "react";
import _, { get, findIndex } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import JsBarcode from "jsbarcode";

import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { getPackageListAPI, getUserStaffList, merchantAPI } from "../../../../config/boda-boda/apiUrls.config";
import { setPageLoader, setFlashMessage } from "../../../../store/boda-boda/ui/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { printerServiceAPIUrl } from "../../../../config/boda-boda/core.config";
import { printModalHelperFn } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { updateAuthUserPrinterTypeFn } from "../../../../store/actions";
import ShippingLabelLogo from "../../../../assets/images/BodaBoda_logoFA-01.png"
import { ShippingLabelUI } from "./ShippingLabelUI";


class ShippingLabel extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    btnText="Shipping Label"
                    onClickBtnFn={() => onClickFn()}
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fa fa-barcode"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Shipping Label"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<ShippingLabelUI packages={[this.props.package]} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLabelBtn
                            type={"create"}
                            setModelStatusFn={(status) => this.setState({ isShow: status })}
                        />
                    }
                />
            </Fragment>
        );

    }
}

const PrintLabelBtn = (props) => {
    const formState = useSelector(state => state.FormData);
    const authState = useSelector(state => state.AuthState);
    const packages = useSelector(state => state?.CoreState?.dataTableResponses?.packageListAPIKey?.results);
    const dispatch = useDispatch();
    const [dropdownState, setDropDownState] = useState(false);

    const printingTypes = {
        "NORMAL": "A4",
        "LABEL": "Sticker"
    };

    const getPackageNo = (data = [], id) => {
        const index = findIndex(data, { id: parseInt(id) });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return "";
        }
    }

    const getSelectedPackages = () => {
        let selecteditemList = [];
        const chkList = get(formState, 'packageListAPIKey', null);

        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'tableSelect')) {
                return item.replace('tableSelect', '');
            }
        })

        selecteditemList = selecteditemList.filter(function (element) {
            return element !== undefined;
        });

        let selectedObjects = [];
        if (packages !== null) {
            for (let i = 0; i < packages?.length; i += 1) {
                for (let j = 0; j < selecteditemList?.length; j += 1) {
                    if (packages[i]?.id == selecteditemList[j]) {
                        selectedObjects.push(packages[i]);
                    }
                }
            }
        }

        console.log('selectedObjects', selectedObjects);

        return selectedObjects;
    }

    const sendPrintRequest = async (printType) => {
        try {
            dispatch(setPageLoader(true));

            if (printType !== get(authState, "authUser.staff.printerType", "NORMAL")) {
                const staffAPIResponse = await callApi(`${getUserStaffList.url}/${get(authState, "authUser.staff.id")}`)
                    .headers(true)
                    .method("put")
                    .body({ printerType: printType })
                    .send();

                if (staffAPIResponse._statue === true) {
                    dispatch(updateAuthUserPrinterTypeFn(printType));
                }
            }

            if (printType === "NORMAL") {
                dispatch(setPageLoader(false));
                printModalHelperFn("print_preview_multiple");
            } else {
                let dataObj = {
                    "labelType": 1
                }

                if (get(props, "type", "") === "bulk") {
                    let apiBodyArray = [];
                    let selectedPackages = getSelectedPackages();

                    if (selectedPackages !== null && selectedPackages?.length !== 0) {
                        for (let i = 0; i < selectedPackages.length; i += 1) {
                            let item = {
                                "collectionNo": null,
                                "orderNo": get(selectedPackages[i], "order.channelOrderNo", "").toString(),
                                "articledID": get(selectedPackages[i], "articleNo", ""),
                                "consignmentNo": get(selectedPackages[i], "order.consignmentNo", ""),
                                "packageNo": getPackageNo(get(selectedPackages[i], "order.packages", []), get(selectedPackages[i], "id", "")),
                                "packageCategory": get(selectedPackages[i], "category.name", ""),
                                "signatureOnDelivery": get(selectedPackages[i], "order.signatureRequired", false) === false ? "No" : "Yes",
                                "paymentType": get(selectedPackages[i], "order.paymentMethod", ""),
                                "delivertoName": get(selectedPackages[i], "order.name", "") + ", " + get(selectedPackages[i], "order.shippingLocation.address", "") + ", " + get(selectedPackages[i], "order.shippingLocation.city.name", "") + ", " + get(selectedPackages[i], "order.shippingLocation.city.district.name", "") + ", " + get(selectedPackages[i], "order.shippingLocation.city.district.province.name", ""),
                                "delivertoPhone": get(selectedPackages[i], "order.phone", ""),
                                "senderName": `${get(selectedPackages[i], `merchant.name`, "")}, ${get(selectedPackages[i], `merchantBranch.name`, "")}, ${get(selectedPackages[i], `merchantBranch.location.address`, "")}, ${get(selectedPackages[i], `merchantBranch.location.city.name`, "")}, ${get(selectedPackages[i], `merchantBranch.location.postalCode`, "")}`,
                                "senderPhone": get(selectedPackages[i], `merchant.contacts[0].phone`, ""),
                                "specialNotes": get(selectedPackages[i], "notesPublic", ""),
                            }
                            apiBodyArray.push(item);
                        }
                        //loop here and add to array.
                        dataObj["barcodeLabels"] = [
                            ...apiBodyArray
                        ];
                    }
                } else if (get(props, "type", "") === "datatable") {
                    dataObj["barcodeLabels"] = [
                        {
                            "collectionNo": null,
                            "orderNo": get(props, "row.order.channelOrderNo", "").toString(),
                            "articledID": get(props, "row.articleNo", ""),
                            "consignmentNo": get(props, "row.order.consignmentNo", ""),
                            "packageNo": getPackageNo(get(props, "row.order.packages", []), get(props, "row.id", "")),
                            "packageCategory": get(props, "row.category.name", ""),
                            "signatureOnDelivery": get(props, "row.order.signatureRequired", false) === false ? "No" : "Yes",
                            "paymentType": get(props, "row.order.paymentMethod", ""),
                            "delivertoName": get(props, "row.order.name", "") + ", " + get(props, "row.order.shippingLocation.address", "") + ", " + get(props, "row.order.shippingLocation.city.name", "") + ", " + get(props, "row.order.shippingLocation.city.district.name", "") + ", " + get(props, "row.order.shippingLocation.city.district.province.name", ""),
                            "delivertoPhone": get(props, "row.order.phone", ""),
                            "senderName": `${get(props, `row.merchant.name`, "")}, ${get(props, `row.merchantBranch.name`, "")}, ${get(props, `row.merchantBranch.location.address`, "")}, ${get(props, `row.merchantBranch.location.city.name`, "")}, ${get(props, `row.merchantBranch.location.postalCode`, "")}`,
                            "senderPhone": get(props, `row.merchant.contacts[0].phone`, ""),
                            "specialNotes": get(props, "row.notesPublic", ""),
                        }
                    ];
                } else {
                    dataObj["barcodeLabels"] = [
                        {
                            "collectionNo": null,
                            "orderNo": get(formState, `${getPackageListAPI.key}_view.order.channelOrderNo`, "").toString(),
                            "articledID": get(formState, `${getPackageListAPI.key}_view.articleNo`, ""),
                            "consignmentNo": get(formState, `${getPackageListAPI.key}_view.order.consignmentNo`, ""),
                            "packageNo": getPackageNo(get(formState, `${getPackageListAPI.key}_view.order.packages`, []), get(formState, `${getPackageListAPI.key}_view.id`, "")),
                            "packageCategory": get(formState, `${getPackageListAPI.key}_view.category.name`, ""),
                            "signatureOnDelivery": get(formState, `${getPackageListAPI.key}_view.order.signatureRequired`, false) === false ? "No" : "Yes",
                            "paymentType": get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, ""),
                            "delivertoName": `${get(formState, `${getPackageListAPI.key}_view.order.name`, "")}, ${get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.address`, "")}, ${get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.name`, "")}, ${get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.name`, "")}, ${get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.province.name`, "")}`,
                            "delivertoPhone": get(formState, `${getPackageListAPI.key}_view.order.phone`, ""),
                            "senderName": `${get(props, `row.merchant.name`, "")}, ${get(formState, `${getPackageListAPI.key}_view.merchantBranch.name`, "")}, ${get(formState, `${getPackageListAPI.key}_view.merchantBranch.location.address`, "")}, ${get(formState, `${getPackageListAPI.key}_view.merchantBranch.location.city.name`, "")}, ${get(formState, `${getPackageListAPI.key}_view.merchantBranch.location.postalCode`, "")}`,
                            "senderPhone": get(props, `row.merchant.contacts[0].phone`, ""),
                            "specialNotes": get(formState, `${getPackageListAPI.key}_view.notesPublic`, "")
                        }
                    ];
                }

                let selectedPackages = getSelectedPackages();
                if (get(props, "type", "") === "bulk" && (selectedPackages === null || selectedPackages?.length === 0)) {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "No packages has been selected",
                        type: "danger",
                    }));
                    dispatch(setPageLoader(false));
                } else {
                const printerAPIResponse = await callApi(printerServiceAPIUrl)
                    .method("post")
                    .headers(true)
                    .body(dataObj)
                    .send();

                dispatch(setPageLoader(false));

                if (printerAPIResponse._statue === true) {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "Request has sent to the printer",
                        type: "success",
                    }));
                } else {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "An error has occurred",
                        type: "danger",
                    }));
                }
            }
            }
        } catch (error) {
            dispatch(setPageLoader(false));

            dispatch(setFlashMessage({
                status: true,
                message: "An error has occurred",
                type: "danger",
            }));
        }
    }

    return (
        <ButtonDropdown
            isOpen={dropdownState}
            toggle={() => setDropDownState(!dropdownState)}
            direction="left"
        >
            <Button
                color="primary"
                onClick={() => sendPrintRequest(get(authState, "authUser.staff.printerType", "NORMAL"))}
            >
                Print as {printingTypes[get(authState, "authUser.staff.printerType", "NORMAL")]}
            </Button>

            {/*<DropdownToggle caret color={"primary"}>*/}
            {/*    Print <i className="mdi mdi-chevron-down"></i>*/}
            {/*</DropdownToggle>*/}

            <DropdownToggle split caret color="info">
                <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem
                    onClick={() => sendPrintRequest("NORMAL")}
                >
                    A4
                </DropdownItem>

                <DropdownItem
                    onClick={() => sendPrintRequest("LABEL")}
                >
                    Sticker
                </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export {
    ShippingLabel,
    PrintLabelBtn
};
