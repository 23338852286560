import React, { Component } from "react";
import { connect } from "react-redux";
import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import {
    getLastMileAPI,
    getRouteLastCityAPI,
} from "../../../../../config/boda-boda/apiUrls.config";
import { get } from "lodash";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";

class RouteViewModelUI extends Component {
    render() {
        let { formState, row } = this.props;
        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getLastMileAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getLastMileAPI.url}/${row.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            status: response.status,
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Route ID</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getLastMileAPI.key}_view.id`, "")}</dt>

                                <dd className={"col-sm-4"}>Route Name</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getLastMileAPI.key}_view.name`, "")}</dt>

                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Type</dd>
                                <dt className={"col-sm-8"}>LASTMILE</dt>

                                <dd className={"col-sm-4"}>Route Status</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getLastMileAPI.key}_view.status`, "")}</dt>
                            </dl>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Waypoint Details</h4>
                        </div>

                        <CrudComponent
                            title={"Cities"}
                            apiURL={`${getRouteLastCityAPI.url}?routeLastId=${row.id}&page=1&limit=20`}
                            dataTableStateKey={getRouteLastCityAPI.key}
                            isSetCreateAction={false}
                            isFilter={false}
                            isSetAction={false}
                            tableHeaderList={[
                                { displayName: "No", key: "id" },
                                { displayName: "City", key: "city.name" },
                                { displayName: "District", key: "city.district.name" },
                                { displayName: "Province", key: "city.district.province.name" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(RouteViewModelUI)
