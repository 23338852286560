import React from "react";
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { ToolTipComponent } from "../CommonElements/BaseElements";


const emptyFn = (...para) => undefined;

const InputButton = ({
    elementWrapperStyle = "",
    elementStyle = "",
    btnName = "",
    btnText = "",
    tooltip = "",
    btnSize = "md",
    btnColor = "primary",
    isFullWidth = false,
    isBtnDisabled = false,
    isOutlined = false,
    startIcon = null,
    endIcon = null,
    onClickBtnFn = emptyFn
}) => {
    return (
        <div className={`${elementWrapperStyle}`}>
            <Button
                id={btnName}
                className={`${elementStyle}`}
                outline={isOutlined}
                disabled={isBtnDisabled}
                color={btnColor}
                size={btnSize}
                block={isFullWidth}
                onClick={(event) => onClickBtnFn({ name: btnName, eventInfo: event })}
            >
                {
                    (startIcon !== null) ? (
                        <i className={`${startIcon} ${btnText ? "mr-2" : ""}`}></i>
                    ) : null
                }
                {btnText}
                {
                    (endIcon !== null) ? (
                        <i className={`${endIcon} ${btnText ? "ml-2" : ""}`}></i>
                    ) : null
                }
            </Button>

            {
                (tooltip !== "") ? (
                    <ToolTipComponent
                        tooltipText={tooltip}
                        target={btnName}
                    />
                ) : null
            }
        </div>
    )
}

const DropdownButton = ({
    isOpen = false,
    elementWrapperStyle = "",
    btnText = "",
    btnColor = "primary",
    itemList = [],
    toggleFn = emptyFn,
    onItemClickFn = emptyFn,
}) => {
    return (
        <div className={elementWrapperStyle}>
            <ButtonDropdown
                isOpen={isOpen}
                toggle={() => toggleFn(!isOpen)}
            >
                <DropdownToggle caret color={btnColor}>
                    {btnText} <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        itemList.map((value, index) => {
                            return (
                                <DropdownItem
                                    key={index}
                                    onClick={() => onItemClickFn(value["value"])}
                                >
                                    {value["name"]}
                                </DropdownItem>
                            )
                        })
                    }
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
}

export {
    InputButton,
    DropdownButton
}
