import React from "react";

import { SubmitButton } from "../../../../components/boda-boda/Forms";
import { getOrdersListAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";

const RemoveMessage = () => {
    return (
        <p>Are your sure you want to delete this user?</p>
    );
}

const RemoveBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Delete"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            dataTableKey={getUserStaffList.key}
            isValidate={false}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getUserStaffList.url}/${props.row.id}`,
                    key: getUserStaffList.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    );
}

export {
    RemoveBtn,
    RemoveMessage
}
