import { get, set } from "lodash";
import {
    initDataTableKey, setDataTableKey, updateDataTableObjectKey,
    shortDataTableDataKey, setReloadDataTableKey, setApiResponseKey,
    updateDataTableFieldValueKey, initResponseKey, updateDataTableRowKey,
    setApiResponseErrorKey, setInitialState, removeDatatable,
    setFetchingInit
} from "./actionTypes";


const initialState = {
    dataTableResponses: {},
    apiResponses: {},
};

const CoreState = (state = initialState, action) => {
    switch (action.type) {
        //init Data Table
        case initDataTableKey:
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        "fetching": 'init',
                        "current_page": 1,
                        "page_count": null,
                        "per_page": 10,
                        "page_size":10,
                        "total": null,
                        "results": [],
                        "shortBy": {},
                        "_updateStatus": false
                    }
                }
            };
        case setDataTableKey:
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...get(state, `dataTableResponses.${action.stateKey}`, {}),
                        "fetching": get(action, 'fetching', 'done'),
                        "current_page": get(action, 'current_page', 1),
                        "per_page": get(action, 'per_page', 10),
                        "page_size": get(action, 'page_size', 10),
                        "page_count": get(action, 'page_count', 1),
                        "total": get(action, 'total', null),
                        "results": get(action, 'results', []),
                        "totalsRow": get(action, 'totalsRow', null),
                        "_updateStatus": !get(state, `dataTableResponses.${action.stateKey}._updateStatus`, false)
                    }
                }
            }
        case shortDataTableDataKey:
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...state.dataTableResponses[action.stateKey],
                        shortBy: {
                            ...state.dataTableResponses[action.stateKey].shortBy,
                            [action.key]: action.status,
                            "_updateStatus": !get(state, `dataTableResponses.${[action.stateKey]}.shortBy._updateStatus`, false)
                        },
                        _updateStatus: !state.dataTableResponses[action.stateKey]["_updateStatus"]
                    }
                },

            };
        case updateDataTableObjectKey:
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...state.dataTableResponses[action.stateKey],
                        ...action.playload,
                        _updateStatus: !state.dataTableResponses[action.stateKey]["_updateStatus"]
                    }
                },
            }
        case setReloadDataTableKey:
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...state.dataTableResponses[action.stateKey],
                        _reloadDataTable: !get(state, `dataTableResponses.${action.stateKey}._reloadDataTable`, false),
                        _updateStatus: !state.dataTableResponses[action.stateKey]["_updateStatus"]
                    }
                },
            }
        case updateDataTableFieldValueKey: {
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...state.dataTableResponses[action.stateKey],
                        results: set(state.dataTableResponses[action.stateKey]["results"], action.inputKey, action.value),
                        _updateStatus: !state.dataTableResponses[action.stateKey]["_updateStatus"]
                    }
                },
            }

        }
        case updateDataTableRowKey: {

            state.dataTableResponses[action.stateKey]["results"][action.index]=action.playload;

            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...state.dataTableResponses[action.stateKey],
                        results:state.dataTableResponses[action.stateKey]["results"],
                        _updateStatus: !state.dataTableResponses[action.stateKey]["_updateStatus"]
                    }
                },
            }
        }
        case initResponseKey:
            return {
                ...state,
                apiResponses: {
                    ...state.apiResponses,
                    [action.stateKey]: {
                        result: [],
                        _updateStatus: !get(state,`apiResponses.${action.stateKey}._updateStatus`,false),
                        status:"fetching"
                    }
                }
            }
        case setApiResponseKey:
            return {
                ...state,
                apiResponses: {
                    ...state.apiResponses,
                    [action.stateKey]: {
                        result: action.playload,
                        _updateStatus: !get(state,`apiResponses.${action.stateKey}._updateStatus`,false),
                        status:"done"
                    }
                }
            }
        case setApiResponseErrorKey:
            return {
                ...state,
                ...set(state,action.path,action.playload)
            }
        case setInitialState:
            return initialState;
        case removeDatatable:
            delete state.dataTableResponses[action.stateKey];
            return state;
        case setFetchingInit:
            return {
                ...state,
                dataTableResponses: {
                    ...state.dataTableResponses,
                    [action.stateKey]: {
                        ...action.stateKey,
                        "fetching": 'init'
                    }
                }
            }
        default:
            return state;
    }
}

export default CoreState;
