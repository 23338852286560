import _, { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { resetDataTable, setBulkValues, setFlashMessage, setPageLoader } from "../../../../store/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { hubSettlementAPI } from "../../../../config/boda-boda/apiUrls.config";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { Button } from "reactstrap";


const MarkAsReceivedBtn = (props) => {

    const formState = useSelector((state) => state.FormData);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { id } = useParams();

    const getSelectedSettlements = () => {
        try {
            let selecteditemList = [];
            const chkList = get(formState, `${hubSettlementAPI.key}`, null);

            let selectedKeys = [];
            if (chkList !== null) {
                for (const [key, value] of Object.entries(chkList)) {
                    if (value === true) {
                        selectedKeys.push(key);
                    }
                }
            }

            selecteditemList = selectedKeys.map((item) => {
                if (_.startsWith(item, 'tableSelect')) {
                    return item.replace('tableSelect', '');
                }
            });
            selecteditemList = selecteditemList.filter(function (element) {
                return element !== undefined;
            });
            selecteditemList = selecteditemList.map((item) => {
                return Number(item)
            });

            return selecteditemList
        } catch (error) {
            dispatch(setFlashMessage({
                status: true,
                message: 'An error has occurred',
                type: 'danger',
            }))
        }
    };

    const clearSettlements = () => {
        let chkList = get(formState, `${hubSettlementAPI.key}`, null);
        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        let selecteditemList = [];
        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'tableSelect')) {
                let pair = {};
                let value = item;
                pair[value] = false;
                return pair;
            }
        });
        if (get(formState, `${hubSettlementAPI.key}.selectall`, null) !== null) {
            let selectAll = {};
            selectAll['selectall'] = false;
            selecteditemList.push(selectAll);
        }

        for (let i = 0; i < selecteditemList?.length; i += 1) {
            dispatch(setBulkValues(`${hubSettlementAPI.key}`, selecteditemList[i]))
        }
    }

    const markAsHeadOfficeRecieved = async () => {
        try {
            dispatch(setPageLoader(true));
            const selectedSettlements = getSelectedSettlements();

            if (selectedSettlements?.length === 0) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: 'No settlements selected',
                        type: 'danger',
                    })
                );
                setShow(false);
                dispatch(setPageLoader(false));
                return;
            }


            const body = {
                "received": true
            }
            let isError = false;
            for (const settlementId of selectedSettlements) {
                const hubSettlementAPIResponse = await callApi(
                    `${hubSettlementAPI.url}/${settlementId}/receive`
                )
                    .headers(true)
                    .method('put')
                    .body(body)
                    .send();
                if (!hubSettlementAPIResponse._statue) {
                    isError = true;
                    break;
                }
            }

            if (isError) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: "Something went wrong!!",
                        type: "danger"
                    })
                );
            } else {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: 'Settled successfully',
                        type: 'success',
                    })
                );
            }

            clearSettlements()
            dispatch(resetDataTable(`${hubSettlementAPI.key}`));
            setShow(false)
            dispatch(setPageLoader(false));

        } catch (error) {
            setShow(false);
            dispatch(setPageLoader(false));

            dispatch(
                setFlashMessage({
                    status: true,
                    message: 'An error has occurred',
                    type: 'danger',
                })
            );
        }
    }

    return (
        <React.Fragment>
            <Button
                color='primary'
                className='btn btn-primary waves-effect waves-light'
                onClick={() => setShow(true)}
            >
                Mark as Received
            </Button>
            <ModalUI
                isOpen={show}
                modalTitle={'Please Confirm'}
                modalBodyComponent={`Are you sure you want to mark ${getSelectedSettlements()?.length
                    } settlements as Head Office Received?`}
                showHideToggleFn={() => setShow(false)}
                closeBtnName='No'
                modalFooterComponent={
                    <Button
                        color='primary'
                        onClick={() => {
                            markAsHeadOfficeRecieved()
                        }
                        }
                    >
                        Yes
                    </Button>
                }
            />
        </React.Fragment>
    )
}

export default MarkAsReceivedBtn;