import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { get } from "lodash"

import { initFormFn,initFromObjectFn,removeFromGroupFn, generateApiCallObjFn, requestAPICallFn } from "../../../store/actions";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";
import { CustomLoader } from "../CommonElements/BaseElements";


const emptyFun = (...para) => undefined;

class FormWrapper extends Component {

    static defaultProps = {
        elementStyle: "",
        isBackProsess: false,
        setDescription: "",
        setGroupName: "",
        formGroupLinkWith: "",
        setFormObject: {},
        apiUrl: "",
        onRebuildResponseFn:emptyFun,
        onDestroyUnsetFormObject: true,
        children: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        let {
            setGroupName,isBackProsess,setDescription,
            formGroupLinkWith,setFormObject,apiUrl,
            initFormFn, requestAPICallFn, onRebuildResponseFn
        } = this.props;
       
        const formObj=initFromObjectFn(setGroupName)
            .isBackProsess(isBackProsess)
            .setDescription(setDescription||setGroupName)
            .setGroupName(setGroupName)
            .setLinkWithOthers(formGroupLinkWith||setGroupName)
            .setFormObject(setFormObject)
            .setOnLoad(apiUrl?true:false)
            .generate();

            initFormFn({
                [setGroupName]:formObj
            });

            if (apiUrl) {
                const apiCallObj = generateApiCallObjFn(apiUrl)
                    .method("get")
                    .setInitStoring(apiResponseStoringType.apiResponseToFormState, setGroupName)
                    .setOnRebuildResponseFn((response)=> onRebuildResponseFn(response))
                    .generate();

                requestAPICallFn(apiCallObj);
            }

            // return () => {
            //    if(onDestroyUnsetFormObject){
            //     formAction.removeFromGroupFn(setGroupName);
            //    }
            // }

    }

    componentWillUnmount(){
        let {removeFromGroupFn,onDestroyUnsetFormObject,setGroupName} =this.props;
        if(onDestroyUnsetFormObject){
            removeFromGroupFn(setGroupName);
        }
    }

    render() {
        let { elementStyle, formData, setGroupName } = this.props;

        return (
            <div className={`col-md-12 padding-none ${elementStyle}`}>
                {
                    (get(formData, `${setGroupName}._onLoad`, true) === false) ? (
                        <Fragment>
                            {this.props.children}
                        </Fragment>
                    ) : (
                        <div className={"fullWidthDiv defaultMarginTopBottom"}>
                            <center>
                                <CustomLoader />
                            </center>
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      formData:state.FormData
    };
};

export default connect(mapStateToProps, {
    initFormFn,
    removeFromGroupFn,
    requestAPICallFn
})(FormWrapper);
