import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getRoleListAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";


class FilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Id"
                            inputName={"id"}
                            placeHolderText={"Customer Id"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Name"
                            inputName={"name"}
                            placeHolderText={"Name"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Identification"
                            inputName={"identification"}
                            placeHolderText={"Identification"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Phone"
                            inputName={"phone"}
                            placeHolderText={"Phone"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            placeHolderText={"Status"}
                            formGroupName={`${getUserStaffList.key}_edit`}
                            dataList={userStatusList}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default FilterUI;
