import React, {Component, Fragment} from "react";
import { useSelector } from "react-redux";
import {InputButton, SubmitButton} from "../../../../components/boda-boda/Forms";
import {ModalUI} from "../../../../components/boda-boda/Modal/ModalUI";
import {apiResponseStoringType} from "../../../../config/boda-boda/apiResponseKey";
import {getOrdersListAPI} from "../../../../config/boda-boda/apiUrls.config";

class PendingDeliveryUI extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    elementWrapperStyle={"mr-2"}
                    elementStyle={"btn waves-effect waves-light"}
                    btnText={"Pending Delivery"}
                    onClickBtnFn={() => onClickFn()}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Please Confirm"}
                    modalBodyComponent={<p>Are your sure you want to mark this consignment as Pending Delivery?</p>}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PendingDeliveryBtn
                            consignment={this.props.consignment}
                            historyFn={this.props.historyFn}
                        />
                    }
                />
            </Fragment>
        );
    }
}

const PendingDeliveryBtn = (props) => {
    const authStatus = useSelector(state => state.AuthState);
    return(
        <SubmitButton
            btnText={"Pending Delivery"}
            elementStyle={"btn waves-effect waves-light"}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Consignment status successfully changed",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${props.consignment}/ready-for-delivery`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {}
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push('/consignments');
                }
            }}
        />
    )
}

export {
    PendingDeliveryUI,
    PendingDeliveryBtn
}