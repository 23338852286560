import { put, call } from 'redux-saga/effects';
import validateMethod from "../../../helpers/boda-boda/validation";
import { get } from "lodash";
import {setFormErrorFn} from "../form/actions";
import {createAuthUserAPI, getRoleListAPI, getUserStaffList} from "../../../config/boda-boda/apiUrls.config";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {defaultCountryCode} from "../../../config/boda-boda/core.config";
import parsePhoneNumber from "libphonenumber-js";

const emptyFn = (...para) => undefined;

function* createMerchantAdministrationFn(data) {

    let validationResponse = null;

    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    if ( get(data, 'payload.user', {}) === "not_found"){
        validationResponse = yield call(() => validateMethod(
            get(data, 'payload', {}),
            {
                name: "Name",
                phone: "Phone",
                identification: "Identification",
                email: "Email",
                credential: "Credential",
                password: "Password",
                confirm_password: "Confirm Password",
                "countryCode.id": "Country Code",
            },
            {
                name: "required|min:4|max:100",
                phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
                identification: "required|min:8|max:20",
                email: "required|email|max:50",
                credential: "required|email|max:50",
                password: "required|passwordPolicy",
                confirm_password: "required|same:password",
                "countryCode.id": "required",
            },
            {
                "confirm_password.same": "Passwords do not match"
            },
        ));

    } else {
        validationResponse = yield call(() => validateMethod(
            get(data, 'payload', {}),
            {
                name: "Name",
                phone: "Phone",
                identification: "Identification",
                email: "Email",
                credential: "Credential",
                "countryCode.id": "Country Code",
            },
            {
                name: "required|min:4|max:100",
                phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
                identification: "required|min:8|max:20",
                email: "email|max:50",
                credential: "required|email|max:50",
                "countryCode.id": "required"
            },
            {},
        ));

    }

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getUserStaffList.key}_create`, validationResponse.errors));

    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_create`, []));

        const role = yield call( () => callApi(`${getRoleListAPI.url}/all?isDefault=true&type=MERCHANT`)
            .headers(true)
            .method("get")
            .send())

        let userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            credential: get(data, "payload.credential", ""),
            password: get(data, "payload.password", ""),
            merchant: {
                id: get(data, "payload.merchant", ""),
            },
            role: {
                id: get(role, "data.data[0].id", ""),
            },
            canSwitch: false
        };

        let userAPIRequest = null;

        if (get(data, 'payload.user', {}) === "not_found") {
            userAPIRequest = yield call(() => callApi(createAuthUserAPI.url).headers(true).method("post").body(userAPIRequestBody).send());
        }else {
            userAPIRequestBody.password = undefined;
            userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, 'payload.user.id', '')}`).headers(true).method("put").body(userAPIRequestBody).send());
        }


        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                user: {
                    id: get(userAPIRequest, "data.data.id", ""),
                },
                merchant: {
                    id: get(data, "payload.merchant", ""),
                },
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                category: "MERCHANT",
                status: "ACTIVE",
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            let staffAPIRequest = null;

            if (get(data, 'payload.staff', {}) === "not_found"){
                staffAPIRequest = yield call(() => callApi(getUserStaffList.url).headers(true).method("post").body(staffAPIRequestBody).send());
            }else {
                staffAPIRequest = yield call(() => callApi(`${getUserStaffList.url}/${get(data, 'payload.staff.id', "")}`).headers(true).method("put").body(staffAPIRequestBody).send());
            }

            if (staffAPIRequest._statue === false) {
                if (get(staffAPIRequest, "data.code", null) === 400){
                    yield put(setFormErrorFn(
                        `${getUserStaffList.key}_create`,
                        get(staffAPIRequest, "data.errors", [])
                    ))
                    yield put(setFlashMessage({
                        status: true,
                        message: get(staffAPIRequest, "data.errors[0].error", []),
                        type: "danger",
                    }));
                }
                yield call(() => callApi(`${createAuthUserAPI.url}/${get(userAPIRequest, "data.data.id", "")}`).headers(true).method("delete").send());
            } else {
                get(data, "payload.onNextFn", emptyFn)();
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400){
                yield put(setFormErrorFn(
                    `${getUserStaffList.key}_create`,
                    get(userAPIRequest, "data.errors", [])
                ))
                yield put(setFlashMessage({
                    status: true,
                    message: get(userAPIRequest, "data.errors[0].error", []),
                    type: "danger",
                }));
            }
        }
    }
    yield put(setPageLoader(false));
}

export {
    createMerchantAdministrationFn
}