import React, { Component, useEffect } from "react";
import JsBarcode from "jsbarcode";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { FormWrapper, InputButton } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { setFlashMessage, setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { printerServiceAPIUrl } from "../../../../config/boda-boda/core.config";


class CollectionCodePrint extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false  };
    }

    render() {
        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <React.Fragment>
                <InputButton
                    btnText={"Print Code"}
                    onClickBtnFn={() => onClickFn()}
                    startIcon={"mdi mdi-printer"}
                    elementWrapperStyle={"mr-2"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Collection Code Print"}
                    modalType={modalTypes.large}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalBodyComponent={
                        <CollectionCode
                            collectionCode={this.props.collectionCode}
                        />
                    }
                    modalFooterComponent={
                        <PrintCollectionCodeBtn
                            collectionCode={this.props.collectionCode}
                            setModelStatusFn={(status) => this.setState({ isShow: status })}
                        />
                    }
                />
            </React.Fragment>
        );
    }
}

const CollectionCode = (props) => {
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, "CollectionCode._onLoad", true) === false){
            JsBarcode("#CollectionCode", get(props, 'collectionCode', ""), {
                width: 2,
                height: 100,
                margin: 0,
            })
        }
    }, [
        get(formState, "CollectionCode._onLoad", true)
    ])

    return (
        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                <FormWrapper
                    setGroupName={"CollectionCode"}
                >
                    <div className={"col-md-12 text-center"}>
                        <img id="CollectionCode"/>
                    </div>
                </FormWrapper>
            </div>
        </div>
    )
}

const PrintCollectionCodeBtn = (props) => {
    const dispatch = useDispatch();

    const sendPrintRequest = () => {
        dispatch(setPageLoader(true));

        const dataObj = {
            "barcodeLabels": {
                "collectionNo": props.collectionCode,
                "orderNo": null,
                "articledID": null,
                "consignmentNo": null,
                "packageNo": null,
                "weightCategory": null,
                "signatureOnDelivery": null,
                "paymentType": null,
                "delivertoName": null,
                "delivertoPhone": null,
                "senderName": null,
                "senderPhone": null,
                "specialNotes": null
            },
            "labelType": 2
        }

        callApi(printerServiceAPIUrl)
            .method("post")
            .headers(true)
            .body(dataObj)
            .send()
            .then((result) => {
                if (result._statue === true) {
                    dispatch(setPageLoader(false));
                    dispatch(setFlashMessage({
                        status: true,
                        message: "Request has sent to the printer",
                        type: "success",
                    }));

                    props.setModelStatusFn(false);
                } else {
                    dispatch(setPageLoader(false));
                    dispatch(setFlashMessage({
                        status: true,
                        message: "An error has occurred",
                        type: "danger",
                    }));
                }
            });
    }

    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => sendPrintRequest()}
        />
    )
}

export {
    CollectionCode,
    CollectionCodePrint,
    PrintCollectionCodeBtn
}
