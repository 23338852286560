import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  headOfficeSettlementAPI,
} from "../../../../../config/boda-boda/apiUrls.config";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import {
  CrudComponent,
  actionBtnTypes,
} from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
  MerchantSettlementConfirmBtn,
  MerchantSettlementConfirmUI,
} from "./ConfirmMerchantSettlementUI";
import { setPageLoader } from "../../../../../store/actions";

export const MerchantSettlementView = (props) => {
  const formState = useSelector((state) => state.FormData);
  const coreState = useSelector(state => state.CoreState);
  const dispatch = useDispatch()
  const generatedMerchantSettlement = get(coreState, `apiResponses.${headOfficeSettlementAPI.key}.result`, [])
  const updatedMerchantSettlement = get(coreState, `apiResponses.${headOfficeSettlementAPI.key}_edit.result`, null)
  const generatedMerchantSettlementArray = Array.isArray(generatedMerchantSettlement) ? generatedMerchantSettlement : [generatedMerchantSettlement]

  useEffect(() => {
    if (updatedMerchantSettlement) {
      dispatch(setPageLoader(true))
      const objIndex = generatedMerchantSettlementArray.findIndex(obj => obj.id === updatedMerchantSettlement.id)
      if (objIndex !== -1) {
        generatedMerchantSettlement[objIndex] = updatedMerchantSettlement
      }
      dispatch(setPageLoader(false))
    }
  }, [updatedMerchantSettlement])

  return (
    <React.Fragment>
      {get(formState, `${headOfficeSettlementAPI.key}.settlementDate`, "") === "" ? (
        <Redirect to={"/merchant-settlement"} />
      ) : (
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Merchant Settlement</h4>
              </div>

              <div className={"row"}>
                <div className={"col-sm-12"}>
                  As of <strong className={"mx-2"}>{dateObjectToString(get(formState, `${headOfficeSettlementAPI.key}.settlementDate`), "dd/mm/YY")}</strong>
                </div>

                <div className={"col-sm-12"}>
                  Merchant:
                  <strong className={"mx-2"}>
                    {get(formState, `${headOfficeSettlementAPI.key}.merchant`, "") === ""
                      ? "All"
                      : get(formState, `${headOfficeSettlementAPI.key}.merchant`, "")?.split(
                        "/"
                      )[1]}
                  </strong>
                </div>
              </div>
            </Col>

            <Col sm={6}>
              <div className={"d-flex justify-content-end"}>
                <BackBtnComponent path={"/merchant-settlement"} />
              </div>
            </Col>
          </Row>

          <div className={"row mt-3"}>
            <CrudComponent
              title={"Merchant Settlement"}
              tableBodyList={generatedMerchantSettlementArray}
              dataTableStateKey={headOfficeSettlementAPI.key}
              isFilter={false}
              isSetCreateAction={false}
              isDropdownActionBtn={true}
              isSetAction={true}
              tableHeaderList={[
                { displayName: "Reference Number", key: "reference" },
                {
                  displayName: "Settlement Date",
                  key: "settlementDate",
                  onPrintFn: (rawData) =>
                    dateObjectToString(rawData.settlementDate, "YY-mm-dd"),
                },
                { displayName: "Merchant Code", key: "settleToMerchant.code" },
                { displayName: "Merchant Name", key: "settleToMerchant.name" },

                { displayName: "Number of Consignment", key: "orderCount" },
                { displayName: "COD Status", key: "settled", onPrintFn: (rawData => rawData.settled === true ? 'Settled' : 'Pending') },
                {
                  displayName: "Amount",
                  key: "total",
                  onPrintFn: (rawData) => {
                    return rawData.total === null ? 0 : rawData.total;
                  },
                },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: true,
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData?.rowData?.settled === false;
                  },
                  actionBtn: {
                    icon: "fas fa-undo",
                    color: "primary",
                    btnText: "Settle Merchant",
                  },
                  modalProps: {
                    modalTitle: "Confirm Merchant Settlement",
                    modalType: "",
                    modalBodyComponent: MerchantSettlementConfirmUI,
                    modalFooterComponent: MerchantSettlementConfirmBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  },
                },
              ]}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
