import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButton, SubmitButton
} from "../../../../components/boda-boda/Forms";
import {
    getAllLocationListAPI, getMerchantBranchAllAPI, getRoleListAPI, getUserStaffList
} from "../../../../config/boda-boda/apiUrls.config";
import { userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";


class EditUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, formState, history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Update Customer</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <EditPackageBtn
                                packageId={match.params.id}
                                historyFn={history}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={"/customer"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserStaffList.key}_edit`}
                            apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                            onDestroyUnsetFormObject={true}
                            onRebuildResponseFn={(response) => {
                                return {
                                    name: response.name,
                                    identification: response.identification,
                                    merchantBranch: response.merchantBranchId,
                                    role: response.user.role.id,
                                    phone: response.phone,
                                    email: response.email,
                                    category: "MERCHANT",
                                    status: response.status,
                                    "_onLoad": false,
                                }
                            }}
                        >

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Personal Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Customer Id"
                                                        placeHolderText={"Customer Id"}
                                                        inputName={"id"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={100}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Name"
                                                        placeHolderText={"Name"}
                                                        inputName={"name"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={100}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Identification"
                                                        placeHolderText={"Identification"}
                                                        inputName={"identification"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Address"
                                                        placeHolderText={"Address"}
                                                        inputName={"address"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={100}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="Province"
                                                        inputName={"province"}
                                                        placeHolderText={"Province"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        dataList={[{ id: 1, value: "Southern" }, { id: 2, value: "Western" }]}
                                                        isRequired={true}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="District"
                                                        inputName={"district"}
                                                        placeHolderText={"District"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        dataList={[{ id: 1, value: "Matara" }, { id: 2, value: "Colombo" }]}
                                                        isRequired={true}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="City"
                                                        inputName={"city"}
                                                        placeHolderText={"City"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        dataList={[{ id: 1, value: "Matara" }, { id: 2, value: "Colombo" }]}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"Phone"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        inputType={"number"}
                                                        isRequired={true}
                                                        maxLength={10}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Email"
                                                        inputName={"email"}
                                                        placeHolderText={"Email"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        maxLength={50}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormWrapper>

                    </Col>
                </Row>

            </div>
        );
    }
}

const EditPackageBtn = (props) => {
    return (
        <Fragment>
            <SubmitButton
                btnText={"Update"}
                elementWrapperStyle={"d-flex justify-content-end mr-2"}
                startIcon={"mdi mdi-content-save-all-outline"}
                isValidate={true}
                dataTableKey={getUserStaffList.key}
                formGroupName={`${getUserStaffList.key}_edit`}
                validationObject={{
                    fields: {
                        name: "Name",
                        email: "Email",
                        phone: "Phone",
                        identification: "identification",
                        merchantBranch: "Branch",
                        role: "Role",
                        status: "Status",
                    },
                    rules: {
                        name: "required|min:4|max:100",
                        email: "email|max:50",
                        // phone: "required|numeric|max:10",
                        identification: "required|max:20",
                        merchantBranch: "required",
                        // role: "required",
                        status: "required",
                    },
                    message: {}
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${getUserStaffList.url}/${props.packageId}`,
                        key: getUserStaffList.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    return {
                        name: formObject.name,
                        identification: formObject.identification,
                        merchantBranch: {
                            id: formObject.merchantBranch
                        },
                        phone: formObject.phone,
                        email: formObject.email,
                        category: "MERCHANT",
                        status: formObject.status,

                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.historyFn.push("/users");
                    }
                }}
            />
        </Fragment>
    )
}

export { EditUI };
