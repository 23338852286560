
import {setAuthTokenKey,setUnauthorisedUserKey,setAuthUserObjectKey, updateAuthUserPrinterType} from "./actionTypes";


const initialState={       
    accessToken: null,
    refreshToken: null,
    isAuthenticated: null,
    authUser:{
        "id": null,
        "email": null,
        "credential": null,
        "merchant": null,
        "role": null,
        "permissions": []
    }
};

const AuthState = (state = initialState, action) => {
    switch (action.type) {
        case setAuthTokenKey:
            return {
                ...state, 
                accessToken:action.payload.access_token,
                refreshToken:action.payload.access_token,
                isAuthenticated:true
            };
        case setUnauthorisedUserKey:
            return {
                ...state, 
                ...initialState,
                isAuthenticated:false
            }  
        case setAuthUserObjectKey:
            return {
                ...state, 
                authUser:{
                    ...state.authUser,
                    ...action.payload,
                }
            } 
        case updateAuthUserPrinterType:
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    staff: {
                        ...state.authUser.staff,
                        printerType: action.payload
                    }
                }
            }      
        default:
            return state;
    }
}

export default AuthState;
