import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import { FilterUI } from "./includes/FilterUI";
import { getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";
import { modalTypes } from "../../../components/boda-boda/Modal/config/model.config";
import { LabelPrint, PrintLabelBtn } from "./includes/PrintUI"
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { formatToDateHelper, isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { withRouter } from "react-router-dom";


class OutboundsCom extends Component {
  render() {
    let { history, authState } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Manage Outbounds</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Manage Outbounds</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Outbounds"
              filterFromComponent={FilterUI}
              isFilter={true}
              defaultFilterFormObject={{
                type: "",
                code: "",
                status: "",
                dateTo: null,
                dateFrom: null,
              }}
              clearErrors={() => {
                const { setFormErrorFn } = this.props;
                setFormErrorFn("manifestListAPIKey_search", [])
              }}
              onRebuildFilterObjFn={(filterData) => {
                const data = filterData;

                let { setFormErrorFn } = this.props;
                const errors = [];

                if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                  errors.push({
                    "property": "dateTo",
                    "error": "The To Date field should not be less than the From Date field "
                  })
                }

                if (errors.length > 0) {
                  setFormErrorFn("manifestListAPIKey_search", errors);
                  return;
                } else {
                  setFormErrorFn("manifestListAPIKey_search", []);
                }

                if (!isEmptyValue(filterData.dateTo)) {
                  data["dateTo"] = formatToDateHelper(filterData.dateTo);
                }

                if (!isEmptyValue(filterData.dateFrom)) {
                  data["dateFrom"] = filterData.dateFrom.toISOString();
                }

                return data;
              }}
              apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&types=["DELIVERY","COLLECTION","RETURN"]&statuses=["IN_TRANSIT_LAST","COMPLETED","IN_TRANSIT_LINE","IN_TRANSIT_RETURN"]&locationPickId=${get(authState, "authUser.facility.locationId")}`}
              dataTableStateKey={getManifestListAPI.key}
              isSetCreateAction={false}
              isDropdownActionBtn={true}
              tableHeaderList={[
                { displayName: "Manifest Code", key: "code" },
                { displayName: "Manifest Type", key: "type" },
                { displayName: "Destination Delivey Center", key: "locationDrop.name" },
                { displayName: "Current Location", key: "locationCurrent.name" },
                { displayName: "Pick Location", key: "locationPick.name" },
                { displayName: "Manifest Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt, "YY-mm-dd") },
                {
                  displayName: "Drop Location", key: "locationDrop.name", onPrintFn: (rawData) => {
                    if (rawData.type === "COLLECTION") {
                      return rawData.locationDrop.name;

                    } else {
                      return " "
                    }
                  }
                },
                {
                  displayName: "Status", key: "status", onPrintFn: (rawData) => {
                    if (rawData.status === "IN_TRANSIT_LINE") {
                      return "Intransit to regional hub";
                    } else if (rawData.status === "COMPLETED") {
                      return " Delivered to regional hub";
                    } else if (rawData.status === "IN_TRANSIT_LAST") {
                      return "Intransit Last";
                    } else if (rawData.status === "IN_TRANSIT_RETURN") {
                      return "Return Intransit";
                    } else {
                      return rawData.status;
                    }
                  }
                },
              ]}

              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => {
                      if (rawData.type === "DELIVERY" || rawData.type === "RETURN")
                        history.push(`/outbounds/view/${rawData.id}`)
                      else
                        history.push(`/collection/view/${rawData.id}`)

                    },
                  },
                },
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: true,
                  actionBtn: {
                    //icon: "fa fa-printer",
                    color: "primary",
                    tooltip: "Print",
                    btnText: "Print",
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.type === "DELIVERY";
                  },
                  modalProps: {

                    modalTitle: "Print Delivery",
                    modalType: modalTypes.large,
                    modalBodyComponent: LabelPrint,
                    modalFooterComponent: PrintLabelBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  },

                },
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: true,
                  actionBtn: {
                    //icon: "fa fa-printer",
                    color: "primary",
                    tooltip: "Print",
                    btnText: "Print",
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.type === "COLLECTION";
                  },
                  modalProps: {

                    modalTitle: "Print Collection",
                    modalType: modalTypes.large,
                    modalBodyComponent: LabelPrint,
                    modalFooterComponent: PrintLabelBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  },

                }
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    formState: state.FormData,
    authState: state.AuthState,
  };
};


const Outbounds = connect(mapStateToProps, { setFormErrorFn, })(OutboundsCom);


export {
  Outbounds,
};
