import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./user_includes/FilterUI";
import { RemoveMessage, RemoveBtn } from "./user_includes/DeleteUI";
import { getUserStaffList } from "../../../config/boda-boda/apiUrls.config";


class UserUI extends Component {
  render() {
    let { history, authState } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Staff</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Staff</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Staff"
              filterFromComponent={FilterUI}
              apiURL={`${getUserStaffList.url}?facilityId=${get(authState, 'authUser.facility.id', '')}&page=1&limit=20&category=WAREHOUSE`}
              dataTableStateKey={getUserStaffList.key}
              defaultFilterFormObject={{
                name: "",
                phone: "",
                status: "",
                roleId: "",
                identification: ""
              }}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: false,
                onClickBtnFn: () => history.push("/users/staff/create")
              }}
              isDropdownActionBtn={true}
              tableHeaderList={[
                { displayName: "User Id", key: "id" },
                { displayName: "Name", key: "name" },
                { displayName: "User Role", key: "user.role.name", default: "-" },
                { displayName: "Phone Number", key: "phone", default: "-" },
                { displayName: "Default Delivery Center", key: "facility.name", default: "-" },
                { displayName: "Status", key: "status" },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/users/staff/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/users/staff/edit/${rawData.id}`),
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    if (get(rawData, "rowData.user.isSuper", "") !== true) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    if (get(rawData, "rowData.user.isSuper", "") !== true) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ]}
            />
          </Row>


        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    authState: state.AuthState
  };
};

export default connect(mapStateToProps, null)(UserUI);
