import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { get } from "lodash";

import { FormWrapper, InputBoxWithState, SubmitButton } from "../../../components/boda-boda/Forms";
import {
  forgotPasswordOTPEmailAPI,
  forgotPasswordOTPSMSAPI,
  forgotPasswordOTPVerifyAPI
} from "../../../config/boda-boda/apiUrls.config";
import { SnackbarList } from "../../../components/boda-boda/Snackbar/Snackbar";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";
import Loader from "../../../components/Loader";
import { changeInputFn } from "../../../store/boda-boda/form/actions";
import logo from "../../../assets/images/boda-logo3.png";


class ForgetPasswordOTPVerifyPage extends Component {

  getMaskData = () => {
    let { formData } = this.props;

    if (get(formData, "forgotPasswordOTPFrom.otpType", "email") === "email") {
      const email = get(formData, "forgotPasswordFrom.maskData.email", "");

      return `********${email.substring(email.indexOf("@") - 2)}`;
    } else {
      const phone = get(formData, "forgotPasswordFrom.maskData.mobile", "");

      return `*******${phone.substring(phone.length - 4)}`;
    }
  }

  render() {
    let { formData, uiState, history } = this.props;

    return (
        <React.Fragment>
          {
            (get(formData, "forgotPasswordFrom", null) === null) ? (
                <Redirect to={"/forgot-password"} />
            ) : (
                <React.Fragment>
                  {
                    (uiState.setPageLoader === true) ? (
                        <Loader />
                    ) : null
                  }
                  <SnackbarList />

                  <div className="account-pages my-5 pt-5">
                    <div className="container">

                      <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>

                          <div className="position-relative">

                            <Card className="overflow-hidden">

                              <div className="bg-primary">
                                <div className="text-primary text-center p-4">
                                  <h5 className="text-white font-size-20">
                                    Forgot Password
                                  </h5>
                                  <p className="text-white-50">
                                    Enter the received OTP below to verify your profile.
                                  </p>
                                  <Link to="/" className="logo logo-admin">
                                    <img src={logo} height="50" alt="logo" />
                                  </Link>
                                </div>
                              </div>

                              <CardBody className="p-4">
                                <div className="p-3">
                                  <div className="form-horizontal mt-4">

                                    <div className={"text-center"}>
                                      <p>OTP has been sent to {this.getMaskData()}</p>
                                    </div>

                                    <FormWrapper
                                        setGroupName={`forgotPasswordOTPVerifyFrom`}
                                    >
                                      <InputBoxWithState
                                          labelText="OTP"
                                          formGroupName={`forgotPasswordOTPVerifyFrom`}
                                          inputName={"pin"}
                                          placeHolderText={"Enter OTP"}
                                      />

                                    </FormWrapper>

                                    <div className="row">
                                      <Col sm={6}></Col>

                                      <Col sm={6} className="d-flex justify-content-end">
                                        <div className="d-inline-flex">
                                          <SubmitButton
                                              btnText={"Re-send"}
                                              elementStyle="btn btn-primary w-md waves-effect waves-light"
                                              formGroupName={`forgotPasswordOTPFrom`}
                                              dataTableKey={forgotPasswordOTPEmailAPI.key}
                                              isValidate={false}
                                              elementWrapperStyle={"mt-3 mr-2"}
                                              flashMessages={{
                                                "success": {
                                                  status: true,
                                                  message: "OTP has successfully send",
                                                  type: "success"
                                                },
                                                "error": {
                                                  status: true,
                                                  message: "No user found with that credential",
                                                  type: "danger"
                                                }
                                              }}
                                              callApiObject={{
                                                isSetHeaders: true,
                                                multipart: false,
                                                method: "post",
                                                onUpload: false
                                              }}
                                              apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: apiResponseStoringType.apiRequestOnly,
                                                mergeToSuccessResponse: null,
                                                mergeToErrorResponse: null,
                                              }}
                                              onGetAPIEndPointFn={(formObject) => {
                                                if (formObject.otpType === "email") {
                                                  return {
                                                    url: `${forgotPasswordOTPEmailAPI.url}`,
                                                    key: forgotPasswordOTPEmailAPI.key
                                                  }
                                                } else {
                                                  return {
                                                    url: `${forgotPasswordOTPSMSAPI.url}`,
                                                    key: forgotPasswordOTPSMSAPI.key
                                                  }
                                                }
                                              }}
                                              onChangeRequestBodyFn={(formObject) => {
                                                return {
                                                  "credential": get(formData, "forgotPasswordFrom.credential", "")
                                                }
                                              }}
                                          />

                                          <SubmitButton
                                              btnText={"Verify"}
                                              elementStyle="btn btn-primary w-md waves-effect waves-light"
                                              formGroupName={`forgotPasswordOTPVerifyFrom`}
                                              dataTableKey={forgotPasswordOTPVerifyAPI.key}
                                              isValidate={true}
                                              elementWrapperStyle={"mt-3"}
                                              flashMessages={{
                                                "success": {
                                                  status: true,
                                                  message: "OTP has Verified",
                                                  type: "success"
                                                },
                                                "error": {
                                                  status: true,
                                                  message: "Pin Invalid",
                                                  type: "danger"
                                                }
                                              }}
                                              validationObject={{
                                                fields: {
                                                  pin: "OTP",
                                                },
                                                rules: {
                                                  pin: "required"
                                                },
                                                message: {}
                                              }}
                                              callApiObject={{
                                                isSetHeaders: true,
                                                multipart: false,
                                                method: "post",
                                                onUpload: false
                                              }}
                                              apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: apiResponseStoringType.apiRequestOnly,
                                                mergeToSuccessResponse: null,
                                                mergeToErrorResponse: null,
                                              }}
                                              onGetAPIEndPointFn={(formObject) => {
                                                return {
                                                  url: `${forgotPasswordOTPVerifyAPI.url}`,
                                                  key: forgotPasswordOTPVerifyAPI.key
                                                }
                                              }}
                                              onChangeRequestBodyFn={(formObject) => {
                                                return {
                                                  "credential": get(formData, "forgotPasswordFrom.credential", ""),
                                                  "pin": parseInt(formObject.pin)
                                                }
                                              }}
                                              onResponseCallBackFn={(error, result) => {
                                                if (!error) {
                                                  this.props.changeInputFn("forgotPasswordFrom", "pin", "", result.data.pin);
                                                  history.push(`/forgot-password-reset`);
                                                }
                                              }}
                                          />
                                        </div>

                                      </Col>
                                    </div>

                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                              <p>
                                Go back to{" "}
                                <Link
                                    to="/login"
                                    className="font-weight-medium text-primary"
                                >
                                  Login
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
            )
          }
        </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    formData: state.FormData,
    uiState: state.UIState
  };
};

export default withRouter(
    connect(mapStatetoProps, { changeInputFn })(ForgetPasswordOTPVerifyPage)
);
