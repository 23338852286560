import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { CrudComponent, actionBtnTypes } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { getOrdersListAPI } from "../../../config/boda-boda/apiUrls.config";
import { isEmptyValue, formatToDateHelper } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { connect } from "react-redux";
import { get } from "lodash";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { withRouter } from "react-router-dom";
import { ReadyToReturnUI } from "./includes/ReadyToReturnUI";
import { FormWrapper } from "../../../components/boda-boda/Forms";


class Consignments extends Component {
    render() {
        let { history } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Consignments</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Consignments</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <FormWrapper
                            setGroupName={getOrdersListAPI.key}
                            onDestroyUnsetFormObject={true}
                        >
                            <CrudComponent
                                title="Consignment Details"
                                apiURL={`${getOrdersListAPI.url}?page=1&limit=20&statuses=["DELIVERED","TERMINATED","PACKED","READY_FOR_RETURN","RETURNED_TO_MERCHANT"]&packageStatuses=["RECEIVED","SORTED","IN_TRANSIT_LINE","IN_TRANSIT_LAST","IN_TRANSIT_FIRST","DELIVERED","NOT_DELIVERED","ON_HOLD","DAMAGED","LOST","READY_FOR_RETURN","RETURNED_TO_MERCHANT","IN_TRANSIT_RETURN","NOT_RETURNED"]`}
                                dataTableStateKey={getOrdersListAPI.key}
                                readyForReturn={true}
                                headerBtn={
                                    <ReadyToReturnUI
                                        isViewScreen={false}
                                        historyFn={history}
                                        consignment={''}
                                    />}
                                filterFromComponent={FilterUI}
                                isFilter={true}
                                searchButtonOnTop={true}
                                defaultFilterFormObject={{
                                    consignmentNo: "",
                                    trackingNumber: "",
                                    customer: "",
                                    merchantId: "",
                                    phone: "",
                                    deliveryType: "",
                                    paymentMethod: "",
                                    dateTo: null,
                                    dateFrom: null,
                                    status: "",
                                    channelOrderNo: "",
                                }}
                                clearErrors={() => {
                                    const { setFormErrorFn } = this.props;
                                    setFormErrorFn("ordersListAPIKey_search", [])
                                }}
                                onRebuildFilterObjFn={(filterData) => {
                                    const data = filterData;
                                    let { setFormErrorFn } = this.props;
                                    const errors = [];

                                    if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                                        errors.push({
                                            "property": "dateTo",
                                            "error": "The To Date field should not be less than the From Date field "
                                        })
                                    }

                                    if (errors.length > 0) {
                                        setFormErrorFn("ordersListAPIKey_search", errors);
                                        return;
                                    } else {
                                        setFormErrorFn("ordersListAPIKey_search", []);
                                    }


                                    if (!isEmptyValue(filterData.dateTo)) {
                                        data["dateTo"] = formatToDateHelper(filterData.dateTo);
                                    }

                                    if (!isEmptyValue(filterData.dateFrom)) {
                                        data["dateFrom"] = filterData.dateFrom.toISOString();
                                    }

                                    return data;
                                }}

                                isSetCreateAction={false}
                                isDropdownActionBtn={true}
                                tableHeaderList={[
                                    { displayName: "Consignment No", key: "consignmentNo" },
                                    {
                                        displayName: "Tracking No", key: "trackingNumber", onPrintFn: (rawData) => (rawData.trackingNumber) ?
                                            <Link to={{ pathname: `${process.env.REACT_APP_BODA_CORPORATE_TRACK_BASE_URL}${rawData.trackingNumber}` }} target="_blank">{rawData.trackingNumber}</Link> : "-"
                                    },
                                    { displayName: "Merchant Name", key: "merchant.name" },
                                    { displayName: "Customer Name", key: "name" },
                                    { displayName: "Delivery Type", key: "deliveryType" },
                                    { displayName: "Payment Method", key: "paymentMethod" },
                                    { displayName: "Consignment Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt, "YY-mm-dd") },
                                    {
                                        displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                            if (rawData.status === "PACKED") {
                                                return "PENDING_DELIVERY";
                                            } else {
                                                return rawData.status
                                            }
                                        }
                                    }
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (rawData) => history.push(`/consignments/view/${rawData.id}`),
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.edit,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (rawData) => history.push(`/consignments/edit/${rawData.id}`),
                                        },
                                        checkBeforeActionRenderFn: (rawData) => {
                                            if (rawData.rowData.status === "PACKED") {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                    },
                                ]}
                            />
                        </FormWrapper>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        formState: state.FormData,
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
})(withRouter(Consignments));

