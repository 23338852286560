import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { modalTypes } from "../../../components/boda-boda/Modal/config/model.config";
import { getPackageListAPI } from "../../../config/boda-boda/apiUrls.config";
import { PrintLabelBtn } from "./includes/ShipingLabel";
import {formatToDateHelper, isEmptyValue} from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { FormWrapper } from "../../../components/boda-boda/Forms";
import { ShippingLabelUI } from "./includes/ShippingLabelUI";

class Packages extends Component {

    componentWillUnmount(){
        sessionStorage.removeItem('selectedPackages')
    }

    render() {
        let { history } = this.props;

        return (
            <React.Fragment>
                <FormWrapper
                setGroupName={getPackageListAPI.key}
                onDestroyUnsetFormObject={true}
                // setFormObject={{
                //   packages: [],
                // }}
                >
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Packages</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Packages</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title="Package List"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&statuses=["IN_TRANSIT","IN_TRANSIT_LINE","IN_TRANSIT_LAST","IN_TRANSIT_FIRST","DELIVERED","NOT_DELIVERED","RECEIVED","SORTED","ON_HOLD","IN_TRANSIT","READY_FOR_RETURN","IN_TRANSIT_RETURN","NOT_RETURNED","RETURNED_TO_MERCHANT"]`}
                            dataTableStateKey={getPackageListAPI.key}
                            isFilter={true}
                            isBulkPrint={true}
                            searchButtonOnTop={true}
                            defaultFilterFormObject={{
                                articleNo: "",
                                customerName: "",
                                merchantId: "",
                                name: "",
                                deliveryType: "",
                                paymentMethod: "",
                                categoryId: "",
                                pickupLocation: "",
                                locationCurrentId: "",
                                locationPickId: "",
                                status: "",
                                dateTo: null,
                                dateFrom: null,
                                merchantBranchId:"",
                                trackingNumber: "",
                                channelOrderNo: "",
                                deliveredDateTo: null,
                                deliveredDateFrom: null,
                            }}
                            clearErrors={() => {
                                const { setFormErrorFn } = this.props;
                                setFormErrorFn("packageListAPIKey_search", [])
                            }} 
                            filterFromComponent={FilterUI}
                            onRebuildFilterObjFn={(filterData) => {

                                const data = filterData;
                                let { setFormErrorFn  } = this.props;
                                const errors = [];

                                if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                                    errors.push({
                                        "property":"dateTo",
                                        "error":"The To Date field should not be less than the From Date field "
                                    })
                                    }

                                    if(errors.length > 0 ){
                                        setFormErrorFn("packageListAPIKey_search", errors);
                                        return;
                                    }else{
                                        setFormErrorFn("packageListAPIKey_search", []);
                                    }

                                if (!isEmptyValue(filterData.dateTo)) {
                                    data["dateTo"] = formatToDateHelper(filterData.dateTo);
                                }

                                if (!isEmptyValue(filterData.dateFrom)) {
                                    data["dateFrom"] = filterData.dateFrom.toISOString();
                                }

                                if (new Date(filterData.deliveredDateFrom) > new Date(filterData.deliveredDateTo)) {
                                    errors.push({
                                        "property":"deliveredDateTo",
                                        "error":"The Delivered To Date field should not be less than the Delivered From Date field "
                                    })
                                    }

                                    if(errors.length > 0 ){
                                        setFormErrorFn("packageListAPIKey_search", errors);
                                        return;
                                    }else{
                                        setFormErrorFn("packageListAPIKey_search", []);
                                    }

                                if (!isEmptyValue(filterData.deliveredDateTo)) {
                                    data["deliveredDateTo"] = formatToDateHelper(filterData.deliveredDateTo);
                                }

                                if (!isEmptyValue(filterData.deliveredDateFrom)) {
                                    data["deliveredDateFrom"] = filterData.deliveredDateFrom.toISOString();
                                }

                                return data;
                            }}
                            isSetCreateAction={false}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Article Id", key: "articleNo" },
                                { displayName: "Merchant Name", key: "merchant.name" },
                                { displayName: "Customer Name", key: "order.name", default: "-" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                                { displayName: "Created Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt, "YY-mm-dd") },
                                {
                                    displayName: "Delivery Status", key: "status", onPrintFn: (rawData) => {

                                        // if (rawData.status !== "DELIVERED" || rawData.status !== "PACKED") {
                                        //     return "Pending Delivery"
                                        //   } else {
                                        //     return "Packed"
                                        // }
                                        if (rawData.status === "PACKED") {
                                            if (rawData.onHold === true) {
                                                return "Packed" + "(On Hold)";
                                            } else
                                                return "Packed";

                                        } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                            if (rawData.onHold === true) {
                                                return "Package Picked up" + "(On Hold)";
                                            } else
                                                return "Package Picked up";

                                        } else if (rawData.status === "RECEIVED") {
                                            if (rawData.onHold === true) {
                                                return "Received at processing Center" + "(On Hold)";
                                            } else
                                                return "Received at processing Center";

                                        } else if (rawData.status === "SORTED") {
                                            if (rawData.onHold === true) {
                                                return "Ready for Transit" + "(On Hold)";
                                            } else
                                                return "Ready for Transit";

                                        } else if (rawData.status === "IN_TRANSIT_LINE") {
                                            if (rawData.onHold === true) {
                                                return "Intransit to regional hub" + "(On Hold)";
                                            } else
                                                return "Intransit to regional hub";

                                        } else if (rawData.status === "IN_TRANSIT_LAST") {
                                            if (rawData.onHold === true) {
                                                return "Out for Delivery" + "(On Hold)";
                                            } else
                                                return "Out for Delivery";

                                        } else if (rawData.status === "DELIVERED") {
                                            if (rawData.onHold === true) {
                                                return "Delivered" + "(On Hold)";
                                            } else
                                                return "Delivered";

                                        } else if (rawData.status === "NOT_DELIVERED") {
                                            if (rawData.onHold === true) {
                                                return "Failed Delivery" + "(On Hold)";
                                            } else
                                                return "Failed Delivery";
                                        } else if (rawData.status === "READY_FOR_RETURN") {
                                            if (rawData.onHold === true) {
                                                return "Ready for Return" + "(On Hold)";
                                            } else
                                                return "Ready for Return";
                                        } else if (rawData.status === "IN_TRANSIT_RETURN") {
                                            if (rawData.onHold === true) {
                                                return "Return Intransit" + "(On Hold)";
                                            } else
                                                return "Return Intransit";
                                        } else if (rawData.status === "RETURNED_TO_MERCHANT") {
                                            if (rawData.onHold === true) {
                                                return "Returned to Merchant" + "(On Hold)";
                                            } else
                                                return "Returned to Merchant";
                                        } else if (rawData.status === "NOT_RETURNED") {
                                            if (rawData.onHold === true) {
                                                return "Failed Return" + "(On Hold)";
                                            } else
                                                return "Failed Return";
                                        }

                                    }
                                },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        
                                        if(rawData.rowData.pendingPriceCalculation){
                                            return false;
                                        }else {
                                            if (rawData.rowData.status === "RECEIVED" || rawData.rowData.status === "SORTED" || rawData.rowData.status === "NOT_DELIVERED") {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "fa fa-barcode",
                                        color: "primary",
                                        tooltip: "Shipping Label",
                                        btnText: "Shipping_Label"
                                    },
                                    modalProps: {
                                        modalTitle: "Shipping Label",
                                        modalType: modalTypes.large,
                                        modalBodyComponent: (rawData) => {
                                            return <ShippingLabelUI packages={[rawData.row]}/>
                                          },
                                        modalFooterComponent: PrintLabelBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable"
                                        }
                                    }
                                }
                            ]}
                        />
                    </Row>

                </div>
                </FormWrapper>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
        setFormErrorFn,
})( withRouter(Packages));

