import {call, put} from "redux-saga/effects";
import validateMethod from "../../../helpers/boda-boda/validation";
import {get} from "lodash";
import {setFormErrorFn} from "../form/actions";
import {createAuthUserAPI, getUserStaffList} from "../../../config/boda-boda/apiUrls.config";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";

function* userPasswordResetFn(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            password: "New Password",
            confirm_password: "Confirm Password",
        },
        {
            password: "required",
            confirm_password: "required|same:password",
        },
        {
            "confirm_password.same": "Passwords do not match"
        }
    ));

    console.log(validationResponse._status)

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getUserStaffList.key}_pw_reset`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_pw_reset`, []));

        const apiRequestBody = {
            password: get(data, "payload.password", "")
        };

        const userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId", "")}`)
            .headers(true)
            .method("put")
            .body(apiRequestBody)
            .send()
        );

        if (userAPIRequest._statue === true) {

            yield put(setPageLoader(false));
            yield put(setFlashMessage({
                status: true,
                message: "Password has changed successfully",
                type: "success",
            }));
        }
    }

    yield put(setPageLoader(false));
}

export {
    userPasswordResetFn
}
