import React, { Component } from "react";
import { Col, Row } from "reactstrap";

import CreateDeliveryCenter from "./create-includes/CreateDeliveryCenter";
import AddContact from "./create-includes/AddContact";
import AddZones from "./create-includes/AddZones";


class CreateUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            deliveryCenterId: null
        }
    }

    render() {
        let { history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">
                                {
                                    (this.state.step === 0) ? "Create Delivery Center" : (
                                        (this.state.step === 1) ? "Add Contacts" : "Add Zones"
                                    )
                                }
                            </h4>
                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <div className={"col-md-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>
                                {
                                    (this.state.step === 0) ? (
                                        <CreateDeliveryCenter
                                            onNextFn={(id) => this.setState({ step: 1, deliveryCenterId: id })}
                                        />
                                    ) : (
                                        (this.state.step === 1) ? (
                                            <AddContact
                                                deliveryCenterId={this.state.deliveryCenterId}
                                                onNextFn={() => this.setState({
                                                    ...this.state,
                                                    step: 2
                                                })}
                                            />
                                        ) : (
                                            <AddZones
                                                deliveryCenterId={this.state.deliveryCenterId}
                                                history={history}
                                            />
                                        )
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export { CreateUI };
