import React, { Component } from "react";
import { groupBy, get } from "lodash";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { find } from "lodash";

import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { merchantBranchWeightRateDedicatedAPI } from "../../../../config/boda-boda/apiUrls.config";
import { connect } from "react-redux";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import {
    InputButton,
    SelectBoxWithState,
    FormWrapper,
} from "../../../../components/boda-boda/Forms";
import { roundValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {
    setFlashMessage,
    setPageLoader,
} from "../../../../store/boda-boda/ui/actions";
import {
    setFormErrorFn
} from "../../../../store/boda-boda/form/actions";
import { DownloadModalUI } from "./weight-includes/DownloadModal";
import { getDistrictListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { FilterUI } from "./weight-includes/FilterUI";
import UpdateWeightRateValue from "./weight-includes/UpdateWeightRateValue"

class Weight extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: false,
            districtId: "",
            showModal: false
        }
    }

    validateFn = (formState, setFormErrorFn) => {
        // let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (get(formState, "branch_actual_weight_rates.districtId", "") === "") {
            errors.push({
                "property": "districtId",
                "error": "The District field is required"
            })
            setFormErrorFn("branch_actual_weight_rates", errors);
            return false;
        } else {
            setFormErrorFn("branch_actual_weight_rates", []);
            return true;
        }
    }

    findRange = (data, range) => {
        let result = find(data, function (obj) {
            if (obj.weightMin == range) {
                return true;
            }
        });
        return result;
    };

    buildResponse = (data) => {
        let city = groupBy(data, "cityId");
        let temp2 = [];
        Object.keys(city).forEach((values, index) => {
            temp2.push({
                city: city[values][0].city?.name,
                zone: city[values][0].city?.zone?.name,
                range1: {
                    id: this.findRange(city[values], 0).id,
                    price: this.findRange(city[values], 0)
                        ? roundValue(this.findRange(city[values], 0).merchantPrice)
                        : "-",
                },
                range2: {
                    id: this.findRange(city[values], 0.5).id,
                    price: this.findRange(city[values], 0.5)
                        ? roundValue(this.findRange(city[values], 0.5).merchantPrice)
                        : "-",
                },
                range3: {
                    id: this.findRange(city[values], 1).id,
                    price: this.findRange(city[values], 1)
                        ? roundValue(this.findRange(city[values], 1).merchantPrice)
                        : "-",
                },

                range4: {
                    id: this.findRange(city[values], 2).id,
                    price: this.findRange(city[values], 2)
                        ? roundValue(this.findRange(city[values], 2).merchantPrice)
                        : "-",
                },

                range5: {
                    id: this.findRange(city[values], 3).id,
                    price: this.findRange(city[values], 3)
                        ? roundValue(this.findRange(city[values], 3).merchantPrice)
                        : "-",
                },

                range6: {
                    id: this.findRange(city[values], 4).id,
                    price: this.findRange(city[values], 4)
                        ? roundValue(this.findRange(city[values], 4).merchantPrice)
                        : "-",
                },

                range7: {
                    id: this.findRange(city[values], 5).id,
                    price: this.findRange(city[values], 5)
                        ? roundValue(this.findRange(city[values], 5).merchantPrice)
                        : "-",
                },

                range8: {
                    id: this.findRange(city[values], 6).id,
                    price: this.findRange(city[values], 6)
                        ? roundValue(this.findRange(city[values], 6).merchantPrice)
                        : "-",
                },

                range9: {
                    id: this.findRange(city[values], 7).id,
                    price: this.findRange(city[values], 7)
                        ? roundValue(this.findRange(city[values], 7).merchantPrice)
                        : "-",
                },

                range10: {
                    id: this.findRange(city[values], 8).id,
                    price: this.findRange(city[values], 8)
                        ? roundValue(this.findRange(city[values], 8).merchantPrice)
                        : "-",
                },

                range11: {
                    id: this.findRange(city[values], 9).id,
                    price: this.findRange(city[values], 9)
                        ? roundValue(this.findRange(city[values], 9).merchantPrice)
                        : "-",
                },
                range12: {
                    id: this.findRange(city[values], 10).id,
                    price: this.findRange(city[values], 10)
                        ? roundValue(this.findRange(city[values], 10).merchantPrice)
                        : "-",
                },
                range13: {
                    id: this.findRange(city[values], 11).id,
                    price: this.findRange(city[values], 11)
                        ? roundValue(this.findRange(city[values], 11).merchantPrice)
                        : "-",
                },
                range14: {
                    id: this.findRange(city[values], 12).id,
                    price: this.findRange(city[values], 12)
                        ? roundValue(this.findRange(city[values], 12).merchantPrice)
                        : "-",
                },
                range15: {
                    id: this.findRange(city[values], 13).id,
                    price: this.findRange(city[values], 13)
                        ? roundValue(this.findRange(city[values], 13).merchantPrice)
                        : "-",
                },
                range16: {
                    id: this.findRange(city[values], 14).id,
                    price: this.findRange(city[values], 14)
                        ? roundValue(this.findRange(city[values], 14).merchantPrice)
                        : "-",
                },
                range17: {
                    id: this.findRange(city[values], 15).id,
                    price: this.findRange(city[values], 15)
                        ? roundValue(this.findRange(city[values], 15).merchantPrice)
                        : "-",
                },
                range18: {
                    id: this.findRange(city[values], 16).id,
                    price: this.findRange(city[values], 16)
                        ? roundValue(this.findRange(city[values], 16).merchantPrice)
                        : "-",
                },
                range19: {
                    id: this.findRange(city[values], 17).id,
                    price: this.findRange(city[values], 17)
                        ? roundValue(this.findRange(city[values], 17).merchantPrice)
                        : "-",
                },
                range20: {
                    id: this.findRange(city[values], 18).id,
                    price: this.findRange(city[values], 18)
                        ? roundValue(this.findRange(city[values], 18).merchantPrice)
                        : "-",
                },
                range21: {
                    id: this.findRange(city[values], 19).id,
                    price: this.findRange(city[values], 19)
                        ? roundValue(this.findRange(city[values], 19).merchantPrice)
                        : "-",
                },
                range22: {
                    id: this.findRange(city[values], 20).id,
                    price: this.findRange(city[values], 20)
                        ? roundValue(this.findRange(city[values], 20).merchantPrice)
                        : "-",
                },
            });
        });

        return temp2;
    };

    onClickDownloadFn = () => {
        let { match, setPageLoader } = this.props;

        setPageLoader(true);

        callApi(
            `${merchantBranchWeightRateDedicatedAPI.url}/all?&merchantBranchId=${match.params.id}`
        )
            .headers(true)
            .method("get")
            .send()
            .then((result) => {
                this.generateCSVData(
                    get(result, "data.data", []),
                    `Weight Rates - ${match.params.name} - ${Date.now()} .csv`
                );
                setPageLoader(false);
            });
    };

    onClickView = (formState, setFormErrorFn) => {
        if (this.validateFn(formState, setFormErrorFn) === true) {
            this.setState({
                selected: true,
                districtId: get(this.props.formState, "branch_actual_weight_rates.districtId", ""),
            })
        }
    }

    render() {
        let { match, formState, setFormErrorFn, setPageLoader } = this.props;
        // const initApiUrl = get(formState, `${merchantBranchWeightRateAPI.key}_search.districtId`, '') === '' || get(formState, `${merchantBranchWeightRateAPI.key}_search.districtId`, '') == `25` ? `&districtId=25` : ``;
        return (
            <React.Fragment>

                <FormWrapper
                    setGroupName={"branch_actual_weight_rates"}
                    onDestroyUnsetFormObject={false}
                    setFormObject={{
                        districtId: "",
                    }}
                >
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Actual Weights</h4>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">View Weight</li>
                                    </ol>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className={"d-flex justify-content-end"}>
                                    <BackBtnComponent
                                        elementWrapperStyle={"mr-2"}
                                        isGoBack={true}
                                    />

                                    <DownloadModalUI
                                        merchantBranchid={match.params.id}
                                        merchantBranchName={match.params.name}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className={"card mb-1"}>
                                    <div className={"card-body"}>
                                        <dl className={"row mb-0"}>
                                            <dd className={"col-sm-2"}>Branch</dd>
                                            <dt className={"col-sm-4"}>{match.params.name}</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <CrudComponent
                                title={match.params.name + " Actual Weights Table"}
                                apiURL={`${merchantBranchWeightRateDedicatedAPI.url}/all?merchantBranchId=${match.params.id}&status=ACTIVE`}
                                filterFromComponent={FilterUI}
                                useInitialFilter={true}
                                defaultFilterFormObject={{ districtId: "25" }}
                                dataTableStateKey={merchantBranchWeightRateDedicatedAPI.key}
                                isSetCreateAction={false}
                                isSetAction={false}
                                isOpenFilterCollapse={true}
                                isPagingShow={false}
                                isFilter={false}
                                onRebuildResponseFn={(response) => {
                                    setPageLoader(false)
                                    return {
                                        data: {
                                            data: this.buildResponse(response),
                                        },
                                    };
                                }}
                                tableHeaderList={[
                                    { displayName: "City", key: "city" },

                                    { displayName: "Price(0-0.5)", key: "range1.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range1.id} price={rawData.range1.price} weightSlab='0-0.5' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(0.5-1)", key: "range2.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range2.id} price={rawData.range2.price} weightSlab='0.5-1' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(1-2)", key: "range3.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range3.id} price={rawData.range3.price} weightSlab='1-2' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(2-3)", key: "range4.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range4.id} price={rawData.range4.price} weightSlab='2-3' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(3-4)", key: "range5.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range5.id} price={rawData.range5.price} weightSlab='3-4' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(4-5)", key: "range6.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range6.id} price={rawData.range6.price} weightSlab='4-5' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(5-6)", key: "range7.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range7.id} price={rawData.range7.price} weightSlab='5-6' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(6-7)", key: "range8.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range8.id} price={rawData.range8.price} weightSlab='6-7' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(7-8)", key: "range9.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range9.id} price={rawData.range9.price} weightSlab='7-8' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(8-9)", key: "range10.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range10.id} price={rawData.range10.price} weightSlab='8-9' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(9-10)", key: "range11.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range11.id} price={rawData.range11.price} weightSlab='9-10' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(10-11)", key: "range12.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range12.id} price={rawData.range12.price} weightSlab='10-11' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(11-12)", key: "range13.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range13.id} price={rawData.range13.price} weightSlab='11-12' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(12-13)", key: "range14.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range14.id} price={rawData.range14.price} weightSlab='12-13' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(13-14)", key: "range15.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range15.id} price={rawData.range15.price} weightSlab='13-14' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(14-15)", key: "range16.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range16.id} price={rawData.range16.price} weightSlab='14-15' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(15-16)", key: "range17.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range17.id} price={rawData.range17.price} weightSlab='15-16' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(16-17)", key: "range18.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range18.id} price={rawData.range18.price} weightSlab='16-17' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(17-18)", key: "range19.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range19.id} price={rawData.range19.price} weightSlab='17-18' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(18-19)", key: "range20.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range20.id} price={rawData.range20.price} weightSlab='18-19' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(19-20)", key: "range21.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range21.id} price={rawData.range21.price} weightSlab='19-20' city={rawData.city} zone={rawData.zone} /> } },

                                    { displayName: "Price(20+)", key: "range22.price", onPrintFn: (rawData) => { return <UpdateWeightRateValue id={rawData.range22.id} price={rawData.range22.price} weightSlab='20+' city={rawData.city} zone={rawData.zone} /> } },
                                ]}
                            />
                        </Row>
                    </div>
                </FormWrapper>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formState: state.FormData,
    };
};

const WeightView = connect(mapStateToProps, {
    setPageLoader,
    setFlashMessage,
    setFormErrorFn
})(Weight);

export { WeightView };
