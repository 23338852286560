import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../components/boda-boda/CRUD/CrudComponent";
import {
    FormWrapper,
    DatePickerBoxWithState, InputBoxWithState, InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import {
    runSheetLineAPI, getLinehaulAPI, getUserDriverList
} from "../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../store/boda-boda/form/actions";
import RouteFilterUI from "../../runsheets/runsheets-line/includes/RouteFilterUI";
import DriverFilterUI from "../../runsheets/runsheets-line/includes/DriverFilterUI";
import { createRunSheetLine } from "../../../../store/boda-boda/transaction_request/actions";
import DriverViewModalUI from "../../driver/common-includes/DriverViewModalUI";
import {callApi} from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";


class CreateDriverRunsheetLine extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getDriver();
    }

    getDriver = async () => {
        let { match } = this.props;

        const response = await callApi(`${getUserDriverList.url}/${match.params.id}`)
            .headers(true)
            .method("get")
            .send();

        if (response._statue === true) {
            this.props.changeInputFn(`${runSheetLineAPI.key}_create`, "driver", "", [response.data.data]);
        }
    }

    addRoute = (row) => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_create`, "route", "", [row]);
    }

    deleteRoute = () => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_create`, "route", "", []);
    }

    addDriver = (row) => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_create`, "driver", "", [row]);
    }

    deleteDriver = () => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_create`, "driver", "", []);
    }

    render() {
        let { formState, history, createRunSheetLine, authState } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${runSheetLineAPI.key}_create`}
                    onDestroyUnsetFormObject={true}
                    setFormObject={{
                        route: [],
                        driver: [],
                        runSheetAt: null
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Create Runsheet</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <InputButtonWithState
                                        elementWrapperStyle={"mr-2"}
                                        btnText={"Save"}
                                        startIcon={"mdi mdi-content-save-all-outline"}
                                        formGroupName={`${runSheetLineAPI.key}_create`}
                                        onClickBtnFn={(dataObject) => {
                                            createRunSheetLine({
                                                ...dataObject.formObject,
                                                history: history,
                                                authUser: {
                                                    id: get(authState, "authUser.id"),
                                                    name: get(authState, "authUser.role.name")
                                                }
                                            })
                                        }}
                                    />

                                    <BackBtnComponent
                                        path={"/runsheets-line"}
                                        btnName={"Cancel"}
                                        icon={"far fa-times-circle"}
                                        btnColor={"danger"}
                                    />
                                </Fragment>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Runsheet Name"
                                                placeHolderText={"Runsheet Name"}
                                                inputName={"name"}
                                                formGroupName={`${runSheetLineAPI.key}_create`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                labelText={"Date"}
                                                isRequired={true}
                                                inputName={"runSheetAt"}
                                                formGroupName={`${runSheetLineAPI.key}_create`}
                                                minDate={new Date()}
                                                placeholderText={"Date"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Vehicle Number"
                                                placeHolderText={"Vehicle Number"}
                                                inputName={"vehiclePlate"}
                                                formGroupName={`${runSheetLineAPI.key}_create`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Special Notes"
                                                inputName={"notes"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={`${runSheetLineAPI.key}_create`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Route</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Routes"
                                            apiURL={`${getLinehaulAPI.url}?page=1&limit=20`}
                                            dataTableStateKey={`${getLinehaulAPI.key}_runsheetLine`}
                                            filterFromComponent={RouteFilterUI}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Route ID", key: "id" },
                                                { displayName: "Route Name", key: "name" },
                                                { displayName: "Type", key: "type", default: "LineHaul" },
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: false,
                                                    modalProps: {
                                                        modalTitle: "View Route",
                                                        modalType: "modal-lg",
                                                        // modalBodyComponent: ViewPackage,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (row) => this.addRoute(row)
                                                    },
                                                    checkBeforeActionRenderFn: (rawData) => {
                                                        return get(formState, `${runSheetLineAPI.key}_create.route`, []).length === 0;
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Route"
                                            dataTableStateKey={`${getLinehaulAPI.key}_runsheetLine_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isSetAction={true}
                                            isPagingShow={false}
                                            tableHeaderList={[
                                                { displayName: "Route ID", key: "id" },
                                                { displayName: "Route Name", key: "name" },
                                                { displayName: "Type", key: "type", default: "LineHaul" },
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            tableBodyList={get(formState, `${runSheetLineAPI.key}_create.route`, [])}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: false,
                                                    modalProps: {
                                                        modalTitle: "View Route",
                                                        modalType: "modal-lg",
                                                        // modalBodyComponent: ViewPackage,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.deleteRoute()
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Driver</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Drivers"
                                            apiURL={`${getUserDriverList.url}?page=1&limit=20&category=LINE`}
                                            dataTableStateKey={`${getUserDriverList.key}_runsheetLine`}
                                            filterFromComponent={DriverFilterUI}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Driver Id", key: "id" },
                                                { displayName: "Name", key: "name" },
                                                { displayName: "Type", key: "category" },
                                                { displayName: "Identification", key: "identification", default: "-" },
                                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "")},
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Driver",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: DriverViewModalUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.addDriver(data)
                                                    },
                                                    checkBeforeActionRenderFn: (rawData) => {
                                                        return get(formState, `${runSheetLineAPI.key}_create.driver`, []).length === 0;
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Driver"
                                            dataTableStateKey={`${getUserDriverList.key}_runsheetLine_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isSetAction={true}
                                            tableHeaderList={[
                                                { displayName: "Driver Id", key: "id" },
                                                { displayName: "Name", key: "name" },
                                                { displayName: "Type", key: "category" },
                                                { displayName: "Identification", key: "identification", default: "-" },
                                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "")},
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            tableBodyList={get(formState, `${runSheetLineAPI.key}_create.driver`, [])}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Driver",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: DriverViewModalUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.deleteDriver()
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    createRunSheetLine
})(CreateDriverRunsheetLine);
