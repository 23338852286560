import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {
    FormWrapper,
    InputBoxWithState, InputButton, InputButtonWithState, SelectBoxWithState
} from "../../../../../../components/boda-boda/Forms";
import {
    countryCodeAPI,
    createAuthUserAPI,
    getUserStaffList
} from "../../../../../../config/boda-boda/apiUrls.config";
import {useDispatch} from "react-redux";
import {
    createMerchantAdministrationTrKey,
    userPasswordResetTrKey
} from "../../../../../../store/boda-boda/transaction_request/actionTypes";
import {callApi} from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { get } from "lodash";
import {CustomLoader} from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import {phoneToStandardFormat} from "../../../../../../helpers/boda-boda/common-helpers/phone.helper";

class ReMerchantAdministration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: "fetching",
            staff: "fetching"
        }
    }

    componentDidMount() {
        this.checkStaff()
    }

    checkStaff = async () => {
        let { merchantId } = this.props;

        const staffResponse = await callApi(`${getUserStaffList.url}?merchantId=${merchantId}&limit=1&page=1`)
            .headers(true)
            .method("get")
            .send();

        if (staffResponse._statue === true) {
            if (get(staffResponse, "data.data", []).length === 0) {
                this.setState({
                    ...this.state,
                    staff: "not_found"
                })
            }else {
                const staffId = get(staffResponse, "data.data[0].id", "")
                const staff = await callApi(`${getUserStaffList.url}/${staffId}`)
                    .headers(true)
                    .method("get")
                    .send();
                this.setState({
                    ...this.state,
                    staff: get(staff, "data.data", {})
                })
            }
        }

        const userResponse = await callApi(`${createAuthUserAPI.url}/all?merchantId=${merchantId}`)
            .headers(true)
            .method("get")
            .send();

        if (userResponse._statue === true) {
            if (get(userResponse, "data.data", []).length === 0){
                this.setState({
                    ...this.state,
                    user: "not_found"
                })
            }else {
                this.setState({
                    ...this.state,
                    user: get(userResponse, "data.data", [])[0]
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.state.user === "fetching" || this.state.staff === "fetching") ? (
                        <center>
                            <CustomLoader/>
                        </center>
                    ) : (
                        <React.Fragment>
                            <Row>
                                <Col sm={12}>
                                    <FormWrapper
                                        setGroupName={`${getUserStaffList.key}_create`}
                                        onDestroyUnsetFormObject={true}
                                        setFormObject={{
                                            name: get(this.state, "staff.name", null),
                                            identification: get(this.state, "staff.identification", null),
                                            phone: get(this.state, "staff.phone", null),
                                            email: get(this.state, "staff.email", null),
                                            credential: get(this.state, "user.credential", null),
                                            role: get(this.state, "user.roleId", null),
                                            countryCode: {
                                                id: `${get(this.state, "staff.countryCode.id", "")}/${get(this.state, "staff.countryCode.code", "")}/${get(this.state, "staff.countryCode.prefix", "")}`
                                            },
                                            countryCodePrefix: get(this.state, `staff.countryCodePrefix`, ""),
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    isRequired={true}
                                                    labelText="Name"
                                                    placeHolderText={"Name"}
                                                    inputName={"name"}
                                                    formGroupName={`${getUserStaffList.key}_create`}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    isRequired={true}
                                                    labelText="Identification"
                                                    placeHolderText={"Identification"}
                                                    inputName={"identification"}
                                                    formGroupName={`${getUserStaffList.key}_create`}
                                                />
                                            </div>
                                            <div className="col-md-6">

                                                <div className={"row"}>
                                                    <div className={"col-md-3"}>
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Country Code"
                                                            inputStatePath={`${getUserStaffList.key}_create.countryCode.id`}
                                                            formGroupName={`${getUserStaffList.key}_create`}
                                                            apiUrl={`${countryCodeAPI.url}/all`}
                                                            apiStateKey={countryCodeAPI.key}
                                                            onRebuildResponseFn={(response) => {
                                                                const data = [];

                                                                response.forEach((value) => {
                                                                    data.push({
                                                                        id: `${value.id}/${value.code}/${value.prefix}`,
                                                                        value: value.code
                                                                    });
                                                                });

                                                                return {
                                                                    "data": {
                                                                        "data": data
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className={"col-md-9"}>
                                                        <InputBoxWithState
                                                            labelText="Phone"
                                                            inputName={"phone"}
                                                            placeHolderText={"+XXXXXXXXXXX"}
                                                            formGroupName={`${getUserStaffList.key}_create`}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    isRequired={true}
                                                    maxLength={50}
                                                    labelText="Email"
                                                    placeHolderText={"Email"}
                                                    inputName={"email"}
                                                    formGroupName={`${getUserStaffList.key}_create`}
                                                />
                                            </div>

                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Username"
                                                            maxLength={50}
                                                            placeHolderText={"Username"}
                                                            inputName={"credential"}
                                                            formGroupName={`${getUserStaffList.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                    {
                                                        (get(this.state, "user", "") === "not_found")  ? (
                                                            <React.Fragment>
                                                                <div className="col-md-6">
                                                                    <InputBoxWithState
                                                                        isRequired={true}
                                                                        labelText="Enter the Password"
                                                                        inputType={"password"}
                                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                                        inputName={"password"}
                                                                        placeHolderText={"Password"}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <InputBoxWithState
                                                                        isRequired={true}
                                                                        labelText="Re Enter the Password"
                                                                        inputType={"password"}
                                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                                        inputName={"confirm_password"}
                                                                        placeHolderText={"Re-Password"}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>

                                        </div>


                                    </FormWrapper>
                                </Col>
                            </Row>

                            {/*{*/}
                            {/*    (get(this.state, "user", "") !== "not_found") ? (*/}
                            {/*        <React.Fragment>*/}
                            {/*            <hr/>*/}

                            {/*            <Row>*/}
                            {/*                <Col sm={6}>*/}
                            {/*                    <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>*/}
                            {/*                        <h4 className="font-size-18">Reset Password</h4>*/}
                            {/*                    </div>*/}
                            {/*                </Col>*/}
                            {/*                <Col sm={6}>*/}
                            {/*                    <div className={"d-flex justify-content-end"}>*/}
                            {/*                        <PasswordResetBtn*/}
                            {/*                            userId={get(this.state, "user.id", "")}*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                </Col>*/}

                            {/*                <Col sm={12}>*/}
                            {/*                    <FormWrapper*/}
                            {/*                        setGroupName={`${getUserStaffList.key}_pw_reset`}*/}
                            {/*                        onDestroyUnsetFormObject={true}*/}
                            {/*                    >*/}

                            {/*                        <div className="row">*/}
                            {/*                            <div className="col-md-12">*/}
                            {/*                                <div className={"d-flex justify-content-end"}>*/}

                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                        <div className={"row"}>*/}

                            {/*                            <div className="col-md-6">*/}
                            {/*                                <InputBoxWithState*/}
                            {/*                                    labelText="New Password"*/}
                            {/*                                    inputName={"password"}*/}
                            {/*                                    placeHolderText={"New Password"}*/}
                            {/*                                    inputType={"password"}*/}
                            {/*                                    formGroupName={`${getUserStaffList.key}_pw_reset`}*/}
                            {/*                                    isRequired={true}*/}
                            {/*                                    maxLength={50}*/}
                            {/*                                />*/}
                            {/*                            </div>*/}

                            {/*                            <div className="col-md-6">*/}
                            {/*                                <InputBoxWithState*/}
                            {/*                                    labelText="Re enter New Password"*/}
                            {/*                                    inputName={"confirm_password"}*/}
                            {/*                                    placeHolderText={"Re enter New Password"}*/}
                            {/*                                    inputType={"password"}*/}
                            {/*                                    formGroupName={`${getUserStaffList.key}_pw_reset`}*/}
                            {/*                                    isRequired={true}*/}
                            {/*                                    maxLength={50}*/}
                            {/*                                />*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}



                            {/*                    </FormWrapper>*/}
                            {/*                </Col>*/}

                            {/*            </Row>*/}
                            {/*        </React.Fragment>*/}
                            {/*    ) : null*/}
                            {/*}*/}

                            <div className={"row"}>
                                <Col sm={12}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={"d-flex justify-content-end"}>
                                                <InputButton
                                                    elementWrapperStyle={"mr-2"}
                                                    btnText={"Back"}
                                                    onClickBtnFn={this.props.onBackFn}
                                                />
                                                <CreateBtn
                                                    onNextFn={this.props.onNextFn}
                                                    merchantId={this.props.merchantId}
                                                    user={get(this.state, "user", "")}
                                                    staff={get(this.state, "staff", "")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>

                        </React.Fragment>


                    )
                }
            </React.Fragment>
        )
    }
}

const PasswordResetBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Reset Password"}
            formGroupName={`${getUserStaffList.key}_pw_reset`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: userPasswordResetTrKey,
                    payload: {
                        ...dataObject.formObject,
                        userId: props.userId
                    }
                })
            }}
        />
    )
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Next"}
            formGroupName={`${getUserStaffList.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantAdministrationTrKey,
                    payload: {
                        ...dataObject.formObject,
                        phone: phoneToStandardFormat(dataObject.formObject.phone),
                        onNextFn: props.onNextFn,
                        merchant: Number(props.merchantId),
                        user: props.user,
                        staff: props.staff
                    }
                })
            }}
        />
    )


}

export default ReMerchantAdministration;