import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getOrdersListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { currency } from "../../../../config/boda-boda/db_config/db_status.config";
import ExpansionPanel from "../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";


class ConsignmentViewModalUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderPanel: true,
            receiverPanel: false,
            locationPanel: false,
            notePanel: false
        }
    }

    render() {
        let { row, history, formState, location } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getOrdersListAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getOrdersListAPI.url}/${row.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            email: response.email,
                            idVerificationRequired: response.idVerificationRequired,
                            idVerificationType: response.idVerificationType,
                            idVerificationReference: response.idVerificationReference,
                            phone: response.phone,
                            status: response.status,
                            deliveryType: response.deliveryType,
                            amount: response.amount,
                            paymentMethod: response.paymentMethod,
                            shippingLocation: response.shippingLocation,
                            consignmentNo: response.consignmentNo,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            signatureRequired: response.signatureRequired,
                            merchant: response.merchant.name,
                            "_onLoad": false,
                        }
                    }}
                >
                    <ExpansionPanel
                        title={"Order Details"}
                        isOpen={this.state.orderPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                orderPanel: !prevState.orderPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Order ID</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.id`, "")}</dt>

                                    <dd className={"col-sm-5"}>Consignment No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.consignmentNo`, "")}</dt>

                                    <dd className={"col-sm-5"}>Signature Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.signatureRequired`, false) === false ? "No" : "Yes"}</dt>

                                    <dd className={"col-sm-5"}>Delivery Type</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.deliveryType`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Merchant</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.merchant`, "")}</dt>

                                    <dd className={"col-sm-5"}>Status</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.status`, "")}</dt>

                                    <dd className={"col-sm-5"}>Payment Method</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "")}</dt>

                                    <dd className={"col-sm-5"}>Amount</dd>
                                    <dt className={"col-sm-7"}>
                                        {
                                            get(formState, `${getOrdersListAPI.key}_view.amount`, null) === null ? "" : get(formState, `${getOrdersListAPI.key}_view.amount`, "") + ` ${currency}`
                                        }
                                    </dt>

                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Customer Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.phone`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>ID Verification Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === false ? "No" : "Yes"}</dt>
                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === true) ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, "")}</dt>

                                                <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, "")}</dt>
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.postalCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lat`, "")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lon`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.name`, "")}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Notes"}
                        isOpen={this.state.notePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                notePanel: !prevState.notePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-3"}>Public Note - </dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPublic`, "None")}</dt>

                                    {/* <dd className={"col-sm-3"}>Private Note - </dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPrivate`, "None")}</dt> */}
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <div className="row">
                        <CrudComponent
                            title="Packages"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${row.id}`}
                            dataTableStateKey={getPackageListAPI.key}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={false}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(ConsignmentViewModalUI);
