import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";


class CollectionViewModelUI extends Component {
    render() {
        let { formState, row} = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${row.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            code: response.code,
                            status: response.status,
                            facility: response.facility,
                            locationPick: response.locationPick,
                            locationDrop: response.locationDrop,
                            locationCurrent: response.locationCurrent,
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Collection Details</h4>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-md-4"}>Collection Code</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                <dd className={"col-md-4"}>Status</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.status`, "")}</dt>

                                <dd className={"col-md-4"}>Destination Delivery Center</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                <dd className={"col-md-4"}>Special Notes</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.notes`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-md-4"}>Current Location</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationCurrent.name`, "")}</dt>

                                <dd className={"col-md-4"}>Pick Location</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                <dd className={"col-md-4"}>Drop Location</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>
                            </dl>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Package Details</h4>
                        </div>

                        <CrudComponent
                            title="Packages"
                            apiURL={`${getManifestListAPI.url}/${row.id}/published-packages?page=1&limit=20`}
                            dataTableStateKey={getManifestListAPI.key}
                            isFilter={false}
                            isSetAction={false}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Article ID", key: "articleNo" },
                                { displayName: "Customer's Name", key: "order.name" },
                                { displayName: "Customer's Phone", key: "order.phone" },
                                { displayName: "Merchant Name", key: "merchant.name" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(CollectionViewModelUI)
