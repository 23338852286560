import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    FormWrapper, DatePickerBoxWithState, InputBoxWithState,
    InputButtonWithState, SelectBoxWithState
} from "../../../../../components/boda-boda/Forms";
import {
    runSheetLastAPI, getUserDriverList, getLastMileAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../../store/boda-boda/form/actions";
import RouteFilterUI from "./RouteFilterUI";
import DriverFilterUI from "./DriverFilterUI";
import { editRunSheetLast } from "../../../../../store/boda-boda/transaction_request/actions";
import { runSheetLineStatuses } from "../../../../../config/boda-boda/db_config/db_status.config";
import PickupAndDeliveries from "./edit-includes/PickupAndDeliveries";
import DriverViewModalUI from "../../../driver/common-includes/DriverViewModalUI";
import RouteViewModelUI from "./RouteViewModelUI";
import { isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


class EditRunsheetLast extends Component {

    addRoute = (row) => {
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "routeLast", "", row);
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "selectedRoute", "", [row]);
    }

    deleteRoute = () => {
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "routeLast", "", null);
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "selectedRoute", "", []);
    }

    addDriver = (row) => {
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "selectedDriver", "", [row]);
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "driver", "", row);
    }

    deleteDriver = () => {
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "selectedDriver", "", []);
        this.props.changeInputFn(`${runSheetLastAPI.key}_edit`, "driver", "", null);
    }

    render() {
        let { formState, history, editRunSheetLast, match, authState } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${runSheetLastAPI.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${runSheetLastAPI.url}/${match.params.id}/compressed`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code: response.code,
                            driver: response.driver,
                            name: response.name,
                            routeLast: response.routeLast,
                            runSheetAt: new Date(response.runSheetAt),
                            status: response.status,
                            vehiclePlate: response.vehiclePlate,
                            notes: response.notes === null ? [] : response.notes,
                            selectedDriver: [response.driver],
                            selectedRoute: [response.routeLast],
                            creator: get(response, "creator.staffs[0].name", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Edit Runsheet</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <InputButtonWithState
                                        elementWrapperStyle={"mr-2"}
                                        btnText={"Save"}
                                        startIcon={"mdi mdi-content-save-all-outline"}
                                        formGroupName={`${runSheetLastAPI.key}_edit`}
                                        onClickBtnFn={(dataObject) => {
                                            editRunSheetLast({
                                                ...dataObject.formObject,
                                                history: history,
                                                authUser: {
                                                    id: get(authState, "authUser.id"),
                                                    name: get(authState, "authUser.role.name")
                                                }
                                            })
                                        }}
                                    />

                                    <BackBtnComponent
                                        path={"/runsheets-last"}
                                        btnName={"Cancel"}
                                        icon={"far fa-times-circle"}
                                        btnColor={"danger"}
                                    />
                                </Fragment>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Runsheet Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.code`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Assigned By</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.creator`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Runsheet Name"
                                                placeHolderText={"Runsheet Name"}
                                                inputName={"name"}
                                                formGroupName={`${runSheetLastAPI.key}_edit`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                labelText={"Date"}
                                                isRequired={true}
                                                inputName={"runSheetAt"}
                                                formGroupName={`${runSheetLastAPI.key}_edit`}
                                                minDate={new Date()}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Vehicle Number"
                                                placeHolderText={"Vehicle Number"}
                                                inputName={"vehiclePlate"}
                                                formGroupName={`${runSheetLastAPI.key}_edit`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Notes</label>
                                            {
                                                get(formState, `${runSheetLastAPI.key}_edit.notes`, []).length > 0 ? (
                                                    <ul style={{ paddingLeft: "17px" }}>
                                                        {
                                                            get(formState, `${runSheetLastAPI.key}_edit.notes`, []).map((element, index) => {
                                                                return (
                                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                ) : (
                                                    <div>None</div>
                                                )
                                            }
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="New Note"
                                                inputName={"note"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={`${runSheetLastAPI.key}_edit`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"} style={{ marginBottom: "22px" }}>
                        <PickupAndDeliveries
                            runSheetId={match.params.id}
                            driver={get(formState, `${runSheetLastAPI.key}_edit.driver`, null)}
                            status={get(formState, `${runSheetLastAPI.key}_edit.status`, "")}
                        />
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Route</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Routes"
                                            apiURL={`${getLastMileAPI.url}?page=1&limit=20&status=ACTIVE&facilityId=${get(authState, "authUser.facility.id", "")}`}
                                            dataTableStateKey={`${getLastMileAPI.key}_runsheetLast`}
                                            filterFromComponent={RouteFilterUI}
                                            defaultFilterFormObject={{
                                                name: "",
                                                waypoint: ""
                                            }}
                                            onRebuildFilterObjFn={(filterData) => {
                                                const data = filterData;

                                                if (!isEmptyValue(filterData.waypoint)) {
                                                    data["cityIds"] = `[${filterData.waypoint}]`;
                                                }

                                                delete data["waypoint"];
                                                return data;
                                            }}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Route ID", key: "id" },
                                                { displayName: "Route Name", key: "name" },
                                                { displayName: "Type", key: "type", default: "LastMile" },
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Route",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: RouteViewModelUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (row) => this.addRoute(row)
                                                    },
                                                    checkBeforeActionRenderFn: (rawData) => {
                                                        return get(formState, `${runSheetLastAPI.key}_edit.selectedRoute`, []).length === 0;
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Route"
                                            dataTableStateKey={`${getLastMileAPI.key}_runsheetLast_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isSetAction={true}
                                            isPagingShow={false}
                                            tableHeaderList={[
                                                { displayName: "Route ID", key: "id" },
                                                { displayName: "Route Name", key: "name" },
                                                { displayName: "Type", key: "type", default: "LastMile" },
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            tableBodyList={get(formState, `${runSheetLastAPI.key}_edit.selectedRoute`, [])}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Route",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: RouteViewModelUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.deleteRoute()
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Driver</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Drivers"
                                            apiURL={`${getUserDriverList.url}?page=1&limit=20&category=LAST&status=ACTIVE`}
                                            dataTableStateKey={`${getUserDriverList.key}_runsheetLast`}
                                            filterFromComponent={DriverFilterUI}
                                            defaultFilterFormObject={{
                                                name: "",
                                                identification: "",
                                                phone: ""
                                            }}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Driver Id", key: "id" },
                                                { displayName: "Name", key: "name" },
                                                { displayName: "Type", key: "category" },
                                                { displayName: "Identification", key: "identification", default: "-" },
                                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "")},
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Driver",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: DriverViewModalUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.addDriver(data)
                                                    },
                                                    checkBeforeActionRenderFn: (rawData) => {
                                                        return get(formState, `${runSheetLastAPI.key}_edit.selectedDriver`, []).length === 0;
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Driver"
                                            dataTableStateKey={`${getUserDriverList.key}_runsheetLast_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isPagingShow={false}
                                            isSetAction={true}
                                            tableHeaderList={[
                                                { displayName: "Driver Id", key: "id" },
                                                { displayName: "Name", key: "name" },
                                                { displayName: "Type", key: "category" },
                                                { displayName: "Identification", key: "identification", default: "-" },
                                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "")},
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            tableBodyList={get(formState, `${runSheetLastAPI.key}_edit.selectedDriver`, [])}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Driver",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: DriverViewModalUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.deleteDriver()
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    editRunSheetLast
})(EditRunsheetLast);
