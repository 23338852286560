import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";

import {
    InputBoxWithState,
    FormWrapper,
    InputButtonWithState, SelectBoxWithState
} from "../../../../../components/boda-boda/Forms";
import {
    countryCodeAPI,
    getUserStaffList
} from "../../../../../config/boda-boda/apiUrls.config";
import {
    createMerchantAdminUserTrKey
} from "../../../../../store/boda-boda/transaction_request/actionTypes";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";


class CreateUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { history, match } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create Merchant Admin User</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                                merchantId={match.params.id}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={""}
                                path={"/users/staff"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserStaffList.key}_create`}
                            onDestroyUnsetFormObject={true}
                        >

                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Name"
                                                placeHolderText={"Name"}
                                                inputName={"name"}
                                                formGroupName={`${getUserStaffList.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Identification"
                                                placeHolderText={"Identification"}
                                                inputName={"identification"}
                                                formGroupName={`${getUserStaffList.key}_create`}
                                            />
                                        </div>
                                        <div className="col-md-6">

                                            <div className={"row"}>
                                                <div className={"col-md-3"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Country Code"
                                                        inputStatePath={`${getUserStaffList.key}_create.countryCode.id`}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        apiUrl={`${countryCodeAPI.url}/all`}
                                                        apiStateKey={countryCodeAPI.key}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.code}/${value.prefix}`,
                                                                    value: value.code
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className={"col-md-9"}>
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"+XXXXXXXXXXX"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Email"
                                                placeHolderText={"Email"}
                                                inputName={"email"}
                                                maxLength={50}
                                                formGroupName={`${getUserStaffList.key}_create`}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Username"
                                                        placeHolderText={"Username"}
                                                        maxLength={50}
                                                        inputName={"credential"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                    />
                                                </div>
                                                <div className="col-md-6"></div>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Enter the Password"
                                                        inputType={"password"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        inputName={"password"}
                                                        placeHolderText={"Password"}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Re Enter the Password"
                                                        inputType={"password"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        inputName={"confirm_password"}
                                                        placeHolderText={"Re-Password"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        );
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getUserStaffList.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantAdminUserTrKey,
                    payload: {
                        ...dataObject.formObject,
                        merchant: props.merchantId,
                        historyFn: props.historyFn
                    }
                })
            }}
        />
    )
}

export { CreateUI };
