import React, { Component } from "react";
import { connect } from "react-redux";

import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { getZoneAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { FilterUI } from "../update-includes/ZoneFilterUI";
import { InputButton } from "../../../../../components/boda-boda/Forms";
import { addZones } from "../../../../../store/boda-boda/transaction_request/actions";


class AddZones extends Component {

    addZone = (row) => {
        const body = {
            "facility": {
                "id": Number(this.props.deliveryCenterId)
            }
        }

        const flashMsjObj = {
            status: true,
            message: "Zone has successfully added",
            type: "success"
        };

        this.props.addZones(row.id, body, flashMsjObj);
    }

    deleteZone = (row) => {
        const body = {
            "facility": null
        }

        const flashMsjObj = {
            status: true,
            message: "Zone has successfully removed",
            type: "success"
        };

        this.props.addZones(row.id, body, flashMsjObj);
    }

    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <CrudComponent
                        title="Zone List"
                        apiURL={`${getZoneAPI.url}?page=1&limit=20&facilityId=null`}
                        dataTableStateKey={`${getZoneAPI.key}_null`}
                        isFilter={true}
                        filterFromComponent={FilterUI}
                        isSetAction={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Zone ID", key: "id" },
                            { displayName: "Zone Name", key: "name" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: false,
                                actionBtn: {
                                    //onClickBtnFn: (rawData) => history.push(`/delivery-centers/update/view-zone`),
                                }
                            },
                            {
                                actionType: actionBtnTypes.create,
                                isSetModel: false,
                                actionBtn: {
                                    btnText: "Add",
                                    tooltip: "Add",
                                    onClickBtnFn: (data) => this.addZone(data)
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row mt-3"}>
                    <CrudComponent
                        title="Selected Zones"
                        apiURL={`${getZoneAPI.url}?page=1&limit=20&facilityId=${this.props.deliveryCenterId}`}
                        dataTableStateKey={`${getZoneAPI.key}_selected`}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Zone ID", key: "id" },
                            { displayName: "Zone Name", key: "name" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: false,
                                actionBtn: {
                                    //onClickBtnFn: (rawData) => history.push(`/delivery-centers/update/view-zone`),
                                }
                            },
                            {
                                actionType: actionBtnTypes.delete,
                                isSetModel: false,
                                actionBtn: {
                                    onClickBtnFn: (data) => this.deleteZone(data)
                                }
                            },
                        ]}
                    />
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-md-12"}>
                        <div className={"d-flex justify-content-end"}>
                            <InputButton
                                btnText={"Finish"}
                                onClickBtnFn={() => this.props.history.push("/delivery-centers")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { addZones })(AddZones);
