import React, { Component, Fragment } from "react";
import { Col, Label, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { fileGetAPIUrl } from "../../../../config/boda-boda/core.config";
import {DeactivateUser} from "./DeactivateUser";
import { ActivateUser } from "./ActivateUser";


class ViewUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserStaffList.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: get(response, "id", ""),
                            name: get(response, "name", ""),
                            email: get(response, "email", ""),
                            phone: get(response, "phone", ""),
                            identification: get(response, "identification", ""),
                            facility: get(response, "facility.name", ""),
                            category: get(response, "category", ""),
                            status: get(response, "status", ""),
                            deletedAt: get(response, "deletedAt", null),
                            role: get(response, "user.role.name", null),
                            image: get(response, "image", null),
                            userId: get(response, "user.id", ''),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Staff</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/users/staff"}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/users/staff/edit/${match.params.id}`)}
                                />

                                {get(formState, `${getUserStaffList.key}_view.status`) !== "ACTIVE" ? (
                                    <ActivateUser
                                        historyFn={history}
                                        staff={get(formState, `${getUserStaffList.key}_view.id`, "")}
                                        user={get(formState, `${getUserStaffList.key}_view.userId`, "")}
                                />
                                ) : (
                                    <DeactivateUser
                                        historyFn={history}
                                        staff={get(formState, `${getUserStaffList.key}_view.id`, "-")}
                                        user={get(formState, `${getUserStaffList.key}_view.userId`, "")}
                                    />
                                    )

                                }

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <FormWrapper
                                setGroupName={`${getUserStaffList.key}_create`}
                                onDestroyUnsetFormObject={true}
                            >

                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    <dd className={"col-sm-4"}>User ID</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>

                                                    <dd className={"col-sm-4"}>User Role</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.role`, "-")}</dt>
                                                </dl>
                                            </div>

                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    <dd className={"col-sm-4"}>Default Delivery Center</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.facility`, "-")}</dt>

                                                    <dd className={"col-sm-4"}>Status</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.status`, "")}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title mb-4">Personal Details</h4>

                                                <div className={"row"}>
                                                    <div className={"col-md-6"}>
                                                        <dl className={"row"}>
                                                            <dd className={"col-sm-4"}>Name</dd>
                                                            <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.name`, "")}</dt>

                                                            <dd className={"col-sm-4"}>Identification</dd>
                                                            <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.identification`, "")}</dt>
                                                        </dl>
                                                    </div>

                                                    {
                                                        (get(formState, `${getUserStaffList.key}_view.image`, null) !== null) ? (
                                                            <div className={"col-md-6"}>
                                                                <div>
                                                                    <Label>Profile Picture</Label>
                                                                </div>

                                                                <div>
                                                                    <img
                                                                        className={"img-thumbnail user-profile-picture"}
                                                                        src={`${fileGetAPIUrl}${get(formState, `${getUserStaffList.key}_view.image`, "")}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title mb-4">Contact Details</h4>

                                                <div className={"row"}>
                                                    <div className={"col-md-6"}>
                                                        <dl className={"row"}>
                                                            <dd className={"col-sm-4"}>E-mail</dd>
                                                            <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.email`, "")}</dt>
                                                            {get(formState, `${getUserStaffList.key}_view.deletedAt`) !== null ? (
                                                                <Fragment>
                                                                    <dd className={"col-sm-4"}>Deleted At</dd>
                                                                    <dt className={"col-sm-8"}>{dateObjectToString(
                                                                        get(formState, `${getUserStaffList.key}_view.deletedAt`, ""),
                                                                        "YY-mm-dd HH:MM:II"
                                                                    )
                                                                    }</dt>
                                                                </Fragment>

                                                            ) : null

                                                            }
                                                        </dl>
                                                    </div>
                                                    <div className={"col-md-6"}>
                                                        <dl className={"row"}>
                                                            <dd className={"col-sm-4"}>Phone</dd>
                                                            <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.phone`, "")}</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </FormWrapper>
                        </Col>
                    </Row>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
    ViewUIWithState
}
