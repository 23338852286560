import _, { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { resetDataTable, setBulkValues, setFlashMessage, setPageLoader } from "../../../../../store/actions";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getOrdersListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { Button } from "reactstrap";


const MarkAsSettledBtn = (props) => {

    const formState = useSelector((state) => state.FormData);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { id } = useParams();

    const getSelectedOrders = () => {
        const tempArray = JSON.parse(sessionStorage.getItem('selectedOrders'));
        const tempBulkPrint = tempArray ? tempArray : [];
        let selecteditemList = [];
        const chkList = get(formState, `${getOrdersListAPI.key}`, null);

        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'tableSelect')) {
                return item.replace('tableSelect', '');
            }
        });

        selecteditemList = selecteditemList.filter(function (element) {
            return element !== undefined;
        });

        let selectedObjects = [];
        if (tempBulkPrint !== null) {
            for (let i = 0; i < tempBulkPrint?.length; i += 1) {
                for (let j = 0; j < selecteditemList?.length; j += 1) {
                    if (tempBulkPrint[i]?.id == selecteditemList[j]) {
                        selectedObjects.push(tempBulkPrint[i]);
                    }
                }
            }
        }

        return selectedObjects;
    };

    const clearSelectedOrders = () => {
        let chkList = get(formState, `${getOrdersListAPI.key}`, null);
        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        let selecteditemList = [];
        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'tableSelect')) {
                let pair = {};
                let value = item;
                pair[value] = false;
                return pair;
            }
        });
        if (get(formState, `${getOrdersListAPI.key}.selectall`, null) !== null) {
            let selectAll = {};
            selectAll['selectall'] = false;
            selecteditemList.push(selectAll);
        }

        for (let i = 0; i < selecteditemList?.length; i += 1) {
            dispatch(setBulkValues(`${getOrdersListAPI.key}`, selecteditemList[i]))
        }
    }

    const settleOrders = async () => {
        try {
            dispatch(setPageLoader(true));

            const selectedOrders = getSelectedOrders();
            if (selectedOrders?.length === 0) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: 'No orders selected',
                        type: 'danger',
                    })
                );
                setShow(false);
                dispatch(setPageLoader(false));
                return;
            }

            var orderIds = selectedOrders.map(selectedOrder => selectedOrder.id);

            const body = {
                "orders": orderIds,
                "codSettlementStatus": "DRIVER_SETTLED"
            }
            let isError = false;
            const orderAPIResponse = await callApi(
                `${getOrdersListAPI.url}/codSettlementStatus`
            )
                .headers(true)
                .method('put')
                .body(body)
                .send();
            if (orderAPIResponse._statue) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: `Orders settled`,
                        type: 'success',
                    })
                );
            } else {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: "Something went wrong!!",
                        type: "danger"
                    })
                );
                dispatch(setPageLoader(false));
                return;
            }

            dispatch(resetDataTable(`${getOrdersListAPI.key}`));

            setShow(false);
            dispatch(setPageLoader(false));
            clearSelectedOrders();
            sessionStorage.removeItem('selectedOrders')

        } catch (error) {
            setShow(false);
            dispatch(setPageLoader(false));

            dispatch(
                setFlashMessage({
                    status: true,
                    message: 'An error has occurred',
                    type: 'danger',
                })
            );
        }
    }

    return (
        <React.Fragment>
            <Button
                color='primary'
                className='btn btn-primary waves-effect waves-light'
                onClick={() => setShow(true)}
            >
                Mark as Settled
            </Button>
            <ModalUI
                isOpen={show}
                modalTitle={'Please Confirm'}
                modalBodyComponent={`Are you sure you want to mark ${getSelectedOrders()?.length
                    } order(s) as Driver settled?`}
                showHideToggleFn={() => setShow(false)}
                closeBtnName='No'
                modalFooterComponent={
                    <Button
                        color='primary'
                        onClick={() => {
                            settleOrders()
                        }
                        }
                    >
                        Yes
                    </Button>
                }
            />
        </React.Fragment>
    )
}

export default MarkAsSettledBtn;