import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {
    FormWrapper,
    InputBoxWithState, InputButton
} from "../../../../../../components/boda-boda/Forms";
import SelectBoxWithState from "../../../../../../components/boda-boda/Forms/SelectBoxWithState";
import {
    countryCodeAPI,
    getAllFacilityAPI,
    getCityListAPI, getFacilityAPI,
    merchantBranchAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import InputButtonWithState from "../../../../../../components/boda-boda/Forms/InputButtonWithState";
import {createMerchantBranchTrKey} from "../../../../../../store/boda-boda/transaction_request/actionTypes";
import {useDispatch} from "react-redux";
import {callApi} from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {get} from "lodash";
import {CustomLoader} from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import {phoneToStandardFormat} from "../../../../../../helpers/boda-boda/common-helpers/phone.helper";
import {currency} from "../../../../../../config/boda-boda/db_config/db_status.config";

class MerchantDefaultBranch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            branch: "fetching"
        }
    }

    componentDidMount() {
        this.checkBranch();
    }

    facilityChange = async (eventData) => {
        let {setPageLoader, changeInputFn} = this.props;
        setPageLoader(true);

        const response = await callApi(`${getFacilityAPI.url}/${eventData.value}`)
            .headers(true)
            .method('get')
            .send()

        if (response._statue === true){
            const pickupCost = get(response, 'data.data.pickupCost', undefined);
            changeInputFn(`${merchantBranchAPI.key}_create`, 'pickupPrice', '', pickupCost);
            setPageLoader(false);
        }
    }

    checkBranch = async () => {
        let { merchantId } = this.props;

        const branchResponse = await callApi(`${merchantBranchAPI.url}?merchantId=${merchantId}&limit=1&page=1`)
            .headers(true)
            .method("get")
            .send();

        if (branchResponse._statue === true) {
            if (get(branchResponse, "data.data", []).length === 0) {
                this.setState({
                    ...this.state,
                    branch: "not_found"
                })
            }else{
                const branchId = get(branchResponse, "data.data[0].id", "");
                const branch = await callApi(`${merchantBranchAPI.url}/${branchId}`)
                    .headers(true)
                    .method("get")
                    .send();
                this.setState({
                    ...this.state,
                    branch: get(branch, "data.data", {})
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.state.branch === "fetching") ? (
                        <center>
                            <CustomLoader/>
                        </center>
                    ) : (
                        <Row>
                            <Col sm={12}>
                                <FormWrapper
                                    setGroupName={`${merchantBranchAPI.key}_create`}
                                    onDestroyUnsetFormObject={true}
                                    setFormObject={{
                                        name: get(this.state, "branch.name", null),
                                        pickupPrice: get(this.state, "branch.pickupPrice", null),
                                        facility: get(this.state, "branch.facilityId", null),
                                        address: get(this.state, "branch.location.address", null),
                                        cityId: get(this.state, "branch.location.cityId", null),
                                        contactId: get(this.state, "branch.contacts[0].id", null),
                                        fullName: get(this.state, "branch.contacts[0].fullName", null),
                                        phone: get(this.state, "branch.contacts[0].phone", null),
                                        email: get(this.state, "branch.contacts[0].email", null),
                                        countryCode: {
                                            id: `${get(this.state, "branch.countryCode.id", "")}/${get(this.state, "branch.countryCode.code", "")}/${get(this.state, "branch.countryCode.prefix", "")}`
                                        },
                                        countryCodePrefix: get(this.state, `branch.countryCodePrefix`, ""),
                                    }}
                                >

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Branch Name"
                                                placeHolderText={"Branch Name"}
                                                inputName={"name"}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText={"Delivery Center"}
                                                inputName={"facility"}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                                placeHolderText={"Delivery Center"}
                                                apiUrl={getAllFacilityAPI.url}
                                                apiStateKey={getAllFacilityAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                onChangeFn={(eventData) => this.facilityChange(eventData)}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText={`Pick Up Cost(${currency})`}
                                                placeHolderText={`Pick Up Cost(${currency})`}
                                                inputName={"pickupPrice"}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                            />
                                        </div>

                                        <div className={"col-md-6"}>
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Address"
                                                placeHolderText={"Enter the Shipping Address"}
                                                inputName={"address"}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                                maxLength={250}
                                            />
                                        </div>

                                        <div className={"col-md-6"}>
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="City"
                                                inputName={"cityId"}
                                                placeHolderText={"Select a City"}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                                apiUrl={`${getCityListAPI.url}/all`}
                                                apiStateKey={getCityListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                            />
                                        </div>

                                    </div>

                                    <hr/>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Contact Person"
                                                placeHolderText={"Contact Person"}
                                                inputName={"fullName"}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                            />
                                        </div>

                                        <div className="col-md-6">

                                            <div className={"row"}>
                                                <div className={"col-md-3"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Country Code"
                                                        inputStatePath={`${merchantBranchAPI.key}_create.countryCode.id`}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        apiUrl={`${countryCodeAPI.url}/all`}
                                                        apiStateKey={countryCodeAPI.key}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.code}/${value.prefix}`,
                                                                    value: value.code
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className={"col-md-9"}>
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"+XXXXXXXXXXX"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <InputBoxWithState
                                                isRequired={true}
                                                labelText="Email"
                                                maxLength={50}
                                                placeHolderText={"Email"}
                                                inputName={'email'}
                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={"d-flex justify-content-end"}>
                                                <InputButton
                                                    elementWrapperStyle={"mr-2"}
                                                    btnText={"Back"}
                                                    onClickBtnFn={this.props.onBackFn}
                                                />
                                                <CreateBtn
                                                    merchantId={this.props.merchantId}
                                                    historyFn={this.props.history}
                                                    branch={get(this.state, "branch", {})}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </FormWrapper>
                            </Col>
                        </Row>
                    )
                }
            </React.Fragment>
        )
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Finish"}
            formGroupName={`${merchantBranchAPI.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantBranchTrKey,
                    payload: {
                        ...dataObject.formObject,
                        phone: phoneToStandardFormat(dataObject.formObject.phone),
                        merchant: Number(props.merchantId),
                        historyFn: props.historyFn,
                        branch: props.branch
                    }
                })
            }}
        />
    )
}

export default MerchantDefaultBranch;