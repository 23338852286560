import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getLinehaulAPI } from "../../../../config/boda-boda/apiUrls.config";
import { CreateUI } from "./includes/linehaul-includes/common-includes";
import CreateLinehaulBtn from "./includes/linehaul-includes/CreateLinehaulBtn";
import FilterUI from "./includes/linehaul-includes/FilterUI";


class Linehaul extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Linehaul Routes</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Linehaul Routes</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <CrudComponent
                            title="Linehaul Routes"
                            apiURL={`${getLinehaulAPI.url}?page=1&limit=20&facilityId=${get(authState, "authUser.facility.id", "")}`}
                            dataTableStateKey={getLinehaulAPI.key}
                            isFilter={true}
                            filterFromComponent={FilterUI}
                            defaultFilterFormObject={{
                                name: "",
                                status: "",
                                locationId: ""
                            }}
                            isDropdownActionBtn={true}
                            createActionProps={{
                                actionType: actionBtnTypes.create,
                                isSetModel: true,
                                modalProps: {
                                    modalTitle: "Create Linehaul Route",
                                    modalType: "",
                                    modalBodyComponent: CreateUI,
                                    modalFooterComponent: CreateLinehaulBtn,
                                    isSetClose: true,
                                    isSetFooterClose: true,
                                    parentToModelProps: {
                                        formKey: "_create",
                                        uiType: "create",
                                    },
                                }
                            }}
                            tableHeaderList={[
                                { displayName: "Route ID", key: "id" },
                                { displayName: "Route Name", key: "name" },
                                { displayName: "Status", key: "status" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/routes/linehaul/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/routes/linehaul/edit/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, null)(Linehaul);
