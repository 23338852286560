import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap"
import { DatePickerBoxWithState, FormWrapper, InputButton, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getFacilityAPI, hubSettlementAPI } from "../../../../config/boda-boda/apiUrls.config";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/actions";
import { get } from "lodash";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../components/boda-boda/CommonElements/BaseElements";

export const HubSettlementBalance = (props) => {
    const formState = useSelector(state => state.FormData);
    const dispatch = useDispatch();
    const history = useHistory();
    const [settledToDropdownData, setSettledToDropdownData] = useState("loading");

    useEffect(() => {
        async function loadHeadOfficeData() {
            const response = await callApi(`${getFacilityAPI.url}/all?isHeadOffice=true`)
                .headers(true)
                .method("get")
                .send()

            if (response._statue === true) {
                const finalData = []
                for (let i = 0; i < response?.data?.data?.length; i++) {
                    finalData.push({
                        id: response?.data?.data[0].id,
                        value: response?.data?.data[0].name,
                    })
                }
                setSettledToDropdownData(finalData)
            } else {
                setSettledToDropdownData([])
            }
        }

        loadHeadOfficeData()
    }, [])

    const validateFn = () => {
        const errors = [];

        if (isEmptyValue(get(formState, `${hubSettlementAPI.key}_balance.settlementDate`, ""))) {
            errors.push({
                "property": "settlementDate",
                "error": "The Settlement Date field is required"
            })
        }
        // if (isEmptyValue(get(formState, `${hubSettlementAPI.key}_balance.settleFromFacilityId`, ""))) {
        //     errors.push({
        //         "property": "settleFromFacilityId",
        //         "error": "The Settlement From field is required"
        //     })
        // }
        if (errors.length !== 0) {
            dispatch(setFormErrorFn(`${hubSettlementAPI.key}_balance`, errors));
            return false;
        } else {
            return true;
        }
    }

    const onGenerateFn = () => {
        if (validateFn() === true) {
            history.push("/hub-settlement-balance/view");
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Hub Settlement Balance</h4>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Hub Settlement Balance</li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <div className={"col-md-12"}>

                        <div className={"card"} style={{ marginBottom: "120px" }}>
                            <div className={"card-body"}>

                                {settledToDropdownData === "loading" ?
                                    <center>
                                        <CustomLoader />
                                    </center> :

                                    <FormWrapper
                                        setGroupName={`${hubSettlementAPI.key}_balance`}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            settlementDate: null,
                                            settleToFacilityId: settledToDropdownData[0]?.id,
                                            settleFromFacilityId: '',
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Settlement Date"
                                                    inputName={"settlementDate"}
                                                    formGroupName={`${hubSettlementAPI.key}_balance`}
                                                    placeholderText={"Settlement Date"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Settled From"
                                                    formGroupName={`${hubSettlementAPI.key}_balance`}
                                                    inputName={"settleFromFacilityId"}
                                                    apiUrl={`${getFacilityAPI.url}/all`}
                                                    apiStateKey={`${getFacilityAPI.key}SettleFrom`}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [
                                                            {
                                                                id: "",
                                                                value: "All",
                                                            },
                                                        ];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Settled To"
                                                    formGroupName={`${hubSettlementAPI.key}_balance`}
                                                    inputName={"settleToFacilityId"}
                                                    isDisabled={true}
                                                    dataList={settledToDropdownData}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}