/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-04 12:47:04 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-04 12:48:45
 */


const userStatusList = [
    {
        id: "ACTIVE",
        value: "Active",
    },
    {
        id: "INACTIVE",
        value: "Inactive",
    },
];

const orderStatusObject = {
    "PENDING": "PENDING",
    "PICKED": "PICKED",
    "PACKED": "PACKED",
    "UNAVAILABLE": "UNAVAILABLE",
    "CANCELLED": "CANCELLED",
};

const pickUpRequestStatus = [
    {
        id: "DRAFT",
        value: "Drafts"
    },
    {
        id: "PUBLISH",
        value: "Pending"
    },
    {
        id: "ACCEPTED",
        value: "Accepted"
    },
    {
        id: "REJECTED",
        value: "Rejected"
    }
];

const orderStatusList = [
    {
        id: "PENDING",
        value: "PENDING",
    },
    {
        id: "PICKED",
        value: "PICKED",
    },
    {
        id: "PACKED",
        value: "PENDING_DELIVERY",
    },
    {
        id: "CANCELLED",
        value: "CANCELLED",
    }
];

const consignmentStatusList = [
    {
        id: "DELIVERED",
        value: "DELIVERED",
    },
    {
        id: "PACKED",
        value: "PENDING_DELIVERY",
    },
    {
        id: "TERMINATED",
        value: "TERMINATED",
    },
    {
        id: "READY_FOR_RETURN",
        value: "READY_FOR_RETURN",
    },
    {
        id: "RETURNED_TO_MERCHANT",
        value: "RETURNED_TO_MERCHANT",
    },
    // {
    //     id: "NOT_DELIVERED",
    //     value: "NOT_DELIVERED",
    // }
];

const pickRequestStatusList = [
    {
        id: "ACCEPTED",
        value: "ACCEPTED"
    },
    {
        id: "COMPLETED",
        value: "PICKUP COMPLETED"
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "PICKED UP"
    },
    {
        id: "PUBLISH",
        value: "PENDING"
    },
    {
        id: "REJECTED",
        value: "REJECTED"
    },

];

const idVerificationTypes = [
    {
        id: "NIC",
        value: "NIC",
    },
    {
        id: "PASSPORT",
        value: "PASSPORT",
    },
    {
        id: "DRIVING",
        value: "DRIVING",
    }
];

const deliveryTypes = [
    {
        id: "EXPRESS",
        value: "EXPRESS",
    },
    {
        id: "STANDARD",
        value: "STANDARD",
    }
];

const staffCategories = [
    {
        id: "STORE",
        value: "STORE",
    },
    {
        id: "DRIVER",
        value: "DRIVER",
    }
];

const paymentMethods = [
    {
        id: "CASH_ON_DELIVERY",
        value: "CASH ON DELIVERY",
    },
    {
        id: "CARD_ON_DELIVERY",
        value: "CARD ON DELIVERY",
    },
    {
        id: "PREPAID",
        value: "PREPAID",
    }
];

const currency = "LKR";

const routeTypes = [
    {
        id: "LASTMILE",
        value: "LASTMILE"
    },
    {
        id: "LINEHAUL",
        value: "LINEHAUL"
    }
];

const routeWaypoints = [
    {
        id: "FACILITIES",
        value: "FACILITIES"
    },
    {
        id: "CITIES",
        value: "CITIES"
    },
];

const merchantStatusList = [
    {
        id: "ACTIVE",
        value: "ACTIVE",
    },
    {
        id: "INACTIVE",
        value: "INACTIVE",
    },
    {
        id: "INCOMPLETE",
        value: "INCOMPLETE"
    }
];

const merchantEditStatusList = [
    {
        id: "ACTIVE",
        value: "ACTIVE",
    },
    {
        id: "INACTIVE",
        value: "INACTIVE",
    }
];

const zoneStatus = [
    {
        id: "ACTIVE",
        value: "Active",
    },
    {
        id: "INACTIVE",
        value: "Inactive",
    },
];

const collectionStatus = [
    {
        id: "DRAFT",
        value: "Open",
    },
    {
        id: "PUBLISH",
        value: "Closed",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub",
    },
    {
        id: "COMPLETED",
        value: "Delivered to regional hub",
    },
];

const packageDeliveryType = [
    {
        id: "EXPRESS",
        value: "EXPRESS",
    },
    {
        id: "STANDARD",
        value: "STANDARD",
    },
];

const packageDeliveryStatus = [
    // {
    //     id: "PACKED",
    //     value: "Packed"
    // },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package Picked up"
    },
    {
        id: "RECEIVED",
        value: "Received at processing Center"
    },
    {
        id: "SORTED",
        value: "Ready for Transit"
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub"
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for Delivery"
    },
    {
        id: "DELIVERED",
        value: "Delivered"
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed Delivery",
    }

];

const userRoleStatus = [
    {
        id: "ACTIVE",
        value: "Active",
    },
    {
        id: "INACTIVE",
        value: "Inactive",
    },
];

const userRoleName = [
    {
        id: "ADMIN",
        value: "Admin User",
    },
    {
        id: "MANAGER",
        value: "Order Manager",
    },
    {
        id: "WADMIN",
        value: "Warehouse Admin",
    },
    {
        id: "WOPERTOR",
        value: "Warehouse Operator",
    },
];

const runSheetTypes = [
    {
        id: "Pickup",
        value: "Pickup",
    },
    {
        id: "Deliveries",
        value: "Deliveries",
    },
    {
        id: "Hybrid",
        value: "Hybrid",
    },
];

const routeStatusList = [
    {
        id: "ACTIVE",
        value: "ACTIVE",
    },
    {
        id: "INACTIVE",
        value: "INACTIVE",
    },
];

const deliveryMethods = [
    {
        id: "ENABLED",
        value: "ENABLED",
    },
    {
        id: "DISABLED",
        value: "DISABLED"
    }
];

const chargingMethods = [
    {
        id: "VOLUMETRIC",
        value: "Volumetric Weight Based",
    },
    {
        id: "WEIGHT",
        value: "Actual Weight Based"
    }
];

const contractTypes = [
    {
        id: "TYPE_ONE",
        value: "Type 1",
    },
    {
        id: "TYPE_TWO",
        value: "Type 2"
    }
];

const notifications = [
    {
        id: "ENABLED",
        value: "ENABLED",
    },
    {
        id: "DISABLED",
        value: "DISABLED"
    }
]

const channelIdForTacking = [
    {
        id: true,
        value: "ENABLED",
    },
    {
        id: false,
        value: "DISABLED"
    }
]

const deliveryCenterTypes = [
    {
        id: "WAR",
        value: "WAR"
    },
    {
        id: "HUB",
        value: "HUB"
    }
];

const driverTypes = [
    {
        id: "LAST",
        name: "LAST"
    },
    {
        id: "LINE",
        name: "LINE"
    }
];
const transactionTypes = [
    {
        id: "BALANCE_UPDATE",
        name: "DRIVER CASH FLOAT"
    },
    {
        id: "DELIVERY",
        name: "CASH COLLECTED"
    },
    {
        id: "SETTLEMENT",
        name: "SETTLEMENT"
    }
];
const DeliveryStatusList = [
    // {
    //     id: "PACKED",
    //     value: "Packed"
    // },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package Picked up"
    },
    {
        id: "RECEIVED",
        value: "Received at processing Center"
    },
    {
        id: "SORTED",
        value: "Ready for Transit"
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub"
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for Delivery"
    },
    {
        id: "DELIVERED",
        value: "Delivered"
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed Delivery",
    },
    {
        id: "READY_FOR_RETURN",
        value: "Ready for Return",
    },
    {
        id: "IN_TRANSIT_RETURN",
        value: "Return Intransit",
    },
    {
        id: "RETURNED_TO_MERCHANT",
        value: "Returned to Merchant",
    },
    {
        id: "NOT_RETURNED",
        value: "Failed Return",
    },
    // {
    //     id: "ON_HOLD",
    //     value: "On Hold"
    // },
    // {
    //     id: "DAMAGED",
    //     value: "Damaged"
    // },
    // {
    //     id: "LOST",
    //     value: "Lost"
    // }
];

const contractExpiry = [
    {
        "id": "CONTINUE",
        "value": "Refer to base table and continue current discount"
    },
    {
        "id": "DISCONTINUE",
        "value": "Refer to base table and discontinue current discount"
    }
];

const chargingType = [
    {
        "id": "PERCENTAGE",
        "value": "PERCENTAGE"
    },
    {
        "id": "FEE",
        "value": "FEE"
    }
];

const merchantTypes = [
    {
        id: "MERCHANT",
        value: "Merchant",
    },
    {
        id: "COLLECTION",
        value: "Collection",
    },
    {
        id: "DELIVERY",
        value: "Deivery",
    }
];

const runSheetLineStatuses = [
    {
        id: "ACTIVE",
        value: "ACTIVE",
    },
    {
        id: "INACTIVE",
        value: "INACTIVE",
    },
    {
        id: "STARTED",
        value: "STARTED",
    },
    {
        id: "COMPLETED",
        value: "COMPLETED",
    },
    {
        id: "CANCELLED",
        value: "CANCELLED",
    },
    {
        id: "EXPIRED",
        value: "EXPIRED",
    }

];

const roleTypes = [
    {
        id: "MERCHANT",
        value: "MERCHANT",
    },
    {
        id: "WAREHOUSE",
        value: "WAREHOUSE",
    },
    {
        id: "DRIVER_LAST",
        value: "DRIVER LAST",
    },
    {
        id: "DRIVER_LINE",
        value: "DRIVER LINE",
    },
]

const merchantProfileTypes = [
    {
        id: "LVL1",
        value: "LVL1",
    },
    {
        id: "LVL2",
        value: "LVL2",
    },
    {
        id: "LVL3",
        value: "LVL3",
    }
];

const packageStatuses = [
    {
        id: "PACKED",
        value: "PACKED",
    },
    {
        id: "IN_TRANSIT",
        value: "IN TRANSIT",
    },
    {
        id: "RECEIVED",
        value: "RECEIVED",
    },
    {
        id: "SORTED",
        value: "SORTED",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "IN TRANSIT LINE",
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "IN TRANSIT LAST",
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "IN TRANSIT FIRST",
    },
    {
        id: "DELIVERED",
        value: "DELIVERED",
    },
    {
        id: "NOT_DELIVERED",
        value: "FAILED DELIVERY",
    },
    {
        id: "ON_HOLD",
        value: "ON HOLD",
    },
    {
        id: "DAMAGED",
        value: "DAMAGED",
    },
    {
        id: "LOST",
        value: "LOST",
    },
];

const reportPackageStatuses = [
    {
        id: "PACKED",
        value: "Packed"
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package Picked up"
    },
    {
        id: "RECEIVED",
        value: "Received at processing Center"
    },
    {
        id: "SORTED",
        value: "Ready for Transit"
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub"
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for Delivery"
    },
    {
        id: "DELIVERED",
        value: "Delivered"
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed Delivery",
    },
];

const packageStatusReportStatuses = [
    {
        id: "PACKED",
        value: "Packed"
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package Picked up"
    },
    {
        id: "RECEIVED",
        value: "Received at processing Center"
    },
    {
        id: "SORTED",
        value: "Ready for Transit"
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub"
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for Delivery"
    },
    {
        id: "DELIVERED",
        value: "Delivered"
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed Delivery",
    },
    {
        id: "READY_FOR_RETURN",
        value: "Ready for Return",
    },
    {
        id: "IN_TRANSIT_RETURN",
        value: "Return Intransit",
    },
    {
        id: "RETURNED_TO_MERCHANT",
        value: "Returned to Merchant",
    },
    {
        id: "NOT_RETURNED",
        value: "Failed Return",
    },
];

const reconciliationReportStatuses = [
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package Picked up",
    },
    {
        id: "RECEIVED",
        value: "Received at processing Center",
    },
    {
        id: "SORTED",
        value: "Ready for Transit",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub",
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for Delivery",
    },
    {
        id: "DELIVERED",
        value: "Delivered",
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed Delivery",
    }
];

const printerTypes = [
    {
        id: "NORMAL",
        value: "NORMAL",
    },
    {
        id: "LABEL",
        value: "LABEL",
    }
];

const contactTypes = [
    {
        id: "OPERATION",
        value: "OPERATION",
    },
    {
        id: "FINANCE",
        value: "FINANCE",
    }
];

const codStatuses = [
    {
        id: "PENDING",
        value: "Pending",
    },
    {
        id: "DRIVER_SETTLED",
        value: "Driver Settled",
    },
    {
        id: "HUB_SETTLED",
        value: "Hub Settled",
    },
    {
        id: "HEAD_OFFICE_RECEIVED",
        value: "Head Office Recieved",
    },
    {
        id: "HEAD_OFFICE_SETTLED",
        value: "Head Office Settled",
    },
]

const mapPackageStatusesNames = {
    "PACKED": "Packed",
    "IN_TRANSIT": "Intransit",
    "RECEIVED": "Received at processing center",
    "SORTED": "Ready for Transit",
    "IN_TRANSIT_LINE": "Intransit to regional hub",
    "IN_TRANSIT_LAST": "Out for delivery",
    "IN_TRANSIT_FIRST": "Package picked up",
    "DELIVERED": "Delivered",
    "NOT_DELIVERED": "Failed Delivery",
    "ON_HOLD": "On hold",
    "DAMAGED": "Damaged",
    "LOST": "Lost",
    "IN_TRANSIT_RETURN": "Return Intransit",
    "NOT_RETURNED": "Failed Return",
    "RETURNED_TO_MERCHANT": "Returned to Merchant",
    "READY_FOR_RETURN": "Ready for Return",
}

const weightMetric = "kg";
const lengthMetric = "cm";

export {
    userStatusList,
    orderStatusObject,
    pickUpRequestStatus,
    orderStatusList,
    idVerificationTypes,
    deliveryTypes,
    staffCategories,
    paymentMethods,
    currency,
    routeTypes,
    routeWaypoints,
    merchantStatusList,
    userRoleStatus,
    userRoleName,
    runSheetTypes,
    routeStatusList,
    deliveryCenterTypes,
    driverTypes,
    transactionTypes,
    deliveryMethods,
    contractExpiry,
    DeliveryStatusList,
    chargingType,
    merchantTypes,
    runSheetLineStatuses,
    merchantProfileTypes,
    roleTypes,
    zoneStatus,
    consignmentStatusList,
    pickRequestStatusList,
    notifications,
    channelIdForTacking,
    collectionStatus,
    packageStatuses,
    merchantEditStatusList,
    packageDeliveryType,
    packageDeliveryStatus,
    reportPackageStatuses,
    reconciliationReportStatuses,
    contractTypes,
    printerTypes,
    chargingMethods,
    weightMetric,
    lengthMetric,
    contactTypes,
    packageStatusReportStatuses,
    codStatuses,
    mapPackageStatusesNames,
}
