import React, {Component} from "react";
import {Col, Row} from "reactstrap";

import { RemoveMessage, RemoveBtn } from "./includes/DeleteUI";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { merchantBranchAPI } from "../../../../config/boda-boda/apiUrls.config";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";

class Branch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, location} = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Branch</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={`/merchant/view/${match.params.id}`}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <CrudComponent
                            title={"Merchant Branch"}
                            apiURL={`${merchantBranchAPI.url}?merchantId=${match.params.id}&page=1&limit=20`}
                            dataTableStateKey={merchantBranchAPI.key}
                            isFilter={false}
                            setCreateAction={false}
                            createActionProps={{
                                actionType:actionBtnTypes.create,
                                isSetModel:false,
                                onClickBtnFn: () => history.push(`/merchant/${match.params.id}/merchant-branch/create`)
                            }}
                            tableHeaderList={[
                                { displayName: "Branch Name", key: "name"},
                                { displayName: "Delivery Center", key: "facility.name"},
                                { displayName: "Pick Up Cost", key: "pickupPrice"},
                                { displayName: "Phone No", key: "contacts[0].phone"},
                                { displayName: "Status", key: "status"},
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant/${match.params.id}/merchant-branch/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant/${match.params.id}/merchant-branch/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.deletedBy === null;
                                    }
                                },

                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: RemoveMessage,
                                        modalFooterComponent: RemoveBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.deletedBy === null;
                                    }
                                }

                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export  {Branch};