/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-08-25 13:24:46 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-08-25 13:32:55
 */
/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-04-02 19:23:25 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-04-10 10:26:13
 */

const actionBtnTypes={
    "create":"CREATE",
    "edit":"EDIT",
    "view":"VIEW",
    "delete":"DELETE",
    "other":"OTHER"
}

const emptyFun = (...para) => undefined;

const commonActionBtnPops={
    [actionBtnTypes.create]:{
        color:"primary",
        elementStyle:" btn-primary ",
        icon:" mdi mdi-plus-circle-outline ",
        btnText:"Create",
        tooltip:"",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },[actionBtnTypes.edit]:{
        color:"primary",
        elementStyle:" btn-primary ",
        icon:" mdi mdi-square-edit-outline ",
        btnText:"Edit",
        tooltip:"Edit",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },[actionBtnTypes.view]:{
        color:"primary",
        elementStyle:" btn-primary ",
        icon:"fas fa-binoculars",
        btnText:"View",
        tooltip:"View",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },[actionBtnTypes.delete]:{
        color:"danger",
        elementStyle:" btn-danger ",
        icon:"mdi mdi-trash-can-outline",
        btnText:"Delete",
        tooltip:"Delete",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    }
    ,[actionBtnTypes.other]:{
        elementStyle:"otherBtn",     
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    }
 }


 export {
    actionBtnTypes,
    commonActionBtnPops
 }
