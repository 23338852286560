import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

import { DatePickerBoxMemo } from "./DatePicker";
import { getValueByFilter } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { changeInputFn } from "../../../store/boda-boda/form/actions";
import { formCacheLevel } from "../../../config/boda-boda/template.config";


const emptyFun = (...para) => undefined;

class DatePickerBoxWithState extends Component {

    static defaultProps = {
        elementWrapperStyle: "",
        elementStyle: "",
        labelText: "",
        isRequired: false,
        inputName: "",
        inputValue: new Date(),
        minDate: null,
        maxDate: null,
        placeholderText: "",
        isDisabled: false,
        helperText: "",
        inputStatePath: "",
        formGroupName: "",
        cacheLevel: formCacheLevel.updateOnFormGroupChange,
        monthYearPicker: false, 
        onChangeFn: emptyFun,
    }

    constructor(props) {
        super(props);
    }

    render() {
        let {
            elementWrapperStyle, elementStyle, labelText,
            inputName, inputValue, isDisabled,
            helperText, inputStatePath, formGroupName,
            cacheLevel, changeInputFn, formState,
            isRequired, minDate, maxDate, placeholderText, monthYearPicker, dateFormat
        } = this.props;

        return (
            <DatePickerBoxMemo
                elementWrapperStyle={elementWrapperStyle}
                elementStyle={elementStyle}
                labelText={labelText}
                isRequired={isRequired}
                inputName={inputName}
                inputValue={get(formState,inputStatePath?inputStatePath:`${formGroupName}.${inputName}`,inputValue)}
                inputError={getValueByFilter(get(formState,`${formGroupName}._errors`,[]),["property",((inputStatePath)?inputStatePath.substr(formGroupName.length + 1):inputName)],"error","","")}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholderText}
                isDisabled={isDisabled}
                helperText={helperText}
                updateStatus={get(formState,`${formGroupName}._updateStatus`,undefined)}
                cacheLevel={cacheLevel}
                monthYearPicker={monthYearPicker}
                dateFormat={dateFormat}
                onChangeFn={(eventData)=>changeInputFn(formGroupName,inputName,inputStatePath,eventData.value)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, { changeInputFn })(DatePickerBoxWithState);
