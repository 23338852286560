import {
    createUserTrKey,
    updateUserTrKey,
    updateManifestIdTrKey,
    updateManifestIdAddPackageTrKey,
    createDriverTrKey,
    deliveryCenterAddZones,
    createZoneTrKey,
    zoneAddCitiesTrKey,
    createRunSheetLineTrKey,
    addCollectionsRunSheetLineTrKey,
    editRunSheetLineTrKey,
    createRunSheetLastTrKey,
    editRunSheetLastTrKey,
    addPickupsRunSheetLastTrKey,
    createMerchantTrKey,
    createMerchantAdministrationTrKey,
    createMerchantBranchTrKey,
    removePickupsRunSheetLastTrKey,
    addDeliveriesRunSheetLastTrKey,
    removeDeliveriesRunSheetLastTrKey,
    routeLastAddCitiesTrKey,
    routeLastRemoveCitiesTrKey,
    linehaulAddLocationsTrKey,
    linehaulRemoveLocationsTrKey,
    updateLinehaulTrKey,
    updateLastMileTrKey,
    createLinehaulTrKey,
    createLastMileTrKey,
    updateMerchantBranchTrKey,
    updateUserProfileTrKey,
    changePasswordTrKey,
    updateDriverTrKey,
    bulkTagPrintTrKey,
    createMerchantAdminUserTrKey,
    updateMerchantAdminUserTrKey,
    userPasswordResetTrKey,
    createBranchTrKey,
    switchFacilityTrKey, changeDriverPasswordTrKey, createMerchantContactTrKey, createMerchantBranchContactTrKey, editMerchantTrKey, addReturnsRunSheetLastTrKey, removeReturnsRunSheetLastTrKey
} from "./actionTypes";

export const createUserTrFn = (dataObj) => {
    return {
        type: createUserTrKey,
        payload: dataObj
    }
}

export const updateUserTrFn = (dataObj) => {
    return {
        type: updateUserTrKey,
        payload: dataObj
    }
}

export const createDriverTrFn = (dataObj) => {
    return {
        type: createDriverTrKey,
        payload: dataObj
    }
}

export const updateDriverTrFn = (dataObj) => {
    return {
        type: updateDriverTrKey,
        payload: dataObj
    }
}

export const updateMerchantAdminUserTrFn = (dataObj) => {
    return {
        type: updateMerchantAdminUserTrKey,
        payload: dataObj
    }
}

export const updateManifestId = (id, data, flashMsjObj) => {
    return {
        type: updateManifestIdTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const updateManifestIdAddPackage = (id, data, flashMsjObj) => {
    return {
        type: updateManifestIdAddPackageTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const addZones = (id, data, flashMsjObj) => {
    return {
        type: deliveryCenterAddZones,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const createZone = (dataObj) => {
    return {
        type: createZoneTrKey,
        payload: dataObj
    }
}

export const addCities = (id, data, flashMsjObj) => {
    return {
        type: zoneAddCitiesTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const createLinehaul = (dataObj) => {
    return {
        type: createLinehaulTrKey,
        payload: dataObj
    }
}

export const createLastMile = (dataObj) => {
    return {
        type: createLastMileTrKey,
        payload: dataObj
    }
}

export const routeLastAddCities = (id, data, flashMsjObj) => {
    return {
        type:  routeLastAddCitiesTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const routeLastRemoveCities = (id, data, flashMsjObj) => {
    return {
        type: routeLastRemoveCitiesTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const linehaulAddLocations = (id, data, flashMsjObj) => {
    return {
        type: linehaulAddLocationsTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const linehaulRemoveLocations = (id, data, flashMsjObj) => {
    return {
        type: linehaulRemoveLocationsTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const updateLinehaul = (dataObj) => {
    return {
        type: updateLinehaulTrKey,
        payload: dataObj
    }
}

export const updateLastMile = (dataObj) => {
    return {
        type: updateLastMileTrKey,
        payload: dataObj
    }
}

export const createRunSheetLine = (dataObj) => {
    return {
        type: createRunSheetLineTrKey,
        payload: dataObj
    }
}

export const CreateMerchantTrFn = (dataObj) => {
    return {
        type: createMerchantTrKey,
        payload: dataObj
    }
}

export const EditMerchantTrFn = (dataObj) => {
    return {
        type: editMerchantTrKey,
        payload: dataObj
    }
}

export const addCollectionsToRunSheetLine = (id, data, flashMsjObj) => {
    return {
        type: addCollectionsRunSheetLineTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const editRunSheetLine = (dataObj) => {
    return {
        type: editRunSheetLineTrKey,
        payload: dataObj
    }
}

export const createMerchantAdministrationTrFn = (dataObj) => {
    return {
        type: createMerchantAdministrationTrKey,
        payload: dataObj
    }
}

export const createMerchantAdminUserTrFn = (dataObj) => {
    return {
        type: createMerchantAdminUserTrKey,
        payload: dataObj
    }
}

export const createRunSheetLast = (dataObj) => {
    return {
        type: createRunSheetLastTrKey,
        payload: dataObj
    }
}

export const editRunSheetLast = (dataObj) => {
    return {
        type: editRunSheetLastTrKey,
        payload: dataObj
    }
}

export const addPickupsToRunSheetLast = (data) => {
    return {
        type: addPickupsRunSheetLastTrKey,
        payload: data
    }
}

export const CreateMerchantBranchTrFn = (dataObj) => {
    return {
        type: createMerchantBranchTrKey,
        payload: dataObj
    }
}

export const removePickupsToRunSheetLast = (data) => {
    return {
        type: removePickupsRunSheetLastTrKey,
        payload: data
    }
}

export const addDeliveriesToRunSheetLast = (data) => {
    return {
        type: addDeliveriesRunSheetLastTrKey,
        payload: data
    }
}

export const removeDeliveriesToRunSheetLast = (data) => {
    return {
        type: removeDeliveriesRunSheetLastTrKey,
        payload: data
    }
}

export const addReturnsToRunSheetLast = (data) => {
    return {
        type: addReturnsRunSheetLastTrKey,
        payload: data
    }
}

export const removeReturnsToRunSheetLast = (data) => {
    return {
        type: removeReturnsRunSheetLastTrKey,
        payload: data
    }
}

export const UpdateMerchantBranchTrFn = (data) => {
    return {
        type: updateMerchantBranchTrKey,
        payload: data
    }
}

export const updateUserProfileTrFn = (dataObj) => {
    return {
        type: updateUserProfileTrKey,
        payload: dataObj
    }
}

export const changePasswordTrFn = (dataObj) => {
    return {
        type: changePasswordTrKey,
        payload: dataObj
    }
}

export const bulkTagPrintTrFn = (data) => {
    return {
        type: bulkTagPrintTrKey,
        payload: data
    }
}

export const userPasswordReset = (data) => {
    return {
        type: userPasswordResetTrKey,
        payload: data
    }
}

export const createBranchFn = (data) => {
    return {
        type: createBranchTrKey,
        payload: data
    }
}

export const switchFacilityFn = (data) => {
    return {
        type: switchFacilityTrKey,
        payload: data
    }
}

export const driverPasswordResetFn = (data) => {
    return {
        type: changeDriverPasswordTrKey,
        payload: data
    }
}

export const createMerchantContactFn = (data) => {
    return {
        type: createMerchantContactTrKey,
        payload: data
    }
}

export const createMerchantBranchContactFn = (data) => {
    return {
        type: createMerchantBranchContactTrKey,
        payload: data
    }
}