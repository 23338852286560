import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { findIndex, get } from "lodash";

import { FormWrapper } from "../../../../components/boda-boda/Forms";
import { getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import ExpansionPanel from "../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { currency, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";
import { roundValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class PackageViewModalUI extends Component {

    getPackageNo = (data = [], id) => {
        const index = findIndex(data, { id: parseInt(id) });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return "";
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            packageDetailsPanel: true,
            receiverPanel: false,
            locationPanel: false,
            notePanel: false,
            chargesPanel: false,
            reasonPanel: false,
        }
    }

    render() {
        let { match, formState, row } = this.props;

        console.log("==============", match, formState, row)

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getPackageListAPI.key}_view`}
                    apiUrl={`${getPackageListAPI.url}/${row.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            type: response.type,
                            code: response.code,
                            articleNo: response.articleNo,
                            category: response.category,
                            weight: response.weight,
                            height: response.height,
                            width: response.width,
                            length: response.length,
                            status: response.status,
                            deletedAt: response.deletedAt,
                            deliveryType: response.deliveryType,
                            order: response.order,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            merchantId: response.merchantId,
                            manifest: response.manifest,
                            expressFee: response.expressFee,
                            totalPrice: response.totalPrice,
                            cashDeliveryAmount: response.cashDeliveryAmount,
                            cardDeliveryAmount: response.cardDeliveryAmount,
                            xlPackageHandlingFee: response.xlPackageHandlingFee,
                            onholdReason: response.onholdReason,
                            notDeliveredReason: response.notDeliveredReason,
                            "_onLoad": false,
                        }
                    }}
                >
                    <ExpansionPanel
                        title={"Package Details"}
                        isOpen={this.state.packageDetailsPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                packageDetailsPanel: !prevState.packageDetailsPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-4"}>Article No</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.articleNo`, "")}</dt>

                                    {/* <dd className={"col-sm-4"}>Order No</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.id`, "")}</dt> */}

                                    <dd className={"col-sm-4"}>Consignment No</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.consignmentNo`, "")}</dt>

                                    <dd className={"col-sm-4"}>Package No</dd>
                                    <dt className={"col-sm-8"}>{this.getPackageNo(get(formState, `${getPackageListAPI.key}_view.order.packages`, []), (match !== undefined) ? match.params.id : row.orderId)}</dt>

                                    <dd className={"col-sm-4"}>Signature Required</dd>
                                    <dt className={"col-sm-8"}>{(get(formState, `${getPackageListAPI.key}_view.order.signatureRequired`, "") === false) ? "No" : "Yes"}</dt>

                                    <dd className={"col-sm-4"}>Package Type</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.type.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Package Category</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Order Amount</dd>
                                    <dt className={"col-sm-8"}>
                                        {
                                            (get(formState, `${getPackageListAPI.key}_view.order.amount`, null) === null) ? "0" : get(formState, `${getPackageListAPI.key}_view.order.amount`, "") + ` ${currency}`
                                        }
                                    </dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-4"}>Weight</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.weight`, "")}</dt>

                                    <dd className={"col-sm-4"}>Length</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.length`, "")}</dt>

                                    <dd className={"col-sm-4"}>Width</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.width`, "")}</dt>

                                    <dd className={"col-sm-4"}>Height</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.height`, "")}</dt>

                                    <dd className={"col-sm-4"}>Delivery Type</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.deliveryType`, "")}</dt>

                                    <dd className={"col-sm-4"}>Status</dd>
                                    <dt className={"col-sm-8"}>{mapPackageStatusesNames[get(formState, `${getPackageListAPI.key}_view.status`, "")]}</dt>

                                    <dd className={"col-sm-4"}>Payment Method</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "")}</dt>

                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Full Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.phone`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>ID Verification Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, "True").toString().toUpperCase()}</dt>

                                    {
                                        get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === true ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationType`, "")}</dt>

                                                <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, "")}</dt>
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.postalCode`, "-")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lat`, "-")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lon`, "-")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.name`, "")}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Delivery Charges"}
                        isOpen={this.state.chargesPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                chargesPanel: !prevState.chargesPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-4"}>Rate Category</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "0")}</dt>

                                    <dd className={"col-sm-4"}>Express Delivery Charge</dd>
                                    <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.expressFee`, "0"))}</dt>

                                    <dd className={"col-sm-4"}>Total Charge</dd>
                                    <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.totalPrice`, "0"))}</dt>

                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-4"}>Delivery Charge</dd>
                                    {(get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "") === "CASH_ON_DELIVERY") ? <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.cashDeliveryAmount`, "0"))}</dt>
                                        : <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.cardDeliveryAmount`, "0"))}</dt>}


                                    <dd className={"col-sm-4"}>XL Handling Charge</dd>
                                    <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.xlPackageHandlingFee`, "0"))}</dt>

                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    {
                        (get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED" || get(formState, `${getPackageListAPI.key}_view.status`, "") === "ON_HOLD") ?
                            (
                                <ExpansionPanel
                                    title={"Reasons"}
                                    isOpen={this.state.reasonPanel}
                                    onClickFn={() => this.setState((prevState) => {
                                        return {
                                            ...prevState,
                                            reasonPanel: !prevState.reasonPanel
                                        }
                                    })}
                                >
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <dl className={"row"}>

                                                {
                                                    (get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED") ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-5"}>Reason for Not Delivered - </dd>
                                                            <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.notDeliveredReason`, "")}</dt>
                                                        </Fragment>
                                                    )
                                                        : (get(formState, `${getPackageListAPI.key}_view.status`, "") === "ON_HOLD") ? (
                                                            <Fragment>
                                                                <dd className={"col-sm-5"}>Reason for On-Hold - </dd>
                                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.onholdReason`, "")}</dt>
                                                            </Fragment>
                                                        ) : (null)
                                                }

                                            </dl>
                                        </div>
                                    </div>
                                </ExpansionPanel>
                            ) : null
                    }



                    <ExpansionPanel
                        title={"Notes"}
                        isOpen={this.state.notePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                notePanel: !prevState.notePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-3"}>Public Note -</dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getPackageListAPI.key}_view.notesPublic`, "None")}</dt>

                                    {/* <dd className={"col-sm-3"}>Private Note -</dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getPackageListAPI.key}_view.notesPrivate`, "None")}</dt> */}
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <div className="row">
                        <CrudComponent
                            title="Other Related Packages"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${get(formState, `${getPackageListAPI.key}_view.order.id`)}`}
                            dataTableStateKey={`${getPackageListAPI.key}_inside`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={false}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(PackageViewModalUI);
