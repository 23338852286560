import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {connect} from "react-redux";
import MerchantProfile from "./create-includes/MerchantProfile";
import MerchantAdministration from "./create-includes/MerchantAdministration";
import MerchantContract from "./create-includes/MerchantContract";
import MerchantDefaultBranch from "./create-includes/MerchantDefaultBranch";
import { changeInputFn } from "../../../../../store/actions";

class CreateUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            merchantId: null
        };
    }

    render() {
        let { history, formState, changeInputFn} = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">
                                    {
                                        (this.state.step === 0) ? "Create Merchant Profile" : (
                                            (this.state.step === 1) ? "Create Merchant Admin" : (
                                                (this.state.step === 2) ? "Create Merchant Contract" : "Create Merchant Default Branch"
                                            )
                                        )
                                    }
                                </h4>
                                <ol className="breadcrumb mb-0">
                                    {
                                        (this.state.step === 0) ? <li className="breadcrumb-item"><i>Profile</i></li> :
                                            <li className="breadcrumb-item active">Profile</li>
                                    }
                                    {
                                        (this.state.step === 1) ? <li className="breadcrumb-item"><i>Admin</i></li> :
                                            <li className="breadcrumb-item active">Admin</li>
                                    }
                                    {
                                        (this.state.step === 2) ? <li className="breadcrumb-item"><i>Contract</i></li> :
                                            <li className="breadcrumb-item active">Contract</li>
                                    }
                                    {
                                        (this.state.step === 3) ? <li className="breadcrumb-item"><i>Default Branch</i></li> :
                                            <li className="breadcrumb-item active">Default Branch</li>
                                    }
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    {
                                        (this.state.step === 0) ? (
                                            <MerchantProfile
                                                onNextFn={(id) => this.setState({ step: 1, merchantId: id })}
                                            />
                                        ) : (
                                            (this.state.step === 1) ? (
                                                <MerchantAdministration
                                                    merchantId={this.state.merchantId}
                                                    onNextFn={() => this.setState({
                                                        ...this.state,
                                                        step: 2
                                                    })}
                                                />
                                            ) : (
                                                (this.state.step === 2) ? (
                                                    <MerchantContract
                                                        merchantId={this.state.merchantId}
                                                        formState={formState}
                                                        changeInputFn={changeInputFn}
                                                        onNextFn={() => this.setState({
                                                            ...this.state,
                                                            step: 3
                                                        })}

                                                    />
                                                ) :  (
                                                        <MerchantDefaultBranch
                                                            merchantId={this.state.merchantId}
                                                            history={history}
                                                        />
                                                    )
                                                )
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const CreateUIWithState = connect(mapStateToProps, { changeInputFn })(CreateUI);

export {
    CreateUIWithState
}