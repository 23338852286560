import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { initFormFn } from "./actions";
import { initFormGroupKey} from "./actionTypes";

// function* intForm(data) {
//     console.log("------------",data);
//     console.log(2);
//     yield put({ type: `${initFormGroupKey}_ASYNC`, payload: "test" });
// }

function* watchFormObjectInit() {
    //yield takeEvery(initFormGroupKey,intForm);
}

function* formSaga() {
    yield all([
        fork(watchFormObjectInit)
    ])
}

export default formSaga;
