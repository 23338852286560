import _, { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetDataTable, setFlashMessage, setPageLoader } from "../../../../../store/actions";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { hubSettlementAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { Button } from "reactstrap";


const MarkAsSettledBtn = (props) => {
    const coreState = useSelector((state) => state.CoreState);
    const generatedHubSettlement = get(coreState, `apiResponses.${hubSettlementAPI.key}.result`, {})
    const toBeSettledOrders = [generatedHubSettlement]
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [isSettled, setIsSettled] = useState(false)

    const markAsHubSettled = async () => {
        try {
            dispatch(setPageLoader(true));

            if (!generatedHubSettlement) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: 'No hub settlement generated',
                        type: 'danger',
                    })
                );
                setShow(false);
                dispatch(setPageLoader(false));
                return;
            }


            const body = {
                "settled": true
            }

            const hubSettlementAPIResponse = await callApi(
                `${hubSettlementAPI.url}/${generatedHubSettlement?.id}`
            )
                .headers(true)
                .method('put')
                .body(body)
                .send();
            if (hubSettlementAPIResponse._statue) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: `Hub settlement settled successfully`,
                        type: 'success',
                    })
                );
                setIsSettled(hubSettlementAPIResponse?.data?.data?.settled)
            } else {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: `Something went wrong`,
                        type: 'danger',
                    })
                );
            }

            // dispatch(resetDataTable(`${hubSettlementAPI.key}`));
            setShow(false)
            dispatch(setPageLoader(false));

        } catch (error) {
            setShow(false);
            dispatch(setPageLoader(false));

            dispatch(
                setFlashMessage({
                    status: true,
                    message: 'An error has occurred',
                    type: 'danger',
                })
            );
        }
    }

    return (
        <React.Fragment>
            <Button
                color='primary'
                className='btn btn-primary waves-effect waves-light'
                disabled={props?.settled ? props?.settled : isSettled}
                onClick={() => setShow(true)}
            >
                {props?.settled ? 'Settled' : isSettled ? 'Settled' : 'Mark as Settled'}
            </Button>
            <ModalUI
                isOpen={show}
                modalTitle={'Please Confirm'}
                modalBodyComponent={`Are you sure you want to mark ${toBeSettledOrders?.length
                    } order(s) as hub settled?`}
                showHideToggleFn={() => setShow(false)}
                closeBtnName='No'
                modalFooterComponent={
                    <Button
                        color='primary'
                        onClick={() => {
                            markAsHubSettled()
                        }
                        }
                    >
                        Yes
                    </Button>
                }
            />
        </React.Fragment>
    )
}

export default MarkAsSettledBtn;