import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import {connect} from "react-redux";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";
import {FormWrapper, InputBoxWithState, SelectBoxWithState, SubmitButton} from "../../../../components/boda-boda/Forms";

class CreateUI extends Component {
    constructor(props) {   
        super(props);
    }

    render() {
        let { formState, coreState, history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Add Pick Up Details</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>

                            <CreateBtn
                                historyFn={history}
                                coreState={coreState}
                            />

                            <BackBtnComponent
                                path={"/merchant/create"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={"CreatePickup"}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Address</h4>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="First Line"
                                                placeHolderText={"First Line"}
                                                inputName={"firstLine"}
                                                formGroupName={"CreatePickup"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="Province"
                                                placeHolderText={"Province"}
                                                inputName={"province"}
                                                formGroupName={"CreatePickup"}
                                                dataList={[
                                                    {
                                                        "id":"SP",
                                                        "value":"SOUTHERN"
                                                    },
                                                    {
                                                        "id":"WP",
                                                        "value":"WESTERN"
                                                    }
                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Second Line"
                                                placeHolderText={"Second Line"}
                                                inputName={"secondLine"}
                                                formGroupName={"CreatePickup"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="District"
                                                placeHolderText={"District"}
                                                inputName={"province"}
                                                formGroupName={"CreatePickup"}
                                                dataList={[
                                                    {
                                                        "id":"KL",
                                                        "value":"KALUTHARA"
                                                    },
                                                    {
                                                        "id":"CMB",
                                                        "value":"COLOMBO"
                                                    },
                                                    {
                                                        "id":"GMP",
                                                        "value":"GAMPAHA"
                                                    }
                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Third Line"
                                                placeHolderText={"Third Line"}
                                                inputName={"thirdLine"}
                                                formGroupName={"CreatePickup"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="City"
                                                placeHolderText={"City"}
                                                inputName={"city"}
                                                formGroupName={"CreatePickup"}
                                                dataList={[
                                                    {
                                                        "id":"0",
                                                        "value":"MT. LAVINIA"
                                                    },
                                                    {
                                                        "id":"1",
                                                        "value":"DEHIWALA"
                                                    },
                                                    {
                                                        "id":"2",
                                                        "value":"WELLAWATTA"
                                                    }
                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Contact Person"
                                                placeHolderText={"Contact Person"}
                                                inputName={"cPerson"}
                                                formGroupName={"CreatePickup"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Phone No"
                                                placeHolderText={"Phone No"}
                                                inputName={"phone"}
                                                formGroupName={"CreatePickup"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Email"
                                                placeHolderText={"Email"}
                                                inputName={"mail"}
                                                formGroupName={"CreatePickup"}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        )
    }
}

const CreateBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const CreateUIWithState = connect(mapStateToProps, null)(CreateUI);

export {
    CreateUIWithState
}