import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import {
    countryCodeAPI,
    getFacilityAPI,
    getRoleListAPI,
    getUserStaffList
} from "../../../../config/boda-boda/apiUrls.config";
import { userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import FileUpload from "../../../../components/boda-boda/FileUpload/FileUpload";
import { updateUserTrFn } from "../../../../store/boda-boda/transaction_request/actions";


class EditUI extends Component {
    render() {
        let { match, formState, history, authState, updateUserTrFn } = this.props;

        return (
            <div className="container-fluid">
                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserStaffList.key}_edit`}
                            apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                            onDestroyUnsetFormObject={true}
                            onRebuildResponseFn={(response) => {
                                return {
                                    userId: response.userId,
                                    canSwitch: response.user.canSwitch,
                                    name: response.name,
                                    identification: response.identification,
                                    facility: response.facilityId,
                                    role: response.user.roleId,
                                    phone: response.phone,
                                    email: response.email,
                                    image: get(response, "image", null) === null ? "" : get(response, "image", null),
                                    status: response.status,
                                    countryCode: {
                                        id: `${get(response, "countryCode.id", "")}/${get(response, "countryCode.code", "")}/${get(response, "countryCode.prefix", "")}`
                                    },
                                    countryCodePrefix: get(response, `countryCodePrefix`, ""),
                                    oldData: {
                                        canSwitch: response.user.canSwitch,
                                        role: response.user.roleId,
                                        facility: response.facilityId,
                                        email: response.email
                                    },
                                    "_onLoad": false,
                                }
                            }}
                        >
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                        <h4 className="font-size-18">Update Staff</h4>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <InputButtonWithState
                                            elementWrapperStyle={"mr-2"}
                                            startIcon={"mdi mdi-content-save-all-outline"}
                                            btnText={"Reset Password"}
                                            onClickBtnFn={() => history.push(`/users/staff/change-password/${match.params.id}`)}
                                        />

                                        <InputButtonWithState
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Save"}
                                            startIcon={"mdi mdi-content-save-all-outline"}
                                            formGroupName={`${getUserStaffList.key}_edit`}
                                            onClickBtnFn={(dataObject) => {
                                                updateUserTrFn({
                                                    ...dataObject.formObject,
                                                    history: history,
                                                    staffId: match.params.id
                                                })
                                            }}
                                        />

                                        <BackBtnComponent
                                            elementWrapperStyle={"mr-2"}
                                            path={"/users/staff"}
                                            btnName={"Cancel"}
                                            icon={"far fa-times-circle"}
                                            btnColor={"danger"}
                                            isModal={true}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>User ID</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_edit.userId`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"User Role"}
                                                inputName={"role"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                apiUrl={`${getRoleListAPI.url}/all?status=ACTIVE&type=WAREHOUSE`}
                                                apiStateKey={getRoleListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                placeHolderText={"Role"}
                                                isRequired={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"Default Delivery Center"}
                                                inputName={"facility"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                apiUrl={`${getFacilityAPI.url}/all`}
                                                apiStateKey={getFacilityAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                placeHolderText={"Default Delivery Center"}
                                                isRequired={true}
                                            />
                                        </div>

                                        {
                                            (get(authState, "authUser.isSuper", false) === true) ? (
                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="Can Switch"
                                                        inputName={"canSwitch"}
                                                        placeHolderText={"Can Switch"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        dataList={[
                                                            {
                                                                id: true,
                                                                value: "Yes"
                                                            },
                                                            {
                                                                id: false,
                                                                value: "No"
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            ) : null
                                        }

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="Status"
                                                inputName={"status"}
                                                placeHolderText={"Status"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                dataList={userStatusList}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Personal Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Name"
                                                        placeHolderText={"Name"}
                                                        inputName={"name"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />

                                                    <InputBoxWithState
                                                        labelText="Identification"
                                                        placeHolderText={"Identification"}
                                                        inputName={"identification"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <div className={"d-flex justify-content-center"}>
                                                        <FileUpload
                                                            labelText={"Profile Picture"}
                                                            formGroupName={`${getUserStaffList.key}_edit`}
                                                            formKey={"image"}
                                                            helperText={"Supported formats (png, jpg, jpeg)"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">

                                                    <div className={"row"}>
                                                        <div className={"col-md-3"}>
                                                            <SelectBoxWithState
                                                                isRequired={true}
                                                                labelText="Country Code"
                                                                inputStatePath={`${getUserStaffList.key}_edit.countryCode.id`}
                                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                                apiUrl={`${countryCodeAPI.url}/all`}
                                                                apiStateKey={countryCodeAPI.key}
                                                                onRebuildResponseFn={(response) => {
                                                                    const data = [];

                                                                    response.forEach((value) => {
                                                                        data.push({
                                                                            id: `${value.id}/${value.code}/${value.prefix}`,
                                                                            value: value.code
                                                                        });
                                                                    });

                                                                    return {
                                                                        "data": {
                                                                            "data": data
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className={"col-md-9"}>
                                                            <InputBoxWithState
                                                                labelText="Phone"
                                                                inputName={"phone"}
                                                                placeHolderText={"+xxxxxxxxxx"}
                                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Email"
                                                        inputName={"email"}
                                                        placeHolderText={"Email"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        maxLength={50}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormWrapper>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

const UserEdit = connect(mapStateToProps, { updateUserTrFn })(EditUI);

export default UserEdit;
