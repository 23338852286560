import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";
import {
    FormWrapper,
    InputBoxWithState,
    InputButtonWithState,
    SubmitButton
} from "../../../../components/boda-boda/Forms";
import {apiResponseStoringType} from "../../../../config/boda-boda/apiResponseKey";
import {createAuthUserAPI, getUserDriverList, getUserStaffList} from "../../../../config/boda-boda/apiUrls.config";
import { get } from "lodash";
import {connect, useDispatch} from "react-redux";
import {changeDriverPasswordTrKey} from "../../../../store/boda-boda/transaction_request/actionTypes";


class ChangePasswordUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserStaffList.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserDriverList.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        console.log(response.userId)
                        return {
                            id: response.userId,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Reset Password</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <PasswordResetBtn
                                    userId={get(formState, `${getUserStaffList.key}_edit.id`, 6)}
                                    staffId={match.params.id}
                                    historyFn={history}
                                />
                                <BackBtnComponent
                                    path={`/users/driver/edit/${match.params.id}`}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">

                                <div className="card-body">
                                    <div className={"row"}>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="New Password"
                                                inputName={"password"}
                                                placeHolderText={"New Password"}
                                                inputType={"password"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Re enter New Password"
                                                inputName={"confirm_password"}
                                                placeHolderText={"Re Enter New Password"}
                                                inputType={"password"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

const PasswordResetBtn = (props) => {
    const dispatch = useDispatch();

    return(
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Reset Password"}
            formGroupName={`${getUserStaffList.key}_edit`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: changeDriverPasswordTrKey,
                    payload: {
                        ...dataObject.formObject,
                        userId: props.userId,
                        staffId: props.staffId,
                        historyFn: props.historyFn
                    }
                })
            }}
        />
    )
}

const CreateBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getUserStaffList.key}_edit`}
            isValidate={false}
            elementWrapperStyle={"mr-2"}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Password successfully Updated",
                    type: "success"
                }
            }}
            validationObject={{
                fields: {
                    password: "Password",
                    confirm_password: "Confirm Password"
                },
                rules: {
                    password: "required|max:50",
                    confirm_password: "required|same:password|max:50"
                },
                message: {}
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${createAuthUserAPI.url}/${props.userId}`,
                    key: createAuthUserAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    password: formObject.password
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push(`/users/driver/edit/${props.staffId}`);
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ChangePasswordUIWithState = connect(mapStateToProps, null)(ChangePasswordUI)

export { ChangePasswordUIWithState };