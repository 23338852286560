import React, { Fragment, useState } from 'react'
import { ModalUI } from '../../../../../components/boda-boda/Modal/ModalUI';
import { CheckBoxWithState, FormWrapper, InputBoxWithState, SubmitButton } from '../../../../../components/boda-boda/Forms';
import { merchantBranchRateAPI } from '../../../../../config/boda-boda/apiUrls.config';
import { useHistory } from "react-router-dom";

const MerchantRateUpdateUI = (props) => {
    return (

        <FormWrapper
            setGroupName={`${merchantBranchRateAPI.key}_edit`}
            onDestroyUnsetFormObject={true}
        >

            <div className={"row"}>
                <div className={"col-md-6"}>
                    <dl className={"row"}>
                        <dd className={"col-sm-5"}>City</dd>
                        <dt className={"col-sm-7"}>{props.data.city}</dt>

                        {props.data.zone && <>
                            <dd className={"col-sm-5"}>Zone</dd>
                            <dt className={"col-sm-7"}>{props.data.zone}</dt>
                        </>}

                        <dd className={"col-sm-5"}>Weight Slab</dd>
                        <dt className={"col-sm-7"}>{props.data.weightSlab}</dt>
                    </dl>
                </div>

                <div className={"col-md-6"}>
                </div>
            </div>
            <hr></hr>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <dl className={"row"}>
                        <dd className={"col-sm-3"}>Current Rate</dd>
                        <dt className={"col-sm-9"}>{props.data.price}</dt>
                    </dl>
                </div>
            </div>
            <InputBoxWithState
                labelText={"Updated Rate"}
                placeHolderText={"Updated Rate"}
                formGroupName={`${merchantBranchRateAPI.key}_edit`}
                inputName={"updatedPrice"}
                isRequired={true}
            />
            {props.data.zone && <CheckBoxWithState
                labelText={`Apply to all cities in the ${props.data.zone}`}
                inputName={`applyZoneWise`}
                inputValue={false}
                formGroupName={`${merchantBranchRateAPI.key}_edit`}
            />}
        </FormWrapper >
    );
};


const MerchantRateConfirmBtn = (props) => {
    const history = useHistory()
    return (
        <SubmitButton
            btnText={"Confirm"}
            formGroupName={`${merchantBranchRateAPI.key}_edit`}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Merchant Rate successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${merchantBranchRateAPI.url}/${props.data.id}/overwrite`,
                    key: `${merchantBranchRateAPI.key}_edit`
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return { merchantPrice: parseFloat(formObject.updatedPrice), applyZoneWise: formObject.applyZoneWise };
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    // dispatch(setPageLoader(true))
                    props.setModelStatusFn(false);
                    // dispatch(resetDataTable(merchantBranchRateAPI.key))
                    history.goBack();
                }
            }}
        />
    );
}

const UpdateVolumetricRateValue = (props) => {

    const [isOpen, setOpen] = useState(false);
    return (
        <Fragment>
            <div className='update_merchant_rate'><span>{props.price}</span><i className="mdi mdi-pencil" onClick={() => setOpen(true)}></i></div>

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Update Merchant Rate"}
                modalBodyComponent={<MerchantRateUpdateUI data={props} />}
                modalFooterComponent={<MerchantRateConfirmBtn data={props} setModelStatusFn={(value) => setOpen(value)} />}
                showHideToggleFn={() => {
                    setOpen(false);
                }}
            />
        </Fragment>
    )
}

export default UpdateVolumetricRateValue