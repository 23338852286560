import React, {Component} from "react";
import {InputBoxWithState, SelectBoxWithState} from "../../../../../../components/boda-boda/Forms";
import {getAllDistrictAPI, getProvinceAPI} from "../../../../../../config/boda-boda/apiUrls.config";

class FilterUI extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="row">

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Province"}
                            inputName={"provinceId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Province"}
                            apiUrl={`${getProvinceAPI.url}/all`}
                            apiStateKey={getProvinceAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"District"}
                            inputName={"districtId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"District"}
                            apiUrl={`${getAllDistrictAPI.url}`}
                            apiStateKey={getAllDistrictAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="City"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"City"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export {
    FilterUI
}