import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";
import {connect} from "react-redux";
import {actionBtnTypes, CrudComponent} from "../../../../components/boda-boda/CRUD/CrudComponent";
import {SubmitButton} from "../../../../components/boda-boda/Forms";

class CreateUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centers: [
                { "id": "101", "name": "City Pack", "city": "Mt. Lavinia", "zone": "Colombo"},
                { "id": "102", "name": "DHL", "city": "Dehiwala", "zone": "Colombo"}
                ],
            selectedCenters: []
        }
    }

    addLocation = (row) => {
        const index = this.state.centers.findIndex(x => x.id === row.id);
        const centers = [... this.state.centers];
        this.setState(prevState => ({ selectedCenters: prevState.selectedCenters.concat([row])}));
        centers.splice(index, 1)
        this.setState( { centers: centers })
    }

    removeLocation = (row) => {
        const index = this.state.selectedCenters.findIndex(x => x.id === row.id);
        const selectedCenters = [... this.state.selectedCenters];
        this.setState( prevState => ({ centers: prevState.centers.concat([row])}))
        selectedCenters.splice(index, 1)
        this.setState({ selectedCenters: selectedCenters})
    }

    render() {
        let { formState, coreState, history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Add Lodgment Location for Merchant</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn/>
                            <BackBtnComponent
                                path={"/merchant/create"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <CrudComponent
                        title={"Delivery Centers"}
                        tableHeaderList={[
                            { displayName: "Delivery Center Id", key: "id"},
                            { displayName: "Name", key: "name"},
                            { displayName: "City", key: "city"},
                            { displayName: "Zone", key: "zone"}
                        ]}
                        tableBodyList={this.state.centers}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view
                            },
                            {
                                actionType: actionBtnTypes.create,
                                actionBtn: {
                                    onClickBtnFn: (data) => this.addLocation(data)
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title={"Selected Delivery Centers"}
                        isSetCreateAction={false}
                        isFilter={false}
                        tableHeaderList={[
                            { displayName: "Delivery Center Id", key: "id"},
                            { displayName: "Name", key: "name"},
                            { displayName: "City", key: "city"},
                            { displayName: "Zone", key: "zone"}
                        ]}
                        tableBodyList={this.state.selectedCenters}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                            },
                            {
                                actionType: actionBtnTypes.delete,
                                actionBtn: {
                                    onClickBtnFn: (data) => this.removeLocation(data)
                                }
                            }
                        ]}
                    />
                </div>
            </div>
        )
    }
}

const CreateBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const CreateUIWithState = connect(mapStateToProps, null)(CreateUI);

export {
    CreateUIWithState
}