import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { RemoveMessage, RemoveBtn } from "./user_includes/DeleteUI";
import { getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import {connect} from "react-redux";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";


class AdminUser extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { history, match } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Merchant Admin Users</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Merchant Admin Users</li>
                </ol>
              </div>
            </Col>
            <Col sm={6}>
              <div className={"d-flex justify-content-end"}>
                <BackBtnComponent
                    elementWrapperStyle={"mr-2"}
                    path={`/merchant/view/${match.params.id}`}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Merchant Admin Users"
              isFilter={false}
              apiURL={`${getUserStaffList.url}?merchantId=${match.params.id}&page=1&limit=20`}
              dataTableStateKey={getUserStaffList.key}
              defaultFilterFormObject={{ id: "", name: "", phone: "", status: "", role: "" }}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: false,
                onClickBtnFn: () => history.push(`/merchant/${match.params.id}/admin-users/create`)
              }}
              isDropdownActionBtn={true}
              tableHeaderList={[
                { displayName: "User Id", key: "id" },
                { displayName: "Name", key: "name" },
                { displayName: "User Role", key: "user.role.name", default: "-" },
                { displayName: "Identification", key: "identification", default: "-" },
                { displayName: "Phone Number", key: "phone", default: "-" },
                { displayName: "Status", key: "status" },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/merchant/${match.params.id}/admin-users/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/merchant/${match.params.id}/admin-users/edit/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  }
                }
              ]}
            />
          </Row>


        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    authState: state.AuthState
  };
};

export default connect(mapStateToProps, null)(AdminUser);
