import React, { Component, Fragment } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

import { FormWrapper } from "../../../../components/boda-boda/Forms";
import { getUserDriverList } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";


class DriverViewModalUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { formState, row } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserDriverList.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserDriverList.url}/${row.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: get(response, "id", ""),
                            name: get(response, "name", ""),
                            email: get(response, "user.staffs[0].email", ""),
                            phone: get(response, "user.staffs[0].phone", ""),
                            identification: get(response, "identification", ""),
                            branch: get(response, "merchantBranch.name", ""),
                            category: get(response, "category", ""),
                            status: get(response, "status", ""),
                            deletedAt: get(response, "deletedAt", null),
                            licenseNo: get(response, "licenseNo", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Driver Details</h4>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-4"}>Driver ID</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.id`, "-")}</dt>

                                <dd className={"col-sm-4"}>Driver Type</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.category`, "-")}</dt>
                            </dl>
                        </div>
                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                {/* <dd className={"col-sm-4"}>User's Location</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.branch`, "-")}</dt> */}

                                <dd className={"col-sm-4"}>Status</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.status`, "")}</dt>
                            </dl>
                        </div>
                    </div>

                    <hr />

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Personal Details</h4>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-4"}>Name</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.name`, "")}</dt>

                                <dd className={"col-sm-4"}>Identification</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.identification`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-4"}>Driver's License No</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.licenseNo`, "")}</dt>
                            </dl>
                        </div>
                    </div>

                    <hr />

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Contact Details</h4>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-4"}>E-mail</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.email`, "")}</dt>
                                {get(formState, `${getUserDriverList.key}_view.deletedAt`) !== null ? (
                                    <Fragment>
                                        <dd className={"col-sm-4"}>Deleted At</dd>
                                        <dt className={"col-sm-8"}>{dateObjectToString(
                                            get(formState, `${getUserDriverList.key}_view.deletedAt`, ""),
                                            "YY-mm-dd HH:MM:II"
                                        )
                                        }</dt>
                                    </Fragment>

                                ) : null

                                }
                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-4"}>Phone</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getUserDriverList.key}_view.phone`, "")}</dt>
                            </dl>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(DriverViewModalUI);
