import React, { Component } from "react";
import { get } from "lodash";

import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    SubmitButton
} from "../../../../../components/boda-boda/Forms";
import { getCityListAPI, getFacilityAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { currency, deliveryCenterTypes } from "../../../../../config/boda-boda/db_config/db_status.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { formObjectValidationHelper, getDataByFormObject, isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


class CreateDeliveryCenter extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className={"row"}>
                    <div className={"col-md-12 p-0"}>
                        <FormWrapper
                            setGroupName={`${getFacilityAPI.key}_create`}
                            onDestroyUnsetFormObject={true}
                        >
                            <div className="row">

                                <div className="col-md-6">
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText="Name"
                                        placeHolderText={"Name"}
                                        inputName={"name"}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText="Code"
                                        placeHolderText={"Code"}
                                        inputName={"code"}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBoxWithState
                                        isRequired={true}
                                        labelText="Type"
                                        placeHolderText={"Type"}
                                        inputName={"type"}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        dataList={deliveryCenterTypes}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText={`Pick Up Cost (${currency})`}
                                        placeHolderText={`Pick Up Cost (${currency})`}
                                        inputName={"pickupCost"}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        inputType={"number"}
                                    />
                                </div>

                            </div>

                            <hr />

                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText="Location Name"
                                        placeHolderText={"Enter the Location Name"}
                                        inputStatePath={`${getFacilityAPI.key}_create.location.name`}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        maxLength={50}
                                    />
                                </div>

                                <div className={"col-md-6"}>
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText="Address"
                                        placeHolderText={"Enter the Shipping Address"}
                                        inputStatePath={`${getFacilityAPI.key}_create.location.address`}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        maxLength={250}
                                    />
                                </div>

                                <div className={"col-md-6"}>
                                    <InputBoxWithState
                                        isRequired={false}
                                        labelText="Postal Code"
                                        placeHolderText={"Enter the Postal Code"}
                                        inputStatePath={`${getFacilityAPI.key}_create.location.postalCode`}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        maxLength={10}
                                    />
                                </div>

                                <div className={"col-md-6"}>
                                    <InputBoxWithState
                                        isRequired={false}
                                        labelText="Plus Code"
                                        placeHolderText={"Enter the Google Plus Code"}
                                        inputStatePath={`${getFacilityAPI.key}_create.location.plusCode`}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        maxLength={10}
                                    />
                                </div>

                                <div className={"col-md-6"}>
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText="Latitude"
                                        placeHolderText={"Enter the Latitude"}
                                        // inputName={"lat"}
                                        inputStatePath={`${getFacilityAPI.key}_create.location.lat`}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        maxLength={12}
                                    />
                                </div>

                                <div className={"col-md-6"}>
                                    <InputBoxWithState
                                        isRequired={true}
                                        labelText="Longitude"
                                        placeHolderText={"Enter the Longitude"}
                                        // inputName={"lon"}
                                        inputStatePath={`${getFacilityAPI.key}_create.location.lon`}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        maxLength={13}
                                    />
                                </div>

                                <div className={"col-md-6"}>
                                    <SelectBoxWithState
                                        isRequired={true}
                                        labelText="City"
                                        inputStatePath={`${getFacilityAPI.key}_create.location.city.id`}
                                        placeHolderText={"Select a City"}
                                        formGroupName={`${getFacilityAPI.key}_create`}
                                        apiUrl={`${getCityListAPI.url}/all`}
                                        apiStateKey={getCityListAPI.key}
                                        keyName={"id"}
                                        valueName={"name"}
                                    />
                                </div>
                            </div>
                        </FormWrapper>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                onNextFn={this.props.onNextFn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const CreateBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Next"}
            formGroupName={`${getFacilityAPI.key}_create`}
            dataTableKey={getFacilityAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    code: "Code",
                    type: "Type",
                    status: "Status",
                    pickupCost: "Pick Up Cost",
                    "location.name": "Location Name",
                    "location.address": "Address",
                    "location.status": "Status",
                    "location.city.id": "City",
                    "location.lat": "Latitude",
                    "location.lon": "Longitude",
                },
                rules: {
                    name: "required",
                    status: "required",
                    type: "required",
                    code: "required",
                    pickupCost: "required|minAmount:0",
                    "location.name": "required",
                    "location.address": "required",
                    "location.city.id": "required",
                    "location.lat": "required|latitude",
                    "location.lon": "required|longitude",
                    "location.postalCode": "min:5",
                },
                message: {
                    "pickupCost.minAmount": "The Pick Up Cost should not be a minus value"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Delivery center has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: getFacilityAPI.url,
                    key: getFacilityAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let temp = {
                    ...data,
                    pickupCost: formObjectValidationHelper(data, "pickupCost", (data) => { return Number(data) }),
                    location: {
                        ...data.location,
                        default: true,
                        status: "ACTIVE",
                        lat: formObjectValidationHelper(data, "location.lat", (data) => { return Number(data) }),
                        lon: formObjectValidationHelper(data, "location.lon", (data) => { return Number(data) }),
                    },
                    status: "ACTIVE"
                }

                if (isEmptyValue(get(data, "location.postalCode", ""))) {
                    delete temp["location"]["postalCode"];
                }

                if (isEmptyValue(get(data, "location.plusCode", ""))) {
                    delete temp["location"]["plusCode"];
                }

                return temp;

                // if (!isEmptyValue(get(data, "lat", ""))) {
                //     temp["location"]["lat"] = Number(data.lat);
                // } else {
                //     temp["location"]["lat"] = undefined;
                // }
                //
                // if (!isEmptyValue(get(data, "lon", ""))) {
                //     temp["location"]["lon"] = Number(data.lon);
                // } else {
                //     temp["location"]["lon"] = undefined;
                // }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.onNextFn(result.data.id);
                }
            }}
        />
    )
}

export default CreateDeliveryCenter;
