import React, { Component, useEffect } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import JsBarcode from "jsbarcode";

import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { runSheetLineAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { printModalHelperFn } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


class LinehaulLabel extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {
        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <React.Fragment>
                <InputButton
                    btnText={"Print"}
                    onClickBtnFn={() => onClickFn()}
                    startIcon={"mdi mdi-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"LineHaul Runsheet"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<LinehaulPrint row={this.props.linehaul} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLinehaulBtn />
                    }
                />
            </React.Fragment>
        )
    }
}

const LinehaulPrint = (props) => {
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, `${runSheetLineAPI.key}_print._onLoad`, true) === false) {
            JsBarcode("#RunsheetId", get(props, 'row.code', ""), {
                width: 1,
                height: 40,
                margin: 0,
                fontSize: 15
            });

            JsBarcode(".collectionID").init();
        }
    }, [
        get(formState, `${runSheetLineAPI.key}_print._onLoad`, true)
    ])

    return (
        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                <FormWrapper
                    setGroupName={`${runSheetLineAPI.key}_print`}
                    onDestroyUnsetFormObject={true}
                >
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Runsheet Code</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.code`, "")}</dt>

                                <dd className={"col-sm-5"}></dd>
                                <dt className={"col-sm-7"}><img id="RunsheetId" /></dt>

                                <dd className={"col-sm-5 mt-3"}>Runsheet Name</dd>
                                <dt className={"col-sm-7 mt-3"}>{get(props, `row.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Date</dd>
                                <dt className={"col-sm-7"}>
                                    {dateObjectToString(get(props, `row.runSheetAt`, ""), "dd-mm-YY")}
                                </dt>

                                <dd className={"col-sm-5"}>Assigned By</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.creator`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Vehicle No</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.vehiclePlate`, "")}</dt>

                                <dd className={"col-sm-5"}>Driver ID</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.driver[0].id`, "")}</dt>

                                <dd className={"col-sm-5"}>Driver Name</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.driver[0].name`, "")}</dt>

                                <dd className={"col-sm-5"}>Route ID</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.routeLine[0].id`, "")}</dt>

                                <dd className={"col-sm-5"}>Route Name</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.routeLine[0].name`, "")}</dt>

                                <dd className={"col-sm-5"}>Address</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.facility.location.address`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                            <label>Waypoints</label>
                            {
                                <ul style={{ paddingLeft: "17px" }}>
                                    {
                                        get(props, `row.locations`, []).map((element, index) => {
                                            return (
                                                <li key={index}>{element.location.name}</li>
                                            );
                                        })
                                    }
                                </ul>
                            }
                        </div>

                        <div className="col-sm-6">
                            <label>Notes</label>
                            {
                                get(props, `row.notes`, []).length > 0 ? (
                                    <ul style={{ paddingLeft: "17px" }}>
                                        {
                                            get(props, `row.notes`, []).map((element, index) => {
                                                return (
                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                );
                                            })
                                        }
                                    </ul>
                                ) : (
                                    <div>None</div>
                                )
                            }
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card mb-1">
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-sm-6">
                                                    <h4 className="card-title">Collections</h4>
                                                </div>
                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-sm-6"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-5"}>No of Collections</dd>
                                                        <dt className={"col-sm-2"}>{get(props, `row.manifests`, []).length}</dt>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div className="table-rep-plugin">
                                                <div
                                                    className="table-responsive mb-0"
                                                    data-pattern="priority-columns"
                                                >
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Collection Code</th>
                                                                <th>Destination Delivery Center</th>
                                                                <th>No of Packages</th>
                                                                {/*<th>Notes</th>*/}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                get(props, `row.manifests`, []).length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan={3} className={"text-center"}>No collections found</td>
                                                                    </tr>
                                                                ) : (
                                                                    get(props, `row.manifests`, []).map((bodyData, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <svg
                                                                                        className="collectionID"
                                                                                        jsbarcode-value={get(bodyData, "code", "")}
                                                                                        jsbarcode-width="1"
                                                                                        jsbarcode-height="35"
                                                                                        jsbarcode-margin="0"
                                                                                        jsbarcode-fontsize="15"
                                                                                    />
                                                                                </td>
                                                                                <td>{get(bodyData, "locationDrop.name", "")}</td>
                                                                                <td>{get(bodyData, "publishedPackages", "").length}</td>
                                                                                {/*<td>*/}
                                                                                {/*    {*/}
                                                                                {/*        get(bodyData, `notes`, []).length > 0 ? (*/}
                                                                                {/*            <ul style={{paddingLeft: "17px"}}>*/}
                                                                                {/*                {*/}
                                                                                {/*                    get(bodyData, `notes`, []).map((element, index) => {*/}
                                                                                {/*                        return (*/}
                                                                                {/*                            <li key={index}>{element.note}*/}
                                                                                {/*                                <span*/}
                                                                                {/*                                    style={{fontWeight: "500"}}> By {element.name}</span>*/}
                                                                                {/*                            </li>*/}
                                                                                {/*                        );*/}
                                                                                {/*                    })*/}
                                                                                {/*                }*/}
                                                                                {/*            </ul>*/}
                                                                                {/*        ) : (*/}
                                                                                {/*            <div>-</div>*/}
                                                                                {/*        )*/}
                                                                                {/*    }*/}
                                                                                {/*</td>*/}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>
            </div>
        </div>
    )
}

const PrintLinehaulBtn = () => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => printModalHelperFn("print_preview")}
        />
    )
}

export {
    PrintLinehaulBtn,
    LinehaulPrint,
    LinehaulLabel
}

