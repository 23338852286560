import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    FormWrapper, DatePickerBoxWithState, InputBoxWithState,
    InputButtonWithState, SelectBoxWithState
} from "../../../../../components/boda-boda/Forms";
import {
    runSheetLineAPI, getUserDriverList, getManifestListAPI,getLinehaulAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../../store/boda-boda/form/actions";
import RouteFilterUI from "./RouteFilterUI";
import DriverFilterUI from "./DriverFilterUI";
import { editRunSheetLine, addCollectionsToRunSheetLine } from "../../../../../store/boda-boda/transaction_request/actions";
import { runSheetLineStatuses } from "../../../../../config/boda-boda/db_config/db_status.config";
import { setFlashMessage } from "../../../../../store/boda-boda/ui/actions";
import DriverViewModalUI from "../../../driver/common-includes/DriverViewModalUI";
import CollectionViewModelUI from "./CollectionViewModelUI";
import CollectionFilterUI from "./CollectionFilterUI";
import RouteViewModelUI from "./RouteViewModelUI";
import { isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


class EditRunsheetLine extends Component {

    addCollection = (row) => {
        let { formState, match, addCollectionsToRunSheetLine, setFlashMessage } = this.props;

        if (get(formState, `${runSheetLineAPI.key}_edit.driver`, null) === null) {
            setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger"
            })
        }
         else {
            const body = {
                "runSheetLine": {
                    "id": Number(match.params.id)
                },
                "driver": {
                    "id": Number(get(formState, `${runSheetLineAPI.key}_edit.driver.id`))
                }
            }

            const flashMsjObj = {
                status: true,
                message: "Collection has successfully added",
                type: "success"
            };

            addCollectionsToRunSheetLine(row.id, body, flashMsjObj);
        }
    }
    addRoute = (row) => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`,"selectedRoute", "", [row] );
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`, "routeLine", "", row);
    }
    deleteRoute = () => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`,"selectedRoute", "", [] );
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`,"routeLine", "", null );
    }
    deleteCollection = (row) => {
        const body = {
            "runSheetLine": null,
            "driver": null
        }

        const flashMsjObj = {
            status: true,
            message: "Collection has successfully removed",
            type: "success"
        };

        this.props.addCollectionsToRunSheetLine(row.id, body, flashMsjObj);
    }

    addDriver = (row) => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`, "selectedDriver", "", [row]);
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`, "driver", "", row);
    }

    deleteDriver = () => {
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`, "selectedDriver", "", []);
        this.props.changeInputFn(`${runSheetLineAPI.key}_edit`, "driver", "", null);
    }

    render() {
        let { formState, history, editRunSheetLine, match, authState } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${runSheetLineAPI.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${runSheetLineAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code: response.code,
                            driver: response.driver,
                            name: response.name,
                            routeLine: response.routeLine,
                            runSheetAt: new Date(response.runSheetAt),
                            status: response.status,
                            vehiclePlate: response.vehiclePlate,
                            notes: response.notes === null ? [] : response.notes,
                            selectedDriver: [response.driver],
                            selectedRoute: [response.routeLine],
                            creator: get(response, "creator.staffs[0].name", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Edit Runsheet</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <InputButtonWithState
                                        elementWrapperStyle={"mr-2"}
                                        btnText={"Save"}
                                        startIcon={"mdi mdi-content-save-all-outline"}
                                        formGroupName={`${runSheetLineAPI.key}_edit`}
                                        onClickBtnFn={(dataObject) => {
                                            editRunSheetLine({
                                                ...dataObject.formObject,
                                                history: history,
                                                authUser: {
                                                    id: get(authState, "authUser.id"),
                                                    name: get(authState, "authUser.role.name")
                                                }
                                            })
                                        }}
                                    />

                                    <BackBtnComponent
                                        path={"/runsheets-line"}
                                        btnName={"Cancel"}
                                        icon={"far fa-times-circle"}
                                        btnColor={"danger"}
                                    />
                                </Fragment>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Runsheet Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_edit.code`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Assigned By</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_edit.creator`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Runsheet Name"
                                                placeHolderText={"Runsheet Name"}
                                                inputName={"name"}
                                                formGroupName={`${runSheetLineAPI.key}_edit`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                labelText={"Date"}
                                                isRequired={true}
                                                inputName={"runSheetAt"}
                                                formGroupName={`${runSheetLineAPI.key}_edit`}
                                                minDate={new Date()}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Vehicle Number"
                                                placeHolderText={"Vehicle Number"}
                                                inputName={"vehiclePlate"}
                                                formGroupName={`${runSheetLineAPI.key}_edit`}
                                                isRequired={true}
                                                maxLength={50}
                                            />
                                        </div>

                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Notes</label>
                                            {
                                                get(formState, `${runSheetLineAPI.key}_edit.notes`, []).length > 0 ? (
                                                    <ul style={{ paddingLeft: "17px" }}>
                                                        {
                                                            get(formState, `${runSheetLineAPI.key}_edit.notes`, []).map((element, index) => {
                                                                return (
                                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                ) : (
                                                    <div>None</div>
                                                )
                                            }
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="New Note"
                                                inputName={"note"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={`${runSheetLineAPI.key}_edit`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Collections</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Collections"
                                            apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=COLLECTION&runSheetLineId=null&locationCurrentId=${get(formState, `${runSheetLineAPI.key}_edit.routeLine.facility.locationId`, "")}&status=PUBLISH`}
                                            dataTableStateKey={`${getManifestListAPI.key}_runsheetLine`}
                                            filterFromComponent={CollectionFilterUI}
                                            defaultFilterFormObject={{
                                                code: "",
                                                facilityId: ""
                                            }}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Collection Code", key: "code" },
                                                { displayName: "Destination Delivery Center", key: "locationDrop.name" },
                                                { displayName: "No of Packages", key: "packages", onPrintFn: (rawData) => {
                                                    if (get(rawData, "publishedPackages", "") === null) {
                                                        return 0;
                                                    } else {
                                                        return get(rawData, "publishedPackages", []).length;
                                                    }
                                                }},
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Collection",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: CollectionViewModelUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (row) => this.addCollection(row)
                                                    },
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Collections"
                                            apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=COLLECTION&runSheetLineId=${match.params.id}`}
                                            dataTableStateKey={`${getManifestListAPI.key}_runsheetLine_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isSetAction={true}
                                            tableHeaderList={[
                                                { displayName: "Collection Code", key: "code" },
                                                { displayName: "Destination Delivery Center", key: "locationDrop.name" },
                                                { displayName: "No of Packages", key: "packages", onPrintFn: (rawData) => {
                                                    if (get(rawData, "publishedPackages", "") === null) {
                                                        return 0;
                                                    } else {
                                                        return get(rawData, "publishedPackages", []).length;
                                                    }
                                                }},
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Collection",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: CollectionViewModelUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (row) => this.deleteCollection(row)
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                     
                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Route</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Routes"
                                            apiURL={`${getLinehaulAPI.url}?page=1&limit=20&status=ACTIVE&facilityId=${get(authState, "authUser.facility.id", "")}`}
                                            dataTableStateKey={`${getLinehaulAPI.key}_runsheetLine`}
                                            filterFromComponent={RouteFilterUI}
                                            defaultFilterFormObject={{
                                                name: "",
                                                waypoint: ""
                                            }}
                                            onRebuildFilterObjFn={(filterData) => {
                                                const data = filterData;

                                                if (!isEmptyValue(filterData.waypoint)) {
                                                    data["cityIds"] = `[${filterData.waypoint}]`;
                                                }

                                                delete data["waypoint"];
                                                return data;
                                            }}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Route ID", key: "id" },
                                                { displayName: "Route Name", key: "name" },
                                                { displayName: "Type", key: "type", default: "LineHaul" },
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Route",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: RouteViewModelUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (row) => this.addRoute(row)
                                                    },
                                                    checkBeforeActionRenderFn: (rawData) => {
                                                        return get(formState, `${runSheetLineAPI.key}_edit.selectedRoute`, []).length === 0;
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Route"
                                            dataTableStateKey={`${getLinehaulAPI.key}_runsheetLine_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isSetAction={true}
                                            isPagingShow={false}
                                            tableHeaderList={[
                                                { displayName: "Route ID", key: "id" },
                                                { displayName: "Route Name", key: "name" },
                                                { displayName: "Type", key: "type", default: "LineHaul" },
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            tableBodyList={get(formState, `${runSheetLineAPI.key}_edit.selectedRoute`, [])}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Route",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: RouteViewModelUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.deleteRoute()
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attach Driver</h4>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Drivers"
                                            apiURL={`${getUserDriverList.url}?page=1&limit=20&category=LINE&status=ACTIVE`}
                                            dataTableStateKey={`${getUserDriverList.key}_runsheetLine`}
                                            filterFromComponent={DriverFilterUI}
                                            defaultFilterFormObject={{
                                                name: "",
                                                identification: "",
                                                phone: ""
                                            }}
                                            isSetCreateAction={false}
                                            tableHeaderList={[
                                                { displayName: "Driver Id", key: "id" },
                                                { displayName: "Name", key: "name" },
                                                { displayName: "Type", key: "category" },
                                                { displayName: "Identification", key: "identification", default: "-" },
                                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "")},
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Driver",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: DriverViewModalUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.create,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.addDriver(data)
                                                    },
                                                    checkBeforeActionRenderFn: (rawData) => {
                                                        return get(formState, `${runSheetLineAPI.key}_edit.selectedDriver`, []).length === 0;
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>

                                    <div className={"row"}>
                                        <CrudComponent
                                            title="Selected Driver"
                                            dataTableStateKey={`${getUserDriverList.key}_runsheetLine_selected`}
                                            isFilter={false}
                                            isSetCreateAction={false}
                                            isPagingShow={false}
                                            isSetAction={true}
                                            tableHeaderList={[
                                                { displayName: "Driver Id", key: "id" },
                                                { displayName: "Name", key: "name" },
                                                { displayName: "Type", key: "category" },
                                                { displayName: "Identification", key: "identification", default: "-" },
                                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "")},
                                                { displayName: "Status", key: "status" },
                                            ]}
                                            tableBodyList={get(formState, `${runSheetLineAPI.key}_edit.selectedDriver`, [])}
                                            actionList={[
                                                {
                                                    actionType: actionBtnTypes.view,
                                                    isSetModel: true,
                                                    modalProps: {
                                                        modalTitle: "View Driver",
                                                        modalType: "modal-lg",
                                                        modalBodyComponent: DriverViewModalUI,
                                                        isSetClose: true,
                                                        isSetFooterClose: true,
                                                    }
                                                },
                                                {
                                                    actionType: actionBtnTypes.delete,
                                                    isSetModel: false,
                                                    actionBtn: {
                                                        onClickBtnFn: (data) => this.deleteDriver()
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    editRunSheetLine,
    addCollectionsToRunSheetLine,
    setFlashMessage
})(EditRunsheetLine);