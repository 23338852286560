import React, { Fragment, memo } from "react";
import { FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { formCacheLevel } from "../../../config/boda-boda/template.config";


const emptyFn = (...para) => undefined;

const DatePickerBox = ({
   dateFormat="MM/dd/yyyy",
   elementWrapperStyle = "",
   elementStyle = "",
   labelText = "",
   isRequired = false,
   inputName = "",
   inputValue = new Date(),
   minDate = null,
   maxDate = null,
   placeholderText = "",
   inputError = "",
   helperText = "",
   isDisabled = false,
   monthYearPicker= false,
   onChangeFn = emptyFn,
}) => {
    return (
        <Fragment>
            <FormGroup className={`${elementWrapperStyle}`}>
                <Label className={`${inputError ? "selectBoxLabelStyle" : ""}`}>{labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}</Label>
                <div className={"reactDatePickerWrapper"}>
                    <DatePicker
                        selected={inputValue}
                        className={`form-control defaultDatePickerStyle ${elementStyle} ${inputError ? "is-touched is-pristine av-invalid is-invalid" : ""}`}
                        name={inputName}
                        disabled={isDisabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        placeholderText={placeholderText}
                        showMonthYearPicker={monthYearPicker}
                        dateFormat={dateFormat}
                        onChange={(event) => {
                            onChangeFn({
                                value: event,
                                name: inputName,
                            })
                        }}
                    />
                </div>
                <small className={`${inputError ? "helperTextErrorStyle" : ""}`}>
                    {inputError ? inputError : helperText}
                </small>
            </FormGroup>
        </Fragment>
    )
}

const areEqual = (prevProps, nextProps) => {
    if (nextProps.cacheLevel===formCacheLevel.none) {
        return false;
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.inputType===nextProps.inputType) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) &&
            (prevProps.isRequired===nextProps.isRequired) && (prevProps.inputValue===nextProps.inputValue) &&
            (prevProps.updateStatus===nextProps.updateStatus)
        )
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.inputType===nextProps.inputType) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) &&
            (prevProps.isRequired===nextProps.isRequired) && (prevProps.inputValue===nextProps.inputValue)
        )
    }
}

const DatePickerBoxMemo = memo(DatePickerBox, areEqual);

export {
    DatePickerBox,
    DatePickerBoxMemo
}
