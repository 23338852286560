import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    SubmitButton
} from "../../../../components/boda-boda/Forms";
import {
    getFacilityAPI, getCityListAPI, facilityContactAPI, getZoneAPI
} from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { currency, deliveryCenterTypes, userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject, isEmptyValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import ExpansionPanel from "../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { actionBtnTypes } from "../../../../components/boda-boda/CRUD/configs/crud.config";
import { RemoveMessage, RemoveBtn } from "./update-includes/ContactDeleteUI";
import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { CreateContactBtn, CreateContact } from "./update-includes/CreateContactDetails";
import { FilterUI } from "./update-includes/ZoneFilterUI";
import { addZones } from "../../../../store/boda-boda/transaction_request/actions";


class EditUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            centerPanel: true,
            zonePanel: false,
        }
    }

    addZone = (row) => {
        const body = {
            "facility": {
                "id": Number(this.props.match.params.id)
            }
        }

        const flashMsjObj = {
            status: true,
            message: "Zone has successfully added",
            type: "success"
        };

        this.props.addZones(row.id, body, flashMsjObj);
    }

    deleteZone = (row) => {
        const body = {
            "facility": null
        }

        const flashMsjObj = {
            status: true,
            message: "Zone has successfully removed",
            type: "success"
        };

        this.props.addZones(row.id, body, flashMsjObj);
    }

    render() {
        let { history, match } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getFacilityAPI.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getFacilityAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            code: response.code,
                            pickupCost: response.pickupCost,
                            status: response.status,
                            type: response.type,
                            // lat: get(response, "location.lat", ""),
                            // lon: get(response, "location.lon", ""),
                            location: {
                                name: get(response, "location.name", ""),
                                address: get(response, "location.address", ""),
                                postalCode: get(response, "location.postalCode", ""),
                                plusCode: get(response, "location.plusCode", ""),
                                lat: get(response, "location.lat", ""),
                                lon: get(response, "location.lon", ""),
                                status: get(response, "location.status", ""),
                                default: get(response, "location.default", ""),
                                city: {
                                    id: get(response, "location.cityId", ""),
                                }
                            },
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Edit Delivery Center</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <EditBtn
                                    id={match.params.id}
                                    history={history}
                                />

                                <BackBtnComponent
                                    elementWrapperStyle={""}
                                    path={"/delivery-centers"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <ExpansionPanel
                        title={"Delivery Center Details"}
                        isOpen={this.state.centerPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                centerPanel: !prevState.centerPanel
                            }
                        })}
                    >
                        <div className="row">

                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Name"
                                    placeHolderText={"Name"}
                                    inputName={"name"}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                />
                            </div>

                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Code"
                                    placeHolderText={"Code"}
                                    inputName={"code"}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                />
                            </div>

                            <div className="col-md-6">
                                <SelectBoxWithState
                                    isRequired={true}
                                    labelText="Type"
                                    placeHolderText={"Type"}
                                    inputName={"type"}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    dataList={deliveryCenterTypes}
                                />
                            </div>

                            <div className="col-md-6">
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText={`Pick Up Cost (${currency})`}
                                    placeHolderText={`Pick Up Cost (${currency})`}
                                    inputName={"pickupCost"}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    inputType={"number"}
                                />
                            </div>

                            <div className="col-md-6">
                                <SelectBoxWithState
                                    isRequired={true}
                                    labelText="Status"
                                    placeHolderText={"Status"}
                                    inputName={"status"}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    dataList={userStatusList}
                                />
                            </div>

                        </div>

                        <hr />

                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Location Name"
                                    placeHolderText={"Enter the Location Name"}
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.name`}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    maxLength={50}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Address"
                                    placeHolderText={"Enter the Shipping Address"}
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.address`}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    maxLength={250}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={false}
                                    labelText="Postal Code"
                                    placeHolderText={"Enter the Postal Code"}
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.postalCode`}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    maxLength={10}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={false}
                                    labelText="Plus Code"
                                    placeHolderText={"Enter the Google Plus Code"}
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.plusCode`}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    maxLength={10}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Latitude"
                                    placeHolderText={"Enter the Latitude"}
                                    // inputName={"lat"}
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.lat`}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    maxLength={12}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <InputBoxWithState
                                    isRequired={true}
                                    labelText="Longitude"
                                    placeHolderText={"Enter the Longitude"}
                                    // inputName={"lon"}
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.lon`}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    maxLength={13}
                                />
                            </div>

                            <div className={"col-md-6"}>
                                <SelectBoxWithState
                                    isRequired={true}
                                    labelText="City"
                                    inputStatePath={`${getFacilityAPI.key}_edit.location.city.id`}
                                    placeHolderText={"Select a City"}
                                    formGroupName={`${getFacilityAPI.key}_edit`}
                                    apiUrl={`${getCityListAPI.url}/all`}
                                    apiStateKey={getCityListAPI.key}
                                    keyName={"id"}
                                    valueName={"name"}
                                />
                            </div>
                        </div>

                    </ExpansionPanel>

                    <div className={"row"}>
                        <CrudComponent
                            title={"Contact Details"}
                            apiURL={`${facilityContactAPI.url}?page=1&limit=20&facilityId=${match.params.id}`}
                            dataTableStateKey={facilityContactAPI.key}
                            isSetAction={true}
                            isFilter={false}
                            isSetCreateAction={true}
                            createActionProps={{
                                actionType: actionBtnTypes.create,
                                isSetModel: true,
                                actionBtn: {
                                    btnText: "Add New"
                                },
                                modalProps: {
                                    modalTitle: "Add Contact",
                                    modalBodyComponent: CreateContact,
                                    modalFooterComponent: CreateContactBtn,
                                    isSetClose: true,
                                    isSetFooterClose: true,
                                    parentToModelProps: {
                                        facilityId: match.params.id,
                                    }
                                }
                            }}
                            tableHeaderList={[
                                { displayName: "Contact Person", key: "name" },
                                { displayName: "Phone", key: "phone" },
                                { displayName: "Email", key: "email" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: RemoveMessage,
                                        modalFooterComponent: RemoveBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                }
                            ]}
                        />
                    </div>

                    <ExpansionPanel
                        title={"Zones"}
                        isOpen={this.state.zonePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                zonePanel: !prevState.zonePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <CrudComponent
                                title="Zone List"
                                apiURL={`${getZoneAPI.url}?page=1&limit=20&facilityId=null`}
                                dataTableStateKey={`${getZoneAPI.key}_null`}
                                isFilter={true}
                                filterFromComponent={FilterUI}
                                isSetAction={true}
                                isSetCreateAction={false}
                                tableHeaderList={[
                                    { displayName: "Zone ID", key: "id" },
                                    { displayName: "Zone Name", key: "name" },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (rawData) => history.push(`/delivery-centers/zones/view/${match.params.id}`),
                                        }

                                    },
                                    {
                                        actionType: actionBtnTypes.create,
                                        isSetModel: false,
                                        actionBtn: {
                                            btnText: "Add",
                                            tooltip: "Add",
                                            onClickBtnFn: (data) => this.addZone(data)
                                        }
                                    }
                                ]}
                            />
                        </div>

                        <div className={"row mt-3"}>
                            <CrudComponent
                                title="Selected Zones"
                                apiURL={`${getZoneAPI.url}?page=1&limit=20&facilityId=${match.params.id}`}
                                dataTableStateKey={`${getZoneAPI.key}_selected`}
                                isFilter={false}
                                isSetAction={true}
                                isSetCreateAction={false}
                                tableHeaderList={[
                                    { displayName: "Zone ID", key: "id" },
                                    { displayName: "Zone Name", key: "name" },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (rawData) => history.push(`/delivery-centers/zones/view/${match.params.id}`),
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.delete,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (data) => this.deleteZone(data)
                                        }
                                    },
                                ]}
                            />
                        </div>
                    </ExpansionPanel>

                </FormWrapper>
            </div>
        )
    }
}

const EditBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getFacilityAPI.key}_edit`}
            dataTableKey={getFacilityAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    code: "Code",
                    type: "Type",
                    status: "Status",
                    pickupCost: "Pick Up Cost",
                    "location.name": "Location Name",
                    "location.address": "Address",
                    "location.status": "Status",
                    "location.city.id": "City",
                    "location.lat": "Latitude",
                    "location.lon": "Longitude",
                },
                rules: {
                    name: "required",
                    status: "required",
                    type: "required",
                    code: "required",
                    pickupCost: "required|minAmount:0",
                    "location.name": "required",
                    "location.address": "required",
                    "location.city.id": "required",
                    "location.postalCode": "min:5",
                    "location.lat": "required|latitude",
                    "location.lon": "required|longitude",
                },
                message: {
                    "pickupCost.minAmount": "The Pick Up Cost should not be a minus value"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Delivery center has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getFacilityAPI.url}/${props.id}`,
                    key: getFacilityAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let temp = {
                    ...data,
                    pickupCost: formObjectValidationHelper(data, "pickupCost", (data) => { return Number(data) }),
                    location: {
                        ...data.location,
                        lat: formObjectValidationHelper(data, "location.lat", (data) => { return Number(data) }),
                        lon: formObjectValidationHelper(data, "location.lon", (data) => { return Number(data) })
                    }
                }

                if (isEmptyValue(get(data, "location.postalCode", ""))) {
                    delete temp["location"]["postalCode"];
                }

                if (isEmptyValue(get(data, "location.plusCode", ""))) {
                    delete temp["location"]["plusCode"];
                }

                return temp;

                // if (!isEmptyValue(get(data, "lat", ""))) {
                //     temp["location"]["lat"] = Number(data.lat);
                // } else {
                //     temp["location"]["lat"] = undefined;
                // }
                //
                // if (!isEmptyValue(get(data, "lon", ""))) {
                //     temp["location"]["lon"] = Number(data.lon);
                // } else {
                //     temp["location"]["lon"] = undefined;
                // }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.history.push("/delivery-centers");
                }
            }}
        />
    )
}

const DeliveryCenterEdit = connect(null, { addZones })(EditUI);

export {
    DeliveryCenterEdit
}
