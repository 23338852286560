import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { FormWrapper, SelectBoxWithState, SubmitButton } from "../../../../../components/boda-boda/Forms";
import InputBoxWithState from "../../../../../components/boda-boda/Forms/InputBoxWithState";
import DatePickerBoxWithState from "../../../../../components/boda-boda/Forms/DatePickerBoxWithState";
import { connect } from "react-redux";
import { BackBtnComponent, CustomLoader } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { get } from "lodash";
import {
    chargingType,
    contractExpiry, contractTypes, currency,
    deliveryMethods, chargingMethods
} from "../../../../../config/boda-boda/db_config/db_status.config";
import { deliveryChargeManagementAPI, getContractAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject
} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { changeInputFn, setFlashMessage } from "../../../../../store/actions";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";

class CreateUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryCharge: "fetching",
            cashCharging: null,
            cardCharging: null
        }
    }


    cashDeliveryTypeChange = (eventData) => {
        let { changeInputFn } = this.props;

        const FEE = (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "FEE") ? get(this.state, "deliveryCharge.cashDeliveryBase", undefined) : 0;
        const PERCENTAGE = (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "PERCENTAGE") ? get(this.state, "deliveryCharge.cashDeliveryBase", undefined) : 0;

        if (eventData.value === "FEE") {
            changeInputFn(`${getContractAPI.key}_create`, "cashDelivery", "", FEE);
            this.setState({
                ...this.state,
                cashCharging: currency
            })
        } else if (eventData.value === "PERCENTAGE") {
            changeInputFn(`${getContractAPI.key}_create`, "cashDelivery", "", PERCENTAGE);
            this.setState({
                ...this.state,
                cashCharging: "%"
            })
        }
    }

    cardDeliveryTypeChange = (eventData) => {
        let { changeInputFn } = this.props;

        const FEE = (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "FEE") ? get(this.state, "deliveryCharge.cardDeliveryBase", undefined) : 0;
        const PERCENTAGE = (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "PERCENTAGE") ? get(this.state, "deliveryCharge.cardDeliveryBase", undefined) : 0;

        if (eventData.value === "FEE") {
            changeInputFn(`${getContractAPI.key}_create`, "cardDelivery", "", FEE);
            this.setState({
                ...this.state,
                cardCharging: currency
            })
        } else if (eventData.value === "PERCENTAGE") {
            changeInputFn(`${getContractAPI.key}_create`, "cardDelivery", "", PERCENTAGE);
            this.setState({
                ...this.state,
                cardCharging: "%"
            })
        }
    }

    componentDidMount() {
        this.getDeliveryCharge();
    }

    getDeliveryCharge = async () => {
        let { setFlashMessage } = this.props;

        const deliveryChargeResponse = await callApi(`${deliveryChargeManagementAPI.url}/all?status=ACTIVE`)
            .headers(true)
            .method("get")
            .send();

        if (deliveryChargeResponse._statue === true) {
            this.setState({
                ...this.state,
                deliveryCharge: get(deliveryChargeResponse, "data.data", [])[0]
            })

            if (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "FEE") {
                this.setState({
                    ...this.state,
                    cashCharging: currency
                })
            } else if (get(this.state, "deliveryCharge.cashChargingTypeBaseType", "") === "PERCENTAGE") {
                this.setState({
                    ...this.state,
                    cashCharging: "%"
                })
            }

            if (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "FEE") {
                this.setState({
                    ...this.state,
                    cardCharging: currency
                })
            } else if (get(this.state, "deliveryCharge.cardChargingTypeBaseType", "") === "PERCENTAGE") {
                this.setState({
                    ...this.state,
                    cardCharging: "%"
                })
            }
        } else {
            if (get(deliveryChargeResponse, "data.statusCode", 0) === 403) {
                setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger"
                })
            }
        }
    }

    render() {
        let { coreState, history, match } = this.props;

        let minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create Contract</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                                coreState={coreState}
                                id={match.params.merchantId}
                            />

                            <BackBtnComponent
                                path={`/merchant/${match.params.merchantId}/contract`}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                <React.Fragment>
                    {
                        (this.state.deliveryCharge === "fetching") ? (
                            <center>
                                <CustomLoader />
                            </center>
                        ) : (
                            <Row>
                                <Col sm={12}>
                                    <FormWrapper
                                        setGroupName={`${getContractAPI.key}_create`}
                                        setFormObject={{
                                            expressMultiplier: get(this.state, "deliveryCharge.expressBaseMultiplier", undefined),
                                            xlPackageHandling: get(this.state, "deliveryCharge.xlPackageHandlingBase", undefined),
                                            cashChargingType: get(this.state, "deliveryCharge.cashChargingTypeBaseType", ""),
                                            cashDelivery: get(this.state, "deliveryCharge.cashDeliveryBase", undefined),
                                            cardChargingType: get(this.state, "deliveryCharge.cardChargingTypeBaseType", ""),
                                            cardDelivery: get(this.state, "deliveryCharge.cardDeliveryBase", undefined),
                                            pricingModelType: get(this.state, "deliveryCharge.pricingModelType", "TYPE_ONE"),
                                            chargingMethod: get(this.state, "deliveryCharge.chargingMethod", "VOLUMETRIC")
                                        }}
                                    >
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Contract Type"
                                                            inputName={"pricingModelType"}
                                                            placeHolderText={"Contract Type"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            dataList={contractTypes}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Contract Name"
                                                            placeHolderText={"Contract Name"}
                                                            inputName={"name"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <DatePickerBoxWithState
                                                            isRequired={true}
                                                            minDate={minDate}
                                                            inputValue={null}
                                                            placeholderText={"Contract Effective From"}
                                                            labelText={"Contract Effective From"}
                                                            inputName={"start"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={false}
                                                            labelText={"Contract Effective Period (Years)"}
                                                            placeHolderText={"Contract Effective Period"}
                                                            inputName={"end"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>

                                                    {
                                                        (get(this.props.formState, `${getContractAPI.key}_create.pricingModelType`, '') === "TYPE_TWO") ? (
                                                            <Fragment>
                                                                <div className="col-md-6">
                                                                    <InputBoxWithState
                                                                        isRequired={true}
                                                                        labelText={"Flat Rate"}
                                                                        placeHolderText={"Flat Rate"}
                                                                        inputName={"flatRate"}
                                                                        formGroupName={`${getContractAPI.key}_create`}
                                                                        inputType={"number"}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <InputBoxWithState
                                                                        isRequired={true}
                                                                        labelText={"Maximum Number of Packages"}
                                                                        placeHolderText={"Maximum Number of Packages"}
                                                                        inputName={"maxPackageCount"}
                                                                        formGroupName={`${getContractAPI.key}_create`}
                                                                        inputType={"number"}
                                                                    />
                                                                </div>
                                                            </Fragment>
                                                        ) : (null)
                                                    }

                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            labelText={"Express Delivery Charge Multiplier"}
                                                            placeHolderText={"Express Delivery Charge Multiplier"}
                                                            inputName={"expressMultiplier"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            labelText={"XL Package Handling Fee"}
                                                            placeHolderText={"XL Package Handling Fee"}
                                                            inputName={"xlPackageHandling"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={"Discount (%)"}
                                                            placeHolderText={"Discount"}
                                                            inputName={"discount"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            inputType={"number"}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Upon Contract Expiry"
                                                            placeHolderText={"Upon Contract Expiry"}
                                                            inputName={"continuation"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            dataList={contractExpiry}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={"Settlement Period (Days)"}
                                                            placeHolderText={"Settlement Period"}
                                                            inputName={"settlementPeriod"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Cash on Delivery"
                                                            inputName={"cashOnDelivery"}
                                                            placeHolderText={"Cash on Delivery"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            dataList={deliveryMethods}
                                                        />
                                                    </div>

                                                    {/* <div className="col-md-6"></div> */}

                                                    {
                                                        (get(this.props.formState, `${getContractAPI.key}_create.cashOnDelivery`, '') === "ENABLED") ? (
                                                            <Fragment>
                                                                <div className="col-md-6">
                                                                    <SelectBoxWithState
                                                                        labelText="Cash Charging Type"
                                                                        inputName={"cashChargingType"}
                                                                        placeHolderText={"Cash Charging Type"}
                                                                        formGroupName={`${getContractAPI.key}_create`}
                                                                        dataList={chargingType}
                                                                        onChangeFn={(eventData) => this.cashDeliveryTypeChange(eventData)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <InputBoxWithState
                                                                        labelText={`Cash Charging Value (${this.state.cashCharging})`}
                                                                        placeHolderText={"Cash Charging Value"}
                                                                        inputName={"cashDelivery"}
                                                                        formGroupName={`${getContractAPI.key}_create`}
                                                                        inputType={"number"}
                                                                    />
                                                                </div>
                                                            </Fragment>
                                                        ) : null
                                                    }

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Card on Delivery"
                                                            placeHolderText={"Card on Delivery"}
                                                            inputName={"cardOnDelivery"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            dataList={deliveryMethods}
                                                        />
                                                    </div>


                                                    {
                                                        (get(this.props.formState, `${getContractAPI.key}_create.cardOnDelivery`, '') === "ENABLED") ? (
                                                            <Fragment>
                                                                <div className="col-md-6">
                                                                    <SelectBoxWithState
                                                                        labelText="Card Charging Type"
                                                                        inputName={"cardChargingType"}
                                                                        placeHolderText={"Card Charging Type"}
                                                                        formGroupName={`${getContractAPI.key}_create`}
                                                                        dataList={chargingType}
                                                                        onChangeFn={(eventData) => this.cardDeliveryTypeChange(eventData)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <InputBoxWithState
                                                                        labelText={`Card Charging Value  (${this.state.cardCharging})`}
                                                                        placeHolderText={`Card Charging Value`}
                                                                        inputName={"cardDelivery"}
                                                                        formGroupName={`${getContractAPI.key}_create`}
                                                                        inputType={"number"}
                                                                    />
                                                                </div>
                                                            </Fragment>
                                                        ) : null
                                                    }


                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Charging Method"
                                                            inputName={"chargingMethod"}
                                                            placeHolderText={"Charging Method"}
                                                            formGroupName={`${getContractAPI.key}_create`}
                                                            dataList={chargingMethods}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </FormWrapper>
                                </Col>
                            </Row>
                        )
                    }
                </React.Fragment>
            </div>
        )
    }
}

const CreateBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            formGroupName={`${getContractAPI.key}_create`}
            dataTableKey={getContractAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Name",
                    pricingModelType: "Contract Type",
                    maxPackageCount: "Max Package Count",
                    flatRate: "Flat Rate",
                    discount: "Discount",
                    expressMultiplier: "Express Delivery Charge Multiplier",
                    xlPackageHandling: "XL Package Handling Fee",
                    cashOnDelivery: "Cash on Delivery",
                    cardOnDelivery: "Card on Delivery",
                    start: "Contract Effective From",
                    end: "Contract Effective Period",
                    continuation: "Upon Contract Expiry",
                    settlementPeriod: "Settlement Period",
                    chargingMethod: "Charging Method"
                },
                rules: {
                    name: "required",
                    pricingModelType: "required",
                    maxPackageCount: "requiredIf:pricingModelType,TYPE_TWO",
                    flatRate: "requiredIf:pricingModelType,TYPE_TWO",
                    discount: "required|minAmount:0",
                    expressMultiplier: "required",
                    xlPackageHandling: "required",
                    cashOnDelivery: "required",
                    cardOnDelivery: "required",
                    start: "required",
                    end: "minAmount:1",
                    settlementPeriod: "required",
                    continuation: "required",
                    chargingMethod: "required"
                },
                message: {
                    "end.minAmount": "The Contract Effective Period must be at least 1 year",
                    "discount.minAmount": "The discount cannot be negative"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Merchant contract has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: getContractAPI.url,
                    key: getContractAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let contractEndDate = undefined;

                if (data.end && data.start !== undefined && data.end !== undefined) {
                    const contractEffectivePeriod = formObjectValidationHelper(data, "end", (data) => { return Number(data) });
                    const contractStartDate = new Date(data.start);
                    contractEndDate = new Date(data.start);
                    contractEndDate.setFullYear(contractStartDate.getFullYear() + contractEffectivePeriod);
                    contractEndDate.setSeconds(contractEndDate.getSeconds() - 1);
                }

                if (data.end && Number(data.end) < 1) {
                    contractEndDate = data.end
                }

                let temp = {
                    name: data.name,
                    maxPackageCount: formObjectValidationHelper(data, "maxPackageCount", (data) => { return Number(data) }),
                    flatRate: formObjectValidationHelper(data, "flatRate", (data) => { return Number(data) }),
                    pricingModelType: data.pricingModelType,
                    merchant: {
                        id: Number(props.id)
                    },
                    discount: formObjectValidationHelper(data, "discount", (data) => { return Number(data) }),
                    expressMultiplier: formObjectValidationHelper(data, "expressMultiplier", (data) => { return Number(data) }),
                    xlPackageHandling: formObjectValidationHelper(data, "xlPackageHandling", (data) => { return Number(data) }),
                    settlementPeriod: data.settlementPeriod,
                    start: data.start,
                    end: contractEndDate,
                    chargingMethod: data.chargingMethod
                }

                if (data.cashOnDelivery === "ENABLED") {
                    temp.cashOnDelivery = true;
                    temp.cashChargingType = data.cashChargingType;
                    temp.cashDelivery = formObjectValidationHelper(data, "cashDelivery", (data) => { return Number(data) });
                } else if (data.cashOnDelivery === "DISABLED") {
                    temp.cashOnDelivery = false;
                    temp.cashChargingType = 'FEE';
                    temp.cashDelivery = 0;
                }

                if (data.cardOnDelivery === "ENABLED") {
                    temp.cardOnDelivery = true;
                    temp.cardChargingType = data.cardChargingType;
                    temp.cardDelivery = formObjectValidationHelper(data, "cardDelivery", (data) => { return Number(data) });
                } else if (data.cardOnDelivery === "DISABLED") {
                    temp.cardOnDelivery = false;
                    temp.cardChargingType = 'FEE';
                    temp.cardDelivery = 0
                }

                if (data.continuation === "CONTINUE") {
                    temp.continuation = true;
                } else if (data.continuation === "DISCONTINUE") {
                    temp.continuation = false;
                }

                return temp;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push(`/merchant/${props.id}/contract`);
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.coreState
    };
};

const CreateUIWithState = connect(mapStateToProps, { changeInputFn, setFlashMessage })(CreateUI);

export {
    CreateUIWithState
}
