import React, { Component } from "react";

import { InputBoxWithState, RadioButton, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getCityListAPI, getZoneAPI } from "../../../../config/boda-boda/apiUrls.config";
import { deliveryCenterTypes } from "../../../../config/boda-boda/db_config/db_status.config";


class FilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Delivery Center Code"
                            inputName={"code"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Delivery Center Code"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Delivery Center Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Delivery Center Name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            placeHolderText={"Status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={[{
                                id: "ACTIVE",
                                name: "ACTIVE"
                            },
                            {
                                id: "INACTIVE",
                                name: "INACTIVE"
                            }]}
                            keyName={"id"}
                            valueName={"name"}
                            isRequired={false}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="City"
                            inputName={"cityId"}
                            placeHolderText={"City"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getCityListAPI.url}/all`}
                            apiStateKey={getCityListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            isRequired={false}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Zone"
                            inputName={"zoneId"}
                            placeHolderText={"Zone"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getZoneAPI.url}/all`}
                            apiStateKey={getZoneAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            isRequired={false}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Type"
                            inputName={"type"}
                            placeHolderText={"Type"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryCenterTypes}
                            keyName={"id"}
                            valueName={"value"}
                            isRequired={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default FilterUI;
