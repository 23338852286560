import {
    setToggleSideMenuKey, setPageLoaderKey, setRouteKey,
    setFlashMessageKey, removeFlashMessageKey, setSideMenuStatusKey,
    setPickRequestCountKey
} from "./actionTypes";


const initialState = {
    toggleSideMenu: true,
    setPageLoader: false,
    currentRouteKey: "",
    flashMessageList: [],
    pickRequestCount: 0
}

const UIState = (state = initialState, action) => {
    switch (action.type) {
        case setToggleSideMenuKey:
            return {
                ...state,
                toggleSideMenu: !state.toggleSideMenu
            };
        case setSideMenuStatusKey:
            return {
                ...state,
                toggleSideMenu: action.playload
            };
        case setPageLoaderKey:
            return {
                ...state,
                setPageLoader: action.playload
            };
        case setRouteKey:
            return{
                ...state,
                currentRouteKey:action.playload
            };
        case setFlashMessageKey:

            let currentList=state.flashMessageList||[];

            if((currentList).length >= 3){
                currentList = currentList.slice(1, currentList.length);
            }

            return {
                ...state,
                flashMessageList: [...currentList, action.playload]
            };

        case removeFlashMessageKey:
            return {
                ...state,
                flashMessageList:(state.flashMessageList||[]).slice(1,(state.flashMessageList||[]).length)
            };

        case setPickRequestCountKey:
            return {
                ...state,
                pickRequestCount: action.payload
            };

        default:
            return state;
    }
}

export default UIState;
