

const formCacheLevel={
    "none":"NONE",
    "updateOnFormGroupChange":"FORMGROUP",
    "updateOnIndividual":"INDIVIDUAL"
}

export {
    formCacheLevel
}