import React, { Component } from "react";
import {connect, useDispatch} from "react-redux";
import { Col, Row } from "reactstrap";
import {get} from "lodash";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import {
    FormWrapper,
    InputBoxWithState,
    InputButtonWithState,
    SelectBoxWithState
} from "../../../../../components/boda-boda/Forms";
import {
    getAllFacilityAPI,
    merchantBranchAPI,
    getCityListAPI,
    countryCodeAPI, getFacilityAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import {createBranchTrKey} from "../../../../../store/boda-boda/transaction_request/actionTypes";
import {currency} from "../../../../../config/boda-boda/db_config/db_status.config";
import {changeInputFn} from "../../../../../store/actions";
import {setPageLoader} from "../../../../../store/boda-boda/ui/actions";
import {callApi} from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";

class MerchantBranchCreate extends Component {

    facilityChange = async (eventData) => {
        let {setPageLoader, changeInputFn} = this.props;
        setPageLoader(true);

        const response = await callApi(`${getFacilityAPI.url}/${eventData.value}`)
            .headers(true)
            .method('get')
            .send()

        if (response._statue === true){
            console.log(response);
            const pickupCost = get(response, 'data.data.pickupCost', undefined);
            changeInputFn(`${merchantBranchAPI.key}_create`, 'pickupPrice', '', pickupCost);
            setPageLoader(false);
        }
    }

    render() {
        let {history, match} = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create Merchant Branch</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                                merchantId={match.params.merchantId}
                            />
                            <BackBtnComponent
                                path={`/merchant/${match.params.merchantId}/branch`}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${merchantBranchAPI.key}_create`}
                            onDestroyUnsetFormObject={true}
                        >
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Branch Name"
                                                        placeHolderText={"Branch Name"}
                                                        inputName={"name"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText={"Delivery Center"}
                                                        inputName={"facility"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        placeHolderText={"Delivery Center"}
                                                        apiUrl={getAllFacilityAPI.url}
                                                        apiStateKey={getAllFacilityAPI.key}
                                                        keyName={"id"}
                                                        valueName={"name"}
                                                        onChangeFn={(eventData) => this.facilityChange(eventData)}
                                                    />
                                                </div>

                                                {
                                                    (get(this.props.formState, `${merchantBranchAPI.key}_create.facility`) !== undefined) ? (
                                                        <div className="col-md-6">
                                                            <InputBoxWithState
                                                                isRequired={true}
                                                                labelText={`Pick Up Cost (${currency})`}
                                                                placeHolderText={"Pick Up Cost"}
                                                                inputName={"pickupPrice"}
                                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                                            />
                                                        </div>
                                                    ): null

                                                }

                                                <div className={"col-md-6"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="City"
                                                        inputName={"cityId"}
                                                        placeHolderText={"Select a City"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        apiUrl={`${getCityListAPI.url}/all`}
                                                        apiStateKey={getCityListAPI.key}
                                                        keyName={"id"}
                                                        valueName={"name"}
                                                    />
                                                </div>

                                                <div className={"col-md-6"}>
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Address"
                                                        placeHolderText={"Enter the Shipping Address"}
                                                        inputName={"address"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                        maxLength={250}
                                                    />
                                                </div>

                                            </div>

                                            <hr />

                                            <div className={"row"}>

                                                <div className={"col-md-6"}>
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Contact Person"
                                                        placeHolderText={"Contact Person"}
                                                        inputName={"fullName"}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                    />
                                                </div>

                                                <div className="col-md-6">

                                                    <div className={"row"}>
                                                        <div className={"col-md-3"}>
                                                            <SelectBoxWithState
                                                                isRequired={true}
                                                                labelText="Country Code"
                                                                inputStatePath={`${merchantBranchAPI.key}_create.countryCode.id`}
                                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                                                apiUrl={`${countryCodeAPI.url}/all`}
                                                                apiStateKey={countryCodeAPI.key}
                                                                onRebuildResponseFn={(response) => {
                                                                    const data = [];

                                                                    response.forEach((value) => {
                                                                        data.push({
                                                                            id: `${value.id}/${value.code}/${value.prefix}`,
                                                                            value: value.code
                                                                        });
                                                                    });

                                                                    return {
                                                                        "data": {
                                                                            "data": data
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className={"col-md-9"}>
                                                            <InputBoxWithState
                                                                labelText="Phone"
                                                                inputName={"phone"}
                                                                placeHolderText={"+XXXXXXXXXXX"}
                                                                formGroupName={`${merchantBranchAPI.key}_create`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-6"}>
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Email"
                                                        placeHolderText={"Email"}
                                                        inputName={'email'}
                                                        maxLength={50}
                                                        formGroupName={`${merchantBranchAPI.key}_create`}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        )
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();
    return (
        <InputButtonWithState
                elementWrapperStyle={"mr-2"}
                btnText={"Save"}
                startIcon={"mdi mdi-content-save-all-outline"}
                formGroupName={`${merchantBranchAPI.key}_create`}
                onClickBtnFn={(dataObject) => {
                    dispatch({
                        type: createBranchTrKey,
                        payload: {
                            ...dataObject.formObject,
                            merchant: props.merchantId,
                            historyFn: props.historyFn,
                        }
                    })
                }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const CreateUI = connect(mapStateToProps, { changeInputFn, setPageLoader})(MerchantBranchCreate);

export {
    CreateUI
}
