import React from "react";
import {InputBoxWithState, SelectBoxWithState} from "../../../../../../../components/boda-boda/Forms";
import {routeStatusList} from "../../../../../../../config/boda-boda/db_config/db_status.config";
import {getCityAPI, getZoneAPI} from "../../../../../../../config/boda-boda/apiUrls.config";

const FilterUI = (props) => {

    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Delivery Center Code"
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Delivery Center Code"}
                    />
                </div>

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Delivery Center Name"
                        inputName={"name"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Delivery Center Name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Status"}
                        inputName={"status"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Status"}
                        dataList={routeStatusList}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"City"}
                        inputName={"cityId"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"City"}
                        apiUrl={getCityAPI.url}
                        apiStateKey={getCityAPI.key}
                        keyName={"id"}
                        valueName={"name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Zone"}
                        inputName={"zoneId"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Zone"}
                        apiUrl={`${getZoneAPI.url}/all`}
                        apiStateKey={getZoneAPI.key}
                        keyName={"id"}
                        valueName={"name"}
                    />
                </div>

            </div>
        </div>
    )
}


export default FilterUI;