import React, { Fragment, useEffect } from "react";
import { Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { removeFlashMessageKey } from "../../../store/boda-boda/ui/actionTypes";


const emptyFn = (...para) => undefined;

const snackBarTypes = {
    "success": {
        "color": "success",
        "style": "bg-success",
        "icon": "far fa-check-circle"
    },
    "info": {
        "color": "info",
        "style": "bg-info",
        "icon": "fas fa-info-circle"
    },
    "warning": {
        "color": "warning",
        "style": "bg-warning",
        "icon": "fas fa-exclamation-triangle"
    },
    "danger": {
        "color": "danger",
        "style": "bg-danger",
        "icon": "far fa-times-circle"
    }
};

const Snackbar = ({
    openStatus = false,
    duration = 5000,
    onCloseFn = emptyFn,
    msjType = "success",
    message = "",
    index = "1"
}) => {
    useEffect(() => {
        if (openStatus) {
            setTimeout(() => {
                onCloseFn();
            }, duration)
        }
    }, [openStatus])

    return (
        <Fragment>
            <div
                className={`snackbar-wrapper ${openStatus ? "snackbar-wrapper-open" : "snackbar-wrapper-close"}`}
                style={{ marginTop: index * 70 }}
            >
                <Alert color={snackBarTypes[msjType]["color"]} className={`${snackBarTypes[msjType]["style"]} text-white`}>
                    <span className={"mr-1"}><i className={snackBarTypes[msjType]["icon"]}></i></span> {message}
                </Alert>
            </div>
        </Fragment>
    )
}

const SnackbarList = () => {
    const uiState = useSelector(state => state.UIState);
    const dispatch = useDispatch();

    return (
        <Fragment>
            {
                (uiState.flashMessageList || []).map((value, index) => {
                    return (
                        <Snackbar
                            key={index}
                            openStatus={value.status||false}
                            index={index}
                            duration={3000}
                            msjType={value.type}
                            message={value.message}
                            onCloseFn={() => {
                                dispatch({
                                    type: removeFlashMessageKey,
                                    key: "null"
                                })
                            }}
                        />
                    )
                })
            }
        </Fragment>
    )
}

export {
    Snackbar,
    SnackbarList
};
