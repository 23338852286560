import React, { Component, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { BackBtnComponent, CustomLoader } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { FormWrapper, InputBoxWithState, InputButtonWithState, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import { CreateUI } from "./common-includes/common-includes";
import { CreateMerchantContactBtn } from "./common-includes/CreateContactBtn";
import { getMerchantAPI, getMerchantBankAPI, getMerchantContactAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { channelIdForTacking, merchantEditStatusList } from "../../../../../config/boda-boda/db_config/db_status.config";
import { RemoveBtn, RemoveMessage } from "./common-includes/DeleteContactUI";
import { get } from "lodash";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { editMerchantTrKey } from "../../../../../store/boda-boda/transaction_request/actionTypes";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";

class EditUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            merchantBank: "fetching",
        }
    }

    componentDidMount() {
        this.loadMerchantBranch();
    }

    loadMerchantBranch = async () => {
        let { match } = this.props;

        if (match.params.id !== undefined) {
            const merchantBankResponse = await callApi(`${getMerchantBankAPI.url}/all?merchantId=${match.params.id}`)
                .headers(true)
                .method("get")
                .send();

            if (merchantBankResponse._statue === true) {
                if (get(merchantBankResponse, "data.data", [])?.length === 0) {
                    this.setState({
                        ...this.state,
                        merchantBank: "not_found"
                    })
                } else {
                    this.setState({
                        ...this.state,
                        merchantBank: get(merchantBankResponse, "data.data[0]", {})
                    })
                }
            } else {
                this.setState({
                    ...this.state,
                    merchantBank: "not_found"
                })
            }
        }
    }

    render() {
        let { formState, coreState, history, match } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Update Merchant Details</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                id={match.params.id}
                                historyFn={history}
                                coreState={coreState}
                                formState={this.props.formState}
                                merchantId={match.params.id}
                                merchantBank={this.state.merchantBank}
                            />
                            <BackBtnComponent
                                path={`/merchant`}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                {(this.state.merchantBank === "fetching") ? (
                    <center>
                        <CustomLoader />
                    </center>
                ) : (
                    <Row>
                        <Col sm={12}>
                            <FormWrapper
                                setGroupName={`${getMerchantAPI.key}_Edit`}
                                apiUrl={`${getMerchantAPI.url}/${match.params.id}`}
                                onDestroyUnsetFormObject={true}
                                onRebuildResponseFn={(response) => {
                                    return {
                                        name: response.name,
                                        code: response.code,
                                        registrationNo: response.registrationNo,
                                        contacts: response.contacts,
                                        status: response.status,
                                        website: response.website,
                                        trackByChannelOrderNo: response?.trackByChannelOrderNo,
                                        isCustomerNotificationEnable: response?.isCustomerNotificationEnable,
                                        trackByChannelOrderNoPrefix: response?.trackByChannelOrderNoPrefix,
                                        bank: get(this.state, "merchantBank.bank", null),
                                        accountNumber: get(this.state, "merchantBank.accountNumber", null),
                                        accountName: get(this.state, "merchantBank.accountName", null),
                                        branch: get(this.state, "merchantBank.branch", null),
                                        merchantBankId: get(this.state, "merchantBank.id", null),
                                        "_onLoad": false,
                                    }
                                }}
                            >

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className="card">
                                            <div className="card-body">

                                                <div className={"row"}>

                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Merchant Name"
                                                            placeHolderText={"Merchant Name"}
                                                            inputName={"name"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Merchant Code"
                                                            placeHolderText={"Merchant Code"}
                                                            inputName={"code"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Business Reg. No."
                                                            placeHolderText={"Registration No"}
                                                            inputName={"registrationNo"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            labelText="Website"
                                                            placeHolderText={"Website"}
                                                            inputName={"website"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Status"
                                                            placeHolderText={"Status"}
                                                            inputName={"status"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                            dataList={merchantEditStatusList}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            labelText={"Use Channel Order No for Tracking Number"}
                                                            placeHolderText={"Use Channel Order No for Tracking Number"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                            inputName={"trackByChannelOrderNo"}
                                                            dataList={channelIdForTacking}
                                                            isRequired={true}
                                                        />
                                                    </div>

                                                    {get(this.props.formState, `${getMerchantAPI.key}_Edit.trackByChannelOrderNo`, false) === true ? (
                                                        <div className="col-md-6">
                                                            <InputBoxWithState
                                                                labelText="Tracking Number Prefix"
                                                                placeHolderText={"Tracking Number Prefix"}
                                                                inputName={"trackByChannelOrderNoPrefix"}
                                                                formGroupName={`${getMerchantAPI.key}_Edit`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    ) : null}

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            labelText={"Send Customer Notifications"}
                                                            placeHolderText={"Send Customer Notifications"}
                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                            inputName={"isCustomerNotificationEnable"}
                                                            dataList={channelIdForTacking}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col-md-12"}>
                                                        <div className={"card"}>
                                                            <div className={"card-body"}>
                                                                <div className={"card-title mb-4"}>Bank Details</div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <InputBoxWithState
                                                                            isRequired={true}
                                                                            labelText="Bank"
                                                                            placeHolderText={"Bank"}
                                                                            inputName={"bank"}
                                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <InputBoxWithState
                                                                            isRequired={true}
                                                                            labelText="Account Name"
                                                                            placeHolderText={"Account Name"}
                                                                            inputName={"accountName"}
                                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <InputBoxWithState
                                                                            isRequired={true}
                                                                            labelText="Branch"
                                                                            placeHolderText={"Branch"}
                                                                            inputName={"branch"}
                                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <InputBoxWithState
                                                                            isRequired={true}
                                                                            labelText="Account Number"
                                                                            placeHolderText={"Account Number"}
                                                                            inputName={"accountNumber"}
                                                                            formGroupName={`${getMerchantAPI.key}_Edit`}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"row"}>
                                                    <CrudComponent
                                                        title={"Contact Details"}
                                                        isFilter={false}
                                                        dataTableStateKey={getMerchantContactAPI.key}
                                                        apiURL={`${getMerchantContactAPI.url}?merchantId=${match.params.id}&page=1&limit=20`}
                                                        createActionProps={{
                                                            actionType: actionBtnTypes.other,
                                                            actionBtn: {
                                                                color: "primary",
                                                                icon: "mdi mdi-account-box-multiple",
                                                                btnText: "Add New",
                                                                tooltip: "Add New",
                                                            },
                                                            isSetModel: true,
                                                            modalProps: {
                                                                modalTitle: "Add Contact",
                                                                modalBodyComponent: CreateUI,
                                                                modalFooterComponent: CreateMerchantContactBtn,
                                                                isSetClose: true,
                                                                isSetFooterClose: true,
                                                                parentToModelProps: {
                                                                    merchantId: match.params.id,
                                                                }
                                                            }
                                                        }}
                                                        tableHeaderList={[
                                                            { displayName: "Contact Person", key: "fullName" },
                                                            { displayName: "Phone No.", key: "phone" },
                                                            { displayName: "Email", key: "email" },
                                                            { displayName: "Contact Type", key: "departmentType" },
                                                            {
                                                                displayName: "Receive Notification", key: "notification", onPrintFn: (rawData) => {
                                                                    return rawData.notification === true ? "Yes" : "No"
                                                                }
                                                            },
                                                        ]}
                                                        actionList={[
                                                            {
                                                                actionType: actionBtnTypes.delete,
                                                                isSetModel: true,
                                                                modalProps: {
                                                                    modalTitle: "Please Confirm",
                                                                    modalType: "",
                                                                    modalBodyComponent: RemoveMessage,
                                                                    modalFooterComponent: RemoveBtn,
                                                                    isSetClose: true,
                                                                    isSetFooterClose: true,
                                                                }
                                                            }
                                                        ]}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </FormWrapper>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}


const CreateBtn = (props) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [duplicateTrackingNoMerchants, setDuplicateTrackingNoMerchants] = useState(false);

    return (
        <React.Fragment>
            <InputButtonWithState
                elementWrapperStyle={"mr-2"}
                btnText={"Save"}
                formGroupName={`${getMerchantAPI.key}_Edit`}
                onClickBtnFn={(dataObject) => {
                    dispatch({
                        type: editMerchantTrKey,
                        payload: {
                            ...dataObject.formObject,
                            merchantId: props.merchantId,
                            merchantBank: props.merchantBank,
                            historyFn: props.historyFn,
                            setDuplicateTrackingNoMerchants: setDuplicateTrackingNoMerchants,
                            setOpen: setOpen,
                        }
                    })
                }
                }
            />
            <ModalUI
                isOpen={isOpen}
                // modalTitle={""}
                modalBodyComponent={<React.Fragment>
                    <p>Entered tracking number prefix is already assigned to another merchant. Do you want to proceed? </p>
                    <p><strong>Prefix already assigned to: </strong></p>
                    <p>{duplicateTrackingNoMerchants}</p>
                </React.Fragment>}
                modalFooterComponent={
                    <InputButtonWithState
                        elementWrapperStyle={"mr-2"}
                        btnText={"Proceed"}
                        formGroupName={`${getMerchantAPI.key}_Edit`}
                        onClickBtnFn={(dataObject) => {
                            setOpen(false);
                            dispatch({
                                type: editMerchantTrKey,
                                payload: {
                                    ...dataObject.formObject,
                                    merchantId: props.merchantId,
                                    merchantBank: props.merchantBank,
                                    historyFn: props.historyFn,
                                    setOpen: setOpen,
                                    isAlreadyValidated: true
                                }
                            })
                        }}
                    />
                }
                showHideToggleFn={() => {
                    setOpen(false);
                }}
            />
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const EditUIWithState = connect(mapStateToProps, null)(EditUI);

export {
    EditUIWithState
}
