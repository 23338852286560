import React from "react";
import {FormWrapper, InputBoxWithState, SelectBoxWithState} from "../../../../../../components/boda-boda/Forms";
import {getLastMileAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import {routeStatusList} from "../../../../../../config/boda-boda/db_config/db_status.config";

const CreateUI = (props) => {
    return (
        <FormWrapper
            setGroupName={getLastMileAPI.key}
        >
            <InputBoxWithState
                labelText="Route Name"
                inputName={"name"}
                isRequired={true}
                formGroupName={getLastMileAPI.key}
                maxLength={20}
                placeHolderText={"Route Name"}
            />
            <SelectBoxWithState
                labelText={"Route Status"}
                isRequired={true}
                placeHolderText={"Route Status"}
                dataList={routeStatusList}
                inputName={"status"}
                formGroupName={getLastMileAPI.key}
            />
        </FormWrapper>
    )
}

export {
    CreateUI
}