import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputButton, FormWrapper } from "../../../components/boda-boda/Forms";
import { getUserStaffList } from "../../../config/boda-boda/apiUrls.config";


class DeliveryChargePrice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { match, history, formState } = this.props;

    return (
      <div className="container-fluid">
        <FormWrapper
          setGroupName={`${getUserStaffList.key}_view`}
          onDestroyUnsetFormObject={true}
          // apiUrl={`${getUserStaffList.url}/${match.params.id}`}
          onRebuildResponseFn={(response) => {
            return {
              id: get(response, "id", ""),
              name: get(response, "name", ""),
              email: get(response, "email", ""),
              phone: get(response, "phone", ""),
              identification: get(response, "identification", ""),
              facility: get(response, "facility.name", ""),
              category: get(response, "category", ""),
              status: get(response, "status", ""),
              deletedAt: get(response, "deletedAt", null),
              role: get(response, "user.role.name", null),
              "_onLoad": false,
            }
          }}
        >
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                <h4 className="font-size-18">View Pricing</h4>
              </div>
            </Col>

            <Col sm={6}>
              <div className={"d-flex justify-content-end"}>

                <InputButton
                  elementWrapperStyle={"mr-2"}
                  btnText={"Edit"}
                  startIcon={"mdi mdi-square-edit-outline"}
                  onClickBtnFn={() => history.push(`/update-delivery-pricing`)}
                />

              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FormWrapper
                setGroupName={`${getUserStaffList.key}_create`}
                onDestroyUnsetFormObject={true}
              >

                <div className="card">
                  <div className="card-body">

                    <div className="row">
                      <div className={"col-md-12"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>Express Delivery Multiplier</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>
                        </dl>
                      </div>
                    </div>
                    <div className="row">
                      <div className={"col-md-12"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>Per Package Cash on Delivery</dd>
                        </dl>
                      </div>
                      <div className={"col-md-6"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>%</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>
                        </dl>
                      </div>
                      <div className={"col-md-6"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>Flat Fee</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>
                        </dl>
                      </div>
                      <div className={"col-md-12"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>Per Package Card on Delivery</dd>
                        </dl>
                      </div>
                      <div className={"col-md-6"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>%</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>
                        </dl>
                      </div>
                      <div className={"col-md-6"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>Flat Fee</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>
                        </dl>
                      </div>

                    </div>
                    <div className="row">
                      <div className={"col-md-12"}>
                        <dl className={"row"}>
                          <dd className={"col-sm-4"}>XL Package Handling Cost</dd>
                          <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>
                        </dl>
                      </div>
                    </div>

                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Lodgment Location wise Rate</h4>

                        <div className={"row"}>
                          <div className={"col-md-12"}>
                            <dl className={"row"}>
                              <li>Colombo</li>
                              <li>Matara</li>
                            </dl>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </FormWrapper>
            </Col>
          </Row>

        </FormWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData
  };
};

const ViewUIWithState = connect(mapStateToProps, null)(DeliveryChargePrice);

export {
  ViewUIWithState
}
