import React, { Fragment } from "react";
import { ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { get } from "lodash";

import { commonActionBtnPops } from "../configs/crud.config";


const emptyFn = (...para) => undefined;

const ActionBtn = ({
    isOpen = false,
    bodyData = {},
    actionList = [],
    onClickActionFn = emptyFn,
    toggleFn = emptyFn
}) => {
    return (
        <Fragment>
            <ButtonDropdown
                isOpen={isOpen}
                toggle={() => toggleFn(!isOpen)}
                direction="left"
            >
                <DropdownToggle caret color={"primary"}>
                    <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>

                <DropdownMenu>
                    {
                        actionList.map((action, index) => {

                            action = {
                                ...action,
                                actionBtn: {
                                    ...get(commonActionBtnPops, action.actionType, {}),
                                    ...action.actionBtn || {}
                                }
                            }

                            const checkBeforeActionRender = get(action, "checkBeforeActionRenderFn", null) === null ? true : get(action, "checkBeforeActionRenderFn", emptyFn)({
                                rowData: bodyData
                            });

                            if (checkBeforeActionRender === true) {
                                return (
                                    <DropdownItem
                                        key={index}
                                        onClick={() => {
                                            if (get(action,'isSetModel', false) !== false) {
                                                onClickActionFn(action, bodyData);
                                            } else {
                                                get(action,'actionBtn.onClickBtnFn', emptyFn)(bodyData);
                                            }
                                        }}
                                    >
                                        {get(action,'actionBtn.btnText', "").replace("_", " ")}
                                    </DropdownItem>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </DropdownMenu>
            </ButtonDropdown>
        </Fragment>
    )
}

export default ActionBtn;
