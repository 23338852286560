const permissions = [
  {
    header: "Delivery Charge Management",
    data: [
      {
        id: "delivery-charge-read",
        value: "Read",
      },
      {
        id: "delivery-charge-create",
        value: "Create",
      },
    ],
  },
  {
    header: "Merchant Channel Management",
    data: [
      {
        id: "merchant-channel-read",
        value: "Read",
      },
    ],
  },
  {
    header: "Location(City/District/Province/CountryCode/Zone) Management",
    data: [
      {
        id: "location-read",
        value: "Read",
      },
      {
        id: "location-create",
        value: "Create",
      },
      {
        id: "location-update",
        value: "Update",
      },
      {
        id: "location-delete",
        value: "Delete",
      },
    ],
  },

  {
    header: "Driver Management",
    data: [
      {
        id: "driver-read",
        value: "Read",
      },
      {
        id: "driver-create",
        value: "Create",
      },
      {
        id: "driver-update",
        value: "Update",
      },
      {
        id: "driver-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Driver Till Management",
    data: [
      {
        id: "driver-till-read",
        value: "Read",
      },
      {
        id: "driver-till-create",
        value: "Create",
      },
    ],
  },
  {
    header: "Facility Management",
    data: [
      {
        id: "facility-read",
        value: "Read",
      },
      {
        id: "facility-create",
        value: "Create",
      },
      {
        id: "facility-update",
        value: "Update",
      },
      {
        id: "facility-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Facility Rates Management",
    data: [
      {
        id: "facility-rates-read",
        value: "Read",
      },
      {
        id: "facility-rates-create",
        value: "Create",
      },
      {
        id: "facility-rates-update",
        value: "Update",
      },
      {
        id: "facility-rates-delete",
        value: "Delete",
      },
    ],
  },

  {
    header: "Manifest (Pick Request/Collection/Delivery) Management",
    data: [
      {
        id: "manifest-read",
        value: "Read",
      },
      {
        id: "manifest-create",
        value: "Create",
      },
      {
        id: "manifest-update",
        value: "Update",
      },
      {
        id: "manifest-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Collection Code",
    data: [
      {
        id: "collection-code-create",
        value: "Create",
      },
    ],
  },
  {
    header: "Merchant Management",
    data: [
      {
        id: "merchant-read",
        value: "Read",
      },
      {
        id: "merchant-create",
        value: "Create",
      },
      {
        id: "merchant-update",
        value: "Update",
      },
      {
        id: "merchant-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Branch Management",
    data: [
      {
        id: "merchant-branch-read",
        value: "Read",
      },
      {
        id: "merchant-branch-create",
        value: "Create",
      },
      {
        id: "merchant-branch-update",
        value: "Update",
      },
      {
        id: "merchant-branch-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Branch Contact Management",
    data: [
      {
        id: "merchant-branch-contact-read",
        value: "Read",
      },
      {
        id: "merchant-branch-contact-create",
        value: "Create",
      },
      {
        id: "merchant-branch-contact-update",
        value: "Update",
      },
      {
        id: "merchant-branch-contact-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Branch Rates Management",
    data: [
      {
        id: "merchant-branch-rates-read",
        value: "Read",
      },
      {
        id: "merchant-branch-rates-overwrite",
        value: "Overwrite",
      },
    ],
  },
  {
    header: "Merchant Branch Category Management",
    data: [
      {
        id: "merchant-category-read",
        value: "Read",
      },
      {
        id: "merchant-category-create",
        value: "Create",
      },
      {
        id: "merchant-category-update",
        value: "Update",
      },
      {
        id: "merchant-category-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Contact Management",
    data: [
      {
        id: "merchant-contact-read",
        value: "Read",
      },
      {
        id: "merchant-contact-create",
        value: "Create",
      },
      {
        id: "merchant-contact-update",
        value: "Update",
      },
      {
        id: "merchant-contact-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Contract Management",
    data: [
      {
        id: "merchant-contract-read",
        value: "Read",
      },
      {
        id: "merchant-contract-create",
        value: "Create",
      },
      {
        id: "merchant-contract-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Template Management",
    data: [
      {
        id: "merchant-template-read",
        value: "Read",
      },
      {
        id: "merchant-template-create",
        value: "Create",
      },
      {
        id: "merchant-template-update",
        value: "Update",
      },
      {
        id: "merchant-template-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Order Management",
    data: [
      {
        id: "order-read",
        value: "Read",
      },
      {
        id: "order-create",
        value: "Create",
      },
      {
        id: "order-update",
        value: "Update",
      },
      {
        id: "order-delete",
        value: "Delete",
      },
      {
        id: "order-cod-settlement-status-update",
        value: "Order Amount Settlement Status Update",
      },
      {
        id: "order-ready-for-return-update",
        value: "Order Ready for Return Update",
      },
      {
        id: "order-ready-for-delivery-update",
        value: "Order Ready for Delivery Update",
      },
    ],
  },
  {
    header: "Order Image Management",
    data: [
      {
        id: "order-image-read",
        value: "Read",
      },
      {
        id: "order-image-create",
        value: "Create",
      },
      {
        id: "order-image-update",
        value: "Update",
      },
      {
        id: "order-image-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Order Item Management",
    data: [
      {
        id: "order-item-read",
        value: "Read",
      },
      {
        id: "order-item-create",
        value: "Create",
      },
      {
        id: "order-item-update",
        value: "Update",
      },
      {
        id: "order-item-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Package Management",
    data: [
      {
        id: "package-read",
        value: "Read",
      },
      {
        id: "package-create",
        value: "Create",
      },
      {
        id: "package-update",
        value: "Update",
      },
      {
        id: "package-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Package Category Management",
    data: [
      {
        id: "package-category-read",
        value: "Read",
      },
      {
        id: "package-category-create",
        value: "Create",
      },
      {
        id: "package-category-update",
        value: "Update",
      },
      {
        id: "package-category-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Package Image Management",
    data: [
      {
        id: "package-image-read",
        value: "Read",
      },
      {
        id: "package-image-create",
        value: "Create",
      },
      {
        id: "package-image-update",
        value: "Update",
      },
      {
        id: "package-image-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Package Type Management",
    data: [
      {
        id: "package-type-read",
        value: "Read",
      },
      {
        id: "package-type-create",
        value: "Create",
      },
      {
        id: "package-type-update",
        value: "Update",
      },
      {
        id: "package-type-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Reason Management",
    data: [
      {
        id: "reason-read",
        value: "Read",
      },
    ],
  },
  {
    header: "Reports Management",
    data: [
      {
        id: "delivery-reconciliation",
        value: "Lodgement Reconciliation Report",
      },
      {
        id: "cash-or-card-on-delivery",
        value: "Cash/Card on Delivery Report",
      },
      {
        id: "package-wise-costing",
        value: "Package Wise Costing Report",
      },
      {
        id: "sage-merchant-invoice",
        value: "SAGE - Merchant Invoice Report",
      },
      {
        id: "sage-driver-payment",
        value: "SAGE - Driver Payment Accural Report",
      },
      {
        id: "package-status-report",
        value: "Package Status Report",
      },
      {
        id: "merchant-settlement-transaction",
        value: "Merchant Settlement Transaction Report",
      },
      {
        id: "hub-settlement-transaction",
        value: "Hub Settlement Transaction Report",
      },
    ],
  },
  {
    header: "Role Management",
    data: [
      {
        id: "role-read",
        value: "Read",
      },
      {
        id: "role-create",
        value: "Create",
      },
      {
        id: "role-update",
        value: "Update",
      },
      {
        id: "role-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Route Last Management",
    data: [
      {
        id: "route-last-read",
        value: "Read",
      },
      {
        id: "route-last-create",
        value: "Create",
      },
      {
        id: "route-last-update",
        value: "Update",
      },
      {
        id: "route-last-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Route Line Management",
    data: [
      {
        id: "route-line-read",
        value: "Read",
      },
      {
        id: "route-line-create",
        value: "Create",
      },
      {
        id: "route-line-update",
        value: "Update",
      },
      {
        id: "route-line-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Runsheet Last Management",
    data: [
      {
        id: "runsheet-last-read",
        value: "Read",
      },
      {
        id: "runsheet-last-create",
        value: "Create",
      },
      {
        id: "runsheet-last-update",
        value: "Update",
      },
      {
        id: "runsheet-last-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Runsheet Line Management",
    data: [
      {
        id: "runsheet-line-read",
        value: "Read",
      },
      {
        id: "runsheet-line-create",
        value: "Create",
      },
      {
        id: "runsheet-line-update",
        value: "Update",
      },
      {
        id: "runsheet-line-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Staff Management",
    data: [
      {
        id: "staff-read",
        value: "Read",
      },
      {
        id: "staff-create",
        value: "Create",
      },
      {
        id: "staff-update",
        value: "Update",
      },
      {
        id: "staff-delete",
        value: "Delete",
      },
      {
        id: "staff-destroy",
        value: "Destroy",
      },
    ],
  },

  {
    header: "File Uploads Management",
    data: [
      {
        id: "file-uploads-read",
        value: "Read",
      },
      {
        id: "file-uploads-create",
        value: "Create",
      },
      {
        id: "file-uploads-update",
        value: "Update",
      },
      {
        id: "file-uploads-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "User Management",
    data: [
      {
        id: "user-read",
        value: "Read",
      },
      {
        id: "user-create",
        value: "Create",
      },
      {
        id: "user-update",
        value: "Update",
      },
      {
        id: "user-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Merchant Bank",
    data: [
      {
        id: "merchant-bank-read",
        value: "Read",
      },
      {
        id: "merchant-bank-create",
        value: "Create",
      },
      {
        id: "merchant-bank-update",
        value: "Update",
      },
      {
        id: "merchant-bank-delete",
        value: "Delete",
      },
    ],
  },
  {
    header: "Hub Settlement",
    data: [
      {
        id: "hub-settlement-read",
        value: "Read",
      },
      {
        id: "hub-settlement-create",
        value: "Create",
      },
      {
        id: "hub-settlement-update",
        value: "Update",
      },
      {
        id: "hub-settlement-receive",
        value: "Receive",
      },
    ],
  },
  {
    header: "Head Office Settlement",
    data: [
      {
        id: "head-office-settlement-read",
        value: "Read",
      },
      {
        id: "head-office-settlement-create",
        value: "Create",
      },
      {
        id: "head-office-settlement-update",
        value: "Update",
      },
    ],
  },
];

export { permissions };
