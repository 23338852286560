import React, { Fragment } from "react";
import { Collapse } from "reactstrap";


const emptyFn = (...para) => undefined;

const ExpansionPanel = ({
    elementWrapperStyle = "mb-1",
    title = "",
    isOpen = false,
    children = null,
    onClickFn = emptyFn
}) => {
    return (
        <Fragment>
            <div className={`card ${elementWrapperStyle}`}>
                <div className="card-header p-3" id="headingOne" onClick={onClickFn} style={{ cursor: "pointer", backgroundColor: "#ffffff" }}>
                    <h4 className="m-0 card-title">
                          <span className="">
                              {title}
                              <i className={isOpen === false ? "fas fa-chevron-down" : "fas fa-chevron-up"} style={{ float: "right" }}></i>
                          </span>
                    </h4>
                </div>

                <Collapse isOpen={isOpen}>
                    <div className={"col-md-12 px-3"}>
                        {children}
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}

export default ExpansionPanel;
