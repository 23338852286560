import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import { merchantAPI, getPackageCategoryListAPI,getPackageListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { packageDeliveryStatus, packageDeliveryType, paymentMethods } from "../../../../../config/boda-boda/db_config/db_status.config";

class FilterUI extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Article No"
                            inputName={"articleNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Article No"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Customer Name"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Merchant"
                            inputName={"merchantId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Merchant"}
                            apiUrl={`${merchantAPI.url}/all`}
                            apiStateKey={merchantAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            placeHolderText={"Merchant"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            isRequired={true}
                            labelText="Delivery Type"
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Delivery Type"}
                            dataList={packageDeliveryType}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Delivery Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Delivery Status"}
                            dataList={packageDeliveryStatus}
                        />
                    </div>
                    
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Payment Method"}
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Package Category"}
                            inputName={"categoryId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getPackageCategoryListAPI.url}`}
                            apiStateKey={getPackageCategoryListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            placeHolderText={"Package Category"}
                        />
                    </div>

                    {/* <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Delivery Status"}
                            placeHolderText={"Delivery Status"}
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={getPackageListAPI.url}
                            apiStateKey={getPackageListAPI.key}
                            keyName={"id"}
                            valueName={"status"}
                        />
                    </div> */}

                    {/* <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={userStatusList}
                            placeHolderText={"Status"}
                        />
                    </div> */}
                </div>
            </div>
        )
    }
}

export default FilterUI;