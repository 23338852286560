import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState
} from "../../../../components/boda-boda/Forms";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    convertToCsvValue,
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getUserDriverList, sageDriverReportAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setFlashMessage } from '../../../../store/boda-boda/ui/actions'


class SAGEDriverPaymentReport extends Component {
    onGenerateFn = () => {
        let { formState } = this.props;

        if (this.validateFn() === true) {
            let dateFrom = new Date(get(formState, "sage_driver_report.dateFrom"));
            let dateTo = new Date(get(formState, "sage_driver_report.dateFrom"));
            dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 1));
            dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1))

            const queryObj = {
                dateTo: dateTo.toISOString(),
                dateFrom: dateFrom.toISOString(),
                driverId: get(formState, "sage_driver_report.driverId"),
            }

            this.getAPIData(fromObjectToQueryString(queryObj));
        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "sage_driver_report.dateFrom", ""))) {
            errors.push({
                "property": "dateFrom",
                "error": "This field is required"
            })
        }

        if (errors.length === 0) {
            setFormErrorFn("sage_driver_report", []);
            return true;
        } else {
            setFormErrorFn("sage_driver_report", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${sageDriverReportAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    console.log('false')
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);
                        
                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }
                                        
                        result.data.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data", []));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    generateCSVData = (data = []) => {
        let { formState } = this.props;

        let dateTo = new Date(get(formState, "sage_driver_report.dateFrom"));
        dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 1));
        dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

        const headers1 = ["RECTYPE", "CNTBTCH", "CNTITEM", "ORIGCOMP", "IDVEND", "IDINVC", "TEXTTRX",
            "IDTRX", "ORDRNBR", "PONBR", "DATEINVC", "CODECURN", "RATETYPE", "EXCHRATEHC", "TERMCODE", "DATEDUE", "CODETAXGRP",
            "TAXCLASS1", "AMTINVCTOT", "AMTDUE", "AMTGROSTOT"
        ];
        const headers2 = ["RECTYPE", "CNTBTCH", "CNTITEM", "CNTLINE", "IDGLACCT", "AMTDIST", "AMTDISTHC"];
        const headers3 = ["RECTYPE", "CNTBTCH", "CNTITEM", "CNTPAYM", "DATEDUE", "AMTDUE"];

        const dataList = [];

        let count = 1;

        data.forEach((value) => {
            if (get(value, "manifests", []).length > 0) {
                get(value, "manifests", []).map((secondValue) => {
                    let dueDate = new Date(dateTo);
                    dueDate.setDate(dueDate.getDate() + 7);
                    const temp = [];

                    temp.push("1");
                    temp.push("1");
                    temp.push(count);
                    temp.push("");
                    temp.push(`"${convertToCsvValue(get(value, "licenseNo", "-"))}"`);
                    temp.push(`"${convertToCsvValue(get(secondValue, "code", "-"))}"`);
                    temp.push("1");
                    temp.push("12");
                    temp.push("");
                    temp.push("");
                    temp.push(`${dateObjectToString(dateTo, "YYYYmmdd")}`);
                    temp.push("SLR");
                    temp.push("AV");
                    temp.push("1");
                    temp.push("07");
                    temp.push(`${dateObjectToString(dueDate, "YYYYmmdd")}`);
                    temp.push("VAT");
                    temp.push("1");
                    temp.push(get(secondValue, "driverCommission", "-"));
                    temp.push(get(secondValue, "driverCommission", "-"));
                    temp.push(get(secondValue, "driverCommission", "-"));
                    dataList.push(temp);

                    const secondTemp = [];
                    secondTemp.push("2");
                    secondTemp.push("1");
                    secondTemp.push(count);
                    secondTemp.push("20");
                    secondTemp.push(process.env.REACT_APP_DRIVER_MANIFESTS_GL_CODE);
                    secondTemp.push(get(secondValue, "driverCommission", "-"));
                    secondTemp.push(get(secondValue, "driverCommission", "-"));

                    dataList.push(secondTemp);

                    const thirdTemp = [];
                    thirdTemp.push("3");
                    thirdTemp.push("1");
                    thirdTemp.push(count);
                    thirdTemp.push("1");
                    thirdTemp.push(`${dateObjectToString(dueDate, "YYYYmmdd")}`);
                    thirdTemp.push(get(secondValue, "driverCommission", "-"));

                    dataList.push(thirdTemp);
                    count += 1;
                });
            }

            if (get(value, "packageHistories", []).length > 0) {
                get(value, "packageHistories", []).map((secondValue) => {
                    let dueDate = new Date(dateTo);
                    dueDate.setDate(dueDate.getDate() + 7);
                    const temp = [];
                    let courierCommission = 0;

                    if (get(secondValue, "package.packageHistories", []).length > 0) {
                        if ((get(secondValue, "package.manifestId", null) !== null) && (get(secondValue, "package.manifestId", null) === get(secondValue, "package.packageHistories[0].manifestId", null))) {
                            courierCommission = get(secondValue, "order.courierCommission", 0);
                        } else {
                            courierCommission = parseInt(get(secondValue, "order.courierCommission", 0)) / 2;
                        }
                    }
                    else {
                        courierCommission = get(secondValue, "order.courierCommission", 0);
                    }
                    let runSheetCode = get(secondValue, "manifest.runSheetLast.code", "");
                    temp.push("1");
                    temp.push("1");
                    temp.push(count);
                    temp.push("");
                    temp.push(get(value, "licenseNo", "-"));
                    temp.push(`${runSheetCode ? runSheetCode + ' - ' : ''}${get(secondValue, "order.consignmentNo", "")}`);
                    temp.push("1");
                    temp.push("12");
                    temp.push("");
                    temp.push("");
                    temp.push(`${dateObjectToString(dateTo, "YYYYmmdd")}`);
                    temp.push("SLR");
                    temp.push("AV");
                    temp.push("1");
                    temp.push("07");
                    temp.push(`${dateObjectToString(dueDate, "YYYYmmdd")}`);
                    temp.push("VAT");
                    temp.push("1");
                    temp.push(courierCommission);
                    temp.push(courierCommission);
                    temp.push(courierCommission);
                    dataList.push(temp);

                    const secondTemp = [];
                    secondTemp.push("2");
                    secondTemp.push("1");
                    secondTemp.push(count);
                    secondTemp.push("20");
                    secondTemp.push(process.env.REACT_APP_DRIVER_CONSIGNMENT_GL_CODE);
                    secondTemp.push(courierCommission);
                    secondTemp.push(courierCommission);

                    dataList.push(secondTemp);

                    const thirdTemp = [];
                    thirdTemp.push("3");
                    thirdTemp.push("1");
                    thirdTemp.push(count);
                    thirdTemp.push("1");
                    thirdTemp.push(`${dateObjectToString(dueDate, "YYYYmmdd")}`);
                    thirdTemp.push(courierCommission);

                    dataList.push(thirdTemp);
                    count += 1;
                })
            }

        });

        const finalDataSet = [headers1, headers2, headers3];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Driver Invoice ${dateObjectToString(dateTo, "YYYYmmdd")}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">SAGE - Driver Payment Accrual Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">SAGE - Driver Payment Accrual Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "100%" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"sage_driver_report"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            dateFrom: null,
                                            dateTo: null,
                                            merchantId: "",
                                            paymentMethod: "",
                                            type: "screen"
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Month/Year"
                                                    inputName={"dateFrom"}
                                                    formGroupName={"sage_driver_report"}
                                                    monthYearPicker={true}
                                                    dateFormat={"MM/yyyy"}
                                                    maxDate={new Date()}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Driver's Name - License Number"}
                                                    apiUrl={`${getUserDriverList.url}/all?category=LAST`}
                                                    apiStateKey={getUserDriverList.key}
                                                    inputName={"driverId"}
                                                    placeHolderText={"Driver"}
                                                    formGroupName={"sage_driver_report"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}`,
                                                                value: `${value.name} - ${value.licenseNo}`,

                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    setFlashMessage,
})(SAGEDriverPaymentReport);

