import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { FormWrapper, InputBoxWithState, SubmitButton } from "../../../components/boda-boda/Forms";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";
import { forgotPasswordAPI } from "../../../config/boda-boda/apiUrls.config";
import { SnackbarList } from "../../../components/boda-boda/Snackbar/Snackbar";
import Loader from "../../../components/Loader";
import { changeInputFn } from "../../../store/boda-boda/form/actions";
import logo from "../../../assets/images/boda-logo3.png";


class ForgetPasswordPage extends Component {
  render() {
    let { uiState, history } = this.props;

    return (
        <React.Fragment>
          {
            (uiState.setPageLoader === true) ? (
                <Loader />
            ) : null
          }
          <SnackbarList />
          <div className="account-pages my-5 pt-5">
            <div className="container">

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>

                  <div className="position-relative">
                    <Card className="overflow-hidden">

                      <div className="bg-primary">
                        <div className="text-primary text-center p-4">
                          <h5 className="text-white font-size-20">
                            Forgot Password
                          </h5>
                          <p className="text-white-50">
                            Enter your username below to validate your profile.
                          </p>
                          <Link to="/" className="logo logo-admin">
                            <img src={logo} height="50" alt="logo" />
                          </Link>
                        </div>
                      </div>

                      <CardBody className="p-4">
                        <div className="p-3">
                          <div className="form-horizontal mt-4">

                            <FormWrapper
                                setGroupName={`forgotPasswordFrom`}
                                onDestroyUnsetFormObject={false}
                            >
                              <InputBoxWithState
                                  labelText="Username"
                                  formGroupName={`forgotPasswordFrom`}
                                  inputName={"credential"}
                                  placeHolderText={"Enter Username"}
                                  maxLength={50}
                              />

                            </FormWrapper>
                            <div className="row">
                              <Col sm={6}>
                              </Col>
                              <Col sm={6} className="text-right">
                                <SubmitButton
                                    btnText={"Continue"}
                                    elementStyle="btn btn-primary w-md waves-effect waves-light"
                                    formGroupName={`forgotPasswordFrom`}
                                    dataTableKey={forgotPasswordAPI.key}
                                    isValidate={true}
                                    elementWrapperStyle={"mt-3"}
                                    flashMessages={{
                                      "success": {
                                        status: true,
                                        message: "Profile has successfully validated",
                                        type: "success"
                                      },
                                      "error": {
                                        status: true,
                                        message: "No user found with that credentials",
                                        type: "danger"
                                      }
                                    }}
                                    validationObject={{
                                      fields: {
                                        credential: "Username",
                                      },
                                      rules: {
                                        credential: "required",
                                      },
                                      message: {}
                                    }}
                                    callApiObject={{
                                      isSetHeaders: true,
                                      multipart: false,
                                      method: "post",
                                      onUpload: false
                                    }}
                                    apiDataStoringObject={{
                                      setLoader: true,
                                      storingType: apiResponseStoringType.apiRequestOnly,
                                      mergeToSuccessResponse: null,
                                      mergeToErrorResponse: null,
                                    }}
                                    onGetAPIEndPointFn={(formObject) => {
                                      return {
                                        url: `${forgotPasswordAPI.url}`,
                                        key: forgotPasswordAPI.key
                                      }
                                    }}
                                    onChangeRequestBodyFn={(formObject) => {
                                      return {
                                        "credential": formObject.credential
                                      }
                                    }}
                                    onResponseCallBackFn={(error, result) => {
                                      if (!error) {
                                        this.props.changeInputFn("forgotPasswordFrom", "maskData", "", result.data);
                                        history.push(`/forgot-password-otp`);
                                      }
                                    }}
                                />
                              </Col>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-5 text-center">
                      <p>
                        Go back to{" "}
                        <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                        >
                          Login
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    uiState: state.UIState
  };
};

export default withRouter(
    connect(mapStatetoProps, { changeInputFn })(ForgetPasswordPage)
);
