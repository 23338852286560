import React from "react";
import { withRouter } from "react-router-dom";
import {SubmitButton} from "../../../../../../components/boda-boda/Forms";
import {getLinehaulAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import {apiResponseStoringType} from "../../../../../../config/boda-boda/apiResponseKey";
import {getDataByFormObject} from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFlashMessage, setFormErrorFn } from "../../../../../../store/actions";
import { get } from "lodash";
import { useDispatch } from "react-redux";

const CreateLinehaulBtn = (props) => {

    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <SubmitButton
                btnText={"Create"}
                startIcon={"mdi mdi-content-save-all-outline"}
                formGroupName={getLinehaulAPI.key}
                dataTableKey={getLinehaulAPI.key}
                isValidate={true}
                validationObject={{
                    fields: {
                        name: "Route Name",
                        status: "Route Status"
                    },
                    rules: {
                        name: "required",
                        status: "required"
                    },
                    messages: {}
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: getLinehaulAPI.url,
                        key: getLinehaulAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);

                    let temp = {
                        name: data.name,
                        status: data.status
                    }

                    return temp
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.setModelStatusFn(false);
                        props.history.push(`/routes/linehaul/create/${result.data.id}`)
                    }
                }}
            />
        </React.Fragment>
    )
}

export default withRouter(CreateLinehaulBtn)