import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect, useDispatch } from "react-redux";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
} from "../../../../components/boda-boda/Forms";
import {
    countryCodeAPI, getRoleListAPI, getUserDriverList
} from "../../../../config/boda-boda/apiUrls.config";
import { userStatusList, driverTypes } from "../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import FileUpload from "../../../../components/boda-boda/FileUpload/FileUpload";
import InputButtonWithState from "../../../../components/boda-boda/Forms/InputButtonWithState";
import { updateDriverTrKey } from "../../../../store/boda-boda/transaction_request/actionTypes";


class EditUI extends Component {
    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserDriverList.key}_edit`}
                    apiUrl={`${getUserDriverList.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            identification: response.identification,
                            merchantBranch: response.merchantBranchId,
                            phone: get(response, 'user.staffs[0].phone', ""),
                            email: get(response, 'user.staffs[0].email', ""),
                            licenseNo: response.licenseNo,
                            category: response.category,
                            status: get(response, 'user.staffs[0].status', ""),
                            staffId: get(response, 'user.staffs[0].id', ""),
                            role: {
                                id: get(response, 'user.role.id', ""),
                                name: get(response, 'user.role.name', ""),
                            },  
                            image: get(response, 'user.staffs[0].image', "") === null ? "" : get(response, 'user.staffs[0].image', ""),
                            countryCode: {
                                id: `${get(response, "user.staffs[0].countryCode.id", "")}/${get(response, "user.staffs[0].countryCode.code", "")}/${get(response, "user.staffs[0].countryCode.prefix", "")}`
                            },
                            countryCodePrefix: get(response, `user.staffs[0].countryCodePrefix`, ""),
                            staffObj: get(response, 'user.staffs[0]', {}),
                            balanceDue: get(response, 'tills[0].balanceDue', ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Update Driver</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <InputButtonWithState
                                    elementWrapperStyle={"mr-2"}
                                    startIcon={"mdi mdi-content-save-all-outline"}
                                    btnText={"Reset Password"}
                                    onClickBtnFn={() => history.push(`/users/driver/change-password/${match.params.id}`)}
                                />

                                <EditUserBtn
                                    userId={match.params.id}
                                    historyFn={history}
                                />

                                <BackBtnComponent
                                    path={"/users/driver"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                {/* <div className="col-md-6">
                                    <InputBoxWithState
                                        labelText="Driver Id"
                                        placeHolderText={"Driver Id"}
                                        inputName={"id"}
                                        formGroupName={`${getUserDriverList.key}_edit`}
                                        isRequired={true}
                                        maxLength={100}
                                        isDisabled={true}
                                    />
                                </div> */}

                                <div className="col-md-6">
                                    <SelectBoxWithState
                                        labelText={"Driver Type"}
                                        inputName={"category"}
                                        formGroupName={`${getUserDriverList.key}_edit`}
                                        dataList={driverTypes}
                                        keyName={"id"}
                                        valueName={"name"}
                                        placeHolderText={"Driver Type"}
                                        isRequired={true}
                                        isDisabled={get(formState, `${getUserDriverList.key}_edit.balanceDue`, "") === "0" ? false : true}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <SelectBoxWithState
                                        labelText="Driver Role"
                                        apiUrl={get(formState, `${getUserDriverList.key}_edit.category`, "") === "LAST" ? `${getRoleListAPI.url}/all?type=DRIVER_LAST` : `${getRoleListAPI.url}/all?type=DRIVER_LINE`}
                                        apiStateKey={getUserDriverList.key}
                                        inputName={"role"}
                                        inputStatePath={`${getUserDriverList.key}_edit.role.id`}
                                        placeHolderText={"Driver Role"}
                                        formGroupName={`${getUserDriverList.key}_edit`}
                                        isRequired={true}
                                        onRebuildResponseFn={(response) => {
                                            const data = [];
                                            response.forEach((value) => { 
                                                data.push({
                                                    id: `${value.id}`,
                                                    value: `${value.name}`,
                                                });
                                            });
 
                                            return {
                                                "data": {
                                                    "data": data
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SelectBoxWithState
                                        labelText="Driver Status"
                                        inputName={"status"}
                                        placeHolderText={"Status"}
                                        formGroupName={`${getUserDriverList.key}_edit`}
                                        dataList={userStatusList}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Personal Details</h4>

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Name"
                                                placeHolderText={"Name"}
                                                inputName={"name"}
                                                formGroupName={`${getUserDriverList.key}_edit`}
                                                isRequired={true}
                                                maxLength={100}
                                            />

                                            <InputBoxWithState
                                                labelText="Identification"
                                                placeHolderText={"Identification"}
                                                inputName={"identification"}
                                                formGroupName={`${getUserDriverList.key}_edit`}
                                                isRequired={true}
                                                maxLength={20}
                                            />

                                            <InputBoxWithState
                                                labelText="Driver's License No"
                                                placeHolderText={"Driver's License No"}
                                                inputName={"licenseNo"}
                                                formGroupName={`${getUserDriverList.key}_edit`}
                                                isRequired={true}
                                                maxLength={20}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <div className={"d-flex justify-content-center"}>
                                                <FileUpload
                                                    labelText={"Profile Picture"}
                                                    formGroupName={`${getUserDriverList.key}_edit`}
                                                    formKey={"image"}
                                                    helperText={"Supported formats (png, jpg, jpeg)"}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Contact Details</h4>

                                    <div className={"row"}>
                                        <div className="col-md-6">

                                            <div className={"row"}>
                                                <div className={"col-md-3"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Country Code"
                                                        inputStatePath={`${getUserDriverList.key}_edit.countryCode.id`}
                                                        formGroupName={`${getUserDriverList.key}_edit`}
                                                        apiUrl={`${countryCodeAPI.url}/all`}
                                                        apiStateKey={countryCodeAPI.key}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.code}/${value.prefix}`,
                                                                    value: value.code
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className={"col-md-9"}>
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"+xxxxxxxxxx"}
                                                        formGroupName={`${getUserDriverList.key}_edit`}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Email"
                                                inputName={"email"}
                                                placeHolderText={"Email"}
                                                formGroupName={`${getUserDriverList.key}_edit`}
                                                maxLength={50}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

const EditUserBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getUserDriverList.key}_edit`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: updateDriverTrKey,
                    payload: {
                        ...dataObject.formObject,
                        historyFn: props.historyFn
                    }
                })
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

const EditUIDriver = connect(mapStateToProps, null)(EditUI);

export  { EditUIDriver };