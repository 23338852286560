import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { hubSettlementAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {
  convertToIST,
  fromObjectToQueryString,
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { FormWrapper } from "../../../../components/boda-boda/Forms";
import MarkAsReceivedBtn from "./MarkAsReceivedBtn";


class PendingSettlementsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loaderStatus: true,
    };
  }


  getQueryString = () => {
    let { formState } = this.props;

    let settlementDate = new Date(get(formState, "pending_confirmation_settlement.asOfDate"));
    settlementDate = new Date(settlementDate.setDate(settlementDate.getDate() + 1));
    settlementDate = new Date(settlementDate.setSeconds(settlementDate.getSeconds() - 1));

    const queryObj = {
      settlementDateTo: convertToIST(settlementDate),
      settleFromFacilityId: get(formState, "pending_confirmation_settlement.branch")?.split("/")[0],
    };

    return `&${fromObjectToQueryString(queryObj)}`;
  };

  render() {
    let { formState } = this.props;

    return (
      <React.Fragment>
        {get(formState, "pending_confirmation_settlement.asOfDate", "") === "" ? (
          <Redirect to={"/settlements-pending-confirmation"} />
        ) : (
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">
                  Settlements Pending Confirmation
                  </h4>
                </div>

                <div className={"row"}>
                  <div className={"col-sm-12"}>
                    As of <strong className={"mx-2"}>{dateObjectToString(get(formState, "pending_confirmation_settlement.asOfDate"), "dd/mm/YY")}</strong>
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div className={"d-flex justify-content-end"}>
                  <BackBtnComponent
                    path={"/settlements-pending-confirmation"}
                  />
                </div>
              </Col>
            </Row>

            <div className={"row mt-3"}>
              <FormWrapper
                setGroupName={hubSettlementAPI.key}
                onDestroyUnsetFormObject={true}
              >
                <CrudComponent
                  title={"Settlements Pending Confirmation"}
                  apiURL={`${hubSettlementAPI.url}?page=1&limit=20&settled=true&received=false${this.getQueryString()}`}
                  dataTableStateKey={hubSettlementAPI.key}
                  isFilter={false}
                  isSetCreateAction={false}
                  isDropdownActionBtn={false}
                  isSetAction={false}
                  readyForReturn={true}
                  headerBtn={
                    <MarkAsReceivedBtn /> }
                  tableHeaderList={[
                    { displayName: "Reference Number", key: "reference" },
                    {
                      displayName: "Settlement Date",
                      key: "settlementDate",
                      onPrintFn: (rawData) =>
                        dateObjectToString(
                          rawData.settlementDate,
                          "YY-mm-dd"
                        ),
                    },
                    { displayName: "Delivery Center Code", key: "settleFromFacility.code" },
                    { displayName: "Delivery Center Name", key: "settleFromFacility.name" },
                    {
                      displayName: "Amount",
                      key: "total",
                      onPrintFn: (rawData) => {
                        return rawData.total === null ? 0 : parseFloat(rawData.total).toFixed(2);
                      },
                    },
                  ]}
                />
              </FormWrapper>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.FormData,
  };
};

export default connect(mapStateToProps, null)(PendingSettlementsView);
