import React, {Component} from "react";
import {connect} from "react-redux";
import { get } from "lodash";
import {FormWrapper} from "../../../../../../components/boda-boda/Forms";
import {getFacilityAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import { currency } from "../../../../../../config/boda-boda/db_config/db_status.config";

class DeliveryCenterViewModalUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { formState, row } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getFacilityAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getFacilityAPI.url}/${row.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: get(response, "name", ""),
                            code: get(response, "code", ""),
                            type: get(response, "type", ""),
                            pickupCost: get(response, "pickupCost", ""),
                            status: get(response, "status", ""),
                            locationName: get(response, "location.name", ""),
                            address: get(response, "location.address", ""),
                            postalCode: get(response, "location.postalCode", ""),
                            plusCode: get(response, "location.plusCode", ""),
                            lat: get(response, "location.lat", ""),
                            lon: get(response, "location.lon", ""),
                            city: get(response, "location.city.name", ""),
                            district: get(response, "location.city.district.name", ""),
                            province: get(response, "location.city.district.province.name", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Delivery Center Details</h4>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Name</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.name`, "-")}</dt>

                                <dd className={"col-sm-4"}>Type</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.type`, "-")}</dt>

                                <dd className={"col-sm-4"}>Status</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.status`, "-")}</dt>
                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Code</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.code`, "-")}</dt>

                                <dd className={"col-sm-4"}>Pick Up Cost</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.pickupCost`, "-")} {`${currency}`}</dt>
                            </dl>
                        </div>
                    </div>

                    <hr/>

                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Location Name</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.locationName`, "-")}</dt>

                                <dd className={"col-sm-4"}>Address</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.address`, "-")}</dt>

                                <dd className={"col-sm-4"}>Postal Code</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.postalCode`, "-")}</dt>

                                <dd className={"col-sm-4"}>Latitude</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.lat`, "-")}</dt>

                                <dd className={"col-sm-4"}>Longitude</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.lon`, "-")}</dt>
                            </dl>
                        </div>

                        <div className={"col-md-6"}>
                            <dl className={"row"}>

                                <dd className={"col-sm-4"}>Plus Code</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.plusCode`, "-")}</dt>

                                <dd className={"col-sm-4"}>Province</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.province`, "-")}</dt>

                                <dd className={"col-sm-4"}>District</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.district`, "-")}</dt>

                                <dd className={"col-sm-4"}>City</dd>
                                <dt className={"col-sm-8"}>{get(formState, `${getFacilityAPI.key}_view.city`, "-")}</dt>
                            </dl>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(DeliveryCenterViewModalUI)