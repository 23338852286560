import React, { Component } from "react";

import { InputBoxWithState } from "../../../../../components/boda-boda/Forms";


class DriverFilterUI extends Component {
    render() {
        return (
            <div className="col-md-12">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Name"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Identification"
                            inputName={"identification"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Identification"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Phone Number"
                            inputName={"phone"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Phone Number"}
                            maxLength={22}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default DriverFilterUI;
