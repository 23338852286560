import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import { setPageLoader, setFlashMessage } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { runSheetLineAPI, getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";
import validateMethod from '../../../helpers/boda-boda/validation';
import { setFormErrorFn } from '../form/actions';
import { resetDataTable } from "../core/actions";
import { isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";

const emptyFn = (...para) => undefined;

function* createRunSheetLine(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            runSheetAt: "Date",
            vehiclePlate: "Vehicle Number",
        },
        {
            name: "required",
            runSheetAt: "required",
            vehiclePlate: "required"
        },
        {},
    )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${runSheetLineAPI.key}_create`, validationResponse.errors));

        if (get(data, 'payload.route', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a route",
                type: "danger",
            }));
        }

        if (get(data, 'payload.driver', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger",
            }));
        }
    } else {
        yield put(setFormErrorFn(`${runSheetLineAPI.key}_create`, []));

        if (get(data, 'payload.driver', []).length === 0 || get(data, 'payload.route', []).length === 0) {
            if (get(data, 'payload.route', []).length === 0) {
                yield put(setFlashMessage({
                    status: true,
                    message: "You should have attach a route",
                    type: "danger",
                }));
            }

            if (get(data, 'payload.driver', []).length === 0) {
                yield put(setFlashMessage({
                    status: true,
                    message: "You should have attach a driver",
                    type: "danger",
                }));
            }
        } else {
            yield put(setPageLoader(true));

            // var date = new Date(get(data, 'payload.runSheetAt')); // Or the date you'd like converted.
            // var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

            let apiRequestBody = {
                name: get(data, 'payload.name', ""),
                runSheetAt: get(data, 'payload.runSheetAt'),
                vehiclePlate: get(data, 'payload.vehiclePlate', ""),
                driver: {
                    id: get(data, 'payload.driver[0].id')
                },
                routeLine: {
                    id: get(data, 'payload.route[0].id')
                },
                status: "ACTIVE",
            }

            if (!isEmptyValue(get(data, 'payload.notes', ""))) {
                apiRequestBody["notes"] = [
                    {
                        "name": get(data, "payload.authUser.name", ""),
                        "at": new Date(),
                        "note": get(data, 'payload.notes', ""),
                        "createdBy": get(data, "payload.authUser.id"),
                    }
                ]
            }

            const response = yield call(() => callApi(runSheetLineAPI.url).headers(true).method("post").body(apiRequestBody).send());

            yield put(setPageLoader(false));

            if (response._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Runsheet has successfully created",
                    type: "success",
                }));

                get(data, "payload.history.push", emptyFn)(`/runsheets-line/edit/${get(response, "data.data.id")}`);
            } else {
                if (get(response, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${runSheetLineAPI.key}_create`, get(response, "data.errors", [])));
                    yield put(setFlashMessage({
                        status: true,
                        message: get(response, "data.errors[0].error", "Something went wrong...!!"),
                        type: "danger",
                    }));
                } else if (get(response, "data.statusCode", null) === 403) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Something went wrong...!!",
                        type: "danger",
                    }));
                }
            }
        }
    }
}

function* addCollections(data) {

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getManifestListAPI.url}/${data.id}`)
        .headers(true)
        .method("put")
        .body(data.payload)
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getManifestListAPI.key}_runsheetLine`));
        yield put(resetDataTable(`${getManifestListAPI.key}_runsheetLine_selected`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

function* editRunSheetLine(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            runSheetAt: "Date",
            vehiclePlate: "Vehicle Number",
            status: "Status",
        },
        {
            name: "required",
            runSheetAt: "required",
            vehiclePlate: "required",
            status: "required",
        },
        {},
        )
    );

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${runSheetLineAPI.key}_edit`, validationResponse.errors));

        if (get(data, 'payload.selectedRoute', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a route",
                type: "danger",
            }));
        }
        else if (get(data, 'payload.selectedDriver', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger",
            }));
        }
    } else {
        yield put(setFormErrorFn(`${runSheetLineAPI.key}_edit`, []));

        if (get(data, 'payload.selectedRoute', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a route",
                type: "danger",
            }));
        }else if (get(data, 'payload.selectedDriver', []).length === 0) {
            yield put(setFlashMessage({
                status: true,
                message: "You should have attach a driver",
                type: "danger",
            }));
        } 
         else {
            yield put(setPageLoader(true));

            let apiRequestBody = {
                name: get(data, 'payload.name', ""),
                runSheetAt: get(data, 'payload.runSheetAt'),
                vehiclePlate: get(data, 'payload.vehiclePlate', ""),
                driver: {
                    id: get(data, 'payload.selectedDriver[0].id')
                },
                status: get(data, 'payload.status', ""),
                routeLine: {
                    id: get(data, 'payload.selectedRoute[0].id')
                },
            }

            if (!isEmptyValue(get(data, 'payload.note', ""))) {
                apiRequestBody["notes"] = [
                    ...get(data, 'payload.notes', []),
                    {
                        "name": get(data, "payload.authUser.name", ""),
                        "at": new Date(),
                        "note": get(data, 'payload.note', ""),
                        "createdBy": get(data, "payload.authUser.id"),
                    }
                ]
            }

            const response = yield call(() => callApi(`${runSheetLineAPI.url}/${get(data, 'payload.id')}`)
                .headers(true).method("put")
                .body(apiRequestBody)
                .send()
            );

            yield put(setPageLoader(false));

            if (response._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Runsheet has successfully updated",
                    type: "success",
                }));

                get(data, "payload.history.push", emptyFn)("/runsheets-line");
            } else {
                if (get(response, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${runSheetLineAPI.key}_edit`, get(response, "data.errors", [])));
                    yield put(setFlashMessage({
                        status: true,
                        message: get(response, "data.errors[0].error", []),
                        type: "danger",
                    }));
                } else if (get(response, "data.statusCode", null) === 403) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Something went wrong...!!",
                        type: "danger",
                    }));
                }
            }
        }
    }
}

export {
    createRunSheetLine,
    addCollections,
    editRunSheetLine
}
