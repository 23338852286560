import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    FormWrapper, InputButton,
} from "../../../../components/boda-boda/Forms";
import {
    getFacilityAPI, facilityContactAPI, getZoneAPI
} from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { currency } from "../../../../config/boda-boda/db_config/db_status.config";
// import { Volumetric } from "./Volumetric";


class ViewUI extends Component {
    render() {
        let { history, match, formState, location } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getFacilityAPI.key}_view`}
                    onDestroyUnsetFormObject={false}
                    apiUrl={`${getFacilityAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            code: response.code,
                            pickupCost: response.pickupCost,
                            status: response.status,
                            type: response.type,
                            location: response.location,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Delivery Center</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/delivery-centers"}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/delivery-centers/edit/${match.params.id}`)}
                                />

                                {
                                    (get(formState, `${getFacilityAPI.key}_view.status`, "") === "ACTIVE") ? (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Delete"}
                                            startIcon={"mdi mdi-trash-can-outline"}
                                            // onClickBtnFn={() => history.push(`/consignments/delete/${match.params.id}`)}
                                            btnColor={"danger"}
                                        />
                                    ) : (null)
                                }


                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Volumetric Weights"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/delivery-centers/view/${match.params.id}/volumetric-weight/${get(formState, `${getFacilityAPI.key}_view.name`, "")}`)}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Actual Weights"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/delivery-centers/view/${match.params.id}/actual-weight/${get(formState, `${getFacilityAPI.key}_view.name`, "")}`)}
                                />

                                {/* <Volumetric
                                    facility={get(formState, `${getFacilityAPI.key}_view`, {})}
                                    facilityId={`${match.params.id}`}
                                /> */}
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card mb-1"}>
                                <div className={"card-body"}>
                                    <h4 className="card-title mb-3">  Details</h4>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>Type</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.type`, "")}</dt>

                                                <dd className={"col-sm-5"}>Status</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.status`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Code</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.code`, "")}</dt>

                                                <dd className={"col-sm-5"}>Pick Up Cost</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.pickupCost`, "")} ({currency})</dt>
                                            </dl>
                                        </div>

                                    </div>

                                    <hr />

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Location Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>Address</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.address`, "")}</dt>

                                                <dd className={"col-sm-5"}>Postal Code</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.postalCode`, "")}</dt>

                                                <dd className={"col-sm-5"}>Latitude</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.lat`, "")}</dt>

                                                <dd className={"col-sm-5"}>Longitude</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.lon`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Plus Code</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.plusCode`, "")}</dt>

                                                <dd className={"col-sm-5"}>Province</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.city.district.province.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>District</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.city.district.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>City</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getFacilityAPI.key}_view.location.city.name`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title={"Contact Details"}
                            apiURL={`${facilityContactAPI.url}?page=1&limit=20&facilityId=${match.params.id}`}
                            dataTableStateKey={facilityContactAPI.key}
                            isSetAction={false}
                            isFilter={false}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Contact Person", key: "name" },
                                { displayName: "Phone", key: "phone" },
                                { displayName: "Email", key: "email" },
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Zones"
                            apiURL={`${getZoneAPI.url}?page=1&limit=20&facilityId=${match.params.id}`}
                            dataTableStateKey={`${getZoneAPI.key}_selected`}
                            isFilter={false}
                            isSetAction={true}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Zone ID", key: "id" },
                                { displayName: "Zone Name", key: "name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/zones/view/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </div>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const DeliveryCenterView = connect(mapStateToProps, null)(ViewUI);

export {
    DeliveryCenterView
}
