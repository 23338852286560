import React from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";

import { CrudComponent,actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import {useSelector} from "react-redux";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {FormWrapper, InputButton, SubmitButton} from "../../../../components/boda-boda/Forms";
import {getManifestListAPI, getPackageListAPI} from "../../../../config/boda-boda/apiUrls.config";
import {BackBtnComponent} from "../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import {CollectionPrint} from "./includes/collectionPrint";
import {CollectionCodePrint} from "../prints/CollectionCodePrint";
import { currency, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";


const ViewDraftUI = (props) => {
    let { history, location, match } = props; 

    const formState = useSelector(state => state.FormData);
    const url = `/runsheets-last/view/${get(formState, `${getManifestListAPI.key}_view.runSheetLineId`, "")}`;
    
    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}/compressed`}
                onRebuildResponseFn={(response) => {
                    return {
                        id: response.id,
                        code: response.code,
                        status: response.status,
                        facility: response.facility,
                        locationPick: response.locationPick,
                        locationDrop: response.locationDrop,
                        //notesPublic: response.notes[0].note,
                        note: get(response, "notes[0].note", ""),
                        locationCurrent: response.locationCurrent,
                        runSheetLastId: response.runSheetLastId,
                        runSheetLast: response.runSheetLast,
                        packages: response.packages,
                        runSheetLine: response.runSheetLine,
                        runSheetLineId: response.runSheetLineId,
                        merchant: response.merchant,
                        createdAt: response.createdAt,
                        publishedPackages: response.publishedPackages,
                        orderCount: get(response, "merchant.orderCount", ""),
                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                  <Col sm={6}>
                      <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                          <h4 className="font-size-18">View Collections</h4>
                      </div>
                  </Col>

                  <Col sm={6}>
                      <div className={"d-flex justify-content-end"}>

                            
                                
                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={`/collection`}
                                btnName={"Back"}
                                btnColor={"primary"}
                            />
                            {
                                get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT"? (
                                    <CreateBtn
                                        id={match.params.id}
                                        historyFn={history}
                                    />
                                ): null
                            }
                            <CollectionCodePrint
                                collectionCode={get(formState, `${getManifestListAPI.key}_view.code`, "")}
                            />
                            {
                                (get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT") ? (
                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/collection/edit/${match.params.id}`)}
                                />
                                ): null
                            }

                            <CollectionPrint
                                collection={get(formState, `${getManifestListAPI.key}_view`, {})}
                            />
                      </div>
                  </Col>
              </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-md-4"}>Collection Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-md-4"}>Status</dd>
                                            <dt className={"col-sm-8"}>
                                                {
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" ? "Open" 
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH" ? "Closed" 
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "") === "IN_TRANSIT_LINE" ? "Intransit to regional hub"
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "") === "COMPLETED" ? "Delivered to regional hub" 
                                                    :  get(formState, `${getManifestListAPI.key}_view.status`, "")
                                                }
                                                    
                                            </dt> 
                                            

                                            <dd className={"col-md-4"}>Destination Delivery Center</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-md-4"}>Special Notes</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.note`, "")}</dt>

                                            <dd className={"col-md-4"}>Current Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationCurrent.name`, "")}</dt>

                                            <dd className={"col-md-4"}>Pick Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            {/* <dd className={"col-md-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt> */}

                                            <dd className={"col-sm-4"}>Runsheet ID</dd>
                                            <dt className={"col-sm-8"}><a href={url}>{get(formState, `${getManifestListAPI.key}_view.runSheetLine.code`, "-")}</a></dt>

                                            <dd className={"col-sm-4"}>Packages Count</dd>
                                            <dt className={"col-sm-8"}>{
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" ? get(formState, `${getManifestListAPI.key}_view.packages.length`, "-") 
                                                    :  get(formState, `${getManifestListAPI.key}_view.publishedPackages.length`, "-")
                                                }
                                            </dt>
                                            <dd className={"col-md-4"}>Created At</dd>
                                            <dt className={"col-sm-8"}>{dateObjectToString(get(formState, `${getManifestListAPI.key}_view.createdAt`, ""))}</dt>

                                            {/* <dd className={"col-sm-5"}>Packages Count</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.orderCount`, "")}</dt> */}

                                            {/* <dd className={"col-sm-4"}>Pick Up Date</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""),"YY-mm-dd")}
                                            </dt> */}

                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={"row"}>
                    <CrudComponent
                        title="Packages"
                        apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}`}
                        //apiURL={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=5`}
                        dataTableStateKey={getManifestListAPI.key}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Article ID", key: "articleNo" },
                            { displayName: "Customer's Name", key: "order.name" },
                            { displayName: "Customer's Phone", key: "order.phone" },
                            { displayName: "Merchant Name", key: "merchant.name" },
                            { displayName: "Payment Method", key: "order.paymentMethod" },
                            { displayName: "Package Category", key: "category.name" },
                            { displayName: "Delivery Type", key: "deliveryType" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: false,
                                actionBtn: {
                                    onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                }
                            }
                        ]}
                        />
                        
                </div> */}
                <div className={"row"}>
                <CrudComponent
                        title={"Packages"}
                        apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}`}
                        dataTableStateKey={`${getPackageListAPI.key}`}
                        isFilter={false}
                        isSetCreateAction={false}
                        isSetAction={false}
                        tableHeaderList={[
                            { displayName: "Article ID", key: "articleNo" },
                            { displayName: "Channel Order No", key: "order.channelOrderNo"},
                            { displayName: "Customer Name", key: "order.name" },
                            { displayName: "Customer Address & City", key: "order.shippingLocation.address", onPrintFn: (rawData) => `${rawData?.order?.shippingLocation?.address}, ${rawData?.order?.shippingLocation?.city?.name}` },
                            { displayName: "Contact Number", key: "order.phone" },
                            { displayName: "Payment Type", key: "order.paymentMethod" },
                            { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                            { displayName: `COD Amount (${currency})`, key: "order.amount" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: false,
                                actionBtn: {
                                    onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                }
                            }
                        ]}

                    />
                </div>
           </FormWrapper>
        </div>
    );
};
const CreateBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Close Collection"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getManifestListAPI.key}_view`}
            dataTableKey={getManifestListAPI.key}
            isValidate={true}
            // validationObject={{
            //     fields: {
            //         name: "Name",
            //         code: "Code",
            //         registrationNo: "Type",
            //         status: "Status",
            //     },
            //     rules: {
            //         name: "required",
            //         code: "required",
            //         registrationNo: "required",
            //         status: "required"
            //     },
            //     message: {}
            // }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Collection is Closed",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getManifestListAPI.url}/${props.id}`,
                    key: getManifestListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                
                
                return {
                    "status": "PUBLISH"
                    
                }
                
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push(`/collection`);
                }
            }}
        />
    )
}
export { ViewDraftUI }
