import React from "react";
import {InputBoxWithState, SelectBoxWithState} from "../../../../../../components/boda-boda/Forms";
import {routeStatusList} from "../../../../../../config/boda-boda/db_config/db_status.config";
import {getAllFacilityAPI, getCityAPI, getZoneAPI} from "../../../../../../config/boda-boda/apiUrls.config";

const FilterUI = (props) => {

    return (
        <div className="col-md-12">
            <div className="row">

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Route Name"
                        inputName={"name"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Route Name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Status"}
                        inputName={"status"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Status"}
                        dataList={routeStatusList}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Waypoint"}
                        inputName={"locationId"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Waypoint"}
                        apiUrl={getAllFacilityAPI.url}
                        apiStateKey={getAllFacilityAPI.key}
                        onRebuildResponseFn={(response) => {
                            const data = [];

                            response.forEach((value) => {
                                data.push({
                                    id: value.location.id,
                                    value: value.location.name
                                });
                            });

                            return {
                                "data": {
                                    "data": data
                                }
                            }
                        }}
                    />
                </div>

            </div>
        </div>
    )
}


export default FilterUI;