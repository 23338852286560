import { put, call } from 'redux-saga/effects';
import validateMethod from "../../../helpers/boda-boda/validation";
import { get } from "lodash";
import {setFormErrorFn} from "../form/actions";
import {merchantBranchAPI, merchantBranchContactAPI} from "../../../config/boda-boda/apiUrls.config";
import {setPageLoader, setFlashMessage} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {isEmptyValue} from "../../../helpers/boda-boda/common-helpers/common.helpers";

const emptyFn = (...para) => undefined;

function* updateMerchantBranchFn(data) {

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            "location.address": "Address",
            cityId: "City",
            // registrationNo: "Business Reg. No.",
            // rating: "Rating",
            // website: "Website",
            // category: "Category",
            // contactName: "Name",
            // fullName: "Full Name",
            phone: "Phone",
            email: "Email",
        },
        {
            name: "required|max:50",
            "location.address": "required|max:100",
            cityId: "required",
            facilityId: "required",
            // rating: "required",
            // registrationNo: "required|min:8",
            // contactName: "required",
            // fullName: "required",
            phone: "required",
            email: "required|email",
            fullName: "required"
        },
        {},
    ));

    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${merchantBranchAPI.key}_create`, validationResponse.errors));

    } else {

        yield put(setPageLoader((true)));
        yield put(setFormErrorFn(`${merchantBranchAPI.key}_create`, []));

        const merchantBranchAPIRequestBody = {
            name: get(data, 'payload.name', ""),
            //pickupPrice: get(data,"payload.pickupPrice", ""),
            merchant: {
                id: get(data, "payload.merchantId", "")
            },
            facility: {
                id: get(data, "payload.facilityId", "")
            },
            location: {
                name: get(data, "payload.location.name", ""),
                address: get(data, "payload.location.address", ""),
                city: {
                    id: get(data, "payload.cityId", "")
                }
            },
        };

        // if (!isEmptyValue(get(data, 'payload.website', ""))) {
        //     merchantBranchAPIRequestBody["website"] = get(data, 'payload.website', "");
        // }

        // if (!isEmptyValue(get(data, 'payload.category', ""))) {
        //     merchantBranchAPIRequestBody["category"]["id"] = get(data, 'payload.category', "");
        // } else {
        //     merchantBranchAPIRequestBody["category"] = undefined;
        // }

        const merchantBranchAPIRequest = yield call(() => callApi(`${merchantBranchAPI.url}/${get(data, "payload.merchantBranchId", "")}`).headers(true).method("put").body(merchantBranchAPIRequestBody).send());



        if (merchantBranchAPIRequest._statue === true) {

            const merchantBranchContactAPIRequestBody = {
                fullName: get(data, 'payload.fullName', ""),
                phone: get(data, 'payload.phone', ""),
                email: get(data, 'payload.email', ""),
                // merchant: {
                //     id: get(merchantBranchAPIRequest, "data.data.id", "")
                // },
                //notification: true
            };

            const merchantBranchContactAPIRequest = yield call(() => callApi(`${merchantBranchContactAPI.url}/${get(data, "payload.contactId", "")}`).headers(true).method("put").body(merchantBranchContactAPIRequestBody).send());

            yield put(setPageLoader(false));

            if (merchantBranchContactAPIRequest._statue === true) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Merchant Branch has successfully updated",
                    type: "success",
                }));

              get(data, "payload.historyFn.push", emptyFn)(`/merchant/${get(data, "payload.merchantId")}/branch`);
            }
            // if (merchantBranchContactAPIRequest._status === true) {
            //     yield put(setFlashMessage({
            //         status: true,
            //         message: "Merchant Branch has successfully updated",
            //         type: "success",
            //     }));
            //     get(data, "payload.historyFn.push", emptyFn)("/merchant");

            // } else {
            //     console.log("Log from onNextFn")
            //     get(data, "payload.onNextFn", emptyFn)(get(merchantBranchAPIRequest, "data.data.id", ""))
            // }
        }
    }

    yield put(setPageLoader(false));
}

export {
    updateMerchantBranchFn
}