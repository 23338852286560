import {get} from "lodash";
import {call, put} from "redux-saga/effects";
import parsePhoneNumber from "libphonenumber-js";

import {resetDataTable} from "../core/actions";
import {getMerchantContactAPI, merchantBranchContactAPI} from "../../../config/boda-boda/apiUrls.config";
import validateMethod from "../../../helpers/boda-boda/validation";
import {setFormErrorFn} from "../form/actions";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {defaultCountryCode} from "../../../config/boda-boda/core.config";

const emptyFn = (...para) => undefined;

function* createMerchantContactFn(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            fullName: "Full Name",
            phone: "Phone",
            "countryCode.id": "Country Code",
            email: "Email",
            notification: "Notifications"
        },
        {
            fullName: "required",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
            email: "required|email",
            notification:  "required"
        },
        {}
    ));

    if (validationResponse._status === false){
        yield put(setFormErrorFn(`${getMerchantContactAPI.key}_create`, validationResponse.errors))
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getMerchantContactAPI.key}_create`, []))

        let notification = undefined;

        if (get(data, 'payload.notification', "") === "ENABLED") {
            notification = true;
        } else if (get(data, 'payload.notification', "") === "DISABLED") {
            notification = false;
        }

        let apiRequestBody = {
            name: get(data, 'payload.fullName', ""),
            fullName: get(data, 'payload.fullName', ""),
            phone: get(data, 'payload.phone', ""),
            email: get(data, 'payload.email', ""),
            merchantBranch: {
                id: get(data, 'payload.merchantBranchId', "")
            },
            notification: notification
        }

        if (get(countryCode, "[1]") !== "OTHER") {
            const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

            if (phoneNumber) {
                apiRequestBody["phone"] = phoneNumber.number;
            }
        }

        const response = yield call(() => callApi(merchantBranchContactAPI.url)
            .headers(true)
            .method("post")
            .body(apiRequestBody)
            .send());

        if (response._statue === true) {
            get(data, "payload.setModelStatusFn", emptyFn)(false);
            yield put(resetDataTable(merchantBranchContactAPI.key));
            yield put(setFlashMessage({
                status: true,
                message: "Contact has created successfully",
                type: "success",
            }));
        } else {
            if (get(response, "data.statusCode", 0) === 403){
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger",
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: get(response, "data.code", "Something went wrong...!!"),
                    type: "danger",
                }));
            }
        }
    }
    yield put(setPageLoader(false));
}

export {
    createMerchantContactFn
}