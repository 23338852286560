import React, { Component } from "react";

import { InputBoxWithState, RadioButton,SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import { getAllProvinceAPI, getAllDistrictAPI, getCityListAPI } from "../../../../../config/boda-boda/apiUrls.config";


class FilterUI extends Component {
    constructor(props) {
        super(props);
}

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <SelectBoxWithState
                            isRequired={false}
                            labelText="Province"
                            placeHolderText={"Province"}
                            inputName={"provinceId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={getAllProvinceAPI.url}
                            apiStateKey={getAllProvinceAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            isRequired={false}
                            labelText="District"
                            placeHolderText={"District"}
                            inputName={"districtId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={getAllDistrictAPI.url}
                            apiStateKey={getAllDistrictAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            isRequired={false}
                            labelText="City"
                            inputName={"name"}
                            placeHolderText={"Select a City"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getCityListAPI.url}/all`}
                            apiStateKey={getCityListAPI.key}
                            keyName={"name"}
                            valueName={"name"}
                        />
                    </div>
                </div>

            </div>
        )
    }
}

export  {FilterUI};
