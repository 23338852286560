import React, { Component, Fragment } from "react";
import { get } from "lodash";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent"
import { InputButton, FormWrapper } from "../../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { getPackageListAPI, getManifestListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { printModalHelperFn, isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { currency } from "../../../../../config/boda-boda/db_config/db_status.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";


class CollectionPrint extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    btnText="Print Preview"
                    onClickBtnFn={() => onClickFn()}
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fa fa-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Collection"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<LabelPrint row={this.props.collection} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLabelBtn type={"create"} />
                    }
                />
            </Fragment>
        );

    }
}

const LabelPrint = (props) => {
    return (
        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_print`}
                    apiURL={`${getManifestListAPI.url}/${get(props, `row.id`, "")}/compressed`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code: response.code,
                            status: response.status,
                            locationPick: response.locationPick,
                            locationDrop: response.locationDrop,
                            note: get(response, "notes[0].note", ""),
                            locationCurrent: response.locationCurrent,
                            packages: response.packages,
                            runSheetLine: response.runSheetLine,
                            runSheetLineId: response.runSheetLineId,
                            publishedPackages: response.publishedPackages,
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Collection Code</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.code`, "")}</dt>

                                <dd className={"col-sm-5"}>Status</dd>
                                <dt className={"col-sm-7"}>
                                    {
                                        get(props, `row.status`, "") === "DRAFT" ? "OPEN"
                                            :  get(props, `row.status`, "") === "PUBLISH" ? "CLOSED"
                                            :  get(props, `row.status`, "") === "COMPLETED" ? "Delivered to regional hub"
                                            :  get(props, `row.status`, "") === "IN_TRANSIT_LINE" ? "Intransit to regional hub"
                                            :  get(props, `row.status`, "")
                                    }
                                </dt>

                                <dd className={"col-sm-5"}>Destination Delivery Center</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationDrop.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Special Notes</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.note`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Current Location</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationCurrent.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Pick Location</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationPick.name`, "-")}</dt>

                                <dd className={"col-sm-5"}>Runsheet Id</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.runSheetLine.code`, "-")}</dt>

                                <dd className={"col-sm-5"}>Packages Count</dd>
                                <dt className={"col-sm-7"}>{
                                    get(props, `row.status`, "") === "DRAFT" ? get(props, `row.packages.length`, "-")
                                        :  get(props, `row.publishedPackages.length`, "-")
                                }
                                </dt>
                                <dd className={"col-sm-5"}>Runsheet Date</dd>
                                <dt className={"col-sm-7"}>{!isEmptyValue(get(props, `row.runSheetLine.runSheetAt`, "")) ? dateObjectToString(get(props, `row.runSheetLine.runSheetAt`, ""), "dd-mm-YY") : "-"}</dt>
                            </dl>
                        </div>

                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title={"Packages"}
                            apiURL={get(props, `row.status`, "") === "DRAFT" ?
                                `${getPackageListAPI.url}/all?manifestId=${get(props, `row.id`, "")}`
                                : `${getManifestListAPI.url}/${get(props, `row.id`, "")}/published-packages/all`
                            }
                            dataTableStateKey={`${getPackageListAPI.key}_print`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={false}
                            isPagingShow={false}
                            tableHeaderList={[
                                { displayName: "Article ID", key: "articleNo" },
                                { displayName: "Channel Order No", key: "order.channelOrderNo" },
                                { displayName: "Customer Name", key: "order.name" },
                                { displayName: "Customer Address / City", key: "order.shippingLocation.address", onPrintFn: (rawData) => `${rawData?.order?.shippingLocation?.address}, ${rawData?.order?.shippingLocation?.city?.name}` },
                                { displayName: "Contact Number", key: "order.phone" },
                                { displayName: "Payment Type", key: "order.paymentMethod" },
                                { displayName: `COD Amount (${currency})`, key: "order.amount" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        </div>
    );
}

const PrintLabelBtn = () => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => printModalHelperFn("print_preview")}
        />
    )
}

export {
    CollectionPrint,
    LabelPrint,
    PrintLabelBtn
};
