import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButton, SubmitButton
} from "../../../../components/boda-boda/Forms";
import {
    getAllLocationListAPI, getMerchantBranchAllAPI, getRoleListAPI, getUserStaffList
} from "../../../../config/boda-boda/apiUrls.config";
import { userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";


class EditUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, formState, history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Update Pricing</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <EditPackageBtn
                                packageId={match.params.id}
                                historyFn={history}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={"/delivery-pricing"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserStaffList.key}_edit`}
                        // apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                        // onDestroyUnsetFormObject={true}
                        // onRebuildResponseFn={(response) => {
                        //     return {
                        //         id: response.id,
                        //         name: response.name,
                        //         identification: response.identification,
                        //         merchantBranch: response.facilityId,
                        //         role: response.user.role.id,
                        //         phone: response.phone,
                        //         email: response.email,
                        //         category: "WAREHOUSE",
                        //         status: response.status,
                        //         "_onLoad": false,
                        //     }
                        // }}
                        >


                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Express Delivery Multiplier"
                                                placeHolderText={"Express Delivery Multiplier"}
                                                inputName={"delivery_multiplier"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Per Package Cash on Delivery</label>
                                            <InputBoxWithState
                                                labelText="%"
                                                placeHolderText={"%"}
                                                inputName={"cash_presentage"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> &nbsp;</label>
                                            <InputBoxWithState
                                                labelText="Flat Fee"
                                                placeHolderText={"Flat Fee"}
                                                inputName={"cash_flat_fee"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Per Package Card on Delivery</label>
                                            <InputBoxWithState
                                                labelText="%"
                                                placeHolderText={"%"}
                                                inputName={"card_presentage"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label> &nbsp;</label>
                                            <InputBoxWithState
                                                labelText="Flat Fee"
                                                placeHolderText={"Flat Fee"}
                                                inputName={"card_flat_fee"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="XL Package Handling Cost"
                                                placeHolderText={"XL Package Handling Cost"}
                                                inputName={"xl_cost"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={100}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Lodgement Location wise Rates</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <li>Colombo</li>
                                                    <li>Matara</li>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>

                    </Col>
                </Row>

            </div>
        );
    }
}

const EditPackageBtn = (props) => {
    return (
        <Fragment>
            <SubmitButton
                btnText={"Update"}
                elementWrapperStyle={"d-flex justify-content-end mr-2"}
                startIcon={"mdi mdi-content-save-all-outline"}
                isValidate={true}
                dataTableKey={getUserStaffList.key}
                formGroupName={`${getUserStaffList.key}_edit`}
                validationObject={{
                    fields: {
                        name: "Name",
                        email: "Email",
                        phone: "Phone",
                        identification: "identification",
                        merchantBranch: "Branch",
                        role: "Role",
                        status: "Status",
                    },
                    rules: {
                        name: "required|min:4|max:100",
                        email: "email|max:50",
                        // phone: "required|numeric|max:10",
                        identification: "required|max:20",
                        merchantBranch: "required",
                        // role: "required",
                        status: "required",
                    },
                    message: {}
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${getUserStaffList.url}/${props.packageId}`,
                        key: getUserStaffList.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    return {
                        name: formObject.name,
                        identification: formObject.identification,
                        merchantBranch: {
                            id: formObject.merchantBranch
                        },
                        phone: formObject.phone,
                        email: formObject.email,
                        category: "MERCHANT",
                        status: formObject.status,

                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.historyFn.push("/users");
                    }
                }}
            />
        </Fragment>
    )
}

export { EditUI };
