import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { getFacilityAPI } from "../../../config/boda-boda/apiUrls.config"
import { RemoveBtn, RemoveMessage } from "./includes/DeleteUI";


class DeliveryCenters extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { history, formState } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Delivery Centers</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Delivery Centers</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Delivery Center Details"
              filterFromComponent={FilterUI}
              apiURL={`${getFacilityAPI.url}?page=1&limit=20`}
              dataTableStateKey={getFacilityAPI.key}
              defaultFilterFormObject={{ name: "", code: "", status: "", cityId: "", zoneId: "", type: "" }}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: false,
                onClickBtnFn: () => history.push("/delivery-centers/create")
              }}
              isDropdownActionBtn={true}
              tableHeaderList={[
                { displayName: "Delivery Center Code", key: "code", default: "-", },
                { displayName: "Name", key: "name", default: "-", },
                { displayName: "Type", key: "type", default: "-", },
                { displayName: "Status", key: "status", default: "-", },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/delivery-centers/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/delivery-centers/edit/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                    parentToModelProps: {
                      type: "datatable",
                      displayMessage: "Are you sure you want to delete this Delivery Center?",
                      status: "INACTIVE",
                      flashMessage: "Delivery Center has successfully deleted"
                    },
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    if (rawData.rowData.status === "ACTIVE") {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

export {
  DeliveryCenters
};
