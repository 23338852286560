import {
    setAuthTokenKey, setUnauthorisedUserKey,setAuthUserObjectKey,
    requestLoginAPICallKey, updateAuthUserInfoKey, updateAuthUserPrinterType
} from "./actionTypes";
import { logoutUser } from "../../../helpers/boda-boda/common-helpers/manageStorage.helpers";
import { getDataByFormObject } from "../../../helpers/boda-boda/common-helpers/common.helpers";


export const setTokensFn = ( tokenObject) => {
    return {
        type: setAuthTokenKey,
        payload: tokenObject
    };          
}

export const unauthorisedUserFn = () => {
    logoutUser();
    return {
        type: setUnauthorisedUserKey,
    };
}

export const setAuthUserFn = (userObject) => {
    return {
        type: setAuthUserObjectKey,
        payload: userObject
    };
}

export const onLoginFn = (formObject,formGroupKey) => {  
    return {
        type: requestLoginAPICallKey,
        payload: {
            formObject:getDataByFormObject(formObject),
            formGroupKey:formGroupKey
        }
    };
}

export const updateAuthUserInfo = () => {
    return {
        type: updateAuthUserInfoKey
    }
}

export const updateAuthUserPrinterTypeFn = (type) => {
    return {
        type: updateAuthUserPrinterType,
        payload: type
    }
}
