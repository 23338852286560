import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { get } from "lodash";

import { checkUserINLocalStorage } from "../../../helpers/boda-boda/common-helpers/manageStorage.helpers";
import { setAuthTokenKey, setUnauthorisedUserKey, setAuthUserObjectKey } from "../../../store/boda-boda/auth/actionTypes";
import { getPickRequestCountFn } from "../../../store/boda-boda/ui/actions";


const BodaRoute = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    ...rest
  }) => {

    const authStatus = useSelector(state => state.AuthState);
    const dispatch = useDispatch();

    useEffect(()=>{       
        if((authStatus.isAuthenticated===null)){
          const localStoreData=checkUserINLocalStorage();
          if(localStoreData.status===true){
            dispatch({
                  type: setAuthTokenKey,
                  payload: localStoreData.result
            });

            dispatch({
                type: setAuthUserObjectKey,
                payload: localStoreData.result.authUser
            });

            dispatch(getPickRequestCountFn(get(localStoreData, "result.authUser.facility.id")));
          }else{
            dispatch({
                  type: setUnauthorisedUserKey,
              }); 
          }          
        }
     },[]);

    return(
        <Route
        {...rest}
        render={props => {
              
            if (isAuthProtected==true && authStatus.isAuthenticated==null) {
                return (<div>loading</div>)
            }else if(isAuthProtected==true && authStatus.isAuthenticated==false){
                return(
                    <Redirect
                        to={{ pathname: "/login", state: { from: props.location } }}
                    />
                )
            }else if(isAuthProtected==false && authStatus.isAuthenticated==true){
                return(
                    <Redirect
                        to={{ pathname: "/", state: { from: props.location } }}
                    />
                )    
            }else if(isAuthProtected==false || authStatus.isAuthenticated==true){
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }            
        }}
        />
    )
}

export default BodaRoute;
