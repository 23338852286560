import React, { Component } from "react";
import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { getContractAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { get } from "lodash";
import { dateObjectToString, yearsDiff } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { currency } from "../../../../../config/boda-boda/db_config/db_status.config";
import { setPageLoader } from "../../../../../store/boda-boda/ui/actions";
import { InputButton } from "../../../../../components/boda-boda/Forms";
import { downloadCSV } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";

class ViewUI extends Component {
    constructor(props) {
        super(props);
    }

    onClickDownloadFn = () => {
        let { formState, setPageLoader } = this.props;

        setPageLoader(true);

        const csvDataList = [
            ["Contract Name", get(formState, `${getContractAPI.key}_view.name`, "")],
            ["Contract Effective Period (Years)", yearsDiff(get(formState, `${getContractAPI.key}_view.start`, ""), dateObjectToString(get(formState, `${getContractAPI.key}_view.end`, ""), "YY-mm-dd"))],
            ["Contract Effective", "From:" + dateObjectToString(get(formState, `${getContractAPI.key}_view.start`, ""), "YY-mm-dd") + " To:" + dateObjectToString(get(formState, `${getContractAPI.key}_view.end`, ""), "YY-mm-dd")],
            ["Upon Contract Expiry", get(formState, `${getContractAPI.key}_view.continuation`, true) ? ("Refer to base table and continue current discount") : (">Refer to base table and discontinue current discount")],
            ["XL package handling fee", get(formState, `${getContractAPI.key}_view.xlPackageHandling`, "") + " " + currency],
            ["Discount", get(formState, `${getContractAPI.key}_view.discount`, "") + " %"],
            ["Cash Charging Value ", `${get(formState, `${getContractAPI.key}_view.cashDelivery`, "")} ${(get(formState, `${getContractAPI.key}_view.cashChargingType`, "") === "PERCENTAGE") ? " %" : ` ${currency}`}`],
            ["Card Charging Value ", `${get(formState, `${getContractAPI.key}_view.cardDelivery`, "")} ${(get(formState, `${getContractAPI.key}_view.cardChargingType`, "") === "PERCENTAGE") ? " %" : ` ${currency}`}`],
            ["Express Delivery Charge Multiplier", get(formState, `${getContractAPI.key}_view.expressMultiplier`, "")],
            ["Settlement period (Days)", get(formState, `${getContractAPI.key}_view.settlementPeriod`, "")],
            ["Flat Rate", get(formState, `${getContractAPI.key}_view.flatRate`, "")],
            ["Maximum Number of Packages", get(formState, `${getContractAPI.key}_view.maxPackageCount`, "")],
            ["Charging Method", get(formState, `${getContractAPI.key}_view.chargingMethod`, "-") === "VOLUMETRIC" ? "Volumetric Weight Based" : get(formState, `${getContractAPI.key}_view.chargingMethod`, "-") === "WEIGHT" ? "Actual Weight Based" : "-"],
            ["Contract Type", get(formState, `${getContractAPI.key}_view.pricingModelType`, "")],
        ];

        const finalData = csvDataList.map(e => e.join(",")).join("\n");
        downloadCSV(finalData, get(formState, `${getContractAPI.key}_view.name`, ""));

        setPageLoader(false);
    }

    render() {
        let { match, formState, location } = this.props;
        return (
            <div className="container-fluid">
                <FormWrapper
                    apiUrl={`${getContractAPI.url}/${match.params.id}`}
                    setGroupName={`${getContractAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            start: response.start,
                            end: response.end,
                            discount: response.discount,
                            expressMultiplier: response.expressMultiplier,
                            xlPackageHandling: response.xlPackageHandling,
                            cashChargingType: response.cashChargingType,
                            cashDelivery: response.cashDelivery,
                            cardChargingType: response.cardChargingType,
                            cardDelivery: response.cardDelivery,
                            continuation: response.continuation,
                            maxPackageCount: response.maxPackageCount,
                            flatRate: response.flatRate,
                            pricingModelType: response.pricingModelType,
                            settlementPeriod: response.settlementPeriod,
                            chargingMethod: response.chargingMethod,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Contract</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={`/merchant/${location.pathname.split('/')[2]}/contract`}
                                />

                                <InputButton
                                    btnText={"Download"}
                                    startIcon={"fas fa-file-download"}
                                    onClickBtnFn={() => this.onClickDownloadFn()}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dd className={"col-sm-4"}>Contract Type</dd>
                                                <dt className={"col-sm-7"}>{(get(formState, `${getContractAPI.key}_view.pricingModelType`, "") === "TYPE_ONE" ? "Type 1" : "Type 2")}</dt>

                                                <dd className={"col-sm-4"}>Contract Name</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Contract Effective</dd>
                                                <dt className={"col-sm-8"}>From: {dateObjectToString(get(formState, `${getContractAPI.key}_view.start`, ""), "YY-mm-dd")} To: {get(formState, `${getContractAPI.key}_view.end`, "") ? dateObjectToString(get(formState, `${getContractAPI.key}_view.end`, ""), "YY-mm-dd") : "Indefinite"}</dt>

                                                <dd className={"col-sm-4"}>XL package handling fee</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.xlPackageHandling`, "")} {currency}</dt>

                                                <dd className={"col-sm-4"}>Cash Charging Value</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.cashDelivery`, "")}{(get(formState, `${getContractAPI.key}_view.cashChargingType`, "") === "PERCENTAGE") ? " %" : ` ${currency}`}</dt>

                                                <dd className={"col-sm-4"}>Settlement period (Days)</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.settlementPeriod`, "")}</dt>

                                                <dd className={"col-sm-4"}>Express Delivery Charge Multiplier</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.expressMultiplier`, "")}</dt>

                                                <dd className={"col-sm-4"}>Card Charging Value</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.cardDelivery`, "")}{(get(formState, `${getContractAPI.key}_view.cardChargingType`, "") === "PERCENTAGE") ? " %" : ` ${currency}`}</dt>

                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dd className={"col-sm-4"}>Contract Effective Period (Years)</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.end`, "") ? yearsDiff(get(formState, `${getContractAPI.key}_view.start`, ""), dateObjectToString(get(formState, `${getContractAPI.key}_view.end`, ""), "YY-mm-dd")) : "Indefinite"}</dt>

                                                <dd className={"col-sm-4"}>Upon Contract Expiry</dd>
                                                {
                                                    (get(formState, `${getContractAPI.key}_view.continuation`, true) === true) ? (
                                                        <dt className={"col-sm-8"}>Refer to base table and continue current discount</dt>
                                                    ) : (
                                                        <dt className={"col-sm-8"}>Refer to base table and discontinue current discount</dt>
                                                    )
                                                }

                                                <dd className={"col-sm-4"}>Discount</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.discount`, "")} %</dt>

                                                <dd className={"col-sm-4"}>Flat Rate</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getContractAPI.key}_view.flatRate`, "") === null ? "-" : `${get(formState, `${getContractAPI.key}_view.flatRate`, "")} ${currency}`}</dt>

                                                <dd className={"col-sm-4"}>Maximum Number of Packages per Order</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.maxPackageCount`, "-")}</dt>

                                                <dd className={"col-sm-4"}>Charging Method</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getContractAPI.key}_view.chargingMethod`, "-") === "VOLUMETRIC" ? "Volumetric Weight Based" : get(formState, `${getContractAPI.key}_view.chargingMethod`, "-") === "WEIGHT" ? "Actual Weight Based" : "-"}</dt>

                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, { setPageLoader })(ViewUI);

export { ViewUIWithState };