import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import { countryCodeAPI, getRoleListAPI, getUserDriverList } from "../../../../config/boda-boda/apiUrls.config";
import { createDriverTrKey } from "../../../../store/boda-boda/transaction_request/actionTypes";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { driverTypes } from "../../../../config/boda-boda/db_config/db_status.config";
import FileUpload from "../../../../components/boda-boda/FileUpload/FileUpload";


class CreateUI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "LAST"
        }

    }

    changeType = (eventData) => {
        this.setState({
            type: eventData.value
        })
    }

    render() {
        let { history } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create New Driver</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={""}
                                path={"/users/driver"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserDriverList.key}_create`}
                            onDestroyUnsetFormObject={true}
                        >
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText={"Driver Type"}
                                                        inputName={"type"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        dataList={driverTypes}
                                                        keyName={"id"}
                                                        valueName={"name"}
                                                        placeHolderText={"Driver Type"}
                                                        isRequired={true}
                                                        onChangeFn={(eventData) => this.changeType(eventData)}
                                                    />

                                                    <SelectBoxWithState
                                                        labelText="Driver Role"
                                                        apiUrl={this.state.type === "LAST" ? `${getRoleListAPI.url}/all?type=DRIVER_LAST` : `${getRoleListAPI.url}/all?type=DRIVER_LINE`}
                                                        apiStateKey={getUserDriverList.key}
                                                        inputName={"role"}
                                                        placeHolderText={"Driver Role"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];
                                                            response.forEach((value) => {     
                                                                if (value.status==='ACTIVE') {                                                    
                                                                data.push({
                                                                    id: `${value.id}`,
                                                                    value: `${value.name}`,
                                                                });
                                                            }});

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />

                                                    <InputBoxWithState
                                                        labelText="Name"
                                                        placeHolderText={"Name"}
                                                        inputName={"name"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={100}
                                                    />

                                                    <InputBoxWithState
                                                        labelText="Identification"
                                                        placeHolderText={"Identification"}
                                                        inputName={"identification"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />

                                                    <InputBoxWithState
                                                        labelText="Driver's License No"
                                                        placeHolderText={"Driver's License No"}
                                                        inputName={"licenseNo"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <div className={"d-flex justify-content-center"}>
                                                        <FileUpload
                                                            labelText={"Profile Picture"}
                                                            formGroupName={`${getUserDriverList.key}_create`}
                                                            formKey={"image"}
                                                            helperText={"Supported formats (png, jpg, jpeg)"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">

                                                    <div className={"row"}>
                                                        <div className={"col-md-3"}>
                                                            <SelectBoxWithState
                                                                isRequired={true}
                                                                labelText="Country Code"
                                                                inputStatePath={`${getUserDriverList.key}_create.countryCode.id`}
                                                                formGroupName={`${getUserDriverList.key}_create`}
                                                                apiUrl={`${countryCodeAPI.url}/all`}
                                                                apiStateKey={countryCodeAPI.key}
                                                                onRebuildResponseFn={(response) => {
                                                                    const data = [];

                                                                    response.forEach((value) => {
                                                                        data.push({
                                                                            id: `${value.id}/${value.code}/${value.prefix}`,
                                                                            value: value.code
                                                                        });
                                                                    });

                                                                    return {
                                                                        "data": {
                                                                            "data": data
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className={"col-md-9"}>
                                                            <InputBoxWithState
                                                                labelText="Phone"
                                                                inputName={"phone"}
                                                                placeHolderText={"+xxxxxxxxxx"}
                                                                formGroupName={`${getUserDriverList.key}_create`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        isRequired={true}
                                                        labelText="Email"
                                                        inputName={"email"}
                                                        placeHolderText={"Email"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        maxLength={50}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Username"
                                                        inputName={"credential"}
                                                        placeHolderText={"Username"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Password"
                                                        inputName={"password"}
                                                        placeHolderText={"Enter Password"}
                                                        inputType={"password"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Confirm Password"
                                                        inputName={"confirm_password"}
                                                        placeHolderText={"Enter Confirm Password"}
                                                        inputType={"password"}
                                                        formGroupName={`${getUserDriverList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        );
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getUserDriverList.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createDriverTrKey,
                    payload: {
                        ...dataObject.formObject,
                        canSwitch: false,
                        historyFn: props.historyFn
                    }
                })
            }}
        />
    )
}

export { CreateUI };
