import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";

import {SubmitButton} from "../../../../components/boda-boda/Forms";
import {getPackageListAPI,getZoneAPI} from "../../../../config/boda-boda/apiUrls.config";
import {apiResponseStoringType} from "../../../../config/boda-boda/apiResponseKey";
import {getDataByFormObject} from "../../../../helpers/boda-boda/common-helpers/common.helpers";

const RemoveMessage = (props) => {
    let { history, location, match } = props;

    return(
        
    <p>Are your sure you want to delete this Zone ?</p>
    );
}

const RemoveBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Delete"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            dataTableKey={getZoneAPI.key}
            isValidate={false}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                console.log("---------------------------------------->", formObject)
                return {
                    url: `${getZoneAPI.url}/${props.row.id}`,
                    key: getZoneAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    );
}

export {
    RemoveBtn,
    RemoveMessage
}