import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { SubmitButton } from "../../../../components/boda-boda/Forms";
import { getFacilityAPI, getPackageListAPI, getZoneAPI } from "../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";

const RemoveMessage = (props) => {
    let { history, location, match } = props;

    return (
        <p>{props.displayMessage}</p>
    );
}

const RemoveBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Delete"}
            elementStyle={"btn btn-danger  waves-effect waves-light"}
            dataTableKey={getFacilityAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: props.flashMessage,
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getFacilityAPI.url}/${props.row.id}`,
                    key: getFacilityAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {

                // let data = getDataByFormObject(formObject);

                // let temp = {
                //     ...data,
                //     status: props.status
                // }

                // return temp;

                return {
                    "status": props.status
                }

            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    );
}

export {
    RemoveBtn,
    RemoveMessage
}