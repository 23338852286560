import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { BackBtnComponent } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import {CrudComponent} from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { FormWrapper, InputButton } from "../../../../../../components/boda-boda/Forms";
import {getLastMileAPI, getRouteLastCityAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import { get } from "lodash";

class ViewUI extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        let { match, history, formState  } = this.props;
        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getLastMileAPI.key}_view`}
                    apiUrl={`${getLastMileAPI.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            status: response.status,
                            name: response.name,
                            cities: response.cities,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Pick Up/Last Route</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/routes/last-mile"}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/routes/last-mile/edit/${match.params.id}`)}
                                />

                                <InputButton
                                    elementWrapperStyle={""}
                                    btnText={"Delete"}
                                    startIcon={"mdi mdi-trash-can-outline"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dd className={"col-sm-4"}>Route ID</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getLastMileAPI.key}_view.id`, "")}</dt>

                                                <dd className={"col-sm-4"}>Route Name</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getLastMileAPI.key}_view.name`, "")}</dt>

                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dd className={"col-sm-4"}>Type</dd>
                                                <dt className={"col-sm-8"}>LASTMILE</dt>

                                                <dd className={"col-sm-4"}>Route Status</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getLastMileAPI.key}_view.status`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                    <h4 className="card-title mb-4">Waypoints</h4>

                                    <CrudComponent
                                        title={"Cities"}
                                        apiURL={`${getRouteLastCityAPI.url}?routeLastId=${match.params.id}&page=1&limit=20`}
                                        isSetCreateAction={false}
                                        isSetAction={false}
                                        isFilter={false}
                                        tableHeaderList={[
                                            { displayName: "No", key: "id" },
                                            { displayName: "City", key: "city.name" },
                                            { displayName: "District", key: "city.district.name" },
                                            { displayName: "Province", key: "city.district.province.name" },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export { ViewUIWithState };