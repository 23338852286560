import React from "react";
import {FormWrapper, InputBoxWithState, SelectBoxWithState} from "../../../../../../components/boda-boda/Forms";
import {countryCodeAPI, getMerchantContactAPI} from "../../../../../../config/boda-boda/apiUrls.config";
import {notifications} from "../../../../../../config/boda-boda/db_config/db_status.config";

const CreateUI = (props) => {
    return (
        <FormWrapper
            setGroupName={`${getMerchantContactAPI.key}_create`}
            onDestroyUnsetFormObject={true}
        >
            <InputBoxWithState
                labelText={"Full Name"}
                placeHolderText={"Full Name"}
                formGroupName={`${getMerchantContactAPI.key}_create`}
                inputName={"fullName"}
                isRequired={true}
            />

            <div className={"row"}>
                <div className="col-md-12">
                    <div className={"row"}>
                        <div className={"col-md-3"}>
                            <SelectBoxWithState
                                isRequired={true}
                                labelText="Country Code"
                                inputStatePath={`${getMerchantContactAPI.key}_create.countryCode.id`}
                                formGroupName={`${getMerchantContactAPI.key}_create`}
                                apiUrl={`${countryCodeAPI.url}/all`}
                                apiStateKey={countryCodeAPI.key}
                                onRebuildResponseFn={(response) => {
                                    const data = [];

                                    response.forEach((value) => {
                                        data.push({
                                            id: `${value.id}/${value.code}/${value.prefix}`,
                                            value: value.code
                                        });
                                    });

                                    return {
                                        "data": {
                                            "data": data
                                        }
                                    }
                                }}
                            />
                        </div>

                        <div className={"col-md-9"}>
                            <InputBoxWithState
                                labelText="Phone"
                                inputName={"phone"}
                                placeHolderText={"+XXXXXXXXXXX"}
                                formGroupName={`${getMerchantContactAPI.key}_create`}
                                isRequired={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <InputBoxWithState
                labelText={"Email"}
                placeHolderText={"Email"}
                formGroupName={`${getMerchantContactAPI.key}_create`}
                inputName={"email"}
                maxLength={50}
                isRequired={true}
            />

            <SelectBoxWithState
                labelText={"Notifications"}
                placeHolderText={"Notifications"}
                formGroupName={`${getMerchantContactAPI.key}_create`}
                inputName={"notification"}
                dataList={notifications}
                isRequired={true}
            />
        </FormWrapper>
    )
}

export {
    CreateUI
}