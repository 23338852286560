import { all, fork, takeEvery, put, call } from 'redux-saga/effects';
import { get } from "lodash";
import { setFlashMessage, setPickRequestCountFn } from "./actions";
import { addFlashMessageKey, getPickRequestCountKey } from "./actionTypes";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";

function* addFlashMessageFn(data) {
    yield put(setFlashMessage(data.payload));
    //yield put(removeFlashMessage());
    //setTimeout(() => put(removeFlashMessage()), 5000);
}

function* getPickRequestCountFn(data) {
    const url = `${getManifestListAPI.url}/compressed?page=1&limit=2&type=MERCHANT&facilityId=${data.facilityId}&statuses=["PUBLISH","ACCEPTED","COMPLETED","IN_TRANSIT_FIRST","REJECTED"]&status=PUBLISH`

    const manifestAPIResponse = yield call(() =>
        callApi(url)
            .method("get")
            .headers(true)
            .send()
    );

    if (manifestAPIResponse._statue === true) {
        yield put(setPickRequestCountFn(get(manifestAPIResponse, "data.meta.totalItems", 0)));
    }
}

function* watchFlashMessageArrayInit() {
    yield takeEvery(addFlashMessageKey, addFlashMessageFn);
}

function* watchGetPickRequestCountFn() {
    yield takeEvery(getPickRequestCountKey, getPickRequestCountFn);
}

function* uiSaga() {
    yield all([
        fork(watchFlashMessageArrayInit),
        fork(watchGetPickRequestCountFn)
    ])
}

export default uiSaga;
