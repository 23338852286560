import LoginPage from "../pages/boda-boda/auth/LoginPage";
import ForgetPassword from "../pages/boda-boda/auth/FogetPassword";
import ForgetPasswordOTP from "../pages/boda-boda/auth/FogetPasswordOTP";
import ForgetPasswordOTPVerify from "../pages/boda-boda/auth/FogetPasswordOTPVerify";
import ForgetPasswordReset from "../pages/boda-boda/auth/FogetPasswordReset";
import Consignments from "../pages/boda-boda/consignments/Consignments";
import { ConsignmentView } from "../pages/boda-boda/consignments/includes/ViewUI";
import { ConsignmentEdit } from "../pages/boda-boda/consignments/includes/EditUI";
import Packages from "../pages/boda-boda/packages/Packages";
import { PackageView } from "../pages/boda-boda/packages/includes/ViewUI";
import { PackageView as PackageCommonView } from "../pages/boda-boda/packages/common_include/ViewUI";
import { PackageEdit } from "../pages/boda-boda/packages/includes/EditUI";
import PickRequests from "../pages/boda-boda/pick-request/PickRequests";
import PickRequestView from "../pages/boda-boda/pick-request/includes/ViewUI";
import PickRequestEdit from "../pages/boda-boda/pick-request/includes/EditUI";
import UserUI from "../pages/boda-boda/users/UserUI";
import UserCreate from "../pages/boda-boda/users/user_includes/CreateUI";
import UserEdit from "../pages/boda-boda/users/user_includes/EditUI";
import { ViewUIWithState as UserView } from "../pages/boda-boda/users/user_includes/ViewUI";
import Dashboard from "../pages/Dashboard";
import Driver from "../pages/boda-boda/driver/Driver";
import { ViewUIWithState as DriverView } from "../pages/boda-boda/driver/driver_includes/ViewUI";
import { CreateUI as DriverCreate } from "../pages/boda-boda/driver/driver_includes/CreateUI";
import { EditUIDriver as DriverEdit } from "../pages/boda-boda/driver/driver_includes/EditUI";
import Customer from "../pages/boda-boda/customer/Customer";
import { CreateUI as CustomerCreate } from "../pages/boda-boda/customer/customer_includes/CreateUI";
import { EditUI as CustomerEdit } from "../pages/boda-boda/customer/customer_includes/EditUI";
import { ViewUIWithState as CustomerrView } from "../pages/boda-boda/customer/customer_includes/ViewUI";
import { ViewUIWithState as DeliveryCharges } from "../pages/boda-boda/delivery-charges/DeliveryCharge";
import { EditUI as DiliveryChargeEdit } from "../pages/boda-boda/delivery-charges/includes/EditUI";
import { PricingEditUI as DiliveryChargePriceEdit } from "../pages/boda-boda/delivery-charges/includes/PricingEditUI";
import { ViewUIWithState as DeliveryPrice } from "../pages/boda-boda/delivery-price/DeliveryPrice";
import { EditUI as DiliveryPriceEdit } from "../pages/boda-boda/delivery-price/includes/EditUI";
import { DeliveryCenters } from "../pages/boda-boda/delivery-centers/DeliveryCenters";
import { CreateUI as DeliveryCenterCreate } from "../pages/boda-boda/delivery-centers/includes/CreateUI";
import { DeliveryCenterEdit } from "../pages/boda-boda/delivery-centers/includes/EditUI";
import { DeliveryCenterView } from "../pages/boda-boda/delivery-centers/includes/ViewUI";
import Collections from "../pages/boda-boda/collections/Collection";
import { Zones } from "../pages/boda-boda/zones/Zones";
import { ZoneCreate } from "../pages/boda-boda/zones/includes/CreateUI";
import { ZoneEdit } from "../pages/boda-boda/zones/includes/EditUI";
import { ZoneView } from "../pages/boda-boda/zones/includes/ViewUI";
import { Inbounds } from "../pages/boda-boda/inbounds/Inbounds";
import { Outbounds } from "../pages/boda-boda/outbounds/Outbounds";
import { ViewUI as OutboundsView } from "../pages/boda-boda/outbounds/includes/ViewUI";
import { ViewUIWithState as ViewLastMileRoute } from "../pages/boda-boda/routes/all-routes/includes/last-mile-includes/ViewUI";
import { ViewUIWithState as ViewLinehaulRoute } from "../pages/boda-boda/routes/all-routes/includes/linehaul-includes/ViewUI";
import AllMerchant from "../pages/boda-boda/_merchant/profile/AllMerchant";
import { ResumeUIWithState as ResumeMerchant } from "../pages/boda-boda/_merchant/profile/includes/ResumeUI"
import { CreateUIWithState as CreateMerchant } from "../pages/boda-boda/_merchant/profile/includes/CreateUI";
import { ViewUIWithState as ViewMerchant } from "../pages/boda-boda/_merchant/profile/includes/ViewUI";
import { EditUIWithState as EditMerchant } from "../pages/boda-boda/_merchant/profile/includes/EditUI";
import { CreateUIWithState as CreatePickupDetails } from "../pages/boda-boda/_merchant/pickup-details/PickupDetails";
import { CreateUIWithState as CreateLodgmentLocation } from "../pages/boda-boda/_merchant/lodgment-location/LodgmentLocation";
import UserRole from "../pages/boda-boda/user-role/UserRole";
import { CreateUI as CreateUserRole } from "../pages/boda-boda/user-role/includes/CreateUI";
import { EditUI as EditUserRole } from "../pages/boda-boda/user-role/includes/EditUI";
import RunSheetLast from "../pages/boda-boda/runsheets/runsheets-last/RunSheetLast";
import UserProfile from "../pages/boda-boda/users-profile/UserProfile";
import EditProfile from "../pages/boda-boda/users-profile/EditProfile";
import { CreateMerchantTemplate } from "../pages/boda-boda/_merchant/template/MerchantTemplate";
import Delivery from "../pages/boda-boda/driver/Delivery";
import Till from "../pages/boda-boda/tills/Till";
import CreateTill from "../pages/boda-boda/tills/includes/CreateUI";
import Contract from "../pages/boda-boda/_merchant/contract/Contract";
import { ViewUIWithState as ViewContract } from "../pages/boda-boda/_merchant/contract/includes/ViewUI";
import { CreateUIWithState as CreateContract } from "../pages/boda-boda/_merchant/contract/includes/CreateUI";
import { Branch } from "../pages/boda-boda/_merchant/manage-branch/Branch";
import { CreateUI as MerchantBranchCreate } from "../pages/boda-boda/_merchant/manage-branch/includes/CreateUI";
import { ViewUIWithState as MerchantBranchView } from "../pages/boda-boda/_merchant/manage-branch/includes/ViewUI";
import { EditUIWithState as MerchantBranchEdit } from "../pages/boda-boda/_merchant/manage-branch/includes/BranchEdit";
import { VolumetricView as BranchVolumetricView } from "../pages/boda-boda/_merchant/manage-branch/Volumetric"
import { WeightView as BranchWeightView } from "../pages/boda-boda/_merchant/manage-branch/Weight"
import LastMile from "../pages/boda-boda/routes/all-routes/LastMile";
import Linehaul from "../pages/boda-boda/routes/all-routes/Linehaul";
import { CreateLastMileWithState } from "../pages/boda-boda/routes/all-routes/includes/last-mile-includes/CreateLastMile";
import { CreateLinehaulWithState } from "../pages/boda-boda/routes/all-routes/includes/linehaul-includes/CreateLinehaul"
import RunSheetLine from "../pages/boda-boda/runsheets/runsheets-line/RunSheetLine";
import CreateRunsheetLine from "../pages/boda-boda/runsheets/runsheets-line/includes/CreateRunsheetLine";
import { ViewUI as InboundsView } from "../pages/boda-boda/inbounds/includes/View";
import { ViewUI } from "../pages/boda-boda/collections/collection-includes/ViewUI";
import { ViewDraftUI } from "../pages/boda-boda/collections/collection-includes/ViewDraft";
import CreateCollection from "../pages/boda-boda/collections/collection-includes/CreateUI";
import CollectionPrint from "../pages/boda-boda/collections/prints/CollectionBulkTagPrint";
import EditRunsheetLine from "../pages/boda-boda/runsheets/runsheets-line/includes/EditRunsheetLine";
import ViewRunsheetLine from "../pages/boda-boda/runsheets/runsheets-line/includes/ViewRunsheetLine";
import CreateRunsheetLast from "../pages/boda-boda/runsheets/runsheets-last/includes/CreateRunsheetLast";
import EditRunsheetLast from "../pages/boda-boda/runsheets/runsheets-last/includes/EditRunsheetLast";
import ViewRunsheetLast from "../pages/boda-boda/runsheets/runsheets-last/includes/ViewRunsheetLast";
import { UpdateLastMileWithState } from "../pages/boda-boda/routes/all-routes/includes/last-mile-includes/UpdateLastMile";
import { UpdateLinehaulWithState } from "../pages/boda-boda/routes/all-routes/includes/linehaul-includes/UpdateLinehaul";
import { VolumetricView } from "../pages/boda-boda/delivery-centers/includes/Volumetric";
import { ChangePasswordUIWithState as ChangeStaffPassword } from "../pages/boda-boda/users/user_includes/ChangePasswordUI";
import { ChangePasswordUIWithState as ChangeDriverPassword } from "../pages/boda-boda/driver/driver_includes/ChangePasswordUI"
import DriverRunSheetLine from "../pages/boda-boda/driver/runsheets-include/DriverRunSheetLine";
import DriverRunSheetLast from "../pages/boda-boda/driver/runsheets-include/DriverRunSheetLast";
import CreateDriverRunsheetLine from "../pages/boda-boda/driver/runsheets-include/CreateDriverRunsheetLine";
import CreateDriverRunsheetLast from "../pages/boda-boda/driver/runsheets-include/CreateDriverRunsheetLast";
import AdminUser from "../pages/boda-boda/_merchant/admin-users/AdminUser";
import { CreateUI as CreateAdminUser } from "../pages/boda-boda/_merchant/admin-users/user_includes/CreateUI";
import { ViewUIWithState as ViewAdminUser } from "../pages/boda-boda/_merchant/admin-users/user_includes/ViewUI";
import { EditUIWithState as EditAdminUser } from "../pages/boda-boda/_merchant/admin-users/user_includes/EditUI";
import { ChangePasswordUIWithState as ChangeAdminUserPassword } from "../pages/boda-boda/_merchant/admin-users/user_includes/ChangePasswordUI";
import ReconciliationReport from "../pages/boda-boda/reports/reconciliation-report/ReconciliationReport";
import CashCardOnDelivery from "../pages/boda-boda/reports/cash-card-on-delivery-report/CashCardOnDelivery";
import ReconciliationReportView from "../pages/boda-boda/reports/reconciliation-report/includes/ReconciliationReportView";
import CashCardOnDeliveryView from "../pages/boda-boda/reports/cash-card-on-delivery-report/includes/CashCardOnDeliveryView";
import PackageWiseCostingReport from "../pages/boda-boda/reports/package-wise-costing-report/PackageWiseCostingReport";
import PackageWiseCostingReportView from "../pages/boda-boda/reports/package-wise-costing-report/includes/PackageWiseCostingReportView";
import SAGEMerchantInvoiceReport from "../pages/boda-boda/reports/sage-merchant-report/SageMerchantReport";
import SAGEDriverPaymentReport from "../pages/boda-boda/reports/sage-driver-report/SageDriverReports";
import { WeightView } from "../pages/boda-boda/delivery-centers/includes/Weight";
import AddPickUp from "../pages/boda-boda/runsheets/runsheets-last/includes/AddPickUp";
import PackageStatusReport from "../pages/boda-boda/reports/package-status-report/PackageStatusReport"
import PackageStatusReportView from "../pages/boda-boda/reports/package-status-report/includes/PackageStatusReportView"
import { DriverSettlement } from "../pages/boda-boda/CashOnDeliveryManagement/DriverSettlement/DriverSettlement";
import { DriverSettlementView } from "../pages/boda-boda/CashOnDeliveryManagement/DriverSettlement/includes/DriverSettlementView";
import { MerchantSettlement } from "../pages/boda-boda/CashOnDeliveryManagement/MerchantSettlement/MerchantSettlement";
import { MerchantSettlementView } from "../pages/boda-boda/CashOnDeliveryManagement/MerchantSettlement/includes/MerchantSettlementView"

import { HubSettlement } from "../pages/boda-boda/CashOnDeliveryManagement/HubSettlement/HubSettlement";
import { HubSettlementView } from "../pages/boda-boda/CashOnDeliveryManagement/HubSettlement/includes/HubSettlementView";
import { HubSettlementBalanceView } from "../pages/boda-boda/CashOnDeliveryManagement/HubSettlementBalance/includes/HubSettlementBalanceView";
import { HubSettlementBalance } from "../pages/boda-boda/CashOnDeliveryManagement/HubSettlementBalance/HubSettlementBalance";
import MerchantSettlementReportView from "../pages/boda-boda/reports/merchant-settlement-transaction-report/includes/MerchantSettlementReportView";
import MerchantSettlementReport from "../pages/boda-boda/reports/merchant-settlement-transaction-report/MerchantSettlementReport";
import HubSettlementReport from "../pages/boda-boda/reports/hub-settlement-transaction-report/HubSettlementReport"
import HubSettlementReportView from "../pages/boda-boda/reports/hub-settlement-transaction-report/includes/HubSettlementReportView"
import PendingSettlements from "../pages/boda-boda/pending-settlements/PendingSettlements";
import PendingSettlementsView from "../pages/boda-boda/pending-settlements/includes/PendingSettlementsView";
const bodaRoutes = [
    { path: "/", component: Dashboard },
    { path: "/users/staff", component: UserUI },
    { path: "/users/staff/view/:id", component: UserView },
    { path: "/users/staff/create", component: UserCreate },
    { path: "/users/staff/edit/:id", component: UserEdit },
    { path: "/users/staff/change-password/:id", component: ChangeStaffPassword },
    { path: "/users/driver", component: Driver },
    { path: "/users/driver/view/:id", component: DriverView },
    { path: "/users/driver/create", component: DriverCreate },
    { path: "/users/driver/edit/:id", component: DriverEdit },
    { path: "/users/driver/change-password/:id", component: ChangeDriverPassword },
    { path: "/customer", component: Customer },
    { path: "/customer/view/:id", component: CustomerrView },
    { path: "/customer/create", component: CustomerCreate },
    { path: "/customer/edit/:id", component: CustomerEdit },
    { path: "/consignments", component: Consignments },
    { path: "/consignments/view/:id", component: ConsignmentView },
    { path: "/consignments/edit/:id", component: ConsignmentEdit },
    { path: "/packages", component: Packages },
    { path: "/packages/view/:id", component: PackageView },
    { path: "/:type/package/view/:id", component: PackageCommonView },
    { path: "/packages/edit/:id", component: PackageEdit },
    { path: "/pick-requests", component: PickRequests },
    { path: "/pick-requests/view/:id", component: PickRequestView },
    { path: "/pick-requests/edit/:id", component: PickRequestEdit },
    { path: "/delivery-charges-costing", component: DeliveryCharges },
    { path: "/update-delivery-charges-costing", component: DiliveryChargeEdit },
    { path: "/update-delivery-charges-pricing", component: DiliveryChargePriceEdit },
    { path: "/delivery-pricing", component: DeliveryPrice },
    { path: "/update-delivery-pricing", component: DiliveryPriceEdit },
    { path: "/delivery-centers", component: DeliveryCenters },
    { path: "/delivery-centers/create", component: DeliveryCenterCreate },
    { path: "/delivery-centers/edit/:id", component: DeliveryCenterEdit },
    { path: "/delivery-centers/view/:id", component: DeliveryCenterView },
    { path: "/delivery-centers/view/:id/volumetric-weight/:name", component: VolumetricView },
    { path: "/delivery-centers/view/:id/actual-weight/:name", component: WeightView },
    { path: "/delivery-charges-costing/:id/volumetric-weight/:name", component: VolumetricView },
    { path: "/delivery-charges-costing/:id/actual-weight/:name", component: WeightView },
    { path: "/zones", component: Zones },
    { path: "/zones/create", component: ZoneCreate },
    { path: "/zones/view/:id", component: ZoneView },
    { path: "/:type/zones/view/:id", component: ZoneView },
    { path: "/zones/edit/:id", component: ZoneEdit },
    { path: "/inbounds", component: Inbounds },
    { path: "/outbounds", component: Outbounds },
    { path: "/routes/last-mile", component: LastMile },
    { path: "/routes/last-mile/view/:id", component: ViewLastMileRoute },
    { path: "/routes/last-mile/create/:id", component: CreateLastMileWithState },
    { path: "/routes/last-mile/edit/:id", component: UpdateLastMileWithState },
    { path: "/routes/linehaul", component: Linehaul },
    { path: "/routes/linehaul/view/:id", component: ViewLinehaulRoute },
    { path: "/routes/linehaul/create/:id", component: CreateLinehaulWithState },
    { path: "/routes/linehaul/edit/:id", component: UpdateLinehaulWithState },
    { path: "/outbounds/view/:id", component: OutboundsView },
    { path: "/merchant", component: AllMerchant },
    { path: "/merchant/resume/:id", component: ResumeMerchant },
    { path: "/merchant/create", component: ResumeMerchant },
    { path: "/merchant/edit/:id", component: EditMerchant },
    { path: "/merchant/view", component: ViewMerchant },
    { path: "/merchant/template", component: CreateMerchantTemplate },
    { path: "/merchant/edit", component: EditMerchant },
    { path: "/merchant/view/:id", component: ViewMerchant },
    { path: "/merchant/:id/admin-users", component: AdminUser },
    { path: "/merchant/:id/admin-users/create", component: CreateAdminUser },
    { path: "/merchant/:merchantId/admin-users/view/:id", component: ViewAdminUser },
    { path: "/merchant/:merchantId/admin-users/edit/:id", component: EditAdminUser },
    { path: "/merchant/:merchantId/admin-users/change-password/:id", component: ChangeAdminUserPassword },
    { path: "/merchant/template/:id", component: CreateMerchantTemplate },
    { path: "/merchant/:id/contract", component: Contract },
    { path: "/merchant/:merchantId/contract/view/:id", component: ViewContract },
    { path: "/merchant/:merchantId/contract/create", component: CreateContract },
    { path: "/merchant/:id/branch", component: Branch },
    { path: "/merchant/:merchantId/merchant-branch/create", component: MerchantBranchCreate },
    { path: "/merchant/:merchantId/merchant-branch/view/:id", component: MerchantBranchView },
    { path: "/merchant/:merchantId/merchant-branch/edit/:id", component: MerchantBranchEdit },
    { path: "/merchant/:merchantId/merchant-branch/view/:id/volumetric-weight/:name", component: BranchVolumetricView },
    { path: "/merchant/:merchantId/merchant-branch/view/:id/actual-weight/:name", component: BranchWeightView },
    //{ path: "/merchant/:merchantId/merchant-branch/edit/:id", component: CreateContract },
    { path: "/pickup-details/add", component: CreatePickupDetails },
    { path: "/lodgment-locations/add", component: CreateLodgmentLocation },
    //{ path: "/inbounds/view", component: InboundsView },
    //{ path: "/collectionss/bul-tag-print", component: CollectionBulk },
    { path: "/users/roles", component: UserRole },
    { path: "/users/roles/create", component: CreateUserRole },
    { path: "/users/roles/edit/:id", component: EditUserRole },
    { path: "/contract", component: Contract },
    { path: "/contract/view", component: ViewContract },
    { path: "/user-profile/view", component: UserProfile },
    { path: "/user-profile/edit", component: EditProfile },
    { path: "/contract/create", component: CreateContract },
    { path: "/cash-card-delivery/:id", component: Delivery },
    // { path: "/till-management/:id", component: Till },
    { path: "/users/driver/view/:id/till-management", component: Till },
    { path: "/users/driver/view/:id/till-management-create", component: CreateTill },
    { path: "/runsheets-last", component: RunSheetLast },
    { path: "/runsheets-last-driver/:id", component: DriverRunSheetLast },
    { path: "/runsheets-last/create", component: CreateRunsheetLast },
    { path: "/runsheets-last-driver/create/:id", component: CreateDriverRunsheetLast },
    { path: "/runsheets-last/edit/:id", component: EditRunsheetLast },
    { path: "/runsheets-last/view/:id", component: ViewRunsheetLast },
    { path: "/runsheets-last/add-pick-up/:id", component: AddPickUp },
    { path: "/runsheets-line", component: RunSheetLine },
    { path: "/runsheets-line-driver/:id", component: DriverRunSheetLine },
    { path: "/runsheets-line/create", component: CreateRunsheetLine },
    { path: "/runsheets-line-driver/create/:id", component: CreateDriverRunsheetLine },
    { path: "/runsheets-line/edit/:id", component: EditRunsheetLine },
    { path: "/runsheets-line/view/:id", component: ViewRunsheetLine },
    { path: "/inbounds/view/:id", component: InboundsView },
    { path: "/collection", component: Collections },
    { path: "/collection/view/:id", component: ViewUI },
    { path: "/collection/view-draft/:id", component: ViewDraftUI },
    { path: "/collection/:type/create/:id", component: CreateCollection },
    { path: "/collection/edit/:id", component: CreateCollection },
    { path: "/collections-print", component: CollectionPrint },
    { path: "/reports/lodgement-reconciliation-report", component: ReconciliationReport },
    { path: "/reports/lodgement-reconciliation-report/view", component: ReconciliationReportView },
    { path: "/reports/cash-or-card-on-delivery-report", component: CashCardOnDelivery },
    { path: "/reports/cash-or-card-on-delivery-report/view", component: CashCardOnDeliveryView },
    { path: "/reports/package-wise-costing-report", component: PackageWiseCostingReport },
    { path: "/reports/package-wise-costing-report/view", component: PackageWiseCostingReportView },
    { path: "/reports/sage-merchant-invoice-report", component: SAGEMerchantInvoiceReport },
    { path: "/reports/sage-driver-payment-accrual-report", component: SAGEDriverPaymentReport },
    { path: "/reports/package-status-report", component: PackageStatusReport },
    { path: "/reports/package-status-report/view", component: PackageStatusReportView },
    { path: "/driver-settlement", component: DriverSettlement },
    { path: "/driver-settlement/view", component: DriverSettlementView },
    { path: "/merchant-settlement", component: MerchantSettlement },
    { path: "/merchant-settlement/view", component: MerchantSettlementView },
    { path: "/reports/merchant-settlement-report", component: MerchantSettlementReport },
    { path: "/reports/merchant-settlement-report/view", component: MerchantSettlementReportView },
    { path: "/hub-settlement", component: HubSettlement },
    { path: "/hub-settlement/view", component: HubSettlementView },
    { path: "/hub-settlement-balance", component: HubSettlementBalance },
    { path: "/hub-settlement-balance/view", component: HubSettlementBalanceView },
    { path: "/reports/hub-settlement-report", component: HubSettlementReport },
    { path: "/reports/hub-settlement-report/view", component: HubSettlementReportView },
    { path: "/settlements-pending-confirmation", component: PendingSettlements },
    { path: "/settlements-pending-confirmation/view", component: PendingSettlementsView },
];

const publicBodaRoutes = [
    { path: "/login", component: LoginPage },
    { path: "/forgot-password", component: ForgetPassword },
    { path: "/forgot-password-otp", component: ForgetPasswordOTP },
    { path: "/forgot-password-otp-verify", component: ForgetPasswordOTPVerify },
    { path: "/forgot-password-reset", component: ForgetPasswordReset },
];

export {
    bodaRoutes,
    publicBodaRoutes
}
