const authTokenStorageKey = "@bodaBodawebAuthKey";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const apiDedicatedBaseUrl = process.env.REACT_APP_API_DEDICATED_BASE_URL;
const fileGetAPIUrl = process.env.REACT_APP_FILE_GET_URL;
const printerServiceAPIUrl = process.env.REACT_APP_PRINTER_SERVICE_URL || "http://localhost:8883/";

const uploadComponentConfig = {
    txt: "file-txt.png",
    pdf: "file-pdf.png",
    docx: "file-word.png",
    xlsx: "file-excel.png",
};

const validImageTypes = {
    png: "image/png",
    jpg: "image/jpg",
    jpeg: "image/jpeg",
};

const validFileTypes = {
    txt: "text/plain",
    pdf: "application/pdf",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    csv: "text/csv",
};

const phoneNumberPrefix = "+94";
const defaultCountryCode = "LK";
const defaultPrecisionPoint = 2;
const roundUpTypes = {
    ceil: "ceil",
    floor: "floor",
    round: "round",
    none: "none"
};

const defaultRoundUpType = roundUpTypes.floor;
const defaultPrecisionValue = 0.00;

export {
    authTokenStorageKey,
    apiBaseUrl,
    apiDedicatedBaseUrl,
    uploadComponentConfig,
    validFileTypes,
    validImageTypes,
    fileGetAPIUrl,
    phoneNumberPrefix,
    defaultCountryCode,
    defaultPrecisionPoint,
    roundUpTypes,
    defaultRoundUpType,
    defaultPrecisionValue,
    printerServiceAPIUrl
}
