import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import {bodaRoutes,publicBodaRoutes} from "./routes/boda-routers";
import AppRoute from "./routes/route";
import BodaRoute from "./components/boda-boda/RouteFiltering/BodaRoute";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
//import { initFirebaseBackend } from "./helpers/authUtils";
import {removeFlashMessage} from "./store/boda-boda/ui/actions";

const firebaseConfig = {
  apiKey: "AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8",
  authDomain: "themesbrand-admin.firebaseapp.com",
  databaseURL: "https://themesbrand-admin.firebaseio.com",
  projectId: "themesbrand-admin",
  storageBucket: "themesbrand-admin.appspot.com",
  messagingSenderId: "427667224207",
  appId: "1:427667224207:web:3b97af80b8b4824619a2fa",
  measurementId: "G-S4LDYNV7FY"
};

// init firebase backend
//initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  // componentDidUpdate(prevProps, prevState, snapshot){
  //   if((prevProps.flashMessageList !== this.props.flashMessageList) && this.props.flashMessageList!=null){
  //
  //     this.props.enqueueSnackbar(this.props.flashMessageList.message, {
  //       variant: this.props.flashMessageList.messageType,
  //     });
  //
  //     this.props.removeFlashMessage();
  //   }
  // }

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {/*{publicRoutes.map((route, idx) => (*/}
            {/*  <AppRoute*/}
            {/*    path={route.path}*/}
            {/*    layout={NonAuthLayout}*/}
            {/*    component={route.component}*/}
            {/*    key={idx}*/}
            {/*    isAuthProtected={false}*/}
            {/*  />*/}
            {/*))}*/}

            {/*{authProtectedRoutes.map((route, idx) => (*/}
            {/*  <AppRoute*/}
            {/*    path={route.path}*/}
            {/*    layout={Layout}*/}
            {/*    component={route.component}*/}
            {/*    key={idx}*/}
            {/*    isAuthProtected={false}*/}
            {/*  />*/}
            {/*))}*/}

            {publicBodaRoutes.map((route, idx) => (
              <BodaRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact={true}
              />
            ))}

            {bodaRoutes.map((route, idx) => (
              <BodaRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact={true}
              />
            ))}


          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    flashMessageList:state.UIState.flashMessageList
  };
};

export default connect(mapStateToProps, {
  removeFlashMessage
})(App);
