import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import {
    runSheetLineAPI, getUserDriverList, getManifestListAPI, getLinehaulAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../../store/boda-boda/form/actions";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import DriverViewModalUI from "../../../driver/common-includes/DriverViewModalUI";
import RouteViewModelUI from "./RouteViewModelUI";
import CollectionViewModelUI from "./CollectionViewModelUI";
import { UIModal } from "./CancelRunSheetLine";
import { LinehaulLabel } from "./printUI";


class ViewRunsheetLine extends Component {

    render() {
        let { formState, history, match } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${runSheetLineAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${runSheetLineAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code: response.code,
                            driver: [response.driver],
                            name: response.name,
                            creator: get(response, "creator.staffs[0].name", ""),
                            routeLine: [response.routeLine],
                            runSheetAt: response.runSheetAt,
                            status: response.status,
                            vehiclePlate: response.vehiclePlate === null ? "-" : response.vehiclePlate,
                            notes: response.notes === null ? [] : response.notes,
                            locations: response.locations,
                            manifests: response.manifests,
                            facility: response.facility,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Runsheet</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <BackBtnComponent
                                        elementWrapperStyle={"mr-2"}
                                        path={"/runsheets-line"}
                                    />

                                    {
                                        (
                                            get(formState, `${runSheetLineAPI.key}_view.status`, "") !== "EXPIRED"
                                            &&
                                            get(formState, `${runSheetLineAPI.key}_view.status`, "") !== "COMPLETED"
                                            &&
                                            get(formState, `${runSheetLineAPI.key}_view.status`, "") !== "STARTED"
                                            &&
                                            get(formState, `${runSheetLineAPI.key}_view.status`, "") !== "CANCELLED"
                                        ) ? (
                                            <InputButton
                                                elementWrapperStyle={"mr-2"}
                                                btnText={"Edit"}
                                                startIcon={"mdi mdi-square-edit-outline"}
                                                onClickBtnFn={() => history.push(`/runsheets-line/edit/${match.params.id}`)}
                                            />
                                        ) : null
                                    }

                                    <LinehaulLabel
                                        linehaul={get(formState, `${runSheetLineAPI.key}_view`, {})}
                                    />

                                    {
                                        (get(formState, `${runSheetLineAPI.key}_view.status`, "") === "ACTIVE") ? (
                                            <UIModal
                                                btnColor={"danger"}
                                                btnText={"Cancel Runsheet"}
                                                startIcon={"far fa-times-circle"}
                                                id={match.params.id}
                                                history={history}
                                                displayMessage={"Are you sure you want to cancel this runsheet?"}
                                                status={"CANCELLED"}
                                                flashMessage={"Runsheet has successfully cancelled"}
                                            />
                                        ) : null
                                    }
                                </Fragment>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card mb-1">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Runsheet Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_view.code`, "")}</dt>

                                                <dd className={"col-sm-4"}>Date</dd>
                                                <dt className={"col-sm-8"}>
                                                    {dateObjectToString(get(formState, `${runSheetLineAPI.key}_view.runSheetAt`, ""), "dd-mm-YY")}
                                                </dt>

                                                <dd className={"col-sm-4"}>Assigned By</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_view.creator`, "")}</dt>

                                                <dd className={"col-sm-4"}>Status</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_view.status`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Runsheet Name</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_view.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Address</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_view.facility.location.address`, "")}</dt>

                                                <dd className={"col-sm-4"}>Vehicle Number</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLineAPI.key}_view.vehiclePlate`, "-")}</dt>

                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Notes</label>
                                            {
                                                get(formState, `${runSheetLineAPI.key}_view.notes`, []).length > 0 ? (
                                                    <ul style={{ paddingLeft: "17px" }}>
                                                        {
                                                            get(formState, `${runSheetLineAPI.key}_view.notes`, []).map((element, index) => {
                                                                return (
                                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                ) : (
                                                    <div>None</div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Collections"
                            apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=COLLECTION&runSheetLineId=${match.params.id}`}
                            dataTableStateKey={`${getManifestListAPI.key}_runsheetLine_selected`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={true}
                            tableHeaderList={[
                                { displayName: "Collection Code", key: "code" },
                                { displayName: "Destination Delivery Center", key: "locationDrop.name" },
                                {
                                    displayName: "No of Packages", key: "packages", onPrintFn: (rawData) => {
                                        if (get(rawData, "publishedPackages", "") === null) {
                                            return 0;
                                        } else {
                                            return get(rawData, "publishedPackages", []).length;
                                        }
                                    }
                                },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Collection",
                                        modalType: "modal-lg",
                                        modalBodyComponent: CollectionViewModelUI,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                },
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Route"
                            dataTableStateKey={`${getLinehaulAPI.key}_runsheetLine_selected`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={true}
                            isPagingShow={false}
                            tableHeaderList={[
                                { displayName: "Route ID", key: "id" },
                                { displayName: "Route Name", key: "name" },
                                { displayName: "Type", key: "type", default: "LineHaul" },
                                { displayName: "Status", key: "status" },
                            ]}
                            tableBodyList={get(formState, `${runSheetLineAPI.key}_view.routeLine`, [])}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Route",
                                        modalType: "modal-lg",
                                        modalBodyComponent: RouteViewModelUI,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                },
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Driver"
                            dataTableStateKey={`${getUserDriverList.key}_runsheetLine_selected`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isPagingShow={false}
                            isSetAction={true}
                            tableHeaderList={[
                                { displayName: "Driver Id", key: "id" },
                                { displayName: "Name", key: "name" },
                                { displayName: "Type", key: "category" },
                                { displayName: "Identification", key: "identification", default: "-" },
                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "") },
                                { displayName: "Status", key: "status" },
                            ]}
                            tableBodyList={get(formState, `${runSheetLineAPI.key}_view.driver`, [])}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Driver",
                                        modalType: "modal-lg",
                                        modalBodyComponent: DriverViewModalUI,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                },
                            ]}
                        />
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
})(ViewRunsheetLine);
