import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { packageWiseCostingReportAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { currency, mapPackageStatusesNames } from "../../../../../config/boda-boda/db_config/db_status.config";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { fromObjectToQueryString, roundValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";


const TableLastRow = (props) => {
    return (
        <Fragment>
            <tr>
                <td colSpan={"6"}></td>
                <td>Total</td>
                <td>{roundValue(get(props, "tableBodyLastProps.cost", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.expressCost", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.xlPackageHandlingCost", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.totalCost", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.merchantPrice", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.expressFee", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.xlPackageHandlingFee", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.totalPrice", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.profit", ""))}</td>
            </tr>
        </Fragment>
    )
}

class PackageWiseCostingReportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaderStatus: false
        }
    }

    getQueryString = () => {
        let { formState } = this.props;

        let dateTo = new Date(get(formState, "package_wise_costing.dateTo", new Date()));
        dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
        dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

        const queryObj = {
            dateFrom: get(formState, "package_wise_costing.dateFrom", new Date()).toISOString(),
            dateTo: dateTo.toISOString(),
            status: get(formState, "package_wise_costing.status", ""),
            merchantId: get(formState, "package_wise_costing.merchantId").split("/")[0],
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    render() {
        let { formState, coreState } = this.props;

        return (
            <React.Fragment>
                {
                    (get(formState, "package_wise_costing.dateFrom", "") === "" || get(formState, "package_wise_costing.dateTo", "") === "") ? (
                        <Redirect to={"/reports/package-wise-costing-report"} />
                    ) : (
                        <div className="container-fluid">
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box">
                                        <h4 className="font-size-18">Package wise Costing Report</h4>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            From<strong className={"mx-2"}>{dateObjectToString(get(formState, "package_wise_costing.dateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "package_wise_costing.dateTo"), "dd/mm/YY")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Merchant:<strong className={"mx-2"}>{get(formState, "package_wise_costing.merchantId", "") === "" ? "All" : get(formState, "package_wise_costing.merchantId", "").split("/")[1]}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Package Status:<strong className={"mx-2"}>{get(formState, "package_wise_costing.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "package_wise_costing.status", "")]}</strong>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <BackBtnComponent
                                            path={"/reports/package-wise-costing-report"}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className={"row mt-3"}>
                                <CrudComponent
                                    title={"Package wise Costing Report"}
                                    apiURL={`${packageWiseCostingReportAPI.url}?page=1&limit=20${this.getQueryString()}`}
                                    dataTableStateKey={packageWiseCostingReportAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={false}
                                    isSetAction={false}
                                    tableHeaderList={[
                                        { displayName: "Pick Up Date", key: "packageHistories[0].manifest.pickedAt",  onPrintFn: (rawData) => dateObjectToString(rawData.packageHistories[0].manifest.pickedAt, "YY-mm-dd")  },
                                        { displayName: "Article ID", key: "articleNo" },
                                        { displayName: "Consignment ID", key: "order.consignmentNo" },
                                        { displayName: "Merchant Code", key: "merchant.code" },
                                        { displayName: "Lodgement Location", key: "packageHistories[0].manifest.locationDrop.name" },
                                        
                                        { displayName: "Status", key: "status",onPrintFn: (rawData) =>{
                                            if (rawData.status === "IN_TRANSIT_FIRST") {
                                                return "PACKAGE PICKED UP";
                                            } else if (rawData.status === "RECEIVED") {
                                                return "RECEIVED AT PROCESSING CENTER";
                                            } else if (rawData.status === "SORTED") {
                                                return "READY FOR TRANSIT";
                                            } else if (rawData.status === "IN_TRANSIT_LINE") {
                                                return "INTRANSIT TO REGIONAL HUB";
                                            } else if (rawData.status === "IN_TRANSIT_LAST") {
                                                return "OUT FOR DELIVERY";
                                            } else if (rawData.status === "NOT_DELIVERED") {
                                                return "FAILED DELIVERY";
                                            } else {
                                                return rawData.status ;
                                            }
                                            
                                        } },
                                        
                                        { displayName: "Delivered Date", key: "deliveredAt", onPrintFn: (rawData) => {
                                            return  rawData.deliveredAt === null ? "" : dateObjectToString(rawData.deliveredAt, "YY-mm-dd");
                                        }},
                                        { displayName: `Delivery Cost (${currency})`, key: "cost",onPrintFn: (rawData) => {
                                            return rawData.cost? roundValue(rawData.cost) : 0; } }, 
                                        { displayName: `Express Delivery Cost (${currency})`, key: "expressCost",onPrintFn: (rawData) => {
                                            return rawData.expressCost? roundValue(rawData.expressCost) : 0; } },  
                                        { displayName: `XL Handling Cost (${currency})`, key: "xlPackageHandlingCost",onPrintFn: (rawData) => {
                                            return rawData.xlPackageHandlingCost? roundValue(rawData.xlPackageHandlingCost) : 0; } }, 
                                        { displayName: `Total Cost (${currency})`, key: "totalCost" ,onPrintFn: (rawData) => {
                                            return rawData.totalCost? roundValue(rawData.totalCost) : 0; } }, 
                                        { displayName: `Delivery Fee (${currency})`, key: "merchantPrice" ,onPrintFn: (rawData) => {
                                            return rawData.merchantPrice? roundValue(rawData.merchantPrice) : 0; } }, 
                                        { displayName: `Express Fee (${currency})`, key: "expressFee" ,onPrintFn: (rawData) => {
                                            return rawData.expressFee? roundValue(rawData.expressFee) : 0; } }, 
                                        { displayName: `XL Handling Fee (${currency})`, key: "xlPackageHandlingFee" ,onPrintFn: (rawData) => {
                                            return rawData.xlPackageHandlingFee? roundValue(rawData.xlPackageHandlingFee) : 0; } }, 
                                        { displayName: `Total Fee (${currency})`, key: "totalPrice" ,onPrintFn: (rawData) => {
                                            return rawData.totalPrice? roundValue(rawData.totalPrice) : 0; } }, 
                                        { displayName: `Profit from Delivery (${currency})`, key: "profit", onPrintFn: (rawData) => {
                                            return  roundValue((rawData.totalPrice - rawData.totalCost)).toString();
                                        }},
                                    ]}
                                    tableBodyLastComponent={TableLastRow}
                                    tableBodyLastProps={get(coreState, `dataTableResponses.${packageWiseCostingReportAPI.key}.totalsRow`, {})}
                                    isForceShowLoader={this.state.loaderStatus}
                                />
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState,
    };
};

export default  connect(mapStateToProps, null)(PackageWiseCostingReportView);
