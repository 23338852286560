import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    FormWrapper, InputButton, InputButtonWithState
} from "../../../../../components/boda-boda/Forms";
import {
    runSheetLastAPI, getUserDriverList, getLastMileAPI
} from "../../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../../store/boda-boda/form/actions";
import { editRunSheetLast } from "../../../../../store/boda-boda/transaction_request/actions";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import PickupAndDeliveries from "./view-includes/PickupAndDeliveries";
import DriverViewModalUI from "../../../driver/common-includes/DriverViewModalUI";
import RouteViewModelUI from "./RouteViewModelUI";
import { UIModal } from "./CancelRunSheetLast";
import { LastMileLabel } from "./printUI";
import PickUps from "./add-pick-up-includes/PickUps";


class AddPickUp extends Component {
    render() {
        let { formState, history, match, authState, editRunSheetLast } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${runSheetLastAPI.key}_edit`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${runSheetLastAPI.url}/${match.params.id}/compressed`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            code: response.code,
                            name: response.name,
                            driver: response.driver,
                            runSheetAt: response.runSheetAt,
                            status: response.status,
                            creator: get(response, "creator.staffs[0].name", ""),
                            vehiclePlate: response.vehiclePlate === null ? "-" : response.vehiclePlate,
                            notes: response.notes === null ? [] : response.notes,
                            selectedDriver: [response.driver],
                            selectedRoute: [response.routeLast],
                            locations: response.locations,
                            manifests: response.manifests,
                            facility: response.facility,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Add Pick Up</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <BackBtnComponent
                                        path={"/runsheets-last"}
                                        btnName={"Back"}
                                        icon={"fas fa-angle-double-left"}
                                        btnColor={"primary"}
                                    />
                                </Fragment>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card mb-3">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Runsheet Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.code`, "")}</dt>

                                                <dd className={"col-sm-4"}>Date</dd>
                                                <dt className={"col-sm-8"}>
                                                    {dateObjectToString(get(formState, `${runSheetLastAPI.key}_edit.runSheetAt`, ""), "dd-mm-YY")}
                                                </dt>

                                                <dd className={"col-sm-4"}>Assigned By</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.creator`, "")}</dt>

                                                <dd className={"col-sm-4"}>Status</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.status`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row mb-0"}>
                                                <dd className={"col-sm-4"}>Runsheet Name</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Address</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.facility.location.address`, "")}</dt>

                                                <dd className={"col-sm-4"}>Vehicle Number</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${runSheetLastAPI.key}_edit.vehiclePlate`, "")}</dt>

                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Notes</label>
                                            {
                                                get(formState, `${runSheetLastAPI.key}_edit.notes`, []).length > 0 ? (
                                                    <ul style={{ paddingLeft: "17px" }}>
                                                        {
                                                            get(formState, `${runSheetLastAPI.key}_edit.notes`, []).map((element, index) => {
                                                                return (
                                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                ) : (
                                                    <div>None</div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* 
                    <div className={"row"}>
                        <PickupAndDeliveries
                            runSheetId={match.params.id}
                        />
                    </div> */}
                    <PickUps
                        runSheetId={match.params.id}
                        driver={get(formState, `${runSheetLastAPI.key}_edit.driver`, null)}
                        status={get(formState, `${runSheetLastAPI.key}_edit.status`, "")}
                    />

                    <div className={"row"}>
                        <CrudComponent
                            title="Route"
                            dataTableStateKey={`${getLastMileAPI.key}_runsheetLast_selected`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={true}
                            isPagingShow={false}
                            tableHeaderList={[
                                { displayName: "Route ID", key: "id" },
                                { displayName: "Route Name", key: "name" },
                                { displayName: "Type", key: "type", default: "LastMile" },
                                { displayName: "Status", key: "status" },
                            ]}
                            tableBodyList={get(formState, `${runSheetLastAPI.key}_edit.selectedRoute`, [])}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Route",
                                        modalType: "modal-lg",
                                        modalBodyComponent: RouteViewModelUI,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                }
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Driver"
                            dataTableStateKey={`${getUserDriverList.key}_runsheetLast_selected`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isPagingShow={false}
                            isSetAction={true}
                            tableHeaderList={[
                                { displayName: "Driver Id", key: "id" },
                                { displayName: "Name", key: "name" },
                                { displayName: "Type", key: "category" },
                                { displayName: "Identification", key: "identification", default: "-" },
                                { displayName: "Phone No", key: "user", onPrintFn: (row) => get(row, "user.staffs[0].phone", "") },
                                { displayName: "Status", key: "status" },
                            ]}
                            tableBodyList={get(formState, `${runSheetLastAPI.key}_edit.selectedDriver`, [])}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Driver",
                                        modalType: "modal-lg",
                                        modalBodyComponent: DriverViewModalUI,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                }
                            ]}
                        />
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    editRunSheetLast
})(AddPickUp);
