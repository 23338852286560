import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
    RadioButtonWithState,
} from "../../../components/boda-boda/Forms";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    convertToCsvValue,
} from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {
    getAllFacilityAPI,
    getFacilityAPI,
    merchantAPI,
    merchantSettlementReportAPI,
} from "../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setFlashMessage, setPageLoader } from "../../../store/boda-boda/ui/actions";

class PendingSettlements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            facility: null,
        }
    }
    componentDidMount(){
        this.fetchHeadOfficeFacility()
    }

    fetchHeadOfficeFacility = () => {
        let { authState, setFlashMessage } = this.props;
        setPageLoader(true);
        callApi(`${getFacilityAPI.url}/all?isHeadOffice=true`
        )
          .headers(true)
          .method("get")
          .send()
          .then((result) => {
            this.setState({...this.state, facility: result.data.data[0]})
            if (result?.data?.data[0]?.id !== get(authState, "authUser.facility.id")) {
              setFlashMessage({
                status: true,
                message: "Permission denied to access",
                type: "danger"
              });
            }
    
            setPageLoader(false);
          });
      };
    onGenerateFn = () => {
        let { formState, history } = this.props;

        if (this.validateFn() === true) {
            history.push("/settlements-pending-confirmation/view");
        }
    };

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (
            isEmptyValue(get(formState, "pending_confirmation_settlement.asOfDate", ""))
        ) {
            errors.push({
                property: "asOfDate",
                error: "The As of Date field is required",
            });
        }

        if (errors.length === 0) {
            setFormErrorFn("pending_confirmation_settlement", []);
            return true;
        } else {
            setFormErrorFn("pending_confirmation_settlement", errors);
            return false;
        }
    };

    render() {
        let { authState } = this.props;
        return (
            <React.Fragment>
                {this.state.facility?.id && this.state.facility?.id === get(authState, "authUser.facility.id") ?
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">
                                Settlements Pending Confirmation
                                </h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                    Settlements Pending Confirmation
                                    </li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"card"} style={{ marginBottom: "120px" }}>
                                <div className={"card-body"}>
                                    <FormWrapper
                                        setGroupName={"pending_confirmation_settlement"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            asOfDate: null,
                                            branch: "",
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Branch"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={getAllFacilityAPI.key}
                                                    inputName={"branch"}
                                                    placeHolderText={"Branch"}
                                                    formGroupName={"pending_confirmation_settlement"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="As of Date"
                                                    inputName={"asOfDate"}
                                                    formGroupName={"pending_confirmation_settlement"}
                                                    placeholderText={"As of Date"}
                                                />
                                            </div>

                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />
                                            </div>
                                        </div>
                                    </FormWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null} 
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    setFlashMessage
})(PendingSettlements);
