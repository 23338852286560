import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputBoxWithState, FormWrapper, InputButtonWithState } from "../../../../components/boda-boda/Forms";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getCityListAPI, getZoneAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { FilterUI } from "./create-includes/FilterUI";
import { changeInputFn } from "../../../../store/boda-boda/form/actions";
import { createZone } from "../../../../store/boda-boda/transaction_request/actions";


class CreateUI extends Component {

    addCity = (city) => {
        const cityIds = get(this.props.formState, `${getZoneAPI.key}_create.cities`, []);
        const cities = get(this.props.formState, `${getZoneAPI.key}_create.selectedCities`, []);

        this.props.changeInputFn(`${getZoneAPI.key}_create`, "cities", "", [...cityIds, city.id]);
        this.props.changeInputFn(`${getZoneAPI.key}_create`, "selectedCities", "", [...cities, city]);
    }

    deleteCity = (city) => {
        let cityIds = get(this.props.formState, `${getZoneAPI.key}_create.cities`, []);
        let cities = get(this.props.formState, `${getZoneAPI.key}_create.selectedCities`, []);

        cityIds = cityIds.filter((e) => e !== city.id);
        cities = cities.filter((e) => e.id !== city.id);

        this.props.changeInputFn(`${getZoneAPI.key}_create`, "cities", "", cityIds);
        this.props.changeInputFn(`${getZoneAPI.key}_create`, "selectedCities", "", cities);
    }

    render() {
        let { history, formState, createZone } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create Zone</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <InputButtonWithState
                                elementWrapperStyle={"mr-2"}
                                btnText={"Save"}
                                startIcon={"mdi mdi-content-save-all-outline"}
                                formGroupName={`${getZoneAPI.key}_create`}
                                onClickBtnFn={(dataObject) => {
                                    createZone({
                                        ...dataObject.formObject,
                                        history: history
                                    })
                                }}
                            />

                            <BackBtnComponent
                                path={"/zones"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="card mb-1">
                            <div className="card-body">
                                <FormWrapper
                                    setGroupName={`${getZoneAPI.key}_create`}
                                    onDestroyUnsetFormObject={true}
                                    setFormObject={{
                                        selectedCities: [],
                                        cities: [],
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Zone Name"
                                                placeHolderText={"Zone Name"}
                                                inputName={"name"}
                                                formGroupName={`${getZoneAPI.key}_create`}
                                                isRequired={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Zone Code"
                                                placeHolderText={"Zone Code"}
                                                inputName={"code"}
                                                formGroupName={`${getZoneAPI.key}_create`}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                </FormWrapper>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <CrudComponent
                        title="Cities"
                        apiURL={`${getCityListAPI.url}?page=1&limit=20&zoneId=null`}
                        dataTableStateKey={`${getCityListAPI.key}_available`}
                        isFilter={true}
                        filterFromComponent={FilterUI}
                        defaultFilterFormObject={{
                            provinceId: "",
                            districtId: "",
                            name: "",
                        }}
                        isSetAction={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "City", key: "name" },
                            { displayName: "District", key: "district.name" },
                            { displayName: "Province", key: "district.province.name" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.create,
                                isSetModel: false,
                                actionBtn: {
                                    tooltip: "Add",
                                    onClickBtnFn: (rawData) => this.addCity(rawData),
                                },
                                checkBeforeActionRenderFn: (rawData) => {
                                    return !get(formState, `${getZoneAPI.key}_create.cities`, []).includes(rawData.rowData.id);
                                }
                            },
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Cities"
                        dataTableStateKey={`${getCityListAPI.key}_selected`}
                        tableBodyList={get(formState, `${getZoneAPI.key}_create.selectedCities`, [])}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        isPagingShow={false}
                        tableHeaderList={[
                            { displayName: "City", key: "name" },
                            { displayName: "District", key: "district.name" },
                            { displayName: "Province", key: "district.province.name" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.delete,
                                isSetModel: false,
                                actionBtn: {
                                    onClickBtnFn: (rawData) => this.deleteCity(rawData),
                                },
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ZoneCreate = connect(mapStateToProps, { changeInputFn, createZone })(CreateUI);

export {
    ZoneCreate
};
