/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-08-25 11:23:04
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-01-25 12:45:20
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _, { get } from "lodash"

import Header from "./crud_includes/Header";
import Table from "./crud_includes/Table";
import { ModalUI } from "../Modal/ModalUI";
import { actionBtnTypes } from "./configs/crud.config";
import { generateQueryString,isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { VariableToComponent, CustomLoader } from "../CommonElements/BaseElements";
import { generateApiCallObjFn, requestAPICallFn, initDataTable, removeDataTable, setDataTableFetchingToInit } from "../../../store/boda-boda/core/actions";
import Footer from "./crud_includes/Footer";
import { setBulkValues } from '../../../store/boda-boda/form/actions';


class CrudWrapper extends Component {

    static defaultProps = {
        title:"",
        dataTableStateKey:"",
        apiURL:null,
        isSetAction:true,
        isSetCreateAction:true,
        createActionProps:{},
        isFilter:true,
        isPagingShow:true,
        isDropdownActionBtn:false,
        filterFromComponent:null,
        actionButtonListComponent:null,
        actionButtonListProps:{},
        tableBodyLastComponent:null,
        tableBodyLastProps:{},
        isForceShowLoader:false,
        defaultFilterFormObject:{},
        tableCellComponents:null,
        tableHeaderList:[],
        tableBodyList:[],
        actionList:[],
        onDestroyUnsetDatatableObject: true,
        onRebuildResponseFn:false,
        onRebuildFilterObjFn:false,
        isOpenFilterCollapse: false,
        searchButtonOnTop: false,
        bulkPrintAll: false,
        readyForReturn: false,
        headerBtn: <React.Fragment></React.Fragment>,
        isMarkAsSettle: false,
        useInitialFilter: false,
    }

    constructor(props) {
        super(props);
        this.state ={
            "filterObject":{},
            "currentModal":{
                status:false,
                modalState:{}
            },
            "currentRow":{}
        }
    }

    requestDataFromAPI=(type=null,requestPage=1,searchFrom={})=>{
        let {apiURL,dataTableResponses,dataTableStateKey,requestAPICallFn,onRebuildResponseFn,setDataTableFetchingToInit, formState, setBulkValues, isOpenFilterCollapse, isPagingShow, useInitialFilter}=this.props;
        let {filterObject}=this.state;

        if(apiURL){
            const dataTableData=get(dataTableResponses,`${dataTableStateKey}`,{});
            let getDataUrl = apiURL;

            if (type !== null && getDataUrl.includes("page=1")) {
                getDataUrl = getDataUrl.replace("page=1", "");
            }

            if (useInitialFilter && type === null) {
                getDataUrl = `${getDataUrl}${getDataUrl.includes("?") === true ? "&" : "?"}${generateQueryString(dataTableData.current_page,searchFrom,dataTableData.shortBy, !isPagingShow)}`;
            }
            if (type === "next") {
                setDataTableFetchingToInit(dataTableStateKey);
                getDataUrl = `${getDataUrl}${getDataUrl.includes("?") === true ? "&" : "?"}${generateQueryString(requestPage,filterObject, dataTableData.shortBy)}`;
            } else if (type === "searchFrom") {
                setDataTableFetchingToInit(dataTableStateKey);
                getDataUrl = `${getDataUrl}${getDataUrl.includes("?") === true ? "&" : "?"}${generateQueryString(1, searchFrom, dataTableData.shortBy, isOpenFilterCollapse && !isPagingShow)}`;
            } else if (type === "sort") {
                getDataUrl = `${getDataUrl}${getDataUrl.includes("?") === true ? "&" : "?"}${generateQueryString(1, filterObject, dataTableData.shortBy)}`;
            } else if (type === "none") {
                getDataUrl = `${getDataUrl}${getDataUrl.includes("?") === true ? "&" : "?"}${generateQueryString(dataTableData.current_page,filterObject,dataTableData.shortBy, !isPagingShow)}`;
            }

            const apiCallObject=generateApiCallObjFn(getDataUrl)
                .method("get")
                .setInitStoring("DATA_TABLE", dataTableStateKey)
                .setOnRebuildResponseFn(onRebuildResponseFn !== false ? (response) => onRebuildResponseFn(response) : false)
                .generate();

            requestAPICallFn(apiCallObject);
        }
    }

    clearSelectedPackages = () => {
        let { dataTableStateKey } = this.props;
        let chkList = get(this.props.formState, `${dataTableStateKey}`, null);
        let selectedKeys = [];
        if (chkList !== null) {
          for (const [key, value] of Object.entries(chkList)) {
            if (value === true) {
              selectedKeys.push(key);
            }
          }
        }
    
        let selecteditemList = [];
        selecteditemList = selectedKeys.map((item) => {
          if (this.props?.isBulkPrint) {
            if (_.startsWith(item, 'tableSelect')) {
              let pair = {};
              let value = item;
              pair[value] = false;
              return pair;
            }
          }
        });
        if (get(this.props.formState, `${dataTableStateKey}.selectall`, null) !== null) {
          let selectAll = {};
          selectAll['selectall'] = false;
          selecteditemList.push(selectAll);
        }
        for (let i = 0; i < selecteditemList?.length; i += 1) {
          this.props.setBulkValues(`${dataTableStateKey}`, selecteditemList[i])
        }
        sessionStorage.removeItem('selectedPackages')
      }

    onFilterFn=(formData)=>{
        const { clearErrors } = this.props;
        this.setState({
            ...this.state,
            "filterObject":formData
        });
        if(undefined !== clearErrors){
            if(!isEmptyValue(formData)){
                clearErrors();
             }
        }
 
        this.requestDataFromAPI("searchFrom",1,formData);
        this.clearSelectedPackages()
    }

    componentDidMount(){
        let {dataTableResponses,dataTableStateKey,initDataTable,defaultFilterFormObject}=this.props;
        if(get(dataTableResponses,`${dataTableStateKey}.fetching`,undefined)===undefined){
            initDataTable(dataTableStateKey);
        }
        this.requestDataFromAPI(null,1,defaultFilterFormObject);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { dataTableStateKey, dataTableResponses, defaultFilterFormObject, useInitialFilter } = this.props;

        if (get(prevProps, `coreState.dataTableResponses[${dataTableStateKey}]._reloadDataTable`, false) !== get(this.props, `coreState.dataTableResponses[${dataTableStateKey}]._reloadDataTable`, false)) {
            if (useInitialFilter && JSON.stringify(this.state?.filterObject) === '{}'){
                this.setState({
                    ...this.state,
                    "filterObject": defaultFilterFormObject
                }, () => this.requestDataFromAPI("none"));
            } else {
                this.requestDataFromAPI("none");
            }
        }
    }

    componentWillUnmount() {
        let { removeDataTable, onDestroyUnsetDatatableObject, dataTableStateKey } = this.props;

        if (onDestroyUnsetDatatableObject) {
            removeDataTable(dataTableStateKey);
        }
    }

    setModelStatus=(status=false)=>{
        this.setState({
            ...this.state,
            currentModal:{
                ...this.state.currentModal,
                status:status
            }
        });
    }

    manageModalFn=(currentModal={},row={})=>{
        this.setState({
            ...this.state,
            currentModal:{
                status:!this.state.currentModal.status,
                modalState:currentModal
            },
            currentRow:row
        })
    }

    getTableBodyData = () => {
        let { tableBodyList, dataTableResponses, dataTableStateKey } = this.props;

        if (Array.isArray(tableBodyList) && tableBodyList.length!==0) {
            return tableBodyList;
        } else {
            return get(dataTableResponses,`${dataTableStateKey}.results`,[]);
        }
    }

    render() {

        let {
            title,tableHeaderList,tableBodyList,actionList,
            isSetAction,isSetCreateAction,createActionProps,
            filterFromComponent,isFilter,dataTableResponses,
            dataTableStateKey,defaultFilterFormObject,
            actionButtonListComponent,actionButtonListProps,
            isPagingShow,apiURL,isDropdownActionBtn,
            tableBodyLastComponent,tableBodyLastProps,isForceShowLoader,
            onRebuildFilterObjFn, isOpenFilterCollapse,isBulkPrint, searchButtonOnTop,
            bulkPrintAll, readyForReturn, headerBtn, isMarkAsSettle,
        }=this.props;
        let {currentModal,currentRow}=this.state;

        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-1">
                            <div className="card-body">

                                <Header
                                    title={title}
                                    isSetCreateAction={isSetCreateAction}
                                    filterFromComponent={filterFromComponent}
                                    isFilter={isFilter}
                                    isOpenFilterCollapse={isOpenFilterCollapse}
                                    dataTableStateKey={dataTableStateKey}
                                    defaultFilterFormObject={defaultFilterFormObject}
                                    createActionProps={createActionProps}
                                    onRebuildFilterObjFn={onRebuildFilterObjFn}
                                    onCreateFn={()=>this.manageModalFn(createActionProps,{})}
                                    onFilterFn={(formObject)=>this.onFilterFn(formObject)}
                                    onResetFn={()=>this.onFilterFn({})}
                                    isBulkPrint={isBulkPrint ? true : false}
                                    searchButtonOnTop={searchButtonOnTop}
                                    bulkPrintAll={bulkPrintAll}
                                    readyForReturn={readyForReturn}
                                    headerBtn={headerBtn}
                                />

                                {
                                    (apiURL && (get(dataTableResponses,`${dataTableStateKey}.fetching`,undefined) === undefined || get(dataTableResponses,`${dataTableStateKey}.fetching`,undefined) === "init")) ? (
                                        <div className={"fullWidthDiv defaultMarginTopBottom"}>
                                            <center>
                                                <CustomLoader />
                                            </center>
                                        </div>
                                    ) : isForceShowLoader ? (
                                        <div className={"fullWidthDiv defaultMarginTopBottom"}>
                                            <center>
                                                <CustomLoader />
                                            </center>
                                        </div>
                                    ) : (
                                        <Fragment>
                                            {
                                                (get(dataTableResponses,`${dataTableStateKey}.fetching`,"") === "error" || this.getTableBodyData().length === 0) ? (
                                                    <div className="fullWidthDiv defaultMarginTopBottom" style={{paddingBottom:'90px'}}>
                                                        No results found
                                                    </div>
                                                ) : (
                                                    <Fragment>
                                                        <div className="table-rep-plugin">
                                                            <div
                                                                className="table-responsive mb-0"
                                                                data-pattern="priority-columns"
                                                            >
                                                                <Table
                                                                    dataTableStateKey={dataTableStateKey}
                                                                    tableHeaderList={tableHeaderList}
                                                                    tableBodyList={this.getTableBodyData()}
                                                                    actionList={actionList}
                                                                    isSetAction={isSetAction}
                                                                    actionButtonListComponent={actionButtonListComponent}
                                                                    actionButtonListProps={actionButtonListProps}
                                                                    tableBodyLastComponent={tableBodyLastComponent}
                                                                    tableBodyLastProps={tableBodyLastProps}
                                                                    manageModalFn={this.manageModalFn}
                                                                    setModelStatusFn={this.setModelStatus}
                                                                    isDropdownActionBtn={isDropdownActionBtn}
                                                                    isBulkPrint={isBulkPrint ? true : false}
                                                                    isMarkAsSettle={isMarkAsSettle}
                                                                    readyForReturn={readyForReturn}
                                                                />
                                                            </div>
                                                        </div>

                                                        {
                                                            (isPagingShow) ? (
                                                                <Footer
                                                                    totalRecordsCount={get(dataTableResponses,`${dataTableStateKey}.total`,"")}
                                                                    currentPage={get(dataTableResponses,`${dataTableStateKey}.current_page`,1)}
                                                                    pageCount={get(dataTableResponses,`${dataTableStateKey}.page_count`, 1)}
                                                                    perPage={get(dataTableResponses,`${dataTableStateKey}.per_page`,20)}
                                                                    pageSize={get(dataTableResponses,`${dataTableStateKey}.page_size`,20)}
                                                                    onNextFn={this.requestDataFromAPI}
                                                                />
                                                            ) : null
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <ModalUI
                    isOpen={currentModal.status}
                    showHideToggleFn={()=>this.manageModalFn()}
                    modalTitle={get(currentModal,'modalState.modalProps.modalTitle','')}
                    modalType={get(currentModal,'modalState.modalProps.modalType','')}
                    modalBodyComponent={get(currentModal,'modalState.modalProps.modalBodyComponent',null)!==null?(<VariableToComponent component={get(currentModal,'modalState.modalProps.modalBodyComponent',null)} props={{
                        ...get(currentModal,'modalState.modalProps.parentToModelProps',{}),
                        row:currentRow||{},
                        setModelStatusFn:(status)=>this.setModelStatus(status),
                        dataTableStateKey:dataTableStateKey
                    }} />):(null) }
                    modalFooterComponent={get(currentModal,'modalState.modalProps.modalFooterComponent',null)!==null?(<VariableToComponent component={get(currentModal,'modalState.modalProps.modalFooterComponent',null)} props={{
                        ...get(currentModal,'modalState.modalProps.parentToModelProps',{}),
                        row:currentRow||{},
                        setModelStatusFn:(status)=>this.setModelStatus(status),
                        dataTableStateKey:dataTableStateKey
                    }} />):(null) }
                    isSetClose={true}
                    isSetFooterClose={true}
                    closeBtnName={get(currentModal,'modalState.modalProps.closeBtnName',"Close")}
                />



            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState:state.CoreState,
        dataTableResponses:state.CoreState.dataTableResponses
    };
};

const CrudComponent =connect(mapStateToProps,{
    requestAPICallFn,
    initDataTable,
    removeDataTable,
    setDataTableFetchingToInit,
    setBulkValues,
})(CrudWrapper);

export {
    CrudComponent,
    actionBtnTypes
};
