import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import {getAllFacilityAPI, getCityListAPI } from "../../../../config/boda-boda/apiUrls.config";


class FilterUI extends Component {
    constructor(props) {
        super(props);
}

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Zone Code"
                            inputName={"code"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Zone Code"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Zone name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Zone Name"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Delivery Center"
                            inputName={"facilityId"}
                            placeHolderText={"Select a delivery center"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={getAllFacilityAPI.url}
                            apiStateKey={getAllFacilityAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="City"
                            inputName={"cityId"}
                            placeHolderText={"Select a City"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getCityListAPI.url}/all`}
                            apiStateKey={getCityListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>
                </div>

            </div>
        )
    }
}

export  {FilterUI};
