import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import { getRouteLastCityAPI } from "../../../../../config/boda-boda/apiUrls.config";


class RouteFilterUI extends Component {
    render() {
        return (
            <div className="col-md-12">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Route Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Route Name"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            apiUrl={`${getRouteLastCityAPI.url}/all`}
                            apiStateKey={getRouteLastCityAPI.key}
                            labelText="Waypoint"
                            inputName={"waypoint"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Waypoint"}
                            onRebuildResponseFn={(response) => {
                                const temp = [];

                                response.forEach((value) => {
                                    temp.push({
                                        id: value.cityId,
                                        value: value.city.name
                                    })
                                });

                                return {
                                    data: {
                                        data: temp
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default RouteFilterUI;
