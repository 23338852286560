import { put, call } from 'redux-saga/effects';
import { setPageLoader } from "../ui/actions";
import { get } from "lodash";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getPackageListAPI } from "../../../config/boda-boda/apiUrls.config";
import { resetDataTable } from "../core/actions";
import { setFlashMessage } from "../ui/actions";

const emptyFn = (...para) => undefined;
function* updateManifestId(data) {
    console.log("-----------------------------farah>", data);

    yield put(setPageLoader(true));
    
    let response = yield call(() => callApi(`${getPackageListAPI.url}/${data.id}`).headers(true).method("put").body(data.payload).send());

    if (response._statue === true) {
        yield put(resetDataTable(`${getPackageListAPI.key}`));
        yield put(resetDataTable(`${getPackageListAPI.key}_manifest`));
    }

    yield put(setPageLoader(false));
    yield put(setFlashMessage(data.messageObj));
}



function* updateManifestIdAddPackage(data) {
    console.log("-----------------------------farah>", data);

    yield put(setPageLoader(true));

    const packageUpdate = {
        
        manifest: {
            id: get(data, "payload.manifest.id", "")
        },
        locationCurrent: {
            id: get(data, "payload.locationCurrent.id", ""),
        },
        locationDrop: {
            id: get(data, "payload.locationDrop.id", ""),
        },
        locationPick: {
            id: get(data, "payload.locationPick.id", ""),
        },
    };
    
    let response = yield call(() => callApi(`${getPackageListAPI.url}/${data.payload.id}`).headers(true).method("put").body(packageUpdate).send());
    console.log("RESPONSE",response)
    console.log("jjjjjjjjjjj", get(data, "payload", ""))
    if (response._statue === true) {
        get(data, "payload.setModelStatusFn", emptyFn)(false);
        yield put(resetDataTable(`${getPackageListAPI.key}`));
        yield put(resetDataTable(`${getPackageListAPI.key}_manifest`));
    }

    yield put(setPageLoader(false));
    yield put(setFlashMessage(data.messageObj));
}

export {
    updateManifestId,
    updateManifestIdAddPackage
}
