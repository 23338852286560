import React, { Component } from "react";
import {get} from "lodash";

import { connect } from "react-redux";


import {mergeFormObject} from "../../../store/boda-boda/form/actions";
import {getDataByFormObject} from "../../../helpers/boda-boda/common-helpers/common.helpers";
import {InputButton} from "./Button";

const emptyFn = (...para) => undefined;

class InputButtonWithState extends Component {
    static defaultProps = {
        elementWrapperStyle: "",
        elementStyle: "",
        btnName: "",
        btnText: "",
        btnSize: "md",
        tooltip: "",
        btnColor: "primary",
        isFullWidth: false,
        isBtnDisabled: false,
        isOutlined: false,
        startIcon: null,
        endIcon: null,
        formGroupName: "",
        dataTableKey: null,
        mergeToForm:null,
        onClickBtnFn: emptyFn,
    }

    constructor(){
        super();
    }

    onClickBtn=(event)=>{

        let {mergeFormObject,formState,formGroupName,mergeToForm,onClickBtnFn} =this.props;

        if(mergeToForm!=null){            
          mergeFormObject(formGroupName,mergeToForm);
        }       
        onClickBtnFn({
            event:event,
            "formObject":getDataByFormObject(get(formState,`${formGroupName}`,{})),            
        });
    }

    render(){
        let {
            elementWrapperStyle,elementStyle,btnName,
            btnText,btnSize,btnColor,isFullWidth,
            isBtnDisabled,isOutlined,startIcon,
            endIcon,tooltip
        }=this.props;
        return(
            <InputButton
                elementWrapperStyle={elementWrapperStyle}
                elementStyle={elementStyle}
                btnName={btnName}
                btnText={btnText}
                btnSize={btnSize}
                btnColor={btnColor}
                tooltip={tooltip}
                isFullWidth={isFullWidth}
                isBtnDisabled={isBtnDisabled}
                isOutlined={isOutlined}
                startIcon={startIcon}
                endIcon={endIcon}
                onClickBtnFn={(event)=>this.onClickBtn(event)}
            />
        )
    }
}

const mapStatetoProps = state => {
    return {
        formState:state.FormData
    };
};

export default connect(mapStatetoProps, { 
    mergeFormObject,
})(InputButtonWithState);

