import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";

import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "../../runsheets/runsheets-line/includes/FilterUI";
import { runSheetLineAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { Message, APICallBtn } from "../../runsheets/runsheets-line/includes/CancelRunSheetLine"


class DriverRunSheetLine extends Component {
    render() {
        let { history, match } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Runsheet Line</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Runsheet Line</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title="Runsheets"
                            apiURL={`${runSheetLineAPI.url}?driverId=${match.params.id}&page=1&limit=20`}
                            dataTableStateKey={runSheetLineAPI.key}
                            filterFromComponent={FilterUI}
                            createActionProps={{
                                actionType: actionBtnTypes.create,
                                isSetModel: false,
                                onClickBtnFn: () => history.push("/runsheets-line/create")
                            }}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Runsheet ID", key: "id" },
                                { displayName: "Date", key: "runSheetAt", onPrintFn: (rawData) => dateObjectToString(rawData.runSheetAt, "dd-mm-YY") },
                                { displayName: "Assigned By", key: "assigned", onPrintFn: (rawData) => get(rawData, "creator.staffs[0].name", "") },
                                { displayName: "Route", key: "routeLine.name" },
                                { displayName: "Driver", key: "driver.name" },
                                { displayName: "Status", key: "status" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/runsheets-line/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/runsheets-line/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status !== "EXPIRED";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: true,
                                    actionBtn: {
                                        btnText: "Cancel Runsheet",
                                    },
                                    modalProps: {
                                        modalTitle: "Please Confirm",
                                        modalType: "",
                                        modalBodyComponent: Message,
                                        modalFooterComponent: APICallBtn,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                        parentToModelProps: {
                                            type: "datatable",
                                            displayMessage: "Are you sure you want to cancel this runsheet?",
                                            status: "CANCELLED",
                                            flashMessage: "Runsheet has successfully cancelled"
                                        },
                                        closeBtnName: "No"
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "ACTIVE";
                                    }
                                },
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DriverRunSheetLine;
