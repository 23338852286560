import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import {
    InputBoxWithState,
    FormWrapper,
    InputButtonWithState,
    SelectBoxWithState
} from "../../../components/boda-boda/Forms";
import {
    getUserStaffList, changePasswordAPI, countryCodeAPI
} from "../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../components/boda-boda/CommonElements/BaseElements";
import FileUpload from "../../../components/boda-boda/FileUpload/FileUpload";
import { updateUserProfileTrFn, changePasswordTrFn } from "../../../store/boda-boda/transaction_request/actions";


class EditProfile extends Component {
    render() {
        let { authState, history, updateUserProfileTrFn, changePasswordTrFn } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Update User Profile</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <InputButtonWithState
                                elementWrapperStyle={"mr-2"}
                                btnText={"Save"}
                                startIcon={"mdi mdi-content-save-all-outline"}
                                formGroupName={`${getUserStaffList.key}_edit`}
                                onClickBtnFn={(dataObject) => {
                                    updateUserProfileTrFn({
                                        ...dataObject.formObject,
                                        history: history,
                                        oldEmail: get(authState, `authUser.staff.email`, "")
                                    })
                                }}
                            />

                            <BackBtnComponent
                                path={"/user-profile/view"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserStaffList.key}_edit`}
                            setFormObject={{
                                staffId: get(authState, `authUser.staff.id`, ""),
                                userId: get(authState, `authUser.staff.userId`, ""),
                                name: get(authState, `authUser.staff.name`, ""),
                                identification: get(authState, `authUser.staff.identification`, ""),
                                phone: get(authState, `authUser.staff.phone`, ""),
                                email: get(authState, `authUser.staff.email`, ""),
                                image: get(authState, `authUser.staff.image`, null) === null ? "" : get(authState, `authUser.staff.image`, ""),
                                countryCode: {
                                    id: `${get(authState, "authUser.staff.countryCode.id", "")}/${get(authState, "authUser.staff.countryCode.code", "")}/${get(authState, "authUser.staff.countryCode.prefix", "")}`
                                },
                                countryCodePrefix: get(authState, `authUser.staff.countryCodePrefix`, ""),
                                "_onLoad": false,
                            }}
                        >
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className={"col-md-6"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-4"}>User ID</dd>
                                                        <dt className={"col-sm-8"}>{get(authState, `authUser.staff.userId`, "")}</dt>

                                                        <dd className={"col-sm-4"}>User Role</dd>
                                                        <dt className={"col-sm-8"}>{get(authState, `authUser.role.name`, "")}</dt>

                                                        <dd className={"col-sm-4"}>Default Delivery Center</dd>
                                                        <dt className={"col-sm-8"}>{get(authState, `authUser.facility.name`, "")}</dt>

                                                        <dd className={"col-sm-4"}>Status</dd>
                                                        <dt className={"col-sm-8"}>{get(authState, `authUser.staff.status`, "")}</dt>
                                                    </dl>
                                                </div>

                                                <div className={"col-md-6"}>
                                                    <FileUpload
                                                        labelText={"Profile Picture"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        formKey={"image"}
                                                        isDownload={false}
                                                        helperText={"Supported formats (png, jpg, jpeg)"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Personal Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Name"
                                                        placeHolderText={"Name"}
                                                        inputName={"name"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={100}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Identification"
                                                        placeHolderText={"Identification"}
                                                        inputName={"identification"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <div className={"row"}>
                                                        <div className={"col-md-3"}>
                                                            <SelectBoxWithState
                                                                isRequired={true}
                                                                labelText="Country Code"
                                                                inputStatePath={`${getUserStaffList.key}_edit.countryCode.id`}
                                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                                apiUrl={`${countryCodeAPI.url}/all`}
                                                                apiStateKey={countryCodeAPI.key}
                                                                onRebuildResponseFn={(response) => {
                                                                    const data = [];

                                                                    response.forEach((value) => {
                                                                        data.push({
                                                                            id: `${value.id}/${value.code}/${value.prefix}`,
                                                                            value: value.code
                                                                        });
                                                                    });

                                                                    return {
                                                                        "data": {
                                                                            "data": data
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className={"col-md-9"}>
                                                            <InputBoxWithState
                                                                labelText="Phone"
                                                                inputName={"phone"}
                                                                placeHolderText={"+xxxxxxxxxx"}
                                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Email"
                                                        inputName={"email"}
                                                        placeHolderText={"Email"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        maxLength={50}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormWrapper>
                    </Col>
                </Row>

                <hr />

                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Reset Password</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <InputButtonWithState
                                elementWrapperStyle={"mr-2"}
                                btnText={"Reset Password"}
                                startIcon={"mdi mdi-content-save-all-outline"}
                                formGroupName={changePasswordAPI.key}
                                onClickBtnFn={(dataObject) => {
                                    changePasswordTrFn({
                                        ...dataObject.formObject,
                                        history: history,
                                    })
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={changePasswordAPI.key}
                            onDestroyUnsetFormObject={true}
                        >
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Old Password"
                                                        inputName={"old"}
                                                        placeHolderText={"Old Password"}
                                                        formGroupName={changePasswordAPI.key}
                                                        isRequired={true}
                                                        maxLength={50}
                                                        inputType={"password"}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="New Password"
                                                        inputName={"new"}
                                                        placeHolderText={"New Password"}
                                                        inputType={"password"}
                                                        formGroupName={changePasswordAPI.key}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Re enter New Password"
                                                        inputName={"confirm_password"}
                                                        placeHolderText={"Re enter New Password"}
                                                        inputType={"password"}
                                                        formGroupName={changePasswordAPI.key}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, {
    updateUserProfileTrFn,
    changePasswordTrFn
})(EditProfile);
