import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import { cashOnDeliveryReport, cashOnDeliveryReportAPI, getUserDriverList } from "../../../config/boda-boda/apiUrls.config";
import DeliveryFilterUI from "./driver_includes/DeliveryFilterUI";
import { BackBtnComponent } from "../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";


class Delivery extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { history, match } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={3}>
              <div className="page-title-box">
                <h4 className="font-size-18">Driver - Cash/Card Deliveries</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Cash/Card Deliveries</li>
                </ol>
              </div>
            </Col>
            <Col sm={9}>
              <div className={"d-flex justify-content-end"}>
                <BackBtnComponent
                  elementWrapperStyle={"mr-2"}
                  path={`/users/driver/view/${match.params.id}`}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Delivery Details"
              filterFromComponent={DeliveryFilterUI}
              apiURL={`${cashOnDeliveryReportAPI.url}?page=1&limit=20&driverId=${match.params.id}`}
              dataTableStateKey={cashOnDeliveryReportAPI.key}
              defaultFilterFormObject={{ id: "", name: "", paymentMethod: "", deliveryType: "" }}
              tableHeaderList={[
                { displayName: "Delivery Date", key: "deliveredAt", onPrintFn: (rawData) => dateObjectToString(rawData.deliveredAt, "YY-mm-dd") },
                // { displayName: "Consignment No", key: "consignmentNo" },
                { displayName: "Order No", key: "consignmentNo" },
                { displayName: "Customer Name", key: "name", default: "-" },
                { displayName: "Delivery Type", key: "deliveryType", default: "-" },
                { displayName: "Payment Method", key: "paymentMethod" },
                {
                  displayName: "Order Amount", key: "amount", onPrintFn: (rawData) => {
                    return rawData.amount === null ? 0 : rawData.amount;
                  }
                },
                {
                  displayName: "Cash on Delivery Fee", key: "cashDeliveryPrice", onPrintFn: (rawData) => {
                    if (rawData.paymentMethod === "CASH_ON_DELIVERY") {
                      return rawData.cashDeliveryPrice;
                    } else {
                      return 0;
                    }
                  }
                },
                {
                  displayName: "Card on Delivery Fee", key: "cardDeliveryPrice", onPrintFn: (rawData) => {
                    if (rawData.paymentMethod === "CARD_ON_DELIVERY") {
                      return rawData.cardDeliveryPrice;
                    } else {
                      return 0;
                    }
                  }
                },
                { displayName: "Delivery Status", key: "status" },
              ]}
              isSetAction={false}
              isSetCreateAction={false}
            />
          </Row>


        </div>
      </React.Fragment>
    )
  }
}

export default Delivery;
