import React, {Component} from "react";
import {FormWrapper, InputButtonWithState} from "../../../../../../components/boda-boda/Forms";
import {
    getFacilityAPI,
    getLinehaulAPI,
    getRouteLineLocationAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import {Col, Label, Row} from "reactstrap";
import {BackBtnComponent} from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import {connect} from "react-redux";
import { get, map, set, reject } from "lodash";
import {actionBtnTypes, CrudComponent} from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./create-includes/FilterUI";
import DeliveryCenterViewModalUI from "./DeliveryCenterViewModalUI";
import { linehaulAddLocations, linehaulRemoveLocations, createLinehaul } from "../../../../../../store/boda-boda/transaction_request/actions";

class CreateLinehaul extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    addLocation = (row) => {
        const body = {
            "routeLine": {
                "id": Number(this.props.match.params.id)
            },
            "location": {
                "id": row.locationId
            }
        }

        const flashMsjObj = {
            status: true,
            message: "Location has successfully added",
            type: "success"
        };

        const locations = get(this.props.formState, `${getLinehaulAPI.key}.locations`, "");

        set(this.props.formState, `${getLinehaulAPI.key}.locations`, [...locations, row]);

        this.props.linehaulAddLocations(row.locationId, body, flashMsjObj);
    }

    removeLocation = (row) => {

        const flashMsjObj = {
            status: true,
            message: "Location has successfully removed",
            type: "success"
        };

        let locations = get(this.props.formState, `${getLinehaulAPI.key}.locations`, "");

        locations = reject(locations, function (el) { return el.locationId === row.locationId });

        set(this.props.formState, `${getLinehaulAPI.key}.locations`, [...locations]);

        this.props.linehaulRemoveLocations(row.id, null, flashMsjObj);
    }

    render() {
        let { match, formState, createLinehaul, history } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={getLinehaulAPI.key}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getLinehaulAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            status: response.status,
                            locations: response.locations,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Create Linehaul Route</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <InputButtonWithState
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Save"}
                                    startIcon={"mdi mdi-content-save-all-outline"}
                                    formGroupName={getLinehaulAPI.key}
                                    onClickBtnFn={(dataObject) => {
                                        createLinehaul({
                                            ...dataObject.formObject,
                                            history: history
                                        })
                                    }}
                                />
                                <BackBtnComponent
                                    elementWrapperStyle={""}
                                    path={"/routes/linehaul"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Label>Route ID: {get(formState, `${getLinehaulAPI.key}.id`, "")}</Label>
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <Label>Route Name: {get(formState, `${getLinehaulAPI.key}.name`, "")}</Label>
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <Label>Route Status: {get(formState, `${getLinehaulAPI.key}.status`, "")}</Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="col-md-12 padding-none">
                            <CrudComponent
                                title={"Delivery Centers"}
                                apiURL={`${getFacilityAPI.url}?page=1&limit=20`}
                                dataTableStateKey={`${getFacilityAPI.key}_linehaul`}
                                isSetCreateAction={false}
                                isFilter={true}
                                filterFromComponent={FilterUI}
                                defaultFilterFormObject={{
                                    code: "",
                                    name: "",
                                    status: "",
                                    cityId: "",
                                    zoneId: ""
                                }}
                                tableHeaderList={[
                                    { displayName: "Delivery Center Code", key: "code" },
                                    { displayName: "Name", key: "location.name" },
                                    { displayName: "Status", key: "location.status" },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: true,
                                        modalProps: {
                                            modalTitle: "View Delivery Center",
                                            modalType: "modal-lg",
                                            modalBodyComponent: DeliveryCenterViewModalUI,
                                            isSetClose: true,
                                            isSetFooterClose: true,
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.create,
                                        isSetModel: false,
                                        actionBtn: {
                                            tooltip: "Add",
                                            onClickBtnFn: (rawData) => this.addLocation(rawData)
                                        },
                                        checkBeforeActionRenderFn: (rawData) => {
                                            return !map(get(formState, `${getLinehaulAPI.key}.locations`, ""), "locationId").includes(rawData.rowData.locationId) ;
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="col-md-12 padding-none">
                            <CrudComponent
                                title={"Selected Delivery Centers"}
                                apiURL={`${getRouteLineLocationAPI.url}?routeLineId=${match.params.id}&page=1&limit=20`}
                                dataTableStateKey={`${getRouteLineLocationAPI.key}_linehaul`}
                                isSetCreateAction={false}
                                isFilter={false}
                                tableHeaderList={[
                                    { displayName: "No", key: "id" },
                                    { displayName: "Name", key: "location.name" },
                                    { displayName: "Status", key: "location.status" },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: true,
                                        modalProps: {
                                            modalTitle: "View Delivery Center",
                                            modalType: "modal-lg",
                                            modalBodyComponent: DeliveryCenterViewModalUI,
                                            isSetClose: true,
                                            isSetFooterClose: true,
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.delete,
                                        isSetModel: false,
                                        actionBtn: {
                                            tooltip: "Remove",
                                            onClickBtnFn: (rawData) => this.removeLocation(rawData)
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const CreateLinehaulWithState = connect(mapStateToProps, { linehaulAddLocations, linehaulRemoveLocations, createLinehaul })(CreateLinehaul);

export {
    CreateLinehaulWithState
}