import React, { Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';


const emptyFn = (...para) => undefined;

const TabComponent = ({
    activeTab = "",
    children = null,
    onClickFn = emptyFn
}) => {
    return (
        <Fragment>
            <Nav tabs>
                {
                    React.Children.map(children, (child, i) => {
                        if (child) {
                            return (
                                <NavItem key={i} className={"tabItem"}>
                                    <NavLink
                                        className={(activeTab === child.props.tabId) ? "active" : ""}
                                        onClick={() => onClickFn(child.props.tabId)}
                                    >
                                        {child.props.tabName}
                                    </NavLink>
                                </NavItem>
                            )
                        }
                    })
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                {
                    React.Children.map(children, (child, i) => {
                        if (child) {
                            return (
                                <TabPane tabId={child.props.tabId}>
                                    {child}
                                </TabPane>
                            )
                        }
                    })
                }
            </TabContent>
        </Fragment>
    )
}

const SubTab = (props) => {
    return (
        <div tabid={props.tabId} tabname={props.tabName}>
            {props.children}
        </div>
    )
}

export {
    TabComponent,
    SubTab
}
