import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  DatePickerBoxWithState,
  FormWrapper,
  SelectBoxWithState,
  SubmitButton,
} from "../../../../components/boda-boda/Forms";
import { getFacilityAPI, headOfficeSettlementAPI, merchantAPI } from "../../../../config/boda-boda/apiUrls.config";
import { useDispatch, useSelector } from "react-redux";
import {
  convertToIST,
  getDataByFormObject,
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { get } from "lodash";
import { useEffect } from "react";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setFlashMessage, setPageLoader } from "../../../../store/actions";

export const MerchantSettlement = (props) => {
  const [facility, setFacility] = useState(null)
  const authStatus = useSelector(state => state.AuthState);
  const dispatch = useDispatch();


  useEffect(() => {
    fetchHeadOfficeFacility()
  }, [])

  const fetchHeadOfficeFacility = () => {
    setPageLoader(true);
    callApi(`${getFacilityAPI.url}/all?isHeadOffice=true`
    )
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        setFacility(result.data.data[0])

        if (result?.data?.data[0]?.id !== authStatus?.authUser?.facility?.id) {
          dispatch(setFlashMessage({
            status: true,
            message: "Permission denied to access",
            type: "danger"
          }));
        }

        setPageLoader(false);
      });
  };

  return (
    <React.Fragment>
      {facility?.id && facility?.id === authStatus?.authUser?.facility?.id ?
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Merchant Settlement</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Merchant Settlement</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"card"} style={{ marginBottom: "120px" }}>
                <div className={"card-body"}>
                  <FormWrapper
                    setGroupName={`${headOfficeSettlementAPI.key}`}
                    onDestroyUnsetFormObject={false}
                    setFormObject={{
                      settlementDate: null,
                      merchant: "",
                    }}
                  >
                    <div className={"row"}>
                      <div className="col-md-6">
                        <SelectBoxWithState
                          labelText={"Merchant"}
                          inputName={"merchant"}
                          placeHolderText={"Merchant"}
                          formGroupName={`${headOfficeSettlementAPI.key}`}
                          apiUrl={`${merchantAPI.url}/all`}
                          apiStateKey={merchantAPI.key}
                          onRebuildResponseFn={(response) => {
                            const data = [
                              {
                                id: "",
                                value: "All",
                              },
                            ];

                            response.forEach((value) => {
                              data.push({
                                id: `${value.id}/${value.name}`,
                                value: `${value.name}`,
                              });
                            });

                            return {
                              data: {
                                data: data,
                              },
                            };
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <DatePickerBoxWithState
                          isRequired={true}
                          labelText="Settlement Date"
                          inputName={"settlementDate"}
                          formGroupName={`${headOfficeSettlementAPI.key}`}
                          placeholderText={"Delivery Transaction Date To"}
                        />
                      </div>
                    </div>

                    <div className={"row"}>
                      <div className={"col-md-12"}>
                        <CreateMerchantSettlementBtn />
                      </div>
                    </div>
                  </FormWrapper>
                </div>
              </div>
            </div>
          </div>
        </div> : null}
    </React.Fragment>
  );
};


const CreateMerchantSettlementBtn = (props) => {
  const authState = useSelector(state => state.AuthState);
  const currentFacilityId = get(authState, `authUser.facility.id`, '');
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Fragment>
      <SubmitButton
        btnText={"Generate"}
        startIcon={"fas fa-spinner"}
        formGroupName={`${headOfficeSettlementAPI.key}`}
        isValidate={true}
        validationObject={{
          fields: {
            settlementDate: "Settlement Date",
          },
          rules: {
            settlementDate: "required",
          },
          message: {}
        }}
        callApiObject={{
          isSetHeaders: true,
          multipart: false,
          method: "post",
          onUpload: false
        }}
        onGetAPIEndPointFn={(formObject) => {
          return {
            url: headOfficeSettlementAPI.url,
            key: headOfficeSettlementAPI.key
          }
        }}
        onChangeRequestBodyFn={(formObject) => {
          const data = getDataByFormObject(formObject);

          const requestBody = {
            "type": "COD",
            "settleFromFacility": {
              "id": Number(currentFacilityId),
            },
            "settleToMerchant": {
              "id": Number(data.merchant.split("/")[0]),
            },
            "settlementDate": convertToIST(data.settlementDate)
          }

          if (data.merchant === "") {
            delete requestBody['settleToMerchant']
          }

          return requestBody
        }}
        onResponseCallBackFn={(error, result) => {
          if (!error) {
            if (result.data?.length > 0) {
              dispatch(setFlashMessage({
                status: true,
                message: "Merchant Settlement has successfully generated",
                type: "success"
              }));
            }
            history.push("/merchant-settlement/view")
          }
        }}
      />
    </Fragment>
  )
}