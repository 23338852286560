import { takeEvery, fork, all } from 'redux-saga/effects';
import { createUserFn, updateUserFn } from "./userSaga";
import { createDriverFn, updateDriverFn } from "./driverSaga";
import { updateManifestId , updateManifestIdAddPackage} from "./manifestSaga";
import {
    createUserTrKey,
    updateUserTrKey,
    updateManifestIdTrKey,
    updateManifestIdAddPackageTrKey,
    createDriverTrKey,
    deliveryCenterAddZones,
    createZoneTrKey,
    zoneAddCitiesTrKey,
    createRunSheetLineTrKey,
    addCollectionsRunSheetLineTrKey,
    editRunSheetLineTrKey,
    createRunSheetLastTrKey,
    editRunSheetLastTrKey,
    addPickupsRunSheetLastTrKey,
    createMerchantTrKey,
    createMerchantAdministrationTrKey,
    createMerchantBranchTrKey,
    removePickupsRunSheetLastTrKey,
    addDeliveriesRunSheetLastTrKey,
    removeDeliveriesRunSheetLastTrKey,
    routeLastAddCitiesTrKey,
    routeLastRemoveCitiesTrKey,
    linehaulAddLocationsTrKey,
    linehaulRemoveLocationsTrKey,
    updateLinehaulTrKey,
    updateLastMileTrKey,
    createLinehaulTrKey,
    createLastMileTrKey,
    updateMerchantBranchTrKey,
    updateUserProfileTrKey,
    changePasswordTrKey,
    updateDriverTrKey,
    bulkTagPrintTrKey,
    createMerchantAdminUserTrKey,
    userPasswordResetTrKey,
    createBranchTrKey,
    switchFacilityTrKey, changeDriverPasswordTrKey, createMerchantContactTrKey, createMerchantBranchContactTrKey, updateMerchantAdminUserTrKey, editMerchantTrKey, addReturnsRunSheetLastTrKey, removeReturnsRunSheetLastTrKey
} from "./actionTypes";
import { addZones } from "./deliveryCenterSaga";
import { createZoneFn, addCities } from "./zoneSaga";
import { createRunSheetLine, addCollections, editRunSheetLine } from "./runSheetLineSaga";
import { createRunSheetLast, editRunSheetLast, addPickups, removePickups, addDeliveries, removeDeliveries, addReturns, removeReturns } from "./runSheetLastSaga";
import { createMerchantFn, editMerchantFn } from "./merchantSaga";
import { createMerchantAdministrationFn } from "./merchantAdministration";
import { createMerchantBranchFn } from "./merchantBranchSaga";
import { updateMerchantBranchFn} from "./merchantBranchUpdateSaga";
import { bulkTagPrintFn } from "./bulkTagPrintSaga";
import {
    linehaulAddLocations,
    routeLastAddCities,
    routeLastRemoveCities,
    linehaulRemoveLocations,
    updateLinehaul,
    updateLastMile,
    createLinehaul,
    createLastMile
} from "./routeSaga";
import { updateProfileDetails, changePassword, switchFacility } from "./userProfileSaga";
import {createMerchantAdminUserFn, updateMerchantAdminUserTrFn} from "./merchantAdminUserSaga";
import {userPasswordResetFn} from "./userPasswordSaga";
import {createBranchFn} from "./branchSaga";
import {driverPasswordResetFn} from "./driverPasswordSaga";
import {createContactFn} from "./createMerchantContactSaga";
import {createMerchantContactFn} from "./createMerchantBranchContactSaga";


function* watchAPIcallRequest() {
    yield takeEvery(createUserTrKey, createUserFn);
}

function* watchAPIUserUpdateRequest() {
    yield takeEvery(updateUserTrKey, updateUserFn);
}

function* watchDriverAPIcallRequest() {
    yield takeEvery(createDriverTrKey, createDriverFn);
}

function* watchDriverUpdateAPIcallRequest() {
    yield takeEvery(updateDriverTrKey, updateDriverFn);
}

function* watchAPIUpdateMerchantAdminUser() {
    yield takeEvery(updateMerchantAdminUserTrKey, updateMerchantAdminUserTrFn);
}

function* watchAPIUpdateManifestId() {
    yield takeEvery(updateManifestIdTrKey, updateManifestId);
}

function* watchAPIUpdateManifestIdAddPackage() {
    yield takeEvery(updateManifestIdAddPackageTrKey, updateManifestIdAddPackage);
}

function* watchAPIAddZones() {
    yield takeEvery(deliveryCenterAddZones, addZones);
}

function* watchAPICreateZones() {
    yield takeEvery(createZoneTrKey, createZoneFn);
}

function* watchAPIAddCities() {
    yield takeEvery(zoneAddCitiesTrKey, addCities);
}

function* watchAPIRouteLastAddCities() {
    yield takeEvery(routeLastAddCitiesTrKey, routeLastAddCities)
}

function* watchAPIRouteRemoveCities() {
    yield takeEvery(routeLastRemoveCitiesTrKey, routeLastRemoveCities)
}

function* watchAPILinehaulAddLocations() {
    yield takeEvery(linehaulAddLocationsTrKey, linehaulAddLocations)
}

function* watchAPILinegaulRemoveLocations() {
    yield takeEvery(linehaulRemoveLocationsTrKey, linehaulRemoveLocations)
}

function* watchAPICreateRunSheetLine() {
    yield takeEvery(createRunSheetLineTrKey, createRunSheetLine);
}

function* watchAPIAddCollectionsRunSheetLine() {
    yield takeEvery(addCollectionsRunSheetLineTrKey, addCollections);
}

function* watchAPIEditRunSheetLine() {
    yield takeEvery(editRunSheetLineTrKey, editRunSheetLine);
}

function* watchAPICreateRunSheetLast() {
    yield takeEvery(createRunSheetLastTrKey, createRunSheetLast);
}

function* watchAPIEditRunSheetLast() {
    yield takeEvery(editRunSheetLastTrKey, editRunSheetLast);
}

function* watchAPIAddPickupsRunSheetLast() {
    yield takeEvery(addPickupsRunSheetLastTrKey, addPickups);
}

function* watchAPICreateMerchant() {
    yield takeEvery(createMerchantTrKey, createMerchantFn)
}

function* watchAPIEditMerchant() {
    yield takeEvery(editMerchantTrKey, editMerchantFn)
}

function* watchAPICreateMerchantAdministration() {
    yield takeEvery(createMerchantAdministrationTrKey, createMerchantAdministrationFn)
}

function* watchAPICreateMerchantAdminUser() {
    yield takeEvery(createMerchantAdminUserTrKey, createMerchantAdminUserFn)
}

function* watchAPICreateMerchantBranch() {
    yield takeEvery(createMerchantBranchTrKey, createMerchantBranchFn)
}

function* watchAPIRemovePickupsRunSheetLast() {
    yield takeEvery(removePickupsRunSheetLastTrKey, removePickups);
}

function* watchAPIAddDeliveriesRunSheetLast() {
    yield takeEvery(addDeliveriesRunSheetLastTrKey, addDeliveries);
}

function* watchAPIRemoveDeliveriesRunSheetLast() {
    yield takeEvery(removeDeliveriesRunSheetLastTrKey, removeDeliveries);
}

function* watchAPIAddReturnsRunSheetLast() {
    yield takeEvery(addReturnsRunSheetLastTrKey, addReturns);
}

function* watchAPIRemoveReturnsRunSheetLast() {
    yield takeEvery(removeReturnsRunSheetLastTrKey, removeReturns);
}

function* watchAPILinehaulUpdate() {
    yield takeEvery(updateLinehaulTrKey, updateLinehaul);
}

function* watchAPILastMileUpdate() {
    yield takeEvery(updateLastMileTrKey, updateLastMile);
}

function* watchAPICreateLinehaul() {
    yield takeEvery(createLinehaulTrKey, createLinehaul);
}

function* watchAPICreateLastMile() {
    yield takeEvery(createLastMileTrKey, createLastMile);
}

function* watchAPIUpdateMerchantBranch() {
    yield takeEvery(updateMerchantBranchTrKey, updateMerchantBranchFn);
}

function* watchAPIUpdateUserProfile() {
    yield takeEvery(updateUserProfileTrKey, updateProfileDetails);
}

function* watchAPIChangePassword() {
    yield takeEvery(changePasswordTrKey, changePassword);
}

function* watchAPIPostBulkTagPrint() {
    yield takeEvery(bulkTagPrintTrKey, bulkTagPrintFn)
}

function* watchAPIUserPasswordReset() {
    yield takeEvery(userPasswordResetTrKey, userPasswordResetFn)
}

function* watchAPICreateBranch() {
    yield takeEvery(createBranchTrKey, createBranchFn)
}

function* watchAPISwitchFacility() {
    yield takeEvery(switchFacilityTrKey, switchFacility)
}

function* watchAPIDriverPasswordChange() {
    yield takeEvery(changeDriverPasswordTrKey, driverPasswordResetFn)
}

function* watchAPICreateContact() {
    yield takeEvery(createMerchantContactTrKey, createContactFn)
}

function* watchAPICreateMerchantBranchContact() {
    yield takeEvery(createMerchantBranchContactTrKey, createMerchantContactFn)
}

function* transactionSaga() {
    yield all([
        fork(watchAPIcallRequest),
        fork(watchAPIUserUpdateRequest),
        fork(watchAPIUpdateManifestId),
        fork(watchAPIUpdateManifestIdAddPackage),
        fork(watchDriverAPIcallRequest),
        fork(watchDriverUpdateAPIcallRequest),
        fork(watchAPIAddZones),
        fork(watchAPICreateZones),
        fork(watchAPIAddCities),
        fork(watchAPICreateRunSheetLine),
        fork(watchAPIAddCollectionsRunSheetLine),
        fork(watchAPIEditRunSheetLine),
        fork(watchAPICreateRunSheetLast),
        fork(watchAPIEditRunSheetLast),
        fork(watchAPIAddPickupsRunSheetLast),
        fork(watchAPICreateMerchant),
        fork(watchAPICreateMerchantAdministration),
        fork(watchAPICreateMerchantBranch),
        fork(watchAPIRemovePickupsRunSheetLast),
        fork(watchAPIAddDeliveriesRunSheetLast),
        fork(watchAPIRemoveDeliveriesRunSheetLast),
        fork(watchAPIRouteLastAddCities),
        fork(watchAPIRouteRemoveCities),
        fork(watchAPILinehaulAddLocations),
        fork(watchAPILinegaulRemoveLocations),
        fork(watchAPILinehaulUpdate),
        fork(watchAPILastMileUpdate),
        fork(watchAPICreateLinehaul),
        fork(watchAPICreateLastMile),
        fork(watchAPIUpdateMerchantBranch),
        fork(watchAPIUpdateUserProfile),
        fork(watchAPIChangePassword),
        fork(watchAPIPostBulkTagPrint),
        fork(watchAPICreateMerchantAdminUser),
        fork(watchAPIUpdateMerchantAdminUser),
        fork(watchAPIUserPasswordReset),
        fork(watchAPICreateBranch),
        fork(watchAPISwitchFacility),
        fork(watchAPIDriverPasswordChange),
        fork(watchAPICreateContact),
        fork(watchAPICreateMerchantBranchContact),
        fork(watchAPIEditMerchant),
        fork(watchAPIAddReturnsRunSheetLast),
        fork(watchAPIRemoveReturnsRunSheetLast),
    ])
}

export default transactionSaga;
