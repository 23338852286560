import parsePhoneNumber from 'libphonenumber-js';

import { getInputsForValidate, isEmptyValue } from "../../common-helpers/common.helpers";

/**
 * @author Chanaka Wickramasinghe
 * @description validate phone number
 * @param {string} key input value key
 * @param {object} values form values
 * @param {array} param additional validation parameters
 * @param {string} message Error message
 * @param {object} filedList display name for form elements
 * @param {Function} cb callback function
 */
const phoneNumber = (key, values, param, message, filedList, cb) => {
    try {
        const countryCode = getInputsForValidate(values, param[1]);

        if (isEmptyValue(countryCode)) {
            const countryFieldName = getInputsForValidate(filedList, param[1]);
            cb(`The ${countryFieldName} field is required`);
        } else {
            const formValue = getInputsForValidate(values, key);
            const phoneFieldName = getInputsForValidate(filedList, key);

            if (param[0] !== "OTHER") {
                const phoneNumber = parsePhoneNumber(formValue, param[0]);

                if (phoneNumber) {
                    if (phoneNumber.country === param[0] && phoneNumber.isValid() === true) {
                        cb(null, true);
                    } else {
                        cb(`The ${phoneFieldName} field is invalid`);
                    }
                } else {
                    cb(`The ${phoneFieldName} field is invalid`);
                }
            } else {
                if (formValue.match(/^[+]?\d+$/) === null) {
                    cb(`The ${phoneFieldName} field is invalid`);
                } else if (formValue.length < 7) {
                    cb(`The ${phoneFieldName} should not be less than 7 characters`);
                } else if (formValue.length > 21) {
                    cb(`The ${phoneFieldName} should not be greater than 21 characters`);
                } else {
                    cb(null, true);
                }
            }
        }

        // if (valueCharArray.length === 3) {
        //     cb("The Phone field is required");/^[\d+]+$/
        // } else if (isNaN(Number(formValue))) {
        //     cb("The Phone must be a number");
        // } else if ((valueCharArray.length - phoneNumberPrefix.length) !== 9) {
        //     cb("The Phone must be 9 digits");
        // } else if (valueCharArray[3] === "0") {
        //     cb("The Phone should not be start with 0");
        // } else {
        //     cb(null, true);
        // }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (requiredIf)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
}

/**
 * @author Chanaka Wickramasinghe
 * @description validate password
 * @param {string} key input value key
 * @param {object} values form values
 * @param {array} param additional validation parameters
 * @param {string} message Error message
 * @param {object} filedList display name for form elements
 * @param {Function} cb callback function
 */
const passwordPolicy = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length < 8) {
            cb(`The ${filedList[key]} must be at least 8 characters`);
        } else if (formValue.match(/[A-Z]/g) === null) {
            cb(`The ${filedList[key]} must contain at least one uppercase letter`);
        } else if (formValue.match(/[0-9]/g) === null) {
            cb(`The ${filedList[key]} must contain at least one number`);
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (requiredIf)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
}

/**
 * @author Chanaka Wickramasinghe
 * @description validate latitude
 * @param {string} key input value key
 * @param {object} values form values
 * @param {array} param additional validation parameters
 * @param {string} message Error message
 * @param {object} filedList display name for form elements
 * @param {Function} cb callback function
 */
const latitude = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue && parseFloat(formValue) >= 5.94851 && parseFloat(formValue) <= 9.81667) {
            cb(null, true);
        } else {
            cb("The Latitude should be between 5.94851 and 9.81667");
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (requiredIf)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
}

/**
 * @author Chanaka Wickramasinghe
 * @description validate longitude
 * @param {string} key input value key
 * @param {object} values form values
 * @param {array} param additional validation parameters
 * @param {string} message Error message
 * @param {object} filedList display name for form elements
 * @param {Function} cb callback function
 */
const longitude = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue && parseFloat(formValue) >= 79.79528 && parseFloat(formValue) <= 81.84198) {
            cb(null, true);
        } else {
            cb("The Longitude should be between 79.79528 and 81.84198");
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (requiredIf)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
}

export {
    phoneNumber,
    passwordPolicy,
    latitude,
    longitude
};
