import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { InputBoxWithState, SelectBoxWithState, DatePickerBoxWithState } from "../../../../components/boda-boda/Forms";
import { getMerchantAPI, getMerchantBranchAllAPI } from "../../../../config/boda-boda/apiUrls.config";
import { pickRequestStatusList } from "../../../../config/boda-boda/db_config/db_status.config";


const FilterUI = (props) => {
    const authState = useSelector(state => state.AuthState);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Pick Up Request Code"
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        maxLength={17}
                        placeHolderText={"Pick Up Request Code"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText="Merchant"
                        inputName={"merchantId"}
                        formGroupName={props.searchFromKey}
                        placeHolderText={"Merchant"}
                        apiUrl={`${getMerchantAPI.url}/all`}
                        apiStateKey={getMerchantAPI.key}
                        keyName={"id"}
                        valueName={"name"}
                    />
                </div>

                {/* <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Article ID"
                        inputName={"articleId"}
                        formGroupName={props.searchFromKey}
                        maxLength={15}
                        placeHolderText={"Article ID"}
                    />
                </div> */}

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"From Date"}
                        inputName={"pickupDateFrom"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"From Date"}
                    />
                </div>

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"To Date"}
                        inputName={"pickupDateTo"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"To Date"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Pick Up Location"}
                        placeHolderText={"All"}
                        inputName={"locationPickId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={`${getMerchantBranchAllAPI.url}?facilityId=${get(authState, "authUser.facility.id", "")}`}
                        apiStateKey={getMerchantBranchAllAPI.key}
                        keyName={"locationId"}
                        valueName={"name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Status"}
                        inputName={"status"}
                        formGroupName={props.searchFromKey}
                        dataList={[
                            {
                                id: "",
                                value: "All"
                            },
                            ...pickRequestStatusList
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

export default FilterUI;
