import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../../../components/boda-boda/Forms";
import {
    getMerchantAPI,
    getPackageCategoryListAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import {
    deliveryTypes,
    paymentMethods
} from "../../../../../../config/boda-boda/db_config/db_status.config";


class DeliveriesFilterUI extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Customer Name"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Merchant Name"
                            inputName={"merchantId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Merchant"}
                            apiUrl={`${getMerchantAPI.url}/all`}
                            apiStateKey={getMerchantAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Article Id"
                            inputName={"articleNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Article No"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Delivery Type"}
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryTypes}
                            placeHolderText={"Delivery Type"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Payment Method"}
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Channel Order No"
                            inputName={"channelOrderNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Channel Order No"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Package Category"}
                            inputName={"categoryId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getPackageCategoryListAPI.url}`}
                            apiStateKey={getPackageCategoryListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            placeHolderText={"Package Category"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default DeliveriesFilterUI;
