import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { get, filter } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getOrdersListAPI, getPackageListAPI, getOrderImageAPI } from "../../../../config/boda-boda/apiUrls.config";
import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { currency, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";
import ExpansionPanel from "../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { TerminateUI } from "./TerminateUI";
import { modalTypes } from "../../../../components/boda-boda/Modal/ModalUI";
import { PrintLabelBtn } from "../../packages/includes/ShipingLabel";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { ShippingLabelUI } from "../../packages/includes/ShippingLabelUI";
import { ReadyToReturnUI } from "./ReadyToReturnUI";
import { PendingDeliveryUI } from "./PendingDeliveryUI";


class ViewUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderPanel: true,
            receiverPanel: false,
            locationPanel: false,
            notePanel: false,
            terminationStatus: false,
            imagesArray: [],
        }
    }

    componentDidMount() {
        this.checkTerminationStatus();
    }

    checkTerminationStatus = async () => {
        let { match } = this.props;

        const response = await callApi(`${getPackageListAPI.url}/all?orderId=${match.params.id}`)
            .headers(true)
            .method("get")
            .send();

        if (response._statue === true) {
            let count = 0;
            let terminationStatus = false;
            get(response, "data.data", []).forEach(item => {
                if (item.status === "NOT_DELIVERED" && item?.locationCurrentId !== null) {
                    count++;
                    // terminationStatus = true;
                }
            })
            if (count === get(response, "data.data", [])?.length) {
                terminationStatus = true;
            }
            this.setState({
                ...this.state,
                terminationStatus: terminationStatus
            })
        }
        callApi(`${getOrderImageAPI.url}/all?orderId=${match.params.id}`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                if (get(result, "data.statusCode", 0) === 403) {

                } else {
                    this.setState({
                        ...this.state,
                        imagesArray: get(result, "data.data", []),
                    })
                }
            })

    }

    getRelevantImages = (type) => {

        let images = [];

        if (type === "signature") {
            images = filter(get(this.state, `imagesArray`, []), (value) => value.type === "SIGNATURE");
        } else {
            images = filter(get(this.state, `imagesArray`, []), (value) => value.type !== "SIGNATURE");
        }

        return images;
    }

    zoomImage = (src) => {
        let modal = document.getElementById("orderImageModal");
        let modalImg = document.getElementById("img01");

        modal.style.display = "block";
        modalImg.src = src;
    }

    closeModal = () => {
        let modal = document.getElementById("orderImageModal");
        modal.style.display = "none";
    }

    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getOrdersListAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            email: response.email,
                            trackingNumber: response.trackingNumber,
                            idVerificationRequired: response.idVerificationRequired,
                            idVerificationType: response.idVerificationType,
                            idVerificationReference: response.idVerificationReference,
                            phone: response.phone,
                            status: response.status,
                            deliveryType: response.deliveryType,
                            amount: response.amount,
                            paymentMethod: response.paymentMethod,
                            shippingLocation: response.shippingLocation,
                            consignmentNo: response.consignmentNo,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            signatureRequired: response.signatureRequired,
                            merchant: response.merchant.name,
                            packages: response.packages,
                            terminatedAt: response.terminatedAt,
                            remarkDelivery: response.remarkDelivery,
                            deliveredAt: response.deliveredAt,
                            images: response.images,
                            courier: response.courier,
                            codSettlementStatus: response?.codSettlementStatus,
                            hubSettlementReferenceNo: response?.hubSettlement?.reference,
                            headOfficeSettlementReferenceNo: response?.headOfficeSettlement?.reference,
                            returnedAt: response?.returnedAt,
                            remarkReturn: response?.remarkReturn,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Consignment</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/consignments"}
                                />

                                {
                                    (get(formState, `${getOrdersListAPI.key}_view.status`, "") === "PACKED") ? (//&& get(formState, `${getOrdersListAPI.key}_view.terminatedAt`, "") === null && this.state.terminationStatus) ? (
                                        <ReadyToReturnUI
                                            isViewScreen={true}
                                            historyFn={history}
                                            consignment={get(formState, `${getOrdersListAPI.key}_view.id`, "")}
                                        />
                                    ) : null
                                }

                                {
                                    (get(formState, `${getOrdersListAPI.key}_view.status`, "") === "READY_FOR_RETURN") ? (
                                        <PendingDeliveryUI
                                            historyFn={history}
                                            consignment={get(formState, `${getOrdersListAPI.key}_view.id`, "")}
                                        />
                                    ) : null
                                }

                                {
                                    (get(formState, `${getOrdersListAPI.key}_view.status`, "") === "PACKED") ? (
                                        <InputButton
                                            btnText={"Edit"}
                                            startIcon={"mdi mdi-square-edit-outline"}
                                            onClickBtnFn={() => history.push(`/consignments/edit/${match.params.id}`)}
                                        />) : (null)
                                }

                            </div>
                        </Col>
                    </Row>

                    <ExpansionPanel
                        title={"Order Details"}
                        isOpen={this.state.orderPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                orderPanel: !prevState.orderPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    {/* <dd className={"col-sm-5"}>Order ID</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.id`, "")}</dt> */}

                                    <dd className={"col-sm-5"}>Consignment No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.consignmentNo`, "")}</dt>

                                    <dd className={"col-sm-5"}>Signature Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.signatureRequired`, false) === false ? "No" : "Yes"}</dt>

                                    <dd className={"col-sm-5"}>Delivery Type</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.deliveryType`, "")}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Amount</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${getOrdersListAPI.key}_view.amount`, null) === null ? "" : get(formState, `${getOrdersListAPI.key}_view.amount`, "") + ` ${currency}`
                                                    }
                                                </dt>
                                            </Fragment>

                                        ) : null
                                    }


                                    <dd className={"col-sm-5"}>Status</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.status`, "") === "PACKED" ? "PENDING_DELIVERY" : get(formState, `${getOrdersListAPI.key}_view.status`, "")}</dt>

                                    <dd className={"col-sm-5"}>Status Reason</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.packages[0].notDeliveredReason`, "")}</dt>


                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Merchant</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.merchant`, "")}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Driver Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.courier.staffs[0].name`, "")}</dt>
                                            </Fragment>

                                        ) : null
                                    }

                                    <dd className={"col-sm-5"}>Tracking No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.trackingNumber`, "-")}</dt>

                                    {/* {
                                        (get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Reason for Not Delivered - </dd>
                                                <dt className={"col-sm-7"}></dt>
                                            </Fragment>
                                        ) : null
                                    } */}

                                    <dd className={"col-sm-5"}>Payment Method</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "")}</dt>

                                    <dd className={"col-sm-5"}>Returned Date & Time</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.returnedAt`, "") ? dateObjectToString(get(formState, `${getOrdersListAPI.key}_view.returnedAt`, ""), "YY-mm-dd HH:MM:II") : ""}</dt>

                                    {
                                        get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED" && get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === "CASH_ON_DELIVERY" ?
                                            (<Fragment>
                                                <dd className={"col-sm-5"}>Cash on Delivery Status</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Reference Number</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HUB_SETTLED" ?
                                                    get(formState, `${getOrdersListAPI.key}_view.hubSettlementReferenceNo`, "-")
                                                    : get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HEAD_OFFICE_SETTLED" ?
                                                        get(formState, `${getOrdersListAPI.key}_view.headOfficeSettlementReferenceNo`, "-")
                                                        : "N/A"}
                                                </dt>
                                            </Fragment>)
                                            : null
                                    }

                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Customer Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.phone`, "")}</dt>

                                    <dd className={"col-sm-5"}>Remark</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.remarkReturn`, "")}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Receiver Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.remarkDelivery`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Delivered Date and Time</dd>
                                                <dt className={"col-sm-7"}>{dateObjectToString(get(formState, `${getOrdersListAPI.key}_view.deliveredAt`, ""), "YY-mm-dd HH:MM:II")}</dt>
                                            </Fragment>
                                        ) : null
                                    }


                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>ID Verification Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === false ? "No" : "Yes"}</dt>
                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === true) ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, "")}</dt>

                                                <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, "")}</dt>
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>
                        </div>

                        {
                            get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED" || get(formState, `${getOrdersListAPI.key}_view.status`, "") === "RETURNED_TO_MERCHANT"? (
                                <Fragment>
                                    <hr />

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <h4 className="card-title">Signature Images</h4>

                                            <div className={"row"}>
                                                {
                                                    this.getRelevantImages("signature").length === 0 ? (
                                                        <div className={"col-md-3"}>Nothing to show</div>
                                                    ) : (
                                                        this.getRelevantImages("signature").map((value, index) => {
                                                            return (
                                                                <div className={"col-md-3"} key={index}>
                                                                    <img
                                                                        className={"img-thumbnail signature-picture"}
                                                                        src={value.url}
                                                                        onClick={() => this.zoomImage(value.url)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>

                                            <h4 className="card-title mt-3">Other Images</h4>

                                            <div className={"row"}>
                                                {
                                                    this.getRelevantImages("other").length === 0 ? (
                                                        <div className={"col-md-3"}>Nothing to show</div>
                                                    ) : (
                                                        this.getRelevantImages("other").map((value, index) => {
                                                            return (
                                                                <div className={"col-md-3"} key={index}>
                                                                    <img
                                                                        className={"img-thumbnail signature-picture"}
                                                                        src={value.url}
                                                                        onClick={() => this.zoomImage(value.url)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>

                                            <div className={"mb-3"}></div>
                                        </div>
                                    </div>

                                    <div id="orderImageModal" className="modalOrder">
                                        <span
                                            className="closeOrderModal"
                                            onClick={() => this.closeModal()}
                                        >
                                            &times;
                                        </span>

                                        <img className="modal-content-order" id="img01" />
                                    </div>
                                </Fragment>
                            ) : null
                        }
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.postalCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lat`, "")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lon`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.name`, "")}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Notes"}
                        isOpen={this.state.notePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                notePanel: !prevState.notePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-3"}>Special Note - </dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPublic`, "None")}</dt>

                                    {/* <dd className={"col-sm-3"}>Private Note - </dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPrivate`, "None")}</dt> */}
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <div className="row">
                        <CrudComponent
                            title="Packages"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                            dataTableStateKey={getPackageListAPI.key}
                            isFilter={false}
                            isSetCreateAction={false}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Package No", key: "id" },
                                { displayName: "Package Type", key: "type.name" },
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Delivery Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/view/${rawData.id}`),
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/packages/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.status === "RECEIVED" || rawData.rowData.status === "SORTED" || rawData.rowData.status === "NOT_DELIVERED") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "fa fa-barcode",
                                        color: "primary",
                                        tooltip: "Shipping Label",
                                        btnText: "Shipping_Label"
                                    },
                                    modalProps: {
                                        modalTitle: "Shipping Label",
                                        modalType: modalTypes.large,
                                        modalBodyComponent: (rawData) => {
                                            return <ShippingLabelUI packages={[rawData.row]} />
                                        },
                                        modalFooterComponent: PrintLabelBtn,
                                        parentToModelProps: {
                                            type: "datatable"
                                        },
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                }
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ConsignmentView = connect(mapStateToProps, null)(ViewUI);

export {
    ConsignmentView
}
