import React, { Fragment, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap"
import { DatePickerBoxWithState, FormWrapper, SelectBoxWithState, SubmitButton } from "../../../../components/boda-boda/Forms";
import { getFacilityAPI, hubSettlementAPI } from "../../../../config/boda-boda/apiUrls.config";
import { useSelector } from "react-redux";
import { convertToIST, getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { get } from "lodash";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { CustomLoader } from "../../../../components/boda-boda/CommonElements/BaseElements";

export const HubSettlement = (props) => {
    const coreState = useSelector((state) => state.CoreState);
    const [settledToDropdownData, setSettledToDropdownData] = useState("loading");

    useEffect(() => {
        async function loadHeadOfficeData() {
            const response = await callApi(`${getFacilityAPI.url}/all?isHeadOffice=true`)
                .headers(true)
                .method("get")
                .send()

            if (response._statue === true) {
                const finalData = []
                for (let i = 0; i < response?.data?.data?.length; i++){
                    finalData.push({
                        id: response?.data?.data[0].id,
                        value: response?.data?.data[0].name,
                    })
                }
                setSettledToDropdownData(finalData)
            } else {
                setSettledToDropdownData([])
            }
        }

        loadHeadOfficeData()
    }, [])

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Hub Settlement</h4>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Hub Settlement</li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <div className={"col-md-12"}>

                        <div className={"card"} style={{ marginBottom: "120px" }}>
                            <div className={"card-body"}>
                                {settledToDropdownData === "loading" ? 
                                <center>
                                    <CustomLoader />
                                </center> :

                                    <FormWrapper
                                        setGroupName={`${hubSettlementAPI.key}`}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            settlementDate: null,
                                            settleToFacilityId: settledToDropdownData[0]?.id,
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Settlement Date"
                                                    inputName={"settlementDate"}
                                                    formGroupName={`${hubSettlementAPI.key}`}
                                                    placeholderText={"Settlement Date"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Settled To"
                                                    inputName={"settleToFacilityId"}
                                                    // inputStatePath={`${hubSettlementAPI.key}.settleToFacilityId`}
                                                    formGroupName={`${hubSettlementAPI.key}`}
                                                    dataList={settledToDropdownData}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <CreateHubSettlementBtn />

                                            </div>
                                        </div>
                                    </FormWrapper>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

const CreateHubSettlementBtn = (props) => {
    const authState = useSelector(state => state.AuthState);
    const currentFacilityId = get(authState, `authUser.facility.id`, '');
    const history = useHistory();
    return (
        <Fragment>
            <SubmitButton
                btnText={"Generate"}
                startIcon={"fas fa-spinner"}
                formGroupName={`${hubSettlementAPI.key}`}
                // dataTableKey={hubSettlementAPI.key}
                isValidate={true}
                validationObject={{
                    fields: {
                        settlementDate: "Settlement Date",
                    },
                    rules: {
                        settlementDate: "required",
                    },
                    message: {}
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Hub Settlement has successfully generated",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                // apiDataStoringObject={{
                //     setLoader: true,
                //     storingType: apiResponseStoringType.dateTableLinkedForm,
                //     mergeToSuccessResponse: null,
                //     mergeToErrorResponse: null,
                // }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: hubSettlementAPI.url,
                        key: hubSettlementAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    const data = getDataByFormObject(formObject);

                    let settlementDate = new Date(get(data, "settlementDate"));
                    settlementDate = new Date(settlementDate.setDate(settlementDate.getDate() + 1));
                    settlementDate = new Date(settlementDate.setSeconds(settlementDate.getSeconds() - 1));

                    return {
                        "type": "COD",
                        "settleFromFacility": {
                            "id": Number(currentFacilityId),
                        },
                        "settleToFacility": {
                            "id": Number(data.settleToFacilityId),
                        },
                        "settlementDate": convertToIST(settlementDate)
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        history.push("/hub-settlement/view")
                    }
                }}
            />
        </Fragment>
    )
}