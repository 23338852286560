import React, { Component } from "react";

import { DatePickerBoxWithState, InputBoxWithState, RadioButton, SelectBoxWithState, } from "../../../../components/boda-boda/Forms";
import { getMerchantBranchAllAPI, getAllManifestListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { merchantTypes } from "../../../../config/boda-boda/db_config/db_status.config";


const FilterUI = (props) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Manifest Code"
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        maxLength={17}
                        placeHolderText={"Manifest Code"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Manifest Type"}
                        placeHolderText={"Manifest Type"}
                        inputName={"type"}
                        formGroupName={props.searchFromKey}
                        valueName={"code"}
                        keyName={"id"}
                        dataList={[
                            {
                                "id": "",
                                "code": "All"
                            },
                            {
                                "id": "DELIVERY",
                                "code": "Delivery"
                            }, {
                                "id": "COLLECTION",
                                "code": "Collection"
                            },
                            {
                                "id": "RETURN",
                                "code": "Return"
                            },
                        ]}

                    />
                </div>

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"From Date"}
                        inputName={"dateFrom"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"From Date"}
                    />
                </div>

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"To Date"}
                        inputName={"dateTo"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"To Date"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Status"}
                        placeHolderText={"Status"}
                        inputName={"status"}
                        formGroupName={props.searchFromKey}
                        valueName={"value"}
                        keyName={"id"}
                        dataList={[
                            {
                                id: "IN_TRANSIT_LINE",
                                value: "Intransit to regional hub"
                            },
                            {
                                id: "IN_TRANSIT_LAST",
                                value: "Intransit Last"
                            },
                            {
                                id: "COMPLETED",
                                value: "Delivered to regional hub",
                            },
                            {
                                id: "IN_TRANSIT_RETURN",
                                value: "Return Intransit",
                            },
                        ]}

                    />
                </div>

                {/* <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Pick Up Location"}
                        placeHolderText={"Pick Up Location"}
                        inputName={"locationPickId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={getMerchantBranchAllAPI.url}
                        apiStateKey={getMerchantBranchAllAPI.key}
                        keyName={"locationId"}
                        valueName={"name"}
                    />
                </div> */}

                {/* <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Drop Location"}
                        placeHolderText={"Drop Location"}
                        inputName={"locationDropId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={getMerchantBranchAllAPI.url}
                        apiStateKey={getMerchantBranchAllAPI.key}
                        keyName={"locationId"}
                        valueName={"name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Current Location"}
                        placeHolderText={"Current Location"}
                        inputName={"locationCurrentId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={getMerchantBranchAllAPI.url}
                        apiStateKey={getMerchantBranchAllAPI.key}
                        keyName={"locationId"}
                        valueName={"name"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Destination Delivery Center"}
                        placeHolderText={"Destination Delivery Center"}
                        inputName={"facilityId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={getMerchantBranchAllAPI.url}
                        apiStateKey={getMerchantBranchAllAPI.key}
                        keyName={"locationId"}
                        valueName={"name"}
                    />
                </div> */}

            </div>
        </div>
    )
}
export { FilterUI };
