import React, { Component, Fragment, useEffect } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import JsBarcode from "jsbarcode";

import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { isEmptyValue, printModalHelperFn } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class ShippingLabel extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <Fragment>
                <InputButton
                    btnText="Shipping Label"
                    onClickBtnFn={() => onClickFn()}
                    elementWrapperStyle={"mr-2"}
                    startIcon={"fa fa-barcode"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Shipping Label"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<LabelPrint row={this.props.package} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLabelBtn type={"create"} />
                    }
                />
            </Fragment>
        );

    }
}

const LabelPrint = (props) => {
    let { history, location, match } = props;

    const formState = useSelector(state => state.FormData);

    return (
        <div id={"print_preview"}>
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_view`}
                    apiUrl={`${getManifestListAPI.url}/${get(props, `row.id`, "")}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: get(response, "id", ""),
                            status: get(response, "status", ""),
                            packages: get(response, "packages", ""),
                            locationDrop: get(response, "locationDrop", ""),
                            locationPick: get(response, "locationPick", ""),
                            notes: get(response, "notes", ""),
                            merchant: get(response, "merchant.name", ""),
                            pickAt: get(response, "pickAt", ""),
                            orderCount: get(response, "merchant.orderCount", ""),
                            code: get(response, "code", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    {/* <h4 className="card-title mb-3">Package Details</h4> */}

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Manifest Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                                <dd className={"col-sm-4"}>Pick Up Location</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Drop Location</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Pick Up Date</dd>
                                                <dt className={"col-sm-8"}>
                                                    {isEmptyValue(get(formState, `${getManifestListAPI.key}_view.pickAt`, null)) ? "-" : dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""), "YY-mm-dd")}
                                                </dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dd className={"col-sm-5"}>Status</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH" ? "PENDING" : get(formState, `${getManifestListAPI.key}_view.status`, "")
                                                    }
                                                </dt>
                                                <dd className={"col-sm-5"}>Received Packages Count</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.orderCount`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Packages"
                            apiURL={`${getPackageListAPI.url}/all?manifestsId=${get(props, `row.id`, "")}`}
                            dataTableStateKey={getPackageListAPI.key}
                            isFilter={false}
                            isSetAction={true}
                            isSetCreateAction={false}
                            isPagingShow={false}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Customer Name", key: "order.name" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                                { displayName: "Status", key: "status" },
                            ]}
                        />
                    </div>
                </FormWrapper>
            </div>
        </div>
    );
}

const PrintLabelBtn = (props) => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => {
                printModalHelperFn("print_preview")
            }}
        // startIcon={"fa fa-barcode"}
        />
    )
}

export {
    ShippingLabel,
    LabelPrint,
    PrintLabelBtn
};



