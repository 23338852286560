import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import parsePhoneNumber from "libphonenumber-js";

import validateMethod from "../../../helpers/boda-boda/validation";
import { setFormErrorFn } from "../form/actions";
import { getMerchantAPI, getMerchantBankAPI, getMerchantContactAPI } from "../../../config/boda-boda/apiUrls.config";
import { setFlashMessage, setPageLoader } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { defaultCountryCode } from "../../../config/boda-boda/core.config";
import { contactTypes } from '../../../config/boda-boda/db_config/db_status.config';

const emptyFn = (...para) => undefined;

function* createMerchantFn(data) {
    yield put(setPageLoader((true)));
    let operationContactCountryCode = get(data, 'payload.operationContact.countryCode.id', "");

    if (operationContactCountryCode === null) {
        operationContactCountryCode = "";
    }

    operationContactCountryCode = operationContactCountryCode.toString().split("/");

    let financeContactCountryCode = get(data, 'payload.financeContact.countryCode.id', "");

    if (financeContactCountryCode === null) {
        financeContactCountryCode = "";
    }

    financeContactCountryCode = financeContactCountryCode.toString().split("/");

    yield put(setPageLoader((true)));
    if (get(data, 'payload.isAlreadyValidated', false) === true) {
        yield* callCreateMerchantAPI(data, operationContactCountryCode, financeContactCountryCode);
        yield put(setPageLoader((false)));
        return;
    }

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            code: "Code",
            registrationNo: "Business Reg. No.",
            website: "Website",
            "operationContact.fullName": "Full Name",
            "operationContact.phone": "Phone",
            "operationContact.email": "Email",
            "operationContact.notification": "Notifications",
            "operationContact.countryCode.id": "Country Code",
            "financeContact.fullName": "Full Name",
            "financeContact.phone": "Phone",
            "financeContact.email": "Email",
            "financeContact.notification": "Notifications",
            "financeContact.countryCode.id": "Country Code",
            trackByChannelOrderNo: "Use Channel Order No for Tracking Number",
            trackByChannelOrderNoPrefix: "Tracking Number Prefix",
            isCustomerNotificationEnable: "Send Customer Notifications",
            bank: "Bank",
            accountName: "Account Name",
            branch: "Branch",
            accountNumber: "Account Number",
        },
        {
            name: "required",
            code: "required|min:6|max:6",
            registrationNo: "required|max:20",
            "operationContact.fullName": "required",
            "operationContact.phone": `required|phoneNumber:${get(operationContactCountryCode, "[1]", defaultCountryCode)},operationContact.countryCode.id`,
            "operationContact.email": "required|email",
            "operationContact.notification": "required",
            "operationContact.countryCode.id": "required",
            "financeContact.fullName": "required",
            "financeContact.phone": `required|phoneNumber:${get(financeContactCountryCode, "[1]", defaultCountryCode)},financeContact.countryCode.id`,
            "financeContact.email": "required|email",
            "financeContact.notification": "required",
            "financeContact.countryCode.id": "required",
            trackByChannelOrderNo: "required",
            trackByChannelOrderNoPrefix: "requiredIf:trackByChannelOrderNo,true|min:1|max:4|alphaNumeric",
            isCustomerNotificationEnable: "required",
            bank: "required",
            accountName: "required",
            branch: "required",
            accountNumber: "required|alphaNumeric",
        },
        {
            "trackByChannelOrderNoPrefix.requiredIf": "The Tracking Number Prefix field is required when Use Channel Order No for Tracking Number is ENABLED",
        },
    ));

    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${getMerchantAPI.key}_create`, validationResponse.errors));

    } else {
        if (get(data, 'payload.trackByChannelOrderNo', false) === true) {
            const allMerchantAPIRequest = yield call(() => callApi(`${getMerchantAPI.url}/all?trackByChannelOrderNoPrefix=${get(data, 'payload.trackByChannelOrderNoPrefix', '')}`).headers(true).method("get").send());
            if (allMerchantAPIRequest._statue === true) {
                if (get(allMerchantAPIRequest, 'data.data', []).length > 0) {
                    const isSameMerchantCount = get(allMerchantAPIRequest, 'data.data', []).filter(value => {
                        return value?.id == get(data, 'payload.merchant.id', "")
                    }).length
                    if (isSameMerchantCount !== 1) {
                        const names = get(allMerchantAPIRequest, 'data.data', [])
                            .map(item => item.name)
                            .join(", ");
                        data.payload.setDuplicateTrackingNoMerchants(names)
                        data.payload.setOpen(true)
                        yield put(setPageLoader((false)));
                    } else {
                        yield* callCreateMerchantAPI(data, operationContactCountryCode, financeContactCountryCode);
                    }
                } else {
                    yield* callCreateMerchantAPI(data, operationContactCountryCode, financeContactCountryCode);
                }
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong",
                    type: "danger",
                }));
                yield put(setPageLoader((false)));
            }
        } else {
            yield* callCreateMerchantAPI(data, operationContactCountryCode, financeContactCountryCode);
        }
    }

    yield put(setPageLoader(false));
}

function* callCreateMerchantAPI(data, operationContactCountryCode, financeContactCountryCode) {
    // yield put(setPageLoader((true)));
    yield put(setFormErrorFn(`${getMerchantAPI.key}_create`, []));

    const merchantAPIRequestBody = {
        name: get(data, 'payload.name', ""),
        code: get(data, 'payload.code', ""),
        registrationNo: get(data, 'payload.registrationNo', ""),
        status: 'INCOMPLETE',
        type: "LVL1",
        category: {
            id: 1
        },
        rating: 0,
        website: undefined,
        trackByChannelOrderNo: get(data, 'payload.trackByChannelOrderNo', false),
        trackByChannelOrderNoPrefix: undefined,
        isCustomerNotificationEnable: get(data, 'payload.isCustomerNotificationEnable', false),
    };

    if (!isEmptyValue(get(data, 'payload.website', ""))) {
        merchantAPIRequestBody["website"] = get(data, 'payload.website', "");
    }

    if (get(data, 'payload.trackByChannelOrderNo', false)) {
        merchantAPIRequestBody.trackByChannelOrderNoPrefix = get(data, 'payload.trackByChannelOrderNoPrefix', "");
    } else if (!get(data, 'payload.trackByChannelOrderNo', false)) {
        merchantAPIRequestBody.trackByChannelOrderNoPrefix = null;
    }

    let merchantAPIRequest = null;

    if (get(data, 'payload.merchant', "") === "not_found") {
        merchantAPIRequest = yield call(() => callApi(getMerchantAPI.url).headers(true).method("post").body(merchantAPIRequestBody).send());
    } else {
        const merchantId = get(data, 'payload.merchant.id', "");
        merchantAPIRequest = yield call(() => callApi(`${getMerchantAPI.url}/${merchantId}`).headers(true).method("put").body(merchantAPIRequestBody).send());
    }


    if (merchantAPIRequest._statue === true) {
        // operation contacts
        const merchantOperationContactAPIRequestBody = {
            name: get(data, 'payload.operationContact.fullName', ""),
            fullName: get(data, 'payload.operationContact.fullName', ""),
            phone: get(data, 'payload.operationContact.phone', ""),
            email: get(data, 'payload.operationContact.email', ""),
            merchant: {
                id: get(merchantAPIRequest, "data.data.id", "")
            },
            notification: null,
            countryCode: {
                id: parseInt(get(operationContactCountryCode, "[0]"))
            },
            countryCodePrefix: get(operationContactCountryCode, "[2]"),
            departmentType: contactTypes[0].id,
        };

        if (get(operationContactCountryCode, "[1]") !== "OTHER") {
            const phoneNumber = parsePhoneNumber(get(data, "payload.operationContact.phone", ""), get(operationContactCountryCode, "[1]"));

            if (phoneNumber) {
                merchantOperationContactAPIRequestBody["phone"] = phoneNumber.number;
            }
        }

        if (get(data, 'payload.operationContact.notification', "") === "ENABLED") {
            merchantOperationContactAPIRequestBody.notification = true;
        } else if (get(data, 'payload.operationContact.notification', "") === "DISABLED") {
            merchantOperationContactAPIRequestBody.notification = false;
        }

        let merchantOperationContactAPIRequest = null;

        if (get(data, 'payload.merchantOperationContact', "") === "not_found") {
            merchantOperationContactAPIRequest = yield call(() => callApi(getMerchantContactAPI.url).headers(true).method("post").body(merchantOperationContactAPIRequestBody).send());
        } else {
            const merchantContactId = get(data, 'payload.merchantOperationContact.id', "");
            merchantOperationContactAPIRequest = yield call(() => callApi(`${getMerchantContactAPI.url}/${merchantContactId}`).headers(true).method("put").body(merchantOperationContactAPIRequestBody).send());
        }

        if (merchantOperationContactAPIRequest._statue === false) {
            if (get(merchantOperationContactAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(
                    `${getMerchantAPI.key}_create`,
                    get(merchantOperationContactAPIRequest, "data.errors", [])
                ))
                yield put(setFlashMessage({
                    status: true,
                    message: get(merchantOperationContactAPIRequest, "data.errors[0].error", []),
                    type: "danger",
                }));
            } else if (get(merchantOperationContactAPIRequest, "data.statusCode", null) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger",
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong!!",
                    type: "danger",
                }));
            }
        } else {
            // finance contacts
            const merchantFinanceContactAPIRequestBody = {
                name: get(data, 'payload.financeContact.fullName', ""),
                fullName: get(data, 'payload.financeContact.fullName', ""),
                phone: get(data, 'payload.financeContact.phone', ""),
                email: get(data, 'payload.financeContact.email', ""),
                merchant: {
                    id: get(merchantAPIRequest, "data.data.id", "")
                },
                notification: null,
                countryCode: {
                    id: parseInt(get(financeContactCountryCode, "[0]"))
                },
                countryCodePrefix: get(financeContactCountryCode, "[2]"),
                departmentType: contactTypes[1].id,
            };

            if (get(financeContactCountryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.financeContact.phone", ""), get(financeContactCountryCode, "[1]"));

                if (phoneNumber) {
                    merchantFinanceContactAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            if (get(data, 'payload.financeContact.notification', "") === "ENABLED") {
                merchantFinanceContactAPIRequestBody.notification = true;
            } else if (get(data, 'payload.financeContact.notification', "") === "DISABLED") {
                merchantFinanceContactAPIRequestBody.notification = false;
            }

            let merchantFinanceContactAPIRequest = null;

            if (get(data, 'payload.merchantFinanceContact', "") === "not_found") {
                merchantFinanceContactAPIRequest = yield call(() => callApi(getMerchantContactAPI.url).headers(true).method("post").body(merchantFinanceContactAPIRequestBody).send());
            } else {
                const merchantContactId = get(data, 'payload.merchantFinanceContact.id', "");
                merchantFinanceContactAPIRequest = yield call(() => callApi(`${getMerchantContactAPI.url}/${merchantContactId}`).headers(true).method("put").body(merchantFinanceContactAPIRequestBody).send());
            }

            if (merchantFinanceContactAPIRequest._statue === false) {
                if (get(merchantFinanceContactAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(
                        `${getMerchantAPI.key}_create`,
                        get(merchantFinanceContactAPIRequest, "data.errors", [])
                    ))
                    yield put(setFlashMessage({
                        status: true,
                        message: get(merchantFinanceContactAPIRequest, "data.errors[0].error", []),
                        type: "danger",
                    }));
                } else if (get(merchantFinanceContactAPIRequest, "data.statusCode", null) === 403) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger",
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Something went wrong!!",
                        type: "danger",
                    }));
                }
            } else {
                // merchant bank post/ put API.
                const merchantBankAPIRequestBody = {
                    bank: get(data, 'payload.bank', ""),
                    accountName: get(data, 'payload.accountName', ""),
                    branch: get(data, 'payload.branch', ""),
                    accountNumber: get(data, 'payload.accountNumber', ""),
                    merchant: {
                        id: get(merchantAPIRequest, "data.data.id", "")
                    },
                };
                let merchantBankAPIRequest = null;

                if (get(data, 'payload.merchantBank', "") === "not_found") {
                    merchantBankAPIRequest = yield call(() => callApi(getMerchantBankAPI.url).headers(true).method("post").body(merchantBankAPIRequestBody).send());
                } else {
                    const merchantBankId = get(data, 'payload.merchantBank.id', "");
                    merchantBankAPIRequest = yield call(() => callApi(`${getMerchantBankAPI.url}/${merchantBankId}`).headers(true).method("put").body(merchantBankAPIRequestBody).send());
                }

                if (merchantBankAPIRequest._statue === false) {
                    if (get(merchantBankAPIRequest, "data.code", null) === 400) {
                        yield put(setFormErrorFn(
                            `${getMerchantAPI.key}_create`,
                            get(merchantBankAPIRequest, "data.errors", [])
                        ))
                        yield put(setFlashMessage({
                            status: true,
                            message: get(merchantBankAPIRequest, "data.errors[0].error", []),
                            type: "danger",
                        }));
                    } else if (get(merchantBankAPIRequest, "data.statusCode", null) === 403) {
                        yield put(setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        }));
                    } else {
                        yield put(setFlashMessage({
                            status: true,
                            message: "Something went wrong!!",
                            type: "danger",
                        }));
                    }
                } else {
                    get(data, "payload.onNextFn", emptyFn)(get(merchantAPIRequest, "data.data.id", ""))
                }
            }
        }
    } else {
        if (get(merchantAPIRequest, "data.code", null) === 400) {
            yield put(setFormErrorFn(
                `${getMerchantAPI.key}_create`,
                get(merchantAPIRequest, "data.errors", [])
            ))
            yield put(setFlashMessage({
                status: true,
                message: get(merchantAPIRequest, "data.errors[0].error", []),
                type: "danger",
            }));
        } else if (get(merchantAPIRequest, "data.statusCode", null) === 403) {
            yield put(setFlashMessage({
                status: true,
                message: "Permission denied to access",
                type: "danger",
            }));
        } else {
            yield put(setFlashMessage({
                status: true,
                message: "Something went wrong!!",
                type: "danger",
            }));
        }
    }
}

function* editMerchantFn(data) {
    yield put(setPageLoader((true)));
    if (get(data, 'payload.isAlreadyValidated', false) === true) {
        yield* callEditMerchantAPI(data);
        return;
    }

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            code: "Code",
            registrationNo: "Type",
            status: "Status",
            trackByChannelOrderNo: "Use Channel Order No for Tracking Number",
            trackByChannelOrderNoPrefix: "Tracking Number Prefix",
            isCustomerNotificationEnable: "Send Customer Notifications",
            bank: "Bank",
            accountName: "Account Name",
            branch: "Branch",
            accountNumber: "Account Number",
        },
        {
            name: "required",
            code: "required",
            registrationNo: "required",
            status: "required",
            trackByChannelOrderNo: "required",
            trackByChannelOrderNoPrefix: "requiredIf:trackByChannelOrderNo,true|min:1|max:4|alphaNumeric",
            isCustomerNotificationEnable: "required",
            bank: "required",
            accountName: "required",
            branch: "required",
            accountNumber: "required",
        },
        {
            "trackByChannelOrderNoPrefix.requiredIf": "The Tracking Number Prefix field is required when Use Channel Order No for Tracking Number is ENABLED",
        }
    ));

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${getMerchantAPI.key}_Edit`, validationResponse.errors));
        yield put(setPageLoader((false)));
    } else {
        if (get(data, 'payload.trackByChannelOrderNo', false) === true) {
            const allMerchantAPIRequest = yield call(() => callApi(`${getMerchantAPI.url}/all?trackByChannelOrderNoPrefix=${get(data, 'payload.trackByChannelOrderNoPrefix', '')}`).headers(true).method("get").send());
            if (allMerchantAPIRequest._statue === true) {
                if (get(allMerchantAPIRequest, 'data.data', []).length > 0) {
                    const isSameMerchantCount = get(allMerchantAPIRequest, 'data.data', []).filter(value => {
                        return value?.id == get(data, 'payload.merchantId', "")
                    }).length
                    if (isSameMerchantCount !== 1) {
                        const names = get(allMerchantAPIRequest, 'data.data', [])
                            .map(item => item.name)
                            .join(", ");
                        data.payload.setDuplicateTrackingNoMerchants(names)
                        data.payload.setOpen(true)
                        yield put(setPageLoader((false)));
                    } else {
                        yield* callEditMerchantAPI(data);
                    }
                } else {
                    yield* callEditMerchantAPI(data);
                }
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong",
                    type: "danger",
                }));
                yield put(setPageLoader((false)));
            }
        } else {
            yield* callEditMerchantAPI(data);
        }
    }
}

function* callEditMerchantAPI(data) {
    // yield put(setPageLoader((true)));
    yield put(setFormErrorFn(`${getMerchantAPI.key}_Edit`, []));

    const merchantAPIRequestBody = {
        name: get(data, 'payload.name', ""),
        code: get(data, 'payload.code', ""),
        registrationNo: get(data, 'payload.registrationNo', ""),
        status: get(data, 'payload.status', ""),
        website: undefined,
        trackByChannelOrderNo: get(data, 'payload.trackByChannelOrderNo', false),
        trackByChannelOrderNoPrefix: undefined,
        isCustomerNotificationEnable: get(data, 'payload.isCustomerNotificationEnable', false),
    };

    if (!isEmptyValue(get(data, 'payload.website', ""))) {
        merchantAPIRequestBody["website"] = get(data, 'payload.website', "");
    }

    if (get(data, 'payload.trackByChannelOrderNo', false)) {
        merchantAPIRequestBody.trackByChannelOrderNoPrefix = get(data, 'payload.trackByChannelOrderNoPrefix', "");
    } else if (!get(data, 'payload.trackByChannelOrderNo', false)) {
        merchantAPIRequestBody.trackByChannelOrderNoPrefix = null;
    }

    let merchantAPIRequest = null;
    const merchantId = get(data, 'payload.merchantId', "");
    merchantAPIRequest = yield call(() => callApi(`${getMerchantAPI.url}/${merchantId}`).headers(true).method("put").body(merchantAPIRequestBody).send());

    if (merchantAPIRequest._statue === false) {
        if (get(merchantAPIRequest, "data.code", null) === 400) {
            yield put(setFormErrorFn(
                `${getMerchantAPI.key}_Edit`,
                get(merchantAPIRequest, "data.errors", [])
            ))
            yield put(setFlashMessage({
                status: true,
                message: get(merchantAPIRequest, "data.errors[0].error", []),
                type: "danger",
            }));
        } else if (get(merchantAPIRequest, "data.statusCode", null) === 403) {
            yield put(setFlashMessage({
                status: true,
                message: "Permission denied to access",
                type: "danger",
            }));
        } else {
            yield put(setFlashMessage({
                status: true,
                message: "Something went wrong!!",
                type: "danger",
            }));
        }
    } else {
        // merchant bank put API.
        const merchantBankAPIRequestBody = {
            bank: get(data, 'payload.bank', ""),
            accountName: get(data, 'payload.accountName', ""),
            branch: get(data, 'payload.branch', ""),
            accountNumber: get(data, 'payload.accountNumber', ""),
            merchant: {
                id: get(merchantAPIRequest, "data.data.id", "")
            },
        };
        let merchantBankAPIRequest = null;
        if (get(data, 'payload.merchantBank', "") === "not_found") {
            merchantBankAPIRequest = yield call(() => callApi(getMerchantBankAPI.url).headers(true).method("post").body(merchantBankAPIRequestBody).send());
        } else {
            const merchantBankId = get(data, 'payload.merchantBank.id', "");
            merchantBankAPIRequest = yield call(() => callApi(`${getMerchantBankAPI.url}/${merchantBankId}`).headers(true).method("put").body(merchantBankAPIRequestBody).send());
        }

        if (merchantBankAPIRequest._statue === false) {
            if (get(merchantAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(
                    `${getMerchantAPI.key}_Edit`,
                    get(merchantAPIRequest, "data.errors", [])
                ))
                yield put(setFlashMessage({
                    status: true,
                    message: get(merchantAPIRequest, "data.errors[0].error", []),
                    type: "danger",
                }));
            } else if (get(merchantAPIRequest, "data.statusCode", null) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger",
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong!!",
                    type: "danger",
                }));
            }
        } else {
            yield put(setPageLoader((false)));
            data.payload.historyFn.push("/merchant")
        }

    }
}

export {
    createMerchantFn,
    editMerchantFn
}