import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";

import ProfileMenu from "../../components/ProfileMenu";
import logo from "../../assets/images/boda-logo1.png";
import { callApi } from "../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getFacilityAPI } from "../../config/boda-boda/apiUrls.config";
import { initFormFn, initFromObjectFn, changeInputFn } from "../../store/boda-boda/form/actions";
import { switchFacilityFn } from "../../store/boda-boda/transaction_request/actions";


class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchOpen: false
    };

    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  componentDidMount() {
    this.getFacilities();
  }

  getFacilities = () => {
    let { formState, initFormFn, changeInputFn, authState } = this.props;

    if (get(formState, "switch_facility", null) === null) {
      const formObj = initFromObjectFn("switch_facility")
          .setGroupName("switch_facility")
          .setFormObject({
            "facility": get(authState, "authUser.facility.id", null)
          })
          .generate();

      initFormFn({
        "switch_facility": formObj
      });

      callApi(`${getFacilityAPI.url}/switchable`)
          .method("get")
          .headers(true)
          .send()
          .then((result) => {
            changeInputFn("switch_facility", "data", "", get(result, "data.data", []));
          })
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    let { authState, formState, changeInputFn, switchFacilityFn, history } = this.props;

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                {/* <Link to="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosmImg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logodarkImg} alt="" height="17" />
                  </span>
                </Link> */}

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <div className={"custom-logo-wrapper-inside-sm"}>
                      <img src={logo} alt="logo" />
                    </div>
                  </span>

                  <span className="logo-lg">
                    <div className={"custom-logo-wrapper-inside-lg"}>
                      <img src={logo} alt="logo" />
                    </div>
                  </span>
                </Link>
              </div>
              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="mdi mdi-menu"></i>
              </button>

              {/* <div className="d-none d-sm-block">
                <Dropdown
                  isOpen={this.state.createMenu}
                  toggle={() =>
                    this.setState({ createMenu: !this.state.createMenu })
                  }
                  className="pt-3 d-inline-block"
                >
                  <DropdownToggle className="btn btn-secondary" tag="button">
                    Create <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" href="#">
                      Action
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Another action
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Something else here
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Separated link
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div> */}
            </div>

            <div className="d-flex">
              {
                (get(authState, "authUser.canSwitch", false) === true) ? (
                    <form className="app-search d-none d-lg-block">
                      <div className="position-relative customerStyle">
                        <select
                            className="form-control"
                            placeholder="Search..."
                            style={{ width: "250px" }}
                            value={get(formState, "switch_facility.facility", null)}
                            onChange={(event) => {
                              changeInputFn("switch_facility", "facility", "", event.target.value);
                              switchFacilityFn({
                                "facility": parseInt(event.target.value),
                                "userId": get(authState, "authUser.staff.userId"),
                                "history": history
                              })
                            }}
                        >
                          {
                            get(formState, "switch_facility.data", []).map((value, index) => {
                              return(
                                  <option value={value.id} key={index}>{value.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </form>
                ) : null
              }
              {/* <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={this.toggleSearch}
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearchOpen
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}

              {/* <LanguageDropdown /> */}

              <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen"></i>
                </button>
              </div>

              {/* <NotificationDropdown /> */}

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="mdi mdi-settings-outline"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    authState: state.AuthState,
    formState: state.FormData,
  };
};

export default withRouter(connect(mapStateToProps, {
  initFormFn,
  changeInputFn,
  switchFacilityFn
})(TopBar));
