import React, { Component } from "react";
import { filter, get, uniqBy } from 'lodash';

import { DatePickerBoxWithState, InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import {
    getAllFacilityAPI,
    getAllManifestListAPI,
    getMerchantAPI,
    getMerchantBranchAllAPI,
    getPackageCategoryListAPI,
    merchantBranchAPI
} from "../../../../config/boda-boda/apiUrls.config";
import {
    DeliveryStatusList,
    deliveryTypes,
    paymentMethods
} from "../../../../config/boda-boda/db_config/db_status.config";
import { changeInputFn, setApiResponse } from "../../../../store/actions";
import { connect } from "react-redux";


class FilterUI extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Article Id"
                            inputName={"articleNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Article No"}
                            maxLength={17}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Tracking Number"
                            inputName={"trackingNumber"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Tracking Number"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Channel Order No."
                            inputName={"channelOrderNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Channel Order No."}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Customer Name"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Merchant"
                            inputName={"merchantId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Merchant"}
                            apiUrl={`${getMerchantAPI.url}/all`}
                            apiStateKey={getMerchantAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        // onChangeFn={(eventData) => {
                        //     const cities = filter(
                        //         get(formState, `${this.props.searchFromKey}.something`, []),
                        //         (e) => e.districtId === eventData.value
                        //     );

                        //     this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.city.id`, "");
                        //     this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.postalCode`, "");
                        //     this.props.setApiResponse(getCityListAPI.key, cities);                            
                        // }}
                        />
                    </div>
                    {console.log("Formstate", get(this.props.formState, `${this.props.searchFromKey}.merchantId`))}
                    <div className="col-md-6">
                    {get(this.props.formState, `${this.props.searchFromKey}.merchantId`) ?
                        <SelectBoxWithState
                            labelText={"Merchant Branch"}
                            placeHolderText={"Merchant Branch"}
                            inputName={"merchantBranchId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getMerchantBranchAllAPI.url}?merchantId=${get(this.props.formState, `${this.props.searchFromKey}.merchantId`)}`}
                            apiStateKey={`${getMerchantBranchAllAPI.key}`}
                            keyName={"id"}
                            valueName={"name"}
                        />
                        :
                        <SelectBoxWithState
                            labelText={"Merchant Branch"}
                            placeHolderText={"Merchant Branch"}
                            inputName={"merchantBranchId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getMerchantBranchAllAPI.url}`}
                            apiStateKey={`${getMerchantBranchAllAPI.key}`}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    }
                    </div>
                    {/* <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Merchant Branch"}
                            placeHolderText={"Merchant Branch"}
                            inputName={"merchantBranchId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${merchantBranchAPI.url}/${get(this.props.formState, `${this.props.searchFromKey}.merchantId`)}`}
                            isDisabled={get(this.props.formState, `${this.props.searchFromKey}.merchantId`) ? false : true}
                            apiStateKey={`${getMerchantBranchAllAPI.key}`}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div> */}

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"Delivered From Date"}
                            inputName={"deliveredDateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"Delivered From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"Delivered To Date"}
                            inputName={"deliveredDateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"Delivered To Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Delivery Type"}
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryTypes}
                            placeHolderText={"Delivery Type"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Package Category"}
                            inputName={"categoryId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getPackageCategoryListAPI.url}`}
                            apiStateKey={getPackageCategoryListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            placeHolderText={"Package Category"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"From Date"}
                            inputName={"dateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"To Date"}
                            inputName={"dateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"To Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Payment Method"}
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Delivery Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={DeliveryStatusList}
                            placeHolderText={"Delivery Status"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Current Location"}
                            placeHolderText={"Current Location"}
                            inputName={"locationCurrentId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getAllFacilityAPI.url}`}
                            apiStateKey={`${getAllFacilityAPI.key}`}
                            keyName={"id"}
                            valueName={"name"}
                            onRebuildResponseFn={(data) => {
                                const temp = [];

                                data.forEach((value) => {
                                    temp.push({
                                        id: get(value, "location.id", ""),
                                        name: get(value, "location.name", ""),
                                    })

                                    return temp
                                });
                                let newTemp = uniqBy(temp, 'id', " ");

                                return {
                                    "data": {
                                        "data": newTemp
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    setApiResponse,
})(FilterUI);
