import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { InputButton } from "../../../../../components/boda-boda/Forms";
import { orderCSVUploadAPI, facilityRateAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import UploadModalBody from "./UploadModalBody";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setPageLoader, setFlashMessage } from "../../../../../store/boda-boda/ui/actions";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";
import { resetDataTable } from "../../../../../store/boda-boda/core/actions";
import { removeFromGroupFn } from "../../../../../store/boda-boda/form/actions";

const validateFn = (formState, dispatch) => {
    // let { formState, setFormErrorFn } = this.props;
    const errors = [];

    if (get(formState, "volumetric_weight_rates.districtIdUpload", "") === "") {
        errors.push({
            "property": "districtIdUpload",
            "error": "The District field is required"
        })
        dispatch(setFormErrorFn("volumetric_weight_rates", errors));
        return false;
    } else {
        dispatch(setFormErrorFn("volumetric_weight_rates", []));
        return true;
    }
}


const UploadCSVBtn = (props) => {
    const formState = useSelector(state => state.FormData);
    const dispatch = useDispatch();

    const onSubmit = () => {
        if (validateFn(formState, dispatch) === true){
        if (get(formState, "upload_csv_data.data.file", null) === null) {
            dispatch(setFlashMessage({
                status: true,
                message: "Please select a csv file",
                type: "danger"
            }));
        } else {
            dispatch(setPageLoader(true));

            callApi(orderCSVUploadAPI.url)
                .headers(true)
                .method("post")
                .body({
                    name: get(formState, "upload_csv_data.data.fileName", ""),
                    url: get(formState, "upload_csv_data.data.url", ""),
                    category: "BASE_RATE",
                    type: "CSV",
                    facility: { id: parseInt(props.facilityId) }
                })
                .send()
                .then((result) => {
                    // dispatch(resetDataTable(facilityRateAPI.key));
                    dispatch(removeFromGroupFn("upload_csv_data"));

                    props.setModelStatusFn(false);
                    dispatch(setPageLoader(false));
                    if (get(result, "_statue", "") === false) {
                        if (get(result, "data.statusCode", 0) === 403) {
                            dispatch(setFlashMessage({
                                status: true,
                                message: "Permission denied to access",
                                type: "danger",
                            }));
                        } else {
                            dispatch(setFlashMessage({
                                status: true,
                                message: get(result, "data.message", "Something went wrong!"),
                                type: "danger",
                            }));
                        }
                    } else {
                        dispatch(setFlashMessage({
                            status: true,
                            message: "CSV file has uploaded successfully",
                            type: "success",
                        }));
                    }
                })
                .catch((error) => {
                    dispatch(setPageLoader(false));
                })
        }
    }
    }

    return (
        <InputButton
            btnText={"Upload"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            onClickBtnFn={() => onSubmit()}
        />
    )
};

const UploadModalUI = (props) => {
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    return (
        <Fragment>
            <InputButton
                elementWrapperStyle={"mr-2"}
                btnText={"Upload"}
                startIcon={"mdi mdi-content-save-all-outline"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Upload CSV"}
                modalBodyComponent={<UploadModalBody />}
                modalFooterComponent={<UploadCSVBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    facilityId={props.facilityId}
                />}
                showHideToggleFn={() => {
                    setOpen(false);
                    dispatch(removeFromGroupFn("upload_csv_data"));
                }}
            />
        </Fragment>
    )
}

export default UploadModalUI;
