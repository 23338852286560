import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import FormData from "./boda-boda/form/reducer";
import CoreState from "./boda-boda/core/reducer";
import AuthState from "./boda-boda/auth/reducer";
import UIState from "./boda-boda/ui/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  FormData,
  CoreState,
  AuthState,
  UIState
});

export default rootReducer;
