import React, { Fragment, memo } from "react";
import Select from "react-select";
import { FormGroup, Input, Label } from "reactstrap";

import { formCacheLevel } from "../../../config/boda-boda/template.config";
import { isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";


const emptyFn = (...para) => undefined;

const convertDataList = (dataList, keyName, valueName) => {
    const temp = [];

    (dataList || []).forEach((value) => {
        temp.push({
            label: value[valueName],
            value: value[keyName]
        })
    })

    return temp;
}

const getInputValue = (dataList, inputValue) => {
    const value = (dataList||[]).find((element) => element.value == inputValue);

    if (isEmptyValue(value)) {
        return null;
    } else {
        return value;
    }
}

const SelectBox = ({
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    isRequired = false,
    placeHolderText = "",
    inputName = "",
    inputValue = "",
    inputError = "",
    dataList = [],
    isDisabled = false,
    helperText = "",
    keyName = "id",
    valueName = "value",
    onChangeFn = emptyFn,
    onClickFn = emptyFn
}) => {
    dataList= convertDataList(dataList, keyName, valueName);
   
    return (
        <Fragment>
            <FormGroup className={`${elementWrapperStyle}`}>
                <Label
                    className={`${inputError ? "selectBoxLabelStyle" : ""}`}
                >
                    {labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}
                </Label>
                <Select
                    className={`${elementStyle} ${inputError ? "selectBoxErrorStyle" : ""}`}
                    value={getInputValue(dataList, inputValue)}
                    name={inputName}
                    options={dataList}
                    isDisabled ={isDisabled}
                    placeholder={placeHolderText}
                    onChange={(event) => {
                        onChangeFn({
                            value: event.value,
                            name: inputName,
                            eventInfo: event
                        })
                    }}
                    onClick={(event) => {
                        onClickFn({
                            name: inputName,
                            eventInfo: event
                        })
                    }}
                />
                <small className={`${inputError ? "helperTextErrorStyle" : ""}`}>
                    {inputError ? inputError : helperText}
                </small>
            </FormGroup>
        </Fragment>
    )
}

const SimpleSelectBox = ({
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    isRequired = false,
    placeHolderText = "",
    inputName = "",
    inputValue = "",
    inputError = "",
    dataList = [],
    keyName = "id",
    valueName = "value",
    emptySelectOptionTxt = "---Select an option---",
    isDisabled = false,
    helperText = "",
    onChangeFn = emptyFn,
    onClickFn = emptyFn
}) => {
    return (
        <Fragment>
            <FormGroup className={`${elementWrapperStyle} ${inputError ? "text-danger" : ""}`}>
                <Label>{labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}</Label>
                <Input
                    type={"select"}
                    className={`defaultSelectBox ${elementStyle} ${inputError ? "is-touched is-pristine av-invalid is-invalid" : ""}`}
                    name={inputName}
                    value={inputValue}
                    disabled={isDisabled}
                    placeholder={placeHolderText}
                    onChange={(event) => {
                        onChangeFn({
                            value: event.target.value,
                            name: inputName,
                            eventInfo: event
                        })
                    }}
                    onClick={(event) => {
                        onClickFn({
                            name: inputName,
                            eventInfo: event
                        })
                    }}
                >
                    <option key={"null"} value={"null"}>{emptySelectOptionTxt || "---Select an option---"}</option>
                    {
                        (dataList || []).map((value, index) => {
                            return (
                                <option key={index} value={value[keyName]}>{value[valueName]}</option>
                            )
                        })
                    }
                </Input>
                <small
                    className={`${inputError ? "invalid-feedback" : ""}`}
                >
                    {inputError ? inputError : helperText}
                </small>
            </FormGroup>
        </Fragment>
    )
}

const areEqual = (prevProps, nextProps) => {
    if (nextProps.cacheLevel===formCacheLevel.none || nextProps.setCache===false) {
        return false;
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.responseUpdateStatus===nextProps.responseUpdateStatus) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
            (prevProps.inputValue===nextProps.inputValue) && (prevProps.updateStatus===nextProps.updateStatus) &&
            (prevProps.dataList.length===nextProps.dataList.length)
        )
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.inputValue===nextProps.inputValue) &&
            (prevProps.responseUpdateStatus===nextProps.responseUpdateStatus) && (prevProps.dataList.length===nextProps.dataList.length)
        )
    }
};

const SelectBoxMemo = memo(SelectBox, areEqual);
const SimpleSelectBoxMemo = memo(SimpleSelectBox, areEqual);

export {
    SelectBox,
    SelectBoxMemo,
    SimpleSelectBox,
    SimpleSelectBoxMemo
}
