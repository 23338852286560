import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import {
    getRoleListAPI, getUserStaffList, getFacilityAPI, countryCodeAPI
} from "../../../../config/boda-boda/apiUrls.config";
import { createUserTrKey } from "../../../../store/boda-boda/transaction_request/actionTypes";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import FileUpload from "../../../../components/boda-boda/FileUpload/FileUpload";


class CreateUI extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Create New Staff</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <CreateBtn
                                historyFn={history}
                            />

                            <BackBtnComponent
                                elementWrapperStyle={""}
                                path={"/users/staff"}
                                btnName={"Cancel"}
                                icon={"far fa-times-circle"}
                                btnColor={"danger"}
                                isModal={true}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormWrapper
                            setGroupName={`${getUserStaffList.key}_create`}
                            onDestroyUnsetFormObject={true}
                            setFormObject={{
                                canSwitch: false
                            }}
                        >
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"User Role"}
                                                inputName={"role"}
                                                formGroupName={`${getUserStaffList.key}_create`}
                                                apiUrl={`${getRoleListAPI.url}/all?status=ACTIVE&type=WAREHOUSE`}
                                                apiStateKey={getRoleListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                placeHolderText={"Role"}
                                                isRequired={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="Default Delivery Center"
                                                inputName={"facility"}
                                                placeHolderText={"Default Delivery Center"}
                                                formGroupName={`${getUserStaffList.key}_create`}
                                                apiUrl={`${getFacilityAPI.url}/all`}
                                                apiStateKey={getFacilityAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                isRequired={true}
                                            />
                                        </div>

                                        {
                                            (get(authState, "authUser.isSuper", false) === true) ? (
                                                <div className="col-md-6">
                                                    <SelectBoxWithState
                                                        labelText="Can Switch"
                                                        inputName={"canSwitch"}
                                                        placeHolderText={"Can Switch"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                        dataList={[
                                                            {
                                                                id: true,
                                                                value: "Yes"
                                                            },
                                                            {
                                                                id: false,
                                                                value: "No"
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Personal Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Name"
                                                        placeHolderText={"Name"}
                                                        //inputStatePath={`${getUserStaffList.key}_create.test.name`}
                                                        inputName={"name"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />

                                                    <InputBoxWithState
                                                        labelText="Identification"
                                                        placeHolderText={"Identification"}
                                                        inputName={"identification"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <div className={"d-flex justify-content-center"}>
                                                        <FileUpload
                                                            labelText={"Profile Picture"}
                                                            formGroupName={`${getUserStaffList.key}_create`}
                                                            formKey={"image"}
                                                            helperText={"Supported formats (png, jpg, jpeg)"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Contact Details</h4>

                                            <div className={"row"}>
                                                <div className="col-md-6">

                                                    <div className={"row"}>
                                                        <div className={"col-md-3"}>
                                                            <SelectBoxWithState
                                                                isRequired={true}
                                                                labelText="Country Code"
                                                                inputStatePath={`${getUserStaffList.key}_create.countryCode.id`}
                                                                formGroupName={`${getUserStaffList.key}_create`}
                                                                apiUrl={`${countryCodeAPI.url}/all`}
                                                                apiStateKey={countryCodeAPI.key}
                                                                onRebuildResponseFn={(response) => {
                                                                    const data = [];

                                                                    response.forEach((value) => {
                                                                        data.push({
                                                                            id: `${value.id}/${value.code}/${value.prefix}`,
                                                                            value: value.code
                                                                        });
                                                                    });

                                                                    return {
                                                                        "data": {
                                                                            "data": data
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className={"col-md-9"}>
                                                            <InputBoxWithState
                                                                labelText="Phone"
                                                                inputName={"phone"}
                                                                placeHolderText={"+xxxxxxxxxx"}
                                                                formGroupName={`${getUserStaffList.key}_create`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Email"
                                                        inputName={"email"}
                                                        placeHolderText={"Email"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        maxLength={50}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className={"row"}>
                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Username"
                                                        inputName={"credential"}
                                                        placeHolderText={"Username"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Password"
                                                        inputName={"password"}
                                                        placeHolderText={"Enter Password"}
                                                        inputType={"password"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <InputBoxWithState
                                                        labelText="Confirm Password"
                                                        inputName={"confirm_password"}
                                                        placeHolderText={"Enter Confirm Password"}
                                                        inputType={"password"}
                                                        formGroupName={`${getUserStaffList.key}_create`}
                                                        isRequired={true}
                                                        maxLength={50}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FormWrapper>
                    </Col>
                </Row>
            </div>
        );
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getUserStaffList.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createUserTrKey,
                    payload: {
                        ...dataObject.formObject,
                        historyFn: props.historyFn
                    }
                })
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

const UserCreate = connect(mapStateToProps, null)(CreateUI);

export default UserCreate;
