import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { FormWrapper, InputBoxWithState, SubmitButton } from "../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";


const PickRequestEdit = (props) => {
    let { history, match, location } = props;

    const formState = useSelector(state => state.FormData);

    const getReceivedPackageCount = () => {
        const packages = get(formState, `${getManifestListAPI.key}_view.packages`, []);

        if (get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH") {
            return `0 / ${packages.length}`;
        } else {
            let count = 0;

            packages.forEach((value) => {
                if (value.status === "RECEIVED" || value.status === "SORTED") {
                    count++;
                }
            });

            return `${count} / ${packages.length}`;
        }
    }

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        id: response.id,
                        code: response.code,
                        status: response.status,
                        locationPick: response.locationPick,
                        locationDrop: response.locationDrop,
                        notes: response.notes,
                        pickAt: response.pickAt,
                        packages: response.packages,
                        merchant: response.merchant.name,
                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Edit Pick Up Request</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <SaveBtn
                                id={match.params.id}
                                history={history}
                            />

                            <BackBtnComponent
                                btnName={"Cancel"}
                                btnColor={"danger"}
                                icon={"far fa-times-circle"}
                                path={`/pick-requests/view/${match.params.id}`}
                            />
                        </div>
                    </Col>
                </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Date</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""), "YY-mm-dd")}
                                            </dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-5"}>Merchant</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.merchant`, "")}</dt>

                                            <dd className={"col-sm-5"}>Status</dd>
                                            <dt className={"col-sm-7"}>
                                                {
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH" ? "PENDING"
                                                        : get(formState, `${getManifestListAPI.key}_view.status`, "") === "IN_TRANSIT_FIRST" ? "PICKED UP"
                                                        : get(formState, `${getManifestListAPI.key}_view.status`, "") === "COMPLETED" ? "PICKUP COMPLETED"
                                                        : get(formState, `${getManifestListAPI.key}_view.status`, "")
                                                }
                                            </dt>
                                            <dd className={"col-sm-5"}>Received Packages Count</dd>
                                            <dt className={"col-sm-7"}>{getReceivedPackageCount()}</dt>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Packages"
                        apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}`}
                        dataTableStateKey={getPackageListAPI.key}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Article No", key: "articleNo" },
                            { displayName: "Customer Name", key: "order.name" },
                            { displayName: "Delivery Type", key: "deliveryType" },
                            { displayName: "Payment Method", key: "order.paymentMethod" },
                            { displayName: "Package Category", key: "category.name" },
                            { displayName: "Status", key: "status" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: false,
                                actionBtn: {
                                    onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">

                                <div className={"row"}>
                                    <div className="col-md-6">
                                        <label>Notes</label>
                                        {   
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []) != null &&
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                    <div>None</div>
                                                )
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <InputBoxWithState
                                            labelText={"New Note"}
                                            inputName={"note"}
                                            inputType={"textarea"}
                                            rows={3}
                                            formGroupName={`${getManifestListAPI.key}_view`}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

const SaveBtn = (props) => {
    const authState = useSelector(state => state.AuthState);

    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getManifestListAPI.key}_view`}
            dataTableKey={getManifestListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Pick request has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getManifestListAPI.url}/${props.id}`,
                    key: getManifestListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let res = {};
                res["notes"] = [...data.notes];

                if (!isEmptyValue(get(data, 'note', ""))) {
                    res["notes"] = [
                        ...data.notes,
                        {
                            "name": get(authState, "authUser.role.name", ""),
                            "at": new Date(),
                            "note": data.note,
                            "createdBy": get(authState, "authUser.id", "")
                        }
                    ]
                }

                return res;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.history.push(`/pick-requests/view/${props.id}`);
                }
            }}
        />
    )
}

export default PickRequestEdit;
