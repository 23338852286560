/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-21 16:17:02 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-08-31 09:01:32
 */
import axios from "axios";
import {get} from "lodash";
import { checkUserINLocalStorage } from "./manageStorage.helpers";
import { logoutUser } from "./manageStorage.helpers";

const axiosWrapper=(
  apiParth,
  methodType = "get",
  headerObject,
  requestBody = {},
  onUpload
)=>{
  return new Promise((resolve, reject)=> {
    let apiCallObject = {
      ...headerObject,
      method: methodType,
      url: apiParth,
      data: requestBody
    };
  
    if (onUpload !== false) {
      apiCallObject['onUploadProgress'] = (progressEvent) => onUpload(progressEvent);
    }
  
   
    axios(apiCallObject)
      .then(response => {
        resolve({
          _statue: true,
          data: response.data
        });       
       
      })
      .catch(error => {
        if (get(error, 'response.data.message', "") === "Unauthorized") {
          logoutUser();
          window.location.reload();
        } else {
          resolve({
            _statue: false,
            data: get(error, 'response.data', {})
          });
        }
      });
  });
}



const createHeader = (isSetHeaders = true, multipart = false) => {
  let headers = {
    "accept": "application/json",
    "Content-Type": multipart ? "multipart/form-data" : "application/json"
  };
  if (isSetHeaders === true) {
    let userData = checkUserINLocalStorage();

    if(get(userData,'result.access_token',"")===""){
     // window.location.reload();
    }
    //
   headers["Authorization"] = "Bearer " + get(userData,'result.access_token',"");
   //headers["Authorization"] = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiIiwidXNlcm5hbWUiOiJhZG1pbiIsInN0YXR1cyI6MSwiYXZhdGFyIjoiMUNhcHR1cmUtZjFlNi5QTkciLCJvcmdhbml6YXRpb25zIjpbeyJpZCI6MSwibmFtZSI6IkdNUC1NYWluIn1dLCJyb2xlcyI6W3siaWQiOiJjMGQ0ZDk4My03NWM3LTQ0ZDYtOWVjYi0xMDEzOTY1MzVhYTkiLCJjb2RlIjoiU0EifV0sInBlcm1pc3Npb25zIjpbIkFQUFJPVkVfQ09SUkVDVElPTiIsIkFMVEVSX0VRX1RZUEUiLCJBUFBST1ZFX1NLSVBfSU5TUEVDVElPTiIsIkFQUFJPVkVfQ09SUkVDVElWRU5FU1NfQUNUSU9OIiwiVklFV19WSVNJVE9SIiwiVklFV19FUV9UWVBFIiwiQUxURVJfVVNFUiIsIlZJRVdfSU5TUEVDVElPTl9SRVEiLCJBUFBST1ZFX0VGRkVDVElWRU5FU1MiLCJBTFRFUl9DT01QQU5ZIiwiQ1JFQVRFX0lOU1BFQ1RJT04iLCJWSUVXX0lOU1BFQ1RJT05fUkVQT1JUIiwiVklFV19MT0NfVFlQRSIsIlZJRVdfQ0hLX0xJU1QiLCJWSUVXX0hSIiwiQUxURVJfSFIiLCJWSUVXX1JPTEUiLCJWSUVXX0NPTVBBTlkiLCJDUkVBVEVfRUZGRUNUSVZFTkVTU19SRVZJRVciLCJWSUVXX1NVQl9MT0MiLCJBTFRFUl9FUSIsIlZBTElEX0pXVCIsIkFMVEVSX1NVQl9MT0MiLCJBTFRFUl9MT0MiLCJWSUVXX1VTRVIiLCJDUkVBVEVfQURIT0NfSU5TUEVDVElPTiIsIkNSRUFURV9DT1JSRUNUSU9OIiwiVklTSVRPUl9JTlNQRUNUSU9OIiwiQVBQUk9WRV9SRV9JTlNQRUNUSU9OIiwiQUxURVJfTE9DX1RZUEUiLCJBTFRFUl9JTlNQRUNUSU9OX1JFUSIsIkNSRUFURV9WSVNJVE9SIiwiQVBQUk9WRV9WSVNJVE9SIiwiQUxURVJfUk9MRSIsIlZJRVdfTE9DIiwiQUxURVJfSFJfVFlQRSIsIkFMVEVSX0NIS19MSVNUIiwiVklFV19FUSIsIkNSRUFURV9DT1JSRUNUSVZFTkVTU19BQ1RJT04iLCJWSUVXX0hSX1RZUEUiXSwiYXV0aF91c2VyX2lkIjoiNWYxNTU1YTUwNWFhZjkwMDI4NThiMWQyIiwiaWF0IjoxNTk4NjE2MzUxLCJleHAiOjE1OTg2MTY5NTEsInN1YiI6IjcyNjg1NGY5LWRmMWItNDIxYi04ZjRiLTNkMzkwMjc3Yjg0NyIsImp0aSI6IjVmNDhmMzFmYjZkOWU5MDAyODIzOWUwYiJ9.itwCPRCBjtwgtbn0oCN2K7aUPNyRltlLPS7MR67dqdA";
  }
  
  return {
    headers: headers,
  };
}

/**
 * @Author: Nisal Madusanka(EruliaF)
 * @description: request data from api
 * @param {String} apiUrl api url
 */
const callApi = (apiUrl = null) => {


  var attributes = {
    apiUrl: apiUrl,
    isSetHeaders: true,
    multipart: false,
    method: "get",
    body: {},
    onUpload: false
  };
  return {
    /**
     * @description: set headers
     * @param {Boolean} status set headers
     */

    headers: function (status = false) {
      attributes = {
        ...attributes,
        isSetHeaders: status
      };
      return this;
    },
    /**
     * @description: set multipart
     * @param {*} status
     */
    isMultipart: function (status = true) {
      attributes = {
        ...attributes,
        multipart: status
      };
      return this;
    },
    /**
     * @description: set api method type
     * @param {String} method set api method type
     */

    method: function (method = "post") {
      attributes = {
        ...attributes,
        method: method
      };
      return this;
    },
    /**
     * @description: set api body
     * @param {Object|Array} status set api body
     */

    body: function (body = {}) {
      attributes = {
        ...attributes,
        body: body
      };
      return this;
    },
    /**
     * @description: tracking uploading process
     * @param {Funtion} onUploadFn
     */
    onUpload: function (onUploadFn) {
      attributes = {
        ...attributes,
        onUpload: onUploadFn
      };
      return this;
    },

    /**
     * @description: full  object
     * @param {Funtion} onUploadFn
     */
    setFullObject: function (object) {
      attributes = {
        ...attributes,
        ...object
      };
      return this;
    },

    /**
     * @description: send request to end-point
     */
    send: function () {

      const headerRequest = createHeader(attributes.isSetHeaders, attributes.multipart);

      return axiosWrapper(
        attributes.apiUrl,
        attributes.method,
        headerRequest,
        attributes.body,
        attributes.onUpload,
      );
    }
  };
};

export {
  callApi
};
