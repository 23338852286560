/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-06-25 21:37:44
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-06-26 13:09:50
 */
import React, { Fragment } from "react";

import { getRelevantImage } from "../../../../helpers/boda-boda/file-upload-helpers/file-upload.helpers";
import { ToolTipComponent } from "../../CommonElements/BaseElements";

const emptyFun = (...para) => undefined;

const FileDisplay = ({
    elementStyle = "",
    formGroupName = "",
    formKey = "",
    file = "",
    apiUrl = "",
    isDownload = true,
    isDelete = true,
    onDeleteFn = emptyFun
}) => {
    return (
        <Fragment>
            {
                (file !== "") ? (
                    <div className={`display-image ${elementStyle}`}>
                        <img
                            src={`${(getRelevantImage(file) === null) ? `${apiUrl}${file}` : `images/${getRelevantImage(file)}`}`}
                            className={"img-thumbnail select-image"}
                        />

                        <div className={"image-hover"}>
                            {
                                (isDownload) ? (
                                    <Fragment>
                                        <i
                                            id={`${formGroupName}${formKey}_download`}
                                            className={"fas fa-file-download mr-4"}
                                            onClick={() => window.open(`${apiUrl}${file}`)}
                                        />

                                        <ToolTipComponent
                                            tooltipText={"Download"}
                                            target={`${formGroupName}${formKey}_download`}
                                        />
                                    </Fragment>
                                ) : null
                            }

                            {
                                (isDelete) ? (
                                    <Fragment>
                                        <i
                                            id={`${formGroupName}${formKey}_delete`}
                                            className={"fas fa-trash-alt"}
                                            onClick={() => onDeleteFn()}
                                        />

                                        <ToolTipComponent
                                            tooltipText={"Delete"}
                                            target={`${formGroupName}${formKey}_delete`}
                                        />
                                    </Fragment>
                                ) : null
                            }
                        </div>
                    </div>
                ) : null
            }
        </Fragment>
    )
};

export default FileDisplay;
