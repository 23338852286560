import { put, call } from 'redux-saga/effects';
import validateMethod from "../../../helpers/boda-boda/validation";
import { get } from "lodash";
import {setFormErrorFn} from "../form/actions";
import { merchantBranchContactAPI,bulkPrintAPI} from "../../../config/boda-boda/apiUrls.config";
import {setPageLoader, setFlashMessage} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {isEmptyValue} from "../../../helpers/boda-boda/common-helpers/common.helpers";

const emptyFn = (...para) => undefined;

function* bulkTagPrintFn(data) {
    console.log("-------->",data);

    const validationResponse = yield call(() => validateMethod(
       
        
        get(data, 'payload', {}),
        {},
    ));

    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${bulkPrintAPI.key}_create`, validationResponse.errors));

    } else {

        yield put(setPageLoader((true)));
        yield put(setFormErrorFn(`${bulkPrintAPI.key}_create`, []));

        const bulkPrintAPIRequestBody = {
            count: get(data, 'payload.count', ""),
            facility: {
               // id: get(data, "payload.facilityId", "")
               id: 1
            }
        }

        const bulkPrintAPIRequest = yield call(() => callApi(`${bulkPrintAPI.url}`).headers(true).method("post").body(bulkPrintAPIRequestBody).send());

        console.log("777777",bulkPrintAPIRequest);

        get(data, "payload.onNextFn", emptyFn)(get(bulkPrintAPIRequest, "data.data.code", ""))


        if (bulkPrintAPIRequest._statue === true) {
            console.log("99999",data);


            yield put(setPageLoader(false));
        }
    }

    yield put(setPageLoader(false));
}

export {
    bulkTagPrintFn
}