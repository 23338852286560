import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
    RadioButtonWithState
} from "../../../../components/boda-boda/Forms";
import { currency, mapPackageStatusesNames, reportPackageStatuses } from "../../../../config/boda-boda/db_config/db_status.config";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    roundValue,
    convertToCsvValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { packageWiseCostingReportAPI, merchantAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setFlashMessage } from '../../../../store/boda-boda/ui/actions'

class PackageWiseCostingReport extends Component {

    onGenerateFn = () => {
        let { formState, history } = this.props;

        if (this.validateFn() === true) {
            let dateTo = new Date(get(formState, "package_wise_costing.dateTo"));
            dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
            dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

            const queryObj = {
                dateFrom: get(formState, "package_wise_costing.dateFrom").toISOString(),
                dateTo: dateTo.toISOString(),
                status: get(formState, "package_wise_costing.status"),
                merchantId: get(formState, "package_wise_costing.merchantId").split("/")[0],
            }

            if (get(formState, "package_wise_costing.type", "screen") === "screen") {
                history.push("/reports/package-wise-costing-report/view");
            } else {
                this.getAPIData(fromObjectToQueryString(queryObj));
            }
        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "package_wise_costing.dateFrom", ""))) {
            errors.push({
                "property": "dateFrom",
                "error": "The Pick Up Date From field is required"
            })
        }

        if (isEmptyValue(get(formState, "package_wise_costing.dateTo", ""))) {
            errors.push({
                "property": "dateTo",
                "error": "The Pick Up Date To field is required"
            })
        }

        if (errors.length === 0) {
            if (new Date(get(formState, "package_wise_costing.dateFrom", "")) > new Date(get(formState, "package_wise_costing.dateTo", ""))) {
                setFormErrorFn("package_wise_costing", [{
                    "property": "dateTo",
                    "error": "The Pick Up Date To field should not be less than the Pick Up Date From"
                }]);

                return false;
            } else {
                setFormErrorFn("package_wise_costing", []);
                return true;
            }
        } else {
            setFormErrorFn("package_wise_costing", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${packageWiseCostingReportAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);
                        
                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }
                                        
                        result.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data", []), get(result, "data.meta.totalsRow", {}));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    generateCSVData = (data = [], totalData) => {
        let { formState } = this.props;

        const reportName = ["Package wise Costing Report"];
        const dateRange = [`From ${dateObjectToString(get(formState, "package_wise_costing.dateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_wise_costing.dateTo"), "dd/mm/YY")}`];
        const merchant = [`"Merchant: ${convertToCsvValue(get(formState, "package_wise_costing.merchantId", "") === "" ? "All" : get(formState, "package_wise_costing.merchantId", "").split("/")[1])}"`];
        const packageStatus = [`"Package Status: ${convertToCsvValue(get(formState, "package_wise_costing.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "package_wise_costing.status", "")])}"`];
        const headers = ["Pick Up Date", "Article ID", "Consignment ID", "Merchant Code", "Lodgement Location", "Status", "Delivered Date",
            `Delivery Cost (${currency})`, `Express Delivery Cost (${currency})`, `XL Handling Cost (${currency})`,
            `Total Cost (${currency})`, `Delivery Fee (${currency})`, `Express Fee (${currency})`, `XL Handling Fee (${currency})`,
            `Total Fee (${currency})`, `Profit from Delivery (${currency})`
        ];

        const dataList = [];

        data.forEach((value) => {
            const temp = [];

            temp.push(dateObjectToString(get(value, "packageHistories[0].manifest.pickedAt", new Date()), "YY-mm-dd HH:MM:II"));
            temp.push(`"${convertToCsvValue(get(value, "articleNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.consignmentNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchant.code", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "packageHistories[0].manifest.locationDrop.name", "-"))}"`);
            if (get(value, "status", "-") === "IN_TRANSIT_FIRST") {
                temp.push("PACKAGE PICKED UP");
            } else if (get(value, "status", "-") === "RECEIVED") {
                temp.push("RECEIVED AT PROCESSING CENTER");
            } else if (get(value, "status", "-") === "SORTED") {
                temp.push("READY FOR TRANSIT");
            } else if (get(value, "status", "-") === "IN_TRANSIT_LINE") {
                temp.push("INTRANSIT TO REGIONAL HUB");
            } else if (get(value, "status", "-") === "IN_TRANSIT_LAST") {
                temp.push("OUT FOR DELIVERY");
            } else if (get(value, "status", "-") === "NOT_DELIVERED") {
                temp.push("FAILED DELIVERY");
            } else {
                temp.push(get(value, "status", "-"));
            }
            temp.push(get(value, "deliveredAt", "") === null ? "" : dateObjectToString(get(value, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II"));
            temp.push(get(value, "cost", "0"));
            temp.push(get(value, "expressCost", "0"));
            temp.push(get(value, "xlPackageHandlingCost", "0"));
            temp.push(get(value, "totalCost", "0"));
            temp.push(get(value, "merchantPrice", "0"));
            temp.push(get(value, "expressFee", "0"));
            temp.push(get(value, "xlPackageHandlingFee", "0"));
            temp.push(get(value, "totalPrice", "0"));
            temp.push((get(value, "totalPrice", 0) - get(value, "totalCost", 0)).toString());

            dataList.push(temp);
        });

        const finalDataSet = [reportName, [""], dateRange, merchant, packageStatus, [""], [""], headers];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        finalDataSet.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "Total",
            roundValue(get(totalData, "cost", 0)),
            roundValue(get(totalData, "expressCost", 0)),
            roundValue(get(totalData, "xlPackageHandlingCost", 0)),
            roundValue(get(totalData, "totalCost", 0)),
            roundValue(get(totalData, "merchantPrice", 0)),
            roundValue(get(totalData, "expressFee", 0)),
            roundValue(get(totalData, "xlPackageHandlingFee", 0)),
            roundValue(get(totalData, "totalPrice", 0)),
            roundValue(get(totalData, "profit", 0))
        ]);

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Package wise Costing Report-${Date.now()}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Package wise Costing Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Package wise Costing Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "120px" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"package_wise_costing"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            dateFrom: null,
                                            dateTo: null,
                                            merchantId: "",
                                            status: "",
                                            type: "screen",
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Pick Up Date From"
                                                    inputName={"dateFrom"}
                                                    formGroupName={"package_wise_costing"}
                                                    placeholderText={"Pick Up Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Pick Up Date To"
                                                    inputName={"dateTo"}
                                                    formGroupName={"package_wise_costing"}
                                                    placeholderText={"Pick Up Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Merchant"}
                                                    apiUrl={`${merchantAPI.url}/all`}
                                                    apiStateKey={merchantAPI.key}
                                                    inputName={"merchantId"}
                                                    placeHolderText={"Merchant"}
                                                    formGroupName={"package_wise_costing"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Status"}
                                                    inputName={"status"}
                                                    placeHolderText={"Status"}
                                                    formGroupName={"package_wise_costing"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...reportPackageStatuses
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <RadioButtonWithState
                                                    isRequired={true}
                                                    labelText={"Report Type"}
                                                    inputName={"type"}
                                                    formGroupName={"package_wise_costing"}
                                                    dataList={[
                                                        {
                                                            id: "screen",
                                                            value: "View on Screen"
                                                        },
                                                        {
                                                            id: "csv",
                                                            value: "Export"
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    setFlashMessage,
})(PackageWiseCostingReport);
