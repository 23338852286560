import { put, call } from 'redux-saga/effects';
import { setPageLoader } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getZoneAPI } from "../../../config/boda-boda/apiUrls.config";
import { resetDataTable } from "../core/actions";
import { setFlashMessage } from "../ui/actions";

function* addZones(data) {

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getZoneAPI.url}/${data.id}`)
        .headers(true)
        .method("put")
        .body(data.payload)
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getZoneAPI.key}_null`));
        yield put(resetDataTable(`${getZoneAPI.key}_selected`));

        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    }

    yield put(setPageLoader(false));
}

export {
    addZones
}
