import React, { Component } from "react";
import { get } from "lodash";

import { InputButton } from "./Button";
import { connect } from "react-redux";

import { getDataByFormObject } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { generateApiCallObjFn, requestAPICallFn } from "../../../store/boda-boda/core/actions";

const emptyFn = (...para) => undefined;

class SubmitButton extends Component {

    static defaultProps = {
        elementWrapperStyle: "",
        elementStyle: "",
        btnName: "",
        btnText: "",
        btnSize: "md",
        btnColor: "primary",
        tooltip: "",
        isFullWidth: false,
        isBtnDisabled: false,
        isOutlined: false,
        startIcon: null,
        endIcon: null,
        formGroupName: "",
        dataTableKey: null,
        isValidate: false,
        validationObject: {
            fields: {},
            rules: {},
            messages: {}
        },
        callApiObject: {
            isSetHeaders: true,
            multipart: false,
            method: "post",
            onUpload: false
        },
        apiDataStoringObject:{
            setLoader:true,
            storingType:"API_RESPONSE_LINKED_FORM",
            mergeToSuccessResponse:null,
            mergeToErrorResponse:null,
        },
        flashMessages:{},
        onClickBtnFn: emptyFn,
        onResponseCallBackFn: emptyFn,
        onGetAPIEndPointFn: emptyFn,
        onChangeRequestBodyFn: emptyFn,
    }

    onClickSubmit=(event)=>{

        let {       
            coreState,formState,formGroupName,dataTableKey,
            apiDataStoringObject,callApiObject,flashMessages,
            isValidate,validationObject,

            onClickBtnFn,onGetAPIEndPointFn,onChangeRequestBodyFn,
            requestAPICallFn,onResponseCallBackFn 
        } = this.props;

        onClickBtnFn(event);

        let currentFormObject={};

        if(formGroupName.includes("dataTableResponses") || formGroupName.includes("apiResponses")){
            currentFormObject = get(coreState,`${formGroupName}`,{});
        }else{
            currentFormObject = get(formState,`${formGroupName}`,{});
        }

        const apiUrlObject=onGetAPIEndPointFn(currentFormObject);

        if(apiUrlObject){

            let formData={};
            formData=onChangeRequestBodyFn(currentFormObject);
            if(!formData){
                formData=getDataByFormObject(currentFormObject);
            }

            let apiObj= generateApiCallObjFn(apiUrlObject.url)
            .setLoader(get(apiDataStoringObject,'setLoader',false))
            .setFullObject(apiDataStoringObject,callApiObject)
            .body(formData)
            .setFlashMessages(flashMessages)
            .setInitStoring(apiDataStoringObject.storingType,{
                "form":formGroupName,
                "responseKey":apiUrlObject.key,
                "dataTable":dataTableKey
            })
            .setOnResponseCallBackFn(onResponseCallBackFn);

            if(isValidate){
                apiObj.setValidationObject(validationObject.fields,validationObject.rules,validationObject.message);
            }            
            apiObj=apiObj.generate();
            requestAPICallFn(apiObj);

        }
    }

    render() {
        let { 
            elementWrapperStyle, elementStyle, btnName, 
            btnText, btnColor, isFullWidth, isBtnDisabled, 
            isOutlined, startIcon, endIcon, tooltip
        } = this.props;

        return (
            <InputButton
                elementWrapperStyle={elementWrapperStyle}
                elementStyle={elementStyle}
                btnName={btnName}
                btnText={btnText}
                btnColor={btnColor}
                tooltip={tooltip}
                isFullWidth={isFullWidth}
                isBtnDisabled={isBtnDisabled}
                isOutlined={isOutlined}
                startIcon={startIcon}
                endIcon={endIcon}
                onClickBtnFn={(event)=>this.onClickSubmit(event)}
            />
        )
    }
}

const mapStatetoProps = state => {
    return {
        formState:state.FormData,
        coreState:state.CoreState
    };
};

export default connect(mapStatetoProps, { 
    requestAPICallFn
})(SubmitButton);
