import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { FormWrapper, InputButton } from "../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";


const ViewUI = (props) => {
    let { history, location, match } = props;

    const formState = useSelector(state => state.FormData);

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        id: get(response, "id", ""),
                        status: get(response, "status", ""),
                        type: get(response, "type", ""),
                        packages: get(response, "packages", ""),
                        locationDrop: get(response, "locationDrop", ""),
                        locationPick: get(response, "locationPick", ""),
                        notes: get(response, "notes", ""),
                        merchant: get(response, "merchant.name", ""),
                        pickAt: get(response, "pickAt", ""),
                        code: get(response, "code", ""),

                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            {
                                (get(formState, `${getManifestListAPI.key}_view.type`, "") === "COLLECTION")?(<h4 className="font-size-18">View Collection</h4>)
                                :(<h4 className="card-title mb-3">View Pick Up</h4>)
                            }
                            
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <BackBtnComponent
                                path={"/inbounds"}
                            />
                        </div>
                    </Col>
                </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Date</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""),"YY-mm-dd")}
                                            </dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-5"}>Merchant</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.merchant`, "")}</dt>

                                            <dd className={"col-sm-5"}>Status</dd>
                                            <dt className={"col-sm-7"}>
                                                {
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH" ? "PENDING" : get(formState, `${getManifestListAPI.key}_view.status`, "")
                                                }
                                            </dt>
                                            <dd className={"col-sm-5"}>Received Packages Count</dd>
                                            <dt className={"col-sm-7"}></dt>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Packages"
                        apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}`}
                        dataTableStateKey={getPackageListAPI.key}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Article No", key: "articleNo" },
                            { displayName: "Customer Name", key: "order.name" },
                            { displayName: "Delivery Type", key: "deliveryType" },
                            { displayName: "Payment Method", key: "order.paymentMethod" },
                            { displayName: "Package Category", key: "category.name" },
                            { displayName: "Status", key: "status" },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: false,
                                actionBtn: {
                                    onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notes</h4>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        {
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div>None</div>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

export  {ViewUI};
