import React, { Component } from "react";

import { InputBoxWithState, RadioButton, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";


class FilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Zone ID"
                            inputName={"zone_id"}
                            //formGroupName={this.props.searchFromKey}
                            placeHolderText={"Zone ID"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Zone Name"
                            inputName={"zone_name"}
                            //formGroupName={this.props.searchFromKey}
                            placeHolderText={"Zone Name"}
                        />
                    </div>

                    {/* <div className="col-md-6">
                        <SelectBoxWithState
                            isRequired={true}
                            labelText={"Zone Status"}
                            //placeHolderText={""}
                            inputName={"zone_status"}
                            formGroupName={"editPackage"}
                            // apiUrl={`${getPackageTypesListAPI.url}/all`}
                            // apiStateKey={getPackageTypesListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            //onChangeFn={(eventData) => this.packageTypeOnchangeFn(eventData)}
                        />
                    </div> */}
                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="City"
                            inputName={"city"}
                            //formGroupName={this.props.searchFromKey}
                            placeHolderText={"City"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export { FilterUI };