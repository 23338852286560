import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import {get} from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import { getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";
import FilterUI from "./collection-includes/includes/FilterUI";
import {formatToDateHelper, isEmptyValue} from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { withRouter } from "react-router-dom";


class Collections extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Collections</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Collections</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <CrudComponent
                            title={"Collections"}
                            apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&type=COLLECTION&statuses=["PUBLISH" ,"COMPLETED" , "IN_TRANSIT_LINE", "DRAFT" ]&facilityId=${get(authState, "authUser.facility.id")}`}
                            dataTableStateKey={getManifestListAPI.key}
                            isFilter={true}
                            filterFromComponent={FilterUI}
                            isDropdownActionBtn={true}
                            defaultFilterFormObject={{
                                dateTo: null,
                                dateFrom: null,
                                code: "",
                                locationPickId: "",
                                locationDropId: "",
                                locationCurrentId: "",
                                facilityId: "",
                                status: "",
                            }}
                            clearErrors={() => {
                                const { setFormErrorFn } = this.props;
                                setFormErrorFn("manifestListAPIKey_search", [])
                            }}
                            onRebuildFilterObjFn={(filterData) => { 
                                const data = filterData;

                                let { setFormErrorFn  } = this.props;
                                const errors = [];

                                if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                                    errors.push({
                                        "property":"dateTo",
                                        "error":"The To Date field should not be less than the From Date field "
                                    })
                                    }

                                    if(errors.length > 0 ){
                                        setFormErrorFn("manifestListAPIKey_search", errors);
                                        return;
                                    }else{
                                        setFormErrorFn("manifestListAPIKey_search", []);
                                    }


                                if (!isEmptyValue(filterData.dateTo)) {
                                    data["dateTo"] = formatToDateHelper(filterData.dateTo);
                                }
                                if (!isEmptyValue(filterData.dateFrom)) {
                                    data["dateFrom"] = filterData.dateFrom.toISOString();
                                }

                                return data;
                            }}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Collection Code", key: "code" },
                                { displayName: "Destination Facility", key: "locationDrop.name",  default: "-" },
                                { displayName: "Current Location", key: "locationCurrent.name", default: "-" },
                                { displayName: "Pick Location", key: "locationPick.name" },
                                { displayName: "Drop Location", key: "locationDrop.name" },
                                {
                                    displayName: "Status", key: "status", onPrintFn:(rawData) => {
                                        if (rawData.status === "DRAFT") {
                                            return "Open";
                                        } else if (rawData.status === "PUBLISH") {
                                            return "Closed";
                                        } else if (rawData.status === "IN_TRANSIT_LINE") {
                                            return "Intransit to regional hub";
                                        } else if (rawData.status === "COMPLETED") {
                                            return "Delivered to regional hub";
                                        }else{
                                            return rawData.status
                                        }
                                    }
                                },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/collection/view-draft/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "DRAFT";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/collection/view/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status !== "DRAFT";
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.edit,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/collection/edit/${rawData.id}`),
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        return rawData.rowData.status === "DRAFT";
                                    }
                                },
                            ]}
                        />
                    </div>

                </div>
         </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
        setFormErrorFn,
})( withRouter(Collections));
