import React, { Component} from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputButton, FormWrapper } from "../../../../../components/boda-boda/Forms";
import { getUserStaffList } from "../../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";


class ViewUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserStaffList.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: get(response, "id", ""),
                            name: get(response, "name", ""),
                            email: get(response, "email", ""),
                            phone: get(response, "phone", ""),
                            identification: get(response, "identification", ""),
                            status: get(response, "status", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Merchant Admin User</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={`/merchant/${match.params.merchantId}/admin-users`}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/merchant/${match.params.merchantId}/admin-users/edit/${match.params.id}`)}
                                />

                                <InputButton
                                    elementWrapperStyle={""}
                                    btnText={"Delete"}
                                    startIcon={"mdi mdi-trash-can-outline"}
                                    btnColor={"danger"}
                                />

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <FormWrapper
                                setGroupName={`${getUserStaffList.key}_create`}
                                onDestroyUnsetFormObject={true}
                            >

                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    <dd className={"col-sm-4"}>User ID</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.id`, "-")}</dt>

                                                    <dd className={"col-sm-4"}>Identification</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.identification`, "")}</dt>

                                                    <dd className={"col-sm-4"}>Phone</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.phone`, "")}</dt>
                                                </dl>
                                            </div>
                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    <dd className={"col-sm-4"}>Name</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.name`, "")}</dt>

                                                    <dd className={"col-sm-4"}>Status</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.status`, "")}</dt>

                                                    <dd className={"col-sm-4"}>E-mail</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.email`, "")}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </FormWrapper>
                        </Col>
                    </Row>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
    ViewUIWithState
}
