import React from "react";

import { InputBoxWithState, SelectBoxWithState, } from "../../../../../components/boda-boda/Forms";
import { getFacilityAPI } from "../../../../../config/boda-boda/apiUrls.config";


const CollectionFilterUI = (props) => {
    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Collection Code"
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        maxLength={20}
                        placeHolderText={"Collection Code"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Destination Delivery Center"}
                        placeHolderText={"Destination Delivery Center"}
                        inputName={"facilityId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={`${getFacilityAPI.url}/all`}
                        apiStateKey={getFacilityAPI.key}
                        keyName={"id"}
                        valueName={"name"}
                    />
                </div>

            </div>
        </div>
    )
}
export default CollectionFilterUI;
