import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

import { RadioButtonMemo } from "./RadioButton";
import { getValueByFilter } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { changeInputFn } from "../../../store/boda-boda/form/actions";
import { formCacheLevel } from "../../../config/boda-boda/template.config";
import { generateApiCallObjFn, initResponse, requestAPICallFn } from "../../../store/boda-boda/core/actions";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";


const emptyFun = (...para) => undefined;

class RadioButtonWithState extends Component {

    static defaultProps = {
        elementWrapperStyle: "",
        elementStyle: "",
        labelText: "",
        isRequired: false,
        inputName: "",
        inputValue: "",
        isDisabled: false,
        isInline: true,
        helperText: "",
        dataList: [],
        keyName: "id",
        valueName: "value",
        inputStatePath: "",
        formGroupName: "",
        apiUrl: "",
        apiStateKey: "",
        setCache: true,
        cacheLevel: formCacheLevel.updateOnFormGroupChange,
        onChangeFn: emptyFun,
    }

    constructor(props) {
      super(props);
    }

    componentDidMount() {
        if (this.props.apiUrl) {
            this.getAPIData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.apiUrl) {
            if (this.props.apiUrl !== prevProps.apiUrl || this.props.apiStateKey !== prevProps.apiStateKey) {
                this.getAPIData();
            }
        }
    }

    getAPIData = () => {
        this.props.initResponse(this.props.apiStateKey);
        const callApiObj = generateApiCallObjFn(this.props.apiUrl)
            .method("get")
            .setInitStoring(apiResponseStoringType.setResponse, {
                "responseKey": this.props.apiStateKey
            })
            .generate();

        this.props.requestAPICallFn(callApiObj);
    }

    render() {
        let {
            elementWrapperStyle, elementStyle, labelText,
            inputName, inputValue, isInline,
            isDisabled, helperText, inputStatePath,
            formGroupName, cacheLevel, formState,
            isRequired, dataList, apiUrl,
            apiStateKey, setCache, keyName,
            valueName, changeInputFn, coreState
        } = this.props;

        return (
            <RadioButtonMemo
                elementWrapperStyle={elementWrapperStyle}
                elementStyle={elementStyle}
                labelText={labelText}
                isRequired={isRequired}
                inputName={inputName}
                isInline={isInline}
                dataList={apiUrl ? get(coreState, `apiResponses.${apiStateKey}.result`, []) : dataList}
                inputValue={get(formState,inputStatePath?inputStatePath:`${formGroupName}.${inputName}`,inputValue)}
                inputError={getValueByFilter(get(formState,`${formGroupName}._errors`,[]),["property",((inputStatePath)?inputStatePath.substr(formGroupName.length + 1):inputName)],"error","","")}
                isDisabled={isDisabled}
                helperText={helperText}
                keyName={keyName}
                valueName={valueName}
                updateStatus={get(formState,`${formGroupName}._updateStatus`,undefined)}
                cacheLevel={cacheLevel}
                setCache={setCache}
                onChangeFn={(eventData)=>changeInputFn(formGroupName,inputName,inputStatePath,eventData.value)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    requestAPICallFn,
    initResponse
})(RadioButtonWithState);
