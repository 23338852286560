import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import ReMerchantProfile from "./resume-includes/ReMerchantProfile";
import ReMerchantAdministration from "./resume-includes/ReMerchantAdministration";
import ReMerchantContract from "./resume-includes/ReMerchantContract";
import ReMerchantDefaultBranch from "./resume-includes/ReMerchantDefaultBranch";
import { changeInputFn } from "../../../../../store/actions";
import {setPageLoader} from "../../../../../store/boda-boda/ui/actions"

class ResumeUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            merchantId: this.props.match.params.id
        };
    }

    render() {
        let { history, formState, changeInputFn, setPageLoader } = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">
                                    {
                                        (this.state.step === 0) ? "Create Merchant Profile" : (
                                            (this.state.step === 1) ? "Create Merchant Admin" : (
                                                (this.state.step === 2) ? "Create Merchant Contract" : "Create Merchant Default Branch"
                                            )
                                        )
                                    }
                                </h4>
                                <ol className="breadcrumb mb-0">
                                    {
                                        (this.state.step === 0) ? <li className="breadcrumb-item"><i>Profile</i></li> :
                                            <li className="breadcrumb-item active">Profile</li>
                                    }
                                    {
                                        (this.state.step === 1) ? <li className="breadcrumb-item"><i>Admin</i></li> :
                                            <li className="breadcrumb-item active">Admin</li>
                                    }
                                    {
                                        (this.state.step === 2) ? <li className="breadcrumb-item"><i>Contract</i></li> :
                                            <li className="breadcrumb-item active">Contract</li>
                                    }
                                    {
                                        (this.state.step === 3) ? <li className="breadcrumb-item"><i>Default Branch</i></li> :
                                            <li className="breadcrumb-item active">Default Branch</li>
                                    }
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    {
                                        (this.state.step === 0) ? (
                                            <ReMerchantProfile
                                                merchantId={this.state.merchantId}
                                                onNextFn={(id) => this.setState({
                                                    merchantId: id,
                                                    step: 1
                                                })}
                                            />
                                        ) : (
                                            (this.state.step === 1) ? (
                                                <ReMerchantAdministration
                                                    merchantId={this.state.merchantId}
                                                    onNextFn={() => this.setState({
                                                        ...this.state,
                                                        step: 2
                                                    })}
                                                    onBackFn={() => this.setState({
                                                        ...this.state,
                                                        step: 0
                                                    })}
                                                />
                                            ) : (
                                                (this.state.step === 2) ? (
                                                    <ReMerchantContract
                                                        merchantId={this.state.merchantId}
                                                        changeInputFn={changeInputFn}
                                                        formState={formState}
                                                        onNextFn={() => this.setState({
                                                            ...this.state,
                                                            step: 3
                                                        })}
                                                        onBackFn={() => this.setState({
                                                            ...this.state,
                                                            step: 1
                                                        })}
                                                    />
                                                ) : (
                                                    <ReMerchantDefaultBranch
                                                        merchantId={this.state.merchantId}
                                                        history={history}
                                                        setPageLoader={setPageLoader}
                                                        changeInputFn={changeInputFn}
                                                        onBackFn={() => this.setState({
                                                            ...this.state,
                                                            step: 2
                                                        })}
                                                    />
                                                )
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const ResumeUIWithState = connect(mapStateToProps, { changeInputFn, setPageLoader })(ResumeUI);

export {
    ResumeUIWithState
}