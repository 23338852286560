import React, { Component } from "react";

import { DatePickerBoxWithState, InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { deliveryTypes, paymentMethods, consignmentStatusList } from "../../../../config/boda-boda/db_config/db_status.config";
import { getMerchantAPI } from "../../../../config/boda-boda/apiUrls.config";


class FilterUI extends Component {
    render() {
        return (
            <div className="col-md-12">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Consignment No"
                            inputName={"consignmentNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Consignment No"}
                            maxLength={20}
                        />
                    </div>
                    
                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Tracking No"
                            inputName={"trackingNumber"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Tracking No"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Customer Name"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Merchant"
                            inputName={"merchantId"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Merchant"}
                            apiUrl={`${getMerchantAPI.url}/all`}
                            apiStateKey={getMerchantAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Phone"
                            inputName={"phone"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Phone"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Delivery Type"
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryTypes}
                            placeHolderText={"Delivery Type"}
                        />
                    </div>


                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"From Date"}
                            inputName={"dateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"To Date"}
                            inputName={"dateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"To Date"}
                        />
                    </div>
                    
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Payment Method"
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={consignmentStatusList}
                            placeHolderText={"Status"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Channel Order No"
                            inputName={"channelOrderNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Channel Order No"}
                            maxLength={20}
                        />
                    </div>  

                </div>
            </div>
        )
    }
}

export default FilterUI;
