import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get, findIndex } from "lodash";

import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { LabelPrint, PrintLabelBtn, ShippingLabel } from "./ShipingLabel";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import ExpansionPanel from "../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { currency, lengthMetric, weightMetric } from "../../../../config/boda-boda/db_config/db_status.config";
import { modalTypes } from "../../../../components/boda-boda/Modal/config/model.config";
import { Link } from "react-router-dom";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { roundValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { roundUpTypes } from "../../../../config/boda-boda/core.config";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { ShippingLabelUI } from "./ShippingLabelUI";


class ViewUI extends Component {

    getPackageNo = (data = [], id) => {
        const index = findIndex(data, { id: parseInt(id) });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return "";
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            packageDetailsPanel: true,
            receiverPanel: false,
            locationPanel: false,
            notePanel: false,
            chargesPanel: false,
            reasonPanel: false,
            isShowAlert: false
        }
    }


    render() {
        let { match, history, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getPackageListAPI.key}_view`}
                    apiUrl={`${getPackageListAPI.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            type: response.type,
                            code: response.code,
                            articleNo: response.articleNo,
                            category: response.category,
                            weight: response.weight,
                            height: response.height,
                            width: response.width,
                            length: response.length,
                            metric: response.metric,
                            status: response.status,
                            deletedAt: response.deletedAt,
                            deliveryType: response.deliveryType,
                            order: response.order,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            merchantId: response.merchantId,
                            manifest: response.manifest,
                            manifestId: response.manifestId,
                            expressFee: response.expressFee,
                            totalPrice: response.totalPrice,
                            cashDeliveryAmount: response.cashDeliveryAmount,
                            cardDeliveryAmount: response.cardDeliveryAmount,
                            xlPackageHandlingFee: response.xlPackageHandlingFee,
                            onHold: response.onHold,
                            onholdReason: response.onholdReason,
                            notDeliveredReason: response.notDeliveredReason,
                            locationPick: response.locationPick,
                            locationDrop: response.locationDrop,
                            locationCurrent: response.locationCurrent,
                            locationFinalFacility: response.locationFinalFacility,
                            merchantPrice: response.merchantPrice,
                            merchant: response.merchant,
                            merchantBranch: response.merchantBranch,
                            volumetric: response.volumetric,
                            totalFlatPrice: response.totalFlatPrice,
                            merchantFlatPrice: response.merchantFlatPrice,
                            priceType: response.priceType,
                            pendingPriceCalculation: response.pendingPriceCalculation,
                            contract: {
                                chargingMethod: response.contract.chargingMethod,
                            },
                            courier: response.courier,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Package</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    isGoBack={true}
                                    elementWrapperStyle={"mr-2"}
                                />
                                {
                                    (get(formState, `${getPackageListAPI.key}_view.status`, "") === "RECEIVED" ||
                                        get(formState, `${getPackageListAPI.key}_view.status`, "") === "SORTED" ||
                                        get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED"
                                    ) ?
                                        (
                                            <InputButton
                                                elementWrapperStyle={"mr-2"}
                                                btnText={"Edit"}
                                                startIcon={"mdi mdi-square-edit-outline"}
                                                onClickBtnFn={() => history.push(`/packages/edit/${match.params.id}`)}
                                            />
                                        ) : null
                                }

                                <ShippingLabel
                                    package={{ ...get(formState, `${getPackageListAPI.key}_view`, {}), id: match.params.id }}
                                />

                                {/* <BackBtnComponent
                                    path={"/packages"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                    elementWrapperStyle={"mr-2"}
                                /> */}

                            </div>
                        </Col>
                    </Row>

                    <ExpansionPanel
                        title={"Package Details"}
                        isOpen={this.state.packageDetailsPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                packageDetailsPanel: !prevState.packageDetailsPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-4"}>Article No</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.articleNo`, "")}</dt>

                                    {/* <dd className={"col-sm-4"}>Order No</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.id`, "")}</dt> */}

                                    <dd className={"col-sm-4"}>Consignment No</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.consignmentNo`, "")}</dt>

                                    <dd className={"col-sm-4"}>Package No</dd>
                                    <dt className={"col-sm-8"}>{this.getPackageNo(get(formState, `${getPackageListAPI.key}_view.order.packages`, []), match.params.id)}</dt>

                                    <dd className={"col-sm-4"}>Manifest</dd>
                                    {get(formState, `${getPackageListAPI.key}_view.manifest.type`, "") === "RETURN" ?
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.manifest.code`, "-")}</dt>
                                        :
                                        <dt className={"col-sm-8"}><Link to={
                                            (get(formState, `${getPackageListAPI.key}_view.manifest.type`, "") === "COLLECTION") ?
                                                "/collection/view/" + get(formState, `${getPackageListAPI.key}_view.manifestId`, "") :
                                                (get(formState, `${getPackageListAPI.key}_view.manifest.type`, "") === "DELIVERY") ?
                                                    "/outbounds/view/" + get(formState, `${getPackageListAPI.key}_view.manifestId`, "") :
                                                    "/pick-requests/view/" + get(formState, `${getPackageListAPI.key}_view.manifestId`, "")
                                        } >{get(formState, `${getPackageListAPI.key}_view.manifest.code`, "-")}</Link></dt>}

                                    <dd className={"col-sm-4"}>Signature Required</dd>
                                    <dt className={"col-sm-8"}>{(get(formState, `${getPackageListAPI.key}_view.order.signatureRequired`, "") === false) ? "No" : "Yes"}</dt>

                                    <dd className={"col-sm-4"}>Package Type</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.type.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Pick Location</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.locationPick.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Merchant</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.merchant.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Merchant Branch</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.merchantBranch.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Current Location</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.locationCurrent.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Package Category</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "")}</dt>

                                    {
                                        (get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "") !== "PREPAID") ? (
                                            <Fragment>
                                                <dd className={"col-sm-4"}>Order Amount</dd>
                                                <dt className={"col-sm-8"}>
                                                    {
                                                        (get(formState, `${getPackageListAPI.key}_view.order.amount`, null) === null) ? "0" : get(formState, `${getPackageListAPI.key}_view.order.amount`, "") + ` ${currency}`
                                                    }
                                                </dt>
                                            </Fragment>

                                        ) : (null)
                                    }



                                    <dd className={"col-sm-4"}>Payment Method</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "")}</dt>

                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-4"}>Weight</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.weight`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.weight`, "")} (${weightMetric})` : "N/A"}</dt>

                                    <dd className={"col-sm-4"}>Length</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.length`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.length`, "")} (${lengthMetric})` : "N/A"}</dt>

                                    <dd className={"col-sm-4"}>Width</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.width`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.width`, "")} (${lengthMetric})` : "N/A"}</dt>

                                    <dd className={"col-sm-4"}>Height</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.height`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.height`, "")} (${lengthMetric})` : "N/A"}</dt>

                                    {get(formState, `${getPackageListAPI.key}_view.contract.chargingMethod`, "") === "VOLUMETRIC" ?
                                        <Fragment>
                                            <dd className={"col-sm-4"}>Volumetric Weight</dd>
                                            <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.volumetric`, ""))}</dt>
                                        </Fragment>
                                        :
                                        null
                                    }

                                    <dd className={"col-sm-4"}>Delivery Type</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.deliveryType`, "")}</dt>

                                    <dd className={"col-sm-4"}>Status</dd>
                                    <dt className={"col-sm-8"}>
                                        {
                                            ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "PACKED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Packed" + "(On Hold)"
                                                : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "PACKED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Packed"
                                                    : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "IN_TRANSIT_FIRST") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Package Picked up" + "(On Hold)"
                                                        : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "IN_TRANSIT_FIRST") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Package Picked up"
                                                            : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "RECEIVED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Received at processing Center" + "(On Hold)"
                                                                : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "RECEIVED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Received at processing Center"
                                                                    : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "SORTED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Ready for Transit" + "(On Hold)"
                                                                        : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "SORTED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Ready for Transit"
                                                                            : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "IN_TRANSIT_LINE") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Intransit to regional hub" + "(On Hold)"
                                                                                : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "IN_TRANSIT_LINE") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Intransit to regional hub"
                                                                                    : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "IN_TRANSIT_LAST") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Out for Delivery" + "(On Hold)"
                                                                                        : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "IN_TRANSIT_LAST") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Out for Delivery"
                                                                                            : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "DELIVERED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Packed" + "(On Hold)"
                                                                                                : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "DELIVERED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Delivered"
                                                                                                    : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true)) ? "Failed Delivery" + "(On Hold)"
                                                                                                        : ((get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED") && (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === false)) ? "Failed Delivery" : get(formState, `${getPackageListAPI.key}_view.status`, "")
                                        }
                                    </dt>

                                    <dd className={"col-sm-4"}>Drop Location</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.locationDrop.name`, "")}</dt>

                                    <dd className={"col-sm-4"}>Destination Delivery Center</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.locationFinalFacility.name`, "")}</dt>

                                    {get(formState, `${getPackageListAPI.key}_view.status`, "") === "DELIVERED" ?
                                        <Fragment>
                                            <dd className={"col-sm-4"}>Driver Name</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.courier.staffs[0].name`, "")}</dt>
                                        </Fragment>
                                        :
                                        null
                                    }

                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Full Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.phone`, "")}</dt>

                                    {
                                        (get(formState, `${getPackageListAPI.key}_view.status`, "") === "DELIVERED") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Reciever Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.remarkDelivery`, "-")}</dt>

                                                {/* <dd className={"col-sm-5"}>Delivered Date and Time</dd>
                                                <dt className={"col-sm-7"}>{
                                                    (get(formState, `${getPackageListAPI.key}_view.deliveredAt`, "") !== null || get(formState, `${getPackageListAPI.key}_view.deliveredAt`, "") !== "")
                                                        ? dateObjectToString(get(formState, `${getPackageListAPI.key}_view.deliveredAt`, ""), "YY-mm-dd HH:MM:II") : "-"}</dt> */}
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>

                            {
                                (get(formState, `${getPackageListAPI.key}_view.status`, "") !== "NOT_DELIVERED") ? (
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-5"}>ID Verification Required</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === true ? "Yes" : "No"}</dt>

                                            {
                                                get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === true ? (
                                                    <Fragment>
                                                        <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationType`, "")}</dt>

                                                        <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, "")}</dt>
                                                    </Fragment>
                                                ) : null
                                            }
                                        </dl>
                                    </div>
                                ) : (null)
                            }


                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.postalCode`, "-")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lat`, "-")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lon`, "-")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.name`, "")} {get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.isCityConflicted`, false) ? <i class="fa fa-exclamation-triangle" aria-hidden="true" onClick={() => this.setState({ isShowAlert: true })} style={{ color: "#FFC107" }}></i> : null}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Delivery Charges"}
                        isOpen={this.state.chargesPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                chargesPanel: !prevState.chargesPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    {/* <dd className={"col-sm-4"}>Rate Category</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "0")}</dt> */}

                                    <dd className={"col-sm-4"}>Express Delivery Charge</dd>
                                    <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.expressFee`, "0"), 2, roundUpTypes.round) + ` ${currency}`}</dt>

                                    <dd className={"col-sm-4"}>XL Handling Charge</dd>
                                    <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.xlPackageHandlingFee`, "0")) + ` ${currency}`}</dt>


                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>

                                    <dd className={"col-sm-4"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, " ") === "VL_RATE" ? "Delivery Charge" : "Delivery Flat Charge"}</dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, " ") === "VL_RATE" ? `${roundValue(get(formState, `${getPackageListAPI.key}_view.merchantPrice`, "0"))} ${currency}` : `${roundValue(get(formState, `${getPackageListAPI.key}_view.merchantFlatPrice`, "0"))} ${currency}`} </dt>

                                    <dd className={"col-sm-4"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, " ") === "VL_RATE" ? "Total Charge" : "Total Flat Charge"} </dd>
                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, " ") === "VL_RATE" ? `${roundValue(get(formState, `${getPackageListAPI.key}_view.totalPrice`, "0"))} ${currency}` : `${roundValue(get(formState, `${getPackageListAPI.key}_view.totalFlatPrice`, "0"))} ${currency}`} </dt>

                                </dl>

                            </div>
                        </div>
                    </ExpansionPanel>

                    {
                        (get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED" || get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true) ?
                            (
                                <ExpansionPanel
                                    title={"Reasons"}
                                    isOpen={this.state.reasonPanel}
                                    onClickFn={() => this.setState((prevState) => {
                                        return {
                                            ...prevState,
                                            reasonPanel: !prevState.reasonPanel
                                        }
                                    })}
                                >
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <dl className={"row"}>

                                                {
                                                    (get(formState, `${getPackageListAPI.key}_view.status`, "") === "NOT_DELIVERED") ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-5"}>Reason for Not Delivered - </dd>
                                                            <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.notDeliveredReason`, "")}</dt>
                                                        </Fragment>
                                                    )
                                                        : (get(formState, `${getPackageListAPI.key}_view.onHold`, false) === true) ? (
                                                            <Fragment>
                                                                <dd className={"col-sm-5"}>Reason for On-Hold - </dd>
                                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.onholdReason`, "")}</dt>
                                                            </Fragment>
                                                        ) : (null)
                                                }

                                            </dl>
                                        </div>
                                    </div>
                                </ExpansionPanel>
                            ) : null
                    }



                    <ExpansionPanel
                        title={"Notes"}
                        isOpen={this.state.notePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                notePanel: !prevState.notePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-3"}>Public Note -</dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getPackageListAPI.key}_view.notesPublic`, "None")}</dt>

                                    {/* <dd className={"col-sm-3"}>Private Note -</dd>
                                    <dt className={"col-sm-9"}>{get(formState, `${getPackageListAPI.key}_view.notesPrivate`, "None")}</dt> */}
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <div className="row">
                        <CrudComponent
                            title="Other Related Packages"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${get(formState, `${getPackageListAPI.key}_view.order.id`)}`}
                            dataTableStateKey={`${getPackageListAPI.key}_inside`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => {
                                            history.replace(`/packages/view/${rawData.id}`);
                                            history.push('/temp');
                                            history.goBack();
                                        },
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.id.toString() !== match.params.id) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: true,
                                    actionBtn: {
                                        icon: "fa fa-barcode",
                                        color: "primary",
                                        tooltip: "Shipping Label",
                                        btnText: "Shipping Label"
                                    },
                                    modalProps: {
                                        modalTitle: "Shipping Label",
                                        modalType: modalTypes.large,
                                        modalBodyComponent: (rawData) => {
                                            return <ShippingLabelUI packages={[rawData.row]} />
                                        },
                                        modalFooterComponent: PrintLabelBtn,
                                        parentToModelProps: {
                                            type: "datatable"
                                        },
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    },
                                    checkBeforeActionRenderFn: (rawData) => {
                                        if (rawData.rowData.id.toString() !== match.params.id) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                }
                            ]}
                        />
                    </div>
                    <ModalUI
                        isOpen={this.state.isShowAlert}
                        modalTitle={"Attention"}
                        // modalType={modalTypes.large}
                        showHideToggleFn={() => this.setState({ isShowAlert: false })}
                        modalBodyComponent={
                            <p>There are multiple cities with the same destination postal code.</p>
                        }
                    />
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const PackageView = connect(mapStateToProps, null)(ViewUI);

export {
    PackageView
};
