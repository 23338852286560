import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { FormWrapper } from "../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { DeliveryPrint } from "./PrintUI";
import { currency, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";


const ViewUI = (props) => {
    let { history, location, match } = props;

    const formState = useSelector(state => state.FormData);

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        id: get(response, "id", ""),
                        status: get(response, "status", ""),
                        packages: get(response, "packages", ""),
                        locationDrop: get(response, "locationDrop", ""),
                        locationPick: get(response, "locationPick", ""),
                        notes: get(response, "notes", ""),
                        merchant: get(response, "merchant.name", ""),
                        pickedAt: get(response, "pickedAt", ""),
                        orderCount: get(response, "merchant.orderCount", ""),
                        code: get(response, "code", ""),
                        packages: response.packages,
                        publishedPackages: response.publishedPackages,
                        runSheetLastId: response.runSheetLastId,
                        runSheetLast: response.runSheetLast,
                        runSheetLine: response.runSheetLine,
                        runSheetLineId: response.runSheetLineId,
                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View Delivery</h4>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={`/outbounds`}
                                btnName={"Back"}
                                btnColor={"primary"}
                            />


                            <DeliveryPrint
                                delivery={get(formState, `${getManifestListAPI.key}_view`, {})}
                            />
                        </div>
                    </Col>

                </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>

                                            <dd className={"col-sm-5"}>Status</dd>
                                            <dt className={"col-sm-7"}>
                                                {
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH" ? "PENDING" : mapPackageStatusesNames[get(formState, `${getManifestListAPI.key}_view.status`, "")]
                                                }
                                            </dt>
                                            <dd className={"col-sm-5"}>Packages Count</dd>
                                            <dt className={"col-sm-7"}>{
                                                get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" ? get(formState, `${getManifestListAPI.key}_view.packages.length`, "-")
                                                    : get(formState, `${getManifestListAPI.key}_view.publishedPackages.length`, "-")
                                            }
                                            </dt>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    {get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" ?
                        (
                            <CrudComponent
                                title="Selected Packages"
                                apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}`}
                                dataTableStateKey={getPackageListAPI.key}
                                isFilter={false}
                                isSetAction={true}
                                isSetCreateAction={false}
                                tableHeaderList={[
                                    { displayName: "Article No", key: "articleNo" },
                                    {
                                        displayName: "Channel Order No", key: "order.channelOrderNo",
                                        onPrintFn: (rawData) => rawData?.order?.channelOrderNo ? rawData?.order?.channelOrderNo : "-"
                                    },
                                    { displayName: "Customer Name", key: "order.name" },
                                    {
                                        displayName: "Customer Address/ City", key: "order.shippingLocation.address",
                                        onPrintFn: (rawData) => `${rawData?.order?.shippingLocation?.address}/ ${rawData?.order?.shippingLocation?.city?.name}`
                                    },
                                    { displayName: "Contact Number", key: "order.phone" },
                                    { displayName: "Payment Method", key: "order.paymentMethod" },
                                    {
                                        displayName: `COD Amount(${currency})`, key: "order.amount",
                                        onPrintFn: (rawData) => rawData?.order?.paymentMethod === "PREPAID" ? "-" : rawData?.order?.amount
                                    },
                                    { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                        }
                                    }
                                ]}
                            />) :
                        (
                            <CrudComponent
                                title="Selected Packages"
                                apiURL={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=30`}
                                dataTableStateKey={getPackageListAPI.key}
                                isFilter={false}
                                isSetAction={true}
                                isSetCreateAction={false}
                                tableHeaderList={[
                                    { displayName: "Article No", key: "articleNo" },
                                    {
                                        displayName: "Channel Order No", key: "order.channelOrderNo",
                                        onPrintFn: (rawData) => rawData?.order?.channelOrderNo ? rawData?.order?.channelOrderNo : "-"
                                    },
                                    { displayName: "Customer Name", key: "order.name" },
                                    {
                                        displayName: "Customer Address/ City", key: "order.shippingLocation.address",
                                        onPrintFn: (rawData) => `${rawData?.order?.shippingLocation?.address}/ ${rawData?.order?.shippingLocation?.city?.name}`
                                    },
                                    { displayName: "Contact Number", key: "order.phone" },
                                    { displayName: "Payment Method", key: "order.paymentMethod" },
                                    {
                                        displayName: `COD Amount (${currency})`, key: "order.amount",
                                        onPrintFn: (rawData) => rawData?.order?.paymentMethod === "PREPAID" ? "-" : rawData?.order?.amount
                                    },
                                    { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                ]}
                                actionList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        isSetModel: false,
                                        actionBtn: {
                                            onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                        }
                                    }
                                ]}
                            />)}
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notes</h4>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        {
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div>None</div>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

export { ViewUI };
