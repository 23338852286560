import React from "react";

import { SubmitButton } from "../../../../components/boda-boda/Forms";
import { getRoleAPI } from "../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";

const RemoveMessage = () => {
    return(
        <p>Are your sure you want to delete this role?</p>
    );
}

const RemoveBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getRoleAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "User role has successfully deleted",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getRoleAPI.url}/${props.row.id}`,
                    key: getRoleAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.setModelStatusFn(false);
                }
            }}
        />
    );
}

export {
    RemoveBtn,
    RemoveMessage
}
